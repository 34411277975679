import React, {useEffect, useMemo, useState} from 'react';
import {debounce} from 'lodash';
import {Option, SearchDropdownInput, SearchInput, Spinner} from 'spenda-ui-react';
import {CloseCross} from '../../assets/svg/CloseCross';

export interface IARSearchBarSearchCategories {
  label: string;
  placeholder?: string;
  value: string;
}

export interface IARSearchBarSearch {
  searchString: string;
  searchPlaceholder?: string;
  searchCategory?: string;
}

interface ISearchBarProps {
  searchString: string;
  searchCategory?: string;
  searchPlaceholder?: string;
  isSearching: boolean;
  dataAutoIdSuffix: string;
  searchCategories?: IARSearchBarSearchCategories[];
  placeholder?: string;
  reverse?: boolean;
  type?: 'simple' | 'advance';
  onSubmit: (values: IARSearchBarSearch) => void;
}

export const ARSearchBar = (props: ISearchBarProps) => {
  // Props
  const {
    dataAutoIdSuffix,
    isSearching,
    reverse,
    searchCategories,
    searchCategory,
    searchPlaceholder,
    searchString,
    type,
    onSubmit,
  } = props;

  // States
  const [searchKey, setSearchKey] = useState('');
  const [searchCat, setSearchCat] = useState<string | undefined>('');
  const [inputPlaceHolder, setInputPlaceholder] = useState<string | undefined>('');

  useEffect(() => {
    setSearchCat(searchCategory);
    setSearchKey(searchString);
    if (searchPlaceholder) {
      setInputPlaceholder(searchPlaceholder);
    } else if (searchCategories && searchCategories.length > 0) {
      setInputPlaceholder(searchCategories?.find(c => c.value == searchCategory)?.placeholder || '');
    }
  }, [searchString, searchCategory, searchPlaceholder]);

  useEffect(() => {
    return () => {
      debounceSearch.cancel();
    };
  }, []);

  // Functions

  const onSearchCategoryChange = (sc: string) => {
    setSearchCat(sc);
    setInputPlaceholder(searchCategories?.find(c => c.value == sc)?.placeholder || '');
    if (!searchKey) return;
    onSubmit({
      searchString: searchKey,
      searchCategory: sc,
      searchPlaceholder: inputPlaceHolder,
    });
  };

  const handleSearch = (searchString: string) =>
    onSubmit({
      searchString: searchString.trim(),
      searchCategory: searchCat,
      searchPlaceholder: inputPlaceHolder,
    });
  const debounceSearch = useMemo(() => debounce(handleSearch, 300), [searchCat]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e?.target;

    debounceSearch(value);
    setSearchKey(value);
  };

  const options = searchCategories?.map(s => (
    <Option key={s?.value} value={s?.value} data-autoid={`btnSearchCategory-${s?.value}`}>
      {s?.label}
    </Option>
  ));

  const endAdornment = isSearching ? (
    <Spinner color="primary" />
  ) : (
    searchKey &&
    searchKey.length > 0 && (
      <CloseCross
        className="cursor-pointer"
        data-autoid="btnClearSearch"
        name="Clear"
        onClick={() => {
          setSearchKey('');
          handleSearch('');
        }}
      />
    )
  );

  return (
    <div className="search-container !relative">
      {type === 'advance' ? (
        <SearchDropdownInput
          reverse={reverse ? reverse : false}
          value={searchKey}
          type="text"
          name="searchString"
          id="search-text-input"
          onChange={handleSearchChange}
          autoComplete="off"
          options={options}
          data-autoid={`txtSearchInput${dataAutoIdSuffix}`}
          inputProps={{
            InputProp: {endAdornment},
            adornmentContainerClass: 'border-none',
          }}
          placeholder={
            searchCategories?.length
              ? `Search ${inputPlaceHolder || searchCat?.replace(/([a-z0-9])([A-Z])/g, '$1 $2')}`
              : 'Search'
          }
          selectProps={{
            className: 'min-w-[100px] border-none',
            children: '',
            onChange: e => onSearchCategoryChange(e as any),
            name: `searchInput${dataAutoIdSuffix}`,
            value: searchCat,
          }}
          iconButtonProps={{'data-autoid': 'btnSearchInput'}}
        />
      ) : (
        <SearchInput
          reverse={reverse ? reverse : false}
          value={searchKey}
          type="text"
          name="searchString"
          id="search-text-input"
          onChange={handleSearchChange}
          autoComplete="off"
          data-autoid={`txtSearchInput${dataAutoIdSuffix}`}
          placeholder={
            searchCategories?.length ? `Search ${searchCategory?.replace(/([a-z0-9])([A-Z])/g, '$1 $2')}` : 'Search'
          }
          iconButtonProps={{
            'data-autoid': `btnSearchInput`,
          }}
        />
      )}
    </div>
  );
};
