import React, {useRef, useState, useMemo, useCallback, useEffect} from 'react';
import {FormikProps} from 'formik';
import {uniqueId, debounce, isEmpty, cloneDeep} from 'lodash';
import clsx from 'clsx';
import currency from 'currency.js';

import {
  ICreatePurchaseInvoice,
  IPurchaseInvoiceItemsFilter,
} from '../../screens/purchase-invoice/PurchaseInvoiceDetail';
import {IPurchaseInvoiceAddInventory, IPurchaseInvoiceDetailLine} from '../../model/purchase-invoice/purchaseInvoice';
import {PurchaseInvoicePriceEntryType, PurchaseInvoiceStatusTypes} from '../../model/constants/Constants';
import {Inventory as IInventory} from '../../model/inventory/Inventory';
import {Inventory} from '../../model/inventory/Inventory';
import {InventoryLineAutocomplete} from '../inventory/InventoryAutocomplete';
import {EditInventoryDialog} from '../inventory/InventoryEditDialog';
import LoadingIndicator from '../ui/LoadingIndicator';
import {PriceFormat, getDecimalFromCurrencyStr, getCurrencyStrFromDecimal} from '../../utils/formatter';
import usePurchaseInvoiceAPI from '../../services/usePurchaseInvoiceAPI';

import {
  ClickAwayListener,
  IconButton,
  InputBase,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  withStyles,
  Tooltip,
} from '@material-ui/core';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import {PencilIcon} from '../../assets/svg/Pencil';

const usePurchaseInvoiceLinesStyles = makeStyles(() => ({
  table: {
    fontFamily: 'Poppins, sans-serif',
    borderCollapse: 'separate',
    borderSpacing: 0,
    '& .MuiTableRow-root': {
      height: 40,
      '& .MuiTableCell-head': {
        fontFamily: 'Poppins, sans-serif',
        color: '#999999',
        fontWeight: '600',
        fontSize: '14px',
        padding: '0px 8px',
        backgroundColor: '#F1F1F1',
        borderBottom: 'none',
        '&:first-child': {
          borderTopLeftRadius: '2px',
        },
        '&:last-child': {
          borderTopRightRadius: '2px',
        },
      },
      '& .MuiTableCell-body': {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '12px',
        color: '#333333',
        padding: '0px 8px',
        borderBottom: '1px solid #EAEAEA50',
        border: '1px solid transparent',
        '&.active': {
          border: '1px solid #1C78AD',
        },
        '&:nth-of-type(even)': {
          backgroundColor: '#FAFAFA',
        },
        '&.cell-input': {
          cursor: 'text',
        },
        '& .input-bordered.Mui-focused': {
          border: '1px solid #1C78AD !important',
        },
      },
    },
  },
  deleteIconButton: {
    padding: 0,
  },
  addLineButton: {
    color: '#1C78AD',
    fontSize: '14px',
    backgroundColor: 'transparent',
    border: 'none',
    fontWeight: 500,
    paddingLeft: '12px',
  },
}));

const StyledInputBase = withStyles({
  root: {
    fontFamily: 'Poppins, sans-serif',
    '& .MuiInputAdornment-root': {
      '& p': {
        color: '#333333',
        fontSize: '12px',
        fontFamily: 'Poppins, sans-serif',
      },
    },
    '& input': {
      fontSize: '12px',
      color: '#333333',
      textAlign: 'right',
      backgroundColor: 'transparent !important',
    },
  },
})(InputBase);

const StyledToolTip = withStyles({
  tooltip: {
    color: '#333333',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 0 4px 0 #00000050',
    borderRadius: '6px',
    fontSize: '10px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    margin: '0px',
    marginBottom: '-10px',
    textAlign: 'center',
  },
  popper: {
    zIndex: 999,
  },
})(Tooltip);

interface IPurchaseInvoiceLines extends FormikProps<ICreatePurchaseInvoice> {
  editMode: boolean;
  setEditMode?: (editMode: boolean) => void;
  searchInput?: string;
  filter?: IPurchaseInvoiceItemsFilter | '';
}

const NUM_COLS = 8;

export const PurchaseInvoiceLines = (props: IPurchaseInvoiceLines) => {
  const classes = usePurchaseInvoiceLinesStyles();

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const {values, setFieldValue, setValues, editMode, setEditMode, searchInput, filter} = props;

  const {addInventoryPurchaseInvoice, isLoading} = usePurchaseInvoiceAPI();

  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [isNavigating, setIsNavigating] = useState<boolean>(true);
  const [isEditing, setIsEditing] = useState<any>(null);

  const [editInventory, setEditInventory] = useState<any>(null);

  const debounceCalculateLineTotal = useCallback(
    debounce(
      (payload: {InventoryList: IPurchaseInvoiceAddInventory[]}, lines: Partial<IPurchaseInvoiceDetailLine>[]) => {
        calculateLineTotal(payload, lines);
      },
      500,
    ),
    [values.ID],
  );

  useEffect(() => {
    if (!isEmpty(values) && !values.Lines?.length) {
      addLineInventory();
    }
  }, [values.Lines]);

  const addLineInventory = () => {
    if (!props?.errors?.Lines) {
      const _Lines = cloneDeep(values.Lines) || [];
      const newLineItem = {
        InventoryID: uniqueId('NewLineItem_'),
        Code: '',
        ShortDescription: '',
        Quantity: 0,
        AdjustedQuantityReceived: '',
        AccountCode: '',
        IsActive: true,
        ExpectedBuyPriceEx: 0,
        CalculatedBuyPriceTax: 0,
        LineTotalEx: 0,
        LineTotalInc: 0,
      } as Partial<IPurchaseInvoiceDetailLine>;
      _Lines.push(newLineItem);
      setFieldValue('Lines', _Lines);
      const _activeIndex = _Lines.length * NUM_COLS;
      if (values.Status === PurchaseInvoiceStatusTypes.Unfinalised && setEditMode) {
        setEditMode(true);
        setIsEditing(newLineItem.InventoryID);
        setIsNavigating(true);
        setActiveIndex(_activeIndex);
      } else {
        setIsNavigating(false);
      }
    }
  };

  const calculateLineTotal = async (
    payload: {InventoryList: IPurchaseInvoiceAddInventory[]},
    lines: Partial<IPurchaseInvoiceDetailLine>[],
  ) => {
    const res = await addInventoryPurchaseInvoice(values.ID, payload);
    if (res.IsSuccess) {
      setValues(Object.assign(values, {Lines: [], ...res.Value}));
    } else {
      const Lines = cloneDeep(lines);
      const index = Lines.findIndex(l => String(l.InventoryID) === String(payload.InventoryList[0].InventoryID));
      Lines[index].HasError = true;
      setFieldValue('Lines', Lines);
      setEditInventory(Lines[index].InventoryID);
    }
  };

  const saveLineInventory = async (selected: Partial<Inventory> | null, _selection: any) => {
    const inventoryItem = {
      InventoryID: selected?.ID,
      Code: selected?.InventoryCode,
      ShortDescription: selected?.ShortDescription,
      Quantity: 1,
      IsActive: true,
    };
    if (Array.isArray(values.Lines)) {
      const index = values.Lines.findIndex(li => li.InventoryID === isEditing);
      const Lines = cloneDeep(values.Lines);
      Lines[index] = {...inventoryItem, Quantity: Lines[index]?.Quantity || 1};
      setFieldValue('Lines', Lines);
      const valuesLength = Lines.length || 0;
      const _activeIndex = (valuesLength - 1) * NUM_COLS + 2;
      debounceCalculateLineTotal(
        {
          InventoryList: [{InventoryID: selected?.ID, Quantity: String(inventoryItem.Quantity)}],
        },
        Lines,
      );
      if (setEditMode) {
        setEditMode(true);
      }
      setIsEditing(inventoryItem?.InventoryID);
      setIsNavigating(true);
      setActiveIndex(_activeIndex);
    }
  };

  const handleColumnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (Array.isArray(values.Lines)) {
      const index = values.Lines.findIndex(li => String(li.InventoryID) === String(isEditing));
      if (Array.isArray(values.Lines)) {
        const Lines = cloneDeep(values.Lines);
        if (e.target.name === 'Quantity') {
          Lines[index][e.target.name as keyof IPurchaseInvoiceDetailLine] =
            parseFloat(e.target.value) < 0 || isNaN(parseFloat(e.target.value))
              ? ('' as any)
              : parseFloat(e.target.value);
        } else {
          Lines[index][e.target.name as keyof IPurchaseInvoiceDetailLine] = Number(e.target.value);
        }
        setFieldValue('Lines', Lines);

        const _inventory: IPurchaseInvoiceAddInventory = {
          InventoryID: Lines[index].InventoryID,
          Quantity: String(Lines[index].Quantity || 0),
        };
        if (e.target.name === 'LineTotalEx' || e.target.name === 'LineTotalInc') {
          _inventory.LineTotal = e.target.value;
        }
        if (e.target.name === 'Quantity' && Lines[index].Quantity) {
          _inventory.Quantity = String(Lines[index].Quantity);
        }
        if (Lines[index].LineID && !Lines[index].HasError && e.target.value && Lines[index].Quantity) {
          debounceCalculateLineTotal({InventoryList: [_inventory]}, Lines);
        }
      }
    }
  };

  const handleKeyDownLastField = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowData: Partial<IPurchaseInvoiceDetailLine>,
  ) => {
    if (e.key === 'Tab' && Array.isArray(values.Lines)) {
      const _Lines = cloneDeep(values.Lines) || [];
      const nextIndex = _Lines.findIndex((item: any) => item.InventoryID === rowData.InventoryID) + 1;
      if (nextIndex >= _Lines.length) {
        // If pressed enter on last line
        addLineInventory();
      } else {
        const nextItem = _Lines[nextIndex];
        const _activeIndex = nextIndex * NUM_COLS;
        setIsEditing(nextItem.InventoryID);
        setIsNavigating(true);
        setActiveIndex(_activeIndex);
      }
    }
  };

  const handleDelete = (row: Partial<IPurchaseInvoiceDetailLine>) => {
    if (row.InventoryID) {
      const lines = (values.Lines || []).filter(line => line.InventoryID !== row.InventoryID);
      setFieldValue('Lines', lines);
      if (!row.HasError) {
        debounceCalculateLineTotal({InventoryList: [{InventoryID: row.InventoryID, IsActive: false}]}, lines);
      }
    }
  };

  const handleGlobalClickAway = () => {
    setActiveIndex(-1);
    setIsNavigating(false);
    setIsEditing(null);
  };

  const handleEditInventory = (row: Partial<IPurchaseInvoiceDetailLine>) => {
    setEditInventory(row.InventoryID);
  };

  const handleEditInventoryClose = (inventory?: IInventory) => {
    if (inventory?.ID) {
      const index = (values.Lines || []).findIndex(li => li.InventoryID === inventory.ID);
      if (index > -1) {
        const Lines = cloneDeep(values.Lines || []);
        debounceCalculateLineTotal(
          {
            InventoryList: [{InventoryID: inventory?.ID, Quantity: String(Lines[index].Quantity || 1)}],
          },
          Lines,
        );
      }
    }
    setEditInventory(false);
  };

  const rows = useMemo(() => values.Lines || [], [values.Lines]);

  const [numRows, numCols] = [rows.length, NUM_COLS];

  const handleMouseDown = useCallback(
    (e: any) => {
      if (tableContainerRef.current && tableContainerRef.current.contains(e.target)) {
        if (e?.target.className.includes && e.target.className.includes('cell-input') && editMode) {
          setIsNavigating(true);
          setIsEditing(e.target.dataset.id);
          setActiveIndex(Number(e.target.dataset.index));
        }
      } else {
        setIsNavigating(false);
      }
    },
    [tableContainerRef, setIsNavigating, editMode],
  );

  const handleKeyDown = useCallback(
    (e: any) => {
      if (isNavigating) {
        const {key} = e;
        switch (key) {
          case 'ArrowUp':
            // Move up a row, subtract num cols from index
            if (!isEditing && activeIndex >= numRows) setActiveIndex(activeIndex - numCols);
            break;
          case 'ArrowDown':
            // Move down a row, add num cols to index
            if (!isEditing && activeIndex < numRows * numCols - numCols) setActiveIndex(activeIndex + numCols);
            break;
          case 'ArrowRight':
            // Move one col right, add one
            if (!isEditing && activeIndex < numRows * numCols - 1) setActiveIndex(activeIndex + 1);
            break;
          case 'ArrowLeft':
            // Move one col left, subtract one
            if (!isEditing && activeIndex > 0) setActiveIndex(activeIndex - 1);
            break;
          case 'Enter':
            if (isEditing) setIsEditing(null);
            else if (isNavigating) setIsEditing(e.target.dataset.id);
            else if (!isEditing) setIsNavigating(true);
            break;
          case 'Escape':
            // Stop navigating
            if (isEditing) setIsEditing(false);
            else if (isNavigating) setIsNavigating(false);
            break;
          default:
            break;
        }
      }
    },
    [activeIndex, isNavigating, isEditing, numRows, numCols],
  );

  useEffect(() => {
    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('keydown', handleKeyDown as any);

    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('keydown', handleKeyDown as any);
    };
  }, [handleMouseDown, handleKeyDown]);

  const onIndexChange = useCallback(() => {
    if (activeIndex >= 0 && activeIndex < numRows * numCols) {
      const inputRef = inputRefs.current[activeIndex];

      if (inputRef) {
        if (isEditing) {
          setTimeout(() => {
            inputRef?.focus && inputRef.focus();
          }, 100);
        } else {
          inputRef?.blur && inputRef.blur();
        }
      }
    }
  }, [activeIndex, isEditing, inputRefs, numRows, numCols]);

  useEffect(onIndexChange, [activeIndex, onIndexChange]);

  const onIsNavigatingChange = useCallback(() => {
    if (!isNavigating) {
      // setActiveIndex(-1);
    } else if (activeIndex < 0 && editMode) {
      // setActiveIndex(0);
    }
  }, [isNavigating, setActiveIndex, activeIndex, editMode]);

  useEffect(onIsNavigatingChange, [isNavigating, onIsNavigatingChange]);

  const lines = useMemo(
    () =>
      (values.Lines || [])
        .filter(line => line.IsActive)
        .filter(
          line =>
            line?.ShortDescription?.toLocaleLowerCase().includes(searchInput || '') ||
            line?.InventoryID?.toString()?.includes(searchInput || ''),
        )
        .filter(line => {
          switch (filter) {
            case IPurchaseInvoiceItemsFilter.MissingAccount: {
              if (!line.AccountCode) {
                return true;
              }
              return false;
            }
            case IPurchaseInvoiceItemsFilter.HideCompleted: {
              return false;
            }
            case IPurchaseInvoiceItemsFilter.VaryingCost: {
              if (line.CostPriceEx !== line.ExpectedBuyPriceEx) {
                return true;
              }
              return false;
            }
            default:
              return true;
          }
        }),
    [searchInput, values.Lines, filter],
  );

  return (
    <>
      <EditInventoryDialog
        open={Boolean(editInventory)}
        onClose={handleEditInventoryClose}
        inventoryId={editInventory}
      />
      <ClickAwayListener onClickAway={handleGlobalClickAway}>
        <div className="relative my-4 flex flex-col" ref={tableContainerRef}>
          <LoadingIndicator isLoading={isLoading} size="md" color="hsl(var(--primary))" />
          <Table className={clsx(classes.table, 'overflow-y-auto')}>
            <TableHead>
              <TableRow>
                <TableCell key="Code" width="10%">
                  Code
                </TableCell>
                <TableCell key="ShortDescription" width="30%">
                  Description
                </TableCell>
                <TableCell key="Quantity" align="center" width="150px">
                  Qty received
                </TableCell>
                <TableCell key="AccountCode" align="center">
                  Account
                </TableCell>
                <TableCell key="ExpectedBuyPriceEx" align="center">
                  Expected unit price
                </TableCell>
                <TableCell key="CalculatedBuyPriceTax" align="right" width="150px">
                  Est. unit tax
                </TableCell>
                <TableCell key="LineTotal" align="right" width="150px">
                  {values.PriceEntryMode === PurchaseInvoicePriceEntryType.TaxExclusive ? 'Total ex' : 'Total inc'}
                </TableCell>
                {editMode ? <TableCell key="Delete" align="center" width={10} /> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {lines.map((row, rIdx) => {
                const editing = String(isEditing) === String(row.InventoryID);
                const index = rIdx * numCols;
                const Lines = values.Lines || [];
                return (
                  <StyledToolTip
                    key={row.InventoryID}
                    title={
                      row.HasError ? 'We are missing some information on this product, please click the edit icon.' : ''
                    }
                    placement="top"
                  >
                    <TableRow
                      tabIndex={-1}
                      key={row.InventoryID}
                      className={clsx('relative', {
                        'shadow-[0_0_0_1px_rgba(197,93,68,0.99)]': row.HasError,
                      })}
                      style={{zIndex: activeIndex === index + 0 ? `${999 - rIdx}` : '99'}}
                    >
                      <TableCell
                        key={`${row.InventoryID}_${index + 0}`}
                        data-index={index + 0}
                        data-id={row.InventoryID}
                        className={clsx('cell-input', {active: activeIndex === index + 0})}
                        style={{color: row.HasError ? '#C55D44' : !row.Code && !editing ? '#999999' : '#333333'}}
                      >
                        {!editing ? row.Code || 'Code' : null}
                        {editing ? (
                          <InventoryLineAutocomplete
                            index={index + 0}
                            inputRefs={inputRefs}
                            id={`Code-${row.InventoryID}`}
                            searchCodeOnly={true}
                            alreadyFilled={Lines.map(a => a.InventoryID)}
                            onFocus={() => setActiveIndex(index + 0)}
                            selected={
                              row?.InventoryID && row?.ShortDescription
                                ? {
                                    InventoryCode: row?.Code || '',
                                    ShortDescription: row?.ShortDescription,
                                  }
                                : null
                            }
                            onSuggestionSelected={(e, selected) => saveLineInventory(selected.suggestion, selected)}
                          />
                        ) : null}
                      </TableCell>
                      <TableCell
                        key={`${row.InventoryID}_${index + 1}`}
                        data-index={index + 1}
                        data-id={row.InventoryID}
                        className={clsx('cell-input', {active: activeIndex === index + 1})}
                        style={{
                          color: row.HasError ? '#C55D44' : !row.ShortDescription && !editing ? '#999999' : '#333333',
                        }}
                      >
                        {!editing ? row.ShortDescription || 'Description' : null}
                        {editing ? (
                          <InventoryLineAutocomplete
                            index={index + 1}
                            inputRefs={inputRefs}
                            id={`ShortDescription-${row.InventoryID}`}
                            alreadyFilled={Lines.map(a => a.InventoryID)}
                            onFocus={() => setActiveIndex(index + 1)}
                            selected={
                              row?.InventoryID && row?.ShortDescription
                                ? {
                                    InventoryCode: row?.Code || '',
                                    ShortDescription: row?.ShortDescription,
                                  }
                                : null
                            }
                            onSuggestionSelected={(e, selected) => saveLineInventory(selected.suggestion, selected)}
                          />
                        ) : null}
                      </TableCell>
                      <TableCell
                        key={`${row.InventoryID}_${index + 2}`}
                        data-index={index + 2}
                        data-id={row.InventoryID}
                        className={clsx('cell-input', {active: activeIndex === index + 2})}
                        align="center"
                        style={{
                          color: row.HasError ? '#C55D44' : !row.Quantity && !editing ? '#999999' : '#333333',
                        }}
                      >
                        {editing ? (
                          <StyledInputBase
                            fullWidth
                            id={`Quantity-${row.InventoryID}`}
                            name="Quantity"
                            type="number"
                            placeholder="0"
                            inputRef={el => (inputRefs.current[index + 2] = el)}
                            onFocus={() => setActiveIndex(index + 2)}
                            value={row.Quantity}
                            onChange={e => !isLoading && handleColumnChange(e)}
                            inputProps={{
                              'data-autoid': `txtQuantity${index}`,
                              'data-id': row.InventoryID,
                              style: {textAlign: 'center'},
                            }}
                          />
                        ) : (
                          row.Quantity || 'Quantity'
                        )}
                      </TableCell>
                      <TableCell
                        key={`${row.InventoryID}_${index + 3}`}
                        data-index={index + 3}
                        data-id={row.InventoryID}
                        className={clsx({active: activeIndex === index + 3})}
                        align="center"
                        style={{color: row.HasError ? '#C55D44' : !row.AccountCode ? '#999999' : '#333333'}}
                      >
                        {row.AccountCode || 'Account'}
                      </TableCell>
                      <TableCell
                        key={`${row.InventoryID}_${index + 4}`}
                        data-index={index + 4}
                        data-id={row.InventoryID}
                        className={clsx({active: activeIndex === index + 4})}
                        align="center"
                        style={{color: row.HasError ? '#C55D44' : !row.ExpectedBuyPriceEx ? '#999999' : '#333333'}}
                      >
                        {PriceFormat(row.ExpectedBuyPriceEx) || 'Expected unit price'}
                      </TableCell>
                      <TableCell
                        key={`${row.InventoryID}_${index + 5}`}
                        data-index={index + 5}
                        data-id={row.InventoryID}
                        className={clsx({active: activeIndex === index + 5})}
                        align="right"
                        style={{
                          color: row.HasError ? '#C55D44' : !row.CalculatedBuyPriceTax ? '#999999' : '#333333',
                        }}
                      >
                        {PriceFormat(row.CalculatedBuyPriceTax) || 'Est. unit tax'}
                      </TableCell>
                      <TableCell
                        key={`${row.InventoryID}_${index + 6}`}
                        data-index={index + 6}
                        data-id={row.InventoryID}
                        className={clsx('cell-input', {active: activeIndex === index + 6})}
                        align="right"
                        style={{
                          color: row.HasError
                            ? '#C55D44'
                            : !(row.LineTotalEx || row.LineTotalInc)
                              ? '#999999'
                              : '#333333',
                        }}
                      >
                        {!editing && values.PriceEntryMode === PurchaseInvoicePriceEntryType.TaxExclusive
                          ? PriceFormat(row.LineTotalEx)
                          : null}
                        {!editing && values.PriceEntryMode === PurchaseInvoicePriceEntryType.TaxInclusive
                          ? PriceFormat(row.LineTotalInc)
                          : null}
                        {editing ? (
                          <StyledInputBase
                            fullWidth
                            key={`Input-${index + 6}`}
                            id={`LineTotal-${row.InventoryID}`}
                            name={
                              values.PriceEntryMode === PurchaseInvoicePriceEntryType.TaxExclusive
                                ? 'LineTotalEx'
                                : 'LineTotalInc'
                            }
                            disabled={isLoading}
                            placeholder="0"
                            inputRef={el => (inputRefs.current[index + 6] = el)}
                            onFocus={() => setActiveIndex(index + 6)}
                            value={
                              values.PriceEntryMode === PurchaseInvoicePriceEntryType.TaxExclusive
                                ? getCurrencyStrFromDecimal(String(row.LineTotalEx || ''))
                                : getCurrencyStrFromDecimal(String(row.LineTotalInc || ''))
                            }
                            onKeyDown={e => handleKeyDownLastField(e, row)}
                            onChange={e => {
                              e.target.value = getDecimalFromCurrencyStr(e.target.value || '');
                              handleColumnChange(e);
                            }}
                            inputProps={{'data-autoid': `txtLineTotal${index}`, 'data-id': row.InventoryID}}
                          />
                        ) : null}
                      </TableCell>
                      {editMode ? (
                        <TableCell align="center">
                          <div className="flex gap-1">
                            {row.HasError ? (
                              <IconButton
                                disableRipple
                                className={classes.deleteIconButton}
                                onClick={() => handleEditInventory(row)}
                                data-autoid={`btnEditOrderLine${rIdx}`}
                              >
                                <PencilIcon fontSize="small" fill="#C55D44" style={{color: '#C55D44'}} />
                              </IconButton>
                            ) : null}
                            <IconButton
                              disableRipple
                              className={classes.deleteIconButton}
                              onClick={() => handleDelete(row)}
                              data-autoid={`btnDeleteOrderLine${rIdx}`}
                            >
                              <DeleteOutlineRoundedIcon fontSize="small" style={{color: '#D8D8D8'}} />
                            </IconButton>
                          </div>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  </StyledToolTip>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </ClickAwayListener>
    </>
  );
};

export const PurchaseInvoiceSubTotal = (props: IPurchaseInvoiceLines) => {
  const classes = usePurchaseInvoiceLinesStyles();

  const {values, setFieldValue, editMode, setEditMode} = props;

  const handleAddInventory = () => {
    if (!props?.errors?.Lines) {
      const _Lines = cloneDeep(values.Lines) || [];
      const newLineItem = {
        InventoryID: uniqueId('NewLineItem_'),
        Code: '',
        ShortDescription: '',
        Quantity: 0,
        AdjustedQuantityReceived: '',
        AccountCode: '',
        IsActive: true,
        ExpectedBuyPriceEx: 0,
        CalculatedBuyPriceTax: 0,
        LineTotalExString: PriceFormat(0),
        LineTotalIncString: PriceFormat(0),
      } as Partial<IPurchaseInvoiceDetailLine>;
      _Lines.push(newLineItem);
      setFieldValue('Lines', _Lines);
      if (setEditMode) {
        setEditMode(true);
      }
    }
  };

  let differenceSubtotal = '';
  let differenceTax = '';
  let differenceTotal = '';
  // Subtotal
  const subtotalDiff = Number(values.TotalEx || 0) - Number(values.ExpectedTotalEx || 0);

  if (subtotalDiff > 0) {
    differenceSubtotal = `Over by ${currency(Math.abs(subtotalDiff)).format()}`;
  } else if (subtotalDiff < 0) {
    differenceSubtotal = `Under by ${currency(Math.abs(subtotalDiff)).format()}`;
  }

  // Tax
  const taxDiff = Number(values.TotalTax || 0) - Number(values.ExpectedTotalTax || 0);

  if (taxDiff > 0) {
    differenceTax = `Over by ${currency(Math.abs(taxDiff)).format()}`;
  } else if (taxDiff < 0) {
    differenceTax = `Under by ${currency(Math.abs(taxDiff)).format()}`;
  }

  // Total
  let totalDiff = Number(values.TotalInc || 0) - Number(values.ExpectedTotalInc || 0);

  if (totalDiff > 0) {
    differenceTotal = `Over by ${currency(Math.abs(totalDiff)).format()}`;
  } else if (totalDiff < 0) {
    differenceTotal = `Under by ${currency(Math.abs(totalDiff)).format()}`;
  }

  return (
    <Table className={classes.table}>
      <TableBody>
        <TableRow id="edit">
          <TableCell align="left" width="150px" style={{borderBottom: 'none'}}>
            {editMode ? (
              <button
                type="button"
                className={classes.addLineButton}
                onClick={handleAddInventory}
                data-autoid="btnAddInvoiceLine"
              >
                Add Line Items
              </button>
            ) : null}
          </TableCell>
          <TableCell
            align="center"
            colSpan={2}
            rowSpan={3}
            style={{backgroundColor: 'transparent', borderBottom: 'none'}}
          >
            {values.HasTotalDiscrepancy && editMode ? (
              <p className="m-auto max-w-[400px] rounded bg-[#D3E5EF50] px-2 py-1 text-center text-xs text-spenda-primarytext">
                You are unable to approve an invoice until the invoice totals match. Either your invoice lines have a
                quantity or price discrepancy or the invoice total you entered is incorrect. Click on the Invoice
                Details option to change you invoice total.
              </p>
            ) : null}
          </TableCell>
          <TableCell align="right" width="150px" className="relative bg-[#fafafa]">
            {subtotalDiff && editMode ? (
              <span className="absolute right-[73px] flex items-center whitespace-nowrap text-[#C55D44]">
                {differenceSubtotal} <div className="ml-2 h-px w-[46px] bg-[#C55D44]" />
              </span>
            ) : null}
            Subtotal
          </TableCell>
          <TableCell align="right" width="150px" className="!bg-transparent">
            {PriceFormat(values.TotalEx)}
          </TableCell>
          {editMode ? <TableCell width={40} /> : null}
        </TableRow>
        <TableRow id="tax">
          <TableCell style={{backgroundColor: 'transparent', borderBottom: 'none'}} />
          <TableCell align="right" width="150px" className="relative">
            {taxDiff && editMode ? (
              <span className="absolute right-[73px] flex items-center whitespace-nowrap text-[#C55D44]">
                {differenceTax} <div className="ml-2 h-px w-[46px] bg-[#C55D44]" />
              </span>
            ) : null}
            Tax
          </TableCell>
          <TableCell align="right" width="150px">
            {PriceFormat(values.TotalTax)}
          </TableCell>
          {editMode ? <TableCell width={40} style={{backgroundColor: 'transparent'}} /> : null}
        </TableRow>
        <TableRow id="total">
          <TableCell style={{backgroundColor: 'transparent', borderBottom: 'none'}} />
          <TableCell align="right" width="150px" className="relative font-semibold">
            {totalDiff && editMode ? (
              <span className="absolute right-[73px] flex items-center whitespace-nowrap font-semibold text-[#C55D44]">
                {differenceTotal} <div className="ml-2 h-px w-[46px] bg-[#C55D44]" />
              </span>
            ) : null}
            <b>Total</b>
          </TableCell>
          <TableCell align="right" width="150px" className="font-semibold">
            <b>{PriceFormat(values.TotalInc)}</b>
          </TableCell>
          {editMode ? <TableCell width={40} style={{backgroundColor: 'transparent'}} /> : null}
        </TableRow>
      </TableBody>
    </Table>
  );
};
