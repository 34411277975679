import React, {SetStateAction, useEffect, useState} from 'react';
import {Button, Checkbox, IconButton, Typography} from 'spenda-ui-react';
import {useHistory} from 'react-router-dom';

import {BackIcon} from '../../../../assets/svg';
import {AlertDialog} from '../../../../components/dialog/AlertDialogSlideV2';
import {AUTH_INVENTORY_PRODUCTS_LIST} from '../../../../routes/InventoryRoutes';
import {UploadProductsDialog} from '../../../../components/dialog/UploadProductsDialog';
import {useInventoryContext} from '../../../../context/inventory-context/InventoryContext';
import _ from 'lodash';
import {useInventoryAPI} from '../../../../services/useInventoryAPI';

interface IStagingAreaHeaderProps {
  selectedInventoryRows: number[];
  setSelectedInventoryRows: React.Dispatch<SetStateAction<number[]>>;
  setIsCommiting: React.Dispatch<SetStateAction<boolean>>;
}

interface FilterCheckboxProps {
  dataAutoid: string;
  label: string;
  name: string;
  value: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FilterCheckbox: React.FC<FilterCheckboxProps> = props => (
  <Checkbox
    {...props}
    labelProps={{className: 'text-sm'}}
    containerProps={{className: '!py-2'}}
    className={
      'h-5 w-5 border-primary bg-white transition-all checked:border-primary checked:bg-primary hover:before:opacity-0'
    }
  />
);

export const StagingAreaHeader: React.FC<IStagingAreaHeaderProps> = ({
  selectedInventoryRows,
  setSelectedInventoryRows,
  setIsCommiting,
}) => {
  const [filters, setFilters] = useState({all: true, valid: true, errors: true, warnings: true});
  const [onRemoveProducts, setOnRemoveProducts] = useState(false);
  const [onNavigateAway, setOnNavigateAway] = useState(false);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [isRemovingProducts, setIsRemovingProducts] = useState(false);

  const history = useHistory();
  const {deteteStagingData, commitProducts, deteteStagingRows, isLoading} = useInventoryAPI();
  const {
    uploadTypeId,
    totalStagingInventoryCount,
    invalidStagingInventoryCount,
    validStagingInventoryCount,
    warningsStagingInventoryCount,
    updateFilteredStagingData,
    filterStagingRows,
  } = useInventoryContext();

  const handleBack = async () => {
    // delete staging table data
    await deteteStagingData(uploadTypeId);
    history.push(AUTH_INVENTORY_PRODUCTS_LIST);
  };

  const handleFilterChange = (filterKey: string, checked: boolean) => {
    const filterVal = _.cloneDeep(filters);
    if (filterKey === 'all') {
      if (checked) {
        filterVal.all = true;
        filterVal.errors = true;
        filterVal.valid = true;
        filterVal.warnings = true;
      } else {
        filterVal.all = false;
        filterVal.errors = false;
        filterVal.valid = false;
        filterVal.warnings = false;
      }
    }
    setFilters({...filterVal, [filterKey]: checked});
  };

  useEffect(() => {
    if (filters.errors && filters.valid && filters.warnings && !filters.all) {
      setFilters(prevFilters => ({...prevFilters, all: true}));
    } else if (filters.all && (!filters.errors || !filters.valid || !filters.warnings)) {
      setFilters(prevFilters => ({...prevFilters, all: false}));
    }
    updateFilteredStagingData(filters);
  }, [filters]);

  const handleCommitProducts = async () => {
    try {
      setIsCommiting(true);
      await commitProducts();
      history.push(AUTH_INVENTORY_PRODUCTS_LIST);
    } finally {
      setIsCommiting(false);
    }
  };

  return (
    <>
      <header className="mb-2 flex justify-between border-b border-[#ECECEC] pb-2.5">
        <div className="flex items-center justify-start gap-2.5">
          <div className="cursor-pointer" onClick={() => setOnNavigateAway(true)}>
            <IconButton variant="outlined" ripple={false}>
              <BackIcon />
            </IconButton>
          </div>
          <div className="flex flex-col">
            <Typography variant="h2" className="font-light">
              Staging Area
            </Typography>
            <Typography variant="xsmall" className="text-spenda-labeltext">
              {totalStagingInventoryCount} products uploaded, {invalidStagingInventoryCount} issues found
            </Typography>
          </div>
        </div>
        <div className="flex items-center gap-2.5">
          <Button variant="outlined" onClick={() => setShowUploadDialog(true)} disabled={isLoading}>
            Re-upload
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setOnRemoveProducts(true)}
            disabled={!selectedInventoryRows.length || isLoading}
          >
            Remove Selected Products
          </Button>
          <Button
            variant="filled"
            disabled={invalidStagingInventoryCount > 0 || isLoading}
            onClick={handleCommitProducts}
          >
            Commit Products
          </Button>
        </div>
      </header>
      <div className="mt-2">
        <FilterCheckbox
          dataAutoid="chkAllProducts"
          label={`All Products (${totalStagingInventoryCount})`}
          name="all"
          value="all"
          checked={filters.all}
          onChange={e => handleFilterChange('all', e.target.checked)}
        />
        <FilterCheckbox
          dataAutoid="chkValidProducts"
          label={`Valid (${validStagingInventoryCount})`}
          name="valid"
          value="valid"
          checked={filters.valid}
          onChange={e => handleFilterChange('valid', e.target.checked)}
        />
        <FilterCheckbox
          dataAutoid="chkErrors"
          label={`Errors (${invalidStagingInventoryCount})`}
          name="errors"
          value="errors"
          checked={filters.errors}
          onChange={e => handleFilterChange('errors', e.target.checked)}
        />
        <FilterCheckbox
          dataAutoid="chkWarnings"
          label={`Warnings (${warningsStagingInventoryCount})`}
          name="warnings"
          value="warnings"
          checked={filters.warnings}
          onChange={e => handleFilterChange('warnings', e.target.checked)}
        />
      </div>
      {onRemoveProducts && (
        <AlertDialog
          id="removeProductsPopup"
          title="Remove selected products"
          size="xs"
          content="Are you sure you would like to continue and remove the selected products from the staging area? "
          contentClass="text-[12px] mx-14 font-medium"
          headingTextSize="h2"
          actions={[
            {
              label: 'No',
              disabled: isRemovingProducts,
              action: () => setOnRemoveProducts(false),
              variant: 'outlined',
            },
            {
              label: 'Yes',
              loading: isRemovingProducts,
              action: async () => {
                try {
                  setIsRemovingProducts(true);
                  await deteteStagingRows(selectedInventoryRows);
                  filterStagingRows(selectedInventoryRows);
                  setSelectedInventoryRows([]);
                  setIsRemovingProducts(false);
                } finally {
                  setOnRemoveProducts(false);
                  setIsRemovingProducts(false);
                }
              },
            },
          ]}
        />
      )}
      {onNavigateAway && (
        <AlertDialog
          id="navigateAwayPopup"
          title="Unsaved changes"
          size="sm"
          content="Are you sure you would like to navigate away from this screen? you currently have unsaved changes. "
          contentClass="text-[12px] mx-20 font-medium"
          headingTextSize="h2"
          actions={[
            {
              label: 'Navigate Away',
              action: () => handleBack(),
              loading: isLoading,
              variant: 'outlined',
            },
            {
              label: 'Stay on this Screen',
              disabled: isLoading,
              action: () => setOnNavigateAway(false),
            },
          ]}
        />
      )}
      {showUploadDialog && (
        <UploadProductsDialog
          handleCancel={() => setShowUploadDialog(false)}
          handleContinue={() => setShowUploadDialog(false)}
        />
      )}
    </>
  );
};
