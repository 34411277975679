import React, {useState, useCallback} from 'react';
import {css} from 'glamor';
import {debounce} from 'lodash';
import {TextField, InputAdornment, IconButton, makeStyles} from '@material-ui/core';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';

import IconSearch from '../../assets/svg/IconSearch';
import LoadingIndicator from '../ui/LoadingIndicator';

interface ISearchInput {
  inputRef?: React.RefObject<HTMLDivElement>;
  loading?: boolean;
  placeholder?: string;
  searchFilter?: any;
  handleChange: (search: string) => void;
  handleIconSearch?: () => void;
  handleSearchClear?: () => void;
}

export const SSearchInput = (props: ISearchInput) => {
  const [searchInput, setSearchInput] = useState<string>('');

  const debouncedSave = useCallback(
    debounce((newValue: string) => {
      props.handleChange(newValue);
    }, 750),
    [props.searchFilter],
  );

  const change = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setSearchInput(e.target.value);
    debouncedSave(e.target.value);
  };

  const handleClear = () => {
    setSearchInput('');
    props.handleChange('');
    if (props?.handleSearchClear) props.handleSearchClear();
  };

  const useStyles = makeStyles({
    root: {
      border: 'transparent !important',
      '& .MuiInputBase-input': {
        color: '#000',
        textOverflow: 'ellipsis !important',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
      '& .MuiInputAdornment-positionStart': {
        position: 'relative',
        marginLeft: '15px',
        marginRight: '15px',
      },
      '& .Mui-error.MuiFormHelperText-filled': {
        bottom: '0',
        margin: '0',
        left: '10%',
        top: '4em',
        position: 'absolute',
        backgroundColor: 'white',
        height: '30px',
        textAlign: 'center',
        padding: '5px',
        borderRadius: '5px',
        boxShadow: '0px 0px 4px #000',
        '&:after, &:before': {
          bottom: '100%',
          left: '50%',
          border: 'solid #ffffff',
          content: "''",
          height: 0,
          width: 0,
          position: 'absolute',
          pointerEvents: ' none',
        },
        '&:after': {
          borderColor: 'transparent',
          borderBottomColor: '#ffffff',
          borderWidth: '7px',
          marginLeft: '-10px',
        },
        '&:before': {
          borderColor: 'transparent',
          borderBottomColor: '#ababab',
          borderWidth: '8px',
          marginLeft: '-11px',
        },
      },
      '& .MuiInput-underline.Mui-error:after': {
        borderColor: 'transparent',
      },
      '& .MuiInput-underline:after , & :before': {
        content: 'none',
      },
      '& .MuiInputBase-root': {
        height: '100%',
      },
      '& .MuiInputBase-root.Mui-focused': {
        '& .MuiIconButton-label': {
          color: 'hsl(var(--primary))',
        },
        '& button#go-btn-input': {
          color: 'black',
          backgroundColor: '#fff',
          width: '33px',
          height: '34px',
          fontSize: '18px',
          marginRight: '5px',
        },
        '& button#clear-btn-input': {
          color: 'black',
        },
      },
    },
  });

  const classes = useStyles();
  const hasError = false;

  return (
    <TextField
      data-autoid="txtSearchInput"
      inputRef={props.inputRef}
      classes={{root: classes.root}}
      type="text"
      name="searchText"
      id="search-text-input"
      helperText={''}
      error={hasError}
      onChange={change}
      value={searchInput}
      placeholder={props.placeholder}
      style={{
        borderRadius: 4,
        border: hasError ? '1px solid #f44336' : '1px solid #0092a8',
        color: '#0092a8',
        flex: '1',
      }}
      autoComplete="off"
      InputProps={{
        startAdornment: (
          <InputAdornment className="cursor-pointer" onClick={props.handleIconSearch} position="start">
            <IconSearch />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <LoadingIndicator
              isLoading={props.loading}
              size="sm"
              color="hsl(var(--dashbg))"
              position={{right: '12px'}}
            />
            {searchInput && !props.loading && (
              <IconButton
                type="button"
                id="clear-btn-input"
                aria-label="clear text string"
                onClick={handleClear}
                className="absolute flex text-xs"
                {...css({right: '0.25em', outline: 'none !important'})}
              >
                <ClearOutlinedIcon />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};
