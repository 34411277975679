import React, {useContext, useEffect, useState} from 'react';
import {Layout} from '../../components/layout/Layout';
import {Formik, Form} from 'formik';
import {css} from 'glamor';
import {pick} from 'lodash';
import {PaymentBatchPermissionType, PaymentBatchStatusTypes} from '../../model/constants/Constants';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {BatchList} from '../../components/purchasing/BatchList';
import {BatchTimeline} from '../../components/purchasing/BatchTimeline';
import {SearchInput} from '../../components/form/InvoiceSearchForm';
import {usePaymentBatchAPI} from '../../services/usePaymentBatchAPI';
import {Popper, Grow, ClickAwayListener} from '@material-ui/core';
import {usePopupState, bindToggle, bindPopper} from 'material-ui-popup-state/hooks';
import {IPaymentBatchForApproval} from '../../model/payment-batch/PaymentBatch';
import searchIcon from '../../assets/svg/accounts-payable/ap-search-icon.svg';
import APContext from '../../context/ap-context/APContext';

const listContainer = css({
  boxShadow: '0px 0px 6px 0px #D3E5EF',
  fontFamily: 'poppins',
});

const borderBottom = css({
  borderBottom: '1px solid #D8D8D8',
});

export const APBatchApproval = () => {
  const [paymentBatches, setPaymentBatches] = useState<IPaymentBatchForApproval[]>([]);
  const [searchString, setSearchString] = useState<string>('');
  const {userRoleBatchPermissions} = useContext(APContext);
  const {
    getPaymentBatchesForApproval,
    approvePaymentBatchForPayment,
    isLoading: paymentBatchLoader,
  } = usePaymentBatchAPI();

  const isLoading = paymentBatchLoader;

  const searchInputPopup = usePopupState({variant: 'popover', popupId: 'searchInputPopup'});

  useEffect(() => {
    if (userRoleBatchPermissions && userRoleBatchPermissions.includes(PaymentBatchPermissionType.ApproveBatch)) {
      fetchBatchesForApproval();
    }
  }, []);

  const fetchBatchesForApproval = async () => {
    const approvalBatches = await getPaymentBatchesForApproval();
    setPaymentBatches(approvalBatches);
  };

  const approveBatch = async (batch: IPaymentBatchForApproval) => {
    await approvePaymentBatchForPayment(batch.accountsPayableBatchID);
    fetchBatchesForApproval();
  };

  const approvePayBatch = async (batch: IPaymentBatchForApproval) => {
    await approvePaymentBatchForPayment(batch.accountsPayableBatchID);
    fetchBatchesForApproval();
  };

  const payBatch = async (batch: IPaymentBatchForApproval) => {
    if (batch.status !== PaymentBatchStatusTypes.PaymentApproved) {
      await approvePaymentBatchForPayment(batch.accountsPayableBatchID);
      fetchBatchesForApproval();
    }
  };

  const onSubmitSearch = (values: {searchText: string}): Promise<void> => {
    setSearchString(values.searchText);
    return Promise.resolve();
  };

  const handleClickAway = () => {
    searchInputPopup.close();
  };

  const batchListPanel = (
    <div className={`flex h-full flex-col`}>
      <div className={`${listContainer} invoicesList h-full rounded rounded bg-white p-2`}>
        <div
          className={`flex items-center justify-between px-4 py-2 text-lg font-light text-spenda-primarytext ${borderBottom}`}
        >
          <h2>{'Requests for approval'}</h2>
          <div className={`flex`}>
            <Formik initialValues={{searchText: '' as string}} onSubmit={onSubmitSearch} validateOnChange={false}>
              {formik => (
                <Form translate="yes">
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <div>
                      <div className="m-auto" {...bindToggle(searchInputPopup)}>
                        <img src={searchIcon} className="cursor-pointer" data-autoid="imgSearchIcon" />
                      </div>
                      <Popper {...bindPopper(searchInputPopup)} disablePortal={false} placement="left" transition>
                        {({TransitionProps}) => (
                          <Grow {...TransitionProps} timeout={250}>
                            <SearchInput
                              placeholder={'Search'}
                              {...formik}
                              onSubmit={formik.handleSubmit}
                              isAccountsPayable={true}
                            ></SearchInput>
                          </Grow>
                        )}
                      </Popper>
                    </div>
                  </ClickAwayListener>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className={`relative mt-2 flex h-full items-start justify-center bg-white`}>
          <BatchList
            isLoading={isLoading}
            batchList={
              searchString
                ? paymentBatches.filter(obj =>
                    Object.values(
                      pick(obj, ['approvedByUserName', 'dueDate', 'name', 'paymentAmount', 'refNumber', 'status']),
                    ).some(val => (val + '').toLocaleLowerCase().includes(searchString.toLocaleLowerCase())),
                  )
                : paymentBatches
            }
            approveBatch={approveBatch}
            approvePayBatch={approvePayBatch}
            payBatch={payBatch}
          />
        </div>
      </div>
    </div>
  );

  const noPermissionsPanel = (
    <div
      className={`${css({
        boxShadow: '0px 0px 6px 0px #D3E5EF',
        height: 'calc(100% - 1rem)',
      })} relative mt-3 flex h-full items-center justify-center rounded bg-white`}
    >
      <span className="flex justify-center font-poppins text-lg font-medium text-spenda-primarytext">
        No access to this module, please contact your primary admin user".
      </span>
    </div>
  );

  const apMainPanel = <BatchTimeline />;

  return (
    <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
      {!isLoading &&
      userRoleBatchPermissions &&
      userRoleBatchPermissions.includes(PaymentBatchPermissionType.ApproveBatch) ? (
        <Layout leftPanel={batchListPanel} mainPanel={apMainPanel} splitWidthType={'AR'} />
      ) : (
        <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
          <Layout
            splitWidthType={4}
            leftPanel={
              isLoading ? (
                <LoadingIndicator isLoading={true} size="md" color="hsl(var(--primary))" />
              ) : (
                noPermissionsPanel
              )
            }
          />
        </div>
      )}
    </div>
  );
};
