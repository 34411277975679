/* eslint-disable no-console */
import * as Yup from 'yup';
import {Formik, FormikProps, useFormikContext} from 'formik';
import React, {useMemo, useRef, useState} from 'react';
import {IconButton, Input} from 'spenda-ui-react';

import {Toast} from '../../utils/Toast';
import {UploadColour} from '../../assets/svg';
import {MediaUploader} from '../inputs/MediaUploader';
import {CloseCross} from '../../assets/svg/CloseCross';
import useVariantAPI from '../../services/useVariantAPI';
import {WidgetDeleteLogo} from '../../assets/svg/WidgetDeleteLogo';
import {AlertDialog, AlertDialogSlideV2, IDialogActionV2} from './AlertDialogSlideV2';
import {IAddProductVariantsPayload, IAddProductVariation, IAddProductVariationOptions} from '../../model/variants';
import LoadingIndicator from '../ui/LoadingIndicator';
import ColorPickerInput from '../inputs/ColorPickerInput';
import {useInventoryAPI} from '../../services/useInventoryAPI';
import {Inventory} from '../../model/inventory/Inventory';
import {WarningMessageForNotAbleToUnlink, WarningMessageForUnlink} from './AddVariantDialog';

interface IAddAttributeDialogProps {
  open?: boolean;
  handleClose: () => void;
  variationDetails?: IAddProductVariation | null;
  variationOptionsDetails?: IAddProductVariationOptions | null;
  productID?: string;
  setOpenMasterVariantDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IAttributeValues {
  attributeName: string;
  abbreviation: string;
  exSellPrice: string;
  incSellPrice: string;
  colour: string;
  mediaFileID?: number | null;
  mediaFileUrl?: string;
  mediaFileThumbnailUrl?: string;
  isActive: boolean;
  variationOptionID: number | null;
}

const validationSchema = Yup.object({
  attributeName: Yup.string().required('Attribute Name is required'),
  abbreviation: Yup.string().required('Abbreviation is required').max(3, 'Reached char limit'),
});

export const UploadedImageView = ({
  url,
  name,
  onDelete,
  containerClassName,
}: {
  url: string;
  name: string;
  containerClassName?: string;
  onDelete?: () => void;
}) => {
  return (
    <div className={`w-1/5 space-y-1 rounded-md ${containerClassName ?? ''}`}>
      <div className="relative flex w-full flex-col">
        <div className="absolute inset-0 flex items-center justify-center rounded-md bg-[#00000040] opacity-0 hover:opacity-100">
          {onDelete && (
            <div className="flex space-x-3">
              <IconButton
                className="h-[30px] rounded-full bg-black-300 !fill-[#fff] opacity-90"
                color="error"
                name="DeleteImage"
                onClick={onDelete}
                size="sm"
                variant="text"
              >
                <WidgetDeleteLogo data-autoid="DeleteIcon" />
              </IconButton>
            </div>
          )}
        </div>
        <img loading="lazy" src={url} alt={name} className="h-full !w-auto rounded-md object-cover object-center" />
      </div>
    </div>
  );
};

enum ButtonAction {
  SaveAndClose = 'SaveAndClose',
  SaveAndAdd = 'SaveAndAdd',
}

export const AddAttributeDialog: React.FunctionComponent<IAddAttributeDialogProps> = ({
  open,
  handleClose,
  productID,
  variationDetails,
  variationOptionsDetails,
  setOpenMasterVariantDialog,
}: IAddAttributeDialogProps) => {
  const [onDeleteImage, setOnDeleteImage] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState({
    unlink: false,
    notAbleToUnlink: false,
    actionButton: ButtonAction.SaveAndClose,
  });

  const formRef = useRef<FormikProps<IAttributeValues>>(null);
  const createdAttributes = useRef<Set<string>>(new Set());

  const {addVariation, isLoading} = useVariantAPI();
  const {checkVariantsCanUnlink, unlinkVariants, isLoading: unlinkLoading} = useInventoryAPI();

  const {values: rootFormValues, setFieldValue} = useFormikContext<Inventory>();

  const attributeValues: IAttributeValues = useMemo(
    () => ({
      attributeName: variationOptionsDetails?.Name || '',
      abbreviation: variationOptionsDetails?.AbbreviatedName || '',
      exSellPrice: variationOptionsDetails?.StandardSellPriceExDelta?.toString() || '',
      incSellPrice: variationOptionsDetails?.StandardSellPriceIncDelta?.toString() || '',
      colour: variationOptionsDetails?.Colour || '',
      mediaFileID: variationOptionsDetails?.MediaFileID || null,
      mediaFileUrl: variationOptionsDetails?.MediaFileUrl || '',
      mediaFileThumbnailUrl: variationOptionsDetails?.MediaFileThumbnailUrl || '',
      isActive: variationOptionsDetails?.IsActive ?? true,
      variationOptionID: variationOptionsDetails?.VariationOptionID || null,
    }),
    [variationOptionsDetails],
  );

  const deleteFile = () => {
    formRef.current?.setFieldValue('mediaFileID', null);
    formRef.current?.setFieldValue('mediaFileUrl', '');
    formRef.current?.setFieldValue('mediaFileThumbnailUrl', '');
    setOnDeleteImage(false);
  };

  const validateUnlink = async (action: ButtonAction) => {
    const isLinkedAttributes = rootFormValues.ChildVariants?.some(v => v.ID !== null);
    const variation = rootFormValues.Variations?.find(v => v.VariationID === variationDetails?.VariationID);

    if (isLinkedAttributes && !variation?.Options) {
      const canUnlink = await checkVariantsCanUnlink(productID!);

      if (canUnlink.Value === false) {
        setAlertOpen(prev => ({...prev, notAbleToUnlink: true, actionButton: action}));
        return false;
      } else {
        setAlertOpen(prev => ({...prev, unlink: true, actionButton: action}));
        return false;
      }
    }
    return true;
  };

  const handleSaveAndAddAttribute = async (values: IAttributeValues, validate = true) => {
    if (validate) {
      const valid = await validateUnlink(ButtonAction.SaveAndAdd);
      if (valid === false) return;
    }

    try {
      await handleSubmit(values);
      formRef.current?.resetForm();
      formRef.current?.setValues({
        attributeName: '',
        abbreviation: '',
        exSellPrice: '',
        incSellPrice: '',
        colour: '',
        mediaFileID: null,
        mediaFileUrl: '',
        mediaFileThumbnailUrl: '',
        isActive: true,
        variationOptionID: null,
      });
    } catch (error) {
      console.error('Error saving and adding new attribute:', error);
    }
  };

  const handleSubmit = async (values: IAttributeValues) => {
    try {
      const attributOptions = {
        VariationOptionID: values.variationOptionID || null,
        Name: values.attributeName,
        AbbreviatedName: values.abbreviation,
        Colour: values.colour ?? undefined,
        MediaFileID: values.mediaFileID,
        MediaFileUrl: values.mediaFileUrl,
        MediaFileThumbnailUrl: values.mediaFileThumbnailUrl,
        StandardSellPriceExDelta: values.exSellPrice ? parseFloat(values.exSellPrice) : 0,
        StandardSellPriceIncDelta: values.incSellPrice ? parseFloat(values.incSellPrice) : 0,
        IsActive: values.isActive,
      };

      // Build the payload
      const payload: IAddProductVariation = {
        VariationID: variationDetails?.VariationID || null,
        MasterInventoryID: productID,
        IsActive: variationDetails?.IsActive ?? true,
        Name: variationDetails?.Name || '',
        AbbreviatedName: variationDetails?.AbbreviatedName || '',
        SequenceNumber: variationDetails?.SequenceNumber || 0,
        Options: [attributOptions],
      };

      const payloadWrapper: IAddProductVariantsPayload = {
        Value: [payload],
      };

      const response = await addVariation(productID!, payloadWrapper);
      if (response.IsSuccess) {
        setFieldValue('Variations', response.Value);
        const {attributeName, isActive, variationOptionID} = values;
        if (!isActive) {
          Toast.error(`${attributeName} attribute deleted`);
        } else if (variationOptionID) {
          Toast.info(`${attributeName} attribute updated`);
        } else if (createdAttributes.current.has(attributeName)) {
          Toast.info(`${attributeName} attribute created`);
        }
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const submitHandler = async (values: IAttributeValues, validate = true) => {
    if (validate) {
      const valid = await validateUnlink(ButtonAction.SaveAndClose);
      if (valid === false) return;
    }
    try {
      await handleSubmit(values);
      handleClose();
      setOpenMasterVariantDialog(true);
    } catch (error) {
      console.error('Failed to save and close:', error);
    }
  };

  const handleUnlinkAllChildVariants = async (values: IAttributeValues) => {
    await unlinkVariants(productID!);
    try {
      if (alertOpen.actionButton === ButtonAction.SaveAndAdd) {
        await handleSaveAndAddAttribute(values, false);
      } else {
        await submitHandler(values, false);
      }
    } catch (error) {
      console.error('Error unlinking variants:', error);
    } finally {
      setAlertOpen(prev => ({...prev, unlink: false, notAbleToUnlink: false}));
    }
  };

  return (
    <>
      {open && (
        <Formik
          innerRef={formRef}
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={attributeValues}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, handleBlur, handleChange, setValues, touched, errors}) => {
            return (
              <AlertDialogSlideV2
                dialogClassess="!mx-5 !w-full !max-w-[791px]"
                dialogBodyClassess="h-[280px] flex flex-grow items-center justify-center"
                title={
                  variationDetails?.Name
                    ? `Add attribute for ${variationDetails.Name.toLowerCase()}`
                    : 'Add new attribute'
                }
                headingClassess="font-semibold justify-center"
                actions={(function () {
                  const action: IDialogActionV2[] = [
                    {
                      label: 'Cancel',
                      action: () => {
                        handleClose();
                        setOpenMasterVariantDialog(true);
                      },
                      variant: 'outlined',
                      className: 'mr-2.5',
                    },

                    {
                      label: 'Save & Add another Attribute',
                      action: () => handleSaveAndAddAttribute(values),
                      variant: 'outlined',
                      className: 'ml-auto',
                      disabled: !(values.attributeName && values.abbreviation) || isLoading,
                    },
                    {
                      label: 'Save & Close',
                      action: async () => {
                        await submitHandler(values);
                      },
                      type: 'submit',
                      className: 'ml-3',
                      disabled: !(values.attributeName && values.abbreviation) || isLoading,
                    },
                  ];

                  if (variationOptionsDetails?.VariationOptionID) {
                    action.splice(1, 0, {
                      label: 'Delete',
                      action: () => {
                        submitHandler(
                          {
                            ...values,
                            isActive: false,
                          },
                          false,
                        );
                      },
                      color: 'error',
                      variant: 'outlined',
                      className: 'mr-auto',
                      disabled: isLoading,
                    });
                  }
                  return action;
                })()}
                dialogActionsAlignment="justify-between"
              >
                <>
                  {isLoading ? (
                    <div className="h-[481px]">
                      <LoadingIndicator isLoading={isLoading} size="md" color="hsl(var(--primary))" />
                    </div>
                  ) : (
                    <div className="flex gap-8">
                      <div className="mt-8 flex flex-col gap-10">
                        <div className="flex items-center justify-center gap-4">
                          <Input
                            data-autoid={`txtAttributeName`}
                            label="Attribute Name"
                            type="text"
                            name="attributeName"
                            placeholder=""
                            containerProps={{className: 'w-[200px]'}}
                            value={values.attributeName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.attributeName && errors.attributeName ? errors.attributeName : ''}
                            error={Boolean(touched.attributeName && errors.attributeName)}
                          />
                          <Input
                            data-autoid={`txtAbbreviation`}
                            label="Abbreviation"
                            type="text"
                            name="abbreviation"
                            placeholder=""
                            value={values?.abbreviation}
                            containerProps={{
                              className:
                                '!min-w-[145px] w-[145px] [&>span]:text-[#333333] [&>span]:font-medium [&>span]:text-sm',
                            }}
                            maxLength={3}
                            displayLength
                            InputProp={{
                              endAdornment: (
                                <CloseCross
                                  className="cursor-pointer"
                                  onClick={() => setFieldValue('abbreviation', '')}
                                />
                              ),
                              startAdornment: <></>,
                            }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.abbreviation && errors.abbreviation ? errors.abbreviation : ''}
                            error={Boolean(touched.abbreviation && errors.abbreviation)}
                          />
                        </div>
                        <div className="flex items-center justify-center gap-4">
                          <Input
                            data-autoid={`txtExSellPrice`}
                            label="Increased sell price by (Ex)"
                            type="number"
                            name="exSellPrice"
                            placeholder="$ 0.00"
                            value={values?.exSellPrice}
                            containerProps={{className: 'w-[180px] !min-w-[180px]'}}
                            shrink={true}
                            onChange={handleChange}
                          />
                          <Input
                            data-autoid={`txtIncSellPrice`}
                            label="Increased sell price by (Inc)"
                            type="number"
                            name="incSellPrice"
                            placeholder="$ 0.00"
                            value={values?.incSellPrice}
                            containerProps={{className: 'w-[180px] !min-w-[180px]'}}
                            shrink={true}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="max-h-[320px] w-0.5 border border-solid border-[#ECECEC]" />

                      <div className="flex flex-col items-center gap-8">
                        <div className="">
                          {values.mediaFileID ? (
                            <UploadedImageView
                              name={values.attributeName}
                              url={values.mediaFileUrl!}
                              containerClassName={'!w-20 !h-20 flex justify-start'}
                              onDelete={() => setOnDeleteImage(true)}
                            />
                          ) : (
                            <MediaUploader
                              onChange={file => {
                                if (file.length > 0) {
                                  setValues({
                                    ...values,
                                    mediaFileID: file[0]?.ID,
                                    mediaFileThumbnailUrl: file[0]?.ThumbnailURL,
                                    mediaFileUrl: file[0]?.URL,
                                  });
                                }
                              }}
                              icon={<UploadColour />}
                              uploadClassName="!min-w-20 flex border border-none p-5 text-primary rounded-lg !bg-primary/10"
                              disabled={Boolean(values.colour)}
                            />
                          )}
                        </div>
                        <div className="relative flex w-full items-center justify-center">
                          <div className="absolute bg-white px-2 font-medium text-spenda-primarytext">or</div>
                          <div className="h-0.5 w-full max-w-[160px] border border-[#ECECEC]" />
                        </div>
                        <ColorPickerInput
                          value={values.colour}
                          onChange={handleChange}
                          name="colour"
                          disabled={Boolean(values.mediaFileID)}
                        />
                      </div>
                      {/* Alert Dialog For Unlink of attributes */}
                      {alertOpen.unlink && (
                        <AlertDialog
                          size="sm"
                          title="Alert"
                          contentClass="px-8 py-4"
                          content={WarningMessageForUnlink}
                          contextTextVariant="small"
                          actions={[
                            {
                              label: 'Cancel',
                              action: () => setAlertOpen(prev => ({...prev, unlink: false})),
                              variant: 'outlined',
                            },
                            {
                              label: 'Ok',
                              loading: unlinkLoading,
                              disabled: unlinkLoading,
                              action: () => handleUnlinkAllChildVariants(values),
                            },
                          ]}
                        />
                      )}
                      {/* Alert Dialog for not able to unlink */}

                      {alertOpen.notAbleToUnlink && (
                        <AlertDialog
                          content={WarningMessageForNotAbleToUnlink}
                          title="Alert"
                          size="sm"
                          dialogActionsAlignment="justify-center"
                          contentClass="px-8 py-4 whitespace-pre-line"
                          contextTextVariant="small"
                          actions={[
                            {
                              label: 'Ok',
                              action: handleClose,
                            },
                          ]}
                        />
                      )}
                    </div>
                  )}
                </>
              </AlertDialogSlideV2>
            );
          }}
        </Formik>
      )}

      {onDeleteImage && (
        <AlertDialog
          id="discardPopup"
          title="Delete Image"
          size="xs"
          actions={[
            {
              label: 'No',
              action: () => setOnDeleteImage(false),
              variant: 'outlined',
            },
            {
              label: 'Yes',
              action: () => deleteFile(),
            },
          ]}
          content="Would you like to delete this image ?"
        />
      )}
    </>
  );
};
