import React, {useState, useEffect, useContext} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Formik, FormikProps} from 'formik';
import * as Yup from 'yup';
import {cloneDeep} from 'lodash';
import clsx from 'clsx';
import Skeleton from 'react-loading-skeleton';
import {DragDropContext, DropResult, DragStart, DraggableLocation} from 'react-beautiful-dnd';

import AppContext from '../../context/app/appContext';
import {AUTH_PURCHASING_PURCHASE_DASHBOARD_LIST} from '../../routes/PurchasingRoutes';
import {DatTypes, DeliveryMethods, RequisitionStatusType, RequisitionType} from '../../model/constants/Constants';
import {IRequisition, IRequisitionLine} from '../../model/requisition/Requisition';
import {IBatchUpdate} from '../../model/BatchUpdate';
import {
  CreateRequisitionDialog,
  ICreateRequisitionValues,
} from '../../components/purchase-order/CreateRequisitionDialog';
import {
  RequisitionAssignSupplier,
  RequisitionLines,
  UNASSIGNED_SUPPLIER,
} from '../../components/purchase-order/RequisitionLines';
import {AssignUserDialog} from '../../components/purchase-order/AssignUserDialog';
import {AlertDialogSlide} from '../../components/dialog/AlertDialogSlide';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {Layout} from '../../components/layout/Layout';
import {DestructiveButton, PrimaryButton, SecondaryButton} from '../../components/buttons/DefaultButtons';
import useRequisitionAPI from '../../services/useRequisitionAPI';
import {useInventoryAPI} from '../../services/useInventoryAPI';
import {useBatchUpdateAPI} from '../../services/useBatchUpdateAPI';
import {Toast} from '../../utils/Toast';

import {IconButton, makeStyles} from '@material-ui/core';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import {ApplicationInfo} from '../../model/constants/ApplicationInfo';

const useRequisitionDetailStyle = makeStyles(() => ({
  backIconButton: {
    color: '#1C78AD',
    border: '1px solid #1C78AD',
    borderRadius: '6px',
    padding: '8px',
    marginRight: '6px',
  },
}));

const validationSchema = Yup.object({});

const newRequisitionValues: ICreateRequisitionValues = {
  Status: RequisitionStatusType.New,
  RequisitionType: RequisitionType.Purchase,
  LinkedTrans: [],
  OrderDate: new Date(),
  DueDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
  IsActive: true,
  Lines: [],
  DeliveryMethod: DeliveryMethods.Deliver,
  Name: '',
  ToWarehouseID: 0,
};

export const RequisitionDetail = () => {
  const history = useHistory();
  const classes = useRequisitionDetailStyle();
  const {tenantInfo} = useContext(AppContext);
  const {requisitionID} = useParams<{requisitionID: string | undefined}>();

  const isAdministrator = Boolean(tenantInfo?.TenantUserDetails?.IsAdministrator);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState<boolean>(false);
  const [createDialog, setCreateDialog] = useState<boolean>(false);
  const [requisitionToEdit, setRequisitionToEdit] = useState<ICreateRequisitionValues>(newRequisitionValues);
  const [requisitionSuppliers, setRequisitionSuppliers] = useState<Map<string, string>>(new Map());
  const [editMode, setEditMode] = useState<boolean>(true);
  const [draggingId, setDraggingId] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<{[x: string]: Set<string>}>({});
  const [assignUserDialog, setAssignUserDialog] = useState<boolean>(false);
  const [changeDefaultDialog, setChangeDefaultDialog] = useState<IBatchUpdate | null>(null);
  const [newSupplierName, setNewSupplierName] = useState<string>('');

  const {
    get: getRequisitionById,
    delete: deleteRequisitionById,
    updateStatus: updateRequisitionStatus,
    saveLines: saveRequisitionLines,
    isLoading,
  } = useRequisitionAPI();

  const {getInventoryById} = useInventoryAPI();
  const {batchUpdate} = useBatchUpdateAPI();

  useEffect(() => {
    if (requisitionID) {
      getRequisition(requisitionID);
    }
  }, []);

  const getRequisition = async (requisitionID: string) => {
    const requisition = await getRequisitionById(Number(requisitionID));

    if (
      requisition.Status !== RequisitionStatusType.New &&
      requisition.Status !== RequisitionStatusType.Pending &&
      requisition.Status !== RequisitionStatusType.Approved
    ) {
      setEditMode(false);
    }
    if (requisition.Lines) {
      const _requisitionSuppliers = requisition.Lines.reduce((acc, curr) => {
        if (curr.SupplierID) {
          acc.set(String(curr.SupplierID), curr?.SupplierName || '');
        }
        return acc;
      }, new Map<string, string>());
      setRequisitionSuppliers(_requisitionSuppliers);
    }
    setRequisitionToEdit(requisition);
  };

  const handleCreateDialogOpen = (_isSupplierSelect: boolean = false) => {
    setCreateDialog(true);
  };

  const handleCreateDialogClose = (refetch?: boolean) => {
    setCreateDialog(false);
    if (refetch && requisitionID) {
      getRequisition(requisitionID);
    }
  };

  const handleAssignUserDialogClose = () => {
    setAssignUserDialog(false);
  };

  const handleCloseClick = () => {
    if (editMode) {
      setOpenConfirmationDialog(true);
    } else {
      history.push(AUTH_PURCHASING_PURCHASE_DASHBOARD_LIST);
    }
  };

  const handleDeleteRequisition = async () => {
    if (requisitionID) {
      await deleteRequisitionById(Number(requisitionID));
      history.push(AUTH_PURCHASING_PURCHASE_DASHBOARD_LIST);
    }
  };

  const saveLines = async (lines: IRequisitionLine[], oldLines?: IRequisitionLine[]): Promise<IRequisition> => {
    unselectAll();
    const requisition = await saveRequisitionLines(Number(requisitionID), lines);
    const _requisitionSuppliers = requisition.Lines.reduce((acc, curr) => {
      if (curr.SupplierID) {
        acc.set(String(curr.SupplierID), curr?.SupplierName || '');
      }
      return acc;
    }, new Map<string, string>());
    if (oldLines) {
      await checkDefaultSupplier(oldLines, lines);
    }
    setRequisitionSuppliers(new Map([...Array.from(requisitionSuppliers), ...Array.from(_requisitionSuppliers)]));
    return requisition;
  };

  const onSubmit = async (values: ICreateRequisitionValues): Promise<void> => {
    if (requisitionID) {
      if (isAdministrator) {
        if (values.Status === RequisitionStatusType.Approved) {
          return updateRequisitionStatus(Number(requisitionID), RequisitionStatusType.Processed).then(() => {
            Toast.info('Requisition updated');
            history.push(AUTH_PURCHASING_PURCHASE_DASHBOARD_LIST);
          });
        } else {
          return updateRequisitionStatus(Number(requisitionID), RequisitionStatusType.Approved).then(() => {
            Toast.info('Requisition updated');
            history.push(AUTH_PURCHASING_PURCHASE_DASHBOARD_LIST);
          });
        }
      } else {
        setAssignUserDialog(true);
        return Promise.resolve();
      }
    }
  };

  const onDragStart = (start: DragStart) => {
    const id: string = start.draggableId;
    const selected: boolean = Object.values(selectedIds).some((Ids): boolean => Ids.has(id));

    // if dragging an item that is not selected - unselect all items
    if (!selected) {
      unselectAll();
    }
    setDraggingId(start.draggableId);
  };

  const onDragEnd = async (result: DropResult, fProps: FormikProps<ICreateRequisitionValues>) => {
    const destination: DraggableLocation | null | undefined = result.destination
      ? {
          ...result.destination,
          droppableId: result.destination.droppableId?.replace('_', ''),
        }
      : undefined;
    const source: DraggableLocation = {...result.source, droppableId: result.source.droppableId.replace('_', '')};

    // nothing to do
    if (!destination || result.reason === 'CANCEL') {
      setDraggingId('');
      return;
    }
    if (source.droppableId === destination.droppableId || destination.droppableId === UNASSIGNED_SUPPLIER) {
      setDraggingId('');
      return;
    }

    const {values, setFieldValue} = fProps;
    const lines = cloneDeep(values.Lines) || [];

    const processed: Result = multiDragAwareReorder({
      entities: lines,
      selectedIds: selectedIds,
      source,
      destination,
      draggableId: result.draggableId,
    });

    setFieldValue('Lines', processed.entities);

    const requisition = await saveLines(processed.entities, cloneDeep(values.Lines || []));

    setFieldValue('Lines', requisition.Lines);
    setSelectedIds(processed.selectedIds);
    setDraggingId('');
  };

  const unselectAll = () => {
    setSelectedIds({});
  };

  const checkDefaultSupplier = async (oldLines: IRequisitionLine[], newLines: IRequisitionLine[]): Promise<any> => {
    const assignDefaultSupplier: IRequisitionLine[] = [];
    const changedLines: IRequisitionLine[] = [];
    newLines.forEach(nLine => {
      const index = oldLines.findIndex(oLine => oLine.LineID === nLine.LineID);
      if (index > -1 && oldLines[index].SupplierID && nLine.SupplierID !== oldLines[index].SupplierID) {
        changedLines.push(nLine);
      } else if (index > -1 && !oldLines[index].SupplierID && nLine.SupplierID !== oldLines[index].SupplierID) {
        assignDefaultSupplier.push(nLine);
      }
    }, [] as IRequisitionLine[]);

    if (changedLines.length) {
      const inventories = await Promise.all(changedLines.map(line => getInventoryById(String(line.InventoryID))));

      if (
        inventories.some(
          inventory =>
            Number(inventory.DefaultSupplierID) !==
            changedLines.find(line => line.InventoryID === Number(inventory.ID))?.SupplierID,
        )
      ) {
        setChangeDefaultDialog({
          Updates: [
            {
              IsRemoveFromCollection: false,
              Value: String(changedLines[0]?.SupplierID || ''),
              DatTypeID: DatTypes.Inventory,
              ColumnToUpdate: 'DefaultSupplierID',
              IDs: inventories.map(inventory => Number(inventory.ID)),
            },
          ],
          WebsiteID: ApplicationInfo.WebsiteId,
        });
        setNewSupplierName(changedLines[0]?.SupplierName || '');
      }
    }

    if (assignDefaultSupplier.length) {
      return batchUpdate({
        Updates: [
          {
            IsRemoveFromCollection: false,
            Value: String(assignDefaultSupplier[0]?.SupplierID || ''),
            DatTypeID: DatTypes.Inventory,
            ColumnToUpdate: 'DefaultSupplierID',
            IDs: assignDefaultSupplier.map(inventory => Number(inventory.InventoryID)),
          },
        ],
        WebsiteID: ApplicationInfo.WebsiteId,
      });
    }
    return Promise.resolve();
  };

  const handleUpdateDefault = async () => {
    if (changeDefaultDialog) {
      await batchUpdate(changeDefaultDialog);
      Toast.info('Default supplier updated.');
      setChangeDefaultDialog(null);
      setNewSupplierName('');
    }
  };

  const _requisitionDetail = (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={requisitionToEdit}
      onSubmit={onSubmit}
    >
      {props => {
        const isSubmitDisabled =
          (props.values.Lines || []).some(line => !line.SupplierID) ||
          (props.values.Lines || []).some(line => !line.Quantity);
        return (
          <DragDropContext onDragStart={onDragStart} onDragEnd={result => onDragEnd(result, props)}>
            <div className="flew-row flex h-full">
              <div
                id="requisition-detail-lines"
                className={clsx('h-full flex-1 overflow-y-auto rounded-lg bg-white p-3 shadow-[0_0_6px_0_#D3E5EF]')}
              >
                <div
                  className="flex flex-row items-center justify-between pb-2"
                  style={{borderBottom: '1px solid #D8D8D8'}}
                >
                  <IconButton
                    disableRipple
                    className={classes.backIconButton}
                    onClick={handleCloseClick}
                    data-autoid="btnBack"
                  >
                    <ArrowBackRoundedIcon fontSize="small" />
                  </IconButton>
                  <span className="ml-2 text-xl font-light text-spenda-primarytext">
                    Requisition - {props.values.RefNumber}
                  </span>
                  <div className="flex-1" />
                  <SecondaryButton
                    label="Purchase Details"
                    margin="0 0 0 8px"
                    onClick={() => handleCreateDialogOpen()}
                  />{' '}
                </div>
                {isLoading && !requisitionToEdit.ID ? (
                  <>
                    <Skeleton height={30} />
                    <Skeleton count={5} />
                  </>
                ) : (
                  <RequisitionLines
                    {...props}
                    editMode={editMode}
                    draggingId={draggingId}
                    requisitionSuppliers={requisitionSuppliers}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    saveLines={saveLines}
                  />
                )}
                {editMode ? (
                  <div className="fixed bottom-2.5 w-[calc(100%-575px)] px-0.5">
                    {isLoading && !requisitionToEdit.ID ? (
                      <Skeleton height={55} />
                    ) : (
                      <div
                        className="flex w-full flex-row p-2"
                        style={{backgroundColor: '#ADADAD50', borderRadius: '8px'}}
                      >
                        <DestructiveButton
                          label="Delete"
                          onClick={() => setDeleteConfirmationDialog(true)}
                          margin="0 8px 0 0"
                        />
                        <SecondaryButton label="Cancel" onClick={handleCloseClick} />
                        <div className="flex-1" />
                        <PrimaryButton
                          onClick={props.handleSubmit}
                          margin="0 0 0 8px"
                          label={
                            isAdministrator
                              ? props.values?.Status === RequisitionStatusType.Approved
                                ? 'Create Orders'
                                : 'Approve'
                              : 'Assign'
                          }
                          isSubmitting={props.isSubmitting}
                          disabled={props.isSubmitting || isSubmitDisabled}
                        />
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
              {editMode ? (
                <div
                  id="requisition-supplier-detail"
                  className="ml-2 flex h-full w-[515px] flex-col justify-between overflow-y-auto rounded-lg bg-white p-3 shadow-[0_0_6px_0_#D3E5EF]"
                >
                  {isLoading && !requisitionToEdit.ID ? (
                    <div>
                      <Skeleton height={50} />
                      <Skeleton count={3} height={20} />
                    </div>
                  ) : (
                    <RequisitionAssignSupplier
                      {...props}
                      editMode={editMode}
                      draggingId={draggingId}
                      requisitionSuppliers={requisitionSuppliers}
                      selectedIds={selectedIds}
                      setSelectedIds={setSelectedIds}
                      saveLines={saveLines}
                    />
                  )}
                </div>
              ) : null}
            </div>
          </DragDropContext>
        );
      }}
    </Formik>
  );

  return (
    <>
      {openConfirmationDialog ? (
        <AlertDialogSlide
          showTitleBottomBorder={true}
          titleColor="black"
          headerFontWeight={300}
          paddingY={60}
          paddingX={85}
          dialogWidth={'451px'}
          fontFamily="Poppins"
          title="Discard Changes"
          footer={
            <div className="flex w-full justify-between rounded bg-[#EFEFEF] px-4 py-2">
              <SecondaryButton label="Cancel" onClick={() => setOpenConfirmationDialog(false)} />
              <DestructiveButton
                label="Discard"
                onClick={() => history.push(AUTH_PURCHASING_PURCHASE_DASHBOARD_LIST)}
              />
            </div>
          }
        >
          <p className="text-center text-xs">
            You have unsaved changes, Would you like to discard them or return to the previous screen?
          </p>
        </AlertDialogSlide>
      ) : null}
      {deleteConfirmationDialog ? (
        <AlertDialogSlide
          showTitleBottomBorder={true}
          titleColor="black"
          headerFontWeight={300}
          paddingY={60}
          paddingX={85}
          dialogWidth={'451px'}
          fontFamily="Poppins"
          title="Delete Requisition"
          footer={
            <div className="flex w-full justify-between rounded bg-[#EFEFEF] px-4 py-2">
              <SecondaryButton label="Cancel" onClick={() => setDeleteConfirmationDialog(false)} />
              <DestructiveButton
                label="Delete"
                onClick={() => handleDeleteRequisition()}
                isSubmitting={isLoading}
                disabled={isLoading}
              />
            </div>
          }
        >
          <p className="text-center text-xs">Are you sure you want to DELETE Requisition, or go back ? </p>
        </AlertDialogSlide>
      ) : null}
      {changeDefaultDialog ? (
        <AlertDialogSlide
          showTitleBottomBorder={true}
          titleColor="black"
          headerFontWeight={300}
          paddingY={60}
          paddingX={85}
          dialogWidth={'451px'}
          fontFamily="Poppins"
          title="Update this product"
          footer={
            <div className="flex w-full justify-between rounded bg-[#EFEFEF] px-2 py-2">
              <SecondaryButton
                label="Keep Existing"
                onClick={() => {
                  setChangeDefaultDialog(null);
                  setNewSupplierName('');
                }}
              />
              <PrimaryButton
                label="Update Default"
                onClick={handleUpdateDefault}
                isSubmitting={isLoading}
                disabled={isLoading}
              />
            </div>
          }
        >
          <p className="text-center text-xs">
            Is {newSupplierName} the preferred supplier for this item? If you normally purchase this item from{' '}
            {newSupplierName} we can make them the default for the next time you order.{' '}
          </p>
        </AlertDialogSlide>
      ) : null}
      <CreateRequisitionDialog
        isReadOnly={!editMode}
        open={createDialog}
        onClose={handleCreateDialogClose}
        requisitionID={requisitionID}
      />
      <AssignUserDialog open={assignUserDialog} onClose={handleAssignUserDialogClose} requisitionID={requisitionID} />
      <div className="relative h-full overflow-hidden bg-spenda-newbg font-poppins">
        <Layout leftPanel={_requisitionDetail} splitWidthType={4} />
        <LoadingIndicator isLoading={isLoading} size="md" color="hsl(var(--primary))" />
      </div>
    </>
  );
};

type Args = {
  entities: IRequisitionLine[];
  selectedIds: {[x: string]: Set<string>};
  source: DraggableLocation;
  destination: DraggableLocation;
  draggableId: string;
};

export type Result = {
  entities: IRequisitionLine[];
  selectedIds: {[x: string]: Set<string>};
};

// utils for reorder
const multiDragAwareReorder = (args: Args): Result => {
  const {entities, destination, selectedIds, draggableId} = args;
  // Delete drop
  if (destination.droppableId === 'DELETE') {
    const ids: string[] = [];
    Object.values(selectedIds).forEach(_ids => ids.push(...Array.from(_ids)));
    const lines = entities.map(line => {
      if (ids.length) {
        if (ids.includes(String(line.LineID))) {
          line.IsActive = false;
          return line;
        }
      } else {
        if (draggableId === String(line.LineID)) {
          line.IsActive = false;
          return line;
        }
      }
      return line;
    });

    return {
      entities: lines,
      selectedIds: {},
    };
  }

  if (args.selectedIds[args.source.droppableId]?.size > 1) {
    return reorderMultiDrag(args);
  }
  return reorderSingleDrag(args);
};

const reorderSingleDrag = ({entities, selectedIds, source, destination, draggableId}: Args): Result => {
  const lines = cloneDeep(entities);

  const index = lines.findIndex(line => String(line.LineID) === draggableId);

  if (index > -1) {
    lines[index].SupplierID = Number(destination.droppableId);
  }

  const updatedIds = {
    ...selectedIds,
    [source.droppableId]: new Set([]),
  };

  return {
    entities: lines,
    selectedIds: updatedIds,
  };
};

const reorderMultiDrag = ({entities, selectedIds, source, destination}: Args): Result | any => {
  const ids: string[] = [];
  Object.values(selectedIds).forEach(_ids => ids.push(...Array.from(_ids)));

  const lines = entities.map(line => {
    if (ids.includes(String(line.LineID))) {
      line.SupplierID = Number(destination.droppableId);
      return line;
    }
    return line;
  });

  const updatedIds = {
    ...selectedIds,
    [source.droppableId]: new Set([]),
  };

  return {
    entities: lines,
    selectedIds: updatedIds,
  };
};
