import React, {useEffect, useState} from 'react';
import {AlertDialogSlideV2} from '../dialog/AlertDialogSlideV2';
import {Option, Select, Step, Stepper, Textarea, Typography} from 'spenda-ui-react';
import {BlueInfoIcon, PostingBehaviourIcon} from '../../assets/svg';
import {camelCase, upperCase} from 'lodash';
import {useInventoryAPI} from '../../services/useInventoryAPI';
import {useHistory, useParams} from 'react-router-dom';
import {Toast} from '../../utils/Toast';
import {AUTH_INVENTORY_PRODUCT_EDIT} from '../../routes/InventoryRoutes';
import {InventoryClass} from '../../model/inventory/InventoryClass';
import {useInventoryClassAPI} from '../../services/useInventoryClassesAPI';
import {DatTypes} from '../../model/constants/Constants';
import useVariantAPI from '../../services/useVariantAPI';

export interface IVariantInventory {
  handleClose: () => void;
  showPostingBehaviour?: boolean;
  viaCreateAll?: boolean;
}

interface IProductVariantBox {
  content?: string;
  handleSelectOption?: () => void;
  isSelected?: boolean;
}

export default function VariantInventory(props: IVariantInventory) {
  const {handleClose, viaCreateAll, showPostingBehaviour = false} = props;
  const {productID} = useParams<{productID?: string | undefined}>();
  const history = useHistory();

  const [selectedBox, setSelectedBox] = useState<number | null>(null);
  const [selectPostingBehavior, setSelectPostingBehavior] = useState<boolean>(showPostingBehaviour);
  const [variantMasterProductCode, setVariantMasterProductCode] = useState<string>('');
  const [inventory, setInventory] = useState<any>(null);
  const [inventoryClasses, setInventoryClasses] = useState<Partial<InventoryClass>[]>([]);
  const [newProductClassName, setNewProductClassName] = useState<string>('');
  const [selectedGenericClass, setSelectedGenericClass] = useState<Partial<InventoryClass> | null>(null);
  const [selectedAccountClass, setSelectedAccountClass] = useState<Partial<InventoryClass> | null>(null);

  const {createInventory, getInventoryById, inventoryCodeUnique, isLoading} = useInventoryAPI();
  const {dashboard: getInventoryClassList} = useInventoryClassAPI();
  const {createAllChildVariants} = useVariantAPI();

  useEffect(() => {
    const fetchInventoryClasses = async () => {
      const res = await getInventoryClassList({
        DatTypeID: DatTypes.InventoryClass,
        SearchNameOnly: true,
      });
      setInventoryClasses(res?.Value);
    };
    fetchInventoryClasses();
  }, []);

  useEffect(() => {
    const fetchInventory = async () => {
      if (productID) {
        const fetchedInventory = await getInventoryById(productID);
        setInventory(fetchedInventory);
      }
    };
    fetchInventory();
  }, [productID]);

  const handleSelectOption = (index: number) => {
    setSelectedBox(index);
  };

  const handleContinue = async () => {
    const response = await inventoryCodeUnique({InventoryCode: variantMasterProductCode});
    if (response.IsSuccess) {
      setSelectPostingBehavior(true);
    } else {
      Toast.error('Variant Master Product code should be unique');
    }
  };

  const addClassDetailsToPayload = (payload: any, selectedClass: Partial<InventoryClass>) => {
    payload.ChildInventoryClassID = selectedClass.ID;
    payload.InventoryClassID = selectedClass.ID;
    payload.ChildInventoryClassName = selectedClass.Name;
    payload.InventoryClassName = selectedClass.Name;
  };

  const handleCreateMasterVariant = async () => {
    if (productID) {
      const newInventoryPayload = {
        ...inventory,
        InventoryCode: variantMasterProductCode,
        IsVariantMaster: true,
        IsSOHTracked: false,
        IsSold: false,
        IsPurchased: false,
        IsPostingInventoryItem: false,
        ID: null,
        GUID: undefined,
        GUIDstring: undefined,
        SKU: undefined,
        AssetAccount: undefined,
        ExpenseAccount: undefined,
        RevenueAccount: undefined,
      };

      if (selectedBox === 0 && selectedAccountClass) {
        addClassDetailsToPayload(newInventoryPayload, selectedAccountClass);
      } else if (selectedBox === 1 && selectedGenericClass) {
        addClassDetailsToPayload(newInventoryPayload, selectedGenericClass);
      } else if (selectedBox === 2 && newProductClassName) {
        newInventoryPayload.InventoryClass = {
          IsAutoCreateGenericAccountInventory: true,
          IsActive: true,
          Name: newProductClassName,
          PostingBehaviour: 'Generic',
        };
      }
      const createAndHandleClose = async (payload: any) => {
        const res = await createInventory(payload);
        if (res?.IsSuccess) {
          await createAllChildVariants(res.Value.ID);
          handleClose();
        }
      };

      if (viaCreateAll) {
        if (selectedBox === 0 && selectedAccountClass) {
          await createAndHandleClose({
            ...inventory,
            InventoryClassName: selectedAccountClass.Name,
            InventoryClassID: selectedAccountClass.ID,
            ChildInventoryClassName: selectedAccountClass.Name,
            ChildInventoryClassID: selectedAccountClass.ID,
          });
          return;
        }
        if (selectedBox === 1 && selectedGenericClass) {
          await createAndHandleClose({
            ...inventory,
            InventoryClassName: selectedGenericClass.Name,
            InventoryClassID: selectedGenericClass.ID,
            ChildInventoryClassName: selectedGenericClass.Name,
            ChildInventoryClassID: selectedGenericClass.ID,
          });
          return;
        }
        if (selectedBox === 2 && newProductClassName) {
          await createAndHandleClose({
            ...inventory,
            InventoryClass: {
              IsAutoCreateGenericAccountInventory: true,
              IsActive: true,
              Name: newProductClassName,
              PostingBehaviour: 'Generic',
            },
          });
          return;
        }
      }

      const res = await createInventory(newInventoryPayload);
      if (res?.IsSuccess) {
        history.push(`${AUTH_INVENTORY_PRODUCT_EDIT.replace(/:productID/g, res.Value.ID)}?tab=variants`);
        history.go(0);
      }
    }
  };

  const isCreateMasterVariantDisabled = () => {
    if (selectedBox === null) return true;
    if (selectedBox === 0 && !selectedAccountClass) return true;
    if (selectedBox === 1 && !selectedGenericClass) return true;
    if (selectedBox === 2 && !newProductClassName) return true;
    return false;
  };

  const ProductVariantBox = (props: IProductVariantBox) => {
    const {content, handleSelectOption, isSelected} = props;

    return (
      <div
        className={`flex h-[112px] max-w-[278px] cursor-pointer items-center justify-center rounded-md border p-8 ${isSelected ? 'border-primary bg-primary/10' : 'border-[#ECECEC] bg-spenda-cream'}`}
        onClick={handleSelectOption}
      >
        <Typography
          className="px-3 text-center font-medium text-black-800"
          data-autoid={`txt${upperCase(camelCase(content))}`}
        >
          {content}
        </Typography>
      </div>
    );
  };
  return (
    <div>
      <AlertDialogSlideV2
        dialogActionsAlignment="justify-between"
        size="lg"
        dialogBodyClassess="px-0 py-0"
        headingTextSize="h1"
        headingClassess="border-none justify-center"
        data-autoid={`${selectPostingBehavior ? 'dlgMasterInventory' : 'dlgPostingBehavior'}`}
        title={`${selectPostingBehavior ? 'Set your child variant posting behaviour' : 'Setup your master product'}`}
        actions={[
          {
            label: 'Cancel',
            variant: 'outlined',
            action: handleClose,
          },
          {
            label: selectPostingBehavior
              ? viaCreateAll
                ? 'Create All Children'
                : 'Create Master Variant'
              : 'Continue',
            loading: isLoading,
            action: !selectPostingBehavior ? handleContinue : handleCreateMasterVariant,
            disabled: !selectPostingBehavior ? !variantMasterProductCode : isCreateMasterVariantDisabled(),
          },
        ]}
      >
        <>
          <div className="bg-cream">
            <div className="flex items-center justify-center bg-[#F6F6F6] py-3">
              <Stepper activeStep={selectPostingBehavior ? 1 : 0} className="w-[65%]">
                <Step className="flex h-full w-max gap-x-4 rounded-none !bg-[#F6F6F6] px-4">
                  <BlueInfoIcon className={selectPostingBehavior ? 'fill-spenda-labeltext' : 'fill-spenda-sBlue'} />
                  <Typography
                    className={`text-base ${selectPostingBehavior ? 'text-spenda-labeltext' : 'text-spenda-primarytext'}`}
                  >
                    Setup master inventory
                  </Typography>
                </Step>
                <Step className="flex h-full w-max gap-x-4 rounded-none !bg-[#F6F6F6] px-4 text-white/75">
                  <PostingBehaviourIcon
                    className={selectPostingBehavior ? 'fill-spenda-sBlue' : 'fill-spenda-labeltext'}
                  />
                  <Typography
                    className={`text-base ${selectPostingBehavior ? 'text-spenda-primarytext' : 'text-spenda-labeltext'}`}
                  >
                    Posting behaviour
                  </Typography>
                </Step>
              </Stepper>
            </div>
          </div>
          {selectPostingBehavior ? (
            <div className="flex min-h-[500px] flex-col items-center gap-y-6">
              <Typography className="py-10 font-poppins text-base text-black-300">
                How would you like us to post your variant children?
              </Typography>
              <div className="mb-5 flex w-full cursor-pointer items-center justify-center gap-5 text-center">
                <ProductVariantBox
                  content="Post all variant children through an existing account class"
                  isSelected={selectedBox === 0}
                  handleSelectOption={() => handleSelectOption(0)}
                />
                <ProductVariantBox
                  content="Post all variant children through an existing generic class"
                  isSelected={selectedBox === 1}
                  handleSelectOption={() => handleSelectOption(1)}
                />
                <ProductVariantBox
                  content="Post all variant children through a new generic class"
                  isSelected={selectedBox === 2}
                  handleSelectOption={() => handleSelectOption(2)}
                />
              </div>
              {selectedBox === 0 && (
                <div className=" flex w-[302px] flex-col items-center justify-center pb-[114px]">
                  <Select
                    size="lg"
                    variant="outlined"
                    label="Select account class"
                    onChange={(value: string | undefined) => {
                      const selectedClass = inventoryClasses.find(ic => ic.ID === value);
                      setSelectedAccountClass(selectedClass || null);
                    }}
                  >
                    {inventoryClasses.length === 0 ||
                    inventoryClasses.filter(inventoryClass => inventoryClass.PostingBehaviour === 'Account').length ===
                      0 ? (
                      <Option value="" disabled>
                        No existing account class
                      </Option>
                    ) : (
                      inventoryClasses
                        .filter(inventoryClass => inventoryClass.PostingBehaviour === 'Account')
                        .map(inventoryClass => (
                          <Option key={inventoryClass.ID} value={inventoryClass.ID}>
                            {inventoryClass?.Name}
                          </Option>
                        ))
                    )}
                  </Select>
                </div>
              )}
              {selectedBox === 1 && (
                <div className=" flex w-[302px] flex-col items-center justify-center pb-[114px]">
                  <Select
                    size="lg"
                    variant="outlined"
                    label="Select generic class"
                    onChange={(value: string | undefined) => {
                      const selectedClass = inventoryClasses.find(ic => ic.ID === value);
                      setSelectedGenericClass(selectedClass || null);
                    }}
                  >
                    {inventoryClasses.length === 0 ||
                    inventoryClasses.filter(inventoryClass => inventoryClass.PostingBehaviour === 'Generic').length ===
                      0 ? (
                      <Option value="" disabled>
                        No existing generic class
                      </Option>
                    ) : (
                      inventoryClasses
                        .filter(inventoryClass => inventoryClass.PostingBehaviour === 'Generic')
                        .map(inventoryClass => (
                          <Option key={inventoryClass.ID} value={inventoryClass.ID}>
                            {inventoryClass.Name}
                          </Option>
                        ))
                    )}
                  </Select>
                </div>
              )}
              {selectedBox === 2 && (
                <div className=" flex w-[302px] flex-col items-center justify-center pb-[114px]">
                  <Textarea
                    name="genericClass"
                    id="genericClass"
                    data-autoid="txtGenericClassName"
                    label="Generic class name"
                    className=" !h-[45px] min-h-[45px]"
                    value={newProductClassName}
                    onChange={e => setNewProductClassName(e.target.value)}
                  />
                  <Typography className="text-start text-xs text-black-800">
                    All variant children will be posted to your finance system as...
                  </Typography>
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center">
              <div className="flex w-[634px] flex-col gap-5 p-6 py-14 text-center">
                <Typography className="text-base font-semibold text-black-300">
                  {inventory?.ShortDescription} - {inventory?.InventoryCode}
                  <span className="ml-1 font-normal">
                    cannot be promoted to a Master product as its integrated with another system.
                  </span>
                </Typography>
                <Typography className="text-base font-normal text-black-300">
                  We will need to make a copy of this product to continue. What would you like the product code to be
                  for your new variant master product?
                </Typography>
              </div>
              <div className="flex w-[246px] items-center justify-center pb-[8rem]">
                <Textarea
                  name="variantInventory"
                  id="variantInventory"
                  label="Variant master product code"
                  className="!h-[45px] min-h-[45px]"
                  color="primary"
                  value={variantMasterProductCode}
                  onChange={e => setVariantMasterProductCode(e.target.value)}
                />
              </div>
            </div>
          )}
        </>
      </AlertDialogSlideV2>
    </div>
  );
}
