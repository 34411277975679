import React, {useMemo, useState} from 'react';
import {Button, Typography} from 'spenda-ui-react';
import {useEnvironment} from '../../../hooks/useEnvironment';

import {CreateClaimRequestDialog} from '../../../components/AccountsReceivable/CreateClaimRequestDialog';
import {useTenantInfo} from '../../../hooks/useTenantInfo';
import {ARSearchBar} from '../../../components/AccountsReceivable/ARSearchBar';
import {VisibleContent} from '../../../components/ui/VisibleContent';
import {CustomContent} from '../../../components/ui/CustomContent';

interface IClaimsHighLevelToolbarProps {
  activeBucket?: string;
  claimAndReturnsV272602: boolean;
  supplierId?: number;
  setSearchQuery: React.Dispatch<
    React.SetStateAction<{
      searchKey: string;
      searchCategory: string;
    }>
  >;
}

export const ClaimsHighLevelToolbar = (props: IClaimsHighLevelToolbarProps) => {
  // Props
  const {activeBucket, supplierId, claimAndReturnsV272602, setSearchQuery} = props;

  // Hooks
  const {isInSupplierContext} = useTenantInfo();
  const {isCapricornEnvironment} = useEnvironment();

  //States
  const [toggleCreateClaimRequest, setToggleCreateClaimRequest] = useState(false);

  const refNumberLabel = CustomContent({
    keyPath: 'accountReceivables.claimRequests.search.refNumber',
    children: 'Ref Number',
  });

  const searchCategories = useMemo(() => {
    const categories = [
      {value: 'Amount', label: 'Amount'},
      {value: 'RefNumber', label: refNumberLabel, placeholder: refNumberLabel},
    ];

    if (!isCapricornEnvironment) {
      categories.push({value: 'VendorName', label: 'Vendor name'});
    }

    if (isInSupplierContext) {
      categories.push({value: 'CustomerName', label: 'Customer Name'});
    }

    return categories.sort((a, b) => a.label.localeCompare(b.label));
  }, [isCapricornEnvironment, isInSupplierContext, refNumberLabel]);

  return (
    <div className={`mb-3 flex h-[49px] items-center justify-between border-[#D8D8D8] border-b-default pb-2.5`}>
      <Typography className="mr-5 inline whitespace-nowrap font-poppins font-medium" variant="h3">
        {activeBucket}
      </Typography>

      <>
        {claimAndReturnsV272602 && (
          <>
            <div className={`flex w-full flex-row items-center justify-between`}>
              <div className="min-w-[400px]">
                <ARSearchBar
                  type="advance"
                  searchString={''}
                  onSubmit={({searchString, searchCategory}) => {
                    setSearchQuery({searchKey: searchString, searchCategory: searchString ? searchCategory || '' : ''});
                  }}
                  isSearching={false}
                  dataAutoIdSuffix={'claimsAndReturn'}
                  searchCategory={'RefNumber'}
                  searchCategories={searchCategories}
                />
              </div>
              <VisibleContent keyPath="accountsReceivable.creditMemos.createClaimRequestButton">
                <Button variant="filled" color="primary" onClick={() => setToggleCreateClaimRequest(true)}>
                  Create Claim Request
                </Button>
              </VisibleContent>
            </div>

            {toggleCreateClaimRequest && (
              <CreateClaimRequestDialog
                supplierId={supplierId}
                showModal={toggleCreateClaimRequest}
                onConfirm={function (): void {
                  throw new Error('Function not implemented.');
                }}
                handleClose={() => setToggleCreateClaimRequest(false)}
              />
            )}
          </>
        )}
      </>
    </div>
  );
};
