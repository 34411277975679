/* eslint-disable no-console */
import * as Yup from 'yup';
import {Input} from 'spenda-ui-react';
import React, {useState} from 'react';
import {Formik, FormikProps, useFormikContext} from 'formik';

import LoadingOverLay from '../ui/LoadingOverlay';
import {AddAttributeDialog} from './AddAttributeDialog';
import useVariantAPI from '../../services/useVariantAPI';
import {AlertDialog, AlertDialogSlideV2, IDialogActionV2} from './AlertDialogSlideV2';
import {IAddProductVariantsPayload, IAddProductVariation} from '../../model/variants';
import {useInventoryAPI} from '../../services/useInventoryAPI';
import {Inventory} from '../../model/inventory/Inventory';
import {Toast} from '../../utils/Toast';

interface IAddVariantDialogProps {
  open?: boolean;
  onClose: () => void;
  productID: string | undefined;
  variationDetails?: IAddProductVariation | null;
  setOpenMasterVariantDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IVariantValues {
  Name: string;
  Abbreviation: string;
  IsActive: boolean;
}

const validationSchema = Yup.object({
  Name: Yup.string().required('Variant Name is required'),
  Abbreviation: Yup.string().required('Abbreviation is required').max(3, 'Reached char limit'),
});

export const WarningMessageForUnlink = `Adding new Variations and Attributes will result in your previous Variants as well as the Products linked to your Variants being deleted. If you want to keep specific Inventory Items, we suggest you unlink them first by accessing the Workflow Tab of the Product and turn the 'Is Child Variant' toggle off. Remember to link your existing Products to your Variants first before using the 'Create All' or 'Create New' feature on the next screen where your new set of Variants will be displayed.`;
export const WarningMessageForNotAbleToUnlink = `Unfortunately, we are unable to update your list of Variants as it looks like some of your existing Variants have transactions, stock tracking enabled or are integrated with a 3rd party system.\nIf you need to add new Variations and Attributes, we suggest that you create a new Master Inventory Item, create your required Variations and Attributes and link your new Products to those Variants.`;

export const AddVariantDialog: React.FunctionComponent<IAddVariantDialogProps> = ({
  open,
  onClose,
  productID,
  variationDetails,
  setOpenMasterVariantDialog,
}: IAddVariantDialogProps) => {
  const {checkVariantsCanUnlink, unlinkVariants, isLoading: unlinkLoading} = useInventoryAPI();
  const {addVariation, isLoading} = useVariantAPI();

  const {values: rootFormValues} = useFormikContext<Inventory>();

  const [openAttributeDialog, setOpenAttributeDialog] = useState<boolean>(false);
  const [newVariationDetails, setNewVariationDetails] = useState<IAddProductVariation>();
  const [alertOpen, setAlertOpen] = useState({
    unlink: false,
    notAbleToUnlink: false,
  });

  const initialValues: IVariantValues = {
    Name: variationDetails?.Name || '',
    Abbreviation: variationDetails?.AbbreviatedName || '',
    IsActive: variationDetails?.IsActive || true,
  };

  const handleCloseClick = (props: FormikProps<IVariantValues>, cancelClick: boolean = true) => {
    if (cancelClick && props.dirty) {
      setOpenAttributeDialog(true);
    } else {
      setOpenAttributeDialog(false);
      setOpenMasterVariantDialog(true);
      onClose();
    }
  };

  const saveAndUpdateVariation = async (values: IVariantValues) => {
    const payload: IAddProductVariation = {
      VariationID: variationDetails?.VariationID || null,
      Name: values.Name,
      AbbreviatedName: values.Abbreviation,
      IsActive: values.IsActive,
    };

    const payloadWrapper: IAddProductVariantsPayload = {
      Value: [payload],
    };

    try {
      // Call the API
      const response = await addVariation(productID!, payloadWrapper);
      if (variationDetails?.VariationID) {
        setOpenAttributeDialog(false);
        setOpenMasterVariantDialog(true);
        if (response.IsSuccess) {
          Toast.error(`${values.Name} variant deleted`);
        }
        onClose();
        return;
      }
      if (values.IsActive && response.IsSuccess) {
        Toast.info(`${values.Name} variant created`);
      }
      if (!response) {
        setOpenAttributeDialog(false);
      } else {
        setOpenAttributeDialog(true);
      }

      // Find the specific variation in the response
      const findResponse = response.Value.find(r => r.Name === values.Name);
      setNewVariationDetails(findResponse);
    } catch (error) {
      console.error('Error submitting form:', error, WarningMessageForUnlink);
    }
  };

  const handleSubmit = async (values: IVariantValues) => {
    if (!variationDetails) {
      const isLinkedAttributes = rootFormValues.ChildVariants?.some(v => v.ID !== null);

      if (isLinkedAttributes) {
        const canUnlink = await checkVariantsCanUnlink(productID!);

        if (canUnlink.Value === false) {
          // show alert, not create new variation
          setAlertOpen(prev => ({...prev, notAbleToUnlink: true}));
          return;
        } else {
          // unlink Dialog
          setAlertOpen(prev => ({...prev, unlink: true}));
          return;
        }
      }
    }
    await saveAndUpdateVariation(values);
  };

  const handleUnlinkAllChildVariants = async (values: IVariantValues) => {
    const res = await unlinkVariants(productID!);
    setAlertOpen(prev => ({...prev, unlink: false}));
    if (res) await saveAndUpdateVariation(values);
  };

  return (
    <>
      {open && (
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({handleChange, handleSubmit, values, touched, errors, handleBlur, isValid, dirty, setFieldValue}) => (
            <LoadingOverLay isLoading={isLoading} overlayBgColor={'rgba(0, 0, 0, 0.1)'}>
              <AlertDialogSlideV2
                dialogClassess="!max-w-[497px] !min-w-[20%]"
                dialogBodyClassess="flex flex-grow items-center justify-center py-16"
                title={variationDetails ? 'Edit variation property' : 'Add a new variation property'}
                headingClassess="font-semibold justify-center"
                actions={(function () {
                  const action: IDialogActionV2[] = [
                    {
                      label: 'Cancel',
                      action: (props: FormikProps<IVariantValues>) => handleCloseClick(props),
                      variant: 'outlined',
                      className: 'mr-2.5',
                    },
                    {
                      label: variationDetails ? 'Update & Save' : 'Continue',
                      type: 'submit',
                      className: 'ml-3',
                      action: () => handleSubmit(),
                      loading: isLoading || unlinkLoading,
                      disabled: !values.Name || !values.Abbreviation || !isValid || !dirty || isLoading,
                    },
                  ];

                  if (variationDetails) {
                    action.splice(1, 0, {
                      label: 'Delete',
                      type: 'button',
                      variant: 'outlined',
                      color: 'error',
                      className: 'mr-auto',
                      action: async () => {
                        await setFieldValue('IsActive', false);
                        handleSubmit();
                      },
                      disabled: isLoading,
                    });
                  }
                  return action;
                })()}
                dialogActionsAlignment="justify-between"
              >
                <>
                  <div className="flex items-center justify-center gap-4">
                    <Input
                      data-autoid={`txtVariantName`}
                      label="Variant Name"
                      name="Name"
                      placeholder=""
                      containerProps={{className: 'w-[231px] min-w-[231px]'}}
                      onChange={handleChange}
                      value={values.Name}
                      helperText={touched.Name && errors.Name ? errors.Name : ''}
                      error={Boolean(touched.Name && errors.Name)}
                      onBlur={handleBlur}
                    />
                    <Input
                      displayLength
                      data-autoid={`txtAbbreviation`}
                      label="Abbreviation"
                      name="Abbreviation"
                      maxLength={3}
                      placeholder=""
                      value={values.Abbreviation}
                      containerProps={{
                        className:
                          'w-[155px] min-w-[155px] [&>span]:text-[#333333] [&>span]:font-medium [&>span]:text-sm',
                      }}
                      onChange={handleChange}
                      helperText={touched.Abbreviation && errors.Abbreviation ? errors.Abbreviation : ''}
                      error={Boolean(touched.Abbreviation && errors.Abbreviation)}
                      onBlur={handleBlur}
                    />
                  </div>
                </>
                {openAttributeDialog && (
                  <AddAttributeDialog
                    open={openAttributeDialog}
                    handleClose={onClose}
                    productID={productID}
                    variationDetails={newVariationDetails}
                    setOpenMasterVariantDialog={setOpenMasterVariantDialog}
                  />
                )}
              </AlertDialogSlideV2>
              {/* Alert Dialog For Unlink of attributes */}
              {alertOpen.unlink && (
                <AlertDialog
                  size="sm"
                  title="Alert"
                  contentClass="px-8 py-4"
                  content={WarningMessageForUnlink}
                  contextTextVariant="small"
                  actions={[
                    {
                      label: 'Cancel',
                      action: () => setAlertOpen(prev => ({...prev, unlink: false})),
                      variant: 'outlined',
                    },
                    {
                      label: 'Ok',
                      loading: unlinkLoading,
                      disabled: unlinkLoading,
                      action: () => handleUnlinkAllChildVariants(values),
                    },
                  ]}
                />
              )}
              {/* Alert Dialog for not able to unlink */}
              {alertOpen.notAbleToUnlink && (
                <AlertDialog
                  content={WarningMessageForNotAbleToUnlink}
                  title="Alert"
                  size="sm"
                  dialogActionsAlignment="justify-center"
                  contentClass="px-8 py-4 whitespace-pre-line"
                  contextTextVariant="small"
                  actions={[
                    {
                      label: 'Ok',
                      action: () => {
                        onClose();
                        setAlertOpen(prev => ({...prev, notAbleToUnlink: false}));
                      },
                    },
                  ]}
                />
              )}
            </LoadingOverLay>
          )}
        </Formik>
      )}
    </>
  );
};
