import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Button, useTabs} from 'spenda-ui-react';
import {IntegrationContext} from '../../context/IntegrationContext';
import {IAccounts} from '../../model/accounts/Accounts';
import {
  ChartsOfAccountClass,
  ChartsOfAccountTaxType,
  ChartsOfAccountType,
  DatTypes,
  LowTouchPsblOnboardingSteps,
} from '../../model/constants/Constants';
import useAccountAPI from '../../services/useAccountAPI';
import PSBLOnboardingFooter, {ResponsiveBookOnboardingButton} from '../buttons/PSBLOnboardingFooter';
import CreateAccountInputComponent from './CreateAccountInput';
import {ImportWorkflowsModal} from '../AccountsReceivable/modals/ImportWorkflowsModal';
import {usePSBLHook} from '../../hooks/useARHook';
import {defaultMarketPlacePath} from '../../config';
import {useOnboarding} from '../../services/useOnboarding';
import IconTipBulb from '../../assets/svg/IconTipBulb';
import {useSupplierAPI} from '../../services/useSupplierAPI';

export const getMissingCode = (codes: string[], startFrom: number, end: number, prefix?: string) => {
  for (let i = startFrom; i <= end; i++) {
    const codeToCompare = prefix ? `${prefix}-${i}` : `${i}`;
    if (!codes.includes(codeToCompare)) {
      return codeToCompare;
    }
  }
  return '';
};

interface IClearingAccountSetupProps {
  handleNext: () => void;
  currentActiveTab: LowTouchPsblOnboardingSteps;
  startSuppliersWorkflow: () => Promise<void>;
}

export const CreateClearingAccount = (props: IClearingAccountSetupProps) => {
  // Props
  const {currentActiveTab, handleNext, startSuppliersWorkflow} = props;

  // State
  const [isShowClearingAccountsScreen, setIsShowClearingAccountsScreen] = useState(false);

  // Hooks
  const {handleTabChange} = useOnboarding();

  useEffect(() => {
    if (currentActiveTab === LowTouchPsblOnboardingSteps.ClearingAccountSetup) {
      setIsShowClearingAccountsScreen(true);
    }
  }, [currentActiveTab]);

  // Functions
  const handleChartOfAccountsImportComplete = async () => {
    try {
      setIsShowClearingAccountsScreen(true);
      await handleTabChange({
        isPsblOnboarding: true,
        action: 'next',
        completeSteps: [LowTouchPsblOnboardingSteps.ChartOfAccountsWorkflow],
        nextStep: LowTouchPsblOnboardingSteps.ClearingAccountSetup,
      });
      startSuppliersWorkflow();
    } catch {}
  };

  return (
    <div className="wrapper relative h-full w-full overflow-hidden bg-white">
      {isShowClearingAccountsScreen ? (
        <CreateAccounts handleComplete={handleNext} />
      ) : (
        <ImportChartsOfAccounts handleComplete={handleChartOfAccountsImportComplete} />
      )}
    </div>
  );
};

interface IImportCOAProps {
  handleComplete: () => void;
}
const ImportChartsOfAccounts = (props: IImportCOAProps) => {
  // Props
  const {handleComplete} = props;

  // State
  const [showFetchCOAModal, setShowFetchCOAModal] = useState(false);
  const {state: tabState} = useTabs();

  // Context
  const {financialAdaptor} = useContext(IntegrationContext);

  useEffect(() => {
    if (tabState?.active === LowTouchPsblOnboardingSteps.ClearingAccountSetup) {
      setShowFetchCOAModal(true);
    }
  }, [tabState?.active]);

  // Functions
  const checkCOASuccess = () => {
    setShowFetchCOAModal(false);
    handleComplete();
  };

  const queuePayload = useMemo(
    () =>
      financialAdaptor && {
        Action: 'Read',
        AdaptorName: financialAdaptor.Name,
        AdaptorID: financialAdaptor.ID,
        DatTypeID: DatTypes.Accounts,
        MaxWaitSecs: 29,
      },
    [financialAdaptor],
  );

  return (
    <div className="wrapper relative h-full w-full overflow-hidden bg-white pb-16">
      <div className="mx-auto h-full w-full overflow-y-auto overflow-x-hidden pb-28 sm:max-w-[622px] sm:pb-0">
        <div className={`mx-auto mt-4 !max-w-[530px] align-middle`}>
          <p className={`text-left font-poppins text-[20px] font-medium text-[#333] sm:text-2xl sm:font-light`}>
            Let’s get your chart of accounts
          </p>
          <p className={`mt-6 text-left text-sm font-normal text-[#999999] sm:mt-2`}>
            This is the account that we’ll use to pay the invoices in your financial system. We recommend setting up a
            new one, so that you can easily manage the new process without any crossover with old transactions.
          </p>
        </div>
        <div className={`mx-auto mt-4 w-full align-middle sm:!max-w-[530px]`}>
          <Button
            className="my-10 w-full bg-white font-semibold text-primary sm:w-[299px]"
            variant="outlined"
            data-autoid={'btnImportChartsAccounts'}
            onClick={() => setShowFetchCOAModal(true)}
            disabled={!financialAdaptor}
            loading={!financialAdaptor}
          >
            Import Charts of Accounts
          </Button>
        </div>
        {showFetchCOAModal && (
          <ImportWorkflowsModal
            open={showFetchCOAModal}
            onSuccess={checkCOASuccess}
            message="We're currently retrieving your chart of accounts. This should only take a moment."
            queuePayload={queuePayload}
          />
        )}
        <ResponsiveBookOnboardingButton />
      </div>
      <PSBLOnboardingFooter isChatWidget onPrimaryClick={() => {}} isDisabled />
    </div>
  );
};

interface IClearingAccountProps {
  handleComplete: () => void;
}

export interface IParentAccountsID {
  clearingAccountID: number | undefined;
  roundingExpenseID: number | undefined;
  roundingIncomeID: number | undefined;
}

const CreateAccounts = (props: IClearingAccountProps) => {
  // Props
  const {handleComplete} = props;

  // State
  const [accounts, setAccounts] = useState<IAccounts[]>([]);
  const [createdClearingAccount, setCreatedClearingAccount] = useState<IAccounts>();
  const [codes, setCodes] = useState({clearingAccount: ''});
  const [error, setError] = useState({clearingAccount: ''});
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
  const [isAccountCreated, setIsAccountCreated] = useState({clearingAccount: false});
  const [parentAccountsID, setParentAccountsID] = useState<Partial<IParentAccountsID>>({clearingAccountID: undefined});

  // APIs
  const {getCreditlineAccounts, parentAccount} = useAccountAPI();
  const {getSupplierById, saveSupplier} = useSupplierAPI();

  // Context
  const {isXeroAdaptor, isMYOBAdaptor} = useContext(IntegrationContext);

  // Hooks
  const {getSupplierByMarketplacePath} = usePSBLHook();
  const supplierDetails = getSupplierByMarketplacePath(defaultMarketPlacePath);

  // Constants
  const clearingAccountDesc = `${supplierDetails?.SupplierName} - Current Statement`;

  // UseEffect
  useEffect(() => {
    if (isXeroAdaptor || isMYOBAdaptor) {
      getClearingAccounts();
    }
    if (isMYOBAdaptor) {
      getParentAccounts();
    }
  }, [isXeroAdaptor, isMYOBAdaptor]);

  // Functions
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const {value, name} = e.target;
      if (!value) {
        setError(p => ({...p, [name]: 'Please enter a code.'}));
      } else if (accounts?.find(c => c?.Code === value)) {
        setError(p => ({...p, [name]: 'This code already exists. Please enter a new code.'}));
      } else {
        setError(p => ({...p, [name]: ''}));
      }
      setCodes(p => ({...p, [name]: value}));
    },
    [accounts],
  );

  const getClearingAccounts = async () => {
    try {
      await getCreditlineAccounts(isMYOBAdaptor ? {Types: undefined} : undefined).then((data: IAccounts[]) => {
        const c = data.map(account => account.Code);
        let clearingAccount = '';
        const findClearingAccount = data?.find(a => a?.Name === clearingAccountDesc);
        if (!findClearingAccount) {
          clearingAccount = isXeroAdaptor ? getMissingCode(c, 800, 899) : getMissingCode(c, 1501, 1599, '2');
        } else {
          clearingAccount = findClearingAccount?.Code;
          handleIsAccountCreated({clearingAccount: true});
          setCreatedClearingAccount(findClearingAccount);
        }
        setCodes(p => ({...p, clearingAccount: clearingAccount}));
        setAccounts(data);
      });
    } catch (errors) {
      console.warn(errors);
    }
  };

  const getParentAccounts = async () => {
    try {
      await parentAccount().then(data => {
        const clearingAccount = data.Value.find(item => item.Name === 'Credit Cards');
        if (clearingAccount) {
          handleParentAccountCreated({clearingAccountID: clearingAccount.ID});
        }
      });
    } catch (errors) {
      console.warn(errors);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSaveLoading(true);
      const response = await getSupplierById(supplierDetails?.SupplierID);
      await saveSupplier({...response, CreditLineAccountID: createdClearingAccount?.ID});
      setIsSaveLoading(false);
      handleComplete();
    } catch {}
  };

  const setCreatedAccount = (account: IAccounts) => {
    setCreatedClearingAccount(account);
  };

  const isNextButtonDisable = useMemo(() => {
    return Object.values(isAccountCreated).some(val => !val);
  }, [isAccountCreated]);

  const handleIsAccountCreated = (newState: Partial<typeof isAccountCreated>) =>
    setIsAccountCreated(prevState => ({
      ...prevState,
      ...newState,
    }));

  const handleParentAccountCreated = (newState: Partial<typeof parentAccountsID>) =>
    setParentAccountsID(prevState => ({
      ...prevState,
      ...newState,
    }));

  return (
    <div className="relative h-full w-full overflow-hidden bg-white pb-24">
      <div className="mx-auto flex h-full w-full flex-col justify-between overflow-y-auto overflow-x-hidden sm:max-w-[622px] sm:pb-0">
        <div className="pb-3 sm:pb-0">
          <div className={`mx-auto mt-4 !max-w-[622px] align-middle`}>
            <p className={`text-left font-poppins text-[20px] font-medium text-[#333] sm:text-2xl sm:font-light`}>
              Let’s get your trade account set up
            </p>
            <p className={`mt-6 text-left text-sm font-normal text-[#999999] sm:mt-2`}>
              We'll create a Capricorn trade account in your financial system that will record credits and debits for
              each transaction on your Capricorn statement. Starting with a fresh account means an easy transition to
              your integrated statement and avoids crossover with any old transactions. We recommend having your
              bookkeeper review this before proceeding.
            </p>
          </div>
          <div className={`mx-auto mt-4 !max-w-[622px] align-middle`}>
            <CreateAccountInputComponent
              onChange={e => onChange(e)}
              name="clearingAccount"
              error={error?.clearingAccount}
              code={codes?.clearingAccount}
              description={clearingAccountDesc}
              accountType={ChartsOfAccountType.CurrentLiability}
              accountTaxType={ChartsOfAccountTaxType.BasExcluded}
              accountClass={ChartsOfAccountClass.Liability}
              isEnablePayments={true}
              isAccountCreated={isAccountCreated?.clearingAccount}
              handleIsAccountCreated={handleIsAccountCreated}
              parentAccountID={parentAccountsID?.clearingAccountID}
              setCreatedAccount={setCreatedAccount}
            />
            <div className="mt-5 flex flex-row items-center rounded-[6px] bg-[#f6f6f6] p-2.5 text-xs font-normal text-black-800 sm:mt-8">
              <IconTipBulb className="mr-2.5 !h-[29px] !w-[22px]" />
              If you need assistance with reconciling your statements, click the 'Live Chat' option below to schedule a
              session with our Customer Success team.
            </div>
          </div>
        </div>
        <ResponsiveBookOnboardingButton />
      </div>
      <PSBLOnboardingFooter
        isChatWidget
        isDisabled={isNextButtonDisable}
        onPrimaryClick={() => handleSubmit()}
        isLoading={isSaveLoading}
      />
    </div>
  );
};
