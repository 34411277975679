import React, {PropsWithChildren, createContext, useContext, useMemo, useState} from 'react';
import {ApplicationInfo} from '../model/constants/ApplicationInfo';
import {IMasterAdaptor} from '../model/MasterAdaptor';
import useMasterAdaptorAPI from '../services/useMasterAdaptorAPI';
import AppContext from './app/appContext';
import {useFeatureFlags} from '../hooks/useFeatureFlags';
import {FinancialAdaptorsType, MasterAdaptors} from '../model/constants/Constants';

const displayNames: Record<FinancialAdaptorsType, string> = {
  [FinancialAdaptorsType.MyObAccountRightLive]: 'MYOB AccountRight',
  [FinancialAdaptorsType.QuickBooksOnline]: 'QuickBooks',
  [FinancialAdaptorsType.Xero]: 'Xero',
  [FinancialAdaptorsType.Parody]: 'Parody',
};

interface IntegrationContextValue {
  adaptors: IMasterAdaptor[];
  financialAdaptor: IMasterAdaptor | undefined;
  getAdaptors: (isRefresh?: boolean) => Promise<IMasterAdaptor[]>;
  isAPFinancialAdaptorSetup: () => boolean;
  setAdaptors: (adaptors: IMasterAdaptor[]) => void;
  isMYOBAdaptor: boolean;
  isXeroAdaptor: boolean;
  isQuickBooksAdaptor: boolean;
  isParodyAdaptor:boolean;
  financialAdaptorDisplayName?: string;
}

export const IntegrationContext = createContext<IntegrationContextValue>({
  adaptors: [],
  financialAdaptor: undefined,
  getAdaptors: (_isRefresh?: boolean) => new Promise<IMasterAdaptor[]>(resolve => resolve([])),
  isAPFinancialAdaptorSetup: () => false,
  setAdaptors: (_adaptors: IMasterAdaptor[]) => {},
  isMYOBAdaptor: false,
  isXeroAdaptor: false,
  isQuickBooksAdaptor: false,
  isParodyAdaptor: false,
  financialAdaptorDisplayName: undefined,
});

export const IntegrationProvider: React.FC<PropsWithChildren> = ({children}) => {
  const {tenantInfo} = useContext(AppContext);
  const [adaptors, setAdaptors] = useState<IMasterAdaptor[]>([]);
  const {apQboIntegration} = useFeatureFlags().tenantOwned();

  const {search} = useMasterAdaptorAPI();

  const tenantId = tenantInfo?.TenantUserDetails?.TenantID || 0;

  const getAdaptors = async (isRefresh?: boolean) => {
    if (isRefresh || adaptors.length === 0) {
      // fetch adaptors
      const adaptorsResponse = await search(tenantId, ApplicationInfo.WebsiteId);
      return adaptorsResponse.filter((account: IMasterAdaptor) => account.Type === 'Accounts');
    } else {
      return adaptors;
    }
  };

  const financialAdaptor = adaptors.find(
    adaptor => adaptor.Type === 'Accounts' && Boolean(adaptor.ID) && adaptor.IsUsedByTenant,
  );

  const isAPFinancialAdaptorSetup = (): boolean => {
    const xeroOrMyObAdaptor = adaptors.find(
      adaptor =>
        adaptor.Type === 'Accounts' &&
        (adaptor.MasterAdaptorID === MasterAdaptors.Xero ||
          adaptor.MasterAdaptorID === MasterAdaptors.Myob ||
          adaptor.MasterAdaptorID === MasterAdaptors.Parody ||
          (apQboIntegration && adaptor.MasterAdaptorID === MasterAdaptors.Quickbooks)) &&
        Boolean(adaptor.ID) &&
        adaptor.IsUsedByTenant,
    );
    return Boolean(xeroOrMyObAdaptor);
  };

  const isMYOBAdaptor = useMemo(
    () => financialAdaptor?.Name === FinancialAdaptorsType.MyObAccountRightLive,
    [financialAdaptor],
  );
  const isXeroAdaptor = useMemo(() => financialAdaptor?.Name === FinancialAdaptorsType.Xero, [financialAdaptor]);
  const isQuickBooksAdaptor = useMemo(
    () => financialAdaptor?.Name === FinancialAdaptorsType.QuickBooksOnline,
    [financialAdaptor],
  );
  const isParodyAdaptor = useMemo(() => process.env.REACT_APP_ENVIRONMENT !== 'production' && financialAdaptor?.Name === FinancialAdaptorsType.Parody, [financialAdaptor]);

  const financialAdaptorDisplayName = useMemo(
    () =>
      financialAdaptor?.Name ? displayNames[financialAdaptor?.Name as FinancialAdaptorsType] : financialAdaptor?.Name,
    [financialAdaptor?.Name],
  );

  return (
    <IntegrationContext.Provider
      value={{
        adaptors,
        getAdaptors,
        setAdaptors,
        isAPFinancialAdaptorSetup,
        financialAdaptor,
        isMYOBAdaptor,
        isXeroAdaptor,
        isParodyAdaptor,
        isQuickBooksAdaptor,
        financialAdaptorDisplayName,
      }}
    >
      {children}
    </IntegrationContext.Provider>
  );
};
