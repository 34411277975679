import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {CSVLink} from 'react-csv';
import {css} from 'glamor';
import moment from 'moment';
import {IPaymentBatch, IPaymentBatchCreditLine} from '../../model/payment-batch/PaymentBatch';
import {
  AuthorisationStatus,
  LabelPaymentBatchCrDrStatusTypes,
  LabelPaymentBatchStatusColors,
  LabelPaymentBatchStatusTypes,
  PaymentBatchStatusTypes,
  PaymentMethodTypes,
  SyncStatuses,
} from '../../model/constants/Constants';
import {Layout} from '../../components/layout/Layout';
import {SButton} from '../../components/buttons/Button';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {AUTH_BUYING_AP_REPORTS} from '../../routes/AccountsPayableRoutes';
import {usePaymentBatchAPI} from '../../services/usePaymentBatchAPI';
import {BatchStatus} from '../../components/purchasing/BatchStatus';
import {PriceFormat} from '../../utils/formatter';
import {TableContainer, Table, TableBody, TableCell, TableHead, TableRow, makeStyles} from '@material-ui/core';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import FailedIcon from '../../assets/svg/accounts-payable/failed-Icon.svg';
import {SendRemittanceModal} from '../../components/accountsPayableOnboarding/SendRemittanceModal';
import STooltip from '../../components/data-display/Tooltip';
import {SendRemittanceAdviceModal} from '../../components/accountsPayableOnboarding/SendRemittanceAdviceModal';
import expandIcon from '../../assets/svg/accounts-payable/report-expand-icon.svg';
import collapseIcon from '../../assets/svg/accounts-payable/report-collapsible-icon.svg';
import downloadCsv from '../../assets/svg/accounts-payable/download-csv.svg';
import {APReportDetailPurchaseInvoices} from './APReportDetailPurchaseInvoices';
import disableExclamation from '../../assets/svg/accounts-payable/red-exclamation.svg';
import APTooltip from '../../components/data-display/APTootip';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import {IntegrationContext} from '../../context/IntegrationContext';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {Typography} from 'spenda-ui-react';
import _ from 'lodash';

const listContainer = css({
  boxShadow: '0px 0px 6px 0px #D3E5EF',
  fontFamily: 'poppins',
  '& p.leading-5 pre': {
    minWidth: '130px',
  },
});

const reportTitle = css({
  fontSize: '22px',
});

const borderBottom = css({
  borderBottom: '1px solid #D8D8D8',
});

const backButton = css({
  borderRadius: '6px',
  border: '1px solid #1C78AD',
  padding: '7px',
});

const useReportStyles = makeStyles({
  reportsTable: {
    borderRadius: '2px 2px 2px 2px',
    margin: '28px 0 0 0',
    maxHeight: 'calc(100vh - 27em)',
    '& table': {
      borderBottom: '2px solid #F8F8F8',
      '& th': {
        color: '#999999',
        fontWeight: 600,
        fontSize: '14px',
        fontFamily: 'Poppins',
      },
    },
  },
});
const statusBtn = css({
  height: '24px',
  padding: '3px 10px',
  minWidth: '88px',
  textAlign: 'center',
});

const tooltipIcon = css({
  height: '24px',
  width: '26px',
});

const headers = [
  {label: 'Payee Name', key: 'supplierName'},
  {label: 'Payment Description', key: 'description'},
  {label: 'Bank Account', key: 'bankAccountNumber'},
  {label: 'Bank BSB', key: 'bankBSB'},
  {label: 'Transaction Amount', key: 'paymentAmount'},
  {label: 'Payment Status', key: 'mappedStatus'},
];

const renderPaymentLineStatus = (credit: IPaymentBatchCreditLine) => {
  switch (credit?.mappedStatus) {
    case LabelPaymentBatchCrDrStatusTypes.Open:
      return (
        <span
          {...css({color: '#8178CF', backgroundColor: '#8178CF20'})}
          className={`rounded px-3 py-1 text-xs font-medium ${statusBtn}`}
        >
          {credit?.mappedStatus}
        </span>
      );
    case LabelPaymentBatchCrDrStatusTypes.InProgress:
      return (
        <span
          {...css({color: '#8178CF', backgroundColor: '#8178CF20'})}
          className={`rounded px-3 py-1 text-xs font-medium ${statusBtn}`}
        >
          {credit?.mappedStatus}
        </span>
      );
    case LabelPaymentBatchCrDrStatusTypes.Paid:
      return (
        <span
          {...css({color: '#3D9F78', backgroundColor: '#3D9F7820'})}
          className={`rounded px-3 py-1 text-xs font-medium ${statusBtn}`}
        >
          {credit?.mappedStatus}
        </span>
      );
    case LabelPaymentBatchCrDrStatusTypes.Failed:
      return (
        <>
          <span
            {...css({color: '#B9624B', backgroundColor: '#B9624B20'})}
            className={`rounded px-3 py-1 text-xs font-medium ${statusBtn}`}
          >
            {credit?.mappedStatus}
          </span>
          <STooltip
            title={`Error : ${credit?.errorMessage !== null ? credit?.errorMessage : 'Information not available'}`}
            placement="top-start"
          >
            <span className="px-0 py-0 text-xs font-medium ">
              <img
                src={FailedIcon}
                className={`${tooltipIcon} cursor-pointer pl-2`}
                alt="Info"
                data-autoid={`imgFailed`}
              />
            </span>
          </STooltip>
        </>
      );
    case LabelPaymentBatchCrDrStatusTypes.Cleared:
      return (
        <span
          {...css({color: '#8178CF', backgroundColor: '#8178CF20'})}
          className={`rounded px-3 py-1 text-xs font-medium ${statusBtn}`}
        >
          {credit?.mappedStatus}
        </span>
      );
    default:
      return credit?.mappedStatus;
  }
};

const renderPaymentStatus = (batchDetails: IPaymentBatch | undefined) => {
  const color = batchDetails?.status && LabelPaymentBatchStatusColors[PaymentBatchStatusTypes[batchDetails.status]];
  const batchStatus =
    batchDetails?.status === PaymentBatchStatusTypes.Failed &&
    batchDetails?.authorisationStatus === AuthorisationStatus.Rejected
      ? PaymentBatchStatusTypes.Cancelled
      : batchDetails?.mappedStatus;
  return (
    <>
      <span
        {...css({color: color, border: `3px solid ${color}`})}
        className="font-lg my-2 rounded-lg px-10 py-2 text-lg"
        data-autoid="txtBatchStatusBadge"
      >
        {batchStatus}
      </span>
      {(batchDetails?.mappedStatus === LabelPaymentBatchStatusTypes.Incomplete ||
        batchDetails?.mappedStatus === LabelPaymentBatchStatusTypes.Completed) &&
        batchDetails?.paymentSubmittedDateTime_utc && (
          <span className="text-xs text-spenda-labeltext">
            {batchStatus} on - {moment.utc(batchDetails?.paymentSubmittedDateTime_utc).local().format('DD MMM YYYY')}
          </span>
        )}
    </>
  );
};

export const APReportDetail = () => {
  const classes = useReportStyles();
  const history = useHistory();
  const {batchID} = useParams<{batchID: string}>();
  const [batchDetails, setBatchDetails] = useState<IPaymentBatch>();
  const [remittanceDetails, setRemittanceDetails] = useState<IPaymentBatchCreditLine | undefined>();
  const [remittanceAdviceBulk, setRemittanceAdviceBulk] = useState<boolean>(false);
  const [collapseView, setCollapseView] = useState<boolean>(false);
  const {getPaymentBatch, isLoading} = usePaymentBatchAPI();
  const {isAPFinancialAdaptorSetup, financialAdaptor} = useContext(IntegrationContext);
  const adaptorName = financialAdaptor?.Name === 'MyObAccountRightLive' ? 'MyOB' : financialAdaptor?.Name;
  const [invoicesCount, setInvoicesCount] = useState<number>(0);
  const {t2TV3} = useFeatureFlags().tenantOwned();

  const handleRemittanceClick = (credit: IPaymentBatchCreditLine) => {
    setRemittanceDetails(credit);
  };

  const handleRemittanceClose = () => {
    setRemittanceDetails(undefined);
  };

  useEffect(() => {
    if (batchID) {
      fetchBatchDetails();
    }
  }, []);

  const fetchBatchDetails = async () => {
    const _batchDetails = await getPaymentBatch(batchID);
    _batchDetails.mappedStatus = LabelPaymentBatchStatusTypes[_batchDetails.status];
    _batchDetails.credits.map(batch => {
      if (
        _batchDetails.status === PaymentBatchStatusTypes.ProcessingDebit ||
        _batchDetails.status === PaymentBatchStatusTypes.DebitSuccessful ||
        _batchDetails.status === PaymentBatchStatusTypes.ProcessingCredit
      ) {
        batch.mappedStatus = LabelPaymentBatchCrDrStatusTypes.InProgress;
      } else {
        batch.mappedStatus = batch.status && LabelPaymentBatchCrDrStatusTypes[batch.status];
      }
    });
    setBatchDetails(_batchDetails);
    const count = _.sumBy(_batchDetails.credits, obj => obj?.purchaseInvoices?.length || 0);
    setInvoicesCount(count);
  };

  const handleBackClick = () => {
    history.push({
      pathname: AUTH_BUYING_AP_REPORTS,
    });
  };

  const handleRemittanceAdviceBulk = () => {
    setRemittanceAdviceBulk(true);
  };

  const handleRemittanceAdviceBulkClose = (refresh?: boolean) => {
    setRemittanceAdviceBulk(false);
    if (refresh) {
      fetchBatchDetails();
    }
  };

  const handleExpandPanel = () => {
    setCollapseView(!collapseView);
  };

  const reportDetailPanel = (
    <div className={`flex h-full flex-col`}>
      <div className={`${listContainer} invoicesList h-full rounded bg-white p-2.5`}>
        <div className={`flex justify-between pb-2.5 text-lg font-medium text-spenda-primarytext ${borderBottom}`}>
          <div className="flex items-center">
            <button
              data-autoid="btnBack"
              onClick={handleBackClick}
              type="button"
              {...backButton}
              className="mr-5 flex items-center"
            >
              <ArrowBackRoundedIcon {...css({color: '#1C78AD'})} data-autoid={`imgBack`} />
            </button>
            <h2 className={`font-light text-spenda-primarytext ${reportTitle}`}>{batchDetails?.refNumber} report</h2>
          </div>
          <div className="flex flex-row">
            <CSVLink headers={headers} data={batchDetails?.credits || []} filename={'report_details.csv'}>
              <SButton
                {...css({whiteSpace: 'normal'})}
                width="93px"
                height="40px"
                borderRadius="6px"
                border="1px solid #1C78AD"
                margin="0 0.5rem"
                color="transparent"
                textColor="blueShade"
                lineHeight="l"
                boxShadow="none"
                fontFamily="poppins"
                fontSize="14px"
                data-autoid="btnCSV"
              >
                <img src={downloadCsv} className="mr-3 cursor-pointer" data-autoid={`imgCSV`} />
                CSV
              </SButton>
            </CSVLink>

            <SButton
              borderRadius="6px"
              height="40px"
              border="1px solid #1C78AD"
              margin="0 0.5rem 0 0"
              color="blueShade"
              textColor="white"
              lineHeight="l"
              boxShadow="none"
              fontFamily="poppins"
              fontSize="14px"
              padding="0px 15px"
              data-autoid="btnSendBulkRemittance"
              disabled={
                batchDetails?.status &&
                [
                  PaymentBatchStatusTypes.Open,
                  PaymentBatchStatusTypes.ReadyForApproval,
                  PaymentBatchStatusTypes.PaymentApproved,
                  PaymentBatchStatusTypes.Incomplete,
                  PaymentBatchStatusTypes.Failed,
                ].includes(batchDetails?.status)
              }
              onClick={handleRemittanceAdviceBulk}
            >
              Send Bulk Remittance
            </SButton>
            <div className={`expandableView cursor-pointer`}>
              <img
                src={collapseView ? expandIcon : collapseIcon}
                onClick={handleExpandPanel}
                data-autoid="imgCollapsibleIcon"
              />
            </div>
          </div>
        </div>
        <div className={`relative mt-7 flex h-full flex-col items-start justify-start bg-white`}>
          <div className="flex w-full flex-row items-center justify-between">
            <div className="flex items-center gap-5">
              <div
                {...css({backgroundColor: '#FAFAFA', border: '1px solid #E1E1E1'})}
                className="min-w-min rounded p-4 text-base text-spenda-primarytext"
              >
                <p className="flex leading-5">
                  <pre className="inline-block font-poppins text-base text-spenda-primarytext">Batch name:</pre>
                  <span data-autoid="lblBatchName">
                    <b>{batchDetails?.name}</b>
                  </span>
                </p>
                <p className="flex leading-5">
                  <pre className="inline-block font-poppins text-base text-spenda-primarytext">Pay from:</pre>
                  <span data-autoid="lblPaymentDetails">
                    <b>
                      {batchDetails?.debits[0]?.cardholderName
                        ? `${batchDetails?.debits[0]?.cardholderName} ${'-'} ${
                            batchDetails?.debits[0]?.cardType ? batchDetails?.debits[0]?.cardType : ''
                          } ${' '}
                  ${batchDetails?.debits[0]?.last4}`
                        : batchDetails?.debits[0]?.bankAccountName
                          ? `${batchDetails?.debits[0]?.bankAccountName} ${'-'} ${batchDetails?.debits[0]?.bankBSB} ${' '}
                  ${batchDetails?.debits[0]?.bankAccountNumber}`
                          : null}
                      {batchDetails?.debits?.[0]?.paymentMethodName === PaymentMethodTypes.Airplus
                        ? `${batchDetails?.debits?.[0]?.friendlyName} - ${batchDetails?.debits[0]?.last4}`
                        : null}
                    </b>
                  </span>
                </p>
                <p className="flex leading-5">
                  <pre className="inline-block font-poppins text-base text-spenda-primarytext">Pay method:</pre>
                  <span data-autoid="lblPaymentMethod">
                    <b>
                      {batchDetails?.debits?.[0]?.paymentMethodName === PaymentMethodTypes.Airplus
                        ? 'AirPlus'
                        : batchDetails?.debits[0]?.cardholderName
                          ? 'Credit Card'
                          : batchDetails?.debits[0]?.bankAccountName
                            ? 'Bank Account'
                            : '-'}
                    </b>
                  </span>
                </p>
                <p className="flex leading-5">
                  <pre className="inline-block font-poppins text-base text-spenda-primarytext">Payment date:</pre>
                  <span data-autoid="lblPaymentDate">
                    <b>{moment(batchDetails?.dueDate, 'YYYY-MM-DD').format('DD MMM YYYY')}</b>
                  </span>
                </p>
              </div>
              <div className="w-[514px] min-w-min rounded border border-[#E1E1E1] bg-[#FAFAFA] p-4 pb-4 text-base text-spenda-primarytext">
                <Typography className="text-[#CCCCCC]">Payment allocation:</Typography>

                <div className="mt-2 flex gap-[3rem]">
                  <div>
                    <Typography variant="paragraph" className="flex gap-8">
                      Batch ref ID:
                      <span className="ml-2 font-semibold">{batchDetails?.refNumber}</span>
                    </Typography>
                    <Typography variant="paragraph" className="mt-2 flex gap-3">
                      No. of invoices:
                      <span className="ml-2 font-semibold">
                        {batchDetails?.abaFileID ? batchDetails.countCredits : invoicesCount}
                      </span>
                    </Typography>
                  </div>
                  <div className="flex flex-col">
                    <Typography variant="paragraph" className="flex gap-8">
                      Batch total:
                      <span className="ml-2 font-semibold">{PriceFormat(batchDetails?.paymentAmount)}</span>
                    </Typography>
                    <Typography variant="paragraph" className="mt-2 flex gap-4">
                      Processing fee:
                      <span className="font-semibold">
                        {PriceFormat(batchDetails?.debits?.[0]?.payerFeeAmount) || '-'}
                      </span>
                    </Typography>
                    <Typography variant="paragraph" className="mt-2 flex gap-6">
                      Total amount:
                      <span className="font-semibold">
                        {PriceFormat(batchDetails?.debits?.[0]?.paymentAmountIncFees) || '-'}
                      </span>
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className="mr-2 flex flex-col items-end justify-end">
              <Typography variant="h3" className="text-[#CCCCCC]">
                Total Amount -{' '}
                {PriceFormat(batchDetails?.debits?.[0]?.paymentAmountIncFees) ||
                  PriceFormat(batchDetails?.paymentAmount)}
              </Typography>
              <div className="flex flex-col items-end justify-end">{renderPaymentStatus(batchDetails)}</div>
              {isAPFinancialAdaptorSetup() ? (
                <div>
                  {batchDetails?.status !== PaymentBatchStatusTypes.Open &&
                  batchDetails?.status !== PaymentBatchStatusTypes.Cancelled &&
                  batchDetails?.status !== PaymentBatchStatusTypes.Failed &&
                  !batchDetails?.abaFileID ? (
                    batchDetails?.syncLogs &&
                    batchDetails?.syncLogs?.length &&
                    batchDetails?.syncLogs[0]?.status === SyncStatuses.Synced ? (
                      <p className="flex">
                        <pre className="inline-block font-poppins text-base text-spenda-primarytext">
                          {adaptorName} sync -
                        </pre>
                        <span className="flex font-poppins text-base font-medium">
                          Batch exported to {adaptorName}{' '}
                          <CheckCircleRoundedIcon
                            className="ml-1 text-xs"
                            fontSize="small"
                            {...css({color: '#3D9F78'})}
                            data-autoid={`imgSync`}
                          />
                        </span>
                      </p>
                    ) : batchDetails?.syncLogs &&
                      batchDetails?.syncLogs?.length &&
                      (batchDetails?.syncLogs[0]?.status === SyncStatuses.Error ||
                        batchDetails?.syncLogs[0]?.status === SyncStatuses.Invalid) ? (
                      <p className="mt-2 flex">
                        <pre className="inline-block font-poppins text-base text-spenda-primarytext">{`  sync - `}</pre>
                        <span className="flex font-poppins text-base font-medium">
                          Export batch failed{' '}
                          <APTooltip
                            arrow
                            placement="bottom-end"
                            title={
                              <React.Fragment>
                                <p className="w-[150px] text-center font-poppins font-medium text-black-800">
                                  {batchDetails?.syncLogs[0]?.details !== null
                                    ? `${batchDetails?.syncLogs[0]?.details}. Wait for next schedule to run`
                                    : 'Wait for next schedule to run'}
                                </p>
                              </React.Fragment>
                            }
                          >
                            <img
                              className="ml-2 cursor-pointer"
                              src={disableExclamation}
                              data-autoid="imgDisableExclamationIcon"
                            />
                          </APTooltip>
                        </span>
                      </p>
                    ) : (
                      <p className="mt-2 flex">
                        <pre className="inline-block font-poppins text-base text-spenda-primarytext">
                          {adaptorName} sync -{' '}
                        </pre>
                        <span className="flex font-poppins text-base font-medium">Sync in progress</span>
                      </p>
                    )
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
          {batchDetails?.abaFileID === 0 ? (
            <APReportDetailPurchaseInvoices
              batchDetails={batchDetails}
              renderPaymentLineStatus={renderPaymentLineStatus}
              handleRemittanceClick={handleRemittanceClick}
              t2tV3={t2TV3}
            />
          ) : (
            <TableContainer className={`${classes.reportsTable} overflow-y-auto`}>
              <Table className="tableCellHeight" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Payee</TableCell>
                    <TableCell align="left">Payment description</TableCell>
                    <TableCell align="left">Bank account</TableCell>
                    <TableCell align="right">Txn amount</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="center" width="4%">
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {batchDetails?.credits.map((credit: IPaymentBatchCreditLine, index: number) => (
                    <TableRow key={index}>
                      <TableCell>
                        <span className="font-poppins text-base font-medium text-spenda-primarytext">
                          {credit.supplierName}
                        </span>
                      </TableCell>
                      <TableCell>
                        <span className="font-poppins text-base font-medium text-spenda-primarytext">
                          {credit.description}
                        </span>
                      </TableCell>
                      <TableCell>
                        <span className="font-poppins text-base font-medium text-spenda-primarytext">
                          {credit.bankBSB} {credit.bankAccountNumber}
                        </span>
                      </TableCell>
                      <TableCell align="right">
                        <span className="font-poppins text-base font-medium text-spenda-primarytext">
                          {PriceFormat(credit.paymentAmount)}
                        </span>
                      </TableCell>
                      <TableCell align="left">
                        <span className="flex items-center font-poppins text-base font-medium text-spenda-primarytext">
                          {credit.mappedStatus && renderPaymentLineStatus(credit)}
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <SendRoundedIcon
                          className={`${
                            credit.mappedStatus !== LabelPaymentBatchCrDrStatusTypes.Open ? 'cursor-pointer' : null
                          }`}
                          onClick={() =>
                            credit.mappedStatus !== LabelPaymentBatchCrDrStatusTypes.Open
                              ? handleRemittanceClick(credit)
                              : null
                          }
                          fontSize="small"
                          {...css({
                            color: `${
                              credit.mappedStatus !== LabelPaymentBatchCrDrStatusTypes.Open ? '#1C78AD' : '#C5C5C5'
                            }`,
                          })}
                          data-autoid={`lnkMappedStatus-${credit.supplierID}`}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </div>
  );

  if (isLoading) {
    return (
      <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
        <Layout
          splitWidthType={4}
          leftPanel={<LoadingIndicator isLoading={true} size="md" color="hsl(var(--primary))" />}
        />
      </div>
    );
  }

  if (!isLoading && !batchDetails) {
    return (
      <div
        className={`${css({
          boxShadow: '0px 0px 6px 0px #D3E5EF',
          height: 'calc(100% - 1rem)',
        })} relative mt-3 flex h-full items-center justify-center rounded bg-white`}
      >
        <span className="flex justify-center text-lg font-medium text-spenda-primarytext">No details found</span>
      </div>
    );
  }

  const mainPanel = <BatchStatus status={batchDetails?.status} actions={batchDetails?.actions} />;

  return (
    <>
      {Boolean(remittanceDetails) && remittanceDetails ? (
        <SendRemittanceModal remittanceDetails={remittanceDetails} handleClose={handleRemittanceClose} />
      ) : null}
      {remittanceAdviceBulk ? (
        <SendRemittanceAdviceModal
          credits={batchDetails?.credits || []}
          handleClose={handleRemittanceAdviceBulkClose}
        />
      ) : null}
      <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
        <Layout
          leftPanel={reportDetailPanel}
          mainPanel={collapseView && mainPanel}
          splitWidthType={collapseView ? 'AR' : 4}
        />
      </div>
    </>
  );
};
