import * as React from 'react';
const BlueInfoIcon = (props: React.SVGProps<SVGSVGElement> & { className?: string }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
      {...props}
    >
      <path
        className={props.className || 'fill-primary'}
        fillRule="evenodd"
        d="M9 0a9 9 0 109 9 9.01 9.01 0 00-9-9zm0 16.5A7.5 7.5 0 1116.5 9 7.508 7.508 0 019 16.5z"
        clipRule="evenodd"
      />
      <path
        className={props.className || 'fill-primary'}
        fillRule="evenodd"
        d="M9 7.5h-.75a.75.75 0 000 1.5H9v4.5a.75.75 0 001.5 0V9A1.5 1.5 0 009 7.5z"
        clipRule="evenodd"
      />
      <circle cx={9} cy={4.875} r={1.125} className={props.className || 'fill-primary'} />
    </svg>
);
export {BlueInfoIcon};
