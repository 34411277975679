import React, {useEffect, useState, useMemo, useContext} from 'react';
import {
  Box,
  DialogTitle,
  DialogContent,
  makeStyles,
  Typography,
  DialogActions,
  Divider,
  Tooltip,
} from '@material-ui/core';
import {SDialog} from '../modals/modalSpendaMeterialUI';
import {withStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import {
  ExpansionPanel as MuiAccordion,
  ExpansionPanelSummary as MuiAccordionSummary,
  ExpansionPanelDetails as MuiAccordionDetails,
} from '@material-ui/core';
import {
  AccountUsage,
  BankAccounts,
  PaymentMethods,
  PaymentProviderName,
  VerificationStatus,
} from '../../model/constants/Constants';
import {SButton} from '../buttons/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {css} from 'glamor';
import InfoOutlineIcon from '@material-ui/icons/ErrorOutline';
import {IOSSwitch} from '../form/SwitchSlider';
import {SuccessAnimation} from '../../assets/svg/animations/LottieAnimations';
import usePaymentServicesAPI, {ISavePaymentServiceMerchantPayload} from '../../services/usePaymentServicesAPI';
import useWorkflowAPI from '../../services/useWorkflowAPI';
import {AdaptorSyncDataActions, DatTypes} from '../../model/constants/Constants';
import HelpIcon from '@material-ui/icons/Help';
import _ from 'lodash';
import AppContext from '../../context/app/appContext';
import {IMasterAdaptor} from '../../model/MasterAdaptor';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LoadingIndicator from '../ui/LoadingIndicator';
import EmailIcon from '../../assets/svg/Email.svg';
import {IconANZ} from '../../assets/svg/bank-accounts/IconANZ';
import {AddBankAccount as AddBankAccountPopup} from '../payments/AddBankAccount';
import {PennyVerificationDialogStages} from '../dialog/PennyVerificationDialog';
import useSpendaPaymentServicesAPI, {IAccountResponse} from '../../services/useSpendaPaymentServicesAPI';
import {IconNAB} from '../../assets/svg/bank-accounts/IconNAB';
import {IconCommonWealth} from '../../assets/svg/bank-accounts/IconCommonWealth';
import {IconWestpac} from '../../assets/svg/bank-accounts/IconWestpac';
import {IconBank} from '../../assets/svg/payment-methods/IconBank';
import {ApplicationInfo} from '../../model/constants/ApplicationInfo';

// Styles
const useStyles = makeStyles({
  root: {
    position: 'absolute',
    '& .MuiPaper-rounded': {
      borderRadius: '20px',
      width: '375px',
      minHeight: '500px',
    },
    '& .MuiDialog-paperScrollPaper': {
      maxHeight: '100%',
    },
    '& .MuiDialogContent-root': {
      padding: '8px 10px',
    },
  },
  fontMedium: {
    fontWeight: 500,
  },
  fontBold: {
    fontWeight: 700,
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  icon: {
    fill: '#1C78AD',
  },
  selectroot: {
    color: '#444343',
    border: '1px solid #EAEAEA',
    textAlign: 'left',
    fontSize: '12px',
    padding: '5px',
    '&:hover': {
      border: '1px solid #EAEAEA',
    },
    '&:focus': {
      border: '1px solid #EAEAEA',
    },
  },
  shape: {
    background: '#F3F2F1',
    border: '1px solid #333',
    borderRadius: 14,
    boxShadow: '0 0 5px rgb(0 0 0 / 20%)',
    height: 166,
    // margin: '-22px auto',
    minWidth: 280,
    padding: '8px',
  },
  connectAccountCarousel: {
    '& .rec-arrow': {
      fontSize: '80%',
      minHeight: '24px !important',
      height: '24px !important',
      minWidth: '24px !important',
      width: '24px !important',
      lineHeight: 0,
    },
  },
  blueBorder: {
    borderColor: '#0082BA',
  },
});

const Accordion = withStyles({
  root: {
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:last-child': {
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
    '& .MuiSvgIcon-colorPrimary ': {
      color: '#1C78AD',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    flexDirection: 'column',
    '& .MuiDivider-root ': {
      backgroundColor: '#D8D8D8',
      margin: '1rem auto',
      alignSelf: 'center',
    },
    '& .MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track ': {
      backgroundColor: '#787880',
    },
  },
}))(MuiAccordionDetails);

// Enums
enum Tabs {
  Connect = 'Connect',
  Configure = 'Configure',
  Confirm = 'Confirm',
}

enum Panels {
  Bank = 'Bank',
  CreditCard = 'Credit Card',
}

enum Status {
  Enabled = 'enabled',
  Pending = 'pending',
}

enum PaymentServiceName {
  SplitPayments_Bank_Transfer = 'SplitPayments Bank Transfer',
  Mastercard_BPSP = 'Mastercard BPSP',
}

// Interfaces
interface IConnectAccountProps {
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
  onComplete?: () => void;
}

interface IAddBankAccountProps {
  getBankAccounts: (isCloseDialog: boolean) => void;
  bankAccountDetails: IAccountResponse | undefined;
  dialogStage: PennyVerificationDialogStages;
  setDialogStage: React.Dispatch<React.SetStateAction<PennyVerificationDialogStages>>;
  supplierId: number | undefined;
  progressHeader: () => JSX.Element;
  setAddBankAccountClicked: React.Dispatch<React.SetStateAction<boolean>>;
  addBankAccountClicked: boolean;
}

interface IConfirmAccountProps {
  closeDialog: () => void;
  onComplete?: () => void;
  isKycApproved: boolean | undefined;
}

interface IConnectConfirmProps {
  onNext: React.Dispatch<React.SetStateAction<Tabs>>;
  bankAccountDetails: IAccountResponse | undefined;
}

interface IConnectTabProps {
  setDialogStage: React.Dispatch<React.SetStateAction<PennyVerificationDialogStages>>;
}

interface ICustomerPaymentsFormProps {
  paymentDeliveryMethod: string | undefined;
  agreeToTermsAndConditions: boolean;
  payerFeePercentage?: number;
  flatFee?: number;
  percentageFee?: number;
  saved?: boolean;
  isCodeFetchedFromDB?: boolean;
  clearingAccountCode: string;
  paymentMethods: number;
}

interface IConfigureProps {
  onNext: React.Dispatch<React.SetStateAction<Tabs>>;
  isKycApproved: boolean | undefined;
  bankAccountDetails: IAccountResponse;
}

interface ISuccessfulBankTransferProps {
  PaymentMethod: string | undefined;
}

interface IAccordionSummaryDetailProps {
  title: string;
  saved: boolean | undefined;
  isKycApproved: boolean | undefined;
}

interface IAccordianPanelDetails {
  title: string;
  toolTipInfo: string;
  isKycApproved: boolean | undefined;
  formValues: ICustomerPaymentsFormProps;
  setFormValues: React.Dispatch<React.SetStateAction<ICustomerPaymentsFormProps>>;
  onClickSuccess: () => {};
  clearingAccountInfo: string;
}

// Form
const initialFormValues = {
  clearingAccountCode: '',
  paymentDeliveryMethod: 'consolidated',
  agreeToTermsAndConditions: false,
  payerFeePercentage: 0,
  percentageFee: 0,
  flatFee: 0,
  saved: false,
  isCodeFetchedFromDB: false,
  paymentMethods: 0,
};

// Progress Tabs
const tabs: Tabs[] = [Tabs.Connect, Tabs.Configure, Tabs.Confirm];

export const ConnectAccount = ({handleClose, onComplete}: IConnectAccountProps) => {
  const classes = useStyles();
  const aCtx = useContext(AppContext);

  const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.Connect);
  const [bankAccountDetails, setBankAccountDetails] = useState<IAccountResponse | undefined>();
  const [dialogStage, setDialogStage] = useState<PennyVerificationDialogStages>(PennyVerificationDialogStages.NONE);
  const [addBankAccountClicked, setAddBankAccountClicked] = useState<boolean>(false);
  const [isCloseDialog, setIsCloseDialog] = useState<boolean>(false);

  const {getAdaptorByType} = usePaymentServicesAPI();
  const {getAccounts, isLoading} = useSpendaPaymentServicesAPI();
  const {synkDataRead} = useWorkflowAPI();

  useEffect(() => {
    getConnectedAdaptor();
    getBankAccounts(true);
  }, []);

  useEffect(() => {
    if (dialogStage === PennyVerificationDialogStages.NONE && isCloseDialog) closeDialog();
  }, [dialogStage]);

  const getConnectedAdaptor = async () => {
    const response: IMasterAdaptor = await getAdaptorByType('Accounts');
    if (response.Name && response.ID) {
      await synkDataRead(false, AdaptorSyncDataActions.Read, response.Name, response.ID, DatTypes.Accounts, 15);
    }
  };

  const getBankAccounts = (isCloseDialog: boolean) => {
    setIsCloseDialog(isCloseDialog);
    getAccounts(PaymentProviderName.Spenda_Payment_Services, {accountUsage: AccountUsage.SUPPLIER})
      .then(accounts => {
        const supplierBankAcc = accounts?.find(account => account.usage === AccountUsage.SUPPLIER);
        if (supplierBankAcc && supplierBankAcc.verificationStatus === VerificationStatus.UNKNOWN) {
          supplierBankAcc.verificationStatus = VerificationStatus.NOT_SET;
        }
        setBankAccountDetails(supplierBankAcc);
        if (supplierBankAcc && supplierBankAcc?.verificationStatus !== VerificationStatus.VERIFIED)
          setDialogStage(PennyVerificationDialogStages.CHECKING_BANK_ACCOUNT_STATUS);
      })
      .catch(() => {
        closeDialog();
      });
  };

  const closeDialog = () => {
    handleClose(false);
  };

  const progressHeader = () => {
    return (
      <Box className="flex justify-around px-10 pb-4 pt-2">
        {tabs.map(tab => {
          return (
            <Typography
              key={tab}
              variant="h6"
              className={`${currentTab === tab ? `border-b-4 text-black-800 ${classes.blueBorder}` : 'text-gray-400'}`}
            >
              {tab}
            </Typography>
          );
        })}
      </Box>
    );
  };

  return (
    <>
      {dialogStage !== PennyVerificationDialogStages.NONE ? (
        <AddBankAccount
          addBankAccountClicked={addBankAccountClicked}
          setAddBankAccountClicked={setAddBankAccountClicked}
          getBankAccounts={getBankAccounts}
          bankAccountDetails={bankAccountDetails}
          dialogStage={dialogStage}
          setDialogStage={setDialogStage}
          progressHeader={progressHeader}
          supplierId={aCtx.marketplaceSupplier?.SupplierID}
        />
      ) : (
        <SDialog
          open
          onBackdropClick={closeDialog}
          onClose={closeDialog}
          maxWidth={'lg'}
          fullWidth
          className={classes.root}
        >
          <DialogTitle className="flex w-full max-w-full flex-col bg-white">{progressHeader()}</DialogTitle>
          {isLoading ? (
            <LoadingIndicator
              isLoading={isLoading}
              size="md"
              color="hsl(var(--primary))"
              position={{right: '46%', bottom: '50%'}}
            />
          ) : (
            <>
              {currentTab === Tabs.Connect &&
                bankAccountDetails?.verificationStatus === VerificationStatus.VERIFIED && (
                  <ConnectConfirm bankAccountDetails={bankAccountDetails} onNext={setCurrentTab} />
                )}
              {currentTab === Tabs.Connect &&
                bankAccountDetails?.verificationStatus !== VerificationStatus.VERIFIED && (
                  <ConnectTab setDialogStage={setDialogStage} />
                )}
              {currentTab === Tabs.Configure && bankAccountDetails && (
                <Configure
                  onNext={setCurrentTab}
                  isKycApproved={aCtx.tenant?.GoodToTrade}
                  bankAccountDetails={bankAccountDetails as IAccountResponse}
                />
              )}
              {currentTab === Tabs.Confirm && (
                <ConfirmAccount
                  closeDialog={closeDialog}
                  onComplete={onComplete}
                  isKycApproved={aCtx.tenant?.GoodToTrade}
                />
              )}
            </>
          )}
        </SDialog>
      )}
    </>
  );
};

const ConnectTab = ({setDialogStage}: IConnectTabProps) => {
  const classes = useStyles();
  return (
    <>
      <DialogContent className="flex flex-col items-center">
        <Box className="w-full px-8 text-center">
          <Typography variant="h5" className={`text-black-800 ${classes.fontMedium}`}>
            Connect Your Bank Account
          </Typography>
          <Typography className={`pb-2 pt-5 text-black-800`} style={{fontSize: '14px'}}>
            To add your bank account please click on the 'Add Bank Account' button shown below.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions className={`${classes.justifyCenter} mb-6 w-full`}>
        <SButton
          color="blueShade"
          width="156px"
          lineHeight="l"
          onClick={() => setDialogStage(PennyVerificationDialogStages.ADD_BANK_DETAILS)}
        >
          Add Bank Account
        </SButton>
      </DialogActions>
    </>
  );
};

const AddBankAccount = (props: IAddBankAccountProps) => {
  const {
    getBankAccounts,
    bankAccountDetails,
    dialogStage,
    setDialogStage,
    supplierId,
    progressHeader,
    setAddBankAccountClicked,
    addBankAccountClicked,
  } = props;

  const successCallback = () => {
    getBankAccounts(false);
  };

  return (
    <AddBankAccountPopup
      successCallBack={successCallback}
      supplierId={supplierId}
      dialogStage={dialogStage}
      setDialogStage={setDialogStage}
      bankAccountDetails={bankAccountDetails}
      addBankAccountClicked={addBankAccountClicked}
      setAddBankAccountClicked={setAddBankAccountClicked}
      dialogTitle={progressHeader}
      accountUsageContext={AccountUsage.SUPPLIER}
    />
  );
};

const ConnectConfirm = ({onNext, bankAccountDetails}: IConnectConfirmProps) => {
  const classes = useStyles();

  let bgGradientColor = '#dbdbdb';
  let fontColor = 'text-white';
  let paymentMethodIcon = undefined;

  switch (bankAccountDetails?.bankDetails?.bankName) {
    case BankAccounts.NAB:
      bgGradientColor = 'linear-gradient(145deg, rgba(0,0,0,1) 0%, rgba(65,65,65,1) 100%)';
      paymentMethodIcon = <IconNAB width="75" height="50" />;
      break;
    case BankAccounts.ANZ:
      bgGradientColor = '#004165';
      paymentMethodIcon = <IconANZ width="100" height="50" />;
      break;
    case BankAccounts.CBA:
      bgGradientColor = '#b9b39c';
      paymentMethodIcon = <IconCommonWealth width="160" height="50" />;
      break;
    case BankAccounts.WESTPAC:
      bgGradientColor = 'linear-gradient(180deg, rgba(237,229,200,0.605) 0%, rgba(121,119,114,1) 100%)';
      paymentMethodIcon = <IconWestpac width="100" height="50" />;
      break;
    default:
      bgGradientColor = '#dbdbdb';
      fontColor = '#ccc';
      paymentMethodIcon = <IconBank width="50" height="50" />;
      break;
  }

  const getBankAccountLast4Digit = () => {
    const bankAccNo = bankAccountDetails?.bankDetails?.bankAccountNumber;
    if (bankAccNo) {
      return `ACC ${'*'.repeat(bankAccNo.length - 4)}${bankAccNo.slice(bankAccNo.length - 4)}`;
    }
    return '';
  };

  return (
    <>
      <DialogContent className="flex flex-col items-center">
        <Box className="w-full px-8 text-center">
          <Typography variant="h5" className={`text-black-800 ${classes.fontMedium}`}>
            Your Bank Account Has Now Been Added
          </Typography>
          <Box className="flex justify-center py-5">
            <Box
              className={`relative flex h-full w-2/3 flex-col items-center justify-between rounded-xl p-2 ${fontColor}`}
              style={{background: bgGradientColor, borderRadius: '10px'}}
            >
              <Box className="pb-2">{paymentMethodIcon}</Box>
              <Typography>{getBankAccountLast4Digit()}</Typography>
            </Box>
          </Box>
          <Typography className={`pb-2 pt-5 text-black-800`} style={{fontSize: '14px'}}>
            This bank account is Configured as the destination account for payments taken through spenda.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions className={`${classes.justifyCenter} mb-6 w-full`}>
        <SButton color="blueShade" width="6rem" lineHeight="m-2" onClick={() => onNext(Tabs.Configure)}>
          Next
        </SButton>{' '}
      </DialogActions>
    </>
  );
};

const Configure = ({onNext, isKycApproved, bankAccountDetails}: IConfigureProps) => {
  const aCtx = useContext(AppContext);

  const [expanded, setExpanded] = useState<Panels | false>(Panels.Bank);
  const [bankAccountValues, setBankAccountValues] = useState<ICustomerPaymentsFormProps>({...initialFormValues});
  const [creditCardValues, setCreditCardValues] = useState<ICustomerPaymentsFormProps>({...initialFormValues});
  const [savedPaymentMethod, setSavedPaymentMethod] = useState<undefined | string>(undefined);

  const {
    savePaymentServiceMerchant,
    createClearingAccount,
    sendAMLKYCEmail,
    getMerchantPaymentConfig: getMerchantPaymentConfigAPI,
  } = usePaymentServicesAPI();

  const handleChange = (panel: Panels) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    // Close the success message dialog automatically after 3 seconds
    if (savedPaymentMethod) {
      setTimeout(() => {
        setSavedPaymentMethod(undefined);
      }, 3000);
    }
  }, [savedPaymentMethod]);

  useEffect(() => {
    getMerchantPaymentConfig(
      PaymentProviderName.Spenda_Payment_Services,
      PaymentServiceName.SplitPayments_Bank_Transfer,
      PaymentMethods.BankTransfer,
    );
    getMerchantPaymentConfig(
      PaymentProviderName.Spenda_Payment_Services,
      PaymentServiceName.Mastercard_BPSP,
      PaymentMethods.CreditCard,
    );
  }, []);

  const getMerchantPaymentConfig = async (
    paymentProviderName: string,
    paymentServiceName: string,
    paymentMethodID: number,
  ) => {
    const merchantConfig = await getMerchantPaymentConfigAPI(paymentProviderName, paymentServiceName, paymentMethodID);
    const stateValues = {
      agreeToTermsAndConditions: merchantConfig.isTermsAndConditionsAccepted,
      payerFeePercentage: merchantConfig.payerFeePercentage,
      flatFee: merchantConfig.flatFee,
      percentageFee: merchantConfig.percentageFee,
      clearingAccountCode: merchantConfig.clearingAccountCode,
      paymentMethods: merchantConfig.paymentMethods,
      isCodeFetchedFromDB: merchantConfig.clearingAccountCode ? true : false,
    };
    switch (paymentServiceName) {
      case PaymentServiceName.SplitPayments_Bank_Transfer:
        setBankAccountValues((current: ICustomerPaymentsFormProps) => ({
          ...current,
          ...stateValues,
          saved: merchantConfig.isTermsAndConditionsAccepted && merchantConfig.clearingAccountCode ? true : false,
        }));
        break;
      case PaymentServiceName.Mastercard_BPSP:
        setCreditCardValues((current: ICustomerPaymentsFormProps) => ({
          ...current,
          ...stateValues,
          saved: merchantConfig.isTermsAndConditionsAccepted && merchantConfig.clearingAccountCode ? true : false,
        }));
        break;
      default:
        break;
    }
  };

  const onSubmitBankAccount = async () => {
    // create clearing account
    if (bankAccountValues.clearingAccountCode) {
      await createClearingAccount({
        BankAccountNumber: bankAccountDetails?.bankDetails?.bankAccountNumber,
        BankBSB: bankAccountDetails?.bankDetails?.bankBSB,
        Code: bankAccountValues?.clearingAccountCode,
        Name: 'Spenda Bank Transfer Payments',
      });
    }

    setBankAccountValues((current: ICustomerPaymentsFormProps) => ({
      ...current,
      saved: true,
    }));

    const payload = {
      paymentProviderName: PaymentProviderName.Spenda_Payment_Services,
      paymentServiceName: PaymentServiceName.SplitPayments_Bank_Transfer,
      status: isKycApproved ? Status.Enabled : Status.Pending,
      deliveryMethod: bankAccountValues.paymentDeliveryMethod,
      payerFeePercentage: bankAccountValues.payerFeePercentage,
      clearingAccountCode: bankAccountValues.clearingAccountCode,
      paymentMethodID: PaymentMethods.BankTransfer,
    } as ISavePaymentServiceMerchantPayload;
    await savePaymentServiceMerchant(payload);

    setExpanded(Panels.CreditCard);
    setSavedPaymentMethod('bank transfer');
  };

  const onSubmitCreditCard = async () => {
    // create clearing account
    if (creditCardValues.clearingAccountCode) {
      await createClearingAccount({
        BankAccountNumber: bankAccountDetails?.bankDetails?.bankAccountNumber,
        BankBSB: bankAccountDetails?.bankDetails?.bankBSB,
        Code: creditCardValues?.clearingAccountCode,
        Name: 'Spenda Credit Card Payments',
      });
    }

    setCreditCardValues((current: ICustomerPaymentsFormProps) => ({
      ...current,
      saved: true,
    }));

    const payload = {
      paymentProviderName: PaymentProviderName.Spenda_Payment_Services,
      paymentServiceName: PaymentServiceName.Mastercard_BPSP,
      status: isKycApproved ? Status.Enabled : Status.Pending,
      deliveryMethod: creditCardValues.paymentDeliveryMethod,
      payerFeePercentage: creditCardValues.payerFeePercentage,
      clearingAccountCode: creditCardValues.clearingAccountCode,
      paymentMethodID: PaymentMethods.CreditCard,
    } as ISavePaymentServiceMerchantPayload;
    await savePaymentServiceMerchant(payload);

    // setExpanded(Panels.Panel1);
    setSavedPaymentMethod('credit card transfer');
  };

  const handleConfigureTabNextClick = async () => {
    if (!isKycApproved) {
      if (!aCtx?.user) return;
      await sendAMLKYCEmail({
        Subject: 'AML/KYC application',
        To: 'customersuccess@spenda.co',
        WebsiteID: ApplicationInfo.WebsiteId,
        DatTypeID: DatTypes.AMLKYCApplication,
      }).then((res: any) => {
        if (res) {
          onNext(Tabs.Confirm);
        }
      });
    } else {
      onNext(Tabs.Confirm);
    }
  };

  const classes = useStyles();
  return (
    <>
      {savedPaymentMethod && (
        <SDialog open onBackdropClick={() => {}} onClose={() => {}} maxWidth={'lg'} fullWidth className={classes.root}>
          <SuccessfulBankTransfer PaymentMethod={savedPaymentMethod} />
        </SDialog>
      )}
      <DialogContent>
        <Box className="w-full text-center">
          <Box className="mb-5">
            <Typography variant="h5" className={`text-black-800 ${classes.fontMedium}`}>
              Configure Customer Payment Options
            </Typography>
          </Box>
          <Accordion square expanded={expanded === Panels.Bank} onChange={handleChange(Panels.Bank)}>
            <AccordionSummary
              data-autoid="btnConfigureBank"
              aria-controls="panel1d-content"
              id="panel1d-header"
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              <AccordionSummaryDetail
                isKycApproved={isKycApproved}
                saved={bankAccountValues.saved}
                title="Customer Payments via Bank Account"
              />
            </AccordionSummary>
            <AccordionDetails>
              <AccordianPanelDetails
                toolTipInfo="Will be added to accounting system"
                title={`Bank Account payments are charged at ${bankAccountValues.percentageFee}% + $${bankAccountValues.flatFee}`}
                isKycApproved={isKycApproved}
                formValues={bankAccountValues}
                setFormValues={setBankAccountValues}
                onClickSuccess={onSubmitBankAccount}
                clearingAccountInfo="Spenda Bank Transfer Payments"
              />
            </AccordionDetails>
          </Accordion>
          <Accordion square expanded={expanded === Panels.CreditCard} onChange={handleChange(Panels.CreditCard)}>
            <AccordionSummary
              data-autoid="btnConfigureCreditCard"
              aria-controls="panel2d-content"
              id="panel2d-header"
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              <AccordionSummaryDetail
                isKycApproved={isKycApproved}
                saved={creditCardValues.saved}
                title="Customer Payments via Credit Card"
              />
            </AccordionSummary>
            <AccordionDetails>
              <AccordianPanelDetails
                toolTipInfo="Will be added to accounting system"
                title={`Credit Card payments are charged at ${creditCardValues.percentageFee}% + $${creditCardValues.flatFee}`}
                isKycApproved={isKycApproved}
                formValues={creditCardValues}
                setFormValues={setCreditCardValues}
                onClickSuccess={onSubmitCreditCard}
                clearingAccountInfo="Spenda Credit Card Payments"
              />
            </AccordionDetails>
          </Accordion>
        </Box>
      </DialogContent>
      <DialogActions className={`${classes.justifyCenter} mb-6 w-full`}>
        <SButton color="blueShade" width="6rem" lineHeight="m-2" onClick={handleConfigureTabNextClick}>
          Next
        </SButton>
      </DialogActions>
    </>
  );
};

const ConfirmAccount = ({closeDialog, isKycApproved, onComplete}: IConfirmAccountProps) => {
  const classes = useStyles();

  const handleDone = () => {
    closeDialog();
    if (onComplete) {
      onComplete();
    }
  };

  return (
    <>
      {isKycApproved ? (
        <DialogContent className="flex items-center">
          <Box className="w-full p-8 text-center">
            <Typography variant="h6" className={`text-black-800 ${classes.fontMedium}`} style={{fontSize: '21px'}}>
              ID confirmation completed
            </Typography>
            <Box display="flex" justifyContent="center" style={{marginTop: '40px'}}>
              <Box width="40%">
                <SuccessAnimation />
              </Box>
            </Box>
          </Box>
        </DialogContent>
      ) : (
        <DialogContent>
          <Box className="w-full p-8 text-center">
            <Typography variant="h6" className={`pb-8 text-black-800 ${classes.fontMedium}`} style={{fontSize: '21px'}}>
              Your email has been sent. We will contact you shortly to complete an ID application.
            </Typography>
            <img className="m-auto h-16" src={EmailIcon} data-autoid="imgConfirmMerchantOnboardingSent" />
          </Box>
        </DialogContent>
      )}
      <DialogActions className={`${classes.justifyCenter} mb-6 w-full`}>
        <SButton color="blueShade" width="156px" lineHeight="m-2" onClick={handleDone}>
          Done
        </SButton>
      </DialogActions>
    </>
  );
};

const SuccessfulBankTransfer = ({PaymentMethod}: ISuccessfulBankTransferProps) => {
  const classes = useStyles();
  return (
    <>
      <DialogContent className="flex items-center">
        <Box className="w-full p-8 text-center">
          <Typography variant="h6" className={`text-black-800 ${classes.fontMedium}`} style={{fontSize: '21px'}}>
            Your {PaymentMethod} has been successfully enabled.
          </Typography>
          <Box display="flex" justifyContent="center" style={{marginTop: '40px'}}>
            <Box width="40%">
              <SuccessAnimation />
            </Box>
          </Box>
          {/* <Typography className={`text-black-800  w-full`} style={{marginTop: '40px'}}>
            You can ‘Continue Onboarding’ or ‘Go To Homepage’
          </Typography> */}
        </Box>
      </DialogContent>
      {/* <DialogActions className={`${classes.justifyCenter} w-full mb-8 flex-col`}>
        <SButton color="blueShade" width="156px" lineHeight="m-2">
          <Typography variant="h6" style={{fontSize: '18px'}}>
            Continue Onboarding
          </Typography>
        </SButton>
        <Link to="/menu" className="text-blue-600 cursor-pointer mt-4">
          <Typography variant="h6" style={{fontSize: '18px'}}>
            Go To Homepage
          </Typography>
        </Link>
      </DialogActions> */}
    </>
  );
};

const AccordionSummaryDetail = ({title, saved, isKycApproved}: IAccordionSummaryDetailProps) => {
  return (
    <Box className="flex items-center">
      {saved ? (
        isKycApproved ? (
          <CheckCircleIcon style={{color: '#4da128', height: '30px', width: '30px', marginRight: '0.75rem'}} />
        ) : (
          <InfoOutlineIcon style={{color: '#cc9631', height: '30px', width: '30px', marginRight: '0.75rem'}} />
        )
      ) : (
        <InfoOutlineIcon style={{color: '#1C78AD', height: '30px', width: '30px', marginRight: '0.75rem'}} />
      )}
      <Typography {...css({fontSize: '15px !important', fontWeight: '700 !important', textAlign: 'left'})}>
        {title}
      </Typography>
    </Box>
  );
};

const AccordianPanelDetails = ({
  title,
  toolTipInfo,
  isKycApproved,
  formValues,
  setFormValues,
  onClickSuccess,
  clearingAccountInfo,
}: IAccordianPanelDetails) => {
  const {validateExistingAccountbycode, isLoading} = usePaymentServicesAPI();
  const [isDuplicateCode, setIsDuplicateCode] = useState<boolean>(false);
  const [isInvalidCode, setIsvalidCode] = useState<boolean>(false);

  const disableBtn = () => {
    if (isInvalidCode) return isInvalidCode;
    const copiedValues = {...formValues};
    delete copiedValues.saved;
    delete copiedValues.flatFee;
    delete copiedValues.percentageFee;
    delete copiedValues.payerFeePercentage;
    delete copiedValues.isCodeFetchedFromDB;
    return Object.values(copiedValues).find(value => !Boolean(value)) !== undefined || isDuplicateCode;
  };

  const checkDuplicateClearingAccountCode = async (value: string | undefined) => {
    if (value) {
      const isDuplicate = await validateExistingAccountbycode(value);
      setIsDuplicateCode(isDuplicate);
    } else {
      setIsDuplicateCode(false);
    }
  };

  useEffect(() => {
    if (formValues.isCodeFetchedFromDB) {
      setFormValues((current: ICustomerPaymentsFormProps) => ({
        ...current,
        isCodeFetchedFromDB: false,
      }));
    } else {
      if (formValues.clearingAccountCode && !/^[0-9.-]+$/gm.test(formValues.clearingAccountCode)) {
        setIsvalidCode(true);
      } else {
        setIsvalidCode(false);
        !isInvalidCode && debouncedEventHandler(formValues.clearingAccountCode);
      }
    }
  }, [formValues.clearingAccountCode]);

  const debouncedEventHandler = useMemo(() => _.debounce(checkDuplicateClearingAccountCode, 300), []);
  const key = useMemo(() => title.split(' ', 2).join(''), []);

  return (
    <>
      <Typography
        variant="body2"
        style={{marginLeft: '2.62rem', marginTop: '-1.25rem', width: '60%', textAlign: 'left'}}
      >
        {title}{' '}
        <Tooltip
          arrow
          placement={'bottom-start'}
          title={`To discuss your rates, please contact our sales team on 1300 682 521.`}
        >
          <InfoOutlineIcon
            className="cursor-pointer"
            style={{height: '1rem', width: '1.5rem', marginTop: '-2px', color: '#1c78ad'}}
          />
        </Tooltip>
      </Typography>
      <Divider className="my-4 w-40" />
      <Box className="flex items-center justify-between">
        <Typography
          variant="body2"
          className="text-left"
          style={{
            flex: 1,
          }}
        >
          Clearing Accounts{' '}
          <Tooltip title={toolTipInfo}>
            <HelpIcon
              className="cursor-pointer"
              style={{height: '1rem', width: '1rem', marginTop: '-2px', color: '#1c78ad'}}
            />
          </Tooltip>
        </Typography>
        <Box
          style={{
            flex: 1,
          }}
        >
          <Box className="flex items-center">
            <input
              maxLength={10}
              data-autoid={`txt${key}COA`}
              value={formValues.clearingAccountCode}
              className="m-0 mr-4 h-8 w-[88px] flex-1 rounded-[4px] border px-1"
              onChange={e => {
                const clearingAccountCode = e?.target?.value;
                setFormValues((current: ICustomerPaymentsFormProps) => ({
                  ...current,
                  clearingAccountCode,
                }));
              }}
            />
            <Typography variant="body2" style={{fontSize: '12px', flex: 2, textAlign: 'left'}}>
              {' '}
              {clearingAccountInfo}
            </Typography>
          </Box>
          {!isInvalidCode && isDuplicateCode && (
            <Typography
              data-autoid={`txtError${key}COA`}
              variant="body2"
              style={{fontSize: '12px', color: 'red', textAlign: 'left'}}
            >
              Code already exist.
            </Typography>
          )}
          {isInvalidCode && (
            <Typography
              data-autoid={`txtError${key}COA`}
              variant="body2"
              style={{fontSize: '12px', color: 'red', textAlign: 'left'}}
            >
              Invalid code (only digit, period and hyphen are allowed).
            </Typography>
          )}
        </Box>
      </Box>
      <Box className="mt-6 flex items-center justify-between">
        <Typography
          variant="body2"
          className="text-left"
          style={{
            flex: 2,
          }}
        >
          <Link
            to={{pathname: 'https://spenda.co/faqs/#payments'}}
            target="_blank"
            className="cursor-pointer text-blue-700"
            style={{textDecoration: 'underline'}}
          >
            How payments work
          </Link>
        </Typography>
      </Box>
      <Box className="mt-6 flex items-center justify-between">
        <Typography variant="body2" className="text-left">
          Agree to{' '}
          <Link
            to={{pathname: 'https://spenda.co/terms-conditions/'}}
            target="_blank"
            className="cursor-pointer text-blue-700"
            style={{textDecoration: 'underline'}}
          >
            Terms & Conditions
          </Link>
        </Typography>
        <Box style={{flex: 4}}>
          <IOSSwitch
            name={`${key}Terms`}
            checked={formValues.agreeToTermsAndConditions}
            onChange={e => {
              const agreeToTermsAndConditions = e?.target?.checked;
              setFormValues((current: ICustomerPaymentsFormProps) => ({
                ...current,
                agreeToTermsAndConditions,
              }));
            }}
          />
        </Box>
      </Box>
      <Box className="mt-6 flex items-center justify-between">
        <Typography variant="body2" className="text-left">
          Surcharge Customers for Account Payments
        </Typography>
        <Box style={{flex: 4}}>
          <IOSSwitch
            name={`${key}Surcharge`}
            checked={!!formValues.payerFeePercentage}
            onChange={e => {
              const payerFeePercentage = e?.target?.checked ? 100 : 0;
              setFormValues((current: ICustomerPaymentsFormProps) => ({
                ...current,
                payerFeePercentage,
              }));
            }}
          />
        </Box>
      </Box>
      {!isKycApproved && !formValues.saved && (
        <Box>
          <Typography
            data-autoid={`txtErrorNoAMLKYC-${key}`}
            variant="body2"
            className="text-left text-spenda-error"
            style={{fontSize: '0.75rem'}}
          >
            You can not <b>Enable Bank Transfer</b> at the moment because you haven’t passed the AML/KYC
          </Typography>
        </Box>
      )}
      {!isKycApproved && formValues.saved && (
        <Box>
          <Typography variant="body2" className="text-left" style={{fontSize: '0.90rem', color: '#cc9631'}}>
            The settings have been saved and will be enabled once KYC passed
          </Typography>
        </Box>
      )}
      <Box className={`flex pb-2 pt-5 ${isKycApproved ? 'justify-center' : 'justify-between'}`}>
        {!isKycApproved && (
          <SButton
            color="transparent"
            width="110px"
            lineHeight="m-2"
            border="1px solid #0082BA !important"
            variant="outlined"
            data-autoid={`btn${key}Save`}
            textColor="blueShade"
            disabled={disableBtn()}
            onClick={onClickSuccess}
          >
            Save
            {isLoading && (
              <LoadingIndicator isLoading={isLoading} size="sm" color="hsl(var(--primary))" position={{right: '5px'}} />
            )}
          </SButton>
        )}
        <SButton
          onClick={onClickSuccess}
          color="blueShade"
          lineHeight="m-2"
          width="142px"
          data-autoid={`btn${key}SaveAndEnable`}
          disabled={!isKycApproved || disableBtn()}
        >
          <Typography>
            Save & Enable
            {isLoading && (
              <LoadingIndicator isLoading={isLoading} size="sm" color="hsl(var(--primary))" position={{right: '5px'}} />
            )}
          </Typography>
        </SButton>
      </Box>
    </>
  );
};
