import React, {useContext, useState, useEffect} from 'react';
import {AuthorizePayment} from './AuthorizePayment';
import {PaymentPermission} from './PaymentPermission';
import {TermsConditions} from './TermsCondition';
import {SetupComplete} from './SetupComplete';
import MockARBackground from '../../assets/jpg/SpendaCollectBg.jpg';
import {Box} from '@material-ui/core';
import {css} from 'glamor';
import {useHistory, useLocation} from 'react-router-dom';
import {AUTH_BUYING_AP_BATCH, AUTH_PURCHASING_PAY_BILL_ROUTE} from '../../routes/AccountsPayableRoutes';
import {AUTH_MENU} from '../../routes/AuthenticatedRoutes';
import {
  AUTH_PURCHASING_PURCHASE_INVOICE_LIST,
  AUTH_PURCHASING_PURCHASE_DASHBOARD_LIST,
} from '../../routes/PurchasingRoutes';
import {BusinessDetails} from '../onboarding/BusinessDetails';
import AppContext from '../../context/app/appContext';
import {AuthorizeAccountingSystem} from '../onboarding/AuthorizeAccountingSystem';
import useSynkAPI from '../../services/useSynkAPI';
import {IMasterAdaptor} from '../../model/MasterAdaptor';
import useStorage from '../../hooks/useStorage';
import {
  DatTypes,
  ManageSchedulerTime,
  MasterAdaptors,
  ModuleTypes,
  ScheduledTaskRecurrenceType,
  StorageKeys,
  WorkflowStatus,
} from '../../model/constants/Constants';
import useMasterAdaptorAPI from '../../services/useMasterAdaptorAPI';
import {SelectAccountingSystem} from '../onboarding/SelectAccountingSystem';
import {Welcome} from '../onboarding/Welcome';
import useTenantAPI from '../../services/useTenantAPI';
import {FetchCustomers} from '../onboarding/FetchCustomers';
import {ITenant} from '../../model/Tenant';
import {IUser} from '../../model/user/User';
import {useTenantInfo} from '../../hooks/useTenantInfo';
import {UserRoleEnum} from '../../model/user/UserAccountInfo';
import {ApplicationInfo} from '../../model/constants/ApplicationInfo';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import useScheduledTaskAPI from '../../services/useScheduledTaskAPI';
import {APPaymentSetup} from './APPaymentSetup';
import usePaymentServicesAPI from '../../services/usePaymentServicesAPI';
import {IntegrationContext} from '../../context/IntegrationContext';

export const initialFilter = {
  LastRowNumber: 0,
  MaxResults: 50,
  SortAsc: true,
  StatusStrings: [
    WorkflowStatus.Cancelled,
    WorkflowStatus.Complete,
    WorkflowStatus.InProcess,
    WorkflowStatus.New,
    WorkflowStatus.Scheduled,
    WorkflowStatus.Workflow,
  ],
  IsExactMatch: false,
};

const APOnboarding = (props: {
  onboardingAccount?: string;
  onBoardingAccountRedirectUrl?: string;
  isPurchasingOnboarding?: boolean;
}) => {
  const {purchaseRequisitionsV1, apQboIntegration} = useFeatureFlags().tenantOwned();
  const history = useHistory();
  const location = useLocation();
  const appContext = useContext(AppContext);
  const {getItem} = useStorage();
  const query = new URLSearchParams(location.search);

  const [customerRequestStatus, setCustomerRequestStatus] = useState<string>('NOTSTARTED');
  const [customerRequestData, setCustomerRequestData] = useState<any>(undefined);
  const [customerRequestPercentage, setCustomerRequestPercentage] = useState<any>(0);
  const [customerRequestWorkflowId, setCustomerRequestWorkflowId] = useState<any>(null);
  const [customerNavigated, setCustomerNavigated] = useState<boolean>(false);
  const [organisationName, setOrganisationName] = useState<string>('');
  const [skipAdaptorSelectionScreen, setSkipAdaptorSelectionScreen] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isSyncConnectLoading, setIsSyncConnectLoading] = useState<boolean>(false);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [isAccountSystemLoading, setIsAccountSystemLoading] = useState<boolean>(false);
  const [selectedAccountMasterAdaptorId, setSelectedAccountMasterAdaptorId] = useState<number | undefined>();
  const [isTenantModuleEnabled, setIsTenantModuleEnabled] = useState<boolean>(false);
  const [isSetupCompleteLoading, setIsSetupCompleteLoading] = useState<boolean>(false);
  const [enableWelcomeNextBtn, setEnableWelcomeNextBtn] = useState<boolean>(false);
  const [isPrimaryUser, setIsPrimaryUser] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {isAPFinancialAdaptorSetup} = useContext(IntegrationContext);
  const [selectedAccount, setSelectedAccount] = useState<IMasterAdaptor>();
  const [accounts, setAccounts] = useState<IMasterAdaptor[]>([]);
  const {getTenantInfo, saveTenantDetails, updateModule} = useTenantAPI();
  const {connect, synkIsAuthorize} = useSynkAPI();
  const {selectAdaptor, search} = useMasterAdaptorAPI();
  const {getScheduledTask, updateScheduledTask} = useScheduledTaskAPI();
  const {sendAMLKYCEmail} = usePaymentServicesAPI();

  const verifySelectedAdaptor = async () => {
    if (props.onboardingAccount) {
      getSelectedAdaptor();
    }
    if (accounts && accounts.length === 0) {
      const tenantId = appContext.tenantInfo?.TenantUserDetails.TenantID || 0;
      const response = await search(tenantId, ApplicationInfo.WebsiteId);
      let filterAccounts =
        (response &&
          response.length > 0 &&
          response.filter(
            (account: IMasterAdaptor) =>
              account.Type === 'Accounts' &&
              (account.MasterAdaptorID === MasterAdaptors.Xero ||
                account.MasterAdaptorID === MasterAdaptors.Myob ||
                (apQboIntegration && account.MasterAdaptorID === MasterAdaptors.Quickbooks)),
          )) ||
        [];

      const adaptorItems = filterAccounts.filter(
        (account: IMasterAdaptor) => Boolean(account.ID) && account.IsUsedByTenant,
      );

      if (adaptorItems.length) {
        setAccounts(adaptorItems);
        if (!props.onboardingAccount) {
          setSelectedAccount(adaptorItems[0]);
        }
        if (adaptorItems[0] && adaptorItems[0].Name && adaptorItems[0].ID && adaptorItems[0].MasterAdaptorID) {
          const synkResponse = await synkIsAuthorize(
            adaptorItems[0]?.Name,
            adaptorItems[0]?.ID,
            adaptorItems[0]?.MasterAdaptorID,
          );
          setSkipAdaptorSelectionScreen(true);
          appContext.setSelectedAdaptor(adaptorItems[0]);
          if (!synkResponse.IsAuthorized || !synkResponse?.TokenDetails?.OrgName) {
            setEnableWelcomeNextBtn(true);
            setIsAuthorized(false);
          } else {
            setSelectedAccountMasterAdaptorId(adaptorItems[0]?.MasterAdaptorID);
            setEnableWelcomeNextBtn(true);
            setOrganisationName(synkResponse?.TokenDetails?.OrgName || '');
            setIsAuthorized(true);
          }
        }
      } else {
        setSkipAdaptorSelectionScreen(false);
        setAccounts(filterAccounts);
        if (filterAccounts.length) {
          setSelectedAccount(filterAccounts[0]);
        }
        setCurrentStep(1);
      }
    }
  };

  useEffect(() => {
    verifySelectedAdaptor();
  }, []);

  useEffect(() => {
    if (query.has('step')) {
      setCurrentStep(Number(query.get('step')));
    }
    if (props.isPurchasingOnboarding) {
      setIsLoading(true);
      const apModule = appContext.tenantInfo?.Modules.find(m => {
        return m && m.IsModuleSetup && m.ModuleID === ModuleTypes.SpendaPay;
      });
      if (apModule) {
        onSetupComplete();
      } else {
        setIsLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    if (query.get('code') && props.onboardingAccount) {
      if (currentStep.toString() === '6') {
        history.push({search: `step=${currentStep}`});
      }
    } else {
      if (query.get('step') !== currentStep.toString()) {
        history.push({search: `step=${currentStep}`});
      }
    }
  }, [currentStep]);

  const onBackPress = (backStep?: number) => {
    const back = backStep !== undefined ? backStep : currentStep - 1;
    setCurrentStep(back);
  };

  const changeStep = (newStep?: number) => {
    let step = newStep ? newStep : currentStep + 1;
    setCurrentStep(step);
  };

  const redirectToStep = (step: number) => {
    setCurrentStep(step);
  };

  const onAccountingSystemSelection = async () => {
    if (selectedAccount && selectedAccount.MasterAdaptorID && ApplicationInfo.WebsiteId) {
      setIsAccountSystemLoading(true);
      const arr = [];
      arr.push(selectedAccount.MasterAdaptorID);
      const response = await selectAdaptor(arr, ApplicationInfo.WebsiteId);
      const adaptorId = response && response.Value[0];
      selectedAccount.ID = adaptorId;
      appContext.setSelectedAdaptor(selectedAccount);
      setSelectedAccountMasterAdaptorId(selectedAccount?.MasterAdaptorID);
    }
    setIsAccountSystemLoading(false);
    setSelectedAccount(selectedAccount);
    changeStep();
  };

  const getSelectedAdaptor = async () => {
    if (appContext && !appContext.selectedAdaptor) {
      const adaptor = await getItem(StorageKeys.SelectedAccount);
      setSelectedAccount(adaptor);
      setSelectedAccountMasterAdaptorId(adaptor?.MasterAdaptorID);
    } else {
      setSelectedAccount(appContext.selectedAdaptor);
    }
    setCurrentStep(2);
  };

  const {getUserRoleID} = useTenantInfo();
  useEffect(() => {
    getUserRolePermissions();
  }, []);

  const getUserRolePermissions = async () => {
    const userRoleID = await getUserRoleID();
    if (userRoleID === UserRoleEnum.Primary) {
      setIsPrimaryUser(true);
    }
  };

  useEffect(() => {
    if (currentStep === 4 && !isTenantModuleEnabled) {
      onTenantModuleSelect();
    }
  }, [currentStep]);

  const handleClose = async () => {
    history.push(`${AUTH_MENU}`);
  };
  const backdropFilterCSS = css({
    background: 'rgb(255, 255, 255)',
    '@supports ((backdrop-filter: blur(10px))) or (-webkit-backdrop-filter: blur(10px))': {
      background: 'rgba(255, 255, 255, 0.5)',
      backdropFilter: 'blur(10px)',
      '& -webkit-backdrop-filter': 'blur(10px)',
    },
  });

  const onTenantModuleSelect = async (enableModule: boolean = false) => {
    const response = await updateModule({
      ParentModuleID: ModuleTypes.SpendaPay,
      IsModuleSetup: enableModule,
    });
    if (response) {
      setIsTenantModuleEnabled(true);
    }
  };

  const synkConnect = async () => {
    let accountType = '';
    if (selectedAccount && selectedAccount.Name) {
      if (selectedAccount.Name === 'Xero') {
        accountType = 'xero';
      } else if (selectedAccount.Name === 'QuickBooksOnline') {
        accountType = 'quickbooks';
      } else if (selectedAccount.Name === 'MyObAccountRightLive') {
        accountType = 'myob';
      }
    }
    const location = window.location.origin;
    let xeroAuthorizeRedirectLink = location + '/pay/onboarding/' + accountType;

    if (props.isPurchasingOnboarding) {
      xeroAuthorizeRedirectLink = `${location}/purchasing/onboarding/${accountType}`;
    }

    const userName = appContext?.user?.UserName;

    if (userName && selectedAccount && selectedAccount.Name && selectedAccount.MasterAdaptorID) {
      setIsSyncConnectLoading(true);
      connect(userName, selectedAccount.Name, xeroAuthorizeRedirectLink, ApplicationInfo.WebsiteId)
        .then(data => {
          if (data.OAuthLoginUrl) {
            window.open(data.OAuthLoginUrl, '_self');
          }
        })
        .catch(_e => {
          setIsSyncConnectLoading(false);
        });
    }
  };

  const onWelcomeNextClick = async () => {
    if (skipAdaptorSelectionScreen) {
      if (isAuthorized) {
        setCurrentStep(4);
      } else {
        setCurrentStep(2);
      }
    } else {
      setCurrentStep(1);
    }
  };

  const onSetupComplete = async () => {
    if (appContext && appContext.tenant) {
      setIsSetupCompleteLoading(true);
      const tenantDetail: ITenant = {...appContext.tenant};
      tenantDetail.IsSetupComplete = true;
      await saveTenantDetails(tenantDetail);
      appContext.setTenant(tenantDetail);
      if (props.isPurchasingOnboarding) {
        await updateModule({
          ParentModuleID: ModuleTypes.SpendaBuy,
          IsModuleSetup: true,
          configureSchedules: true,
          SchedulesConfig: [
            {
              TaskTypeID: 72,
              WorkflowID: null,
              IsActive: true,
              IsRecurringSchedule: true,
              IsEnabled: true,
              StatusType: 0,
            },
          ],
        });
        const tenantInfo = await getTenantInfo();
        appContext.setTenantInfo(tenantInfo);
        if (appContext && appContext.user) {
          const currentUser: IUser = {...appContext.user};
          const userModules = currentUser.Modules + ',' + ModuleTypes.SpendaBuy.toString();
          currentUser.Modules = userModules;
          appContext.setUser(currentUser);
          await appContext.loadUserSession(currentUser);
        }
        setIsSetupCompleteLoading(false);
        if (purchaseRequisitionsV1) {
          history.push(AUTH_PURCHASING_PURCHASE_DASHBOARD_LIST);
        } else {
          history.push(AUTH_PURCHASING_PURCHASE_INVOICE_LIST);
        }
      } else {
        await updateModule({
          ParentModuleID: ModuleTypes.SpendaPay,
          IsModuleSetup: true,
          configureSchedules: true,
          SchedulesConfig: [
            {
              TaskTypeID: 72,
              WorkflowID: null,
              IsActive: true,
              IsRecurringSchedule: true,
              IsEnabled: true,
              StatusType: 0,
            },
          ],
        });
        const schedulerRes = await getScheduledTask(initialFilter);
        const payload = schedulerRes.map(task => {
          if (task.TaskTypeID === 31) {
            return {
              ID: task.ID,
              IsActive: task.IsActive,
              IsEnabled: task.IsEnabled,
              Interval: 1,
              IntervalType: ManageSchedulerTime.Hours,
              IsRecurringSchedule: task.IsRecurringSchedule,
              TimeOfDay: task.TimeOfDay,
              RecurrenceType: ScheduledTaskRecurrenceType.RunEvery,
            };
          }
          return {
            ID: task.ID,
            IsActive: task.IsActive,
            IsEnabled: task.IsEnabled,
            Interval: task.Interval,
            IntervalType: task.IntervalType,
            IsRecurringSchedule: task.IsRecurringSchedule,
            TimeOfDay: task.TimeOfDay,
            RecurrenceType: task.RecurrenceType,
          };
        });
        await updateScheduledTask(payload);

        const tenantInfo = await getTenantInfo();
        await appContext.setTenantInfo(tenantInfo);
        if (appContext && appContext.user) {
          const currentUser: IUser = {...appContext.user};
          const userModules = currentUser.Modules + ',' + ModuleTypes.SpendaPay.toString();
          currentUser.Modules = userModules;
          appContext.setUser(currentUser);
          await appContext.loadUserSession(currentUser);
        }
        // if aml/kyc not approved send email
        if (!appContext.tenant?.GoodToTrade) {
          if (!appContext?.user) return;
          const requestPayload = {
            Subject: 'AML/KYC application',
            WebsiteID: ApplicationInfo.WebsiteId,
            DatTypeID: DatTypes.AMLKYCApplication,
            To: '',
            PersonalisedMessage:
              'Team, above are the details for the potential lead who has just completed the AP onboarding.',
          };
          if (process.env.REACT_APP_ENVIRONMENT === 'production') {
            requestPayload.To = 'customersuccess@spenda.co';
          } else {
            requestPayload.To = 'developers@spenda.co';
          }
          await sendAMLKYCEmail(requestPayload);
        }
        setIsSetupCompleteLoading(false);
        if (
          selectedAccountMasterAdaptorId === MasterAdaptors.Xero ||
          selectedAccountMasterAdaptorId === MasterAdaptors.Myob ||
          (apQboIntegration && selectedAccountMasterAdaptorId === MasterAdaptors.Quickbooks)
        ) {
          history.push(`${AUTH_PURCHASING_PAY_BILL_ROUTE}`);
        } else {
          history.push(`${AUTH_BUYING_AP_BATCH}`);
        }
      }
    }
    setIsLoading(false);
  };

  const renderSteps = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Welcome
            isAPOnboarding={true}
            onNextClick={() => onWelcomeNextClick()}
            handleClose={handleClose}
            userName={appContext?.user?.FirstName}
            isBuyer={true}
            enableWelcomeNextBtn={enableWelcomeNextBtn}
          />
        );
      case 1:
        return (
          <SelectAccountingSystem
            isAPOnboarding={true}
            accounts={accounts}
            selectedAccount={selectedAccount}
            onNextClick={onAccountingSystemSelection}
            selectedAccountMasterAdaptorId={selectedAccountMasterAdaptorId}
            handleClose={handleClose}
            isBuyer={true}
            onSelectAccountingSystem={setSelectedAccount}
            isAccountSystemLoading={isAccountSystemLoading}
            onCancelClick={() => changeStep(3)}
            apQboIntegration={apQboIntegration}
          />
        );
      case 2:
        return (
          <AuthorizeAccountingSystem
            onBackPress={onBackPress}
            handleClose={handleClose}
            onNextClick={changeStep}
            isAPOnboarding={true}
            isPurchasingOnboarding={props.isPurchasingOnboarding}
            onboardingAccount={props.onboardingAccount}
            onBoardingAccountRedirectUrl={props.onBoardingAccountRedirectUrl}
            userName={appContext?.user?.UserName}
            websiteId={ApplicationInfo.WebsiteId}
            setOrganizationName={name => setOrganisationName(name)}
            synkConnect={synkConnect}
            isSyncConnectLoading={isSyncConnectLoading}
            isBuyer={true}
            selectedAccount={selectedAccount}
            onCancelClick={() => changeStep(3)}
          />
        );
      case 3:
        return (
          <BusinessDetails
            onBackPress={() => onBackPress(1)}
            onNextClick={() => {
              if (
                ((selectedAccountMasterAdaptorId === MasterAdaptors.Xero ||
                  selectedAccountMasterAdaptorId === MasterAdaptors.Myob ||
                  (apQboIntegration && selectedAccountMasterAdaptorId === MasterAdaptors.Quickbooks)) &&
                  props.onboardingAccount) ||
                props.isPurchasingOnboarding
              ) {
                changeStep(4);
              } else if (isPrimaryUser) {
                changeStep(5);
              } else {
                changeStep(6);
              }
            }}
            isBuyer={true}
            isAPOnboarding={true}
            handleClose={handleClose}
            organisationName={organisationName}
            selectedAdaptorName={selectedAccount?.Name}
          />
        );
      case 4:
        return (
          <FetchCustomers
            onBackPress={onBackPress}
            onNextClick={() => {
              if (props.isPurchasingOnboarding) {
                changeStep(9);
              } else if (isPrimaryUser) {
                changeStep(5);
              } else {
                changeStep(8);
              }
            }}
            selectedAdaptor={selectedAccount}
            websiteId={ApplicationInfo.WebsiteId}
            customerRequestPercentage={customerRequestPercentage}
            setCustomerRequestPercentage={setCustomerRequestPercentage}
            setCustomerRequestWorkflowId={setCustomerRequestWorkflowId}
            customerRequestWorkflowId={customerRequestWorkflowId}
            redirectToStep={redirectToStep}
            setCustomerRequestStatus={setCustomerRequestStatus}
            customerRequestStatus={customerRequestStatus}
            customerRequestData={customerRequestData}
            setCustomerRequestData={setCustomerRequestData}
            selectedAccount={selectedAccount}
            setCustomerNavigated={setCustomerNavigated}
            customerNavigated={customerNavigated}
            isBuyer={false}
            handleClose={handleClose}
            isAPOnboarding={true}
            isPurchasingOnboarding={props.isPurchasingOnboarding}
          />
        );
      case 5:
        return <PaymentPermission onBackPress={onBackPress} handleClose={handleClose} onNextClick={changeStep} />;
      case 6:
        return (
          <AuthorizePayment
            onBackPress={onBackPress}
            handleClose={handleClose}
            onNextClick={() => (!isAPFinancialAdaptorSetup() ? changeStep(8) : changeStep(7))}
          />
        );
      case 7:
        return (
          <APPaymentSetup
            onBackPress={onBackPress}
            onNextClick={changeStep}
            selectedAdaptor={selectedAccount}
            handleClose={handleClose}
          />
        );
      case 8:
        return (
          <TermsConditions
            onBackPress={() => (!isAPFinancialAdaptorSetup() ? onBackPress(6) : onBackPress(7))}
            handleClose={handleClose}
            onNextClick={changeStep}
          />
        );
      case 9:
        return (
          <SetupComplete
            onSetupComplete={onSetupComplete}
            isSetupCompleteLoading={isSetupCompleteLoading}
            onBackPress={onBackPress}
            handleClose={handleClose}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Box>
        <img
          alt={'background'}
          src={MockARBackground}
          className="absolute bottom-0 left-0 right-0 h-full w-full"
          data-autoid="imgMockARBackground"
        />
      </Box>
      <Box className={`z-10 h-full overflow-y-auto ${backdropFilterCSS}`}>
        {!isLoading ? renderSteps(currentStep) : null}
        <LoadingIndicator
          isLoading={isLoading}
          position={{
            height: '100% !important',
            display: 'flex',
            position: 'absolute',
            left: '0',
            right: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          size="md"
          color="hsl(var(--primary))"
        />
      </Box>
    </>
  );
};

export default APOnboarding;
