import React, {useState, useContext, useEffect, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import WatchIcon from '../../../assets/svg/accounts-payable/WatchIcon';
import StepSuccessIcon from '../../../assets/png/AccountsPayable/onboardingSucessGreenCheck.png';
import {BusinessInformation} from './BusinessInformation';
import {AMLKYC} from './AmlKyc';
import {PaymentMethod} from './PaymentMethod';
import {ClearingAccount} from './ClearingAccount';
import usePaymentServicesAPI from '../../../services/usePaymentServicesAPI';
import useScheduledTaskAPI from '../../../services/useScheduledTaskAPI';
import AppContext from '../../../context/app/appContext';
import {TabsHeader, TabsBody, Tab, TabPanel, useTabs} from 'spenda-ui-react';
import {
  APOnboardingStepsName,
  ARModals,
  AccountType,
  DatTypes,
  FinancialAdaptors,
  LowTouchOnBoardingDisplaySteps,
  LowTouchPsblOnboardingSteps,
  LowTouchPsblOnboardingStepsName,
  FinancialAdaptorsType,
  ManageSchedulerTime,
  MasterAdaptors,
  ModuleTypes,
  PaymentMethodTypes,
  PaymentProviderName,
  PsblOnboardingSteps,
  PsblOnboardingStepsName,
  ScheduledTaskRecurrenceType,
  StorageKeys,
  WorkflowTypes,
} from '../../../model/constants/Constants';
import useSpendaPaymentServicesAPI, {IAccountResponse} from '../../../services/useSpendaPaymentServicesAPI';
import {UserSettings} from './UserSettings';
import {SetupComplete} from './SetupComplete';
import {FinancialAdaptor} from './FinancialAdaptor';
import {IMasterAdaptor} from '../../../model/MasterAdaptor';
import {ITenant} from '../../../model/Tenant';
import {IUser} from '../../../model/user/User';
import useMasterAdaptorAPI from '../../../services/useMasterAdaptorAPI';
import useSynkAPI from '../../../services/useSynkAPI';
import {getItem} from 'localforage';
import {Typography} from 'spenda-ui-react';
import {APOnboardingSteps} from '../../../model/constants/Constants';
import useTenantAPI from '../../../services/useTenantAPI';
import {AUTH_BUYING_AP_BATCH, AUTH_PURCHASING_PAY_BILL_ROUTE} from '../../../routes/AccountsPayableRoutes';
import {initialFilter} from '../APOnboarding';
import {IntegrationContext} from '../../../context/IntegrationContext';
import {useOnboarding} from '../../../services/useOnboarding';
import {setActive, setIndicator} from 'spenda-ui-react/components/Tabs/TabsContext';
import {IOnboardingV1Step, IPsblOnboardingSteps} from '../../../model/OnboardingV1Step';
import APOnbFinancialAdaptorIcon from '../../../assets/svg/accounts-payable/APOnbFinancialAdaptorIcon';
import APOnbBusinessInfoIcon from '../../../assets/svg/accounts-payable/APOnbBusinessInfoIcon';
import APOnbAmlKycIcon from '../../../assets/svg/accounts-payable/APOnbAmlKycIcon';
import APOnbPaymentMethodIcon from '../../../assets/svg/accounts-payable/APOnbPaymentMethodIcon';
import APOnbClearingAccountIcon from '../../../assets/svg/accounts-payable/APOnbClearingAccountIcon';
import APOnbUserSettingIcon from '../../../assets/svg/accounts-payable/APOnbUserSettingIcon';
import {defaultMarketPlacePath, websiteId} from '../../../config';
import {PostingConfigurationView} from '../../../screens/AccountsReceivable/ARsettingsView/PostingConfigurationsView';
import APOnbPostingConfigurationsIcon from '../../../assets/svg/accounts-payable/APOnbPostingConfigurationsIcon';
import {usePSBLHook} from '../../../hooks/useARHook';
import {BookOnboardingModal} from '../../AccountsReceivable/modals/BookOnboarding';
import {CreateClearingAccount} from '../../lowTouchOnboarding/CreateClearingAccount';
import {ReconcilingPeriod} from '../../lowTouchOnboarding/ReconcilingPeriod';
import {SetUpInventory} from '../../lowTouchOnboarding/SetUpInventory';
import IconSetupInventory from '../../../assets/svg/IconSetupInventory';
import IconReconsilingPeriod from '../../../assets/svg/IconReconsilingPeriod';
import IconCapSupplierMapping from '../../../assets/svg/IconCapSupplierMapping';
import CapricornSupplierMapping from '../../lowTouchOnboarding/CapricornSupplierMapping';
import useWorkflowAPI from '../../../services/useWorkflowAPI';
import {ImportWorkflowsModal} from '../../AccountsReceivable/modals/ImportWorkflowsModal';
import IconInvoiceMatchingSettings from '../../../assets/svg/IconInvoiceMatchingSettings';
import {PreffixSuffix} from '../../../screens/AccountsReceivable/ARsettingsView/CustomPrefixSuffix';
import {IQueueWorkFlowPayload} from '../../../model/workflow/WorkflowResponse';
import ARContext from '../../../context/ar-context/ARContext';
import {isMobile} from 'react-device-detect';

interface IAPOnboardingV1 {
  isAPOnboardingV1?: boolean;
  onboardingAccount?: string;
  onBoardingAccountRedirectUrl?: string;
  isSkipAdapter?: boolean | null;
  tabContentState: IOnboardingV1Step[] | IPsblOnboardingSteps[];
  setTabContentState: React.Dispatch<React.SetStateAction<IOnboardingV1Step[]>>;
  currentActiveTab?: APOnboardingSteps | PsblOnboardingSteps | LowTouchPsblOnboardingSteps;
  psblOnboardingSteps: IPsblOnboardingSteps[];
  setPsblOnboardingSteps: React.Dispatch<React.SetStateAction<IPsblOnboardingSteps[]>>;
  isPsblOnboarding?: boolean;
  payStatementByLinkV289938?: boolean;
  lmiCommercialRelease92671?: boolean;
  psblV22LowTouchOnboarding92923?: boolean;
}

export interface TabContent {
  heading: string;
  requiredTime: string;
  icon: string | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
}

interface WorkflowState {
  supplier: number | undefined;
  apDataV2: number | undefined;
}

export const APOnboardingV1 = (props: IAPOnboardingV1) => {
  // destructuring props
  const {
    isSkipAdapter,
    isAPOnboardingV1,
    setTabContentState,
    psblOnboardingSteps,
    tabContentState,
    isPsblOnboarding,
    payStatementByLinkV289938,
    lmiCommercialRelease92671,
    psblV22LowTouchOnboarding92923,
    currentActiveTab,
  } = props;
  const history = useHistory();

  // Use Context
  const appContext = useContext(AppContext);
  const {setArModalToShow} = useContext(ARContext);
  const userName = appContext?.user?.UserName;
  const {financialAdaptor} = useContext(IntegrationContext);

  // API Call
  const {sendAMLKYCEmail} = usePaymentServicesAPI();
  const {getAccounts} = useSpendaPaymentServicesAPI();
  const {selectAdaptor, search} = useMasterAdaptorAPI();
  const {connect, synkIsAuthorize} = useSynkAPI();
  const {saveTenantDetails, updateModule, getTenantInfo, subscriptionActivate} = useTenantAPI();
  const {getScheduledTask, updateScheduledTask} = useScheduledTaskAPI();
  const {queueWorkflow} = useWorkflowAPI();

  // State
  const [isSetupComplete, setIsSetupComplete] = useState<boolean>(false);
  const [financialAdaptorAccounts, setFinancialAdaptorAccounts] = useState<IMasterAdaptor[]>([]);
  const [selectedFinancialAdaptorAccount, setSelectedFinancialAdaptorAccount] = useState<IMasterAdaptor>();
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [isShowBookOnboardingDialog, setIsShowBookOnboardingDialog] = useState<boolean>(false);
  const [importStatusWorkFlowId, setImportStatusWrokFlowId] = useState<WorkflowState>({
    supplier: undefined,
    apDataV2: undefined,
  });
  const [isShowImportModal, setIsShowImportModal] = useState(false);

  // organisationName will be used later
  const [organizationName, setOrganizationName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paymentAccounts, setPaymentAccounts] = useState<IAccountResponse[]>([]);
  const {isAPFinancialAdaptorSetup} = useContext(IntegrationContext);
  const {getSupplierByMarketplacePath} = usePSBLHook();
  const {handleTabChange} = useOnboarding();
  const {dispatch: updateState, state: tabState} = useTabs();

  // Constant
  const isOnboardingMeetingCompleted = appContext?.tenantInfo?.TenantUserDetails?.IsOnboardingMeetingCompleted;

  useEffect(() => {
    setIsAuthorized(
      props.onboardingAccount === FinancialAdaptors.Xero ||
        props.onboardingAccount === FinancialAdaptors.QuickBooksOnline ||
        props.onboardingAccount === FinancialAdaptors.MyObAccountRightLive,
    );
  }, []);

  useEffect(() => {
    if (
      isPsblOnboarding &&
      psblV22LowTouchOnboarding92923 &&
      currentActiveTab === LowTouchPsblOnboardingSteps.ChartOfAccountsWorkflow
    ) {
      setActive(updateState, LowTouchPsblOnboardingSteps.ClearingAccountSetup);
    } else if (
      isPsblOnboarding &&
      psblV22LowTouchOnboarding92923 &&
      currentActiveTab === LowTouchPsblOnboardingSteps.AccountsPayableWorkflow
    ) {
      setActive(updateState, LowTouchPsblOnboardingSteps.InvoiceMatchingSettings);
      setIsSetupComplete(true);
    } else {
      setActive(updateState, currentActiveTab || APOnboardingSteps.ConfigureFinancialAdaptor);
    }
  }, [currentActiveTab, psblV22LowTouchOnboarding92923, isPsblOnboarding]);

  useEffect(() => {
    setIndicator(updateState, {
      className: 'bg-primary/10 rounded-none',
    });
  }, []);

  useEffect(() => {
    if (typeof isSkipAdapter != 'object' && isSkipAdapter != true) {
      setIsLoading(true);
      verifySelectedAdaptor();
    }
  }, [isSkipAdapter]);

  const setActiveTab = (step: APOnboardingSteps | PsblOnboardingSteps | LowTouchPsblOnboardingSteps) => {
    setActive(updateState, step);
  };

  const setStepCompleted = (stepID: APOnboardingSteps | PsblOnboardingSteps | LowTouchPsblOnboardingSteps) => {
    const tabContent = tabContentState.map((tab: IOnboardingV1Step | IPsblOnboardingSteps) => {
      if (tab.stepID === stepID) {
        tab.completedDateTime_utc = new Date().toISOString();
      }
      return tab;
    });
    setTabContentState(tabContent);
  };

  const handleLanding = () => {
    history.push('/menu');
  };

  const getSelectedAdaptor = async () => {
    if (appContext && !appContext.selectedAdaptor) {
      try {
        const adaptor: IMasterAdaptor | null = await getItem(StorageKeys.SelectedAccount);
        if (adaptor) {
          setSelectedFinancialAdaptorAccount(adaptor);
        }
      } catch (e) {
        setSelectedFinancialAdaptorAccount(appContext.selectedAdaptor);
      }
    }
    if (isPsblOnboarding && payStatementByLinkV289938) {
      if (psblV22LowTouchOnboarding92923) {
        if (currentActiveTab === LowTouchPsblOnboardingSteps.ConfigureFinancialAdaptor) {
          setActiveTab(LowTouchPsblOnboardingSteps.ConfigureFinancialAdaptor);
        }
      } else {
        setActiveTab(PsblOnboardingSteps.ConfigureFinancialAdaptor);
      }
    } else {
      setActiveTab(APOnboardingSteps.ConfigureFinancialAdaptor);
    }
  };

  const verifySelectedAdaptor = async () => {
    if (props.onboardingAccount) {
      getSelectedAdaptor();
    }
    if (financialAdaptorAccounts && financialAdaptorAccounts.length === 0) {
      const tenantId = appContext.tenantInfo?.TenantUserDetails.TenantID || 0;
      const response = await search(tenantId, websiteId);

      const financialAdaptors = [MasterAdaptors.Xero, MasterAdaptors.Myob];
      if (!(isPsblOnboarding && lmiCommercialRelease92671)) {
        financialAdaptors.push(MasterAdaptors.Quickbooks);
      }

      if(process.env.REACT_APP_ENVIRONMENT !== 'production'){
        financialAdaptors.push(MasterAdaptors.Parody);
      }

      let filterAccounts = response?.filter(
        account =>
          account.Type === 'Accounts' && financialAdaptors?.includes(account.MasterAdaptorID as MasterAdaptors),
      );

      const adaptorItems = filterAccounts.filter(
        (account: IMasterAdaptor) => Boolean(account.ID) && account.IsUsedByTenant,
      );

      if (adaptorItems.length) {
        setFinancialAdaptorAccounts(adaptorItems);
        if (!props.onboardingAccount) {
          setSelectedFinancialAdaptorAccount(adaptorItems[0]);
        }
        if (adaptorItems[0] && adaptorItems[0].Name && adaptorItems[0].ID && adaptorItems[0].MasterAdaptorID) {
          const synkResponse = await synkIsAuthorize(
            adaptorItems[0]?.Name,
            adaptorItems[0]?.ID,
            adaptorItems[0]?.MasterAdaptorID,
          );
          setSelectedFinancialAdaptorAccount(adaptorItems[0]);
          if (!synkResponse.IsAuthorized || !synkResponse?.TokenDetails?.OrgName) {
            setIsAuthorized(false);
          } else {
            setOrganizationName(synkResponse?.TokenDetails?.OrgName || '');
            setIsAuthorized(true);
          }
        }
      } else {
        setFinancialAdaptorAccounts(filterAccounts);
      }
    }
    setIsLoading(false);
  };

  const loadPaymentMethods = async () => {
    const accounts = await getAccounts(PaymentProviderName.Spenda_Payment_Services);
    setPaymentAccounts(accounts);
    return accounts;
  };
  // If already payment method added
  const fetchPaymentMethod = async () => {
    const accounts = await loadPaymentMethods();
    setStepCompleted(APOnboardingSteps.AMLKYC);
    if (
      accounts?.some(
        account =>
          account.accountType === AccountType.CREDIT_CARD ||
          account.accountType === AccountType.BANK_TRANSFER ||
          account.paymentMethod === PaymentMethodTypes.Airplus,
      )
    ) {
      setStepCompleted(APOnboardingSteps.SetupPaymentMethods);
      if (isSkipAdapter || !isAPFinancialAdaptorSetup()) {
        setActiveTab(APOnboardingSteps.UserSettings);
        handleTabChange({
          action: 'next',
          completeSteps: [APOnboardingSteps.AMLKYC, APOnboardingSteps.SetupPaymentMethods],
          nextStep: APOnboardingSteps.UserSettings,
        });
      } else {
        setActiveTab(APOnboardingSteps.ClearingAccount);
        handleTabChange({
          action: 'next',
          completeSteps: [APOnboardingSteps.AMLKYC, APOnboardingSteps.SetupPaymentMethods],
          nextStep: APOnboardingSteps.ClearingAccount,
        });
      }
    } else {
      setActiveTab(APOnboardingSteps.SetupPaymentMethods);
      handleTabChange({
        action: 'next',
        completeSteps: [APOnboardingSteps.AMLKYC],
        nextStep: APOnboardingSteps.SetupPaymentMethods,
      });
    }
  };

  const handleNextFinancialAdaptor = () => {
    if (isPsblOnboarding && payStatementByLinkV289938) {
      if (psblV22LowTouchOnboarding92923) {
        setStepCompleted(LowTouchPsblOnboardingSteps.ConfigureFinancialAdaptor);
        setActiveTab(LowTouchPsblOnboardingSteps.ClearingAccountSetup);
        handleTabChange({
          isPsblOnboarding,
          action: 'next',
          completeSteps: [LowTouchPsblOnboardingSteps.ConfigureFinancialAdaptor],
          nextStep: LowTouchPsblOnboardingSteps.ChartOfAccountsWorkflow,
        });
        return;
      } else if (lmiCommercialRelease92671) {
        setStepCompleted(PsblOnboardingSteps.ConfigureFinancialAdaptor);
        if (!isOnboardingMeetingCompleted) {
          setActiveTab(PsblOnboardingSteps.BusinessInformation);
          setIsShowBookOnboardingDialog(true);
          return;
        }
        setActiveTab(PsblOnboardingSteps.PostingConfigurations);
        return;
      }
      setStepCompleted(PsblOnboardingSteps.ConfigureFinancialAdaptor);
      setActiveTab(PsblOnboardingSteps.BusinessInformation);
      return;
    }
    setStepCompleted(APOnboardingSteps.ConfigureFinancialAdaptor);
    setActiveTab(APOnboardingSteps.BusinessInformation);
    history.replace({
      search: '',
      pathname: '/pay/onboardingv1',
    });
    handleTabChange({
      action: 'next',
      completeSteps: [APOnboardingSteps.ConfigureFinancialAdaptor],
      nextStep: APOnboardingSteps.BusinessInformation,
    });
  };

  const handleNextBusinessInformation = () => {
    if (isPsblOnboarding && payStatementByLinkV289938) {
      setStepCompleted(PsblOnboardingSteps.BusinessInformation);
      if (lmiCommercialRelease92671) {
        return;
      }
      setActiveTab(PsblOnboardingSteps.PostingConfigurations);
      return;
    }
    setActiveTab(APOnboardingSteps.AMLKYC);
    setStepCompleted(APOnboardingSteps.BusinessInformation);

    handleTabChange({
      action: 'next',
      nextStep: APOnboardingSteps.AMLKYC,
      completeSteps: [APOnboardingSteps.BusinessInformation],
    });
  };

  //if aml/kyc not approved send email
  const handleSendEmail = async () => {
    if (!appContext.tenant?.GoodToTrade) {
      if (!appContext?.user) return;
      const requestPayload = {
        Subject: 'AML/KYC application',
        WebsiteID: websiteId,
        DatTypeID: DatTypes.AMLKYCApplication,
        To: '',
        PersonalisedMessage:
          'Team, above are the details for the potential lead who has just completed the AP onboarding.',
      };
      if (process.env.REACT_APP_ENVIRONMENT === 'production') {
        requestPayload.To = 'customersuccess@spenda.co';
      } else {
        requestPayload.To = 'developers@spenda.co';
      }
      await sendAMLKYCEmail(requestPayload);
    }
  };

  const onSetupComplete = async () => {
    setIsLoading(true);
    if (appContext && appContext.tenant) {
      const tenantDetail: ITenant = {...appContext.tenant};
      tenantDetail.IsSetupComplete = true;
      await saveTenantDetails(tenantDetail);
      appContext.setTenant(tenantDetail);
      await updateModule({
        ParentModuleID: ModuleTypes.SpendaPay,
        IsModuleSetup: true,
        configureSchedules: true,
        SchedulesConfig: [
          {
            TaskTypeID: 72,
            WorkflowID: null,
            IsActive: true,
            IsRecurringSchedule: true,
            IsEnabled: true,
            StatusType: 0,
          },
        ],
      });
      const schedulerRes = await getScheduledTask(initialFilter);
      const payload = schedulerRes.map(task => {
        if (task.TaskTypeID === 31) {
          return {
            ID: task.ID,
            IsActive: task.IsActive,
            IsEnabled: task.IsEnabled,
            Interval: 1,
            IntervalType: ManageSchedulerTime.Hours,
            IsRecurringSchedule: task.IsRecurringSchedule,
            TimeOfDay: task.TimeOfDay,
            RecurrenceType: ScheduledTaskRecurrenceType.RunEvery,
          };
        }
        return {
          ID: task.ID,
          IsActive: task.IsActive,
          IsEnabled: task.IsEnabled,
          Interval: task.Interval,
          IntervalType: task.IntervalType,
          IsRecurringSchedule: task.IsRecurringSchedule,
          TimeOfDay: task.TimeOfDay,
          RecurrenceType: task.RecurrenceType,
        };
      });
      await updateScheduledTask(payload);

      const tenantInfo = await getTenantInfo();
      await appContext.setTenantInfo(tenantInfo);
      if (appContext && appContext.user) {
        const currentUser: IUser = {...appContext.user};
        const userModules = currentUser.Modules + ',' + ModuleTypes.SpendaPay.toString();
        currentUser.Modules = userModules;
        appContext.setUser(currentUser);
        await appContext.loadUserSession(currentUser);
      }
      // if aml/kyc not approved send email
      if (!appContext.tenant?.GoodToTrade) {
        if (!appContext?.user) return;
        const requestPayload = {
          Subject: 'AML/KYC application',
          WebsiteID: websiteId,
          DatTypeID: DatTypes.AMLKYCApplication,
          To: '',
          PersonalisedMessage:
            'Team, above are the details for the potential lead who has just completed the AP onboarding.',
        };
        if (process.env.REACT_APP_ENVIRONMENT === 'production') {
          requestPayload.To = 'customersuccess@spenda.co';
        } else {
          requestPayload.To = 'developers@spenda.co';
        }
        await sendAMLKYCEmail(requestPayload);
      }

      // Redirect to psbl view
      if (isPsblOnboarding) {
        if (psblV22LowTouchOnboarding92923) {
          await handleTabChange({
            isPsblOnboarding,
            action: 'start',
            nextStep: LowTouchPsblOnboardingSteps.AccountsPayableWorkflow,
          });
          setIsLoading(false);
          setIsSetupComplete(false);
          setIsShowImportModal(true);
          return;
        } else {
          await subscriptionActivate();
          setIsLoading(false);
          redirectToRequiresAttention();
          return;
        }
      }

      setIsLoading(false);
      if (isAuthorized) {
        history.push(`${AUTH_PURCHASING_PAY_BILL_ROUTE}`);
      } else {
        history.push(`${AUTH_BUYING_AP_BATCH}`);
      }
    }
    setStepCompleted(APOnboardingSteps.UserSettings);
    await handleTabChange({
      action: 'next',
      completeSteps: [APOnboardingSteps.UserSettings],
    });
    setIsSetupComplete(false);
  };

  const redirectToRequiresAttention = () => {
    history.push(`/${defaultMarketPlacePath}`);
  };

  const handleSkipPurchaseInvoiceImportModal = async (importWorkflowId?: number) => {
    setIsLoading(true);
    if (importWorkflowId) {
      setArModalToShow({modal: ARModals.ImportWorkflowModalAlert, data: {importWorkflowId}});
    }
    if (isMobile) {
      appContext.setIsMobileDisclaimerShown(true);
    }
    await handleTabChange({
      isPsblOnboarding,
      action: 'next',
      completeSteps: [LowTouchPsblOnboardingSteps.AccountsPayableWorkflow],
    });
    await subscriptionActivate();
    setIsLoading(false);
    redirectToRequiresAttention();
  };

  const onAccountingSystemSelection = async (account: IMasterAdaptor) => {
    if (account && account.MasterAdaptorID && websiteId) {
      const arr = [];
      arr.push(account.MasterAdaptorID);
      const response = await selectAdaptor(arr, websiteId);
      const adaptorId = response && response.Value[0];
      account.ID = adaptorId;
      appContext.setSelectedAdaptor(account);
      setSelectedFinancialAdaptorAccount(account);
    }
    setIsLoading(false);
  };

  const synkConnect = async () => {
    const origin = window.location.origin;
    const financialAdaptorName =
      selectedFinancialAdaptorAccount &&
      FinancialAdaptors[selectedFinancialAdaptorAccount?.Name as keyof typeof FinancialAdaptors];
    const onboardingPath = isPsblOnboarding
      ? `supplier/psbl/onboarding/${financialAdaptorName}`
      : `pay/onboardingv1/${financialAdaptorName}`;

    let xeroAuthorizeRedirectLink = `${origin}/${onboardingPath}`;

    if (
      userName &&
      selectedFinancialAdaptorAccount &&
      selectedFinancialAdaptorAccount.Name &&
      selectedFinancialAdaptorAccount.MasterAdaptorID
    ) {
      setIsLoading(true);
      connect(userName, selectedFinancialAdaptorAccount.Name, xeroAuthorizeRedirectLink!, websiteId)
        .then(data => {
          if (data.OAuthLoginUrl) {
            window.open(data.OAuthLoginUrl, '_self');
          } else if(selectedFinancialAdaptorAccount.Name === FinancialAdaptorsType.Parody)
          {
            location.href = `${location.href}/parody`;
          }          
        })
        .catch(() => setIsLoading(false));
    }
  };

  const handleSkipAuthorization = async () => {
    setTabContentState(prev =>
      prev.filter((tab: IOnboardingV1Step) => {
        if (tab.stepID === APOnboardingSteps.ClearingAccount) {
          return false;
        }
        return true;
      }),
    );
    handleTabChange({
      action: 'skip',
      skipSteps: [APOnboardingSteps.ClearingAccount, APOnboardingSteps.ConfigureFinancialAdaptor],
      nextStep: APOnboardingSteps.BusinessInformation,
    });
    setActiveTab(APOnboardingSteps.BusinessInformation);
  };

  // Low touch onboarding functions
  const handleCreateClearingAccount = () => {
    setStepCompleted(LowTouchPsblOnboardingSteps.ClearingAccountSetup);
    setActiveTab(LowTouchPsblOnboardingSteps.GenericInventorySetup);
    handleTabChange({
      isPsblOnboarding,
      action: 'next',
      completeSteps: [LowTouchPsblOnboardingSteps.ClearingAccountSetup],
      nextStep: LowTouchPsblOnboardingSteps.GenericInventorySetup,
    });
  };

  const handleSetupInventory = () => {
    setStepCompleted(LowTouchPsblOnboardingSteps.GenericInventorySetup);
    setActiveTab(LowTouchPsblOnboardingSteps.ReconcilingInfo);
    handleTabChange({
      isPsblOnboarding,
      action: 'next',
      completeSteps: [LowTouchPsblOnboardingSteps.GenericInventorySetup],
      nextStep: LowTouchPsblOnboardingSteps.ReconcilingInfo,
    });
  };

  const handleReconcilingPeriod = () => {
    setStepCompleted(LowTouchPsblOnboardingSteps.ReconcilingInfo);
    setActiveTab(LowTouchPsblOnboardingSteps.SupplierMapping);
    handleTabChange({
      isPsblOnboarding,
      action: 'next',
      completeSteps: [LowTouchPsblOnboardingSteps.ReconcilingInfo],
      nextStep: LowTouchPsblOnboardingSteps.SupplierMapping,
    });
  };

  const handleCapricornSupplierMapping = () => {
    setStepCompleted(LowTouchPsblOnboardingSteps.SupplierMapping);
    setActiveTab(LowTouchPsblOnboardingSteps.InvoiceMatchingSettings);
    handleTabChange({
      isPsblOnboarding,
      action: 'next',
      completeSteps: [LowTouchPsblOnboardingSteps.SupplierMapping],
      nextStep: LowTouchPsblOnboardingSteps.InvoiceMatchingSettings,
    });
  };

  const handlePostingConfigurationComplete = async () => {
    if (psblV22LowTouchOnboarding92923) {
      setStepCompleted(LowTouchPsblOnboardingSteps.InvoiceMatchingSettings);
      await handleTabChange({
        isPsblOnboarding,
        action: 'next',
        completeSteps: [LowTouchPsblOnboardingSteps.InvoiceMatchingSettings],
      });
    } else if (lmiCommercialRelease92671) {
      setStepCompleted(PsblOnboardingSteps.PostingConfigurations);
    }
    setIsSetupComplete(true);
  };

  const suppliersQueuePayload: Partial<IQueueWorkFlowPayload> | undefined = useMemo(
    () =>
      financialAdaptor && !importStatusWorkFlowId?.supplier
        ? {
            Action: 'Read',
            AdaptorName: financialAdaptor?.Name,
            AdaptorID: financialAdaptor?.ID,
            DatTypeID: DatTypes.Suppliers,
            MaxWaitSecs: 29,
            IsSyncDependencies: false,
          }
        : undefined,
    [financialAdaptor, importStatusWorkFlowId?.supplier],
  );

  const apDataV2QueuePayload: Partial<IQueueWorkFlowPayload> | undefined = useMemo(
    () =>
      financialAdaptor && !importStatusWorkFlowId?.apDataV2
        ? {
            IsSyncDependencies: true,
            Action: 'Read',
            AdaptorName: financialAdaptor?.Name,
            AdaptorID: financialAdaptor?.ID,
            WorkflowType: WorkflowTypes.SyncAccountsPayableDataV2,
            MaxWaitSecs: 29,
            IsImportUnPaidInvoicesOnly: true,
          }
        : undefined,
    [financialAdaptor, importStatusWorkFlowId?.apDataV2],
  );

  const startSuppliersWorkflow = async () => {
    if (!suppliersQueuePayload) return;
    const res = await queueWorkflow(suppliersQueuePayload);
    setImportStatusWrokFlowId(prev => ({...prev, supplier: res?.WorkflowID}));
  };

  const startApDataV2Workflow = async () => {
    if (!apDataV2QueuePayload) return;
    const res = await queueWorkflow(apDataV2QueuePayload);
    setImportStatusWrokFlowId(prev => ({...prev, apDataV2: res?.WorkflowID}));
  };

  const getTabContent = (id: number) => {
    if (isPsblOnboarding && psblV22LowTouchOnboarding92923) {
      switch (id) {
        case LowTouchPsblOnboardingSteps.ConfigureFinancialAdaptor:
          if (tabState?.active !== LowTouchPsblOnboardingSteps.ConfigureFinancialAdaptor) return;
          return (
            <FinancialAdaptor
              selectedAccount={selectedFinancialAdaptorAccount}
              accounts={financialAdaptorAccounts}
              onBoardingAccountRedirectUrl={props.onBoardingAccountRedirectUrl}
              isLoading={isLoading}
              onboardingAccount={props.onboardingAccount}
              isAuthorized={isAuthorized}
              userName={userName}
              onSelectAccountingSystem={account => {
                setIsLoading(true);
                onAccountingSystemSelection(account);
              }}
              synkConnect={synkConnect}
              setIsLoading={setIsLoading}
              setIsAuthorized={setIsAuthorized}
              setOrganizationName={setOrganizationName}
              isAPOnboardingV1={isAPOnboardingV1}
              organizationName={organizationName}
              handleSkipAuthorization={handleSkipAuthorization}
              handleNext={handleNextFinancialAdaptor}
              isPsblOnboarding={isPsblOnboarding}
              lmiCommercialRelease92671={lmiCommercialRelease92671}
              psblV22LowTouchOnboarding92923={psblV22LowTouchOnboarding92923}
              currentActiveTab={currentActiveTab}
            />
          );
        case LowTouchPsblOnboardingSteps.ClearingAccountSetup:
          if (tabState?.active !== LowTouchPsblOnboardingSteps.ClearingAccountSetup) return;
          return (
            <CreateClearingAccount
              currentActiveTab={currentActiveTab as LowTouchPsblOnboardingSteps}
              handleNext={handleCreateClearingAccount}
              startSuppliersWorkflow={startSuppliersWorkflow}
            />
          );
        case LowTouchPsblOnboardingSteps.GenericInventorySetup:
          if (tabState?.active !== LowTouchPsblOnboardingSteps.GenericInventorySetup) return;
          return <SetUpInventory handleNext={handleSetupInventory} />;
        case LowTouchPsblOnboardingSteps.ReconcilingInfo:
          if (tabState?.active !== LowTouchPsblOnboardingSteps.ReconcilingInfo) return;
          return <ReconcilingPeriod handleNext={handleReconcilingPeriod} />;
        case LowTouchPsblOnboardingSteps.SupplierMapping:
          if (tabState?.active !== LowTouchPsblOnboardingSteps.SupplierMapping) return;
          const supplierId = getSupplierByMarketplacePath(defaultMarketPlacePath)?.SupplierID;
          return (
            <CapricornSupplierMapping
              supplierId={supplierId}
              handleNext={handleCapricornSupplierMapping}
              suppliersImportWrokFlowId={importStatusWorkFlowId?.supplier}
              startApDataV2Workflow={startApDataV2Workflow}
              suppliersQueuePayload={suppliersQueuePayload}
            />
          );
        case LowTouchPsblOnboardingSteps.InvoiceMatchingSettings:
          if (tabState?.active !== LowTouchPsblOnboardingSteps.InvoiceMatchingSettings) return;
          return (
            <>
              <PreffixSuffix handleNext={handlePostingConfigurationComplete} isOnboarding />
              {isSetupComplete && (
                <SetupComplete
                  onSetupComplete={onSetupComplete}
                  isAPOnboardingV1={isAPOnboardingV1}
                  isLoading={isLoading}
                  isPsblOnboarding={isPsblOnboarding}
                />
              )}
              {isShowImportModal && (
                <ImportWorkflowsModal
                  isFooter
                  isLoading={isLoading}
                  open={isShowImportModal}
                  workflowId={importStatusWorkFlowId?.apDataV2}
                  onClose={handleSkipPurchaseInvoiceImportModal}
                  onSuccess={handleSkipPurchaseInvoiceImportModal}
                  message="This might take a while. Feel free to move on to the next step, and we'll notify you when it's done."
                  onPrimaryClick={handleSkipPurchaseInvoiceImportModal}
                  queuePayload={!importStatusWorkFlowId?.apDataV2 ? apDataV2QueuePayload : undefined}
                />
              )}
            </>
          );
        default:
          return <Typography variant="h3">Not content</Typography>;
      }
    }
    switch (id) {
      case PsblOnboardingSteps.ConfigureFinancialAdaptor:
      case APOnboardingSteps.ConfigureFinancialAdaptor:
        return (
          <FinancialAdaptor
            selectedAccount={selectedFinancialAdaptorAccount}
            accounts={financialAdaptorAccounts}
            onBoardingAccountRedirectUrl={props.onBoardingAccountRedirectUrl}
            isLoading={isLoading}
            onboardingAccount={props.onboardingAccount}
            isAuthorized={isAuthorized}
            userName={userName}
            onSelectAccountingSystem={account => {
              setIsLoading(true);
              onAccountingSystemSelection(account);
            }}
            synkConnect={synkConnect}
            setIsLoading={setIsLoading}
            setIsAuthorized={setIsAuthorized}
            setOrganizationName={setOrganizationName}
            isAPOnboardingV1={isAPOnboardingV1}
            organizationName={organizationName}
            handleSkipAuthorization={handleSkipAuthorization}
            handleNext={handleNextFinancialAdaptor}
            isPsblOnboarding={isPsblOnboarding}
            lmiCommercialRelease92671={lmiCommercialRelease92671}
          />
        );
      case PsblOnboardingSteps.BusinessInformation:
      case APOnboardingSteps.BusinessInformation:
        if (isPsblOnboarding && payStatementByLinkV289938 && lmiCommercialRelease92671) {
          return <div className="flex h-full"></div>;
        }
        return (
          <BusinessInformation
            isBuyer={true}
            onNextClick={handleNextBusinessInformation}
            isAPOnboardingV1={isAPOnboardingV1}
            isPsblOnboarding={isPsblOnboarding}
          />
        );
      case PsblOnboardingSteps.PostingConfigurations:
      case APOnboardingSteps.AMLKYC:
        if (isPsblOnboarding && payStatementByLinkV289938) {
          const supplierId = getSupplierByMarketplacePath(defaultMarketPlacePath)?.SupplierID;
          return (
            <>
              <PostingConfigurationView
                isPsblOnboarding={isPsblOnboarding}
                supplierId={supplierId}
                onComplete={handlePostingConfigurationComplete}
              />
              {isSetupComplete && (
                <SetupComplete
                  onSetupComplete={onSetupComplete}
                  isAPOnboardingV1={isAPOnboardingV1}
                  isLoading={isLoading}
                  isPsblOnboarding={isPsblOnboarding}
                />
              )}
            </>
          );
        }
        return (
          <AMLKYC
            onNextClick={() => {
              handleSendEmail();
              fetchPaymentMethod();
            }}
            isAPOnboardingV1={isAPOnboardingV1}
          />
        );
      case APOnboardingSteps.SetupPaymentMethods:
        return (
          <PaymentMethod
            loadPaymentMethods={loadPaymentMethods}
            accounts={paymentAccounts}
            isLoading={isLoading}
            onNextClick={() => {
              setStepCompleted(APOnboardingSteps.SetupPaymentMethods);
              if (isSkipAdapter || !isAPFinancialAdaptorSetup()) {
                setActiveTab(APOnboardingSteps.UserSettings);
                handleTabChange({
                  action: 'next',
                  nextStep: APOnboardingSteps.UserSettings,
                  completeSteps: [APOnboardingSteps.SetupPaymentMethods],
                });
              } else {
                setActiveTab(APOnboardingSteps.ClearingAccount);
                handleTabChange({
                  action: 'next',
                  nextStep: APOnboardingSteps.ClearingAccount,
                  completeSteps: [APOnboardingSteps.SetupPaymentMethods],
                });
              }
            }}
            onSkipClick={() => {
              if (isSkipAdapter || !isAPFinancialAdaptorSetup()) {
                setActiveTab(APOnboardingSteps.UserSettings);
                handleTabChange({
                  action: 'skip',
                  nextStep: APOnboardingSteps.UserSettings,
                  skipSteps: [APOnboardingSteps.SetupPaymentMethods],
                });
              } else {
                setActiveTab(APOnboardingSteps.ClearingAccount);
                handleTabChange({
                  action: 'skip',
                  nextStep: APOnboardingSteps.ClearingAccount,
                  skipSteps: [APOnboardingSteps.SetupPaymentMethods],
                });
              }
            }}
            isAPOnboardingV1={isAPOnboardingV1}
          />
        );
      case APOnboardingSteps.ClearingAccount:
        return (
          <ClearingAccount
            onNextClick={() => {
              setStepCompleted(APOnboardingSteps.ClearingAccount);
              setActiveTab(APOnboardingSteps.UserSettings);
              handleTabChange({
                action: 'next',
                nextStep: APOnboardingSteps.UserSettings,
                completeSteps: [APOnboardingSteps.ClearingAccount],
              });
            }}
            onSkipClick={() => {
              setActiveTab(APOnboardingSteps.UserSettings);
              handleTabChange({
                action: 'skip',
                skipSteps: [APOnboardingSteps.ClearingAccount],
                nextStep: APOnboardingSteps.UserSettings,
              });
            }}
            isAPOnboardingV1={isAPOnboardingV1}
          />
        );
      case APOnboardingSteps.UserSettings:
        return (
          <React.Fragment>
            <UserSettings
              isAPOnboardingV1={isAPOnboardingV1}
              onNextClick={() => {
                setIsSetupComplete(true);
              }}
            />
            {isSetupComplete && (
              <SetupComplete
                onSetupComplete={onSetupComplete}
                isAPOnboardingV1={isAPOnboardingV1}
                isLoading={isLoading}
              />
            )}
          </React.Fragment>
        );
      default:
        return <Typography variant="h3">Not content</Typography>;
    }
  };

  const getTabHeading = (id: number): TabContent | null => {
    if (isPsblOnboarding && psblV22LowTouchOnboarding92923) {
      switch (id) {
        case LowTouchPsblOnboardingSteps.ConfigureFinancialAdaptor:
          return {
            heading: LowTouchPsblOnboardingStepsName.ConnectFinancialAdaptor,
            requiredTime: '2 min',
            icon: <APOnbBusinessInfoIcon width="20px" height={'20px'} />,
          };
        case LowTouchPsblOnboardingSteps.ClearingAccountSetup:
          return {
            heading: LowTouchPsblOnboardingStepsName.CreateTradeAccount,
            requiredTime: '1 min',
            icon: <APOnbFinancialAdaptorIcon width="20px" height={'20px'} className="ml-[1px]" />,
          };
        case LowTouchPsblOnboardingSteps.GenericInventorySetup:
          return {
            heading: LowTouchPsblOnboardingStepsName.SetupInventory,
            requiredTime: '6 min',
            icon: <IconSetupInventory />,
          };
        case LowTouchPsblOnboardingSteps.ReconcilingInfo:
          return {
            heading: LowTouchPsblOnboardingStepsName.ReconcilingPeriod,
            requiredTime: '1 min',
            icon: <IconReconsilingPeriod />,
          };
        case LowTouchPsblOnboardingSteps.SupplierMapping:
          return {
            heading: LowTouchPsblOnboardingStepsName.CapricornSupplierMapping,
            requiredTime: '1 min',
            icon: <IconCapSupplierMapping />,
          };
        case LowTouchPsblOnboardingSteps.InvoiceMatchingSettings:
          return {
            heading: LowTouchPsblOnboardingStepsName.InvoiceMatchingSettings,
            requiredTime: '1 min',
            icon: <IconInvoiceMatchingSettings />,
          };
        default:
          return null;
      }
    }
    switch (id) {
      case PsblOnboardingSteps.ConfigureFinancialAdaptor:
      case APOnboardingSteps.ConfigureFinancialAdaptor:
        if (isPsblOnboarding && payStatementByLinkV289938) {
          return {
            heading: PsblOnboardingStepsName.ConfigureFinancialAdaptor,
            requiredTime: '2 min',
            icon: <APOnbFinancialAdaptorIcon width="16px" height={'20px'} />,
          };
        }
        return {
          heading: APOnboardingStepsName.ConfigureFinancialAdaptor,
          requiredTime: '2 min',
          icon: <APOnbFinancialAdaptorIcon width="16px" height={'20px'} />,
        };
      case PsblOnboardingSteps.BusinessInformation:
      case APOnboardingSteps.BusinessInformation:
        if (isPsblOnboarding && payStatementByLinkV289938) {
          return {
            heading: lmiCommercialRelease92671 ? 'Book onboarding' : PsblOnboardingStepsName.BusinessInformation,
            requiredTime: '3 min',
            icon: <APOnbPostingConfigurationsIcon width="20px" height={'20px'} />,
          };
        }
        return {
          heading: APOnboardingStepsName.BusinessInformation,
          requiredTime: '1 min',
          icon: <APOnbBusinessInfoIcon width="20px" height={'20px'} />,
        };
      case PsblOnboardingSteps.PostingConfigurations:
      case APOnboardingSteps.AMLKYC:
        if (isPsblOnboarding && payStatementByLinkV289938) {
          return {
            heading: PsblOnboardingStepsName.PostingConfigurations,
            requiredTime: '3 min',
            icon: <APOnbPostingConfigurationsIcon width="20px" height={'20px'} />,
          };
        }
        return {
          heading: APOnboardingStepsName.AMLKYC,
          requiredTime: '30 sec',
          icon: <APOnbAmlKycIcon width="20px" height={'18px'} />,
        };
      case APOnboardingSteps.SetupPaymentMethods:
        return {
          heading: APOnboardingStepsName.SetupPaymentMethods,
          requiredTime: '2 min',
          icon: <APOnbPaymentMethodIcon width="20px" height={'16px'} />,
        };
      case APOnboardingSteps.ClearingAccount:
        return {
          heading: APOnboardingStepsName.ClearingAccount,
          requiredTime: '1 min',
          icon: <APOnbClearingAccountIcon width="20px" height={'14px'} />,
        };
      case APOnboardingSteps.UserSettings:
        return {
          heading: APOnboardingStepsName.UserSettings,
          requiredTime: '2 min',
          icon: <APOnbUserSettingIcon width="20px" height={'20px'} />,
        };
      default:
        return null;
    }
  };

  const TabView = (stepID: APOnboardingSteps | PsblOnboardingSteps, completedDateTime_utc?: string) => {
    let tabHeading = getTabHeading(stepID);
    if (!tabHeading) {
      return null;
    }
    return (
      <div className="flex items-center justify-between p-2.5">
        <div className="flex items-center gap-x-4">
          {completedDateTime_utc ? <img src={StepSuccessIcon} /> : tabHeading.icon}
          {tabHeading.heading}
        </div>
        <Typography variant="xsmall" className="flex items-center justify-end font-medium text-spenda-labeltext">
          <WatchIcon className="mr-2" width={'12px'} height={'12px'} /> {tabHeading.requiredTime}
        </Typography>
      </div>
    );
  };

  const getSteps = useMemo(() => {
    if (isPsblOnboarding && payStatementByLinkV289938) {
      if (psblV22LowTouchOnboarding92923) {
        return tabContentState?.filter(
          (s: IPsblOnboardingSteps) => LowTouchOnBoardingDisplaySteps.includes(s.stepID) && s?.stepID,
        );
      }
      return psblOnboardingSteps;
    }
    return tabContentState;
  }, [
    isPsblOnboarding,
    payStatementByLinkV289938,
    psblV22LowTouchOnboarding92923,
    tabContentState,
    psblOnboardingSteps,
  ]);

  return (
    <>
      <TabsHeader
        className={`hidden h-[calc(100vh-94px)] min-w-[345px] rounded-none border-r border-r-[#ECECEC] md:block ${isPsblOnboarding ? 'bg-opacity-1 bg-[#FAFAFA]' : 'bg-spenda-cream bg-opacity-0'}  p-0`}
      >
        <Typography variant="h3" className="px-5 py-6 text-base text-black">
          Onboarding steps
        </Typography>
        {getSteps.map?.((tab: IOnboardingV1Step | IPsblOnboardingSteps) => {
          return (
            <Tab
              onClick={() => {}}
              preventTabOnClick
              key={tab.stepID}
              value={tab.stepID}
              className={`max-h-[60px] cursor-default`}
            >
              {TabView(tab.stepID, tab.completedDateTime_utc)}
            </Tab>
          );
        })}
      </TabsHeader>
      <TabsBody className="bg-white">
        {!isPsblOnboarding && (
          <Typography
            variant="small"
            className="absolute right-0 top-0 z-[999] cursor-pointer  p-5 font-medium text-primary"
          >
            <span className=" hover:underline" onClick={handleLanding}>
              Go To Landing Page
            </span>
          </Typography>
        )}
        {getSteps.map?.((tab: IOnboardingV1Step | IPsblOnboardingSteps) => {
          return (
            <TabPanel key={tab.stepID} value={tab.stepID} className="pt-6">
              {getTabContent(tab.stepID)}
            </TabPanel>
          );
        })}
      </TabsBody>
      {isShowBookOnboardingDialog && lmiCommercialRelease92671 && (
        <BookOnboardingModal isOpen={isShowBookOnboardingDialog} />
      )}
    </>
  );
};
