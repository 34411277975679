import React, {useEffect, useState} from 'react';
import {Layout} from '../../components/layout/Layout';
import {Box} from '@material-ui/core';
import {FramelessDestructiveButton, SecondaryButton} from '../../components/buttons/DefaultButtons';
import {SelectToggler} from './CommonComponents';
import {PostingTypeModal} from '../../components/accountsPayableOnboarding/PostingTypeModal';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {useStagedTransactionsAPI} from '../../services/useStagedTransactionsAPI';
import {
  IStagedTransactions,
  ISupplierPrimaryContact,
  ISuppliersStagedTransactions,
  ITransactionsList,
  SelectedInvoicesType,
} from '../../model/requires-attention/StagedTransactions';
import {DatTypes} from '../../model/constants/Constants';
import {InvoiceMappingModal} from '../../components/accountsPayableOnboarding/InvoiceMappingModal';
import _ from 'lodash';
import APTooltip from '../../components/data-display/APTootip';
import {CompleteSupplierDetailsDialog} from '../../components/accountsPayableOnboarding/CompleteSupplierDetails';
import {DiscardSupplierDetails} from '../../components/accountsPayableOnboarding/DiscardSupplierDetails';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {CreditlineModal} from '../../components/accountsPayableOnboarding/CreditlineModal';
import {SelectPostingInventoryModal} from '../../components/accountsPayableOnboarding/SelectPostingInventoryModal';
import {RequiresAttentionTable} from '../../components/t2t/RequiresAttentionTable';

export const RequiresAttention = () => {
  const {getStagedTransactions, isLoading, approveStagedTransactions, rejectStagedTransactions, updateVendorMapping} =
    useStagedTransactionsAPI();
  const [stagedTransactions, setStagedTransactions] = useState<ISuppliersStagedTransactions[]>();
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const [selectPostingTypeModel, setSelectPostingTypeModel] = useState<boolean>(false);
  const [isInvoiceMappingModal, setIsInvoiceMappingModal] = useState<boolean>(false);
  const [breakDownSupplierId, setBreakDownSupplierId] = useState<number>();
  const [breakdownVendorToggle, setBreakdownVendorToggle] = useState<boolean>(false);
  const [selectedInvoices, setSelectedInvoices] = useState<SelectedInvoicesType>();
  const [allInvoiceSelected, setAllInvoiceSelected] = useState<boolean>(false);
  const [postingTypeSupplier, setPostingTypeSupplier] = useState<ISupplierPrimaryContact>();
  const [disableApproveInvoices, setDisableApproveInvoices] = useState<boolean>(false);
  const [isVendorPostingType, setIsVendorPostingType] = useState<boolean>(false);
  const [vendorPostingTypeDetail, setVendorPostingTypeDetail] = useState<ITransactionsList>();
  const [showCompleteSupplierDetails, setShowCompleteSupplierDetails] = useState<boolean>(false);
  const [showDiscardSupplierModal, setShowDiscardSupplierModal] = useState<boolean>(false);
  const [isVendorDataAvailable, setIsVendorDataAvailable] = useState<boolean>(false);
  const [showCreditlineModal, setShowCreditlineModal] = useState<boolean>(false);
  const [supplierCreditLineAccount, setSupplierCreditLineAccount] = useState<number | null>();
  const [approveErrorMessage, setApproveErrorMessage] = useState<string>('');
  const [selectPostingInventoryModal, setSelectPostingInventoryModal] = useState<boolean>(false);
  const [selectVendorPostingInventory, setSelectVendorPostingInventory] = useState<boolean>(false);
  const [selectSourceSupplierData, setSelectSourceSupplierData] = useState<ISuppliersStagedTransactions>();
  const [selectedVendorData, setSelectedVendorData] = useState<ITransactionsList>();

  const {T2TPhase280729: t2tV2} = useFeatureFlags().tenantOwned();

  const handleVendorMapping = async (supplierId: number, value: boolean) => {
    // call vendor mapping api
    await updateVendorMapping(supplierId, value);
    if (value) {
      setBreakdownVendorToggle(true);
    }
    if (isInvoiceMappingModal) setIsInvoiceMappingModal(false);
    if (t2tV2) {
      setShowCreditlineModal(true);
    } else {
      setIsMounted(false);
      setStagedTransactions([]);
      getStagedTransactionsDetails();
    }
  };

  const handleSelectPostingType = (
    show: boolean = false,
    supplierDetails?: ISupplierPrimaryContact,
    isVendorDataAvailable?: boolean,
  ) => {
    setSelectPostingTypeModel(show);
    if (show && supplierDetails) {
      isVendorDataAvailable && setIsVendorDataAvailable(isVendorDataAvailable);
      setPostingTypeSupplier(supplierDetails);
    }
  };

  const handleAllInvoiceSelect = () => {
    if (!stagedTransactions || !stagedTransactions.length) return;
    if (allInvoiceSelected) {
      // unselected all
      setSelectedInvoices(undefined);
      setAllInvoiceSelected(false);
    } else {
      const _selectedInvoices: any = {};
      stagedTransactions?.forEach(transaction => {
        if (transaction?.sourceSupplier?.supplierID) {
          const messageIds = transaction?.transactionsList?.map(item => {
            return item.messageID;
          });
          _selectedInvoices[transaction?.sourceSupplier?.supplierID?.toString()] = {
            MessageIds: messageIds,
          };
        }
      });
      setSelectedInvoices(_selectedInvoices);
      setAllInvoiceSelected(true);
    }
  };

  const handleBulkInvoices = (transaction: ISuppliersStagedTransactions) => {
    try {
      const _selectedInvoices = selectedInvoices ? _.cloneDeep(selectedInvoices) : {};
      const supplierId = transaction?.sourceSupplier?.supplierID;
      const stagedTrans = stagedTransactions?.find(trans => trans.sourceSupplier?.supplierID === supplierId);
      if (
        _selectedInvoices &&
        supplierId &&
        _selectedInvoices?.[supplierId]?.MessageIds?.length === stagedTrans?.transactionsList?.length
      ) {
        delete _selectedInvoices[supplierId];
        setSelectedInvoices(_selectedInvoices);
      } else {
        const messageIds = transaction?.transactionsList?.map(item => {
          return item?.messageID;
        });
        if (supplierId) {
          _selectedInvoices[supplierId.toString()] = {
            MessageIds: messageIds,
          };
          setSelectedInvoices(_selectedInvoices);
        }
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleSelectInvoices = (messageId: number, supplierId: number) => {
    try {
      const _selectedInvoices = selectedInvoices ? _.cloneDeep(selectedInvoices) : {};
      if (_selectedInvoices && _selectedInvoices?.[supplierId]) {
        // check if all already selected remove else add
        if (_selectedInvoices?.[supplierId]?.MessageIds.includes(messageId)) {
          const newList = _.remove(_selectedInvoices?.[supplierId]?.MessageIds, function (n) {
            return n !== messageId;
          });
          _selectedInvoices[supplierId.toString()] = {
            MessageIds: newList,
          };
        } else {
          _selectedInvoices[supplierId.toString()] = {
            MessageIds: [..._selectedInvoices[supplierId.toString()]?.MessageIds, messageId],
          };
        }
      } else {
        _selectedInvoices[supplierId.toString()] = {
          MessageIds: [messageId],
        };
      }
      setSelectedInvoices(_selectedInvoices);
    } catch (error) {
      console.error('error', error);
    }
  };

  const getStagedTransactionsDetails = async () => {
    const response = await getStagedTransactions();
    if (response && response.value) {
      const transactions: IStagedTransactions[] = response.value;
      const suppliers: ISuppliersStagedTransactions[] = [];
      transactions.forEach(item => {
        // check if supplier already exist in suppliers[] if not add in
        const existingSupplierIndex = suppliers.findIndex(
          supp =>
            supp?.sourceSupplier?.supplierID ===
            (item.datTypeID === DatTypes.StagedPurchaseInvoice
              ? item?.stagedPurchaseInvoice?.sourceSupplierID
              : item?.stagedDebitNote?.sourceSupplierID),
        );
        const isAutomaticImportInvoicesEnabled =
          item.datTypeID === DatTypes.StagedPurchaseInvoice
            ? item?.stagedPurchaseInvoice?.isSourceSupplierAutomaticInvoiceImportEnabled
            : item?.stagedDebitNote?.isSourceSupplierAutomaticInvoiceImportEnabled;
        if (isAutomaticImportInvoicesEnabled) {
          if (existingSupplierIndex === -1) {
            const isSourceSupplierVendorMappingEnabled =
              item.datTypeID === DatTypes.StagedPurchaseInvoice
                ? item?.stagedPurchaseInvoice?.isSourceSupplierVendorMappingEnabled!
                : item?.stagedDebitNote?.isSourceSupplierVendorMappingEnabled!;
            const sourceSupplierCreditLineAccountID =
              item.datTypeID === DatTypes.StagedPurchaseInvoice
                ? item?.stagedPurchaseInvoice?.sourceSupplierCreditLineAccountID!
                : item?.stagedDebitNote?.sourceSupplierCreditLineAccountID!;
            const isSourceSupplierPosted =
              item.datTypeID === DatTypes.StagedPurchaseInvoice
                ? item?.stagedPurchaseInvoice?.isSourceSupplierPosted!
                : item?.stagedDebitNote?.isSourceSupplierPosted!;
            const mappedSupplier =
              item.datTypeID === DatTypes.StagedPurchaseInvoice
                ? item?.stagedPurchaseInvoice?.supplierPrimaryContact
                : item?.stagedDebitNote?.supplierPrimaryContact;
            const sourceSupplier =
              item.datTypeID === DatTypes.StagedPurchaseInvoice
                ? item?.stagedPurchaseInvoice?.sourceSupplierPrimaryContact
                : item?.stagedDebitNote?.sourceSupplierPrimaryContact;
            const sourceSupplierPostingInventoryID =
              item.datTypeID === DatTypes.StagedPurchaseInvoice
                ? item?.stagedPurchaseInvoice?.sourceSupplierPostingInventoryID
                : item?.stagedDebitNote?.sourceSupplierPostingInventoryID;
            const sourceSupplierPostingInventoryCode =
              item.datTypeID === DatTypes.StagedPurchaseInvoice
                ? item?.stagedPurchaseInvoice?.sourceSupplierPostingInventoryCode
                : item?.stagedDebitNote?.sourceSupplierPostingInventoryCode;
            const isSourceSupplierInventoryMappingEnabled =
              item.datTypeID === DatTypes.StagedPurchaseInvoice
                ? item?.stagedPurchaseInvoice?.isSourceSupplierInventoryMappingEnabled
                : item?.stagedDebitNote?.isSourceSupplierInventoryMappingEnabled;
            if (isAutomaticImportInvoicesEnabled) {
              suppliers.push({
                sourceSupplier,
                mappedSupplier,
                transactionsList: [
                  {
                    stagedDebitNote: item?.stagedDebitNote || null,
                    stagedPurchaseInvoice: item?.stagedPurchaseInvoice || null,
                    datTypeID: item.datTypeID,
                    messageID: item.messageID,
                  },
                ],
                isSourceSupplierVendorMappingEnabled,
                isSourceSupplierPosted,
                sourceSupplierCreditLineAccountID,
                sourceSupplierPostingInventoryID,
                sourceSupplierPostingInventoryCode,
                isSourceSupplierInventoryMappingEnabled,
              });
            }
          } else {
            suppliers[existingSupplierIndex].transactionsList.push({
              stagedDebitNote: item?.stagedDebitNote || null,
              stagedPurchaseInvoice: item?.stagedPurchaseInvoice || null,
              datTypeID: item.datTypeID,
              messageID: item.messageID,
            });
          }
        }
      });
      setStagedTransactions(suppliers);
      setSelectedInvoices({});
      setAllInvoiceSelected(false);
      setDisableApproveInvoices(false);
      setIsMounted(true);
    } else {
      setIsMounted(true);
    }
  };

  useEffect(() => {
    const checkIfAllInvoicesSelected = async () => {
      let _disableApproveInvoices = false;
      if (selectedInvoices && stagedTransactions?.length) {
        let _allSelected = true;
        await stagedTransactions?.forEach(transaction => {
          const supplierId = transaction?.sourceSupplier?.supplierID;
          transaction.transactionsList.forEach(trans => {
            const err =
              trans.datTypeID === DatTypes.StagedPurchaseInvoice
                ? trans.stagedPurchaseInvoice?.errors
                : trans?.stagedDebitNote?.errors;
            if (
              err &&
              err.length &&
              supplierId &&
              selectedInvoices?.[supplierId]?.MessageIds.includes(trans.messageID)
            ) {
              _disableApproveInvoices = true;
            }
            if (
              transaction.isSourceSupplierVendorMappingEnabled &&
              !transaction.sourceSupplierCreditLineAccountID &&
              supplierId &&
              selectedInvoices?.[supplierId]?.MessageIds.includes(trans.messageID) &&
              t2tV2
            ) {
              _disableApproveInvoices = true;
              setApproveErrorMessage('Creditline account missing.');
            }
          });
          if (!_allSelected) return;
          if (supplierId && selectedInvoices?.[supplierId]?.MessageIds?.length) {
            if (selectedInvoices?.[supplierId]?.MessageIds?.length !== transaction?.transactionsList?.length) {
              _allSelected = false;
            }
          } else {
            _allSelected = false;
          }
        });
        if (!_disableApproveInvoices) {
          setApproveErrorMessage('');
        }
        setDisableApproveInvoices(_disableApproveInvoices);
        setAllInvoiceSelected(_allSelected);
      }
    };
    checkIfAllInvoicesSelected();
  }, [selectedInvoices]);

  useEffect(() => {
    setIsMounted(false);
    setStagedTransactions([]);
    getStagedTransactionsDetails();
  }, []);

  const handleApproveInvoice = async () => {
    if (selectedInvoices && !_.isEmpty(selectedInvoices)) {
      let messageIds: number[] = [];
      await Object.keys(selectedInvoices).map(key => {
        if (selectedInvoices[key]?.MessageIds?.length) {
          for (const msgId of selectedInvoices[key]?.MessageIds) {
            messageIds.push(msgId);
          }
        }
      });
      if (messageIds && messageIds.length) {
        approveStagedTransactions(messageIds).then(() => {
          setIsMounted(false);
          setStagedTransactions([]);
          getStagedTransactionsDetails();
        });
      }
    }
  };

  const handleRejectInvoice = async () => {
    if (selectedInvoices && !_.isEmpty(selectedInvoices)) {
      const messageIds: number[] = [];
      await Object.keys(selectedInvoices).map(key => {
        if (selectedInvoices[key]?.MessageIds?.length) {
          for (const msgId of selectedInvoices[key]?.MessageIds) {
            messageIds.push(msgId);
          }
        }
      });
      if (messageIds && messageIds.length) {
        rejectStagedTransactions(messageIds).then(() => {
          setIsMounted(false);
          setStagedTransactions([]);
          getStagedTransactionsDetails();
        });
      }
    }
  };

  const handlePostingInventoryModalClose = () => {
    setSelectPostingInventoryModal(false);
    setSelectVendorPostingInventory(false);
  };

  const invoices = Object.entries(selectedInvoices || {})?.map(([key, value]) => {
    return {key: key, value: value as any};
  });

  const leftPanel = (
    <div className={`relative mt-3 flex h-full  flex-col rounded bg-white`}>
      <div className={`flex items-center justify-between border-[#D8D8D8] border-b-default p-2.5`}>
        <h1 className="text-xxl min-w-[220px] pl-2 pt-1 text-left font-poppins font-light leading-8 text-spenda-primarytext">
          Manage suppliers
        </h1>
        <Box className="flex w-full justify-end pt-1 text-right">
          <APTooltip
            title={approveErrorMessage + '\n1 of invoices got issues to resolve, fix it or exclude it from selection.'}
            placement="bottom"
            arrow
            disableHoverListener={!disableApproveInvoices || !invoices.some(a => a?.value?.MessageIds?.length)}
          >
            <span>
              <SecondaryButton
                label="Approve Invoice"
                data-autoid="btnApproveInvoice"
                disabled={disableApproveInvoices || !invoices.some(a => a?.value?.MessageIds?.length)}
                onClick={handleApproveInvoice}
              />
            </span>
          </APTooltip>
          <FramelessDestructiveButton
            label="Reject Invoice"
            data-autoid="btnRejectInvoice"
            className="!ml-2.5"
            disabled={!invoices.some(a => a?.value?.MessageIds?.length)}
            onClick={handleRejectInvoice}
          />
        </Box>
      </div>
      <div className={`flex min-h-[51px] items-center justify-start pl-5`}>
        <span
          data-autoid={'lnkSelectAllToggler'}
          className={` items-center text-base font-medium`}
          onClick={() => handleAllInvoiceSelect()}
        >
          <SelectToggler
            dataAutoId="imgSelectAllToggler"
            className="!m-0"
            isSelected={allInvoiceSelected}
            isAccountsPayable
          />
        </span>
        <span className="ml-2 flex items-center justify-between text-right font-poppins font-medium text-primary">
          {'Select all'}
        </span>
      </div>
      <div className="h-full overflow-y-auto" style={{maxHeight: 'calc(100% - 140px)'}}>
        <RequiresAttentionTable
          t2tV2={t2tV2}
          stagedTransactions={stagedTransactions}
          handleBulkInvoices={handleBulkInvoices}
          breakDownSupplierId={breakDownSupplierId}
          setBreakDownSupplierId={setBreakDownSupplierId}
          handleVendorMapping={handleVendorMapping}
          breakdownVendorToggle={breakdownVendorToggle}
          setBreakdownVendorToggle={setBreakdownVendorToggle}
          handleSelectInvoices={handleSelectInvoices}
          selectedInvoices={selectedInvoices}
          handleSelectPostingType={handleSelectPostingType}
          setIsVendorPostingType={setIsVendorPostingType}
          setVendorPostingTypeDetail={setVendorPostingTypeDetail}
          setSelectPostingInventoryModal={setSelectPostingInventoryModal}
          setSelectVendorPostingInventory={setSelectVendorPostingInventory}
          setShowCreditlineModal={setShowCreditlineModal}
          setSupplierCreditLineAccount={setSupplierCreditLineAccount}
          setSelectSourceSupplierData={setSelectSourceSupplierData}
          setSelectedVendorData={setSelectedVendorData}
        />
      </div>
      <div className="ml-2 mr-2 "></div>
    </div>
  );

  return (
    <div className="relative h-full overflow-hidden bg-spenda-newbg">
      <LoadingIndicator isLoading={isLoading || !isMounted} size="md" color="hsl(var(--primary))" />
      <Layout splitWidthType={4} leftPanel={leftPanel} />
      {selectPostingTypeModel && (
        <PostingTypeModal
          scope="AP"
          isDialog
          isVendorPostingType={isVendorPostingType}
          vendorPostingTypeDetail={vendorPostingTypeDetail}
          handleClose={() => {
            handleSelectPostingType(false);
            setIsVendorDataAvailable(false);
            setIsVendorPostingType(false);
            setVendorPostingTypeDetail(undefined);
          }}
          supplierDetails={postingTypeSupplier}
          handleDone={async () => {
            handleSelectPostingType(false);
            // check if we have vendor posting data for the supplier
            if (!isVendorPostingType && isVendorDataAvailable) setIsInvoiceMappingModal(true);
            else {
              setIsVendorPostingType(false);
              setVendorPostingTypeDetail(undefined);
              setIsMounted(false);
              setStagedTransactions([]);
              getStagedTransactionsDetails();
            }
          }}
          handleVendorPostAsIs={(vendorDetails: ITransactionsList) => {
            setVendorPostingTypeDetail(vendorDetails);
            handleSelectPostingType(false);
            setShowCompleteSupplierDetails(true);
          }}
        />
      )}
      {isInvoiceMappingModal && (
        <InvoiceMappingModal
          supplierId={postingTypeSupplier?.supplierID!}
          handleVendorMapping={handleVendorMapping}
          handleClose={() => {
            setIsInvoiceMappingModal(false);
            setIsVendorDataAvailable(false);
            setIsVendorPostingType(false);
            setVendorPostingTypeDetail(undefined);
            setIsMounted(false);
            setStagedTransactions([]);
            getStagedTransactionsDetails();
          }}
        />
      )}

      {showCompleteSupplierDetails && (
        <CompleteSupplierDetailsDialog
          handleClose={() => {
            setShowCompleteSupplierDetails(false);
            setIsVendorPostingType(false);
            setVendorPostingTypeDetail(undefined);
            setIsMounted(false);
            setStagedTransactions([]);
            getStagedTransactionsDetails();
          }}
          handleDiscardModal={() => setShowDiscardSupplierModal(true)}
          vendorPostingDetails={vendorPostingTypeDetail!}
          selectedSupplierId={postingTypeSupplier?.supplierID!}
        />
      )}
      {showDiscardSupplierModal && (
        <DiscardSupplierDetails
          handleClose={() => setShowDiscardSupplierModal(false)}
          handleDiscard={() => {
            setShowDiscardSupplierModal(false);
            setShowCompleteSupplierDetails(false);
            setIsVendorPostingType(false);
            setVendorPostingTypeDetail(undefined);
            setIsMounted(false);
            setStagedTransactions([]);
            getStagedTransactionsDetails();
          }}
        />
      )}
      {showCreditlineModal && (
        <CreditlineModal
          handleClose={() => {
            setShowCreditlineModal(false);
            setSupplierCreditLineAccount(null);
            setIsMounted(false);
            setStagedTransactions([]);
            getStagedTransactionsDetails();
          }}
          selectedCreditLineAccountID={supplierCreditLineAccount}
          supplierID={breakDownSupplierId || postingTypeSupplier?.supplierID!}
          disableDoneButton={supplierCreditLineAccount ? true : false}
        />
      )}
      {selectPostingInventoryModal && (
        <SelectPostingInventoryModal
          selectedSupplierData={selectSourceSupplierData}
          selectedVendorData={selectedVendorData}
          isVendorSelected={selectVendorPostingInventory}
          handleClose={() => handlePostingInventoryModalClose()}
          handleDone={() => {
            handlePostingInventoryModalClose();
            getStagedTransactionsDetails();
          }}
          scope="AP"
        />
      )}
    </div>
  );
};
