import React, {useState, useRef, ChangeEvent, useEffect} from 'react';
import {Input} from 'spenda-ui-react';

interface ColorPickerInputProps {
  value: string;
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement> | {target: {name: string; value: string}}) => void;
  disabled?: boolean;
  className?: string;
}

interface SyntheticEvent {
  target: {
    name: string;
    value: string;
  };
}

const ColorPickerInput: React.FC<ColorPickerInputProps> = ({value, onChange, name, disabled, className = ''}) => {

  const formatColor = (color: string): string => {
    if (!color) return '';
    return color.startsWith('#') ? color : `#${color}`;
  };

  const [selectedColor, setSelectedColor] = useState<string>(formatColor(value));

  useEffect(() => {
    setSelectedColor(formatColor(value));
  }, [value]);

  const colorPickerRef = useRef<HTMLInputElement>(null);

  const handleColorPickerClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault();
    if (colorPickerRef.current) {
      colorPickerRef.current.click();
    }
  };

  const handleColorChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const newColor = e.target.value;
    setSelectedColor(newColor);
    const syntheticEvent: SyntheticEvent = {
      target: {
        name,
        value: newColor,
      },
    };
    onChange(syntheticEvent);
  };

  return (
    <Input
      data-autoid="txtColour"
      label="Colour Code"
      type="text"
      name={name}
      placeholder="Color"
      containerProps={{className: `w-[134px] min-w-[134px] ${className}`}}
      value={selectedColor}
      onChange={onChange}
      disabled={disabled}
      InputProp={{
        endAdornment: (
          <div className="relative">
            <div
              className={`h-6 w-6 rounded-md border border-[#ECECEC] ${!disabled ? 'cursor-pointer' : 'cursor-not-allowed'}`}
              style={{
                backgroundColor: selectedColor || '#FFFFFF',
              }}
              onClick={!disabled ? handleColorPickerClick : undefined}
            />
            <input
              ref={colorPickerRef}
              type="color"
              value={selectedColor || '#FFFFFF'}
              onChange={handleColorChange}
              className="absolute h-0 w-0 opacity-0"
              name={name}
              disabled={disabled}
            />
          </div>
        ),
        startAdornment: <></>,
      }}
    />
  );
};

export default ColorPickerInput;
