import React from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {SButton} from '../buttons/Button';
import NextArrowIcon from '../../assets/svg/next-arrow.svg';
import {ITenantTemplate} from '../../model/Tenant';
import _ from 'lodash';
import BackIcon from '../../assets/svg/back.svg';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import LoadingIndicator from '../ui/LoadingIndicator';
import {SModal} from '../modals/modalSpendaMeterialUI';
import {ChatWidget} from './ChatWidget';
import CloseIcon from '@material-ui/icons/Close';
import {GetBackgroundCSS} from './Onboarding';

const useTemplateSelectionStyles = makeStyles({
  rebrandingTempleteBtn: {
    flexWrap: 'wrap',
  },
});
interface TemplateSelectionProps {
  onNextClick: () => void;
  businessTemplate?: ITenantTemplate[];
  selectedBusinessTemplate?: ITenantTemplate;
  onTemplateSelection: (businessTemplate: ITenantTemplate) => void;
  onBackPress: () => void;
  isLoading: Boolean;
  handleClose: () => void;
  isBuyer: boolean;
}

export const TemplateSelection: React.FunctionComponent<TemplateSelectionProps> = ({
  onNextClick,
  businessTemplate,
  selectedBusinessTemplate,
  onTemplateSelection,
  onBackPress,
  isLoading,
  handleClose,
  isBuyer,
}: TemplateSelectionProps) => {
  const classes = useTemplateSelectionStyles();

  return (
    <SModal open>
      <div className={`spenda-color flex h-full max-w-full items-center justify-center text-xl ${GetBackgroundCSS()}`}>
        <div className="modal-box max-w-3xl px-5 py-5">
          {isBuyer && (
            <span className="closeIcon onboarding-close-icon" onClick={handleClose} data-autoid="btnClose">
              <CloseIcon />
            </span>
          )}
          <span className="cursor-pointer" onClick={onBackPress} style={{position: 'relative'}} data-autoid="btnBack">
            <img src={BackIcon} alt="Next Arrow Icon" className="back-btn-bg-image" style={{marginTop: '-7px'}} />
            <ChevronLeftIcon className="back-btn-icon" />
          </span>
          <p id="template-selection-title" className="simple-modal-title pb-3 text-center text-3xl">
            "First off, how would you describe your business?"
          </p>
          <p
            id="template-selection-description"
            className="simple-modal-description color-gray mx-auto mb-8 w-4/5 text-center text-lg"
          >
            We use business templates to help configure your account and speed up the setup process. Please select a
            business template from the below options, and click "Next" to continue.
          </p>
          <div className={`flex flex-col justify-around px-7 py-4 pt-0`}>
            <div className={`row flex`}>
              <div className="template-selection-modal-box column justify-center" style={{width: '100%'}}>
                <p
                  id="template-selection-sm-title"
                  className="simple-modal-description-note mb-2 text-center text-xs text-gray-600"
                >
                  Select from below, and click <span className="font-semibold"> 'Next'</span> to continue
                </p>
                <div
                  className={`${classes.rebrandingTempleteBtn}`}
                  style={{
                    maxHeight: '210px',
                    overflowY: 'auto',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  {businessTemplate &&
                    businessTemplate.map((business, index) => {
                      return (
                        <SButton
                          className="ml-3"
                          key={index}
                          color={'lightBlue'}
                          textColor={'blue'}
                          width={'200px'}
                          lineHeight="l"
                          fontSize="m"
                          boxShadow="none"
                          backgroundColor="#EFF3F6"
                          onClick={() => onTemplateSelection(business)}
                          padding="6px 16px"
                          margin={'0px 7px 20px 8px'}
                          variant={
                            business.TemplateID === selectedBusinessTemplate?.TemplateID ? 'outlined' : 'contained'
                          }
                          border={
                            business.TemplateID === selectedBusinessTemplate?.TemplateID
                              ? '2px solid #1C78AD'
                              : '2px solid transparent'
                          }
                        >
                          {business.Name}
                        </SButton>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <Box display="flex" justifyContent="center">
            <SButton
              className="common-next-btn fridayNextbtn"
              color={_.isEmpty(selectedBusinessTemplate) ? 'lightBlue' : 'darkBlue'}
              type="submit"
              width="m"
              lineHeight="m"
              fontSize="m"
              disabled={(_.isEmpty(selectedBusinessTemplate) ? true : false) || Boolean(isLoading)}
              onClick={onNextClick}
              data-autoid="btnNext"
            >
              Next{' '}
              {isLoading ? (
                <LoadingIndicator
                  isLoading={Boolean(isLoading)}
                  size="sm"
                  color="hsl(var(--white))"
                  position={{right: '5px'}}
                />
              ) : (
                <img src={NextArrowIcon} alt="Next Arrow Icon" className="" />
              )}
            </SButton>
          </Box>
          <div className="live-chat-section">
            <ChatWidget />
          </div>
        </div>
      </div>
    </SModal>
  );
};
