import React, {useState, useEffect, useContext, useCallback} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {InvoiceDetail} from '../../components/ui/InvoiceDetailsComponent/InvoiceDetail';
import AppContext from '../../context/app/appContext';
import {IAlertInfo, IAlertRequestRequest} from '../../model/alert/AlertInfo';
import {DatTypes, PaymentStatusType, PaymentWidgetScope} from '../../model/constants/Constants';
import {IInvoice} from '../../model/invoice/Invoice';
import {useSupplierTransactionsAPI} from '../../services/useSupplierTransactionsAPI';
import {PayByLinkLayout} from './Layout';
import {useAuth} from '../../services/useAuth';
import {Toast} from '../../utils/Toast';
import {InvoiceFooter} from './InvoiceFooter';
import useConnectedSupplierAPI from '../../services/useConnectedSuppliersAPI';
import {IConnectedSupplier} from '../../model/supplier/ConnectedSupplier';
import {ICreditCardT, ITenantSupplierPaymentMethod} from '../../model/payment/PaymentMethod';
import {IRefBusTrans} from '../../model/payment/PaymentT';
import useConnectedSupplier from '../../hooks/useConnectedSupplier';
import {usePaymentsFromBuyer} from './payment-widget/usePaymentsFromBuyer';
import {IDefaultDbiDetails, ISubmitPayment, PaymentWidgetModal} from './payment-widget/PaymentWidget';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {useFeatureFlagsAPI} from '../../services/useFeatureFlagsAPI';
import {useCreditClaimsAPI} from '../../services/useCreditClaimsAPI';
import moment from 'moment';
import {usePaymentMethods} from './payment-widget/usePaymentMethods';
import {IGetSurchargeProps} from '../../model/payment-widget/PaymentWidget';
import {TenantSetupComponent} from '../../components/tenantSetup/TenantSetupComponent';
import {PayByLinkContext} from './PayByLinkContext';
import {IUser} from '../../model/user/User';

export interface IPayByLinkInvoice {
  linkedSupplierId?: string;
  transactionGuid?: string;
  magicLink?: string;
}

export const PayByLinkInvoice = () => {
  const {
    logout: removeSession,
    suppliers,
    user,
    setFeatureFlags,
    marketplaceSupplier,
    switchSupplier,
    tenant,
    tenantInfo,
    availablePaymentMethodTypes,
  } = useContext(AppContext);

  const {supplierDetails} = useContext(PayByLinkContext);

  const [invoice, setInvoice] = useState<IInvoice | undefined>();
  const [isPayMode, setPayMode] = useState<boolean>(false);
  const [refreshInvoice, setRefreshInvoice] = useState<boolean>(true);
  const [showSetupTenantModal, setShowSetupTenantModal] = useState<boolean>(false);
  const [hasAmountError] = useState(false);

  const history = useHistory();
  const redirectUrl = new URLSearchParams(useLocation().search).get('redirectUrl') || '';
  const {magicLink} = useParams<{magicLink?: string}>();

  const [isAutoOpenWidget, setIsAutoOpenWidget] = useState(!!redirectUrl);
  const [connectedSupplier, setConnectedSupplier] = useState<IConnectedSupplier | undefined>();
  const [invoiceLoading, setInvoiceLoading] = useState(false);

  const {getClaims: getCreditClaimsAPI, getTotalPaymentAndCreditToApplyValues} = useCreditClaimsAPI();
  const {
    getInvoice: getInvoiceAPI,
    getInvoicePrintout,
    search: getSupplierStatementSummary,
  } = useSupplierTransactionsAPI();
  const {getConnectedSupplier} = useConnectedSupplierAPI();
  const {logout: logoutAPI} = useAuth();
  const {isBPSP} = useConnectedSupplier();

  const {fetchFlags} = useFeatureFlagsAPI();

  const {featLoyaltyPoints, capricornDemo77857, scheduledPayments83107, scheduledPaymentsV2, fees88078} =
    useFeatureFlags().supplierDriven();

  const defaultDbiDetails: IDefaultDbiDetails = {
    AU: invoice?.RefNumber ?? '',
    IK: 'Invoice Payment',
  };

  const {paymentAuth72488, onboardingUpgradeV1, airplus79131} = useFeatureFlags().tenantOwned();

  const {
    getSurcharge: getSurchargeAPI,
    submitPayment: submitPaymentAPI,
    ARcheckBatchPaymentStatus,
  } = usePaymentsFromBuyer({
    linkedSupplierId: invoice?.Supplier?.ID,
    paymentAuth72488,
    scheduledPayments83107,
    airplus79131,
    fees88078,
  });

  const {
    fetchSPSAccount,
    initiateVerification,
    onRetryVerification,
    onVerifyCode,
    removeCreditCard,
    saveCreditCard,
    updateAccountDetails,
    updatePaymentMethodDetails,
    getPaymentMethods: getPaymentMethodsFromSPS,
    getPaymentMethodsWithFees,
    getPaymentMethodAuthorisationRules,
    savePaymentAuthorisationRules,
    authorisePayment,
    approvePaymentAuthorisation,
    getPaymentMethodAuthorisationDetails,
    resetAuthenticationCode,
    rejectPaymentAuthorisation,
    fetchAirPlusDBIConfig,
  } = usePaymentMethods({
    linkedSupplierId: invoice?.Supplier?.ID,
    merchantGlobalID: marketplaceSupplier?.GlobalID,
    fees88078,
  });

  useEffect(() => {
    if (tenantInfo) {
      setShowSetupTenantModal(!tenantInfo?.TenantUserDetails.AgreedTsAndCs);
    }
  }, [tenantInfo]);

  useEffect(() => {
    if (isPayMode) return; // we are not calling the fetchInvoice API when the widget is open
    const fetchInvoice = async () => {
      // const magicLink = props.location.state?.magicLink || '';

      if (!magicLink) {
        return;
      }

      const request = {
        MagicLink: magicLink,
      };

      setInvoiceLoading(true);
      const invoice = await getInvoiceAPI(request);

      // Get connected supplier info and update feature Flags
      if (invoice.Supplier?.ID) {
        const featureFlags = await fetchFlags(invoice.Supplier?.ID);
        setFeatureFlags(featureFlags);

        const connectedSupplier = await getConnectedSupplier(invoice.Supplier.ID.toString());
        setConnectedSupplier(connectedSupplier);

        switchSupplier(connectedSupplier.AvailableModules, connectedSupplier.MarketplacePath);
      } else {
        Toast.info('Invoice is missing supplier identification');
      }

      setInvoice(invoice);
      setInvoiceLoading(false);
    };

    if (refreshInvoice && user?.UserID && !isPayMode) {
      fetchInvoice();
      setRefreshInvoice(false);
    }
  }, [
    refreshInvoice,
    user?.UserID,
    isPayMode,
    magicLink,
    getInvoiceAPI,
    fetchFlags,
    setFeatureFlags,
    getConnectedSupplier,
    switchSupplier,
  ]);

  const redirectToLogin = useCallback(() => {
    history.push(`/invoices?code=${magicLink}`);
  }, []);

  useEffect(() => {
    if (!user) {
      redirectToLogin();
    }
  }, [redirectToLogin, user]);

  const onOpenPDF = () => {
    // if (supplier?.SupplierID) {
    const alertReq: Partial<IAlertRequestRequest> = {
      LinkedSupplierID: invoice?.Supplier?.ID,
      TransactionGuid: invoice?.GUID,
      DatTypeID: DatTypes.Invoice, //tx && tx.DatTypeID,
      DocID: invoice?.ID,
    };

    return getInvoicePrintout(alertReq).then((data: IAlertInfo) => {
      if (data.AlertMsg) {
        const pdf = data.AlertMsg.AttachmentFileNames[0];
        window.open(pdf);
        return;
      }
    });
    // }
  };

  const onLogout = () => {
    logoutAPI().finally(() => {
      removeSession();
      redirectToLogin();
      Toast.info('Thanks for using Spenda! You have been succesfully logged out.');
    });
  };

  const onAmountChanged = (updatedAmount?: number) => {
    if (invoice) {
      let i = {...invoice};
      i.AmountToPay = updatedAmount || 0;
      setInvoice(i);
    }
  };

  const onSaveCreditCard = (card: ICreditCardT) => {
    const isBPSPMerchant = connectedSupplier ? isBPSP(connectedSupplier) : false;
    return saveCreditCard(card, isBPSPMerchant);
  };

  const invoicesToPay = invoice ? [invoice] : [];

  const submitPayment = (submitPaymentPayload: ISubmitPayment) => {
    const {
      pm,
      merchantSurcharge,
      temporalCard,
      isCreditNotesAppliedGreaterThanInvoiceTotal,
      creditStatementSummary,
      isLoyaltyPointsToggleOn,
      authorisationToken,
      scheduledPaymentDate,
      airPlusDbiData,
    } = submitPaymentPayload;
    return submitPaymentAPI(
      pm,
      merchantSurcharge,
      invoicesToPay,
      temporalCard,
      isCreditNotesAppliedGreaterThanInvoiceTotal,
      creditStatementSummary,
      isLoyaltyPointsToggleOn,
      authorisationToken,
      undefined,
      undefined,
      PaymentWidgetScope.PIBL,
      undefined,
      scheduledPaymentDate,
      airPlusDbiData,
    );
  };

  const getSurcharge = (props: IGetSurchargeProps) => {
    if (fees88078) return;
    let refBusTrans: IRefBusTrans[] = [];

    const {
      creditStatementSummary,
      isCreditNotesAppliedGreaterThanInvoiceTotal,
      paymentAccountGUID,
      ccType,
      isPayerFeeApplicable,
    } = props;

    if (creditStatementSummary?.CreditNotesAndClaimsTotalAmount) {
      if (isCreditNotesAppliedGreaterThanInvoiceTotal) {
        // setMerchantSurcharge && setMerchantSurcharge(undefined);
        return;
      }
      refBusTrans = creditStatementSummary.InvoicesToCalculateSurcharge?.filter(
        statement => statement.DatTypeID === DatTypes.Invoice,
      ).map(i => {
        return {
          GUID: i.TransGUID,
          RefNumber: i.RefNumber,
          AppliedDate: moment().local().format(),
          AppliedAmount: i.AmountToPay || i.Balance,
          IsSyncBackRequired: false,
          IsUpdate: false,
        } as IRefBusTrans;
      });
    } else {
      refBusTrans =
        invoicesToPay?.map(i => {
          return {
            GUID: i.GUIDstring || i.GUID,
            RefNumber: i.RefNumber,
            AppliedDate: moment().local().format(),
            AppliedAmount: i.AmountToPay || i.Balance, // It is not invoice's balance, it should be Split payment AmountToPay
            IsSyncBackRequired: false,
            IsUpdate: false,
          } as IRefBusTrans;
        }) || [];
    }
    return getSurchargeAPI({ccType, paymentAccountGUID, refBusTrans, isPayerFeeApplicable});
  };

  const isPaid =
    (invoice?.Balance || 0) <= 0 && invoice?.Status.toLocaleLowerCase() === PaymentStatusType.Paid.toLocaleLowerCase();

  const isPaymentInProgress = Boolean(invoice?.Payments?.find(p => p.Status === PaymentStatusType.InProgress));

  const isPaymentScheduled = Boolean(
    invoice?.Payments?.find(
      p => p.Status === PaymentStatusType.InProgress && p?.InvoicePaymentAllocations?.[0]?.IsUpcomingPayment,
    ),
  );

  const totalPayableAmount =
    invoicesToPay?.reduce((acc, curr) => acc + (curr.AmountToPay || curr.Balance || 0), 0) || 0;

  const getPaymentMethods = (): Promise<ITenantSupplierPaymentMethod[]> => {
    if (fees88078) {
      return getPaymentMethodsWithFees(totalPayableAmount);
    } else {
      return getPaymentMethodsFromSPS();
    }
  };

  const invoiceFooter = (
    <InvoiceFooter
      invoiceData={invoice}
      onSavePDFClick={onOpenPDF}
      onPayClick={(invoice?.Balance || 0) > 0 ? () => setPayMode(true) : () => undefined}
      hasAmountError={hasAmountError}
      isPaymentInProgress={isPaymentInProgress}
      onAmountChanged={onAmountChanged}
      redirectUrl={redirectUrl}
      isAutoOpenWidget={isAutoOpenWidget}
      supplierId={marketplaceSupplier?.SupplierID}
      invoiceLoading={invoiceLoading}
    />
  );

  const buyerDetailsFromSupplier: Partial<IUser> = {
    FirstName: supplierDetails?.contact.firstName || '',
    LastName: supplierDetails?.contact.lastName || '',
    TenantName: supplierDetails?.contact.companyName || '',
    ABN: supplierDetails?.contact.abn || '',
  };

  return (
    <>
      {showSetupTenantModal ? (
        <TenantSetupComponent
          tenant={tenant}
          user={{...user, ...(buyerDetailsFromSupplier as IUser)}}
          onAfterSubmit={() => setShowSetupTenantModal(false)}
        />
      ) : (
        <>
          <PayByLinkLayout isScrollable>
            <InvoiceDetail
              tx={invoice as IInvoice}
              isPaid={isPaid}
              isPaymentInProgress={isPaymentInProgress}
              isPaymentScheduled={isPaymentScheduled}
              supplier={invoice?.Supplier}
              bgColor="white"
              footer={invoiceFooter}
              isStickyFooter
              hideQuantity={!tenantInfo?.TenantUserDetails.IsShowQuantityInQuote}
            />
            {}
          </PayByLinkLayout>
          <PaymentWidgetModal
            approvePaymentAuthorisation={approvePaymentAuthorisation}
            authorisePayment={authorisePayment}
            availablePaymentMethodTypes={availablePaymentMethodTypes}
            availablePaymentOptions={marketplaceSupplier?.AvailablePaymentMethods}
            capricornDemo77857={capricornDemo77857}
            checkPaymentStatus={ARcheckBatchPaymentStatus}
            featLoyaltyPoints={featLoyaltyPoints}
            fetchSPSAccount={fetchSPSAccount}
            getCreditClaimsAPI={getCreditClaimsAPI}
            getPaymentMethodAuthorisationDetails={getPaymentMethodAuthorisationDetails}
            getPaymentMethodAuthorisationRules={getPaymentMethodAuthorisationRules}
            getSupplierStatementSummary={getSupplierStatementSummary}
            getSurcharge={getSurcharge}
            getTotalPaymentAndCreditToApplyValues={getTotalPaymentAndCreditToApplyValues}
            initiateVerification={initiateVerification}
            invoicesToPay={invoicesToPay}
            isBPSP={isBPSP(connectedSupplier)}
            isLinkedToLendingProvider={marketplaceSupplier?.IsLendingProvider}
            loadPaymentOptions={getPaymentMethods}
            marketplaceSupplier={marketplaceSupplier}
            onClose={() => {
              setIsAutoOpenWidget(false);
              setPayMode(value => !value);
            }}
            onLogout={onLogout}
            onPaymentApproved={() => setRefreshInvoice(true)}
            onRetryVerification={onRetryVerification}
            onVerifyCode={onVerifyCode}
            open={isPayMode}
            paymentAuth72488={paymentAuth72488}
            rejectPaymentAuthorisation={rejectPaymentAuthorisation}
            removeCreditCard={removeCreditCard}
            resetAuthenticationCode={resetAuthenticationCode}
            saveCreditCard={onSaveCreditCard}
            savePaymentAuthorisationRules={savePaymentAuthorisationRules}
            submitPayment={submitPayment}
            suppliers={suppliers}
            tenantName={user?.TenantName}
            tenantUserDetails={tenantInfo?.TenantUserDetails}
            totalPayableAmount={totalPayableAmount}
            updateAccountDetails={updateAccountDetails}
            updatePaymentMethodDetails={updatePaymentMethodDetails}
            userEmailAddress={user?.UserName}
            userID={user?.UserID}
            widgetScope={PaymentWidgetScope.PIBL}
            scheduledPaymentsV2={scheduledPaymentsV2}
            scheduledPayments83107={scheduledPayments83107}
            isAPOnboardingV1={onboardingUpgradeV1}
            airplus79131={airplus79131}
            fetchAirPlusDBIConfig={fetchAirPlusDBIConfig}
            defaultDbiDetails={defaultDbiDetails}
            fees88078={fees88078}
          />
        </>
      )}
    </>
  );
};
