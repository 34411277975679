import React from 'react';

interface ITableSkeletonProps {
  cols: number;
  rows: number;
  dataAutoId: string;
}
const TableSkeleton = (props: ITableSkeletonProps) => {
  const {cols, rows, dataAutoId} = props;

  return (
    <table className="relative w-full rounded-sm">
      <thead className="sticky top-0 z-[1]">
        <tr className="relative w-auto">
          {Array(cols)
            .fill(1)
            .map((_, indx) => (
              <th
                key={`${dataAutoId}Th${indx}`}
                className="sticky top-0 z-10 bg-gray-200 p-2.5 text-center text-base font-semibold leading-normal"
              >
                <div className="h-4 w-1/4 rounded bg-gray-100"></div>
              </th>
            ))}
        </tr>
      </thead>
      <tbody>
        {Array(rows)
          .fill(1)
          ?.map(tl => (
            <tr key={tl}>
              {Array(cols)
                .fill(1)
                .map((_, indx) => (
                  <td key={`${dataAutoId}Th${indx}`}>
                    <div className="mt-1 h-4 w-full rounded bg-gray-100"></div>
                  </td>
                ))}
            </tr>
          ))}
        <tr></tr>
      </tbody>
    </table>
  );
};

export default TableSkeleton;
