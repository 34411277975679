import {Box} from '@material-ui/core';
import React, {useContext} from 'react';
import {
  CreditNotesFooter,
  CreditNotesHeader,
  CreditNotesListDialog,
  CreditNotesTableBody,
  CreditNotesTableHeader,
} from '../../../components/dialog/CreditNotesListDialog';
import {PaymentWidgetContext, PaymentWidgetPaymentSteps, PaymentWidgetStep} from './PaymentWidget';
import {useCreditNotes} from '../../../hooks/useCreditNotesHooks';
import {DatTypes, PaymentWidgetScope} from '../../../model/constants/Constants';
import ARContext from '../../../context/ar-context/ARContext';
import {IARBatchResponse} from '../../../model/accounts-receivable/batch';
import LoadingIndicator from '../../../components/ui/LoadingIndicator';

interface IPaymentWidgetStepCreditNotesProps {
  isShowDialog?: boolean;
  handleCloseDialog?: () => void;
}

export const PaymentWidgetStepCreditNotes = (props: IPaymentWidgetStepCreditNotesProps) => {
  const {isShowDialog, handleCloseDialog} = props;

  const {
    getCreditClaimsAPI: getClaims,
    getTotalPaymentAndCreditToApplyValues,
    getSupplierStatementSummary: search,
    setStep,
    marketplaceSupplier,
    suppliers,
    invoicesToPay,
    creditsToApply,
    setCreditsToApply,
    creditStatementSummary,
    setCreditStatementSummary,
    widgetScope,
    creditAndClaimsList,
    setCreditAndClaimsList,
  } = useContext(PaymentWidgetContext);

  const {psblBatch} = useContext(ARContext);

  const handleDialog = () => {
    setStep?.(PaymentWidgetPaymentSteps.NEW);
    handleCloseDialog?.();
  };

  const invoices = invoicesToPay?.reduce(
    (acc, current) => {
      const invoice = {
        AmountToPay: current.AmountToPay,
        Balance: current.Balance,
        DatTypeID: current.DatTypeID,
        RefNumber: current.RefNumber,
        TransGUID: current.GUID,
        TransID: current.ID,
      };
      if (!acc.hasOwnProperty('key')) {
        return (acc = [invoice]);
      } else {
        return (acc = [...acc, invoice]);
      }
    },
    [{}],
  );

  const {
    applyCredit,
    getCreditNotesAndClaims,
    creditList,
    isLoadingApplyCredit,
    isAutoApprovedClaimEnabled,
    editClaim,
    handleClaimDialog,
    handleEditClaim,
    isSelectedCreditGreaterThanInvoiceAmount,
    handleCreditsToApply,
    handleAddClaim,
    totalInvoiceAmount,
    totalCreditSelected,
    handleDeleteClaim,
    selectedCredits,
    showClaimDialog,
    claimId,
    claimCode,
    selectedCreditAndClaimsIds,
    handleSelectAll,
    isAllSelected,
    loadingCreditList,
  } = useCreditNotes({
    setCreditsToApply,
    invoicesToPay: invoices,
    getTotalPaymentAndCreditToApplyValues,
    setCreditStatementSummary,
    creditStatementSummary,
    marketplaceSupplier,
    search,
    suppliers,
    getClaims,
    isSingleInvoice: widgetScope === PaymentWidgetScope.PIBL,
    handleDialog,
    creditsToApply,
    psblBatch: {
      claims: psblBatch?.selectedTxList.filter(tx => tx.transactionTypeID === DatTypes.Claim) as any,
      creditNotes: psblBatch?.selectedTxList.filter(tx => tx.transactionTypeID === DatTypes.CreditNote) as any,
      payments: psblBatch?.selectedTxList.filter(tx => tx.transactionTypeID === DatTypes.Payment) as any,
      invoices: psblBatch?.selectedTxList.filter(tx => tx.transactionTypeID === DatTypes.Invoice) as any,
    } as IARBatchResponse,
    totalInvoiceAmount: psblBatch?.totalInvoiceAmount,
    totalCreditAndClaimsSelected: psblBatch?.totalCreditClaimsPrepaymentsSelectedAmount,
    creditAndClaimsList,
    setCreditAndClaimsList,
    setStep,
  });

  const title = (
    <Box>
      <CreditNotesHeader
        totalInvoiceAmount={totalInvoiceAmount}
        isAutoApprovedClaimEnabled={isAutoApprovedClaimEnabled}
        totalCreditSelected={totalCreditSelected}
        isPIBL={true}
        prepayments72511={true}
      />
    </Box>
  );

  const body = loadingCreditList ? (
    <LoadingIndicator isLoading={loadingCreditList} size="md" color="hsl(var(--primary))" />
  ) : (
    <div className="text-left">
      <CreditNotesTableHeader isPIBL={true} />
      <div style={{overflowY: 'scroll', overflowX: 'hidden', minHeight: '348px', maxHeight: '400px'}}>
        <CreditNotesTableBody
          showClaimDialog={showClaimDialog}
          claimId={claimId}
          claimCode={claimCode}
          handleClaimDialog={handleClaimDialog}
          handleDeleteClaim={handleDeleteClaim}
          handleAddClaim={handleAddClaim}
          handleEditClaim={handleEditClaim}
          creditList={creditList}
          handleCreditsToApply={handleCreditsToApply}
          selectedCredits={selectedCredits}
          isAutoApprovedClaimEnabled={isAutoApprovedClaimEnabled}
          isSelectedCreditGreaterThanInvoiceAmount={isSelectedCreditGreaterThanInvoiceAmount}
          editClaim={editClaim}
          isPIBL={true}
          prepayments72511={true}
          selectedCreditAndClaimsIds={selectedCreditAndClaimsIds}
        />
      </div>
    </div>
  );

  const footer = (
    <CreditNotesFooter
      isLoading={isLoadingApplyCredit.current}
      applyCredit={applyCredit}
      selectedCredits={selectedCredits}
      isPIBL={true}
      setStep={setStep}
      handleClaimDialog={handleClaimDialog}
      isAutoApprovedClaimEnabled={isAutoApprovedClaimEnabled}
      prepayments72511={true}
    />
  );

  if (widgetScope === PaymentWidgetScope.PSBL && isShowDialog) {
    return (
      <CreditNotesListDialog
        handleDialog={handleDialog}
        applyCredit={applyCredit}
        creditList={creditList}
        creditsToApply={creditsToApply!}
        isAutoApprovedClaimEnabled={isAutoApprovedClaimEnabled}
        getCreditNotesAndClaims={getCreditNotesAndClaims}
        isLoading={isLoadingApplyCredit.current}
        totalInvoiceAmount={totalInvoiceAmount}
        editClaim={editClaim}
        handleClaimDialog={handleClaimDialog}
        handleEditClaim={handleEditClaim}
        isSelectedCreditGreaterThanInvoiceAmount={isSelectedCreditGreaterThanInvoiceAmount}
        handleCreditsToApply={handleCreditsToApply}
        handleAddClaim={handleAddClaim}
        totalCreditSelected={totalCreditSelected}
        handleDeleteClaim={handleDeleteClaim}
        selectedCredits={selectedCredits}
        showClaimDialog={showClaimDialog}
        claimId={claimId}
        claimCode={claimCode}
        selectedCreditAndClaimsIds={selectedCreditAndClaimsIds}
        prepayments72511={true}
        handleSelectAll={handleSelectAll}
        isAllSelected={isAllSelected}
        widgetScope={PaymentWidgetScope.PSBL}
      />
    );
  }

  if (widgetScope === PaymentWidgetScope.PIBL) {
    return <PaymentWidgetStep title={title} body={body} footer={footer} />;
  }

  return <></>;
};
