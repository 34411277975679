import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  Button,
  Card,
  CardBody,
  Carousel,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  IconButton,
  Typography,
} from 'spenda-ui-react';
import IconSearch from '../../assets/svg/IconSearch';
import EditCustomerIcon from '../../assets/svg/EditCustomerIcon';
import ArrowLeftRoundedIcon from '@material-ui/icons/ArrowLeftRounded';
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded';
import {useCustomersAPI} from '../../services/useCustomersAPI';
import {ICustomer} from '../../model/customer/Customer';
import {IPagedActionResults} from '../../model/ActionResults';
import LoadingIndicator from '../ui/LoadingIndicator';
import {CustomerDialog} from './CustomerDialog';
import {CloseCross} from '../../assets/svg/CloseCross';
import {debounce} from 'lodash';
import {useQuoteContext} from '../../context/quote-context/QuoteContext';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';

interface ISelectQuoteCustomerDialog {
  isService?: boolean;
  isEdit?: boolean;
  open: boolean;
  handleCancel: () => void;
  handleConfirm?: () => void;
  setSelectedQuoteCustomer: React.Dispatch<React.SetStateAction<ICustomer | undefined>>;
  selectedQuoteCustomer: ICustomer | undefined;
}

export const SelectQuoteCustomerDialog: React.FunctionComponent<ISelectQuoteCustomerDialog> = ({
  open,
  handleCancel,
  handleConfirm,
  setSelectedQuoteCustomer,
  selectedQuoteCustomer,
  isEdit = false,
  isService = false,
}: ISelectQuoteCustomerDialog) => {
  const [searchText, setSearchText] = useState<string>();
  const {isLoading, list} = useCustomersAPI();
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [openCustomer, setOpenCustomer] = useState<boolean>(false);
  const [, setOpenAccountCustomer] = useState<boolean>(false);
  const [editingCustomer, setEditingCustomer] = useState<ICustomer>();
  const inputRef = useRef<HTMLInputElement>(null);
  const {setQuoteCustomer} = useQuoteContext();

  const {QuoteV289367: QuoteV2} = useFeatureFlags().tenantOwned();

  const handleOnConfirm = () => {
    if (selectedQuoteCustomer?.ClassID || selectedQuoteCustomer?.AccountCustomerClassID || !QuoteV2) handleConfirm?.();
    else {
      setEditingCustomer(selectedQuoteCustomer);
      setOpenCustomer(true);
    }
  };

  const getCustomerList = (query?: string) => {
    list({
      MaxResults: 100,
      ...{isShowAccountCustomersOnly: false},
      ...(query ? {Search: query} : {}),
    }).then((res: IPagedActionResults<ICustomer[]>) => {
      setCustomers(res.Value);
    });
  };

  const debouncedSave = useCallback(
    debounce((newValue: string) => {
      getCustomerList(newValue);
    }, 750),
    [],
  );

  useEffect(() => {
    if (open && !openCustomer) {
      setSearchText('');
      getCustomerList();
    }
  }, [open, openCustomer]);

  const prevArrow = ({loop, handlePrev, firstIndex}: {loop: boolean; handlePrev: () => void; firstIndex: boolean}) => {
    return (
      <button
        data-autoid="btnPrev"
        onClick={handlePrev}
        disabled={!loop && firstIndex ? true : false}
        className="!absolute left-[-45px] top-[50%] z-10 grid h-12 max-h-[55px] w-12 max-w-[55px] -translate-y-2/4 select-none place-items-center transition-all disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
      >
        <ArrowLeftRoundedIcon style={{color: 'inherit', fontSize: '55px'}} />
      </button>
    );
  };

  const nextArrow = ({loop, handleNext, lastIndex}: {loop: boolean; handleNext: () => void; lastIndex: boolean}) => {
    return (
      <button
        data-autoid="btnNext"
        onClick={handleNext}
        disabled={!loop && lastIndex ? true : false}
        className="!absolute right-[-35px] top-[50%] z-10 grid h-12 max-h-[55px] w-12 max-w-[55px] -translate-y-2/4 select-none place-items-center transition-all disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
      >
        <ArrowRightRoundedIcon style={{color: 'inherit', fontSize: '55px'}} />
      </button>
    );
  };

  const onSearchChange: ChangeEventHandler = event => {
    event.preventDefault();
    setSearchText(event.target.value);
    debouncedSave(event.target.value);
  };

  const activeIndex = useMemo(
    () => customers.findIndex(customer => customer.ID === selectedQuoteCustomer?.ID),
    [customers, selectedQuoteCustomer],
  );

  return (
    <>
      <Dialog data-autoid={'dlgSelectQuoteCustomer'} open={open} handler={() => {}} className="!w-full !max-w-[771px]">
        <DialogHeader className="justify-center border-b border-[#ccc] text-center font-light">
          {isService ? 'Select customer for service' : isEdit ? 'Change customer' : 'Select customer for quote'}
        </DialogHeader>
        <DialogBody>
          <div className="mx-8 pt-16">
            <div className="mb-5 flex flex-col items-center justify-center gap-6 pb-10">
              <div className="flex w-[349px] items-center justify-center rounded-md border border-r-0 border-primary">
                <input
                  id="searchText"
                  onChange={onSearchChange}
                  value={searchText}
                  ref={inputRef}
                  type="text"
                  placeholder="Search by name, phone or address"
                  className="w-full rounded-l-md pl-2 font-poppins font-semibold text-black-800"
                  data-autoid="txtSearchCustomer"
                />
                <CloseCross
                  name="clear"
                  className={`${
                    searchText?.length ? 'block w-full' : 'hidden'
                  }  mx-1.5 h-[14px] max-w-[14px] cursor-pointer text-gray-700 hover:text-black-800`}
                  onClick={() => {
                    inputRef.current?.focus();
                    getCustomerList('');
                    setSearchText('');
                  }}
                  data-autoid="btnClear"
                />
                <IconButton
                  className="w-full rounded-l-none rounded-r-md"
                  name="search"
                  onClick={() => getCustomerList(searchText)}
                >
                  <IconSearch fill="currentColor" />
                </IconButton>
              </div>
            </div>
            {isLoading ? (
              <div className={'relative mb-5 h-28 px-8 py-2'}>
                <LoadingIndicator isLoading={isLoading} size="md" color="hsl(var(--primary))" />
              </div>
            ) : customers.length === 0 ? (
              <div className={'relative mb-5 h-28 px-8 py-2'}>
                <Typography className="text-center text-black-800">No result found</Typography>
              </div>
            ) : (
              <div className="relative mb-5">
                <Carousel
                  itemsToShow={4}
                  className={'static max-w-[771px]'}
                  prevArrow={prevArrow}
                  nextArrow={nextArrow}
                  initialIndex={activeIndex > 0 ? Math.floor(activeIndex / 4) : 0}
                >
                  {customers?.map(customerCard => (
                    <div key={customerCard.GUID} className="!h=full !w-full px-1 py-2">
                      <Card
                        className={`flex h-28 w-40 cursor-pointer items-center justify-center shadow-[0_0px_6px_0px_rgba(144,142,142,0.5)] ${
                          customerCard.ID === selectedQuoteCustomer?.ID
                            ? 'border-[1px] border-primary bg-primary/10'
                            : null
                        }`}
                        data-autoid={`customerCard-${customerCard.ID}`}
                        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                          event.stopPropagation();
                          setSelectedQuoteCustomer(customerCard);
                          setQuoteCustomer(customerCard);
                        }}
                      >
                        <CardBody className="flex w-full flex-col items-center justify-center px-2 py-6">
                          <div className="absolute right-2 top-2 flex cursor-pointer justify-end pb-2">
                            <EditCustomerIcon
                              onClick={(events: React.MouseEvent<SVGSVGElement>) => {
                                events.stopPropagation();
                                setOpenCustomer(true);
                                setEditingCustomer(customerCard);
                              }}
                              data-autoid={`btnEditCustomer-${customerCard.ID}`}
                            />
                          </div>
                          <Typography
                            data-autoid={`txtCompanyName-${customerCard.ID}`}
                            className="line-clamp-2 w-full cursor-pointer text-center text-base font-semibold text-primary"
                          >
                            {customerCard.CompanyName}
                          </Typography>
                        </CardBody>
                      </Card>
                    </div>
                  ))}
                </Carousel>
              </div>
            )}
          </div>
        </DialogBody>
        <DialogFooter className="p-2.5">
          <div className="flex w-full items-center justify-between rounded-lg bg-[#f1f1f1] p-2.5">
            <div>
              <Button variant="outlined" onClick={handleCancel} className="bg-white">
                Cancel
              </Button>
            </div>
            <div className="flex gap-3">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setEditingCustomer(undefined);
                  setOpenCustomer(true);
                }}
                className="bg-white "
                data-autoid="btnCreateNewCustomer"
              >
                Create New Customer
              </Button>
              <Button variant="filled" disabled={!selectedQuoteCustomer} color="primary" onClick={handleOnConfirm}>
                Continue
              </Button>
            </div>
          </div>
        </DialogFooter>
      </Dialog>
      {openCustomer && (
        <CustomerDialog
          customerId={editingCustomer?.ID || null}
          open={openCustomer}
          onClose={() => {
            setOpenCustomer(false);
            if (!editingCustomer) {
              setSelectedQuoteCustomer(undefined);
            }
          }}
          setOpenAccountCustomer={setOpenAccountCustomer}
          setCustomer={setSelectedQuoteCustomer}
          isQuoteCustomer
          onCustomerCreate={() => {
            setOpenCustomer(false);
          }}
          onCustomerUpdate={() => {
            setOpenCustomer(false);
          }}
        />
      )}
    </>
  );
};
