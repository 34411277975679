import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from 'react';
import {Tabs, TabsHeader, Tab, TabsBody, TabPanel, useTabs, Button, Typography} from 'spenda-ui-react';
import {camelCase, omit, upperFirst} from 'lodash';
import {useHistory} from 'react-router-dom';
import moment from 'moment';

import QuoteDashboardHeader from './QuoteDashboardHeader';
import QuotesList from './QuotesList';
import {useLoadQuotes} from '../../services/useLoadQuotes';
import useQuotesAPI from '../../services/useQuotesAPI';
import {QuoteStatusLabel, QuoteViewMode} from '../../model/constants/Constants';
import {ICustomer} from '../../model/customer/Customer';
import {IQuotePackageDetails, IQuoteSettings} from '../../model/quotes/quotes';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {Layout} from '../../components/layout/Layout';
import {QuoteDetailsDialog} from '../../components/dialog/QuoteDetailsDialog';
import {SelectQuoteCustomerDialog} from '../../components/dialog/SelectQuoteCustomerDialog';
import {quoteVariantInitialValues} from '../../components/form/QuoteCreateForm';
import {AUTH_SELLING_QUOTES_CREATE} from '../../routes/QuoteManagementRoutes';
import AppContext from '../../context/app/appContext';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';

export const QuotesDashboard = () => {
  const [searchString, setSearchString] = useState<string>();
  const {createQuotePackage, createQuote, getQuotesSettings} = useQuotesAPI();
  const {isQuotesSettingsSaved} = useContext(AppContext);
  const {QuoteV289367} = useFeatureFlags().tenantOwned();
  const history = useHistory();
  const [showCustomerSelectionDialog, setShowCustomerSelectionDialog] = useState(false);
  const [showQuoteDetailsDialog, setShowQuoteDetailsDialog] = useState(false);
  const [selectedQuoteCustomer, setSelectedQuoteCustomer] = useState<ICustomer>();
  const [sorting, setSorting] = useState<string>('ModifiedDate');
  const [quoteSettings, setQuoteSettings] = useState<IQuoteSettings>();
  const [columnOptions, setColumnOptions] = useState<string[]>([
    'Customer ref',
    'Customer',
    'Company',
    'Variants',
    'Created date',
    'Created by',
    'Assigned to',
    'Quote status',
    'Expiry date',
    'Est. cost (inc)',
  ]);
  const [quotePackageDetails, setQuotePackageDetails] = useState<IQuotePackageDetails>({
    validFor: '',
    assignQuoteToUserID: 0,
  });

  const Tabdata = [
    {
      label: QuoteStatusLabel.All,
      value: QuoteViewMode.All,
    },
    {
      label: QuoteStatusLabel.Open,
      value: QuoteViewMode.Draft,
    },
    {
      label: QuoteStatusLabel.Sent,
      value: QuoteViewMode.Sent,
    },
    {
      label: QuoteStatusLabel.Approved,
      value: QuoteViewMode.Approved,
    },
    {
      label: QuoteStatusLabel.ExpiredRejected,
      value: QuoteViewMode.ExpiredRejected,
    },
  ];

  //state for current active tab
  const [currentActive, setCurrentActive] = useState<QuoteViewMode>(QuoteViewMode.All);

  const handleCreateQuote = () => {
    setSelectedQuoteCustomer(undefined);
    setQuotePackageDetails({
      validFor: '',
      assignQuoteToUserID: 0,
      depositAmountType: quoteSettings?.quoteDefaultDepositAmountType,
      depositAmount: parseFloat(quoteSettings?.quoteDefaultDepositAmount || '0.00'),
      expireInDays: quoteSettings?.quoteValidityPeriodDays,
    });
    setShowCustomerSelectionDialog(true);
  };

  useEffect(() => {
    const fetchQuoteSettings = async () => {
      const settings = await getQuotesSettings();
      setQuoteSettings(settings);
    };
    QuoteV289367 && isQuotesSettingsSaved && fetchQuoteSettings();
  }, []);

  const getStatusCount = (viewMode: QuoteViewMode) => {
    switch (viewMode) {
      case QuoteViewMode.All:
        return countAllQuotes;
      case QuoteViewMode.Approved:
        return countApprovedQuotes;
      case QuoteViewMode.Sent:
        return countSentQuotes;
      case QuoteViewMode.Draft:
        return countOpenQuotes;
      case QuoteViewMode.ExpiredRejected:
        return countExpiredOrRejectedQuotes;
    }
  };

  const {
    totalCount,
    items: quotes,
    loading: isLoading,
    setSearchFilter,
    currentPage,
    searchFilter,
    pageSize,
    countAllQuotes,
    countApprovedQuotes,
    countExpiredOrRejectedQuotes,
    countOpenQuotes,
    countSentQuotes,
  } = useLoadQuotes();

  const handleStatusChange = (value: QuoteViewMode) => {
    setSearchFilter({
      ...omit(searchFilter, ['ViewMode']),
      StartRow: 1,
      ViewMode: value,
    });
  };

  useEffect(() => {
    if (typeof searchString != 'undefined') {
      setSearchFilter({
        ...searchFilter,
        SearchString: searchString,
      });
    }
  }, [setSearchString, searchString]);

  useEffect(() => {
    if (typeof sorting != 'undefined') {
      setSearchFilter({
        ...searchFilter,
        SortField: sorting,
        SortOrder: sorting === 'ModifiedDate' || 'expiryDate' ? 'DESC' : 'ASC',
      });
    }
  }, [setSorting, sorting]);

  const handlePageChange = (newPage: number) => {
    setSearchFilter({
      ...searchFilter,
      StartRow: (newPage - 1) * pageSize + 1,
    });
  };

  const handleRowsPerPageChange = (rows: number) => {
    setSearchFilter({
      ...searchFilter,
      MaxResults: rows,
      StartRow: 1,
    });
  };

  const _quote = (
    <Tabs value="All">
      <TabsHeader className="rounded-lg bg-[#FFFFFF] px-0 shadow-sm">
        {Tabdata.map(tab => (
          <Tab key={tab.value} value={tab.value} className="px-[5px]" onClick={() => handleStatusChange(tab.value)}>
            <TabButton data={tab} setCurrentActive={setCurrentActive} count={getStatusCount(tab.value)} />
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody>
        <TabPanel value={currentActive} className="mt-2 rounded-md bg-white px-2.5 py-2.5">
          <QuoteDashboardHeader
            handleCreateQuote={handleCreateQuote}
            setSearchString={setSearchString}
            sorting={sorting}
            setSorting={setSorting}
            columnOptions={columnOptions}
            setColumnOptions={setColumnOptions}
          />
          <QuotesList
            quotes={quotes}
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={pageSize}
            columnOptions={columnOptions}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
        </TabPanel>
      </TabsBody>
    </Tabs>
  );

  return (
    <div className={`bg-[rgba(211,229,239,0.5)]`}>
      <LoadingIndicator isLoading={isLoading} size="md" color="hsl(var(--primary))" />
      <Layout leftPanel={_quote} splitWidthType={4} />
      <SelectQuoteCustomerDialog
        open={showCustomerSelectionDialog}
        handleCancel={() => {
          setShowCustomerSelectionDialog(!showCustomerSelectionDialog);
        }}
        handleConfirm={() => {
          setShowCustomerSelectionDialog(!showCustomerSelectionDialog);
          setShowQuoteDetailsDialog(!showQuoteDetailsDialog);
        }}
        setSelectedQuoteCustomer={setSelectedQuoteCustomer}
        selectedQuoteCustomer={selectedQuoteCustomer}
      />
      {selectedQuoteCustomer && showQuoteDetailsDialog && (
        <QuoteDetailsDialog
          open={showQuoteDetailsDialog}
          handleGoBack={() => {
            setShowQuoteDetailsDialog(!showQuoteDetailsDialog);
            setShowCustomerSelectionDialog(!showCustomerSelectionDialog);
          }}
          quotePackageDetails={quotePackageDetails}
          handleContinueClick={async (expiryDate, assignedToUserID, days, depositAmountType, depositAmount) => {
            setQuotePackageDetails({
              validFor: expiryDate,
              assignQuoteToUserID: assignedToUserID,
              depositAmountType: depositAmountType,
              depositAmount: depositAmount,
              expireInDays: days,
            });
            const quotePackageResponse = await createQuotePackage({
              customerID: selectedQuoteCustomer.ID!,
              expiryDate: days ? undefined : expiryDate,
              expiresInDays: days,
              isActive: true,
              assignedToUserID,
              quoteDate: moment(new Date()).format('YYYY-MM-DD'),
              depositAmountType: depositAmountType || undefined,
              depositAmount: depositAmount || undefined,
            });
            const quotePackageId = quotePackageResponse.quotePackageID;
            const payload = {
              ...quoteVariantInitialValues,
              quotePackageID: quotePackageId!,
              name: 'Quote 1',
            };
            await createQuote(payload);
            history.push(`${AUTH_SELLING_QUOTES_CREATE}${quotePackageId}`);
          }}
        />
      )}
    </div>
  );
};

const TabButton = (props: {
  data: {label: string; value: string};
  setCurrentActive: Dispatch<SetStateAction<string>>;
  count: number;
}) => {
  const {state} = useTabs();
  const {active: currentActive} = state;
  const isActive = props.data.value === currentActive;

  if (isActive) {
    props.setCurrentActive(props.data.value);
  }

  const postFixForDataAutoId = upperFirst(camelCase(props.data.label));

  return (
    <Button
      data-autoid={`btn${postFixForDataAutoId}`}
      fullWidth
      wave
      ripple={false}
      className="h-12"
      variant={isActive ? 'filled' : 'outlined'}
    >
      <div className="flex flex-col items-center">
        <Typography variant="xsmall" className="font-medium">
          {props.data.label}
        </Typography>
        <Typography
          data-autoid={`txt${postFixForDataAutoId}Count`}
          className="font-semibold"
        >{`${props.count} Quotes`}</Typography>
      </div>
    </Button>
  );
};
