import {makeStyles} from '@material-ui/core';
import React, {useState} from 'react';
import {DateRangePicker, DateRangePickerInputControllerShape, FocusedInputShape} from 'react-dates';
import {DateRangeCalendar} from '../../assets/svg/DateRangeCalendar';
import moment from 'moment';

export const useARDatepickerStyle = makeStyles(() => ({
  dateRangeStyles: {
    '& .DateRangePickerInput ': {
      border: '1px solid hsl(var(--primary))',
      borderRadius: '6px',
      '& .DayPicker_focusRegion': {
        position: 'fixed !important',
      },
      '& .DateInput': {
        marginLeft: '4px',
        marginRight: '4px',
        width: '90px',
      },
      '& .DateInput_input': {
        marginBottom: '0px',
        paddingLeft: '0px',
        paddingRight: '0px',
        fontFamily: 'poppins',
        fontSize: '13px',
        fontWeight: 600,
        color: '#999',
        textAlign: 'center',
      },
      '& #your_unique_start_date_id': {
        paddingLeft: '6px',
      },
      '& .DateRangePickerInput_arrow': {
        margin: '0px 2px 0px 2px',
      },
    },
    '& .DateInput_input__focused': {
      borderBottom: '2px solid hsl(var(--primary))',
    },
    '& .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover': {
      background: 'hsl(var(--primary))',
      border: '1px double hsl(var(--primary))',
    },
    '& .CalendarDay__hovered_span:active, .CalendarDay__hovered_span, .CalendarDay__selected_span, .CalendarDay__hovered_span:hover ':
      {
        background: 'hsl(var(--primary) / 0.2)',
        border: '1px double hsl(var(--primary))',
        color: 'hsl(var(--primary))',
      },
    '& .DayPickerKeyboardShortcuts_show__bottomRight::before': {
      borderRight: '33px solid hsl(var(--primary))',
    },
    '& .DayPickerNavigation_button__default': {
      border: '1px solid hsl(var(--primary) / 0.2)',
      backgroundColor: 'hsl(var(--primary) / 0.2)',
      '& .DayPickerNavigation_svg__horizontal': {
        fill: 'hsl(var(--primary))',
      },
    },
    '& .DateRangePickerInput_calendarIcon': {
      margin: 0,
    },
  },
  '&.CalendarDay__selected_span': {
    background: 'red !important',
    color: 'red !important',
    border: '1px solid red !important',
  },
}));

const ARDatepicker = (props: DateRangePickerInputControllerShape) => {
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);

  const classes = useARDatepickerStyle();

  const handleFocusChange = (arg: FocusedInputShape | null) => {
    if (arg) {
      setFocusedInput(arg);
    } else {
      setFocusedInput(null);
    }
  };

  // Function to disable future dates
  const isOutsideRange = (day: moment.Moment) => day.isAfter(moment(), 'day');

  return (
    <div className={classes.dateRangeStyles}>
      <DateRangePicker
        data-autoid="btnDatePicker"
        customInputIcon={<DateRangeCalendar width="16px" height="15px" />}
        disableScroll
        hideKeyboardShortcutsPanel
        startDatePlaceholderText={'dd/mm/yyyy'}
        endDatePlaceholderText={'dd/mm/yyyy'}
        displayFormat="DD/MM/YYYY"
        startDateId="startDateInput"
        endDateId="endDateInput"
        focusedInput={focusedInput}
        onFocusChange={handleFocusChange}
        {...props}
        startDate={props?.startDate || null}
        endDate={props?.endDate || null}
        onDatesChange={props?.onDatesChange || (() => undefined)}
        isOutsideRange={isOutsideRange}
        minimumNights={0}
      />
    </div>
  );
};

export default ARDatepicker;
