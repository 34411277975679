import React, {useContext, useMemo} from 'react';
import {ARTable, IARColumns} from '../../../components/AccountsReceivable/ARTable';
import {Typography} from 'spenda-ui-react';
import {IQuoteLines, IQuotes} from '../../../model/quotes/quotes';
import {PriceFormat} from '../../../utils/formatter';
import AppContext from '../../../context/app/appContext';
import {DISCOUNT_MODE} from '../../../model/constants/Constants';
import PriceCalculator from '../../../utils/priceCalculator';
import {ProfitMarginTooltip, UnitMarginTooltip} from '../../../components/quote-management/ProfitMarginTooltip';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import {useQuoteContext} from '../../../context/quote-context/QuoteContext';

interface IQuoteVariantDetails {
  selectedQuoteDetails: IQuotes | undefined;
  showActionJobs?: boolean;
}

const QuoteVariantDetails = (props: IQuoteVariantDetails) => {
  //context
  const {tenantInfo} = useContext(AppContext);
  const {QuoteV289367} = useFeatureFlags().tenantOwned();
  const {showCustomerDetails} = useQuoteContext();

  const approvedQuoteColumns: IARColumns[] = useMemo(
    () => [
      {
        title: 'Code',
        key: 'code',
        width: '20%',
        align: 'left',
        rowClassName: 'p-2.5',
        rowRenderer: (row: IQuoteLines, rowIndex: number) => {
          return (
            <Typography variant="small" className="text-spenda-primarytext" data-autoid={`lblCode${rowIndex}`}>
              {row?.code}
            </Typography>
          );
        },
      },
      {
        title: 'Product',
        key: 'product',
        width: '43%',
        align: 'left',
        rowClassName: 'p-2.5',
        rowRenderer: (row: IQuoteLines, rowIndex: number) => {
          return (
            <Typography variant="small" className="text-spenda-primarytext" data-autoid={`lblProduct${rowIndex}`}>
              {row?.shortDescription}
            </Typography>
          );
        },
      },
      {
        title: 'Qty',
        key: 'qty',
        width: QuoteV289367 ? '6px' : '12px',
        align: 'center',
        rowClassName: 'p-2.5',
        rowRenderer: (row: IQuoteLines, rowIndex: number) => {
          return (
            <Typography variant="small" className="text-spenda-primarytext" data-autoid={`lblQty${rowIndex}`}>
              {row?.quantity}
            </Typography>
          );
        },
      },
      ...(QuoteV289367
        ? [
            {
              title: 'UoM',
              key: 'UoM',
              width: '6%',
              align: 'center',
              rowClassName: 'p-2.5',
              rowRenderer: (row: IQuoteLines, rowIndex: number) => {
                return (
                  <Typography variant="small" className="text-spenda-primarytext" data-autoid={`lblUoM${rowIndex}`}>
                    {row?.uoM}
                  </Typography>
                );
              },
            },
          ]
        : []),
      {
        title: 'Price',
        key: 'price',
        width: '12%',
        align: 'right',
        rowClassName: 'p-0',
        rowRenderer: (row: IQuoteLines, rowIndex: number) => {
          return (
            <div
              className="flex items-center justify-between border border-transparent p-2.5 hover:border-[#1C78AD]"
              data-autoid={`lblPrice${rowIndex}`}
            >
              <UnitMarginTooltip
                costPrice={row.costPriceEx ?? 0}
                lineMargin={
                  row.costPriceEx && row.costPriceEx > 0 ? Number(row?.sellPriceEx) - row.costPriceEx * row.quantity : 0
                }
                unitMargin={
                  row.costPriceEx && row.costPriceEx > 0 ? Number(row?.sellPriceEx) - (row.costPriceEx || 0) : 0
                }
              />
              <Typography variant="small" className="text-spenda-primarytext">
                {PriceFormat(row?.sellPriceEx, '$', 2)}
              </Typography>
            </div>
          );
        },
      },
      {
        title: 'Line Total',
        key: 'lineTotal',
        width: '13%',
        align: 'right',
        columClassName: 'pr-7',
        rowClassName: 'p-2.5 pr-7',
        rowRenderer: (row: IQuoteLines, rowIndex: number) => {
          return (
            <Typography variant="small" className="text-spenda-primarytext" data-autoid={`lblLineTotal${rowIndex}`}>
              {PriceFormat(row?.lineTotalEx, '$', 2)}
            </Typography>
          );
        },
      },
    ],
    [],
  );

  const lineItems = useMemo(() => {
    return props.selectedQuoteDetails?.lines.filter(
      line =>
        line.inventoryID !== tenantInfo?.TenantUserDetails.DefaultDiscountLineItemInventoryId &&
        line.inventoryID !== tenantInfo?.TenantUserDetails.DefaultShippingLineItemInventoryId,
    ) as IQuoteLines[];
  }, [props?.selectedQuoteDetails?.lines]);

  const unDiscountedTotalInc = useMemo(() => {
    return lineItems.reduce((acc, line) => acc + (line.lineTotalInc || 0), 0);
  }, [lineItems]);

  const subTotal = useMemo(() => {
    return lineItems.reduce((acc, line) => acc + (line.lineTotalEx || 0), 0);
  }, [lineItems]);

  const discountPercentage = useMemo(() => {
    if (props.selectedQuoteDetails?.discountMode === DISCOUNT_MODE.PERCENT) {
      return Number(props.selectedQuoteDetails?.discount) * 100;
    } else if (props.selectedQuoteDetails?.discountMode === DISCOUNT_MODE.DOLLAR) {
      return PriceCalculator.roundNumber((Number(props.selectedQuoteDetails?.discount) / unDiscountedTotalInc!) * 100);
    } else {
      return 0;
    }
  }, [lineItems]);

  const discountAmount = useMemo(() => {
    if (props.selectedQuoteDetails?.discountMode === DISCOUNT_MODE.PERCENT) {
      return PriceCalculator.roundNumber(
        (Number(props.selectedQuoteDetails?.discount) * 100 * unDiscountedTotalInc) / 100,
      );
    } else if (props.selectedQuoteDetails?.discountMode === DISCOUNT_MODE.DOLLAR) {
      return props.selectedQuoteDetails?.discount;
    } else {
      return 0.0;
    }
  }, [lineItems]);

  const totalCostPriceEx = useMemo(() => {
    return lineItems.reduce((acc, line) => acc + (line.costPriceEx || 0) * line.quantity, 0);
  }, [lineItems]);

  const totalSalesPriceEx = useMemo(() => {
    return lineItems.reduce((acc, line) => acc + (line.sellPriceEx || 0) * line.quantity, 0);
  }, [lineItems]);

  const discountEx = useMemo(
    () =>
      props.selectedQuoteDetails?.lines.find(
        line => line.inventoryID === tenantInfo?.TenantUserDetails.DefaultDiscountLineItemInventoryId,
      )?.lineTotalEx ?? 0,
    [props?.selectedQuoteDetails?.lines],
  );

  const CustomTableData = ({
    tableHead,
    tableCell,
    className,
  }: {
    tableHead: string | React.ReactNode;
    tableCell: string | React.ReactNode;
    className?: string;
  }) => {
    return (
      <>
        <td
          width="12%"
          align="right"
          className={`${className} border-y border-[#EAEAEA] bg-spenda-cream p-2.5 text-sm text-spenda-primarytext`}
        >
          {tableHead}
        </td>
        <td
          width="13%"
          align="right"
          className={`${className} border-y border-[#EAEAEA] p-2.5 pr-7 text-sm text-spenda-primarytext`}
        >
          {tableCell}
        </td>
      </>
    );
  };
  
 return (
    <div
    className={`${
      !QuoteV289367 
        ? 'h-[calc(100vh-174px)]' 
        : showCustomerDetails 
        ? 'h-[calc(100vh-440px)] overflow-auto' 
        : 'h-[calc(100vh-370px)] overflow-auto'
    }`}
    >
      <div
        className={` ${QuoteV289367 ? (props.showActionJobs ? 'min-h-[calc(100vh-660px)]' : 'min-h-[calc(100vh-589px)]') : 'h-[calc(100vh-430px)]'} pb-1`}
      >
        <ARTable columns={approvedQuoteColumns} scope="AP" rows={lineItems} isLoading={false} tableClass="!mx-0" />
      </div>
      <table className={`${!QuoteV289367 ? 'mt-9' : 'mt-4'} w-full`}>
        <tbody>
          <tr>
            <td rowSpan={5} width="75%"></td>
            <CustomTableData
              tableHead={
                <div className="flex items-center justify-between">
                  <ProfitMarginTooltip
                    profitMargin={totalSalesPriceEx - totalCostPriceEx + discountEx}
                    profitMarginPercentage={
                      totalSalesPriceEx > 0
                        ? ((totalSalesPriceEx - totalCostPriceEx + discountEx) / totalCostPriceEx) * 100
                        : 0
                    }
                  />

                  <Typography className="bg-[#FAFAFA] text-right" variant="small">
                    Sub Total
                  </Typography>
                </div>
              }
              tableCell={PriceFormat(subTotal, '$', 2)}
            />
          </tr>
          <tr>
            <CustomTableData
              tableHead="Discount (Inc)"
              tableCell={
                <div className="flex items-center justify-end gap-4">
                  <span>{discountPercentage}%</span> <span>{PriceFormat(Number(discountAmount), '$', 2)}</span>
                </div>
              }
            />
          </tr>
          <tr>
            <CustomTableData
              tableHead="Shipping (Inc)"
              tableCell={PriceFormat(Number(props?.selectedQuoteDetails?.shipping) || 0)}
            />
          </tr>
          <tr>
            <CustomTableData tableHead="Tax" tableCell={PriceFormat(props?.selectedQuoteDetails?.totalTax!)} />
          </tr>
          <tr>
            <CustomTableData
              tableHead="Total"
              tableCell={PriceFormat(props?.selectedQuoteDetails?.totalInc!, '$', 2)}
              className="font-semibold"
            />
          </tr>
        </tbody>
      </table>

      {QuoteV289367 && (
        <div className="my-4">
          <table className={`w-full`}>
            <tbody>
              <tr>
                <td rowSpan={5} width="75%"></td>
                <CustomTableData
                  tableHead="Deposit due"
                  tableCell={
                    <div className="flex items-center justify-end gap-4">
                      <span>
                        {PriceFormat(
                          (props?.selectedQuoteDetails?.requiredDepositAmount ??  0),
                          '$',
                          2,
                        )}
                      </span>
                    </div>
                  }
                />
              </tr>
              <tr>
              <CustomTableData
                  tableHead="Amount paid"
                  tableCell={
                    <div className="flex items-center justify-end gap-4">
                      <span>
                        {PriceFormat(
                          (props?.selectedQuoteDetails?.totalInc || 0) - (props?.selectedQuoteDetails?.balance || 0),
                          '$',
                          2,
                        )}
                      </span>
                    </div>
                  }
                />
              </tr>
              <tr>
                <CustomTableData
                  tableHead="Balance"
                  tableCell={
                    <div className="flex items-center justify-end gap-4">
                      <span>{PriceFormat(props?.selectedQuoteDetails?.balance || 0, '$', 2)}</span>
                    </div>
                  }
                />
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default QuoteVariantDetails;
