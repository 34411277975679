import React, {useEffect, useMemo, useState} from 'react';
import {Alert, Button, Input, Typography} from 'spenda-ui-react';
import {ICreateClearingAccountPayload, useOnboarding} from '../../services/useOnboarding';
import {ChartsOfAccountClass, ChartsOfAccountTaxType, ChartsOfAccountType} from '../../model/constants/Constants';
import {IAccounts} from '../../model/accounts/Accounts';
interface ICreateClearingAccountInputComponent {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error: string;
  code: string;
  name: string;
  description: string;
  accountType: ChartsOfAccountType;
  accountTaxType: ChartsOfAccountTaxType;
  accountClass: ChartsOfAccountClass;
  isEnablePayments: boolean;
  parentAccountID: number | undefined;
  isAccountCreated?: boolean;
  handleIsAccountCreated?: (
    newState: Partial<{
      clearingAccount: boolean;
      roundingExpense: boolean;
      roundingIncome: boolean;
    }>,
  ) => void;
  setCreatedAccount?: (account: IAccounts) => void;
}

const CreateAccountInputComponent = (props: ICreateClearingAccountInputComponent) => {
  // Props
  const {
    onChange,
    accountType,
    accountTaxType,
    accountClass,
    code,
    error,
    name,
    isEnablePayments,
    description,
    isAccountCreated,
    handleIsAccountCreated,
    parentAccountID,
    setCreatedAccount,
  } = props;

  // State
  const [showAlert, setShowAlert] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);

  // UseEffect
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [showAlert]);

  // API
  const {createAccount} = useOnboarding();

  // Memo
  const bottomHeight = useMemo(() => document.getElementsByClassName('spenda-alert')?.length * 60, [showAlert]);

  // Functions
  const handleCreateAccount = async () => {
    try {
      setIsSaveLoading(true);

      const payload: ICreateClearingAccountPayload = {
        Object: {
          IsActive: true,
          Code: code ?? '',
          Name: description,
          Description: description,
          Type: accountType,
          TaxType: accountTaxType,
          Class: accountClass,
          IsEnablePayments: isEnablePayments,
          ParentAccountID: parentAccountID,
        },
      };
      const res = await createAccount(payload);
      setCreatedAccount?.(res?.Object);
      setShowAlert(true);
      handleIsAccountCreated?.({[name]: true});
      setIsSaveLoading(false);
    } catch {}
  };

  return (
    <div className="h-full w-full">
      <div className="mt-10 flex flex-col items-center gap-6 rounded-lg bg-primary/15 px-5 py-5 sm:!h-[92px] sm:w-full sm:flex-row sm:justify-between sm:py-0">
        <div className="flex flex-row items-center justify-between gap-2 sm:justify-start sm:gap-5">
          <div className=" flex !h-[52px] !w-[114px] flex-row items-end justify-end bg-white sm:justify-between">
            <Input
              type="string"
              label="Account No"
              value={code ?? ''}
              name={name}
              disabled={isAccountCreated}
              onChange={e => onChange(e)}
              containerProps={{className: '!max-h-[50px] !w-[112px] !min-w-[112px] bg-white '}}
            />
          </div>
          <Typography className="w-[50%] text-center font-semibold sm:w-auto sm:font-medium">{description}</Typography>
        </div>
        <Button
          variant="filled"
          data-autoid={'btnCreateAccount'}
          className="w-full sm:w-auto"
          onClick={() => handleCreateAccount()}
          disabled={isAccountCreated}
          loading={isSaveLoading}
        >
          Create Account
        </Button>
      </div>
      {error && (
        <Typography variant="xsmall" className="text-semibold !mt-1 mb-3 text-sm text-error">
          {error}
        </Typography>
      )}
      {showAlert && isAccountCreated && (
        <Alert
          color="primary"
          open={isAccountCreated}
          style={{bottom: `${bottomHeight}px`}}
          className={`spenda-alert absolute ${bottomHeight ? `!bottom-[${bottomHeight}px]` : 'bottom-24'} left-0 right-0 z-[99999] mx-auto mb-16 flex !h-[40px] !w-[475px] items-center whitespace-nowrap bg-primary-header font-poppins text-sm font-medium text-primary`}
        >
          Successfully created your clearing account, please click ‘Next’ to continue.
        </Alert>
      )}
    </div>
  );
};

export default CreateAccountInputComponent;
