import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Formik, FormikProps} from 'formik';
import * as Yup from 'yup';
import {pick} from 'lodash';
import clsx from 'clsx';

import {AUTH_PURCHASING_PURCHASE_INVOICE_LIST} from '../../routes/PurchasingRoutes';
import {IPurchaseInvoiceDetail, IPurchaseInvoiceDetailLine} from '../../model/purchase-invoice/purchaseInvoice';
import {PurchaseInvoiceStatusTypes} from '../../model/constants/Constants';
import {Layout} from '../../components/layout/Layout';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {AlertDialogSlide} from '../../components/dialog/AlertDialogSlide';
import {FilterMenu, FilterMenuItem} from '../../components/menu/FilterMenu';
import {PurchaseInvoiceLines, PurchaseInvoiceSubTotal} from '../../components/purchase-invoice/PurchaseInvoiceLines';
import {
  CreatePurchaseInvoiceDialog,
  ICreatePurchaseInvoiceValues,
} from '../../components/purchase-invoice/CreatePurchaseInvoice';
import {DestructiveButton, PrimaryButton, SecondaryButton} from '../../components/buttons/DefaultButtons';
import usePurchaseInvoiceAPI from '../../services/usePurchaseInvoiceAPI';
import {Toast} from '../../utils/Toast';

import {ClickAwayListener, Grow, IconButton, Popper, makeStyles, TextField} from '@material-ui/core';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import IconFilter from '../../assets/svg/IconFilter';
import IconSearch from '../../assets/svg/IconSearch';
import {usePopupState, bindMenu, bindTrigger, bindToggle, bindPopper} from 'material-ui-popup-state/hooks';

export enum IPurchaseInvoiceItemsFilter {
  MissingAccount = 'MissingAccount',
  HideCompleted = 'HideCompleted',
  VaryingCost = 'VaryingCost',
}

const usePurchaseInvoiceDetailStyle = makeStyles(() => ({
  iconButton: {
    color: '#1C78AD',
    border: '1px solid #1C78AD',
    borderRadius: '6px',
    padding: '8px',
  },
  iconButtonActive: {
    borderRadius: '0 6px 6px 0',
    backgroundColor: '#1C78AD',
    '& path': {
      fill: '#FFFFFF',
    },
    '&:hover': {
      borderRadius: '0 6px 6px 0',
      backgroundColor: '#1C78AD',
    },
  },
  textfield: {
    fontFamily: 'Poppins',
    '& .MuiOutlinedInput-root': {
      border: '1px solid #1C78AD',
      borderRadius: '6px 0 0 6px',
    },
    '& .MuiInputBase-root': {
      color: '#333333',
      padding: '0 5px',
      height: '100%',
      '&::placeholder': {
        color: '#BBBBBB',
        fontWeight: 600,
      },
    },
  },
}));

const validationSchema = Yup.object({});

export interface ICreatePurchaseInvoice extends Omit<Partial<IPurchaseInvoiceDetail>, 'Lines'> {
  Lines?: Partial<IPurchaseInvoiceDetailLine>[];
}

const newPurchaseInvoice: ICreatePurchaseInvoice = {
  RefNumber: '',
  TransDate: '',
  LineIDs: [],
  DueDate: '',
  Lines: [],
  WarehouseID: null,
  ExpectedTotalInc: '',
  SupplierID: undefined,
  ExpectedTotalTax: '',
};

export const PurchaseInvoiceDetail = () => {
  const history = useHistory();
  const classes = usePurchaseInvoiceDetailStyle();
  const {purchaseInvoiceID} = useParams<{purchaseInvoiceID: string | undefined}>();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState<boolean>(false);
  const [createDialog, setCreateDialog] = useState<boolean>(false);
  const [isSupplierSelect, setIsSupplierSelect] = useState<boolean>(false);
  const [purchaseInvoiceToEdit, setPurchaseInvoiceToEdit] = useState<ICreatePurchaseInvoice>(newPurchaseInvoice);
  const [editMode, setEditMode] = useState<boolean>(true);
  const [searchInput, setSearchInput] = useState<string>('');
  const [filter, setFilter] = useState<IPurchaseInvoiceItemsFilter | ''>('');

  const filterPopupState = usePopupState({variant: 'popover', popupId: 'filterPopupState'});
  const searchInputPopup = usePopupState({variant: 'popover', popupId: 'searchInputPopup'});

  const {
    getPurchaseInvoiceById,
    deletePurchaseInvoiceById,
    updatePurchaseInvoiceStatus,
    updatePurchaseInvoiceById,
    isLoading,
  } = usePurchaseInvoiceAPI();

  useEffect(() => {
    if (purchaseInvoiceID) {
      getPurchaseInvoice(Number(purchaseInvoiceID));
    }
  }, []);

  const getPurchaseInvoice = async (purchaseInvoiceID: number) => {
    const purchaseInvoice = await getPurchaseInvoiceById(purchaseInvoiceID);
    if (!purchaseInvoice.Lines) {
      purchaseInvoice.Lines = [];
    }

    if (purchaseInvoice.Status !== PurchaseInvoiceStatusTypes.Unfinalised) {
      setEditMode(false);
    }
    setPurchaseInvoiceToEdit(purchaseInvoice);
  };

  const handlePurchaseInvoiceEdit = (_isSupplierSelect: boolean = false) => {
    setIsSupplierSelect(_isSupplierSelect);
    setCreateDialog(true);
  };

  const handleCreateDialogClose = (refetch?: boolean) => {
    setCreateDialog(false);
    if (refetch && purchaseInvoiceID) {
      getPurchaseInvoice(Number(purchaseInvoiceID));
    }
  };

  const handleCloseClick = (props: FormikProps<ICreatePurchaseInvoice>) => {
    if (props.dirty) {
      setOpenConfirmationDialog(true);
    } else {
      history.push(AUTH_PURCHASING_PURCHASE_INVOICE_LIST);
    }
  };

  const handleDeletePurchaseInvoice = async () => {
    if (purchaseInvoiceID) {
      await deletePurchaseInvoiceById(purchaseInvoiceID);
      history.push(AUTH_PURCHASING_PURCHASE_INVOICE_LIST);
    }
  };

  const handleFilter = (_filter: IPurchaseInvoiceItemsFilter) => {
    if (filter === _filter) {
      setFilter('');
    } else {
      setFilter(_filter);
    }
  };

  const handleClickAway = () => {
    if (!searchInput) {
      searchInputPopup.close();
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const onSubmit = async (): Promise<void> => {
    if (purchaseInvoiceID) {
      const purchaseInvoice = await getPurchaseInvoiceById(Number(purchaseInvoiceID));

      if (!purchaseInvoice.HasTotalDiscrepancy) {
        return updatePurchaseInvoiceStatus(purchaseInvoiceID, {
          ID: Number(purchaseInvoiceID),
          IsQueue: true,
          Status: PurchaseInvoiceStatusTypes.Finalised,
        }).then(() => {
          Toast.info('Purchase Invoice created');
          history.push(AUTH_PURCHASING_PURCHASE_INVOICE_LIST);
        });
      }
      return Promise.reject();
    }
  };

  const updatePurchaseInvoice = async (values: ICreatePurchaseInvoiceValues): Promise<void> => {
    if (purchaseInvoiceID) {
      let payload: ICreatePurchaseInvoiceValues = pick(values, [
        'RefNumber',
        'TransDate',
        'PriceEntryMode',
        'LineIDs',
        'DueDate',
        'Lines',
        'ExpectedTotalTax',
        'ExpectedTotalInc',
      ]);

      updatePurchaseInvoiceById(purchaseInvoiceID, payload).then(() => {
        Toast.info('Purchase Invoice updated');
        getPurchaseInvoice(Number(purchaseInvoiceID));
      });
    }
  };

  const _piDetail = (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={purchaseInvoiceToEdit}
      onSubmit={onSubmit}
    >
      {props => (
        <div className="flex h-full flex-col">
          <div
            id="purchase-invoice-detail-lines"
            className="w-full flex-1 overflow-y-auto rounded-lg bg-white p-3 shadow-[0_0_6px_0_#D3E5EF]"
          >
            <div
              className="flex flex-row items-center justify-between pb-2"
              style={{borderBottom: '1px solid #D8D8D8'}}
            >
              <IconButton
                disableRipple
                className={classes.iconButton}
                onClick={() => handleCloseClick(props)}
                data-autoid="btnBack"
              >
                <ArrowBackRoundedIcon fontSize="small" />
              </IconButton>
              <span className="ml-2 text-xl font-light text-spenda-primarytext">
                Purchase Invoice - {props.values.SupplierName} {props.values.RefNumber}
              </span>
              <div className="flex-1" />
              <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                  <IconButton
                    disableRipple
                    className={clsx(classes.iconButton, {
                      [classes.iconButtonActive]: searchInputPopup.isOpen,
                    })}
                    data-autoid="btnSearch"
                    {...bindToggle(searchInputPopup)}
                  >
                    <IconSearch />
                  </IconButton>
                  <Popper {...bindPopper(searchInputPopup)} disablePortal={false} placement="left" transition>
                    {({TransitionProps}) => (
                      <Grow {...TransitionProps} timeout={250}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          placeholder="Search..."
                          className={clsx(classes.textfield)}
                          value={searchInput}
                          onChange={handleSearchChange}
                          inputProps={{
                            'data-autoid': 'txtSearch',
                          }}
                        />
                      </Grow>
                    )}
                  </Popper>
                </div>
              </ClickAwayListener>
              <IconButton
                disableRipple
                className={clsx(classes.iconButton, '!ml-2')}
                data-autoid="btnFilter"
                {...bindTrigger(filterPopupState)}
              >
                <IconFilter />
              </IconButton>
              <FilterMenu
                {...bindMenu(filterPopupState)}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'left'}}
              >
                <FilterMenuItem
                  selected={filter === IPurchaseInvoiceItemsFilter.MissingAccount}
                  onClick={() => handleFilter(IPurchaseInvoiceItemsFilter.MissingAccount)}
                  data-autoid={`mnu${IPurchaseInvoiceItemsFilter.MissingAccount}`}
                >
                  {filter === IPurchaseInvoiceItemsFilter.MissingAccount ? (
                    <RadioButtonCheckedIcon fontSize="small" className="mr-1 text-primary" />
                  ) : (
                    <RadioButtonUncheckedIcon fontSize="small" className="mr-1 text-primary" />
                  )}
                  Filter by missing account
                </FilterMenuItem>
                <FilterMenuItem
                  selected={filter === IPurchaseInvoiceItemsFilter.HideCompleted}
                  onClick={() => handleFilter(IPurchaseInvoiceItemsFilter.HideCompleted)}
                  data-autoid={`mnu${IPurchaseInvoiceItemsFilter.HideCompleted}`}
                >
                  {filter === IPurchaseInvoiceItemsFilter.HideCompleted ? (
                    <RadioButtonCheckedIcon fontSize="small" className="mr-1 text-primary" />
                  ) : (
                    <RadioButtonUncheckedIcon fontSize="small" className="mr-1 text-primary" />
                  )}
                  Hide completed lines
                </FilterMenuItem>
                <FilterMenuItem
                  selected={filter === IPurchaseInvoiceItemsFilter.VaryingCost}
                  onClick={() => handleFilter(IPurchaseInvoiceItemsFilter.VaryingCost)}
                  data-autoid={`mnu${IPurchaseInvoiceItemsFilter.VaryingCost}`}
                >
                  {filter === IPurchaseInvoiceItemsFilter.VaryingCost ? (
                    <RadioButtonCheckedIcon fontSize="small" className="mr-1 text-primary" />
                  ) : (
                    <RadioButtonUncheckedIcon fontSize="small" className="mr-1 text-primary" />
                  )}
                  Only show products with varying unit cost
                </FilterMenuItem>
              </FilterMenu>
              {editMode ? (
                <>
                  <SecondaryButton
                    label="Invoice Details"
                    margin="0 0 0 8px"
                    padding="10px !important"
                    onClick={() => handlePurchaseInvoiceEdit()}
                  />{' '}
                </>
              ) : null}
            </div>
            <PurchaseInvoiceLines
              {...props}
              editMode={editMode}
              setEditMode={setEditMode}
              searchInput={searchInput}
              filter={filter}
            />
          </div>
          <div
            id="purchase-invoice-detail-lines"
            className="mt-2 h-[206px] w-full rounded-lg bg-white p-3 shadow-[0_0_6px_0_#D3E5EF]"
          >
            <PurchaseInvoiceSubTotal {...props} editMode={editMode} setEditMode={setEditMode} />
            {editMode ? (
              <div className="fixed px-3" style={{bottom: 10, width: 'calc(100% - 2.5rem)'}}>
                <div className="flex w-full flex-row p-2" style={{backgroundColor: '#ADADAD50', borderRadius: '8px'}}>
                  <DestructiveButton
                    label="Delete"
                    onClick={() => setDeleteConfirmationDialog(true)}
                    margin="0 8px 0 0"
                  />
                  <SecondaryButton label="Cancel" onClick={() => handleCloseClick(props)} />
                  <div className="flex-1" />
                  <PrimaryButton
                    label="Save"
                    isSubmitting={props.isSubmitting}
                    disabled={props.isSubmitting}
                    onClick={() => updatePurchaseInvoice(props.values as ICreatePurchaseInvoiceValues)}
                  />
                  <PrimaryButton
                    margin="0 0 0 8px"
                    label="Process Invoice"
                    isSubmitting={props.isSubmitting}
                    disabled={props.isSubmitting || Boolean(props.values.HasTotalDiscrepancy)}
                    onClick={() => {
                      props.handleSubmit();
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </Formik>
  );

  return (
    <>
      {openConfirmationDialog ? (
        <AlertDialogSlide
          showTitleBottomBorder={true}
          titleColor="black"
          headerFontWeight={300}
          paddingY={60}
          paddingX={85}
          dialogWidth={'451px'}
          fontFamily="Poppins"
          title="Discard Changes"
          footer={
            <div className="flex w-full justify-between rounded bg-[#EFEFEF] px-4 py-2">
              <SecondaryButton label="Cancel" onClick={() => setOpenConfirmationDialog(false)} />
              <DestructiveButton label="Discard" onClick={() => history.push(AUTH_PURCHASING_PURCHASE_INVOICE_LIST)} />
            </div>
          }
        >
          <p className="text-center text-xs">
            You have unsaved changes, Would you like to discard them or return to the previous screen?
          </p>
        </AlertDialogSlide>
      ) : null}
      {deleteConfirmationDialog ? (
        <AlertDialogSlide
          showTitleBottomBorder={true}
          titleColor="black"
          headerFontWeight={300}
          paddingY={60}
          paddingX={85}
          dialogWidth={'451px'}
          fontFamily="Poppins"
          title="Delete Purchase Invoice"
          footer={
            <div className="flex w-full justify-between rounded bg-[#EFEFEF] px-4 py-2">
              <SecondaryButton label="Cancel" onClick={() => setDeleteConfirmationDialog(false)} />
              <DestructiveButton
                label="Delete"
                onClick={() => handleDeletePurchaseInvoice()}
                isSubmitting={isLoading}
                disabled={isLoading}
              />
            </div>
          }
        >
          <p className="text-center text-xs">Are you sure you would like to delete this purchase invoice or go back?</p>
        </AlertDialogSlide>
      ) : null}
      <CreatePurchaseInvoiceDialog
        open={createDialog}
        onClose={handleCreateDialogClose}
        purchaseInvoiceId={purchaseInvoiceID}
        isSupplierSelect={isSupplierSelect}
      />
      <div className="relative h-full overflow-hidden bg-spenda-newbg font-poppins">
        <Layout leftPanel={_piDetail} splitWidthType={4} />
        <LoadingIndicator isLoading={isLoading} size="md" color="hsl(var(--primary))" />
      </div>
    </>
  );
};
