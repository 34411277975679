import React, {useContext, useMemo} from 'react';
import {Badge, IconButton, Typography} from 'spenda-ui-react';
import {VisibleContent} from '../../ui/VisibleContent';
import StartConvo from '../../conversationChatBox/StartConvo';
import {AttachDocs} from '../../../assets/svg/AttachDocs';
import {IconNoteBook} from '../../../assets/svg';
import ARTooltip from '../../data-display/ARTootip';
import {ARInfoYellowIcon} from '../../../assets/svg/ARInfoYellowIcon';
import LoadingIndicator from '../../ui/LoadingIndicator';
import IconDownloadClaim from '../../../assets/svg/IconDownloadClaim';
import {NameInitials, PriceFormat} from '../../../utils/formatter';
import {IClaimRequestDetailsResponse} from '../../../model/claims/ClaimsAndReturns';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import {useTenantInfo} from '../../../hooks/useTenantInfo';
import {ClaimsAndReturnsStatus, ConversationType, StartConvoType} from '../../../model/constants/Constants';
import {useClaimsAndReturnHook, usePSBLHook} from '../../../hooks/useARHook';
import {renderClaimsAndReturnStatus} from '../../../screens/AccountsReceivable/claims-and-returns/ClaimsHighLevel';
import PurchasingContext from '../../../context/purchasing/purchasingContext';
import ClaimsInternalNotesDialog from '../ClaimsInternalNotesDialog';
import EditEvidenceDialog from '../EditEvidenceDialog';
import {isEmpty} from 'lodash';
import {FormikErrors} from 'formik';
import {IClaimRequestLineItemsFormValues} from '../../../screens/AccountsReceivable/claims-and-returns/CreateAndEditClaimRequest';

interface IClaimsWidgetHeaderProps {
  claimDetails: IClaimRequestDetailsResponse;
  supplierId?: number;
  accountCustomerId?: number;
  claimRequestId?: number;
  isShowInternalNotesDialog: boolean;
  iSShowUploadAttachmentModal: boolean;
  errors?: FormikErrors<{lineItems: IClaimRequestLineItemsFormValues[]}>;
  setIsShowInternalNotesDialog(isShowInternalNotesDialog: boolean): void;
  setISShowUploadAttachmentModal(iSShowUploadAttachmentModal: boolean): void;
}

export const ClaimsWidgetHeader = (props: IClaimsWidgetHeaderProps) => {
  // Props
  const {
    claimDetails,
    supplierId,
    accountCustomerId,
    claimRequestId,
    isShowInternalNotesDialog,
    iSShowUploadAttachmentModal,
    errors,
    setIsShowInternalNotesDialog,
    setISShowUploadAttachmentModal,
  } = props;

  // Feature Flags
  const {claimAndReturnsV272602} = useFeatureFlags().tenantOwned();

  // Contexts
  const {conversations} = useContext(PurchasingContext);

  // Hooks
  const {getSelectedSupplier} = usePSBLHook();
  const {isInBuyerContext, isInSupplierContext} = useTenantInfo();
  const {downloadClaimDocument, isDownloadClaimLoading} = useClaimsAndReturnHook();

  // Constants
  const currentSupplier = getSelectedSupplier(supplierId);
  const isShowPendingStatus =
    [ClaimsAndReturnsStatus?.Sent, ClaimsAndReturnsStatus?.Reviewed]?.includes(claimDetails?.status) &&
    claimDetails?.isPendingCustomerAction;
  const attachmentCount = claimDetails?.lines?.reduce((a, c) => a + (c?.claimRequestAttachments?.length || 0), 0) || 0;

  const downloadClaimsClick = () => {
    if (isInBuyerContext && claimRequestId && supplierId) {
      downloadClaimDocument({claimRequestId, linkedSupplierId: supplierId});
    } else if (isInSupplierContext && claimRequestId && accountCustomerId) {
      downloadClaimDocument({claimRequestId, accountCustomerId});
    }
  };

  const accountCustomerNameInitials = useMemo(() => {
    if (isInBuyerContext) {
      return NameInitials(currentSupplier?.SupplierName);
    } else if (isInSupplierContext) {
      return NameInitials(claimDetails?.accountCustomerName || claimDetails?.customerName || '');
    }
  }, [isInBuyerContext, isInSupplierContext]);

  return (
    <div className="flex w-full flex-col items-center justify-between">
      <div className="h-15 flex w-full flex-row items-center justify-between bg-[#FAFAFA] p-2.5">
        <div className="flex flex-row gap-2.5">
          <div
            data-autoid={`lblAccountCustomerNameInitials`}
            className="flex h-10 w-10 items-center justify-center rounded-full bg-[#D4E8E0] font-poppins text-base font-normal leading-normal text-[#3D9F78]"
          >
            {accountCustomerNameInitials}
          </div>
          <div className="flex flex-col">
            <div className="flex flex-row items-center gap-2">
              <Typography
                data-autoid={`lblAccountCustomerName`}
                className="font-medium leading-normal text-black-800"
                variant="paragraph"
              >
                {isInBuyerContext
                  ? currentSupplier?.SupplierName
                  : claimDetails?.accountCustomerName || claimDetails?.customerName}
              </Typography>
              <VisibleContent keyPath="accountsReceivable.claimRequests.aRClaimsWidget.startConvo">
                <div className="h-[16px]" data-autoid={`btnConversationModule`}>
                  {claimRequestId && claimDetails && claimAndReturnsV272602 && (
                    <StartConvo
                      operationUniqueId={claimDetails?.claimGUID}
                      conversationWith={
                        isInBuyerContext ? currentSupplier?.SupplierName || '' : claimDetails?.accountCustomerName || ''
                      }
                      refNumber={claimDetails?.refNumber}
                      customerID={isInBuyerContext ? undefined : claimDetails?.accountCustomerID}
                      supplierID={isInBuyerContext ? supplierId : undefined}
                      datTypeID={claimDetails?.datTypeID}
                      guid={claimDetails?.claimGUID}
                      contextConvos={conversations}
                      startConvoType={StartConvoType.ICON}
                      convoType={ConversationType.DOCUMENT}
                      unreadMessages={claimDetails?.unreadMessages}
                      conversationId={claimDetails?.conversationID}
                    />
                  )}
                </div>
              </VisibleContent>
            </div>
            <Typography
              data-autoid={`lblCompanyPhoneNumber&VendorName`}
              className="font-normal leading-normal text-black-800"
              variant="xsmall"
            >
              {claimDetails?.companyPhoneNumber}{' '}
              {claimDetails?.vendorName ? `Vendor - ${claimDetails?.vendorName}` : ''}
            </Typography>
          </div>
        </div>
        <div className="mr-1 flex flex-row justify-between gap-3">
          <div className="flex flex-row gap-2.5">
            <div className="flex flex-col items-end">
              <Typography className="text-center font-normal leading-normal text-black-800" variant="xsmall">
                Code
              </Typography>
              <Typography
                data-autoid={`lblClaimCode`}
                className="text-center indent-10 font-medium leading-normal text-black-800"
                variant="paragraph"
              >
                {claimDetails?.claimCode}
              </Typography>
            </div>
            <VisibleContent keyPath="accountsReceivable.claimRequests.aRClaimsWidget.attachment">
              {claimRequestId && claimAndReturnsV272602 && (
                <Badge
                  content={attachmentCount}
                  className="h-[22px] w-[20px] font-poppins"
                  invisible={attachmentCount === 0}
                  color="error"
                >
                  <IconButton
                    variant="outlined"
                    data-autoid="btnAttachDocs"
                    onClick={() => setISShowUploadAttachmentModal(true)}
                  >
                    <AttachDocs className="-rotate-45 cursor-pointer" width="22px" height="auto" />
                  </IconButton>
                </Badge>
              )}
            </VisibleContent>
            {claimAndReturnsV272602 && (
              <IconButton
                variant="outlined"
                data-autoid="btnAddInternalNotes"
                onClick={() => setIsShowInternalNotesDialog(true)}
              >
                <IconNoteBook />
              </IconButton>
            )}
          </div>
        </div>
      </div>
      <div className="w-full bg-white px-2.5">
        <div className="h-18 flex w-full flex-col justify-between py-2.5">
          <div className="flex w-full flex-row justify-between">
            <div className="flex flex-row items-center gap-2">
              <Typography
                data-autoid={`lblClaimedItems`}
                className=" font-medium leading-normal text-black-800"
                variant="paragraph"
              >
                Claimed items({claimDetails?.noItems})
              </Typography>
              {claimAndReturnsV272602 && (claimDetails?.isDisputed || claimDetails?.claimRequestRefIDIsDisputed) && (
                <ARTooltip
                  title={
                    <Typography
                      className="whitespace-nowrap text-center font-medium text-spenda-primarytext"
                      variant="small"
                    >
                      {claimDetails?.isDisputed
                        ? 'Disputed'
                        : claimDetails?.claimRequestRefIDIsDisputed
                          ? 'Previously Disputed'
                          : ''}
                    </Typography>
                  }
                >
                  <div className="cursor-pointer" data-autoid={`iconInfoClaimsWidget`}>
                    <ARInfoYellowIcon />
                  </div>
                </ARTooltip>
              )}
              {claimAndReturnsV272602 &&
                (isDownloadClaimLoading ? (
                  <div className="relative pr-5">
                    <LoadingIndicator size="sm" isLoading={isDownloadClaimLoading} />
                  </div>
                ) : (
                  <ARTooltip
                    title={
                      <p className="whitespace-nowrap text-center font-poppins text-[12px] font-medium text-spenda-primarytext">
                        Download Claim
                      </p>
                    }
                  >
                    <button
                      type="button"
                      data-autoid="btnDownloadClaim"
                      className={'relative flex items-center justify-center'}
                      onClick={downloadClaimsClick}
                    >
                      <IconDownloadClaim />
                    </button>
                  </ARTooltip>
                ))}
            </div>
            <Typography
              data-autoid={`lblTotalInc`}
              className="text-right font-medium leading-normal text-[#3C9F78]"
              variant="paragraph"
            >
              {PriceFormat(claimDetails?.totalInc)}
            </Typography>
          </div>
          <div className="flex w-full flex-row items-center justify-between">
            <div className="">
              <div className="text-xs font-medium leading-normal text-[#BFBFBF]">
                Action:{' '}
                <Typography className="inline text-[#3C9F78]" variant="xsmall" data-autoid={`lblAction`}>
                  {claimDetails?.action}
                </Typography>
              </div>
              <div className="text-xs font-medium leading-normal text-[#BFBFBF]">
                Reason:{' '}
                <Typography className="inline text-[#3C9F78]" variant="xsmall" data-autoid={`lblReason`}>
                  {claimDetails?.reason}
                </Typography>
              </div>
            </div>
            <div
              data-autoid={`lblStatus`}
              className="flex h-6 items-center justify-center rounded-[4px] font-poppins text-xs font-medium leading-normal"
            >
              {isShowPendingStatus
                ? renderClaimsAndReturnStatus('Pending')
                : renderClaimsAndReturnStatus(claimDetails?.status)}
            </div>
          </div>
          {!isEmpty(errors) && isInBuyerContext && (
            <Typography
              data-autoid="txtErrorMessage"
              className="mb-2 font-medium leading-normal text-spenda-error"
              variant="paragraph"
            >
              Please check the line items
            </Typography>
          )}
        </div>
      </div>
      {isShowInternalNotesDialog && (
        <ClaimsInternalNotesDialog
          handleClose={() => setIsShowInternalNotesDialog(false)}
          claimRequestID={claimDetails.claimRequestID}
          linkedSupplierId={isInBuyerContext ? supplierId : undefined}
          accountCustomerId={isInSupplierContext ? accountCustomerId : claimDetails?.accountCustomerID ?? undefined}
        />
      )}
      {iSShowUploadAttachmentModal && (
        <EditEvidenceDialog
          isViewOnly
          showModal={iSShowUploadAttachmentModal}
          handleClose={() => setISShowUploadAttachmentModal(false)}
          claimRequestDetails={claimDetails}
          linkedSupplierId={isInBuyerContext ? supplierId : undefined}
          accountCustomerId={isInSupplierContext ? accountCustomerId : claimDetails?.accountCustomerID ?? undefined}
        />
      )}
    </div>
  );
};
