import React, {FunctionComponent} from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import {Button, ButtonProps, createTheme} from '@material-ui/core';
import {css} from 'glamor';
import LoadingIndicator from '../ui/LoadingIndicator';
import {createAutoIdAttribute} from '../../utils/createAutoIdAttribute';

export type color =
  | 'blue'
  | 'red'
  | 'green'
  | 'lightGreen'
  | 'yellow'
  | 'purple'
  | 'darkBlue'
  | 'grey'
  | 'darkGrey'
  | 'white'
  | 'black'
  | 'blueShade'
  | 'lightBlue'
  | 'greyTint'
  | 'transparent'
  | 'lightYellow'
  | 'discardRed'
  | 'disableBlueShade'
  | 'destructiveRed'
  | 'disabledDestructiveRed';
export type lineHeight = 'xs' | 's' | 'm' | 'm-2' | 'l' | 'xl' | 'xxl';
export type fontSize = 'xs' | 's' | 'm' | 'l' | 'xl';
export type width = 'xs' | 's' | 'sm' | 'm' | 'l' | 'xl';

// exclude these properties as they are also part of MatUI button but we don't need
// Spenda use their own version of those properties
type ExcludedButtonProps = Omit<ButtonProps, 'color' | 'onClick'>;

export interface ISButtonProps extends ISButtonPropsOnly, ExcludedButtonProps {}
export interface ISButtonPropsOnly {
  backgroundColor?: string;
  border?: string;
  boxShadow?: string;
  color?: color;
  fontSize?: fontSize | string;
  isSubmitting?: boolean;
  label?: color;
  left?: string;
  lineHeight?: lineHeight | string;
  margin?: string;
  padding?: string;
  position?: 'absolute' | 'relative';
  right?: string;
  textColor?: color | string;
  top?: string;
  width?: width | string;
  borderRadius?: string;
  fontWeight?: number;
  fontFamily?: string;
  height?: string;
  // onClick is also a MaterialUI ButtonProps, but we need `(... | any) => void`, comment out the line and see the errors light up.
  onClick?: (event: React.MouseEvent<HTMLButtonElement> | any) => void;
  loadingIndicatorPosition?: string;
  loaderColor?: string;
}

type ISButtonPropsKeysToDelete = keyof Omit<ISButtonPropsOnly, 'onClick'>;
const propsToDelete: ISButtonPropsKeysToDelete[] = [
  // all these properties should be removed when the props are passed to material UI's Button Component
  // The rule is: All properties from ISButtonProps should be here, excluding  onClick as Material UI's Button need that too.
  'backgroundColor',
  'border',
  'boxShadow',
  'color',
  'fontSize',
  'isSubmitting',
  'label',
  'left',
  'lineHeight',
  'margin',
  'margin',
  'padding',
  'position',
  'right',
  'textColor',
  'top',
  'width',
  'borderRadius',
  'loaderColor',
];

export const SButton: FunctionComponent<ISButtonProps> = props => {
  let fontSize = '1rem'; // Medium
  let color = getButtonColor(props.color!) ?? '#2F97BC'; // Blue
  let label = getLabelColor(props.textColor!) ?? '#ffffff'; // White
  let lineHeight = getLineHeight(props.lineHeight!) ?? '1rem'; // Small default
  let width = 'auto'; // Medium default

  switch (props.color) {
    case 'lightGreen':
      color = '#4DA128';
      break;
    case 'green':
      color = '#3C9F78';
      break;
    case 'red':
      color = '#B9624B';
      break;
    case 'yellow':
      color = '#C68A19';
      break;
    case 'purple':
      color = '#766BCA';
      break;
    case 'darkBlue':
      color = '#0082BA';
      break;
    case 'grey':
      color = '#e1e1e1';
      break;
    case 'white':
      color = '#ffffff';
      break;
    case 'black':
      color = '#333333';
      break;
    case 'blueShade':
      color = '#1C78AD';
      break;
    case 'disableBlueShade':
      color = '#E3ECF1';
      break;
    case 'lightBlue':
      color = '#EFF3F6';
    case 'transparent':
      color = 'rgba(255, 0, 0, 0)';
      break;
    case 'lightYellow':
      color = '#faecd1';
      break;
    case 'destructiveRed':
      color = '#C55D44';
      break;
    case 'disabledDestructiveRed':
      color = 'rgba(197, 93, 68, .5)';
      break;
  }

  switch (props.textColor) {
    case 'green':
      label = '#3C9F78';
      break;
    case 'red':
      label = '#B9624B';
      break;
    case 'yellow':
      label = '#D2A656';
      break;
    case 'purple':
      label = '#766BCA';
      break;
    case 'grey':
      label = '#e1e1e1';
      break;
    case 'darkGrey':
      label = '#999999';
      break;
    case 'blue':
      label = '#2F97BC';
      break;
    case 'white':
      label = '#ffffff';
      break;
    case 'blueShade':
      label = '#1C78AD';
      break;
    case 'greyTint':
      label = '#444343';
      break;
    case 'discardRed':
      label = '#C55D44';
      break;
  }

  switch (props.lineHeight) {
    case 'xs':
      lineHeight = '0.5rem';
      break;
    case 's':
      lineHeight = '1rem';
      break;
    case 'm':
      lineHeight = '1.5rem';
      break;
    case 'm-2':
      lineHeight = '1.35rem';
      break;
    case 'l':
      lineHeight = '2rem';
      break;
    case 'xl':
      lineHeight = '2.5rem';
      break;
    case 'xxl':
      lineHeight = '5rem';
      break;
    default:
      lineHeight = props.lineHeight as string;
      break;
  }

  switch (props.fontSize) {
    case 'xs':
      fontSize = '0.875rem';
      break;
    case 's':
      fontSize = '1rem';
      break;
    case 'm':
      fontSize = '1.125rem';
      break;
    case 'l':
      fontSize = '1.25rem';
      break;
    case 'xl':
      fontSize = '1.375rem';
      break;
    case 'xxl':
      fontSize = '1.5rem';
      break;
    default:
      fontSize = props.fontSize as string;
      break;
  }

  switch (props.width) {
    case 'xs':
      width = '0.5rem';
      break;
    case 's':
      width = '1rem';
      break;
    case 'sm':
      width = '6rem';
      break;
    case 'm':
      width = '6.5rem';
      break;
    case 'l':
      width = '8rem';
      break;
    case 'xl':
      width = '10rem';
      break;
    default:
      width = props.width as string;
      break;
  }
  width = getWidth(props.width!);

  const Reqtheme = createTheme({
    overrides: {
      MuiButton: {
        root: {
          textTransform: 'none',
          fontSize: fontSize,
          margin: props.margin || '0',
          // margin: props.margin || "auto",
          lineHeight: lineHeight,
          width: props.fullWidth ? '100%' : 'auto',
          minWidth: width,
          position: props.position || 'relative',
          top: props.top || 'auto',
          left: props.left || 'auto',
          right: props.right || 'auto',
          borderRadius: props.borderRadius || '6px',
          padding: props.padding || '2px 8px 2px 8px',
          fontWeight: props.fontWeight || 500,
          fontFamily: props.fontFamily || 'inherit',
          height: props.height || 'auto',
        },
        outlined: {
          lineHeight: lineHeight,
          border: props.border || '1px solid #000000',
        },
        label: {
          // color: `${props.label} !important`,
          color: `${label} !important`,
        },
      },
    },
    palette: {
      primary: {
        main: color,
      },
      text: {
        primary: '#ffffff',
      },
    },
    typography: {
      fontFamily: 'Saira Condensed',
    },
  });

  const textCSS = css({
    color: `${props.label} !important`,
  });

  /*
  priority to build a data attribute
  ----------------------------------
  1. when developer pass the data-autoid attribute as part of the HTML element, that takes highest priority.
  2. when the developer does not pass a data-autoid attribute,
      check if the Children passed with the component is text, then use the text but sanitize it.
  3. when the develop does not pass a data-autoid attribute with text,
      check if the Children passed was another component, with this property 'props.children.type.displayName',
      use 'props.children.type.displayName' as the data attribute.
  */

  let dataAttributeValue = (props as any)['data-autoid'];
  const dataAttribute = createAutoIdAttribute(dataAttributeValue);

  try {
    if (!dataAttributeValue) {
      if ((props.children && typeof props.children === 'string') || props.children instanceof String) {
        const properCaseAndNoSpaces = props.children
          .split(' ')
          .map(i => (i[0] && i[0].toUpperCase()) + (i.substring(1) && i.substring(1).toLowerCase()))
          .join('');
        dataAttributeValue = `btn${properCaseAndNoSpaces}`;
      } else if (props.children && (props.children as any)['type']) {
        const childrenType = (props.children as any)['type'];
        if (childrenType.displayName) {
          dataAttributeValue = `btn${childrenType.displayName}`;
        }
      }
    }
  } catch (err) {}

  if (!dataAttributeValue) {
    // the last option will look like this
    //      <SButton data-autoid="btn">
    dataAttribute['data-autoid'] = 'btn';
  } else {
    dataAttribute['data-autoid'] = dataAttributeValue;
  }

  // had a react warning: React does not recognize the `lineHeight` prop on a DOM element.
  // so we need to remove the custom ISButtonProps properties and then pass it to material UI's <Button/>.
  let copyProps = Object.assign({}, props);
  for (const s of propsToDelete) {
    // You can use `let` instead of `const` if you like
    delete copyProps[s];
  }
  //restore the props with only the Material UI's BUtton props.
  const matUIButtonProps = {...(copyProps as ButtonProps)};

  return (
    <ThemeProvider theme={Reqtheme}>
      <Button
        {...dataAttribute}
        {...matUIButtonProps}
        className={`focus:outline-none ${props.className} ${props.textColor ? textCSS : ''}`}
        style={{
          display: props.hidden ? 'none' : 'inside-flex',
          border: props.border ? props.border : '0px',
          boxShadow: 'none',
          background: props.backgroundColor ? props.backgroundColor : 'primary',
          borderRadius: props.borderRadius ? props.borderRadius : '6px',
        }}
        type={props.type || 'button'}
        variant={props.variant || 'contained'}
        color="primary"
        onClick={props.onClick}
        form={props.form}
        disabled={props.disabled}
      >
        {props.children}
        <LoadingIndicator
          isLoading={props.isSubmitting}
          size="sm"
          color={props.loaderColor ?? 'hsl(var(--white))'}
          position={{right: props?.loadingIndicatorPosition ? props.loadingIndicatorPosition : '5px'}}
        />
      </Button>
    </ThemeProvider>
  );
};

const getButtonColor = (color: color) => {
  switch (color) {
    case 'lightGreen':
      return '#4DA128';
    case 'green':
      return '#3C9F78';
    case 'red':
      return '#B9624B';
    case 'yellow':
      return '#C68A19';
    case 'purple':
      return '#766BCA';
    case 'darkBlue':
      return '#0082BA';
    case 'grey':
      return '#e1e1e1';
    case 'white':
      return '#fffff';
    case 'black':
      return '#333333';
    case 'blueShade':
      return '#1C78AD';
    case 'lightBlue':
      return '#EFF3F6';
    case 'transparent':
      return 'rgba(255, 0, 0, 0)';
  }
};

const getLabelColor = (color: color | string) => {
  switch (color) {
    case 'green':
      return '#3C9F78';
    case 'red':
      return '#B9624B';
    case 'yellow':
      return '#D2A656';
    case 'purple':
      return '#766BCA';
    case 'grey':
      return '#e1e1e1';
    case 'blue':
      return '#2F97BC';
    case 'white':
      return '#fffff';
    case 'blueShade':
      return '#1C78AD';
    case 'greyTint':
      return '#444343';
    case 'black':
      return '#333333';
    default:
      return color;
  }
};

const getLineHeight = (lineHeight: lineHeight | string) => {
  switch (lineHeight) {
    case 'xs':
      return '0.5rem';
    case 's':
      return '1rem';
    case 'm':
      return '1.5rem';
    case 'm-2':
      return '1.35rem';
    case 'l':
      return '2rem';
    case 'xl':
      return '2.5rem';
    case 'xxl':
      return '5rem';
    default:
      return lineHeight as unknown as string;
  }
};

const getWidth = (width: width | string) => {
  switch (width) {
    case 'xs':
      return '0.5rem';
    case 's':
      return '1rem';
    case 'm':
      return '6.5rem';
    case 'l':
      return '8rem';
    case 'xl':
      return '10rem';
    default:
      return width as unknown as string;
  }
};
