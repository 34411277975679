import React, {ChangeEvent, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Button, Input, Select, Typography, Option} from 'spenda-ui-react';
import {IAffixes, IPrefixSuffix, useAPConfigs} from '../../../services/useAPConfigs';
import LoadingIndicator from '../../../components/ui/LoadingIndicator';
import {SelectCOADropdown} from '../../../components/AccountsReceivable/required-attention/SelectCOADropdown';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import {
  ChartsOfAccountClass,
  ChartsOfAccountTaxType,
  ChartsOfAccountType,
  DatTypes,
} from '../../../model/constants/Constants';
import {PriceFormat} from '../../../utils/formatter';
import {Toast} from '../../../utils/Toast';
import PSBLOnboardingFooter, {ResponsiveBookOnboardingButton} from '../../../components/buttons/PSBLOnboardingFooter';
import {IntegrationContext} from '../../../context/IntegrationContext';
import CreateAccountInputComponent from '../../../components/lowTouchOnboarding/CreateAccountInput';
import {usePSBLHook} from '../../../hooks/useARHook';
import {IAccounts} from '../../../model/accounts/Accounts';
import useAccountAPI from '../../../services/useAccountAPI';
import {getMissingCode, IParentAccountsID} from '../../../components/lowTouchOnboarding/CreateClearingAccount';

const initialFormValues = {
  affixes: [],
  roundingThreshold: undefined,
  roundingAccountCode: undefined,
};

interface IPreffixSuffixProps {
  onClickCancelBtn?: () => void;
  handleNext?: () => void;
  isOnboarding?: boolean;
}

export const PreffixSuffix = (props: IPreffixSuffixProps) => {
  // Props
  const {onClickCancelBtn, handleNext, isOnboarding} = props;

  // APIs
  const {getPrefixSuffix, savePrefixSuffix, isLoading} = useAPConfigs();
  const {getCreditlineAccounts, parentAccount} = useAccountAPI();

  // States
  const [state, setState] = useState<Partial<IPrefixSuffix>>(initialFormValues);
  const [apiData, setApiData] = useState<IPrefixSuffix>();
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
  const [codes, setCodes] = useState({roundingExpense: '', roundingIncome: ''});
  const [error, setError] = useState({roundingExpense: '', roundingIncome: ''});
  const [accounts, setAccounts] = useState<IAccounts[]>([]);
  const [isAccountCreated, setIsAccountCreated] = useState({roundingExpense: false, roundingIncome: false});
  const [parentAccountsID, setParentAccountsID] = useState<Partial<IParentAccountsID>>({});

  // Feature Flag
  const {psblV22LowTouchOnboarding92923} = useFeatureFlags().supplierDriven();

  // Context
  const {isMYOBAdaptor, isXeroAdaptor, isParodyAdaptor} = useContext(IntegrationContext);

  // Hooks
  const {getSupplierByDefaultMarketplacePath} = usePSBLHook();
  const supplierDetails = getSupplierByDefaultMarketplacePath();

  // Constants
  const roundingExpenseDesc = `${supplierDetails?.SupplierName} - Rounding Expense`;
  const roundingIncomeDesc = `${supplierDetails?.SupplierName} - Rounding Income`;

  useEffect(() => {
    loadPrefixSuffix();
  }, []);

  const loadPrefixSuffix = async () => {
    try {
      const prefixSuffix = await getPrefixSuffix();
      setApiData(prefixSuffix);
      const affixes = Array.isArray(prefixSuffix.affixes) ? prefixSuffix.affixes : [];
      setState({...prefixSuffix, affixes});

      const {roundingAccountCode: rAccCode, roundingRevenueAccountCode: rRevenueAccCode} = prefixSuffix;

      if (psblV22LowTouchOnboarding92923 && isMYOBAdaptor && (!rAccCode || !rRevenueAccCode)) {
        getClearingAccounts(rAccCode, rRevenueAccCode);
        getParentAccounts();
      }
    } catch (error) {
      console.error('Error loading prefix and suffix:', error);
    }
  };

  const getClearingAccounts = async (rAccCode: string, rRevenueAccCode: string) => {
    try {
      await getCreditlineAccounts(isMYOBAdaptor ? {Types: undefined} : undefined).then((data: IAccounts[]) => {
        const c = data.map(account => account.Code);

        if (!rAccCode) {
          let roundingExpense = '';
          const findRoundingExpense = data?.find(a => a?.Name === roundingExpenseDesc);
          if (!findRoundingExpense) {
            roundingExpense = getMissingCode(c, 1100, 1199, '9');
          } else {
            roundingExpense = findRoundingExpense?.Code;
            handleIsAccountCreated({roundingExpense: true});
          }
          setCodes(p => ({...p, roundingExpense}));
          setState(p => ({...p, roundingAccountCode: roundingExpense}));
        } else {
          handleIsAccountCreated({roundingExpense: true});
        }

        if (!rRevenueAccCode) {
          let roundingIncome = '';
          const findRoundingIncome = data?.find(a => a?.Name === roundingIncomeDesc);
          if (!findRoundingIncome) {
            roundingIncome = getMissingCode(c, 1100, 1199, '8');
          } else {
            roundingIncome = findRoundingIncome?.Code;
            handleIsAccountCreated({roundingIncome: true});
          }
          setCodes(p => ({...p, roundingIncome}));
          setState(p => ({...p, roundingRevenueAccountCode: roundingIncome}));
        } else {
          handleIsAccountCreated({roundingIncome: true});
        }

        setAccounts(data);
      });
    } catch (errors) {
      console.warn(errors);
    }
  };

  const getParentAccounts = async () => {
    try {
      await parentAccount().then(data => {
        {
          const roundingExpense = data.Value.find(item => item.Name === 'Other Expenses');
          const roundingIncome = data.Value.find(item => item.Name === 'Other Income');
          if (roundingExpense) {
            handleParentAccountCreated({roundingExpenseID: roundingExpense.ID});
          }
          if (roundingIncome) {
            handleParentAccountCreated({roundingIncomeID: roundingIncome.ID});
          }
        }
      });
    } catch (errors) {
      console.warn(errors);
    }
  };

  const handleIsAccountCreated = (newState: Partial<typeof isAccountCreated>) =>
    setIsAccountCreated(prevState => ({
      ...prevState,
      ...newState,
    }));

  const handleParentAccountCreated = (newState: Partial<typeof parentAccountsID>) =>
    setParentAccountsID(prevState => ({
      ...prevState,
      ...newState,
    }));

  const onChangeAccountCode = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, keyToBeUpdateInState: string) => {
      const {value, name} = e.target;
      if (!value) {
        setError(p => ({...p, [name]: 'Please enter a code.'}));
      } else if (accounts?.find(c => c?.Code === value)) {
        setError(p => ({...p, [name]: 'This code already exists. Please enter a new code.'}));
      } else {
        setError(p => ({...p, [name]: ''}));
      }
      setCodes(p => ({...p, [name]: value}));
      setState(p => ({...p, [keyToBeUpdateInState]: value}));
    },
    [accounts],
  );

  const isNextButtonDisable = useMemo(() => {
    if (isXeroAdaptor) {
      return !state?.roundingAccountCode;
    }
    if (isMYOBAdaptor) {
      return Object.values(isAccountCreated).some(val => !val);
    }
    if (isParodyAdaptor) {
      return false;
    }
    return true;
  }, [state?.roundingAccountCode, isAccountCreated, isMYOBAdaptor, isXeroAdaptor, isParodyAdaptor]);

  const validateForm = (): boolean => {
    let isValid = true;
    const affixesArray = state.affixes || [];
    affixesArray.forEach(affix => {
      if (affix.prefix && /\d$/.test(affix.prefix)) {
        Toast.error('The Prefix cannot end in a digit');
        isValid = false;
      }
    });
    return isValid;
  };

  const onSubmit = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      setIsSaveLoading(true);
      let payload = {...state};
      payload.affixes = payload?.affixes?.filter(affix => affix.prefix !== null || affix.suffix !== null);
      await savePrefixSuffix(payload);
      if (isOnboarding) {
        handleNext?.();
      }
    } catch (error) {
      console.error('Error saving:', error);
    } finally {
      setIsSaveLoading(false);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, datTypeID?: number) => {
    const {name, value} = e.target;

    if (datTypeID) {
      setState(prevState => {
        const updatedState = {...prevState};
        updatedState.affixes = updatedState.affixes || [];

        const existingAffixIndex = updatedState.affixes.findIndex(affix => affix.datTypeID === datTypeID);

        if (existingAffixIndex > -1) {
          updatedState.affixes[existingAffixIndex] = {
            ...updatedState.affixes[existingAffixIndex],
            [name as keyof IAffixes]: value,
          };
        } else {
          updatedState.affixes.push({
            prefix: name === 'prefix' ? value : null,
            suffix: name === 'suffix' ? value : null,
            datTypeID,
          });
        }

        return updatedState;
      });
    } else {
      setState(prevState => ({
        ...prevState,
        [name as keyof IPrefixSuffix]: value,
      }));
    }
  };

  const getInputValue = (name: 'prefix' | 'suffix', datTypeID: number): string => {
    const affix = state.affixes?.find(a => a.datTypeID === datTypeID);
    return affix ? affix[name] || '' : '';
  };

  return (
    <div
      className={`${isOnboarding ? 'wrapper' : 'h-[calc(100vh-160px)]'} relative flex h-full w-full flex-col overflow-auto bg-white pb-16`}
    >
      {isLoading && !isOnboarding ? (
        <LoadingIndicator
          isLoading={isLoading}
          position={{
            height: '100% !important',
            display: 'flex',
            position: 'absolute',
            left: '0',
            right: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          size="md"
          color="hsl(var(--primary))"
        />
      ) : (
        <div className="overflow-y-auto">
          <div className={`${isOnboarding && 'sm:mx-10 sm:mt-3'}`}>
            <div
              className={` header flex !min-h-[60px] items-center justify-between ${!isOnboarding && 'border-b border-[#D8D8D8]'} py-2.5`}
            >
              <Typography
                className="font-poppins text-[20px] font-medium text-[#333333] sm:text-xl sm:font-light"
                variant="h2"
              >
                {'Invoice Matching Settings'}
              </Typography>
            </div>
            <div className="mt-6 flex flex-col sm:flex-row sm:gap-32">
              <div className="flex max-w-[409px] flex-col gap-7">
                <div className="flex flex-col gap-3.5">
                  <Typography className="font-poppins font-medium text-[#000000]">
                    {'Invoice and credit note matching settings'}
                  </Typography>
                  <Typography className="flex-start mt-2 flex text-sm font-normal text-[#333333]">
                    If your workshop software adds characters to the supplier’s invoice number when imported to your
                    Financial System, Spenda won’t be able to match it automatically. Input the added characters below
                    and they’ll be ignored during the matching process.
                  </Typography>
                </div>
                <div className="flex items-start justify-start gap-7">
                  <div className="flex w-full flex-col sm:w-fit">
                    <div className="flex flex-col gap-3 sm:flex-row">
                      <Typography className="my-2.5 font-poppins font-medium text-[#000000]">Invoices:</Typography>
                      <Input
                        id="Prefix"
                        label="Prefix"
                        value={getInputValue('prefix', DatTypes?.PurchaseInvoice)}
                        name="prefix"
                        maxLength={13}
                        onChange={e => onChange(e, 72)}
                        data-autoid="txtInvoicePrefix"
                        containerProps={{className: ' sm:ml-7 !max-h-[50px] !w-full sm:!w-[118px] !min-w-[118px] '}}
                      />
                      <Input
                        id="Suffix"
                        label="Suffix"
                        value={getInputValue('suffix', DatTypes?.PurchaseInvoice)}
                        name="suffix"
                        maxLength={13}
                        onChange={e => onChange(e, 72)}
                        data-autoid="txtInvoiceSuffix"
                        containerProps={{className: '!max-h-[50px] sm:!w-[118px] !min-w-[118px]'}}
                      />
                    </div>
                    <div className="my-3 flex flex-col justify-between gap-3 sm:flex-row">
                      <Typography className="my-2.5  gap-7 font-poppins font-medium text-[#000000]">
                        Credit notes:
                      </Typography>
                      <Input
                        id="Prefix"
                        label="Prefix"
                        value={getInputValue('prefix', DatTypes?.DebitNote)}
                        name="prefix"
                        maxLength={13}
                        onChange={e => onChange(e, DatTypes?.DebitNote)}
                        data-autoid="txtCreditNotePrefix"
                        containerProps={{className: '!max-h-[50px] sm:!w-[118px] !min-w-[118px]'}}
                      />
                      <Input
                        id="Suffix"
                        label="Suffix"
                        value={getInputValue('suffix', DatTypes?.DebitNote)}
                        name="suffix"
                        maxLength={13}
                        onChange={e => onChange(e, DatTypes?.DebitNote)}
                        data-autoid="txtCreditNoteSuffix"
                        containerProps={{className: '!max-h-[50px] sm:!w-[118px] !min-w-[118px]'}}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10 flex !max-w-[431px] flex-col gap-2 rounded-[10px] bg-primary-header p-3">
                <Typography className=" font-poppins text-xs font-medium text-[#000000]" variant="h2">
                  Here are some examples:
                </Typography>
                <p className="text-xs font-normal text-[#000000CC]">
                  In this example, Spenda could match an invoice in your Financial System with a reference number of
                  “WMS-12345” to an invoice on your statement with the original Supplier reference of “12345”
                </p>
                <div className="flex w-full justify-center">
                  <div className="flex !max-h-[23px] w-[72px] items-center justify-center rounded-[4px] border border-primary bg-white py-1 text-xs font-normal text-primary">
                    Prefix: <span className="ml-0.5 font-medium">WMS-</span>
                  </div>
                </div>
                <p className="text-xs font-normal text-[#000000CC]">
                  In this example, Spenda could match an invoice in your Financial System with a reference number of
                  “XXX
                  {'{'}12345{'}'}” to an invoice on your statement with the original Supplier reference of “12345”
                </p>
                <div className="flex w-full justify-center gap-3">
                  <div className="flex !max-h-[23px] w-[54px] items-center justify-center rounded-[4px] border border-primary bg-white py-1 text-xs font-normal text-primary">
                    Prefix: <span className="ml-0.5 font-medium">{'*{'}</span>
                  </div>
                  <div className="flex !max-h-[23px] w-[54px] items-center justify-center rounded-[4px] border border-primary bg-white py-1 text-xs font-normal text-primary">
                    Suffix: <span className=" ml-0.5 font-medium">{'}'}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-10 flex flex-col sm:mt-0 sm:flex-row sm:gap-32">
              <div className="flex max-w-[409px] flex-col gap-7">
                <div className="flex flex-col gap-2.5">
                  <Typography className="mt-4 font-poppins font-medium text-[#000000]" variant="paragraph">
                    Rounding Tolerance
                  </Typography>
                  <Typography className="flex-start flex text-sm font-normal text-[#333333]">
                    When matching invoices, Spenda can allow a tolerance based on the amount you set below.
                  </Typography>

                  <div className="mt-3 flex !h-[60px] !w-[330px] flex-col gap-6">
                    <Select
                      key={'roundingThreshold'}
                      name={'roundingThreshold'}
                      value={state?.roundingThreshold?.toString()}
                      color="primary"
                      label="Select rounding threshold"
                      inputProps={{placeholder: 'Select rounding threshold'}}
                      menuProps={{className: '!max-h-[200px]'}}
                      selected={() => (state?.roundingThreshold ? PriceFormat(state?.roundingThreshold) : '')}
                      onChange={value => {
                        const e = {target: {value, name: 'roundingThreshold'}};
                        onChange(e as ChangeEvent<HTMLInputElement>);
                      }}
                    >
                      {Array.from({length: 10}, (_, i) => (i + 1) * 0.01).map(option => (
                        <Option key={option} value={option.toFixed(2)}>
                          ${option.toFixed(2)}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="flex max-h-fit max-w-[431px] flex-col gap-2 rounded-[10px] bg-primary-header p-3 sm:mt-14">
                <Typography className=" font-poppins text-xs font-medium text-[#000000]" variant="h2">
                  Here are some examples:
                </Typography>
                <p className="text-xs font-normal text-[#000000CC]">
                  If this amount is set to $0.05, then Spenda will identify a match between two invoices with values of
                  $100.00 and $100.05, as long as they are from the same Supplier and have a matching reference number.
                </p>
              </div>
            </div>
            <div className="mb-5 mt-6 max-w-[968px]">
              <div className="mb-2 sm:mb-5">
                <Typography className="flex-start flex text-sm font-normal text-[#333333]">
                  How would you like us to post rounding adjustments?
                </Typography>
              </div>
              {isMYOBAdaptor && !apiData?.roundingAccountCode && psblV22LowTouchOnboarding92923 ? (
                <CreateAccountInputComponent
                  onChange={e => onChangeAccountCode(e, 'roundingAccountCode')}
                  name="roundingExpense"
                  error={error?.roundingExpense}
                  code={codes?.roundingExpense}
                  description={roundingExpenseDesc}
                  accountType={ChartsOfAccountType.Expense}
                  accountTaxType={ChartsOfAccountTaxType.BasExcluded}
                  accountClass={ChartsOfAccountClass.OtherExpense}
                  isEnablePayments={false}
                  isAccountCreated={isAccountCreated?.roundingExpense}
                  handleIsAccountCreated={handleIsAccountCreated}
                  parentAccountID={parentAccountsID?.roundingExpenseID}
                />
              ) : (
                <div className="mt-3 flex !h-[60px] !w-[330px] flex-col gap-6">
                  <SelectCOADropdown
                    type={
                      isMYOBAdaptor ? `${ChartsOfAccountType.Expense},${ChartsOfAccountType.DirectCosts}` : undefined
                    }
                    isUseCodeFieldAsValue
                    prefillValue={'860'}
                    key={'roundingAccountCode'}
                    value={state.roundingAccountCode}
                    onChange={value => {
                      const e = {target: {value, name: 'roundingAccountCode'}};
                      onChange(e as ChangeEvent<HTMLInputElement>);
                    }}
                    disabled={apiData?.roundingAccountCode && psblV22LowTouchOnboarding92923 ? true : false}
                    name={'roundingAccountCode'}
                    color="primary"
                    size="lg"
                    variant="outlined"
                    label={isMYOBAdaptor ? 'Select an expenses account' : 'Select an account'}
                    placeholder={isMYOBAdaptor ? 'Select an expenses account' : 'Select an account'}
                  />
                </div>
              )}
              {isMYOBAdaptor &&
                (!apiData?.roundingRevenueAccountCode && psblV22LowTouchOnboarding92923 ? (
                  <CreateAccountInputComponent
                    onChange={e => onChangeAccountCode(e, 'roundingRevenueAccountCode')}
                    name="roundingIncome"
                    error={error?.roundingIncome}
                    code={codes?.roundingIncome}
                    description={roundingIncomeDesc}
                    accountType={ChartsOfAccountType.OtherIncome}
                    accountTaxType={ChartsOfAccountTaxType.BasExcluded}
                    accountClass={ChartsOfAccountClass.OtherIncome}
                    isEnablePayments={false}
                    isAccountCreated={isAccountCreated?.roundingIncome}
                    handleIsAccountCreated={handleIsAccountCreated}
                    parentAccountID={parentAccountsID?.roundingIncomeID}
                  />
                ) : (
                  <div className="mt-3 flex !h-[60px] !w-[330px] flex-col gap-6">
                    <SelectCOADropdown
                      type={`${ChartsOfAccountType.OtherIncome}`}
                      isUseCodeFieldAsValue
                      key={'roundingRevenueAccountCode'}
                      value={state.roundingRevenueAccountCode}
                      onChange={value => {
                        const e = {target: {value, name: 'roundingRevenueAccountCode'}};
                        onChange(e as ChangeEvent<HTMLInputElement>);
                      }}
                      disabled={apiData?.roundingAccountCode && psblV22LowTouchOnboarding92923 ? true : false}
                      name={'roundingRevenueAccountCode'}
                      color="primary"
                      size="lg"
                      variant="outlined"
                      label="Select a income account"
                      placeholder="Select a income account"
                    />
                  </div>
                ))}
            </div>
          </div>
          {isOnboarding ? (
            <div className="my-10 sm:mt-0">
              <ResponsiveBookOnboardingButton />
              <PSBLOnboardingFooter
                isChatWidget
                onPrimaryClick={onSubmit}
                isLoading={isSaveLoading}
                isDisabled={isNextButtonDisable}
              />
            </div>
          ) : (
            <div className="footer absolute bottom-2.5 flex h-[60px] w-full flex-row items-center justify-between rounded-[6px] bg-[#ececec] px-4">
              <Button
                onClick={() => onClickCancelBtn?.()}
                data-autoid={`btnCancel`}
                variant="outlined"
                color="primary"
                className="bg-white"
              >
                Cancel
              </Button>
              <div className="space-x-4">
                <Button
                  type="submit"
                  color="primary"
                  data-autoid="btnSavePrefixSuffix"
                  loading={isSaveLoading}
                  onClick={onSubmit}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
