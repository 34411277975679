import React, {useCallback, useEffect, useState} from 'react';
import {debounce} from 'lodash';
import {Option, SearchInput, Select, Typography} from 'spenda-ui-react';

import {AlertDialogSlideV2} from '../dialog/AlertDialogSlideV2';
import {ARTable} from '../AccountsReceivable/ARTable';
import {useInventoryAPI} from '../../services/useInventoryAPI';
import {ILinkVariantsRequest, Inventory, VariationOptions} from '../../model/inventory/Inventory';
import GreenCheckIcon from '../../assets/svg/accounts-payable/GreenCheckIcon';
import {useFormikContext} from 'formik';
import {AddInventoryValues} from '../form/InventoryForm';
import {LoadingOverlayV1} from '../ui/LoadingOverlayV1';

interface IInventoryItemsDialog {
  handleCancel: () => void;
  variationOptions: VariationOptions[];
  inventoryID: string;
}

const columns = (selectedProductID: string) => [
  {
    title: 'SKU',
    key: 'sku',
    width: '13%',
    align: 'left',
    rowClassName: 'p-2.5',
    rowRenderer: (row: Inventory, index: number) => <Typography data-autoid={`txtSku-${index}`}>{row.SKU}</Typography>,
  },
  {
    title: 'Code',
    key: 'code',
    width: '13%',
    align: 'left',
    rowClassName: 'p-2.5',
    rowRenderer: (row: Inventory, index: number) => (
      <Typography data-autoid={`txtCode-${index}`}>{row.InventoryCode}</Typography>
    ),
  },
  {
    title: 'Product',
    key: 'product',
    width: '13%',
    align: 'left',
    rowClassName: 'p-2.5',
    rowRenderer: (row: Inventory, index: number) => (
      <div className="flex items-center justify-between">
        <Typography data-autoid={`txtProduct-${index}`}>{row.Description}</Typography>
        {selectedProductID === row.ID && <GreenCheckIcon className="w-min-[18px] ml-1" width="18px" height={'18px'} />}
      </div>
    ),
  },
];

export const InventoryItemsDialog: React.FC<IInventoryItemsDialog> = ({
  handleCancel,
  variationOptions,
  inventoryID,
}) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<Inventory[]>([]);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [productID, setProductID] = useState<string | undefined>(undefined);

  const {listInventory, isLoading, linkVariants, getInventoryById} = useInventoryAPI();

  const {setFieldValue} = useFormikContext<AddInventoryValues>();

  const debouncedSearch = useCallback(
    debounce((searchValue: string) => {
      setQuery(searchValue);
    }, 750),
    [],
  );

  const handleSearchChange = (value: string) => {
    setQuery(value);
    debouncedSearch(value);
  };

  const handleRowClick = (row: Inventory) => {
    setProductID(row.ID);
  };
  const handleSaveAndClose = () => {
    if (!productID || !inventoryID) {
      console.error('Product ID is not defined');
      return;
    }
    const payload: ILinkVariantsRequest = {
      VariationOptions: variationOptions,
      ID: productID,
      IsVariantPriceOverride: false,
    };
    linkVariants(inventoryID, payload)
      .then(async () => {
        const latestInventory = await getInventoryById(inventoryID);
        if (latestInventory) {
          setFieldValue('ChildVariants', latestInventory.ChildVariants);
        }
      })
      .catch(error => console.error('Error linking variants:', error))
      .finally(() => !isLoading && handleCancel());
  };

   const emptyDataMessage = () => {
      return (
        <Typography variant="small" className="flex text-base font-semibold text-black-800">
          No results found
        </Typography>
      );
    };

  useEffect(() => {
    listInventory({
      ...(query && {SearchString: query}),
      MaxResults: 1000,
      SortField: 'InventoryCode',
      SortAsc: sortOrder === 'asc' ? true : false,
      ExactMatch: false,
      IsApprovedForPOS: true,
      ViewMode: 'NonVariantSales',
    })
      .then(res => {
        setResults(res?.Value || []);
      })
      .catch(error => console.error('Error fetching inventory:', error));
  }, [query, sortOrder]);

  return (
    <AlertDialogSlideV2
      headingClassess="border-b border-[#EAEAEA] flex items-center justify-center"
      dialogActionsAlignment="justify-between"
      dialogBodyClassess="flex flex-col gap-3 h-[70vh]"
      dialogClassess="!min-w-[948px]"
      data-autoid="dlgInventoryItems"
      actions={[
        {
          label: 'Cancel',
          variant: 'outlined',
          action: handleCancel,
        },
        {
          label: 'Save & Close',
          disabled: !productID || isLoading,
          loading: isLoading,
          action: handleSaveAndClose,
        },
      ]}
      title={'Inventory items'}
    >
      <div className="mx-4 mr-10 flex items-center justify-between">
        <Typography variant="h3" className="text-center text-spenda-primarytext">
          Products ({results.length})
        </Typography>
        <div className="flex items-center gap-2">
          <div className="flex w-[330px] items-center">
            <SearchInput
              expanded
              reverse
              clearIcon
              iconButtonProps={{name: 'Search'}}
              data-autoid="txtSearchInput"
              autoFocus
              placeholder="Search product, code..."
              value={query}
              onChange={e => handleSearchChange(e.target.value)}
              clearIconProps={{
                onClick: () => setQuery(''),
              }}
              className="w-full pr-5 text-black-800 outline-none"
            />
          </div>
          <div className="w-[180px]">
            <Select
              label="Sort by code: A to Z"
              value={sortOrder}
              onChange={value => setSortOrder(value as 'asc' | 'desc')}
            >
              <Option value="asc">Sort by code: A to Z</Option>
              <Option value="desc">Sort by code: Z to A</Option>
            </Select>
          </div>
        </div>
      </div>
      <div className="relative mx-4 mt-4 h-full overflow-y-auto">
        <LoadingOverlayV1 isLoading={isLoading}>
          <ARTable
            isLoading={false}
            rows={results}
            columns={columns(productID!)}
            tableClass="!-mx-2.5 !w-[calc(100%+20px)] text-black"
            isHighlightRowOnHover
            onRowClick={(row: Inventory) => {
              handleRowClick(row);
            }}
            emptyDataMessage={emptyDataMessage}
          />
        </LoadingOverlayV1>
      </div>
    </AlertDialogSlideV2>
  );
};
