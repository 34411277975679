import React, {useState, useEffect, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {Formik, Form} from 'formik';
import {css} from 'glamor';
import {CSVLink} from 'react-csv';
import moment from 'moment';
import {AUTH_BUYING_AP_REPORTS_VIEW_DETAILS} from '../../routes/AccountsPayableRoutes';
import {
  AuthorisationStatus,
  LabelPaymentBatchStatusColors,
  LabelPaymentBatchStatusTypes,
  PaymentBatchCrDrStatusTypes,
  PaymentBatchStatusTypes,
  PaymentBatchStatusValues,
  SyncStatuses,
} from '../../model/constants/Constants';
import {IPaymentBatchDebitLine, IPaymentBatchForApproval} from '../../model/payment-batch/PaymentBatch';
import {Layout} from '../../components/layout/Layout';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {SearchInput} from '../../components/form/InvoiceSearchForm';
import {SButton} from '../../components/buttons/Button';
import {SPagination} from '../../components/tables/SPagination';
import {usePaymentBatchAPI, usePaymentBatchReports} from '../../services/usePaymentBatchAPI';
import {PriceFormat} from '../../utils/formatter';
import {SCheckbox} from './../../components/inputs/SCheckbox';
import IconStatusFilter from '../../assets/svg/IconStatusFilter';
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Popper,
  Grow,
  ClickAwayListener,
  makeStyles,
  Popover,
} from '@material-ui/core';
import downloadCsv from '../../assets/svg/accounts-payable/download-csv.svg';
import {usePopupState, bindToggle, bindPopper, bindPopover, bindTrigger} from 'material-ui-popup-state/hooks';
import searchIcon from '../../assets/svg/accounts-payable/ap-search-icon.svg';
import APTooltip from '../../components/data-display/APTootip';
import refreshDone from '../../assets/svg/accounts-payable/refresh_done.svg';
import refreshError from '../../assets/svg/accounts-payable/refresh_error.svg';
import refreshDisclaimer from '../../assets/svg/accounts-payable/refresh_disclaimer.svg';
import APContext from '../../context/ap-context/APContext';
import _ from 'lodash';
import {IntegrationContext} from '../../context/IntegrationContext';
import STooltip from '../../components/data-display/Tooltip';
import FailedIcon from '../../assets/svg/accounts-payable/failed-Icon.svg';

const listContainer = css({
  boxShadow: '0px 0px 6px 0px #D3E5EF',
  fontFamily: 'poppins',
});

const borderBottom = css({
  borderBottom: '1px solid #D8D8D8',
});

const useReportStyles = makeStyles({
  title: {
    fontWeight: 500,
    fontSize: '1.25em',
    color: '#4D4D4D',
    lineHeight: 1.1,
  },
  batchSelect: {
    width: '185px',
    height: '38px',
    background: '#fff',
    paddingBottom: '0px',
    margin: '0em 0.5em',
    '& .MuiOutlinedInput-root': {
      height: '100%',
      backgroundColor: 'transparent',
      borderRadius: '6px',
      paddingLeft: '10px',
    },
    '& .MuiFormLabel-root': {
      color: '#333',
      fontFamily: 'Poppins, sans-serif !important',
      width: 'auto',
      fontWeight: 500,
      fontSize: '13px',
      top: '-7px',
      left: '-5px',
    },
    '& .MuiInputLabel-shrink': {
      top: '0px',
      left: 0,
      paddingRight: '2px',
      marginLeft: '-4px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#333',
      top: '-2px',
      '& legend': {
        height: '5px',
      },
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#333!important',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#333',
    },
    '& .MuiSelect-select': {
      color: '#333333',
      fontSize: '14px',
      fontWeight: '600',
      fontFamily: 'poppins',
    },
    '& svg': {
      color: '#1c78ad',
    },
  },

  reportsTable: {
    borderRadius: '2px 2px 2px 2px',
    maxHeight: 'calc(100vh - 22em)',
    '& table': {
      borderBottom: '2px solid #F8F8F8',
      '& th': {
        color: '#999999',
        fontWeight: 600,
        fontSize: '14px',
        fontFamily: 'Poppins',
        width: '180px',
      },
    },
  },
});

const headers = [
  {label: 'Batch Name', key: 'name'},
  {label: 'Batch ID', key: 'refNumber'},
  {label: 'No. of Payee', key: 'countCredits'},
  {label: 'Approved By', key: 'approvedByUserName'},
  {label: 'Due Date', key: 'dueDate'},
  {label: 'Transaction Amount', key: 'paymentAmount'},
  {label: 'Payment Status', key: 'mappedStatus'},
];

const PaymentStatusBadge = (props: {
  status: string;
  color: string;
  bgColor: string;
  error?: IPaymentBatchDebitLine;
}) => {
  return (
    <div className="relative flex flex-row items-center justify-center">
      <span
        {...css({color: props.color, backgroundColor: props.bgColor})}
        className={`inline-block min-w-[115px] rounded-md px-3 py-1 text-xs font-medium`}
      >
        {props.status}
      </span>
      {props?.error?.status === PaymentBatchCrDrStatusTypes.Failed && props?.error?.errorCode && (
        <STooltip
          title={`${props?.error?.errorCode} : ${props?.error?.errorMessage !== null && props?.error?.errorMessage}`}
          placement="top-start"
        >
          <span className="absolute right-0 text-xs font-medium ">
            <img
              src={FailedIcon}
              className={`h-[24px] w-[26px] cursor-pointer pl-2`}
              alt="Info"
              data-autoid={`imgFailed`}
            />
          </span>
        </STooltip>
      )}
    </div>
  );
};

const renderPaymentStatus = (
  status: PaymentBatchStatusTypes,
  authorisationStatus?: AuthorisationStatus,
  debits?: IPaymentBatchDebitLine[],
) => {
  const error = debits?.find(debit => debit.errorCode != null);
  const color = LabelPaymentBatchStatusColors[PaymentBatchStatusTypes[status]];
  const bgColor = `${color}20`;
  const batchStatus =
    status === PaymentBatchStatusTypes.Failed && authorisationStatus === AuthorisationStatus.Rejected
      ? PaymentBatchStatusTypes.Cancelled
      : status;
  return (
    <PaymentStatusBadge
      color={color}
      bgColor={bgColor}
      status={LabelPaymentBatchStatusTypes[batchStatus]}
      error={error}
    />
  );
};

type CheckboxInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  [key: string]: string | undefined;
};

export const APReports = () => {
  const classes = useReportStyles();
  const history = useHistory();
  const {defaultSearchStatusFilters, defaultTimelineGroupsFilter, setUserDefaultViewSettings} = useContext(APContext);
  const [selectedStatus, setSelectedStatus] = useState<Set<PaymentBatchStatusTypes>>(
    new Set(defaultSearchStatusFilters?.map(val => PaymentBatchStatusValues[val] as PaymentBatchStatusTypes)),
  );
  const searchInputPopup = usePopupState({variant: 'popover', popupId: 'searchInputPopup'});
  const statusSettingPopupState = usePopupState({variant: 'popover', popupId: 'columnSettingPopupState'});
  const {
    loading,
    totalCount,
    pageSize,
    currentPage,
    items: paymentBatches,
    searchFilter,
    setSearchFilter,
  } = usePaymentBatchReports(
    defaultSearchStatusFilters?.map(val => PaymentBatchStatusValues[val] as PaymentBatchStatusTypes),
  );

  const {isLoading, updateUserRoleDefaultViewSettings} = usePaymentBatchAPI();
  const {isAPFinancialAdaptorSetup, financialAdaptor} = useContext(IntegrationContext);
  const [searchAllStatus, setSearchAllStatus] = useState<boolean>(false);

  const updateUserSettings = async (selStatus: PaymentBatchStatusTypes[]) => {
    if (selStatus) {
      const searchStatusFilter = selStatus?.filter(item => !!item);
      const response = await updateUserRoleDefaultViewSettings({
        defaultSearchStatusFilters: searchStatusFilter.length
          ? (selStatus?.map(val => PaymentBatchStatusValues[val]) as PaymentBatchStatusValues[])
          : [],
        defaultTimelineGroupsFilter: defaultTimelineGroupsFilter,
      });
      setUserDefaultViewSettings(response);
    }
  };

  useEffect(() => {
    if (searchFilter?.Status?.sort().join(',') !== defaultSearchStatusFilters?.join(',')) {
      setSelectedStatus(
        new Set(defaultSearchStatusFilters?.map(val => PaymentBatchStatusValues[val] as PaymentBatchStatusTypes)),
      );
      setSearchFilter({
        ...searchFilter,
        StartRow: 1,
        Status: defaultSearchStatusFilters?.map(val => PaymentBatchStatusValues[val] as PaymentBatchStatusTypes),
      });
    }
  }, [defaultSearchStatusFilters]);

  const onAllStatusClick = (val: boolean) => {
    const selectedStatusSet = new Set(selectedStatus);
    if (statusSettingPopupState.isOpen && val) {
      Object.entries(LabelPaymentBatchStatusTypes).map(([key, _value]: [string, string]) => {
        selectedStatusSet.add(key as PaymentBatchStatusTypes);
      });
      setSelectedStatus(selectedStatusSet);
    } else if (statusSettingPopupState.isOpen && !val) {
      Object.entries(LabelPaymentBatchStatusTypes).map(([key, _value]: [string, string]) => {
        selectedStatusSet.delete(key as PaymentBatchStatusTypes);
      });
      setSelectedStatus(selectedStatusSet);
    }
  };

  useEffect(() => {
    if (
      !statusSettingPopupState.isOpen &&
      searchFilter.Status?.sort().join(',') !== (selectedStatus && Array.from(selectedStatus).sort().join(','))
    ) {
      let selStatus = selectedStatus && Array.from(selectedStatus);
      if (
        selStatus &&
        selStatus.includes(PaymentBatchStatusTypes.DebitPreApproved) &&
        !selStatus.includes(PaymentBatchStatusTypes.DebitSuccessful)
      ) {
        selStatus = [
          ...selStatus,
          PaymentBatchStatusTypes.DebitSuccessful,
          PaymentBatchStatusTypes.ProcessingCredit,
          PaymentBatchStatusTypes.Incomplete,
        ];
      } else if (!selStatus.includes(PaymentBatchStatusTypes.DebitPreApproved)) {
        selStatus = _.remove(selStatus, function (status) {
          return (
            status !== PaymentBatchStatusTypes.DebitSuccessful &&
            status !== PaymentBatchStatusTypes.ProcessingCredit &&
            status !== PaymentBatchStatusTypes.Incomplete
          );
        });
      }
      if (
        selStatus &&
        selStatus.includes(PaymentBatchStatusTypes.InProgress) &&
        !selStatus.includes(PaymentBatchStatusTypes.ProcessingDebit)
      ) {
        selStatus = [...selStatus, PaymentBatchStatusTypes.ProcessingDebit];
      } else if (!selStatus.includes(PaymentBatchStatusTypes.InProgress)) {
        selStatus = _.remove(selStatus, function (status) {
          return status !== PaymentBatchStatusTypes.ProcessingDebit;
        });
      }
      // update user default view settings
      updateUserSettings(selStatus);
    } else if (statusSettingPopupState.isOpen && selectedStatus.size >= 13) {
      setSearchAllStatus(true);
    }
  }, [statusSettingPopupState.isOpen, selectedStatus]);

  const handleStatusCheckbox = (event: React.ChangeEvent<HTMLInputElement>, key: PaymentBatchStatusTypes) => {
    const selectedStatusSet = new Set(selectedStatus);
    if (event.target.checked) {
      selectedStatusSet.add(key);
    } else {
      selectedStatusSet.delete(key);
      setSearchAllStatus(false);
    }
    setSelectedStatus(selectedStatusSet);
  };

  const onSubmitSearch = (values: {searchText: string}): Promise<void> => {
    setSearchFilter({
      ...searchFilter,
      SearchString: values.searchText,
    });
    return Promise.resolve();
  };

  const handleClickAway = (searchText: string) => {
    if (!searchText) {
      searchInputPopup.close();
    }
  };

  const handleRowClick = (id: string) => {
    history.push({
      pathname: AUTH_BUYING_AP_REPORTS_VIEW_DETAILS.replace(/:batchID/g, id),
    });
  };

  const handlePageChange = (newPage: number) => {
    setSearchFilter({
      ...searchFilter,
      StartRow: (newPage - 1) * pageSize + 1,
    });
  };

  const handleRowsPerPageChange = (rows: number) => {
    setSearchFilter({
      ...searchFilter,
      MaxResults: rows,
      StartRow: 1,
    });
  };

  const filterItem = css({color: '#4D4D4D', whiteSpace: 'nowrap'});

  const reportListPanel = (
    <div className={`flex flex-col`}>
      <div className={`${listContainer} invoicesList h-full rounded bg-white p-2`}>
        <div
          className={`flex items-center justify-between pb-2 text-lg font-medium text-spenda-primarytext ${borderBottom}`}
        >
          <h2>All batches ({paymentBatches.length})</h2>
          <div className="flex space-x-2">
            <Formik initialValues={{searchText: '' as string}} onSubmit={onSubmitSearch} validateOnChange={false}>
              {formik => (
                <Form translate="yes" className={`w-auto`}>
                  <ClickAwayListener onClickAway={() => handleClickAway(formik.values.searchText)}>
                    <div>
                      <div className="m-auto" {...bindToggle(searchInputPopup)}>
                        <img src={searchIcon} className="cursor-pointer" data-autoid="imgSearchBatch" />
                      </div>
                      <Popper {...bindPopper(searchInputPopup)} disablePortal={false} placement="left" transition>
                        {({TransitionProps}) => (
                          <Grow {...TransitionProps} timeout={250}>
                            <SearchInput
                              placeholder={'Search'}
                              {...formik}
                              onSubmit={formik.handleSubmit}
                              isAccountsPayable={true}
                            ></SearchInput>
                          </Grow>
                        )}
                      </Popper>
                    </div>
                  </ClickAwayListener>
                </Form>
              )}
            </Formik>
            <div
              {...filterItem}
              {...bindTrigger(statusSettingPopupState)}
              className={
                'flex w-[40px] cursor-pointer items-center rounded-md border !border-spenda-sBlue pl-2 text-xl font-medium'
              }
              data-autoid="ddlStatus"
            >
              <IconStatusFilter data-autoid={`imgStatus`} />
            </div>
            <CSVLink headers={headers} data={paymentBatches} filename={'reports.csv'}>
              <SButton
                {...css({whiteSpace: 'normal'})}
                borderRadius="6px"
                border="1px solid #1C78AD"
                color="transparent"
                textColor="blueShade"
                height="40px"
                lineHeight="l"
                boxShadow="none"
                fontFamily="poppins"
                fontSize="14px"
                data-autoid="btnDownloadBatchFiles"
              >
                <img src={downloadCsv} className="mr-3 cursor-pointer" data-autoid={`imgDownloadBatchFiles`} />
                Download Batch Files
              </SButton>
            </CSVLink>
            <Popover
              {...bindPopover(statusSettingPopupState)}
              anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
              transformOrigin={{vertical: 'top', horizontal: 'right'}}
            >
              <div className="flex flex-col p-3 pr-4">
                <p className={`${classes.title}`}>Status</p>
                <div className="flex flex-col">
                  <SCheckbox
                    key={`columnAll`}
                    name="All"
                    label="All"
                    checked={searchAllStatus}
                    onChange={() => {
                      setSearchAllStatus(!searchAllStatus);
                      onAllStatusClick(!searchAllStatus);
                    }}
                    inputProps={
                      {
                        'data-autoid': `chkReportStatus-columnAll`,
                      } as CheckboxInputProps
                    }
                  />
                  {Object.entries(LabelPaymentBatchStatusTypes).map(([key, value]: [string, string]) => {
                    if (
                      key === 'ProcessingDebit' ||
                      key === 'DebitSuccessful' ||
                      key === 'ProcessingCredit' ||
                      key === 'Incomplete'
                    ) {
                      return;
                    }
                    return (
                      <SCheckbox
                        key={`columns-${key}`}
                        name={key}
                        label={value}
                        checked={selectedStatus && selectedStatus.has(key as PaymentBatchStatusTypes)}
                        onChange={e => handleStatusCheckbox(e, key as PaymentBatchStatusTypes)}
                        inputProps={
                          {
                            'data-autoid': `chkReportStatus-${key}`,
                          } as CheckboxInputProps
                        }
                      />
                    );
                  })}
                </div>
              </div>
            </Popover>
          </div>
        </div>
        <div className={`relative mt-2 flex h-full items-start justify-center bg-white`}>
          <LoadingIndicator isLoading={loading || isLoading} size="md" color="hsl(var(--primary))" />
          <TableContainer className={`${classes.reportsTable} overflow-y-auto`}>
            <Table data-autoid="tblReportsTable" className="tableCellHeight" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Batch name</TableCell>
                  <TableCell align="right">Batch ID</TableCell>
                  <TableCell align="right">No. of payee</TableCell>
                  <TableCell align="right">Due date</TableCell>
                  <TableCell align="center">Approved by</TableCell>
                  <TableCell align="right">Transaction amount</TableCell>
                  <TableCell align="center">Payment status</TableCell>
                  {isAPFinancialAdaptorSetup() ? (
                    <TableCell align="center">{`${
                      financialAdaptor?.Name === 'MyObAccountRightLive' ? 'MyOB' : financialAdaptor?.Name
                    } sync status`}</TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {!paymentBatches.length && !loading ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <p className="font-poppins text-base font-medium text-spenda-primarytext">No Records Found</p>
                    </TableCell>
                  </TableRow>
                ) : null}
                {paymentBatches.map((credit: IPaymentBatchForApproval, index: number) => (
                  <TableRow
                    key={`batch-${index}`}
                    data-autoid={`lnkBatchReportsRow`}
                    className="cursor-pointer"
                    onClick={() => handleRowClick(credit.accountsPayableBatchID)}
                  >
                    <TableCell>
                      <span className="font-poppins text-base font-medium text-spenda-primarytext">{credit.name}</span>
                    </TableCell>
                    <TableCell align="right">
                      <span className="font-poppins text-base font-medium text-spenda-primarytext">
                        {credit.refNumber}
                      </span>
                    </TableCell>
                    <TableCell align="right">
                      <span className="font-poppins text-base font-medium text-spenda-primarytext">
                        {credit.countCredits}
                      </span>
                    </TableCell>
                    <TableCell align="right">
                      <span className="font-poppins text-base font-medium text-spenda-primarytext">
                        {moment.utc(credit.dueDate).local().format('DD MMM YYYY')}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="font-poppins text-base font-medium text-spenda-primarytext">
                        {credit.batchApprovedByUserName || '-'}
                      </span>
                    </TableCell>
                    <TableCell align="right">
                      <span className="font-poppins text-base font-medium text-spenda-primarytext">
                        {PriceFormat(credit.paymentAmount)}
                      </span>
                    </TableCell>
                    <TableCell align="center" width="200px">
                      <span className="font-poppins text-base font-medium text-spenda-primarytext">
                        {renderPaymentStatus(credit.status, credit?.authorisationStatus, credit?.debits)}
                      </span>
                    </TableCell>
                    {isAPFinancialAdaptorSetup() ? (
                      <TableCell align="center" width="200px">
                        {credit.status !== PaymentBatchStatusTypes.Open &&
                          credit.status !== PaymentBatchStatusTypes.Cancelled &&
                          credit.status !== PaymentBatchStatusTypes.Failed &&
                          !credit.abaFileID && (
                            <span className="font-poppins text-base font-medium text-spenda-primarytext">
                              {credit?.syncLogs &&
                              credit?.syncLogs?.length &&
                              credit?.syncLogs[0]?.status === SyncStatuses.Synced ? (
                                <APTooltip
                                  arrow
                                  placement="bottom-end"
                                  title={
                                    <React.Fragment>
                                      <p className="w-[150px] text-center font-poppins font-medium text-black-800">
                                        Batch exported to{' '}
                                        {`${
                                          financialAdaptor?.Name === 'MyObAccountRightLive'
                                            ? 'MyOB'
                                            : financialAdaptor?.Name
                                        }`}
                                      </p>
                                    </React.Fragment>
                                  }
                                >
                                  <img
                                    className="ml-16 cursor-pointer"
                                    src={refreshDone}
                                    data-autoid={`imgRefresh-${credit.refNumber}`}
                                  />
                                </APTooltip>
                              ) : credit?.syncLogs &&
                                credit?.syncLogs?.length &&
                                (credit?.syncLogs[0]?.status === SyncStatuses.Error ||
                                  credit?.syncLogs[0]?.status === SyncStatuses.Invalid) ? (
                                <APTooltip
                                  arrow
                                  placement="bottom-end"
                                  title={
                                    <React.Fragment>
                                      <p className="w-[150px] text-center font-poppins font-medium text-black-800">
                                        {credit?.syncLogs[0]?.details !== null
                                          ? `${credit?.syncLogs[0]?.details}. Wait for next schedule to run`
                                          : 'Export batch failed,Wait for next schedule to run'}
                                      </p>
                                    </React.Fragment>
                                  }
                                >
                                  <img
                                    className="ml-16 cursor-pointer"
                                    src={refreshError}
                                    data-autoid={`imgRefreshError-${credit.refNumber}`}
                                  />
                                </APTooltip>
                              ) : (
                                <APTooltip
                                  arrow
                                  placement="bottom-end"
                                  title={
                                    <React.Fragment>
                                      <p className="text-center font-poppins font-medium text-black-800">
                                        Sync in progress
                                      </p>
                                    </React.Fragment>
                                  }
                                >
                                  <img
                                    className="ml-16 cursor-pointer"
                                    src={refreshDisclaimer}
                                    data-autoid={`imgRefreshDisclaimer-${credit.refNumber}`}
                                  />
                                </APTooltip>
                              )}
                            </span>
                          )}
                      </TableCell>
                    ) : null}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <SPagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 15, 25]}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </div>
    </div>
  );

  const upcomingBatches = (
    <div className={`flex flex-col`}>
      <div className={`${listContainer} invoicesList h-full rounded bg-white p-2`}>
        <div
          className={`flex items-center justify-between pb-2 text-lg font-medium text-spenda-primarytext ${borderBottom}`}
        >
          <h2>Upcoming batches ({paymentBatches.length})</h2>
          <div className="flex space-x-2">
            <Formik initialValues={{searchText: '' as string}} onSubmit={onSubmitSearch} validateOnChange={false}>
              {formik => (
                <Form translate="yes" className={`w-auto`}>
                  <ClickAwayListener onClickAway={() => handleClickAway(formik.values.searchText)}>
                    <div>
                      <div className="m-auto" {...bindToggle(searchInputPopup)}>
                        <img src={searchIcon} className="cursor-pointer" data-autoid="imgSearchBatch" />
                      </div>
                      <Popper {...bindPopper(searchInputPopup)} disablePortal={false} placement="left" transition>
                        {({TransitionProps}) => (
                          <Grow {...TransitionProps} timeout={250}>
                            <SearchInput
                              placeholder={'Search'}
                              {...formik}
                              onSubmit={formik.handleSubmit}
                              isAccountsPayable={true}
                            ></SearchInput>
                          </Grow>
                        )}
                      </Popper>
                    </div>
                  </ClickAwayListener>
                </Form>
              )}
            </Formik>
            <div
              {...filterItem}
              {...bindTrigger(statusSettingPopupState)}
              className={
                'flex w-[40px] cursor-pointer items-center rounded-md border !border-spenda-sBlue pl-2 text-xl font-medium'
              }
              data-autoid="ddlStatus"
            >
              <IconStatusFilter data-autoid={`imgStatus`} />
            </div>
            <CSVLink headers={headers} data={paymentBatches} filename={'reports.csv'}>
              <SButton
                {...css({whiteSpace: 'normal'})}
                borderRadius="6px"
                border="1px solid #1C78AD"
                color="transparent"
                textColor="blueShade"
                height="40px"
                lineHeight="l"
                boxShadow="none"
                fontFamily="poppins"
                fontSize="14px"
                data-autoid="btnDownloadBatchFiles"
              >
                <img src={downloadCsv} className="mr-3 cursor-pointer" data-autoid={`imgDownloadBatchFiles`} />
                Download Batch Files
              </SButton>
            </CSVLink>
            <Popover
              {...bindPopover(statusSettingPopupState)}
              anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
              transformOrigin={{vertical: 'top', horizontal: 'right'}}
            >
              <div className="flex flex-col p-3 pr-4">
                <p className={`${classes.title}`}>Status</p>
                <div className="flex flex-col">
                  <SCheckbox
                    key={`columnAll`}
                    name="All"
                    label="All"
                    checked={searchAllStatus}
                    onChange={() => {
                      setSearchAllStatus(!searchAllStatus);
                      onAllStatusClick(!searchAllStatus);
                    }}
                    inputProps={
                      {
                        'data-autoid': `chkReportStatus-columnAll`,
                      } as CheckboxInputProps
                    }
                  />
                  {Object.entries(LabelPaymentBatchStatusTypes).map(([key, value]: [string, string]) => {
                    if (
                      key === 'ProcessingDebit' ||
                      key === 'DebitSuccessful' ||
                      key === 'ProcessingCredit' ||
                      key === 'Incomplete'
                    ) {
                      return;
                    }
                    return (
                      <SCheckbox
                        key={`columns-${key}`}
                        name={key}
                        label={value}
                        checked={selectedStatus && selectedStatus.has(key as PaymentBatchStatusTypes)}
                        onChange={e => handleStatusCheckbox(e, key as PaymentBatchStatusTypes)}
                        inputProps={
                          {
                            'data-autoid': `chkReportStatus-${key}`,
                          } as CheckboxInputProps
                        }
                      />
                    );
                  })}
                </div>
              </div>
            </Popover>
          </div>
        </div>
        <div className={`relative mt-2 flex h-full items-start justify-center bg-white`}>
          <LoadingIndicator isLoading={loading || isLoading} size="md" color="hsl(var(--primary))" />
          <TableContainer className={`${classes.reportsTable} overflow-y-auto`}>
            <Table data-autoid="tblReportsTable" className="tableCellHeight" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Batch name</TableCell>
                  <TableCell align="right">Batch ID</TableCell>
                  <TableCell align="right">No. of payee</TableCell>
                  <TableCell align="right">Due date</TableCell>
                  <TableCell align="center">Approved by</TableCell>
                  <TableCell align="right">Transaction amount</TableCell>
                  <TableCell align="center">Status</TableCell>
                  {isAPFinancialAdaptorSetup() ? (
                    <TableCell align="center">{`${
                      financialAdaptor?.Name === 'MyObAccountRightLive' ? 'MyOB' : financialAdaptor?.Name
                    } sync status`}</TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {!paymentBatches.length && !loading ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <p className="font-poppins text-base font-medium text-spenda-primarytext">No Records Found</p>
                    </TableCell>
                  </TableRow>
                ) : null}
                {paymentBatches.map((credit: IPaymentBatchForApproval, index: number) => (
                  <TableRow
                    key={`batch-${index}`}
                    data-autoid={`lnkBatchReportsRow`}
                    className="cursor-pointer"
                    onClick={() => handleRowClick(credit.accountsPayableBatchID)}
                  >
                    <TableCell>
                      <span className="font-poppins text-sm font-medium text-spenda-primarytext">{credit.name}</span>
                    </TableCell>
                    <TableCell align="right">
                      <span className="font-poppins text-sm font-medium text-spenda-primarytext">
                        {credit.refNumber}
                      </span>
                    </TableCell>
                    <TableCell align="right">
                      <span className="font-poppins text-sm font-medium text-spenda-primarytext">
                        {credit.countCredits}
                      </span>
                    </TableCell>
                    <TableCell align="right">
                      <span className="font-poppins text-sm font-medium text-spenda-primarytext">
                        {moment.utc(credit.dueDate).local().format('DD MMM YYYY')}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      <span className="font-poppins text-sm font-medium text-spenda-primarytext">
                        {credit.batchApprovedByUserName || '-'}
                      </span>
                    </TableCell>
                    <TableCell align="right">
                      <span className="font-poppins text-sm font-medium text-spenda-primarytext">
                        {PriceFormat(credit.paymentAmount)}
                      </span>
                    </TableCell>
                    <TableCell align="center" width="200px">
                      <span className="font-poppins text-sm font-medium text-spenda-primarytext">
                        {renderPaymentStatus(credit.status, credit?.authorisationStatus)}
                      </span>
                    </TableCell>
                    {isAPFinancialAdaptorSetup() ? (
                      <TableCell align="center" width="200px">
                        {credit.status !== PaymentBatchStatusTypes.Open &&
                          credit.status !== PaymentBatchStatusTypes.Cancelled &&
                          credit.status !== PaymentBatchStatusTypes.Failed &&
                          !credit.abaFileID && (
                            <span className="font-poppins text-sm font-medium text-spenda-primarytext">
                              {credit?.syncLogs &&
                              credit?.syncLogs?.length &&
                              credit?.syncLogs[0]?.status === SyncStatuses.Synced ? (
                                <APTooltip
                                  arrow
                                  placement="bottom-end"
                                  title={
                                    <React.Fragment>
                                      <p className="w-[150px] text-center font-poppins font-medium text-black-800">
                                        Batch exported to{' '}
                                        {`${
                                          financialAdaptor?.Name === 'MyObAccountRightLive'
                                            ? 'MyOB'
                                            : financialAdaptor?.Name
                                        }`}
                                      </p>
                                    </React.Fragment>
                                  }
                                >
                                  <img
                                    className="ml-16 cursor-pointer"
                                    src={refreshDone}
                                    data-autoid={`imgRefresh-${credit.refNumber}`}
                                  />
                                </APTooltip>
                              ) : credit?.syncLogs &&
                                credit?.syncLogs?.length &&
                                (credit?.syncLogs[0]?.status === SyncStatuses.Error ||
                                  credit?.syncLogs[0]?.status === SyncStatuses.Invalid) ? (
                                <APTooltip
                                  arrow
                                  placement="bottom-end"
                                  title={
                                    <React.Fragment>
                                      <p className="w-[150px] text-center font-poppins font-medium text-black-800">
                                        {credit?.syncLogs[0]?.details !== null
                                          ? `${credit?.syncLogs[0]?.details}. Wait for next schedule to run`
                                          : 'Export batch failed,Wait for next schedule to run'}
                                      </p>
                                    </React.Fragment>
                                  }
                                >
                                  <img
                                    className="ml-16 cursor-pointer"
                                    src={refreshError}
                                    data-autoid={`imgRefreshError-${credit.refNumber}`}
                                  />
                                </APTooltip>
                              ) : (
                                <APTooltip
                                  arrow
                                  placement="bottom-end"
                                  title={
                                    <React.Fragment>
                                      <p className="text-center font-poppins font-medium text-black-800">
                                        Sync in progress
                                      </p>
                                    </React.Fragment>
                                  }
                                >
                                  <img
                                    className="ml-16 cursor-pointer"
                                    src={refreshDisclaimer}
                                    data-autoid={`imgRefreshDisclaimer-${credit.refNumber}`}
                                  />
                                </APTooltip>
                              )}
                            </span>
                          )}
                      </TableCell>
                    ) : null}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <SPagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 15, 25]}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </div>
    </div>
  );

  const [selectedBucket] = useState('reportListPanel');

  const getCurrentView = () => {
    switch (selectedBucket) {
      case 'reportListPanel':
        return reportListPanel;
      case 'upcomingBatches':
        return upcomingBatches;
      default:
        return null;
    }
  };

  const APReports = (
    <div className={`relative flex h-full flex-col overflow-hidden bg-spenda-newbg`}>
      <div className="w-full overflow-auto !rounded-[10px] bg-white">{getCurrentView()}</div>
    </div>
  );

  return (
    <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
      <Layout leftPanel={APReports} splitWidthType={4} />
    </div>
  );
};
