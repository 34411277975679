import React from 'react';
import {Box, makeStyles} from '@material-ui/core/';
import {ChatWidget} from '../onboarding/ChatWidget';
import LoadingIndicator from '../ui/LoadingIndicator';
import {Button} from 'spenda-ui-react';
interface APFooterProps {
  isAPOnboarding?: boolean;
  isDisabled?: boolean;
  buttonLabel?: string;
  isCancelButton?: boolean;
  handleCancelClick?: () => void;
  onNextClick?: () => void;
  secondaryButtonLabel?: string;
  isLoading?: boolean;
  isAPOnboardingV1?: boolean;
  isT2TSupplierModule?: boolean;
  isInviteUser?: boolean;
  handleInvite?: () => void;
  isSetupComplete?: boolean;
  APFooterClass?: string;
  conditionallyClass?: boolean;
  style?: any;
  cancelButtonType?: 'button' | 'submit' | 'reset' | undefined;
  secondaryButtonDisable?: boolean;
  isPsblOnboarding?: boolean;
  showUnlinkVariantsButton?: boolean;
  handleUnlinkVariants?: () => void;
}

export const APFooter: React.FunctionComponent<APFooterProps> = ({
  onNextClick,
  isDisabled,
  buttonLabel,
  isCancelButton,
  handleCancelClick,
  secondaryButtonLabel,
  isLoading,
  isAPOnboardingV1,
  isT2TSupplierModule,
  isInviteUser,
  handleInvite,
  isSetupComplete,
  APFooterClass,
  conditionallyClass,
  style,
  cancelButtonType,
  secondaryButtonDisable,
  isPsblOnboarding,
  showUnlinkVariantsButton,
  handleUnlinkVariants,
}: APFooterProps) => {
  const useAPFooterStyles = makeStyles({
    apFooterWrapper: {
      position: 'absolute',
      width: '100%',
      left: '0px',
      bottom: isT2TSupplierModule ? ' 80px' : '15px',
    },
    apFooterinner: {
      maxWidth: isAPOnboardingV1 ? ' 540px' : '660px',
      width: '100%',
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: isAPOnboardingV1 ? '#FFFFFF' : 'rgb(173 173 173 / 20%)',
      padding: '10px',
      borderRadius: '8px',
      fontFamily: 'Poppins, sans-serif !important',
    },
  });
  const classes = useAPFooterStyles();

  const handleClick = () => {
    if (onNextClick) {
      onNextClick();
    }
  };

  return (
    <Box className={`${conditionallyClass && APFooterClass} ${classes.apFooterWrapper}`} style={style}>
      <Box
        className={`${isPsblOnboarding ? 'absolute  -bottom-[24px]  left-0 flex h-[60px] w-full flex-row items-center justify-between rounded-[6px] bg-[#EFEFEF] px-2.5' : classes.apFooterinner} ${isT2TSupplierModule ? '!max-w-full' : ''} ${
          isSetupComplete ? '!justify-center' : ''
        }`}
      >
        {!isT2TSupplierModule && !isSetupComplete && (
          <Box className="flex">
            <ChatWidget isAPOnboarding={true} />
            {isInviteUser && (
              <Button variant="outlined" onClick={handleInvite} data-autoid="btnCancel">
                Invite User
              </Button>
            )}
          </Box>
        )}
        <Box className={isT2TSupplierModule ? 'flex w-full items-center justify-between' : ''}>
          <div className="flex gap-2">
            {isCancelButton && (
              <Button
                variant="outlined"
                onClick={isCancelButton ? handleCancelClick : undefined}
                type={cancelButtonType}
                disabled={secondaryButtonDisable}
              >
                {secondaryButtonLabel ? secondaryButtonLabel : 'Cancel'}
              </Button>
            )}
            {showUnlinkVariantsButton && (
              <Button variant="outlined" onClick={handleUnlinkVariants}>
                Unlink Variant
              </Button>
            )}
          </div>
          <Button
            onClick={handleClick}
            type="submit"
            disabled={isDisabled}
            data-autoid={buttonLabel ? `btn${buttonLabel}` : 'btnNext'}
          >
            {buttonLabel ? buttonLabel : 'Next'}
            {isLoading && (
              <LoadingIndicator
                isLoading={isLoading}
                size="sm"
                color={isDisabled ? 'hsl(var(--primary))' : 'hsl(var(--white))'}
                position={{right: '5px'}}
              />
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
