import {RegistrationDetails, IConfirmEmailAddressInfo} from '../model/RegistrationDetails';
import {RegistrationConverter} from '../utils/data-converters/registration-converter';
import {IActionRes, IActionResults, IPagedActionResults} from '../model/ActionResults';
import useHttp from '../hooks/useHttp';
import {IAccounts, IGetCreditLineAccountsQuery, ISelectedAccounts} from '../model/accounts/Accounts';
import {IAccountsFilter} from '../model/search-filters/AccountsFilter';
import {IGetUserTenantsResponse} from '../model/user/User';

const useAccountAPI = () => {
  const {isLoading, GET, POST} = useHttp();

  const register = (rego: RegistrationDetails) => {
    return POST('account/register', RegistrationConverter.CreateRegistrationOut(rego));
  };

  const confirmEmail = (code: string): Promise<IConfirmEmailAddressInfo> => {
    return POST('registration/confirmemailaddress', {
      code: code,
    });
  };

  const resendConfirmationEmail = (emailAddress: string, websiteId: number): Promise<IActionRes> => {
    return POST('account/ResendEmailAddressConfirmation', {
      emailAddress: emailAddress,
      websiteId: websiteId,
    });
  };
  const recoverPassword = (emailAddress: string, websiteId: number): Promise<IActionRes> => {
    return POST('account/confirmResetPassword', {
      emailAddress: emailAddress,
      websiteId: websiteId,
    });
  };
  const changePassword = (newPassword: string, websiteId: number, token: string): Promise<IActionRes> => {
    return POST('account/changePassword', {
      newPassword: newPassword,
      websiteId: websiteId,
      token: token,
    });
  };
  const getAccounts = (): Promise<IAccounts[]> => {
    return GET(
      `accounts?IsExactMatch=false&Types=Bank&Types=Current_Asset&Types=Current_Liability&SortAsc=true&MaxResults=1000`,
    ).then((data: IActionResults<IAccounts[]>) => data.Value);
  };

  const getCreditlineAccounts = (queryParams?: IGetCreditLineAccountsQuery): Promise<IAccounts[]> => {
    const query = {
      IsExactMatch: false,
      Types: 'Current_Liability',
      SortAsc: true,
      MaxResults: 1000,
      ...queryParams,
    };
    return GET(`accounts`, query).then((data: IActionResults<IAccounts[]>) => data.Value);
  };

  const submitPayment = (newPayment: any): Promise<IAccounts[]> => {
    return POST('accounts/paymentmethods', newPayment);
  };

  const getPaymentMethods = (): Promise<any> => {
    return GET('accounts/paymentmethods').then((data: IActionResults<ISelectedAccounts>) => data.Value);
  };

  const listAccounts = (filter: IAccountsFilter): Promise<IPagedActionResults<IAccounts[]>> => {
    return GET('accounts', filter).then((data: IPagedActionResults<IAccounts[]>) => data);
  };

  const createAccount = (payload: Partial<IAccounts>): Promise<IAccounts> => {
    return POST('accounts', {Object: payload}).then((data: IActionResults<IAccounts>) => data.Object!);
  };

  const getAccountByCode = (CODE: string): Promise<IAccounts> => {
    return GET(`accounts/getbycode/${CODE}`).then((data: IActionResults<IAccounts>) => data.Value);
  };

  const listUserTenants = (): Promise<IGetUserTenantsResponse> => {
    return GET('account/usertenant').then((data: IGetUserTenantsResponse) => data);
  };

  const parentAccount = (): Promise<IActionResults<IAccounts[]>> => {
    return GET('accounts/parentAccounts').then((data: IActionResults<IAccounts[]>) => data);
  };

  const switchUserTenant = (userGlobalId: string): Promise<IActionRes> => {
    return POST(`account/usertenant/${userGlobalId}`, undefined).then((data: IActionRes) => data);
  };

  return {
    isLoading,
    register,
    confirmEmail,
    recoverPassword,
    resendConfirmationEmail,
    changePassword,
    getAccounts,
    submitPayment,
    getPaymentMethods,
    listAccounts,
    createAccount,
    getAccountByCode,
    listUserTenants,
    switchUserTenant,
    getCreditlineAccounts,
    parentAccount,
  };
};

export default useAccountAPI;
