import React from 'react';
import SVGIcon from '../ui/SVGIcon';
import {Button, Typography} from 'spenda-ui-react';
import {colors} from 'spenda-ui-react/types/generic';
import {variant} from 'spenda-ui-react/types/components/button';
import {VisibleContent} from '../ui/VisibleContent';

export interface IOptionProps {
  border?: string;
  color?: colors;
  description?: string;
  isButton?: boolean;
  keyPath?: string; // keyof HiddenContent["settings"];
  label?: string;
  svg?: any;
  variant?: variant;
  action: () => void;
}

export const Dropdown = (props: {options: IOptionProps[]; title?: string}) => {
  let hasIconSettings = props.options.find(opt => opt.isButton);

  return (
    <ul>
      <li className={`mb-2 cursor-default ${!hasIconSettings ? 'border-b border-spenda-dashbg' : ''}`}>
        <Typography variant="h3" className="relative pb-2 text-start">
          {props.title}
        </Typography>
      </li>
      {props.options.map((opt: IOptionProps, id: number) => (
        <VisibleContent key={id} keyPath={opt.keyPath!}>
          <li key={id}>
            {opt.isButton ? (
              <Button
                data-autoid={`btn${opt?.label?.replace(/ /g, '')}Setting`}
                color={opt.color}
                onClick={opt.action}
                variant={opt.variant}
                fullWidth
                className="min-w-[150px]"
              >
                {opt.label}
              </Button>
            ) : (
              <div
                data-autoid={`btn${opt?.label?.replace(/ /g, '')}Setting`}
                onClick={opt.action}
                className="float-left mb-2 flex cursor-pointer p-2"
              >
                <div className="pt-3">{opt.svg && <SVGIcon name={opt.svg} />}</div>
                <div className="ml-2 flex flex-col text-left">
                  <Typography variant="h3" className="text-primary">
                    {opt.label}
                  </Typography>
                  <Typography>{opt.description}</Typography>
                </div>
              </div>
            )}
          </li>
        </VisibleContent>
      ))}
    </ul>
  );
};
