import React, {useContext} from 'react';
import {Typography} from 'spenda-ui-react';
import {useLocation} from 'react-router-dom';
import {APXeroAdaptorInvoices} from '../../purchasing/APXeroAdaptorInvoices';
import {XeroInvoicesDetails} from '../../../components/accountsPayableOnboarding/XeroInvoicesDetails';
import {IntegrationContext} from '../../../context/IntegrationContext';
import {Layout} from '../../../components/layout/Layout';

enum CollectSettingTabs {
  AdaptorView = 'AdaptorView',
}

export const CollectSettings = () => {
  // Hooks
  const {search} = useLocation();

  // Context
  const {isAPFinancialAdaptorSetup, financialAdaptorDisplayName} = useContext(IntegrationContext);

  // Constant
  const searchParams = new URLSearchParams(search);
  const selectedTab = searchParams.get('tab') || CollectSettingTabs.AdaptorView;
  const adaptorLogId = searchParams.get('adaptorLogId');

  const settingTabs = [
    {
      id: 'btnAdaptor',
      label: `${financialAdaptorDisplayName} adaptor`,
      tab: CollectSettingTabs.AdaptorView,
    },
  ];

  const renderTabContent = () => {
    switch (selectedTab) {
      case CollectSettingTabs.AdaptorView:
        if (!isAPFinancialAdaptorSetup()) return;
        if (adaptorLogId) return <XeroInvoicesDetails arAdaptorLogId={adaptorLogId} scope="AR" />;
        return <APXeroAdaptorInvoices scope="AR" />;
      default:
        return null;
    }
  };

  const settingsTabs = (
    <div
      style={{boxShadow: '0px 0px 6px 0px #D3E5EF'}}
      className={`h-full w-full rounded-[6px] bg-white py-8 !font-poppins`}
    >
      <Typography variant="h2" className="mb-2 px-4 text-lg font-medium text-[#BBBBBB]">
        Settings
      </Typography>
      <div className="w-full pb-8">
        {settingTabs.map(({id, label, tab}) => (
          <button
            key={id}
            data-autoid={id}
            type="button"
            id={id}
            className={`${selectedTab === tab || (!selectedTab && !tab) ? 'bg-primary/10' : 'bg-white'} h-[40px] w-full px-4 text-left text-base font-medium text-black-800`}
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  );

  let mainPanel = (
    <>
      <div
        style={{boxShadow: '0px 0px 6px 0px #D3E5EF'}}
        className={`flex h-full w-full flex-col rounded-md bg-white px-2.5 pt-2.5 font-poppins`}
      >
        {renderTabContent()}
      </div>
    </>
  );

  return (
    <>
      <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
        <Layout leftPanel={settingsTabs} mainPanel={mainPanel} splitWidthType={6} />
      </div>
    </>
  );
};
