import React from 'react';

import AttachmentDefaultView from '../../../components/quote-management/AttachmentDefaultView';
import PreviewAttachment from '../../quote-management/PreviewAttachment';
import {IQuoteAttachment} from '../../../model/quotes/quotes';

export default function ServicePhotos({attachments}: {attachments: IQuoteAttachment[]}) {
  const [attachmentPreview, setAttachmentPreview] = React.useState({
    show: false,
    uri: '',
    type: '',
  });

  return (
    <div className="relative min-h-[300px] w-full overflow-y-auto bg-[#FFFFFF] lg:px-4 xl:px-12">
      <div className={`flex flex-wrap gap-x-5 gap-y-5`}>
        {attachments.map((photo, index) => (
          <AttachmentDefaultView
            key={photo.attachmentGuid}
            caption={photo.note || `${photo.type}_${index + 1}`}
            name={photo.note || `${photo.type}_${index + 1}`}
            uri={photo.uri}
            containerClassName="w-[15%] flex-wrap justify-start"
            isEditAble={false}
            handleVisibility={() => setAttachmentPreview({uri: photo.uri, show: true, type: photo.type})}
          />
        ))}
      </div>

      {attachmentPreview.show && (
        <PreviewAttachment
          type={attachmentPreview.type}
          imageUrl={attachmentPreview.uri}
          setShow={value => setAttachmentPreview({uri: '', show: value, type: ''})}
        />
      )}
    </div>
  );
}
