import {useEffect, useState} from 'react';
import {IStagingInventory, IStagingInventoryFilter} from '../model/inventory/Inventory';
import {useInventoryContext} from '../context/inventory-context/InventoryContext';

export const useStagingInventory = () => {
  let pageSize = 25;

  const initialFilterState = {
    startRow: 1,
    isShowActiveOnly: false,
    maxResults: pageSize,
    isShowAllProducts: true,
    isShowOnlyErrors: false,
    itemssShowOnlyWarnings: false,
    isShowOnlyInActive: false,
    isShowOnlyValid: false,
  } as IStagingInventoryFilter;

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<IStagingInventory[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [moreToGet, setMoreToGet] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [searchFilter, setSearchFilter] = useState<any>(initialFilterState);
  const {filteredStagingInventory} = useInventoryContext();

  const getStagingInventoryList = async () => {
    setLoading(true);

    if (searchFilter.startRow === 1) {
      setItems([]);
      setMoreToGet(true);
    }

    try {
      let items =
        filteredStagingInventory
          ?.filter?.(item => item.isActive)
          .slice(searchFilter.startRow - 1, searchFilter.startRow + searchFilter.maxResults - 1) || [];
      setItems(items);
      setTotalCount(filteredStagingInventory.length);
      setMoreToGet(currentPage * pageSize < filteredStagingInventory.length);
      setCurrentPage(Math.ceil((searchFilter.startRow || 1 + pageSize - 1) / (searchFilter.maxResults || pageSize)));
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStagingInventoryList();
  }, [searchFilter, filteredStagingInventory]);

  return {
    loading,
    items,
    totalCount,
    pageSize: searchFilter.maxResults || pageSize,
    currentPage,
    moreToGet,
    error,
    searchFilter,
    getStagingInventoryList,
    setSearchFilter,
  };
};
