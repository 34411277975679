import React from 'react';
import {IconButton, Typography} from 'spenda-ui-react';
import {Visibility} from '@material-ui/icons';

import {DownloadIcon} from '../../assets/svg/DownloadIcon';
import CrossIcon from '../../assets/svg/Cross';
import {PDFIcon, WordIcon} from '../../assets/svg';
import {AttachmentType} from '../../model/FileDetails';
import {fileExtension} from '../../utils/formatter';
import {Toast} from '../../utils/Toast';
import {useAuth0} from '@auth0/auth0-react';

const getFileIcon = (fileName: string) => {
  const fileTypeToIconMap: {[key: string]: JSX.Element} = {
    pdf: <PDFIcon className="h-[60px] w-[60px] text-gray-600" />,
    doc: <WordIcon className="h-[60px] w-[60px] text-gray-600" />,
    docx: <WordIcon className="h-[60px] w-[60px] text-gray-600" />,
  };

  const extension = fileName.split('.').pop()?.toLowerCase() || '';
  return fileTypeToIconMap[extension] || null;
};

const AttachmentDefaultView = ({
  uri: fileURL,
  name,
  onDelete,
  caption,
  handleVisibility,
  isEditAble,
  containerClassName,
  disabled,
  type,
}: {
  uri: string;
  name: string;
  caption: string;
  isEditAble?: boolean;
  containerClassName?: string;
  onDelete?: () => void;
  handleVisibility?: (fileURL: string, type?: string) => void;
  disabled?: boolean;
  type?: string;
}) => {
  const icon = getFileIcon(name);
  const {isAuthenticated} = useAuth0();

  const handleViewImage = (fileURL: string) => {
    const fetchDocument = async (fileUrl: string) => {
      try {
        const response = await fetch(fileUrl);

        if (!response.ok) {
          throw new Error(response.status.toString());
        }

        const blob = await response.blob(); // Convert the response to a Blob
        const fileName = caption;
        const file = new File([blob], fileName, {
          type: AttachmentType[fileExtension(fileName) as keyof typeof AttachmentType],
        });

        const reader = new FileReader();
        reader.onload = () => {
          handleVisibility && handleVisibility(reader.result as string);
        };

        reader.readAsDataURL(file); // Convert Blob to Data URL
      } catch (error) {
        Toast.error('Fetching PDF ' + error);
        console.error('Error fetching PDF:', error);
      }
    };

    if (handleVisibility) {
      if (!isEditAble && type && type === 'Document' && !isAuthenticated) {
        fetchDocument(fileURL);
      } else handleVisibility(fileURL);
      return;
    }
    window.open(fileURL, '_blank');
  };

  return (
    <div className={`w-1/5 space-y-1 rounded-md ${containerClassName ?? null}`}>
      <div className="relative flex h-[110px] w-full flex-col">
        <div className="absolute inset-0 flex items-center justify-center rounded-md bg-[#00000040] opacity-0 hover:opacity-100">
          <div className="flex space-x-3">
            <Visibility
              data-autoid="btnViewEvidence"
              className="cursor-pointer !fill-[#fff]"
              onClick={() => {
                handleViewImage(fileURL);
              }}
            />
            <a data-autoid="btnDownloadEvidence" href={fileURL} target="_blank" download>
              <DownloadIcon data-autoid="EditEvidenceDialogDownloadIcon" className="cursor-pointer !fill-[#fff]" />
            </a>
          </div>
          {isEditAble && (
            <div className="absolute -right-2 -top-2 flex h-[20px] w-[20px] cursor-pointer items-center justify-center">
              <IconButton
                className="h-[20px] rounded-full bg-error/30"
                color="error"
                disabled={disabled}
                name="DeleteEvidence"
                onClick={onDelete}
                size="sm"
                variant="text"
              >
                <CrossIcon data-autoid="CrossIcon" className="h-[8px] w-[8px] fill-error" />
              </IconButton>
            </div>
          )}
        </div>
        {icon ? (
          <div className="flex h-full items-center justify-center rounded-md bg-gray-200">{icon}</div>
        ) : (
          <img
            loading="lazy"
            src={fileURL}
            alt={name}
            className="h-[110px] w-full rounded-md object-cover object-center"
          />
        )}
        <Typography
          variant="xsmall"
          className="absolute bottom-0 w-full truncate rounded-b-lg bg-[#212121] bg-opacity-30 px-2.5 text-center text-sm text-white"
        >
          {caption}
        </Typography>
      </div>
    </div>
  );
};

export default AttachmentDefaultView;
