/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Layout} from '../../components/layout/Layout';
import {SuppliersListHeader} from './SuppliersListHeader';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {ARTable} from '../../components/AccountsReceivable/ARTable';
import {Arrow} from '../../assets/svg/accounts-payable/Arrow';
import {ISuppliers} from '../../model/supplier/Supplier';
import {Vendor} from '../../assets/svg/accounts-payable/Vendor';
import {Link} from 'react-router-dom';
import {AUTH_BUYING_EDIT_SUPPLIER_ROUTE} from '../../routes/SupplierManagementRoutes';
import {PostingTypeModal} from '../../components/accountsPayableOnboarding/PostingTypeModal';
import {ISupplierPrimaryContact} from '../../model/requires-attention/StagedTransactions';
import {Edit} from '@material-ui/icons';
import {SPagination} from '../../components/tables/SPagination';
import {useLoadSupplier} from '../../services/useLoadSupplier';

export const SuppliersList = () => {
  const [searchString, setSearchString] = useState<string>();
  const [postingTypeSupplier, setPostingTypeSupplier] = useState<ISupplierPrimaryContact>();
  const [selectPostingTypeModel, setSelectPostingTypeModel] = useState<boolean>(false);

  const handleSelectPostingType = (show: boolean = false, s?: ISuppliers) => {
    setSelectPostingTypeModel(show);
    const supplierDetails: ISupplierPrimaryContact = {
      supplierID: s?.supplierID,
      abn: s?.abn,
      emailAddress: s?.emailAddress,
      firstName: s?.firstName,
      lastName: s?.lastName,
      name: s?.name,
      phone: s?.phone1,
      phoneMobile: s?.phoneMobile,
      refNumber: s?.refNumber,
    } as ISupplierPrimaryContact;
    show && supplierDetails && setPostingTypeSupplier(supplierDetails);
  };

  const {
    totalCount,
    items: suppliers,
    loading: isLoading,
    setSearchFilter,
    currentPage,
    searchFilter,
    pageSize,
  } = useLoadSupplier();

  useEffect(() => {
    if (typeof searchString != 'undefined') {
      setSearchFilter({
        ...searchFilter,
        SearchString: searchString,
      });
    }
  }, [setSearchString, searchString]);

  const handlePageChange = (newPage: number) => {
    setSearchFilter({
      ...searchFilter,
      StartRow: (newPage - 1) * pageSize + 1,
    });
  };

  const handleRowsPerPageChange = (rows: number) => {
    setSearchFilter({
      ...searchFilter,
      MaxResults: rows,
      StartRow: 1,
    });
  };

  // Table Columns
  const columns = [
    {
      title: 'Suppliers',
      key: 'Suppliers',
      width: '25%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (s: ISuppliers) => (
        <Link
          to={AUTH_BUYING_EDIT_SUPPLIER_ROUTE.replace(':supplierId', s?.supplierID.toString())}
          data-autoid={`lblSuppliers`}
          className="flex cursor-pointer items-center overflow-ellipsis font-poppins text-base font-medium text-primary underline"
        >
          {s?.name}
          {s.isLinked && s.isVendorMappingEnabled && <Vendor className="ml-2" />}
        </Link>
      ),
    },
    {
      title: 'ABN',
      key: 'ABN',
      width: '12%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      rowRenderer: (s: ISuppliers) => (
        <span data-autoid={`lblABN`} className="font-poppins text-base font-semibold text-black-800">
          {s?.abn}
        </span>
      ),
    },
    {
      title: 'Email',
      key: 'Email',
      width: '20%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'pl-2.5',
      rowRenderer: (s: ISuppliers) => (
        <span
          data-autoid={`lblEmail`}
          className="flex w-full items-center justify-between font-poppins text-base font-medium"
        >
          {s?.emailAddress}
        </span>
      ),
    },
    {
      title: 'Contact number',
      key: 'contactNumber',
      width: '12%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      rowRenderer: (s: ISuppliers) => (
        <span data-autoid={`lblContactNumber`} className="font-poppins text-base font-semibold text-black-800">
          {s?.phoneMobile}
        </span>
      ),
    },
    {
      title: 'Reference ID',
      key: 'referenceId',
      width: '12%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      rowRenderer: (s: ISuppliers) => (
        <span data-autoid={`lblRefId`} className={`font-poppins text-base font-semibold text-black-800`}>
          {s?.refNumber}
        </span>
      ),
    },
    {
      title: 'Post as',
      key: 'PostAs',
      width: '20%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      rowRenderer: (s: ISuppliers) => (
        <>
          {s?.isLinked &&
            (!s?.supplierBSID && !s?.postingSupplierID ? (
              <span
                data-autoid={`lblActionTypeLink`}
                className={`flex cursor-pointer items-center justify-center font-poppins text-base font-semibold text-primary`}
                onClick={() => handleSelectPostingType(true, s)}
              >
                Select Posting Type
                <Arrow className="ml-2" />
              </span>
            ) : (
              <span
                data-autoid={`lblActionTypeUnlink`}
                className={`flex items-end justify-center font-poppins text-base font-semibold text-primary`}
              >
                {s?.name}
              </span>
            ))}
        </>
      ),
    },
    {
      title: 'Action',
      key: 'Action',
      width: '20%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      rowRenderer: (s: ISuppliers) => (
        <Link
          to={AUTH_BUYING_EDIT_SUPPLIER_ROUTE.replace(':supplierId', s?.supplierID.toString())}
          data-autoid={`linkSuppliers`}
          className="flex cursor-pointer items-center overflow-ellipsis font-poppins text-base font-medium text-primary underline"
        >
          <Edit className="ml-2 !h-3 !w-3 cursor-pointer" />
        </Link>
      ),
    },
  ];

  const leftpanel = (
    <div className="invoicesList shadow-[0px_0px_6px_0px_#D3E5EF'] flex h-screen w-full flex-col rounded bg-white px-2 pb-24 pt-2">
      <SuppliersListHeader
        isLoading={isLoading}
        searchText={searchString}
        setSearchString={setSearchString}
        refetchSuppliers={() => setSearchFilter({...searchFilter})}
      />
      {isLoading ? (
        <LoadingIndicator isLoading={isLoading} size="md" color="hsl(var(--primary))" />
      ) : (
        <>
          <ARTable tableClass="!mx-0" rows={suppliers} isLoading={isLoading} columns={columns} scope="AP" />
          <SPagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 15, 25]}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </>
      )}

      {selectPostingTypeModel && (
        <PostingTypeModal
          supplierDetails={postingTypeSupplier}
          handleClose={() => handleSelectPostingType(false)}
          handleDone={async () => {
            handleSelectPostingType(false);
            setSearchFilter({...searchFilter});
          }}
          scope="AP"
          isDialog
        />
      )}
    </div>
  );
  return (
    <div>
      <Layout leftPanel={leftpanel} splitWidthType={4} />
    </div>
  );
};
