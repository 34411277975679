import React, {useEffect, useState} from 'react';
import {Button, IconButton, Typography} from 'spenda-ui-react';
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd';
import {useFormikContext} from 'formik';
import _ from 'lodash';

import {DragIcon, PlusButton} from '../../assets/svg';
import {AlertDialogSlideV2} from './AlertDialogSlideV2';
import {Inventory} from '../../model/inventory/Inventory';
import {useInventoryAPI} from '../../services/useInventoryAPI';
import {ARTable, ARTableDataRender, IARColumns, IARCustomTableRows} from '../AccountsReceivable/ARTable';
import {IAddProductVariation, IAddProductVariationOptions} from '../../model/variants';
import {AddInventoryValues} from '../form/InventoryForm';
import useVariantAPI from '../../services/useVariantAPI';
import {LoadingOverlayV1} from '../ui/LoadingOverlayV1';

interface ICreateMasterVariantDialogProps {
  open?: boolean;
  onClose: () => void;
  productID?: string | undefined;
  handleAddVariant?: () => void;
  handleEditVariation: (data: IAddProductVariation) => void;
  handleAddAttribute: (data: IAddProductVariation) => void;
  handleEditAttribute: (variation: IAddProductVariation, variationOptions: IAddProductVariationOptions) => void;
}

interface AttributeButtonProps {
  label: string;
  shortLabel: string;
  onClick: (label: string, shortLabel: string) => void;
}

interface VariantAttributeProps {
  label: string;
  shortLabel: string;
  onClick: (label: string, shortLabel: string) => void;
  colour?: string;
  imageUrl?: string;
  sellPrice?: string;
}

const CreateMasterVariantDialog: React.FunctionComponent<ICreateMasterVariantDialogProps> = ({
  onClose,
  productID,
  handleAddVariant,
  handleEditVariation,
  handleAddAttribute,
  handleEditAttribute,
}) => {
  const [variationDetails, setVariationDetails] = useState<Inventory | null>(null);

  const {getInventoryById, isLoading: inventoryLoading} = useInventoryAPI();
  const {addVariation, isLoading: variationLoading} = useVariantAPI();
  const {setFieldValue} = useFormikContext<AddInventoryValues>();

  const isLoading = variationLoading || inventoryLoading;

  useEffect(() => {
    const fetchProductData = async () => {
      if (productID) {
        try {
          const fetchedInventory = await getInventoryById(productID);
          if (fetchedInventory) {
            setVariationDetails(fetchedInventory);
          }
          setFieldValue('ChildVariants', fetchedInventory?.ChildVariants);
        } catch (error) {
          console.error('Error fetching inventory:', error);
        }
      }
    };
    fetchProductData();
  }, [productID]);

  const AttributeButton: React.FC<AttributeButtonProps> = ({label, shortLabel, onClick}) => (
    <div
      className="flex cursor-pointer items-center rounded-bl-md rounded-tl-md bg-gray-200 py-1 text-sm"
      onClick={() => onClick(label, shortLabel)}
      role="button"
      tabIndex={0}
      data-autoid="btnAttribute"
    >
      <Typography
        variant="small"
        className="flex !h-2 items-center justify-between text-ellipsis whitespace-nowrap bg-gray-200 !px-2 text-sm text-black hover:no-underline"
        data-autoid="txtAttributeName"
      >
        {label}
      </Typography>
      <Typography variant="small" className="ml-4 mr-4 text-gray-500" data-autoid="txtAttributeAbbr">
        {shortLabel}
      </Typography>
    </div>
  );

  const VariantAttribute: React.FC<VariantAttributeProps> = ({
    label,
    shortLabel,
    onClick,
    colour,
    imageUrl,
    sellPrice,
  }) => (
    <div className={`flex flex-col items-start ${sellPrice && parseFloat(sellPrice) !== 0 ? 'mt-4' : 'mt-0' } `}>
      <div className="flex items-center gap-0.5">
        <AttributeButton label={label} shortLabel={shortLabel} onClick={onClick} />
        {colour && (
          <div
            className="h-10 w-10 rounded-xl border border-[#ECECEC] shadow-sm"
            style={{backgroundColor: `#${colour}`}}
          ></div>
        )}
        {imageUrl && <img src={imageUrl} alt={label} className="h-10 w-10 min-w-[40px] rounded-xl" />}
      </div>
      {sellPrice && parseFloat(sellPrice) !== 0  && (
        <Typography variant="xsmall" className="ml-1 font-medium text-spenda-sGreen">
          + ${sellPrice}(ex)
        </Typography>
      )}
    </div>
  );

  const handleVariationOptionDragEnd = async (result: DropResult, index: number) => {
    // dropped outside the list
    if (!result.destination || result.destination.index === result.source.index) {
      return;
    }

    // no movement
    if (result.destination.index === result.source.index) {
      return;
    }

    const updatedLines = _.cloneDeep(variationDetails?.Variations || []);
    const [movedRow] = updatedLines.at(index)?.Options?.splice(result.source.index, 1) ?? [];
    updatedLines[index]?.Options?.splice(result.destination.index, 0, movedRow);

    // Update sequence numbers based on new order
    updatedLines?.at(index)?.Options?.forEach((line, index) => {
      line.SequenceNumber = 10 * (index + 1);
    });

    setVariationDetails(prevState => {
      return {
        ...prevState,
        Variations: updatedLines,
      };
    });
    addVariation(productID!, {Value: updatedLines});
  };

  const columns: IARColumns[] = [
    {
      title: 'Variations',
      key: 'variant',
      width: '30%',
      align: 'left',
      rowClassName: 'p-2.5 !max-w-[200px]',
      rowRenderer: (data: IAddProductVariation) => {
        return (
          <Button
            variant="text"
            className="p-0 text-left shadow-none hover:no-underline active:bg-transparent"
            onClick={() => handleEditVariation(data)}
          >
            <Typography
              variant="h3"
              className="text-spenda-primarytext"
              data-autoid={`txtVariantColor-${data?.VariationID}`}
            >
              {data?.Name || 'No Name'}
            </Typography>
            <Typography variant="xsmall" data-autoid={`txtVariantColorAbbr-${data?.VariationID}`}>
              {data?.AbbreviatedName || 'No Abbreviation'}
            </Typography>
          </Button>
        );
      },
    },
    {
      title: 'Attributes',
      key: 'attribute',
      width: '67%',
      align: 'left',
      rowClassName: 'p-2.5 !max-w-[780px]',
      rowRenderer: (data: IAddProductVariation, rowIndex) => {
        return (
          <div className="flex items-center gap-2">
            <IconButton
              variant="outlined"
              className="border-none shadow-none"
              ripple={false}
              onClick={() => handleAddAttribute(data)}
            >
              <PlusButton className="cursor-pointer" data-autoid="btnAddNewVariant" width={35} height={35} />
            </IconButton>
            <DragDropContext onDragEnd={res => handleVariationOptionDragEnd(res, rowIndex)}>
              <Droppable droppableId={`variationOptions-${data.VariationID}`} direction="horizontal">
                {droppableProvided => (
                  <div
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                    className="scrollbar-hide flex items-center gap-4 overflow-x-auto"
                  >
                    {data?.Options?.map((option, index) => {
                      return (
                        <Draggable
                          draggableId={`${option.VariationOptionID}`}
                          index={index}
                          key={option.VariationOptionID}
                        >
                          {provided => (
                            <div
                              key={option?.VariationOptionID}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                                <VariantAttribute
                                  label={option?.Name || ''}
                                  shortLabel={option?.AbbreviatedName || ''}
                                  onClick={() => handleEditAttribute(data, option)}
                                  colour={option.Colour}
                                  imageUrl={option.MediaFileUrl}
                                  sellPrice={option.StandardSellPriceExDelta?.toFixed(2) || ''}
                                />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        );
      },
    },
    {
      title: '',
      key: 'drag',
      width: '3%',
      align: 'right',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return (
          <span data-autoid={`lnkDragIcon-${index}`}>
            <DragIcon data-autoid={`imgDrag-${index}`} />
          </span>
        );
      },
    },
  ];

  const onDragEnd = async (result: DropResult) => {
    // dropped outside the list
    if (!result.destination || result.destination.index === result.source.index) {
      return;
    }

    // no movement
    if (result.destination.index === result.source.index) {
      return;
    }

    const updatedLines = _.cloneDeep(variationDetails?.Variations || []);
    const [movedRow] = updatedLines.splice(result.source.index, 1);
    updatedLines.splice(result.destination.index, 0, movedRow);

    // Update sequence numbers based on new order
    updatedLines.forEach((line, index) => {
      line.SequenceNumber = 10 * (index + 1);
    });

    setVariationDetails(prevState => {
      return {
        ...prevState,
        Variations: updatedLines,
      };
    });
    await addVariation(productID!, {Value: updatedLines});
  };

  const CustomTableBody = ({children}: React.PropsWithChildren) => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="variationTableBody">
        {droppableProvided => (
          <tbody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
            {children}
            {droppableProvided.placeholder}
          </tbody>
        )}
      </Droppable>
    </DragDropContext>
  );

  const CustomTableRows = ({row, rowIndex, ...rest}: IARCustomTableRows) => {
    return (
      <Draggable draggableId={`${row.VariationID}`} index={rowIndex} key={row.VariationID}>
        {provided => (
          <tr ref={provided.innerRef} {...provided.draggableProps} {...rest}>
            {columns.map((column, columnIndex) => (
              <ARTableDataRender
                column={column}
                columnIndex={columnIndex}
                isHighlightRowOnHover={false}
                key={`${column.key}row${columnIndex}`}
                row={row}
                rowIndex={rowIndex}
                {...(column.key === 'drag' ? provided.dragHandleProps : {})}
              />
            ))}
          </tr>
        )}
      </Draggable>
    );
  };

  return (
    <>
      <AlertDialogSlideV2
        dialogClassess="!mx-5 !w-full !max-w-[1091px]"
        dialogBodyClassess="min-h-[520px]"
        title={variationDetails?.ShortDescription}
        headerChildren={
          variationDetails?.StandardSellPriceEx !== 0 &&
          variationDetails?.StandardSellPriceInc !== 0 && (
            <Typography className="text-spenda-labeltext" variant="paragraph">
              Base sell price ${variationDetails?.StandardSellPriceEx?.toFixed(2)}(ex) - $
              {variationDetails?.StandardSellPriceInc?.toFixed(2)}(inc)
            </Typography>
          )
        }
        headingClassess="font-semibold justify-center flex flex-col gap-1"
        actions={[
          {label: 'Cancel', action: () => onClose(), variant: 'outlined', className: 'mr-auto'},
          {
            label: 'Done',
            type: 'submit',
            className: 'ml-3',
            action: () => onClose(),
          },
        ]}
        dialogActionsAlignment="justify-between"
      >
        <LoadingOverlayV1 isLoading={isLoading}>
          <div className="mx-4">
            {variationDetails?.VariantsCount && (
              <Typography className="ml-3 text-spenda-labeltext" variant="paragraph">
                Variation combination count {variationDetails?.VariantsCount}/500
              </Typography>
            )}
            <div className={`mt-4 max-h-[400px] overflow-y-auto`}>
              <ARTable
                columns={columns}
                rows={variationDetails?.Variations || []}
                isLoading={false}
                tableClass="w-full"
                customTableBody={CustomTableBody}
                customTableRows={CustomTableRows}
              />
            </div>

            <div className="px-1 py-0">
              <Button
                variant="text"
                ripple={false}
                className="bg-spenda-bckgrndBlue px-3 font-semibold hover:no-underline"
                onClick={() => handleAddVariant?.()}
              >
                + Add Variant
              </Button>
            </div>
          </div>
        </LoadingOverlayV1>
      </AlertDialogSlideV2>
    </>
  );
};

export default CreateMasterVariantDialog;
