import React, {useState, useEffect, useContext} from 'react';
import {Box} from '@material-ui/core';
import {SButton} from '../buttons/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NextArrowIcon from '../../assets/svg/next-arrow.svg';
import BackIcon from '../../assets/svg/back.svg';
import useWorkflowAPI from '../../services/useWorkflowAPI';
import useSynkAPI from '../../services/useSynkAPI';
import {IMasterAdaptor} from '../../model/MasterAdaptor';
import {DatTypes} from '../../model/constants/Constants';
import ProgressBar from '../ProgressBar';
import moment from 'moment';
import {IWorkflowStatusResponse} from '../../model/workflow/WorkflowResponse';
import {ISynkIsAuthorizedResponse} from '../../model/synkd/SynkAuthorizeResponse';
import {Toast} from '../../utils/Toast';
import LoadingIndicator from '../ui/LoadingIndicator';
import {SModal} from '../modals/modalSpendaMeterialUI';
import {ChatWidget} from './ChatWidget';
import AppContext from '../../context/app/appContext';
import CloseIcon from '@material-ui/icons/Close';
import {GetBackgroundCSS} from './Onboarding';
import {makeStyles} from '@material-ui/core/styles';
import {APFooter} from '../accountsPayableOnboarding/APFooter';

const useFetchCustomersStyles = makeStyles({
  apOnboardingModal: {
    maxWidth: '800px',
  },
  boxHeight: {
    height: 'calc(100% - 105px)',
    overflow: 'hidden',
    marginTop: '0px!important',
    '& .simple-modal-title': {
      fontFamily: 'Poppins, sans-serif !important',
      fontSize: '28px',
    },
    '& button': {
      fontFamily: 'Poppins, sans-serif !important',
    },
    '& p, button, span': {
      fontFamily: 'Poppins, sans-serif !important',
    },
  },
  title: {
    fontWeight: 500,
    fontSize: '16px',
    color: '#333333',
  },
  apCustomerBot: {
    margin: '95px 0 0 -5px',
  },
  apCustomerSucessBot: {
    margin: '38px 0 0 -22px',
  },
});

interface FetchCustomersProps {
  selectedAdaptor?: IMasterAdaptor;
  websiteId?: number;
  onNextClick: () => void;
  onBackPress: () => void;
  customerRequestPercentage: number;
  setCustomerRequestPercentage: (value: any) => void;
  customerRequestWorkflowId: number;
  setCustomerRequestWorkflowId: (value: any) => void;
  redirectToStep: (step: number) => void;
  setCustomerRequestStatus: (value: string) => void;
  customerRequestStatus: string;
  setCustomerRequestData: (value: any) => void;
  customerRequestData: string;
  selectedAccount?: IMasterAdaptor;
  setCustomerNavigated: (value: boolean) => void;
  customerNavigated: boolean;
  handleClose: () => void;
  isBuyer: boolean;
  isAPOnboarding?: boolean;
  isPurchasingOnboarding?: boolean;
}

export const FetchCustomers: React.FunctionComponent<FetchCustomersProps> = ({
  onNextClick,
  selectedAdaptor,
  websiteId,
  onBackPress,
  customerRequestPercentage,
  setCustomerRequestPercentage,
  setCustomerRequestWorkflowId,
  redirectToStep,
  setCustomerRequestStatus,
  customerRequestStatus,
  customerRequestData,
  setCustomerRequestData,
  selectedAccount,
  setCustomerNavigated,
  customerNavigated,
  handleClose,
  isBuyer,
  isAPOnboarding,
  isPurchasingOnboarding,
}: FetchCustomersProps) => {
  const appCtx = useContext(AppContext);
  const currentTime = moment(new Date());
  const {synkDataRead, checkWorkflowStatus} = useWorkflowAPI();
  const {synkIsAuthorize} = useSynkAPI();
  const classes = useFetchCustomersStyles();

  let timer: ReturnType<typeof setInterval>;
  let workflowStatusTimer: ReturnType<typeof setTimeout>;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (customerRequestStatus === 'SUCCESSFULL') {
      setCustomerRequestPercentage(0);
      setCustomerNavigated(true);
    }
  }, []);

  const updateProgress = (value: number) => {
    setCustomerRequestPercentage((state: number) => {
      if (value < 99) {
        return value;
      }
      return state;
    });
  };

  const checkWorkflowStatusFun = (customerRequestWorkflowId: number) => {
    if (customerRequestWorkflowId) {
      checkWorkflowStatus(customerRequestWorkflowId).then((data: IWorkflowStatusResponse) => {
        if (data.Status === 'Complete') {
          appCtx.setWorkFlowId(undefined);
          clearTimeout(workflowStatusTimer);
          clearInterval(timer);
          setCustomerRequestStatus('SUCCESSFULL');
          const localTime = moment.utc(data.EndDateTime_utc).local().format('DD MMM YYYY, LT');
          setCustomerRequestData(localTime);
          setCustomerRequestPercentage(100);
        } else {
          workflowStatusTimer = setTimeout(() => {
            checkWorkflowStatusFun(customerRequestWorkflowId);
          }, 5000);
        }
      });
    }
  };

  let loadingPercentageCalculator = async () => {
    clearInterval(timer);
    let percentage = 0;
    timer = setInterval(() => {
      percentage = percentage + 1;
      updateProgress(percentage);
    }, 500);
  };

  const getCustomers = async () => {
    if (selectedAdaptor?.Name && selectedAdaptor?.ID && selectedAdaptor?.MasterAdaptorID && websiteId) {
      setIsLoading(true);
      const response: ISynkIsAuthorizedResponse = await synkIsAuthorize(
        selectedAdaptor?.Name,
        selectedAdaptor?.ID,
        selectedAdaptor?.MasterAdaptorID,
      );
      const isAuthorized = response.IsAuthorized;
      if (!isAuthorized) {
        // redirect back to xero account selection
        redirectToStep(3);
        Toast.error(`You are disconnected from ${selectedAccount?.Name}. Please connect again.`);
        return;
      }

      setCustomerNavigated(false);
      setCustomerRequestStatus('STARTED');
      setCustomerRequestWorkflowId(null);
      setCustomerRequestPercentage(0);
      loadingPercentageCalculator();

      let customer: {Value: {WorkflowID: number | undefined; IsComplete: any}};

      if (!isAPOnboarding) {
        const invoices = await synkDataRead(
          true,
          'Read',
          selectedAdaptor.Name,
          selectedAdaptor.ID,
          DatTypes.Invoice,
          29,
          true,
          moment().subtract(3, 'years').toISOString(),
        );
        customer = invoices;
      } else {
        const purchaseInvoices = await synkDataRead(
          true,
          'Read',
          selectedAdaptor.Name,
          selectedAdaptor.ID,
          DatTypes.PurchaseInvoice,
          29,
          true,
          moment().subtract(3, 'years').toISOString(),
        );
        customer = purchaseInvoices;
      }

      setIsLoading(false);
      setCustomerRequestWorkflowId(customer?.Value?.WorkflowID);
      if (customer?.Value?.IsComplete) {
        setCustomerRequestStatus('SUCCESSFULL');
        setCustomerRequestData(moment(new Date()).format('DD MMM YYYY, LT'));
        setCustomerRequestPercentage(100);
        clearInterval(timer);
      } else {
        setCustomerRequestStatus('FAILED');
        setCustomerRequestData(null);

        appCtx.setWorkFlowId(customer?.Value?.WorkflowID);
        workflowStatusTimer = setTimeout(() => {
          customer?.Value?.WorkflowID && checkWorkflowStatusFun(customer.Value.WorkflowID);
        }, 5000);
      }
    }
  };

  return (
    <SModal open>
      <div className={`spenda-color flex h-full max-w-full items-center justify-center text-xl ${GetBackgroundCSS()}`}>
        <div
          className={`modal-box ${
            isAPOnboarding ? `${classes.apOnboardingModal} px-3 py-3` : 'fetch-customer-modal max-w-3xl px-5 py-5'
          } `}
        >
          {isAPOnboarding && (
            <Box className="flex justify-between">
              <Box className="flex w-full justify-end text-right">
                <span className="ap-onboarding-close-icon" onClick={handleClose} data-autoid="btnClose">
                  <CloseIcon />
                </span>
              </Box>
            </Box>
          )}
          {isBuyer && !isAPOnboarding && (
            <span className="closeIcon onboarding-close-icon" onClick={handleClose} data-autoid="btnClose">
              <CloseIcon />
            </span>
          )}
          {!isAPOnboarding && (
            <span
              className="cursor-pointer"
              style={{position: 'relative'}}
              onClick={() => {
                if (customerRequestStatus !== 'NOTSTARTED') {
                  // setCustomerNavigated(true);
                }
                onBackPress();
              }}
              data-autoid="btnBack"
            >
              <img src={BackIcon} alt="Next Arrow Icon" className="back-btn-bg-image" style={{marginTop: '-7px'}} />
              <ChevronLeftIcon className="back-btn-icon" />
            </span>
          )}
          <div
            className={`${customerRequestStatus !== 'NOTSTARTED' ? 'after-success-row' : ''} ${
              isAPOnboarding
                ? `${classes.boxHeight} flex flex-row-reverse justify-between px-12 pt-5`
                : 'fetch-customer-row my-8 ml-8 flex w-11/12 items-center justify-between'
            }`}
          >
            <div className={`${isAPOnboarding ? 'ml-8' : 'relative'} w-full`}>
              {isAPOnboarding ? (
                <p
                  id="simple-modal-description"
                  className={`simple-modal-title text-center ${
                    isAPOnboarding ? 'pb-12 pl-6 text-3xl' : 'pb-3 text-4xl'
                  }`}
                >
                  {customerRequestStatus === 'SUCCESSFULL' && customerNavigated
                    ? ''
                    : 'Awesome! Now let’s import your suppliers and Purchase Invoices'}
                </p>
              ) : (
                <p
                  id="simple-modal-description"
                  className={`simple-modal-title text-center ${
                    isAPOnboarding ? 'pb-12 pl-6 text-3xl' : 'pb-3 text-4xl'
                  }`}
                >
                  {customerRequestStatus === 'SUCCESSFULL' && customerNavigated
                    ? `“Would you like to refresh your Accounts Receivable from your${' '}
                  ${selectedAccount?.Name}${' '} account.”`
                    : `"Awesome! Let's get your Accounts Receivable from your${' '}
                ${selectedAccount?.Name}${' '} account."`}
                </p>
              )}

              {(customerRequestStatus === 'NOTSTARTED' ||
                customerRequestStatus === 'STARTED' ||
                customerRequestStatus === 'FAILED') && (
                <p
                  id="simple-modal-description"
                  className={`${
                    isAPOnboarding
                      ? `${classes.title} px-4 pb-12 text-center`
                      : 'customer-description-para color-gray mx-4 mb-8 mt-4 text-center'
                  }`}
                >
                  "
                  {isPurchasingOnboarding
                    ? ' Purchase Invoices '
                    : isAPOnboarding
                      ? 'Accounts Payable'
                      : 'Accounts Receivable'}{' '}
                  can take a while to sync with Spenda depending on how many you have. Press
                  {
                    <span className="font-semibold">
                      {isPurchasingOnboarding
                        ? ' Get Purchase Invoices '
                        : isAPOnboarding
                          ? ' Get Accounts Payable '
                          : ' Get Accounts Receivable '}
                    </span>
                  }
                  to start the import process."
                </p>
              )}

              {customerRequestStatus === 'SUCCESSFULL' && !customerNavigated && (
                <p
                  id="simple-modal-description"
                  className={`${
                    isAPOnboarding
                      ? `${classes.title} px-7 pb-16 text-center`
                      : 'customer-description-para color-gray mx-4 mb-8 mt-4 text-center'
                  }`}
                >
                  Hurray! We have imported your{' '}
                  {isPurchasingOnboarding
                    ? 'Purchase Invoices'
                    : isAPOnboarding
                      ? 'Accounts Payable'
                      : 'Accounts Receivable'}
                  . Press "Next" to continue.
                </p>
              )}

              <Box display="flex" justifyContent="center" className={isAPOnboarding ? 'px-7' : ''}>
                {(customerRequestStatus === 'STARTED' || customerRequestStatus === 'FAILED') && (
                  <ProgressBar bgcolor={'#2F97BC'} completed={customerRequestPercentage} />
                )}
              </Box>

              {customerRequestStatus === 'FAILED' && (
                <p
                  id="simple-modal-description"
                  className={`${
                    isAPOnboarding
                      ? `${classes.title} px-7 pt-7 text-center`
                      : 'simple-modal-title time-taken-para pb-3 text-center text-xl'
                  }`}
                >
                  Fetching{' '}
                  {isPurchasingOnboarding ? 'Purchasing' : isAPOnboarding ? 'Accounts Payable' : 'Accounts Receivable'}{' '}
                  is taking some time! While it gets completed, you can continue your onboarding by clicking on ‘Next’
                </p>
              )}
              {customerRequestStatus === 'SUCCESSFULL' && customerNavigated && (
                <>
                  <p
                    id="simple-modal-description"
                    className={` ${
                      isAPOnboarding
                        ? `${classes.title} px-7 pb-16 text-center`
                        : 'simple-modal-title time-taken-para color-gray pb-3 text-center text-xl'
                    }`}
                  >
                    We have read in your{' '}
                    {isPurchasingOnboarding
                      ? 'Purchasing'
                      : isAPOnboarding
                        ? 'Accounts Payable'
                        : 'Accounts Receivable'}{' '}
                    {moment.duration(currentTime.diff(customerRequestData)).asMinutes().toFixed(0)} minute(s) ago. Would
                    you like us to check for updates?
                  </p>
                </>
              )}

              <Box display="flex" justifyContent="center">
                {customerRequestStatus === 'NOTSTARTED' && (
                  <SButton
                    type="submit"
                    className={'common-next-btn invoice-btn mt-4'}
                    width="m"
                    height={isAPOnboarding ? '40px' : undefined}
                    lineHeight={isAPOnboarding ? 'l' : 'm'}
                    fontSize={isAPOnboarding ? 'xs' : 'm'}
                    borderRadius={isAPOnboarding ? '6px' : undefined}
                    padding={isAPOnboarding ? '7px 20px!important' : undefined}
                    variant={isAPOnboarding ? 'outlined' : 'contained'}
                    backgroundColor="#1C78AD"
                    textColor="white"
                    disabled={isLoading}
                    onClick={() => getCustomers()}
                    data-autoid={`btn${
                      isPurchasingOnboarding
                        ? 'GetPurchasing'
                        : isAPOnboarding
                          ? 'GetAccountsPayable'
                          : 'GetAccountsReceivable'
                    }`}
                  >
                    {isPurchasingOnboarding
                      ? 'Get Purchase Invoices'
                      : isAPOnboarding
                        ? 'Get Accounts Payable'
                        : 'Get Accounts Receivable'}

                    {isLoading ? (
                      <LoadingIndicator
                        isLoading={isLoading}
                        size="sm"
                        color="hsl(var(--white))"
                        position={{right: '5px'}}
                      />
                    ) : (
                      <img src={NextArrowIcon} alt="Next Arrow Icon" className="" />
                    )}
                  </SButton>
                )}
              </Box>
              {isAPOnboarding && (
                <Box display="flex" justifyContent="center">
                  {customerRequestStatus === 'SUCCESSFULL' && customerNavigated && (
                    <SButton
                      type="submit"
                      className={`common-next-btn invoice-btn mt-4`}
                      width="m"
                      height="40px"
                      lineHeight="l"
                      fontSize={'xs'}
                      borderRadius={'6px'}
                      padding={'7px 20px!important'}
                      variant={'outlined'}
                      backgroundColor="#1C78AD"
                      textColor="white"
                      disabled={isLoading}
                      onClick={() => getCustomers()}
                    >
                      {isPurchasingOnboarding
                        ? 'Get Purchase Invoices'
                        : isAPOnboarding
                          ? 'Get Accounts Payable'
                          : 'Get Accounts Receivable'}
                      {isLoading ? (
                        <LoadingIndicator
                          isLoading={isLoading}
                          size="sm"
                          color="hsl(var(--primary))"
                          position={{right: '5px'}}
                        />
                      ) : (
                        <img src={NextArrowIcon} alt="Next Arrow Icon" className="" />
                      )}
                    </SButton>
                  )}
                </Box>
              )}
            </div>
          </div>
          <Box
            display="flex"
            justifyContent="center"
            className={
              customerRequestStatus === 'SUCCESSFULL' ? 'FetchCustomers-btns-sucess' : 'FetchCustomers-btns-failed'
            }
          >
            {customerRequestStatus === 'SUCCESSFULL' && customerNavigated && !isAPOnboarding && (
              <SButton
                type="submit"
                className={`common-next-btn invoice-btn mt-4 ${
                  customerRequestStatus === 'SUCCESSFULL' ? 'primaryBtn' : ''
                }`}
                width="m"
                lineHeight="m"
                fontSize="m"
                disabled={isLoading}
                onClick={() => getCustomers()}
                data-autoid={`btn${isAPOnboarding ? 'GetAccountsPayable' : 'GetAccountsReceivable'}`}
              >
                {isAPOnboarding ? 'Get Accounts Payable' : 'Get Accounts Receivable'}
                {isLoading ? (
                  <LoadingIndicator
                    isLoading={isLoading}
                    size="sm"
                    color="hsl(var(--primary))"
                    position={{right: '5px'}}
                  />
                ) : (
                  <img src={NextArrowIcon} alt="Next Arrow Icon" className="" />
                )}
              </SButton>
            )}
            {(customerRequestStatus === 'SUCCESSFULL' || customerRequestStatus === 'FAILED') && !isAPOnboarding && (
              <SButton
                type="submit"
                className="common-next-btn
                                mt-4"
                width="m"
                lineHeight="m"
                fontSize="m"
                onClick={() => {
                  onNextClick();
                }}
                data-autoid="btnNext"
              >
                Next <img src={NextArrowIcon} alt="Next Arrow Icon" className="" />
              </SButton>
            )}
          </Box>
          {!isAPOnboarding && (
            <div className="live-chat-section">
              <ChatWidget />
            </div>
          )}
          {isAPOnboarding && (
            <APFooter
              isDisabled={!(customerRequestStatus === 'SUCCESSFULL' || customerRequestStatus === 'FAILED')}
              onNextClick={onNextClick}
            />
          )}
        </div>
      </div>
    </SModal>
  );
};
