import * as React from 'react';

export const PostingBehaviourIcon = (props: React.SVGProps<SVGSVGElement> & { className?: string }) => {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={20}
          height={21}
          fill="none"
          {...props}
        >
          <path
            className={props.className || 'fill-primary'}
            fillRule="evenodd"
            d="M7.5 12.167h5a.833.833 0 100-1.667h-5a.833.833 0 100 1.667z"
            clipRule="evenodd"
          />
          <path
            className={props.className || 'fill-primary'}
            fillRule="evenodd"
            d="M15.833.5H4.167A4.172 4.172 0 000 4.667V5.5a2.5 2.5 0 00.833 1.862v8.971A4.172 4.172 0 005 20.5h10a4.172 4.172 0 004.167-4.167V7.362A2.5 2.5 0 0020 5.5v-.833A4.172 4.172 0 0015.833.5zM1.667 4.667a2.5 2.5 0 012.5-2.5h11.666a2.5 2.5 0 012.5 2.5V5.5a.833.833 0 01-.833.833h-15a.833.833 0 01-.833-.833v-.833zM17.5 16.333a2.5 2.5 0 01-2.5 2.5H5a2.5 2.5 0 01-2.5-2.5V8h15v8.333z"
            clipRule="evenodd"
          />
        </svg>
      )
};
