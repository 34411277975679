import React, {SetStateAction, useEffect, useState} from 'react';
import {Tooltip, Typography} from 'spenda-ui-react';

import {RoundedCheckbox} from '../../../../components/inputs/RoundedCheckbox';
import {ARTable} from '../../../../components/AccountsReceivable/ARTable';
import {SPagination} from '../../../../components/tables/SPagination';
import {Toast} from '../../../../utils/Toast';
import {IStagingInventory} from '../../../../model/inventory/Inventory';
import {useInventoryContext} from '../../../../context/inventory-context/InventoryContext';
import {PriceFormat} from '../../../../utils/formatter';
import {useStagingInventory} from '../../../../hooks/useStagingInventory';
import {CreateNewProductItemModal} from '../../../../components/accountsPayableOnboarding/CreateNewProductItemModal';
import moment from 'moment';

interface IStagingAreaTable {
  selectedInventoryRows: number[];
  setSelectedInventoryRows: React.Dispatch<SetStateAction<number[]>>;
}

export const StagingAreaTable: React.FC<IStagingAreaTable> = ({selectedInventoryRows, setSelectedInventoryRows}) => {
  const [selectedInventory, setSelectedInventory] = useState<IStagingInventory>();

  const {invalidStagingInventoryCount, validStagingInventoryCount} = useInventoryContext();

  const {loading, items, totalCount, pageSize, currentPage, searchFilter, setSearchFilter} = useStagingInventory();

  const handlePageChange = (newPage: number) => {
    setSearchFilter({
      ...searchFilter,
      startRow: (newPage - 1) * pageSize + 1,
    });
  };

  const handleRowsPerPageChange = (rows: number) => {
    setSearchFilter({
      ...searchFilter,
      maxResults: rows,
      startRow: 1,
    });
  };

  const getBorderClass = (validationResult: string) => {
    if (validationResult === 'missing') {
      return 'border border-error';
    } else if (validationResult === 'invalid') {
      return 'border border-warning';
    }
    return '';
  };

  const commonRowClass = 'm-0 flex items-center text-lg focus-within:border-primary';

  useEffect(() => {
    if (invalidStagingInventoryCount > 0) {
      Toast.warning(
        <>
          <Typography className="font-medium text-spenda-primarytext">
            {validStagingInventoryCount} products uploaded, {invalidStagingInventoryCount} issues found
          </Typography>
          <Typography variant="xsmall" className="text-spenda-primarytext">
            Please address the following issues in your Excel template. Once these issues are resolved, please re-upload
            your file.
          </Typography>
        </>,
        {
          border: '1px solid #C68A19',
          padding: '10px',
          width: '503px',
        },
      );
    }
  }, []);

  const columns = [
    {
      title: '',
      key: 'inventoryCSVID',
      align: 'center',
      columClassName: 'sticky left-0 z-20',
      rowClassName: 'sticky left-0 z-15 border-b border-[#f1f1f1]',
      rowRenderer: (rowData: IStagingInventory, _index: number) => {
        return (
          <div className="w-[30px]">
            <RoundedCheckbox
              value={rowData.inventoryCSVID}
              onChange={() => {
                const selectedRows = [...selectedInventoryRows];
                if (selectedRows.includes(rowData.inventoryCSVID)) {
                  const index = selectedRows.findIndex(item => item === rowData.inventoryCSVID);
                  selectedRows.splice(index, 1);
                } else {
                  selectedRows.push(rowData.inventoryCSVID);
                }
                setSelectedInventoryRows(selectedRows);
              }}
              checked={selectedInventoryRows.includes(rowData.inventoryCSVID)}
            />
          </div>
        );
      },
    },
    {
      title: 'Code',
      key: 'code',
      align: 'left',
      columClassName: 'sticky left-[30px] z-20',
      rowClassName: 'sticky left-[30px] z-15 bg-white border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'code').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'code').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} w-[60px] cursor-pointer`} onClick={() => handleRowClick(rowData)}>
            <Typography className="px-2" data-autoid={`txtCode-${rowIndex}`}>
              {rowData?.code}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Product Name',
      key: 'shortDescription',
      width: '100px',
      align: 'left',
      columClassName: 'sticky left-[90px] z-20',
      rowClassName: 'sticky left-[90px] z-15 border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'shortdescription').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'shortdescription').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} w-[250px] cursor-pointer`} onClick={() => handleRowClick(rowData)}>
            <Typography className="px-2 font-medium text-primary" data-autoid={`txtShortDescription-${rowIndex}`}>
              {rowData?.shortDescription}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'SKU',
      key: 'sku',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'sku').length > 0
            ? 'missing'
            : rowData.errors.filter(item => item.columnName.toLowerCase() === 'sku').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} cursor-pointer`} onClick={() => handleRowClick(rowData)}>
            <Typography className="px-2" data-autoid={`txtSku-${rowIndex}`}>
              {rowData?.sku}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Barcode',
      key: 'barcode',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'barcode').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'barcode').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} cursor-pointer`} onClick={() => handleRowClick(rowData)}>
            <Typography className="px-2" data-autoid={`txtSku-${rowIndex}`}>
              {rowData?.barcode}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Description',
      key: 'description',
      width: '5%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'description').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'description').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} cursor-pointer`} onClick={() => handleRowClick(rowData)}>
            <Typography className="px-2" data-autoid={`txtSku-${rowIndex}`}>
              {rowData?.description}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Product Class',
      key: 'className',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'classname').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'classname').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} `}>
            <Typography className="px-2" data-autoid={`txtClassName-${rowIndex}`}>
              {rowData?.className}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Sell Price (ex)',
      key: 'sellPriceEx',
      width: '2%',
      align: 'right',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'sellpriceex').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'sellpriceex').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass}  justify-end`}>
            <Typography className="px-2" data-autoid={`txtSellPriceEx-${rowIndex}`}>
              {rowData?.sellPriceEx && PriceFormat(parseInt(rowData?.sellPriceEx || ''))}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Cost Price (ex)',
      key: 'costPriceEx',
      width: '2%',
      align: 'right',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'costpriceex').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'costpriceex').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} justify-end`}>
            <Typography className="px-2" data-autoid={`txtCostPriceEx-${rowIndex}`}>
              {rowData?.costPriceEx && PriceFormat(parseInt(rowData?.costPriceEx || ''))}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'RRP',
      key: 'rrp',
      width: '2%',
      align: 'center',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'rrp').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'rrp').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} justify-end`}>
            <Typography className="px-2" data-autoid={`txtRRP-${rowIndex}`}>
              {rowData?.rrp && PriceFormat(parseInt(rowData?.rrp || ''))}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Parent Category Name',
      key: 'parentCategoryName',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'parentcategoryname').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'parentcategoryname').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass}   `}>
            <Typography className="px-2" data-autoid={`txtParentCategoryName-${rowIndex}`}>
              {rowData?.parentCategoryName}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Level2 Category Name',
      key: 'level2CategoryName',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'level2categoryname').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'level2categoryname').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass}   `}>
            <Typography className="px-2" data-autoid={`txLevel2CategoryName-${rowIndex}`}>
              {rowData?.level2CategoryName}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Level3 Category Name',
      key: 'level3CategoryName',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'level3categoryname').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'level3categoryname').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} `}>
            <Typography className="px-2" data-autoid={`txtLevel3CategoryName-${rowIndex}`}>
              {rowData?.level3CategoryName}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Brand Name',
      key: 'brandName',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'brandname').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'brandname').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} `}>
            <Typography className="px-2" data-autoid={`txtBrandName-${rowIndex}`}>
              {rowData?.brandName}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Catalogue Name',
      key: 'catalogueName',
      width: '7%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'cataloguename').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'cataloguename').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} `}>
            <Typography className="px-2" data-autoid={`txtCatalogueName-${rowIndex}`}>
              {rowData?.catalogueName}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Is SOH Tracked',
      key: 'isSOHTracked',
      width: '2%',
      align: 'center',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'issohtracked').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'issohtracked').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} justify-center`}>
            <Typography className="px-2" data-autoid={`txtIsSOHTracked-${rowIndex}`}>
              {rowData?.isSOHTracked}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Is Stocked In Batches',
      key: 'isStockedInBatches',
      width: '2%',
      align: 'center',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'isstockedinbatches').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'isstockedinbatches').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} justify-center`}>
            <Typography className="px-2" data-autoid={`txtIsStockedInBatches-${rowIndex}`}>
              {rowData?.isStockedInBatches}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Is Discontinued',
      key: 'isDiscontinued',
      width: '2%',
      align: 'center',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'isdiscontinued').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'isdiscontinued').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} justify-center`}>
            <Typography className="px-2" data-autoid={`txtIsDiscontinued-${rowIndex}`}>
              {rowData?.isDiscontinued}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Discontinued Date',
      key: 'discontinuedDate',
      width: '2%',
      align: 'center',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'discontinueddate').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'discontinueddate').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} justify-center`}>
            <Typography className="px-2" data-autoid={`txtDiscontinuedDate-${rowIndex}`}>
              {rowData?.discontinuedDate ? moment(rowData?.discontinuedDate).format('Do MMM YYYY') : ''}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Is Sold',
      key: 'isSold',
      width: '2%',
      align: 'center',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'issold').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'issold').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} justify-center`}>
            <Typography className="px-2" data-autoid={`txtIsSold-${rowIndex}`}>
              {rowData?.isSold}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Is Order Tax Exempt',
      key: 'isOrderTaxExempt',
      width: '2%',
      align: 'center',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'isordertaxexempt').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'isordertaxexempt').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} justify-center`}>
            <Typography className="px-2" data-autoid={`txtOrderTaxExempt-${rowIndex}`}>
              {rowData?.isOrderTaxExempt}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Is Purchased',
      key: 'isPurchased',
      width: '2%',
      align: 'center',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'ispurchased').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'ispurchased').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} justify-center`}>
            <Typography className="px-2" data-autoid={`txtPurchased-${rowIndex}`}>
              {rowData?.isPurchased}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Is Physical',
      key: 'isPhysical',
      width: '2%',
      align: 'center',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'isPhysical').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'isPhysical').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} justify-center`}>
            <Typography className="px-2" data-autoid={`txtPhysical-${rowIndex}`}>
              {rowData?.isPhysical}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Revenue account',
      key: 'revenueAccount',
      width: '2%',
      align: 'center',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'revenueaccount').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'revenueaccount').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} justify-center`}>
            <Typography className="px-2" data-autoid={`txtRevenueAccount-${rowIndex}`}>
              {rowData?.revenueAccount}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Expense account',
      key: 'expenseAccount',
      width: '2%',
      align: 'center',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'expenseaccount').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'expenseaccount').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} justify-center`}>
            <Typography className="px-2" data-autoid={`txtExpenseAccount-${rowIndex}`}>
              {rowData?.expenseAccount}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Asset Account',
      key: 'assetAccount',
      width: '2%',
      align: 'center',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'assetaccount').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'assetaccount').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} justify-center`}>
            <Typography className="px-2" data-autoid={`txtAssetAccount-${rowIndex} justify-center`}>
              {rowData?.assetAccount}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'UoM',
      key: 'uoM',
      width: '2%',
      align: 'center',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'uom').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'uom').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} justify-center`}>
            <Typography className="px-2 text-center" data-autoid={`txtUoM-${rowIndex}`}>
              {rowData?.uoM}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'UoM Description',
      key: 'uoMDescription',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'uomdescription').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'uomdescription').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass}`}>
            <Typography className="px-2" data-autoid={`txtuoMDescription-${rowIndex}`}>
              {rowData?.uoMDescription}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Inner Item SKU',
      key: 'innerItemSKU',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'inneritemsku').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'inneritemsku').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass}`}>
            <Typography className="px-2" data-autoid={`txtInnerItemSKU-${rowIndex}`}>
              {rowData?.innerItemSKU}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Quantity of Inners',
      key: 'quantityofInners',
      width: '2%',
      align: 'center',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'quantityofinners').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'quantityofinners').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} justify-center`}>
            <Typography className="px-2" data-autoid={`txtQuantityofInners-${rowIndex}`}>
              {rowData?.quantityofInners}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Supplier Name',
      key: 'supplierName',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'suppliername').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'suppliername').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass}`}>
            <Typography className="px-2" data-autoid={`txtSupplierName-${rowIndex}`}>
              {rowData?.supplierName}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Supplier SKU',
      key: 'supplierSKU',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'suppliersku').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'suppliersku').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass}`}>
            <Typography className="px-2" data-autoid={`txtSupplierSKU-${rowIndex}`}>
              {rowData?.supplierSKU}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Note',
      key: 'note',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'note').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'note').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass}`}>
            <Typography className="px-2" data-autoid={`txtNote-${rowIndex}`}>
              {rowData?.note}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Url',
      key: 'url',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'url').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'url').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass}`}>
            <Typography className="px-2" data-autoid={`txtUrl-${rowIndex}`}>
              {rowData?.url}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Is Published',
      key: 'isPublished',
      width: '2%',
      align: 'center',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'ispublished').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'ispublished').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} justify-center`}>
            <Typography className="px-2" data-autoid={`txtIsPublished-${rowIndex}`}>
              {rowData?.isPublished}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Length m',
      key: 'length_m',
      width: '2%',
      align: 'center',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'length_m').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'length_m').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} justify-center`}>
            <Typography className="px-2" data-autoid={`txtLength_m-${rowIndex}`}>
              {rowData?.length_m}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Width m',
      key: 'width_m',
      width: '2%',
      align: 'center',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'width_m').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'width_m').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} justify-center`}>
            <Typography className="px-2" data-autoid={`txtWidth_m-${rowIndex}`}>
              {rowData?.width_m}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Depth m',
      key: 'depth_m',
      width: '2%',
      align: 'center',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'depth_m').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'depth_m').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} justify-center`}>
            <Typography className="px-2" data-autoid={`txtDepth_m-${rowIndex}`}>
              {rowData?.depth_m}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Weight kg',
      key: 'weight_kg',
      width: '2%',
      align: 'center',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'weight_kg').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'weight_kg').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} justify-center`}>
            <Typography className="px-2" data-autoid={`txtWeight_kg-${rowIndex}`}>
              {rowData?.weight_kg}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Warranty Period Type',
      key: 'warrantyPeriodType',
      width: '2%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'warrantyperiodtype').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'warrantyperiodtype').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass}`}>
            <Typography className="px-2" data-autoid={`txtWarrantyPeriodType-${rowIndex}`}>
              {rowData?.warrantyPeriodType}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Warranty Period',
      key: 'warrantyPeriod',
      width: '2%',
      align: 'center',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'warrantyperiod').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'warrantyperiod').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} justify-center`}>
            <Typography className="px-2" data-autoid={`txtWarrantyPeriod-${rowIndex}`}>
              {rowData?.warrantyPeriod}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Warranty Details',
      key: 'warrantyDetails',
      width: '7%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'warrantydetails').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'warrantydetails').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass}`}>
            <Typography className="px-2" data-autoid={`txtWarrantyDetails-${rowIndex}`}>
              {rowData?.warrantyDetails}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Warranty URL',
      key: 'warrantyURL',
      width: '7%',
      align: 'left',
      rowClassName: 'border-b border-[#f1f1f1]',
      getRowClassConditionally: (rowData: IStagingInventory) => {
        const validationResult =
          rowData.errors.filter(item => item.columnName.toLowerCase() === 'warrantyurl').length > 0
            ? 'missing'
            : rowData.warnings.filter(item => item.columnName.toLowerCase() === 'warrantyurl').length > 0
              ? 'invalid'
              : '';
        return `${getBorderClass(validationResult)}`;
      },
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass}`}>
            <Typography className="px-2" data-autoid={`txtWarrantyURL-${rowIndex}`}>
              {rowData?.warrantyURL}
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Warning message',
      key: 'warningMessage',
      width: '15%',
      align: 'center',
      rowClassName: 'border-b border-[#f1f1f1]',
      rowRenderer: (rowData: IStagingInventory, rowIndex: number, _columnIndex: number) => {
        return (
          <div className={`${commonRowClass} justify-center`}>
            {!rowData.errors.length && !rowData.warnings.length ? (
              '-'
            ) : (
              <>
                {(rowData.errors.length === 1 && !rowData.warnings.length) ||
                (!rowData.errors.length && rowData.warnings.length === 1) ? (
                  <>
                    {rowData?.errors?.length ? (
                      <Typography data-autoid={`txtErrorMessage-${rowIndex}`} className="text-error">
                        {`${rowData?.errors?.[0]?.description
                          ?.replace('${ColumnName}', rowData?.errors?.[0]?.columnName)
                          ?.replace('${Actual}', rowData?.errors?.[0]?.actual)}`}
                      </Typography>
                    ) : (
                      <Typography data-autoid={`txtWarningMessage-${rowIndex}`} className="ml-1 text-warning ">
                        {`${rowData?.warnings?.[0]?.description
                          ?.replace('${ColumnName}', rowData?.warnings?.[0]?.columnName)
                          ?.replace('{Actual}', rowData?.warnings?.[0]?.actual)}`}
                      </Typography>
                    )}
                  </>
                ) : (
                  <Tooltip
                    placement="top-start"
                    className={`rounded-[4px] bg-white p-0 text-black-800 shadow-xl`}
                    content={
                      (rowData.errors.length || rowData.warnings.length) && (
                        <div className="flex flex-col px-3 py-2">
                          <Typography className="text-error" variant="small">
                            Errors:
                          </Typography>
                          <ul className="list-disc space-y-1 pl-7">
                            {rowData?.errors?.map((err, index) => {
                              return (
                                <li key={index}>
                                  <Typography data-autoid={`txtErrorMessage-${index}`} variant="small">
                                    {`${err?.description
                                      ?.replace('${ColumnName}', err?.columnName)
                                      ?.replace('${Actual}', err?.actual)}`}
                                  </Typography>
                                </li>
                              );
                            })}
                          </ul>
                          <Typography className="text-warning" variant="small">
                            Warnings:
                          </Typography>
                          <ul className="list-disc space-y-1 pl-7">
                            {rowData?.warnings?.map((war, index) => {
                              return (
                                <li key={index}>
                                  <Typography data-autoid={`txtErrorMessage-${index}`} variant="small">
                                    {`${war?.description
                                      ?.replace('${ColumnName}', war?.columnName)
                                      ?.replace('{Actual}', war?.actual)}`}
                                  </Typography>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )
                    }
                  >
                    <div className={`flex cursor-pointer flex-row`}>
                      <Typography data-autoid={`txtErrorMessage-${rowIndex}`} className="text-error">
                        {`${rowData.errors.length} ${rowData.errors.length > 1 ? 'errors, ' : 'error, '}`}
                      </Typography>
                      <Typography data-autoid={`txtWarningMessage-${rowIndex}`} className="ml-1 text-warning ">
                        {`${rowData.warnings.length} ${rowData.warnings.length > 1 ? 'warnings' : 'warning'}`}
                      </Typography>
                    </div>
                  </Tooltip>
                )}
              </>
            )}
          </div>
        );
      },
    },
  ];

  const handleRowClick = (s: IStagingInventory) => setSelectedInventory(s);

  return (
    <div className="h-full w-full">
      <div className={`h-[calc(100vh-320px)] flex-grow`}>
        <ARTable
          isLoading={loading}
          rows={items}
          columns={columns}
          tableClass="!w-[calc(100%+3000px)] border-separate border-spacing-0"
          conatinerClass="!px-0"
        />
      </div>
      <div className="mt-4">
        <SPagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 15, 25]}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </div>
      {selectedInventory && (
        <CreateNewProductItemModal
          handleClose={() => {
            setSelectedInventory(undefined);
          }}
          stagingInventoryData={selectedInventory}
          hideActionButtons
        />
      )}
    </div>
  );
};
