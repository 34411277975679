export const AUTH_SELLING_COLLECT_ROUTE = '/selling/collect';
export const AUTH_SELLING_SETTINGS_ROUTE = '/selling/settings';
export const AUTH_SELLING_AR_PAYMENT_HISTORY_ROUTE = '/selling/payment-history';
export const AUTH_SELLING_AR_CLAIM_REQUESTS_ROUTE = '/selling/claim-request';
export const AUTH_SELLING_AR_PAID_INVOICES_ROUTE = '/selling/paid-invoices';
export const AUTH_SELLING_AR_EDIT_CLAIM_REQUESTS_ROUTE = '/selling/claim-request/:accountCustomerId/edit';
export const AUTH_SELLING_AR_CREATE_CLAIM_REQUESTS_ROUTE = '/selling/claim-request/:accountCustomerId/create/';
export const AUTH_SUPPLIER_ROUTE = '/supplier';
export const AUTH_PAYBYLINKINVOICE_ROUTE = '/invoices/pibl/:magicLink';
export const AUTH_MENU = '/menu';
export const AUTH_TENANT_SETUP = '/tenant/setup';
export const AUTH_MERCHANT_PORTAL_ROUTE = '/merchant-portal';
export const AUTH_TRADE_ACCOUNT_ROUTE = '/trade-account';

// Onboarding Routes
export const AUTH_ONBOARING_ROUTE = '/onboarding';
export const AUTH_ACCOUNTSPAYABLE_ONBOARING_ROUTE = '/pay/onboarding';

// Onboarding v1 Routes
export const AUTH_ACCOUNTSPAYABLE_ONBOARDING_V1_ROUTE = '/pay/onboardingv1';

// Module Info Routes
export const AUTH_ACCOUNTSPAYABLE_MODULEINFO_ROUTE = '/module-info/accounts-payable';

// Referral
export const AUTH_REFERRAL_INVITE = '/invite/referral';

// Authorise Payment
export const AUTH_PAYMENT_AUTHORISATION = '/payment-authorisation/:code';

export const AUTH_MARKETPLACE_RESERVED_ROUTES = [
  'bills',
  'order',
  'payment-history',
  'prepayment',
  'payment-options',
  'claims',
  'create-claim',
  'edit-claim',
  'paid-invoices',
];
