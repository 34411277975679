/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from 'react';
import {Form, Formik, FormikHelpers, getIn, validateYupSchema, yupToFormErrors} from 'formik';
import {Link, useHistory, useParams} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import {Layout} from '../../components/layout/Layout';
import backButton from '../../assets/png/back-button.png';
import {STextField} from '../../components/inputs/STextField';
import {SwitchSlider} from '../../components/form/SwitchSlider';
import PostingInventoryIcon from '../../assets/svg/accounts-payable/PostingInventoryIcon';
import {ARTable} from '../../components/AccountsReceivable/ARTable';
import {APFooter} from '../../components/accountsPayableOnboarding/APFooter';
import {AUTH_BUYING_EDIT_SUPPLIER_ROUTE, AUTH_BUYING_SUPPLIERS} from '../../routes/SupplierManagementRoutes';
import {LocationAutoComplete} from '../../components/location/LocationAutoComplete';
import {IAddress} from '../../model/address/Address';
import {useLocationAPI} from '../../services/useLocationAPI';
import {ISupplier, IVendorPostingRules} from '../../model/supplier/Supplier';
import {useSupplierAPI} from '../../services/useSupplierAPI';
import {Toast} from '../../utils/Toast';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import APTooltip from '../../components/data-display/APTootip';
import blueInfoIcon from '../../assets/svg/accounts-payable/blue-info-icon.svg';
import {SAutocomplete} from '../../components/inputs/SAutocomplete';
import {Inventory} from '../../model/inventory/Inventory';
import {useInventoryAPI} from '../../services/useInventoryAPI';
import {SuggestionSelectedEventData} from 'react-autosuggest';
import {IPagedActionResults} from '../../model/ActionResults';
import AppContext from '../../context/app/appContext';
import _ from 'lodash';
import {ISupplierMarket} from '../../model/SupplierMarket';
import {supplierValidationSchema} from '../../utils/supplierValidation';
import {SelectPostingInventoryModal} from '../../components/accountsPayableOnboarding/SelectPostingInventoryModal';
import {CreditlineModal} from '../../components/accountsPayableOnboarding/CreditlineModal';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';

interface ISupplierValues extends Partial<ISupplier> {}

const initSupplierDetails: ISupplierValues = {
  ABN: '',
  Name: '',
  Phone1: '',
  RefNumber: '',
  Locations: [
    {
      EmailAddress: '',
      Addresses: [
        {
          ID: null,
          StreetAddress: '',
          City: '',
          State: '',
          PostCode: '',
          Country: '',
        },
      ],
      IsActive: true,
      IsDefaultLocation: false,
    },
  ],
  Contacts: [
    {
      IsPrimaryContact: true,
      PhoneMobile: '',
      FirstName: '',
      LastName: '',
      EmailAddress: '',
      IsActive: true,
    },
  ],
  PostingInventoryID: null,
  IsAutomaticInvoiceImportEnabled: false,
  IsVendorMappingEnabled: false,
  VendorPostingRules: [],
  IsARBatchImportEnabled: false,
  CreditLineAccountID: null,
  PostingSupplierID: null,
};

const useSupplierDetailsStyle = makeStyles({
  BSBTextField: {
    '&.textfieldContainer': {
      width: '100%',
      margin: '0px',
      padding: '0px',
      background: '#fafafa',
      '& .MuiFormControl-fullWidth': {
        padding: '0px',
        position: 'relative',
        '&:first-child': {
          '&::after': {
            content: "''",
            position: 'absolute',
            height: '100%',
            width: '1px',
            backgroundColor: '#444343',
            right: '0',
            top: '0px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderRight: '0px',
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '6px 0px 0px 6px',
          },
        },
        '&:last-child': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderLeft: '0px',
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '0px 6px 6px 0px',
          },
        },
      },
    },
  },
  icon: {
    borderRadius: 4,
    width: 16,
    height: 16,
    margin: '2px',
    border: '1px solid #1C78AD',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  switchSlider: {
    '& .MuiFormControlLabel-root': {
      width: 'auto',
    },
  },
});

export const SupplierDetails = () => {
  const classes = useSupplierDetailsStyle();
  const history = useHistory();
  const {tenantInfo, setMarketplaceSupplier, setConnectedSuppliers, marketplaceSupplier, suppliers} =
    useContext(AppContext);
  const {getLocationAddress} = useLocationAPI();
  const {saveSupplier, getSupplierById, isLoading} = useSupplierAPI();
  const {supplierId} = useParams<{supplierId: string}>();
  const [supplierDetails, setSupplierDetails] = useState<ISupplierValues>(initSupplierDetails);
  const [suggestions, setSuggestions] = useState<Partial<Inventory>[]>([]);
  const {isLoading: isInventoryLoading, listInventory} = useInventoryAPI();
  const [selectedPostingInventory, setSelectedPostingInventory] = useState<Partial<Inventory>>({});
  const [oldAutomaticImportSetting, setOldAutomaticImportSetting] = useState<boolean>(false);
  const [expenseAccountError, setExpenseAccountError] = useState<string>('');
  const [primaryContactIndex, setPrimaryContactIndex] = useState<number>(0);
  const {T2TPhase280729} = useFeatureFlags().tenantOwned();
  const [selectPostingInventoryModal, setSelectPostingInventoryModal] = useState<boolean>(false);
  const [isCreditlineAlreadySet, setIsCreditlineAlreadySet] = useState<boolean>(false);

  const updateSupplierGobalContext = (importInvoices: boolean = false, supplierId: number) => {
    const connectedSuppliers = _.cloneDeep(suppliers);
    const suppIndex = connectedSuppliers.findIndex(s => s.SupplierID === supplierId);
    if (suppIndex !== -1) {
      connectedSuppliers[suppIndex].IsAutomaticInvoiceImportSetup = true;
      connectedSuppliers[suppIndex].IsAutomaticInvoiceImportEnabled = importInvoices;
      if (marketplaceSupplier?.SupplierID === supplierId) {
        setMarketplaceSupplier(connectedSuppliers[suppIndex] as ISupplierMarket);
      }
      setConnectedSuppliers(connectedSuppliers);
    }
  };

  const onSubmit = async (values: ISupplierValues, helpers: FormikHelpers<ISupplierValues>): Promise<void> => {
    if (values.ID && values.PostingInventoryID === null) {
      values.PostingInventoryID = '-1';
    }
    if (values.IsAutomaticInvoiceImportEnabled && !values.IsAutomaticInvoiceImportSetup) {
      values.IsAutomaticInvoiceImportSetup = true;
    }
    return saveSupplier(values).then(res => {
      if (res) {
        helpers.resetForm();
        Toast.info(`Supplier ${values.ID ? 'updated' : 'created'}`);
        if (values.ID && oldAutomaticImportSetting !== values.IsAutomaticInvoiceImportEnabled)
          updateSupplierGobalContext(values.IsAutomaticInvoiceImportEnabled, values.ID);
        history.push(`${AUTH_BUYING_SUPPLIERS}`);
      }
    });
  };

  useEffect(() => {
    // get supplier by ID
    if (supplierId) {
      getSupplierById(supplierId).then(res => {
        res.ABN = res?.ABN?.replace(/ /g, '');
        // get Primary Contact index
        if (res?.Contacts && res?.Contacts.length) {
          const primaryContactIndex = res.Contacts.findIndex(contact => contact.IsPrimaryContact);
          if (!res?.Contacts?.[primaryContactIndex]?.PhoneMobile) {
            res.Contacts[primaryContactIndex].PhoneMobile = '';
          }
          if (!res?.Contacts?.[primaryContactIndex]?.EmailAddress) {
            res.Contacts[primaryContactIndex].EmailAddress = '';
          }
        }
        if (
          res?.Locations &&
          res?.Locations?.length &&
          res?.Locations?.[0].Addresses &&
          res?.Locations?.[0].Addresses.length &&
          !res?.Locations?.[0].Addresses?.[0]?.PostCode
        ) {
          res.Locations[0].Addresses[0].PostCode = '';
          res.Locations[0].Addresses[0].Country = '';
        }
        if (res?.Locations && res?.Locations.length && !res?.Locations?.[0]?.EmailAddress) {
          res.Locations[0].EmailAddress = '';
        }
        if (res.PostingInventoryID && res.PostingInventoryID != null) {
          getInventoryList('', true, res.PostingInventoryID!);
        }
        setOldAutomaticImportSetting(res.IsAutomaticInvoiceImportEnabled || false);
        res?.CreditLineAccountID && setIsCreditlineAlreadySet(true);
        setPrimaryContactIndex(primaryContactIndex);
        setSupplierDetails(res);
      });
    }
  }, [supplierId]);

  const getInventoryList = (
    newValue: string = '',
    setPostingInventory: boolean = false,
    postingInventoryID?: string | undefined,
  ) => {
    listInventory({
      MaxResults: 15,
      ...{isPurchased: true, isSystem: false},
      ...(newValue ? {Search: newValue} : {}),
    }).then((res: IPagedActionResults<Inventory[]>) => {
      setSuggestions(res?.Value || []);
      if (setPostingInventory && supplierDetails) {
        const val = res?.Value?.find(item => item.ID === postingInventoryID);
        if (val) setSelectedPostingInventory(val);
      }
    });
  };

  useEffect(() => {
    if (
      selectedPostingInventory &&
      selectedPostingInventory.InventoryCode &&
      !selectedPostingInventory.ExpenseAccount
    ) {
      setExpenseAccountError("Set Expense Code, else your batch won't import to Financial");
    } else if (
      !selectedPostingInventory.InventoryCode &&
      !tenantInfo?.TenantUserDetails?.DefaultGenericLineItemInventoryExpenseAccountCode
    )
      setExpenseAccountError("Set Expense Code, else your batch won't import to Financial");
    else {
      setExpenseAccountError('');
    }
  }, [selectedPostingInventory]);

  const columns = [
    {
      title: 'Vendors',
      key: 'Vendors',
      width: '30%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5 bg-white',
      isSortable: false,
      rowRenderer: (postingRule: IVendorPostingRules) => (
        <Link
          to={AUTH_BUYING_EDIT_SUPPLIER_ROUTE.replace(':supplierId', postingRule?.PostingSupplierID?.toString()!)}
          data-autoid={`lnkSuppliers`}
          className="flex cursor-pointer items-center overflow-ellipsis font-poppins text-base font-medium text-primary underline"
        >
          <span
            data-autoid={`lblSuppliers`}
            className="flex cursor-pointer items-center overflow-ellipsis font-poppins text-base font-medium text-primary underline"
          >
            {postingRule?.VendorName || '-'}
          </span>
        </Link>
      ),
    },
    {
      title: 'Inventory code',
      key: 'Inventory code',
      width: '32%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      rowRenderer: (postingRule: IVendorPostingRules) => (
        <span
          data-autoid={`lblInventoryCode`}
          className="cursor-pointer font-poppins text-base font-semibold text-primary underline"
          onClick={() => setSelectPostingInventoryModal(true)}
        >
          {postingRule?.PostingInventoryCode || 'Spenda generic'}
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'Action',
      width: '38%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5 bg-white',
      rowRenderer: (postingRule: IVendorPostingRules) => (
        <span
          data-autoid={`lblActionTypeLink`}
          className={`cursor-pointer font-poppins text-base font-semibold text-primary`}
        >
          {`Posted as ${postingRule?.PostingSupplierName}`}
        </span>
      ),
    },
  ];
  const leftPanel = (
    <div className="invoicesList shadow-[0px_0px_6px_0px_#D3E5EF'] flex h-screen w-full flex-col rounded bg-white p-2.5 pb-10 font-poppins">
      <Formik
        validateOnBlur
        initialValues={supplierDetails}
        onSubmit={onSubmit}
        validate={async value => {
          try {
            await validateYupSchema(value, supplierValidationSchema, false, value);
          } catch (err) {
            return yupToFormErrors(err); //for rendering validation errors
          }
          return {};
        }}
        enableReinitialize
        validateOnMount
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          handleReset,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit} className={`relative h-full`}>
              <div className="mb-5 flex items-center justify-start border-[#D8D8D8] border-b-default pb-2.5 text-xl text-spenda-primarytext">
                <span
                  className="cursor-pointer"
                  data-autoid="btnBack"
                  onClick={() => {
                    history.push(AUTH_BUYING_SUPPLIERS);
                  }}
                >
                  <img
                    src={backButton}
                    alt="Back Button"
                    style={{width: '40px', height: '40px', margin: '0px 10px 0px 0px'}}
                    data-autoid={`imgBack`}
                  />
                </span>
                Supplier details
              </div>
              <div className="flex">
                <div className="w-[55%] overflow-y-auto pr-4" style={{height: 'calc(100vh - 280px)'}}>
                  <div>
                    <div className="">
                      <h2 className="mb-4 font-medium text-spenda-primarytext">Company details</h2>
                      <div>
                        <div className="mb-5 flex w-full">
                          <div className="mr-2 w-6/12">
                            <STextField
                              v2
                              id="Name"
                              name="Name"
                              placeholder="Company name"
                              label="Company name"
                              value={values.Name}
                              helperText={getIn(touched, 'Name') && getIn(errors, 'Name')}
                              error={getIn(touched, 'Name') && Boolean(getIn(errors, 'Name'))}
                              autoComplete="off"
                              onChange={handleChange}
                              fullWidth
                            />
                          </div>
                          <div className="ml-2 w-6/12">
                            <STextField
                              v2
                              id="ABN"
                              name="ABN"
                              placeholder="ABN"
                              label="Australian business number"
                              value={values.ABN}
                              helperText={getIn(touched, 'ABN') && getIn(errors, 'ABN')}
                              error={getIn(touched, 'ABN') && Boolean(getIn(errors, 'ABN'))}
                              autoComplete="off"
                              onChange={handleChange}
                              fullWidth
                            />
                          </div>
                        </div>
                        <div className="mb-5 flex w-full">
                          <div className="mr-2 w-6/12">
                            <STextField
                              v2
                              id="Phone1"
                              name="Phone1"
                              label="Company phone number"
                              placeholder="Company phone number"
                              value={values.Phone1}
                              helperText={getIn(touched, 'Phone1') && getIn(errors, 'Phone1')}
                              error={getIn(touched, 'Phone1') && Boolean(getIn(errors, 'Phone1'))}
                              autoComplete="off"
                              onChange={e => handleChange(e)}
                              fullWidth
                            />
                          </div>
                          <div className="ml-2 w-6/12">
                            <STextField
                              v2
                              id="Locations.0.EmailAddress"
                              name="Locations.0.EmailAddress"
                              label="Company email"
                              value={values.Locations?.[0]?.EmailAddress}
                              placeholder="Company email"
                              helperText={
                                getIn(touched, 'Locations.0.EmailAddress') && getIn(errors, 'Locations.0.EmailAddress')
                              }
                              error={
                                getIn(touched, 'Locations.0.EmailAddress') &&
                                Boolean(getIn(errors, 'Locations.0.EmailAddress'))
                              }
                              autoComplete="off"
                              onChange={e => handleChange(e)}
                              fullWidth
                            />
                          </div>
                        </div>
                        <div className="mb-5 flex w-full">
                          <div className="mr-2 w-6/12 pr-2">
                            <STextField
                              v2
                              id="WebSiteURL"
                              name="WebSiteURL"
                              label="Company website"
                              placeholder="Company website"
                              value={values.WebSiteURL}
                              helperText={getIn(touched, 'WebSiteURL') && getIn(errors, 'WebSiteURL')}
                              error={getIn(touched, 'WebSiteURL') && Boolean(getIn(errors, 'WebSiteURL'))}
                              autoComplete="off"
                              onChange={e => handleChange(e)}
                              fullWidth
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pt-7">
                      <h2 className="mb-4 font-medium text-spenda-primarytext">Primary details</h2>
                      <div>
                        <div className="mb-5 flex w-full">
                          <div className="mr-2 w-6/12">
                            <STextField
                              v2
                              fullWidth
                              id={`Contacts.${primaryContactIndex}.FirstName`}
                              name={`Contacts.${primaryContactIndex}.FirstName`}
                              variant="outlined"
                              label="First name"
                              placeholder="First name"
                              value={values.Contacts?.[primaryContactIndex]?.FirstName}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              helperText={
                                getIn(touched, `Contacts.${primaryContactIndex}.FirstName`) &&
                                getIn(errors, `Contacts.${primaryContactIndex}.FirstName`)
                              }
                              error={
                                getIn(touched, `Contacts.${primaryContactIndex}.FirstName`) &&
                                Boolean(getIn(errors, `Contacts.${primaryContactIndex}.FirstName`))
                              }
                            />
                          </div>
                          <div className="ml-2 w-6/12">
                            <STextField
                              v2
                              fullWidth
                              id="Contacts.0.LastName"
                              name="Contacts.0.LastName"
                              variant="outlined"
                              label="Last name"
                              placeholder="Last name"
                              value={values.Contacts?.[0]?.LastName}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              helperText={getIn(touched, 'Contacts.0.LastName') && getIn(errors, 'Contacts.0.LastName')}
                              error={
                                getIn(touched, 'Contacts.0.LastName') && Boolean(getIn(errors, 'Contacts.0.LastName'))
                              }
                            />
                          </div>
                        </div>
                        <div className="mb-5 flex w-full">
                          <div className="mr-2 w-6/12">
                            <STextField
                              v2
                              fullWidth
                              id="Contacts.0.PhoneMobile"
                              name="Contacts.0.PhoneMobile"
                              variant="outlined"
                              label="Phone"
                              placeholder="Phone"
                              value={values.Contacts?.[0]?.PhoneMobile}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              helperText={
                                getIn(touched, 'Contacts.0.PhoneMobile') && getIn(errors, 'Contacts.0.PhoneMobile')
                              }
                              error={
                                getIn(touched, 'Contacts.0.PhoneMobile') &&
                                Boolean(getIn(errors, 'Contacts.0.PhoneMobile'))
                              }
                            />
                          </div>
                          <div className="ml-2 w-6/12">
                            <STextField
                              v2
                              fullWidth
                              id="Contacts.0.EmailAddress"
                              name="Contacts.0.EmailAddress"
                              variant="outlined"
                              label="Email"
                              placeholder="Email"
                              value={values.Contacts?.[0]?.EmailAddress}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              helperText={
                                getIn(touched, 'Contacts.0.EmailAddress') && getIn(errors, 'Contacts.0.EmailAddress')
                              }
                              error={
                                getIn(touched, 'Contacts.0.EmailAddress') &&
                                Boolean(getIn(errors, 'Contacts.0.EmailAddress'))
                              }
                              className="col-span-2"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full pt-7">
                      <h2 className="mb-4 font-medium text-spenda-primarytext">Account details</h2>
                      <div className="mb-5 flex w-full">
                        <div className="mr-2 w-6/12">
                          <STextField
                            v2
                            name="accountName"
                            label="Account name"
                            value={values?.BankAccount?.AccountName}
                            disabled
                            autoComplete="off"
                            onChange={e => handleChange(e)}
                            inputProps={{
                              'data-autoid': `txtAccountName`,
                            }}
                            fullWidth
                          />
                        </div>
                        <div className="ml-2 w-6/12">
                          <div
                            className={`textfieldContainer flex flex-row items-center justify-center ${classes.BSBTextField}`}
                          >
                            <STextField
                              v2
                              fullWidth
                              id="bsb"
                              name="BSB"
                              label="BSB"
                              inputMode="numeric"
                              type="number"
                              disabled
                              value={values?.BankAccount?.BSB}
                              onChange={e => handleChange(e)}
                              inputProps={{
                                'data-autoid': `txtBsb`,
                              }}
                            />
                            <STextField
                              v2
                              fullWidth
                              id="accNumber"
                              name="accountNumber"
                              type="number"
                              disabled
                              label="Acc number"
                              value={values?.BankAccount?.AccountNumber}
                              onChange={e => handleChange(e)}
                              inputMode="numeric"
                              inputProps={{
                                'data-autoid': `txtAccNo`,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pt-7">
                      <h2 className="mb-4 font-medium text-spenda-primarytext">Billing address</h2>
                      <div>
                        <div className="mb-5 flex w-full">
                          <div className="w-full">
                            <LocationAutoComplete
                              isT2TSupplierModule={true}
                              carouselUI={false}
                              styles={{marginTop: '0rem'}}
                              selected={
                                values?.Locations?.[0]?.Addresses?.[0]?.StreetAddress ||
                                values?.Locations?.[0]?.Addresses?.[0]?.City ||
                                values?.Locations?.[0]?.Addresses?.[0]?.State ||
                                values?.Locations?.[0]?.Addresses?.[0]?.Country ||
                                values?.Locations?.[0]?.Addresses?.[0]?.PostCode
                                  ? {
                                      PlaceDescription: `${
                                        values?.Locations?.[0]?.Addresses?.[0]?.StreetAddress ?? ''
                                      } ${values?.Locations?.[0]?.Addresses?.[0]?.City ?? ''} ${
                                        values?.Locations?.[0]?.Addresses?.[0]?.State ?? ''
                                      } ${values?.Locations?.[0]?.Addresses?.[0]?.PostCode ?? ''} ${
                                        values?.Locations?.[0]?.Addresses?.[0]?.Country ?? ''
                                      }`,
                                    }
                                  : null
                              }
                              clearClick={() =>
                                setFieldValue(`Locations.0.Addresses.0`, {
                                  ID: values?.Locations?.[0]?.Addresses?.[0]?.ID || null,
                                  StreetAddress: '',
                                  City: '',
                                  State: '',
                                  Country: '',
                                  PostCode: '',
                                })
                              }
                              onSuggestionSelected={(e, selected) => {
                                getLocationAddress(selected.suggestion.PlaceID || '').then((res: IAddress) => {
                                  if (res) {
                                    setFieldValue(`Locations.0.Addresses.0`, {
                                      ID: values?.Locations?.[0]?.Addresses?.[0]?.ID || null,
                                      StreetAddress: res.StreetAddress || '',
                                      City: res.City || '',
                                      State: res.State || '',
                                      Country: res.Country || '',
                                      PostCode: res.PostCode || '',
                                    });
                                  }
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="mb-5 flex w-full">
                          <div className="mr-2 w-6/12">
                            <STextField
                              v2
                              id="Locations.0.Addresses.0.StreetAddress"
                              label="Street address"
                              name={`Locations.0.Addresses.0.StreetAddress`}
                              value={values?.Locations?.[0]?.Addresses?.[0]?.StreetAddress || ''}
                              onChange={handleChange}
                              fullWidth
                            />
                          </div>
                          <div className="ml-2 w-6/12">
                            <STextField
                              v2
                              id="Locations.0.Addresses.0.City"
                              label="City"
                              name={`Locations.0.Addresses.0.City`}
                              value={values?.Locations?.[0]?.Addresses?.[0]?.City || ''}
                              onChange={handleChange}
                              fullWidth
                            />
                          </div>
                        </div>
                        <div className="mb-5 flex w-full">
                          <div className="mr-2 w-6/12">
                            <STextField
                              v2
                              label="State"
                              id={`Locations.0.Addresses.0.State`}
                              name={`Locations.0.Addresses.0.State`}
                              key={`Locations.0.Addresses.0.State`}
                              value={values?.Locations?.[0]?.Addresses?.[0]?.State || ''}
                              onChange={handleChange}
                              fullWidth
                            />
                          </div>
                          <div className="ml-2 w-6/12">
                            <STextField
                              v2
                              label="Country"
                              id={`Locations.0.Addresses.0.Country`}
                              name={`Locations.0.Addresses.0.Country`}
                              key={`Locations.0.Addresses.0.Country`}
                              value={values?.Locations?.[0]?.Addresses?.[0]?.Country || ''}
                              onChange={handleChange}
                              helperText={
                                getIn(touched, 'Locations.0.Addresses.0.Country') &&
                                getIn(errors, 'Locations.0.Addresses.0.Country')
                              }
                              error={
                                getIn(touched, 'Locations.0.Addresses.0.Country') &&
                                Boolean(getIn(errors, 'Locations.0.Addresses.0.Country'))
                              }
                              autoComplete="off"
                              fullWidth
                            />
                          </div>
                        </div>
                        <div className="mb-5 flex w-full">
                          <div className="mr-2 w-6/12 pr-2">
                            <STextField
                              v2
                              label="PostCode"
                              id={`Locations.0.Addresses.0.PostCode`}
                              name={`Locations.0.Addresses.0.PostCode`}
                              key={`Locations.0.Addresses.0.PostCode`}
                              value={values?.Locations?.[0]?.Addresses?.[0]?.PostCode || ''}
                              onChange={handleChange}
                              helperText={
                                getIn(touched, 'Locations.0.Addresses.0.PostCode') &&
                                getIn(errors, 'Locations.0.Addresses.0.PostCode')
                              }
                              error={
                                getIn(touched, 'Locations.0.Addresses.0.PostCode') &&
                                Boolean(getIn(errors, 'Locations.0.Addresses.0.PostCode'))
                              }
                              autoComplete="off"
                              fullWidth
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[45%] overflow-y-auto pl-5" style={{height: 'calc(100vh - 280px)'}}>
                  <div className="rounded-[10px] bg-[rgba(227,238,245,0.3)] pb-5">
                    <h4 className="mb-4 border-[#D8D8D8] border-b-default px-5 py-4 font-medium">Additional details</h4>
                    <div className="px-5 pb-5">
                      <div className={`flex items-center justify-between ${classes.switchSlider}`}>
                        <span className="text-xs">Automatically import batches from this supplier.</span>
                        <SwitchSlider
                          label=""
                          checked={values?.IsARBatchImportEnabled || false}
                          disabled={!values?.IsLinked || !values.LinkedTenantGlobalID}
                          name="autoImportBatches"
                          dataAutoId={'lblAutoBatches'}
                          handleChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                            setFieldValue('IsARBatchImportEnabled', checked);
                          }}
                          labelPlacement="start"
                        />
                      </div>
                      <div className={`flex items-center justify-between ${classes.switchSlider}`}>
                        <span className="text-xs">Automatically import invoices from this supplier.</span>
                        <SwitchSlider
                          label=""
                          checked={values?.IsAutomaticInvoiceImportEnabled || false}
                          disabled={!values?.IsLinked || !values.LinkedTenantGlobalID}
                          name="autoImportInvoice"
                          dataAutoId={'lblAutoInvoice'}
                          handleChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                            setFieldValue('IsAutomaticInvoiceImportEnabled', checked);
                          }}
                          labelPlacement="start"
                        />
                      </div>
                      {values?.IsAutomaticInvoiceImportEnabled && (
                        <>
                          <div className="mt-7 flex items-center justify-between">
                            <div className="flex w-[230px]  flex-row">
                              <span className="min-w-[60%] text-xs">Select generic posting inventory:</span>
                              <APTooltip
                                arrow
                                placement="bottom"
                                title={
                                  values?.PostingSupplierID
                                    ? 'By default, the system will allocate a generic inventory. If you wish to make your own selection, please choose from the dropdown menu.'
                                    : 'Please select posting type of supplier first.'
                                }
                              >
                                <img className="ml-1 cursor-pointer" src={blueInfoIcon} data-autoid={`imgBlueInfo`} />
                              </APTooltip>
                            </div>
                            <div className="mr-1 flex flex-row items-center justify-center">
                              <SAutocomplete<Partial<Inventory>>
                                isT2TSupplierModule
                                disabled={!values.PostingSupplierID}
                                async
                                autoFocus={false}
                                styles={{marginTop: '0rem'}}
                                size={'small'}
                                loading={isInventoryLoading}
                                placeholder={'Spenda generic inventory'}
                                clearClick={() => {
                                  setFieldValue('PostingInventoryID', null);
                                  setSelectedPostingInventory({});
                                }}
                                suggestions={suggestions}
                                selected={
                                  values?.PostingInventoryID &&
                                  selectedPostingInventory.ID === values?.PostingInventoryID
                                    ? selectedPostingInventory
                                    : null
                                }
                                getSuggestions={getInventoryList}
                                renderSuggestion={(item: Partial<Inventory>) => {
                                  return (
                                    <p className={'cursor-pointer font-poppins text-spenda-primarytext'}>
                                      {item.InventoryCode} {item.ShortDescription ? item.ShortDescription : ''}
                                    </p>
                                  );
                                }}
                                getSuggestionValue={(suggestion: Partial<Inventory>) => {
                                  return `${suggestion.InventoryCode} ${
                                    suggestion.ShortDescription ? suggestion.ShortDescription : ''
                                  }`;
                                }}
                                onSearchChange={(value: string) => {
                                  getInventoryList(value);
                                }}
                                onSuggestionSelected={(
                                  event: React.FormEvent<any>,
                                  _selected: SuggestionSelectedEventData<Partial<Inventory>>,
                                ) => {
                                  setFieldValue('PostingInventoryID', _selected.suggestion.ID);
                                  setSuggestions([_selected.suggestion]);
                                  setSelectedPostingInventory(_selected.suggestion);
                                }}
                              />
                              <PostingInventoryIcon
                                className={`ml-2.5 ${values?.PostingSupplierID && 'cursor-pointer'}`}
                                data-autoid={'imgPostingInventory'}
                                onClick={() => {
                                  if (!values.PostingSupplierID) return;
                                  if (values.PostingInventoryID === '-1' || values?.PostingInventoryID === null) {
                                    tenantInfo?.TenantUserDetails?.DefaultGenericLineItemInventoryId &&
                                      history.push(
                                        `/inventory/products/edit/${tenantInfo?.TenantUserDetails?.DefaultGenericLineItemInventoryId}?tab=1`,
                                      );
                                  } else {
                                    history.push(`/inventory/products/edit/${values.PostingInventoryID}`);
                                  }
                                }}
                              />
                            </div>
                          </div>
                          {expenseAccountError && (
                            <span className="flex justify-end pt-2 font-poppins text-xs text-spenda-error">
                              {expenseAccountError}
                            </span>
                          )}
                        </>
                      )}
                    </div>
                    {values?.ID && values?.IsLinked && (
                      <>
                        <div className="border-[#D8D8D8] border-t-default px-5 pt-5 ">
                          <div className={`mb-7 flex items-center justify-between ${classes.switchSlider}`}>
                            <span className="text-xs">Turn on vendor mapping</span>
                            <SwitchSlider
                              label=""
                              checked={values.IsVendorMappingEnabled || false}
                              dataAutoId={'lblVendorMapping'}
                              name="vendorMapping"
                              handleChange={() =>
                                setFieldValue('IsVendorMappingEnabled', !values.IsVendorMappingEnabled)
                              }
                              labelPlacement="start"
                            />
                          </div>
                          {values.IsVendorMappingEnabled && (
                            <div className="max-h-[200px] overflow-y-auto">
                              <ARTable
                                tableClass="mb-0 !mx-0"
                                columns={columns}
                                rows={values?.VendorPostingRules || []}
                                isLoading={false}
                                scope="AP"
                              />
                            </div>
                          )}
                        </div>
                        {T2TPhase280729 && values.IsVendorMappingEnabled && (
                          <div className="border-[#D8D8D8] border-t-default px-5">
                            <CreditlineModal
                              fromSupplierDetails
                              selectedCreditLineAccountID={isCreditlineAlreadySet ? values.CreditLineAccountID : null}
                              setCreditLineAccountID={(value: number | null) =>
                                setFieldValue('CreditLineAccountID', value)
                              }
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <APFooter
                buttonLabel="Save"
                isDisabled={isSubmitting}
                isCancelButton={true}
                secondaryButtonLabel="Reset"
                isT2TSupplierModule={true}
                handleCancelClick={handleReset}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
  return (
    <div>
      <LoadingIndicator isLoading={isLoading} size="md" color="hsl(var(--primary))" />
      <Layout leftPanel={leftPanel} splitWidthType={4} />
      {selectPostingInventoryModal && (
        <SelectPostingInventoryModal
          isVendorSelected={selectPostingInventoryModal}
          handleClose={() => setSelectPostingInventoryModal(false)}
          handleDone={() => setSelectPostingInventoryModal(false)}
          scope="AP"
        />
      )}
    </div>
  );
};
