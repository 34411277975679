import React, {useEffect, useState, useContext, useMemo} from 'react';
import {Layout} from '../../components/layout/Layout';
import {css} from 'glamor';
import {
  PurchaseInvoiceRequestedList,
  PaymentStatusType,
  ViewTypes,
  PurchaseInvoiceViewTypes,
  SupplierViewSortingTypes,
  TimelineCustomRange,
  PaymentBatchStatusTypes,
  PaymentBatchPermissionType,
} from '../../model/constants/Constants';
import {
  IPurchaseInvoice,
  ISupplierSummaries,
  ISupplier,
  IPurchaseInvoiceSummaries,
  IBatchReviewPurchaseInvoice,
} from '../../model/purchase-invoice/purchaseInvoice';
import {AccountsPayableActivityHeader} from '../../components/ActivityHeader';
import {ISearchPurchaseInvoicesFilter} from '../../model/search-filters/SearchCustomerStatementsFilter';
import {useParams, useHistory} from 'react-router-dom';
import {IPagedActionResults} from '../../model/ActionResults';
import usePurchaseInvoiceAPI from '../../services/usePurchaseInvoiceAPI';
import _, {cloneDeep, omit} from 'lodash';
import {Toast} from '../../utils/Toast';
import {BatchCreate, CreateBatchValues} from '../../components/purchasing/BatchCreate';
import {APInvoiceSingleViewList} from './APInvoicesSingleViewList';
import {APInvoiceSupplierViewList} from './APInvoiceSupplierViewList';
import {APInvoiceTimelineViewList} from './APInvoiceTimelineViewList';
import {usePaymentBatchAPI} from '../../services/usePaymentBatchAPI';
import AppContext from '../../context/app/appContext';
import {AUTH_PURCHASING_PAY_BILL_EDIT_ROUTE, AUTH_PURCHASING_PAY_BILL_ROUTE} from '../../routes/AccountsPayableRoutes';
import {
  IBatchPaymentRequest,
  IDebitNote,
  IDebitNoteRequest,
  IPaymentBatch,
  IPaymentBatchCreditLine,
  IPurchaseInvoiceBatchActions,
  IPurchaseInvoiceRequest,
} from '../../model/payment-batch/PaymentBatch';
import {checkDueDate} from '../../utils/timelineViewValidator';
import moment from 'moment';
import APContext from '../../context/ap-context/APContext';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {IntegrationContext} from '../../context/IntegrationContext';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';

const newBatch = {
  batchName: '',
  paymentDate: new Date().toString(),
  invoices: [],
  debitNotes: [],
} as CreateBatchValues;

const height = css({
  borderRadius: '0.25rem',
});

const leftGrid = css({
  display: 'grid',
  gridTemplateColumns: 'auto ',
  gridTemplateRows: 'minmax(50px,max-content) auto',
  gridTemplateAreas: `"spendaPayActivityHeader"
     "invoicesList"`,
});

const apActivityHead = css({
  padding: '10px',
});

export const Receive = () => {
  const history = useHistory();
  const requestedList = PurchaseInvoiceRequestedList.Payable;
  const {batchID} = useParams<{batchID: string}>();
  const purchaseInvoiceAPI = usePurchaseInvoiceAPI();
  const [batchDetails, setBatchDetails] = useState<CreateBatchValues>(newBatch);
  const {
    userRoleBatchPermissions,
    defaultTimelineGroupsFilter,
    setUserDefaultViewSettings,
    defaultSearchStatusFilters,
  } = useContext(APContext);
  const {T2TPhase280729, scheduledPaymentsV2, t2TV3} = useFeatureFlags().tenantOwned();

  const initialInsideSalesFilterState = {
    RequestedList: requestedList,
    StartRow: 1,
    SearchString: '',
    TimelineGroup: TimelineCustomRange.SevenToTwentyEightDays,
  } as ISearchPurchaseInvoicesFilter;
  const {tenant} = useContext(AppContext);
  const [filter, setFilter] = useState<ISearchPurchaseInvoicesFilter>(initialInsideSalesFilterState);
  const [isSearchingInvoices, setIsSearchingInvoices] = useState(false);
  const [selectedBucket, setSelectedBucket] = useState<PurchaseInvoiceRequestedList>(requestedList);
  const [viewMode, setViewMode] = useState<ViewTypes>(ViewTypes.List);
  const [selectedInvoices, setSelectedInvoices] = useState<any>({});
  const [suppliersList, setSuppliersList] = useState<Array<ISupplier>>([]);
  const [purchaseInvoicesList, setpurchaseInvoicesList] = useState<Array<IPurchaseInvoice>>([]);
  const [totals, setTotals] = useState<Partial<ISupplierSummaries> | undefined>();
  const [approvedInvoices, setApprovedInvoices] = useState<IBatchReviewPurchaseInvoice[]>([]);
  const [isConnectedSupplierSelected, setIsConnectedSupplierSelected] = useState<boolean>(false);
  const [selectedConnectedSupplierId, setSelectedConnectedSupplierId] = useState<number>();
  const [isUnlinkedSuppliersSelected, setIsUnlinkedSuppliersSelected] = useState<boolean>(false);
  const [pagedResultStatements, setPagedResultStatements] = useState<Partial<IPagedActionResults<ISupplierSummaries>>>(
    {},
  );
  const {
    getPaymentBatch,
    createPurchaseInvoicesBatch,
    updatePaymentBatch,
    updateUserRoleDefaultViewSettings,
    isLoading,
  } = usePaymentBatchAPI();
  const [totalPayable, setTotalPayable] = useState<number>(0);
  const [purchaseInvoiceViewMode, setPurchaseInvoiceViewMode] = useState<PurchaseInvoiceViewTypes>(
    PurchaseInvoiceViewTypes.TimelineView,
  );
  const [supplierViewMode, setSupplierViewMode] = useState<PurchaseInvoiceViewTypes>(
    PurchaseInvoiceViewTypes.SupplierView,
  );
  const [timelineViewMode, setTimelineViewMode] = useState<PurchaseInvoiceViewTypes>(
    PurchaseInvoiceViewTypes.TimelineView,
  );
  const [SupplierSortingViewMode, setSupplierSortingViewMode] = useState<SupplierViewSortingTypes>(
    SupplierViewSortingTypes.OverduePercentage,
  );
  const [timeLineCustomRangeMode, setTimeLineCustomRangeMode] = useState<TimelineCustomRange>(
    defaultTimelineGroupsFilter || TimelineCustomRange.SevenToTwentyEightDays,
  );
  const [initialBatchLoad, setInitialBatchLoad] = useState<boolean>(true);
  const [isSavingBatch, setIsSavingBatch] = useState<boolean>(false);
  const {isAPFinancialAdaptorSetup, financialAdaptor} = useContext(IntegrationContext);
  const [showDeselectOption, setShowDeselectOption] = useState<boolean>(false);
  const [showDraftList, setShowDraftList] = useState<boolean>(false);

  const isListMode: boolean = Boolean(viewMode === ViewTypes.List);

  const onClickBucket = (requestedListId: PurchaseInvoiceRequestedList) => {
    setSelectedBucket(requestedListId);
    setViewMode(ViewTypes.List);
    setPagedResultStatements({});
    setSuppliersList([]);
    setpurchaseInvoicesList([]);
    setFilter({
      ...filter,
      StartRow: 1,
      SearchString: undefined,
      RequestedList: requestedListId,
    });
  };

  const fetchSuppliers = async () => {
    setIsSearchingInvoices(true);
    let res: any;
    if (
      filter.RequestedList === PurchaseInvoiceRequestedList.Search ||
      (isListMode && purchaseInvoiceViewMode === PurchaseInvoiceViewTypes.SingleInvoiceView)
    ) {
      res = await purchaseInvoiceAPI.getPurchaseInvoicesList(filter);
    } else {
      res = await purchaseInvoiceAPI.search(filter);
    }

    if (!res || !res.Value) {
      if (filter.RequestedList === PurchaseInvoiceRequestedList.Search || isListMode) {
        setpurchaseInvoicesList([]);
      } else {
        setSuppliersList([]);
      }
      setIsSearchingInvoices(false);
      return;
    }

    if (
      filter.RequestedList === PurchaseInvoiceRequestedList.Search ||
      (isListMode && purchaseInvoiceViewMode === PurchaseInvoiceViewTypes.SingleInvoiceView)
    ) {
      setpurchaseInvoicesList(
        filter.StartRow === 1 ? res.Value.Results : [...purchaseInvoicesList, ...res.Value.Results],
      );
    } else {
      setSuppliersList(filter.StartRow === 1 ? res.Value.Results : [...suppliersList, ...res.Value.Results]);
    }

    setTotals(res.Value);
    setPagedResultStatements(res);
    setIsSearchingInvoices(false);
  };

  useEffect(() => {
    if (pagedResultStatements && pagedResultStatements?.MoreToGet === false) {
      return;
    }
    fetchSuppliers();
  }, [filter]);

  useEffect(() => {
    if (
      batchID &&
      initialBatchLoad &&
      ((suppliersList && suppliersList.length) ||
        purchaseInvoiceViewMode === PurchaseInvoiceViewTypes.SingleInvoiceView)
    ) {
      fetchBatchDetails();
      setInitialBatchLoad(false);
    }
  }, [batchID, suppliersList]);

  useEffect(() => {
    if (t2TV3) {
      if (!_.isEmpty(selectedInvoices)) {
        for (const [key] of Object.entries(selectedInvoices)) {
          const supplier = suppliersList.find(su => su.SupplierID === Number(key));
          const isSelected =
            selectedInvoices[key]?.purchaseInvoiceIds?.length || selectedInvoices[key]?.debitNotesIds?.length;
          if (supplier?.IsLinked && isSelected) {
            setIsConnectedSupplierSelected(true);
            setSelectedConnectedSupplierId(supplier.SupplierID);
            setShowDeselectOption(true);
          } else if (!supplier?.IsLinked && isSelected) {
            setIsUnlinkedSuppliersSelected(true);
            setShowDeselectOption(true);
          }
        }
      } else if (isConnectedSupplierSelected || isUnlinkedSuppliersSelected) {
        setIsConnectedSupplierSelected(false);
        setSelectedConnectedSupplierId(undefined);
        setIsUnlinkedSuppliersSelected(false);
        setShowDeselectOption(false);
      }
    }
  }, [selectedInvoices]);

  const updateBatchDetails = (batchDetails: IPaymentBatch) => {
    if (
      (batchDetails && batchDetails.name) ||
      (batchDetails.credits && batchDetails.credits.length) ||
      (batchDetails.debitNotes && batchDetails.debitNotes.length)
    ) {
      let isLinkedSupplier = false;
      let totalPayable = 0;
      const _selectedInvoices: any = {};
      let purchaseInvoices: IPurchaseInvoice[] = [];
      const approvedInvoices: IBatchReviewPurchaseInvoice[] = [];
      batchDetails.credits &&
        batchDetails.credits.forEach((pi: IPaymentBatchCreditLine) => {
          _selectedInvoices[pi.supplierID] = {
            purchaseInvoiceIds: [],
            debitNotesIds: [],
            allSelected: false,
          };
          let _selectedSupplier = _selectedInvoices[pi.supplierID];
          pi.purchaseInvoices &&
            pi.purchaseInvoices?.forEach((invoice: IBatchReviewPurchaseInvoice) => {
              if (batchDetails.status !== PaymentBatchStatusTypes.PaymentApproved && invoice.remnantBalance === 0) {
                invoice.SupplierName = pi.supplierName;
                invoice.SupplierId = pi.supplierID;
                approvedInvoices.push(invoice);
              }
              const amount = invoice.appliedAmount
                ? invoice.appliedAmount
                : invoice.remnantBalance && invoice.remnantBalance > 0
                  ? invoice.remnantBalance
                  : invoice.balance;

              if (_selectedSupplier) {
                _selectedSupplier.purchaseInvoiceIds = [
                  ...(_selectedSupplier.purchaseInvoiceIds || []),
                  invoice.purchaseInvoiceID,
                ];
                totalPayable += amount;
              } else {
                _selectedSupplier = {};
                _selectedSupplier.purchaseInvoiceIds = [invoice.purchaseInvoiceID];
                totalPayable += amount;
              }
              const supplierInvoices = suppliersList.find(supp => supp.SupplierID === pi.supplierID);
              const purchaseInv =
                supplierInvoices?.PurchaseInvoices?.find(inv => inv.TransID === invoice.purchaseInvoiceID) ||
                purchaseInvoicesList?.find(inv => inv.TransID === invoice.purchaseInvoiceID);
              if (!isLinkedSupplier) {
                isLinkedSupplier =
                  purchaseInv?.LinkedDocumentGUID !== '' && purchaseInv?.LinkedDocumentGUID !== null ? true : false;
              }
              if (purchaseInv) {
                purchaseInv.linkedBatches = invoice?.linkedBatches?.filter(
                  inv =>
                    inv.status === PaymentBatchStatusTypes.PaymentApproved ||
                    inv.status === PaymentBatchStatusTypes.Completed ||
                    inv.status === PaymentBatchStatusTypes.InProgress ||
                    inv.status === PaymentBatchStatusTypes.ProcessingDebit ||
                    inv.status === PaymentBatchStatusTypes.DebitSuccessful ||
                    inv.status === PaymentBatchStatusTypes.ProcessingCredit ||
                    inv.status === PaymentBatchStatusTypes.Incomplete,
                );
                purchaseInv.remnantBalance = invoice?.remnantBalance;
                purchaseInv.appliedAmount = Number(Number(invoice?.appliedAmount || 0)?.toFixed(2));
                purchaseInvoices.push(purchaseInv);
              }
              if (!t2TV3) {
                if (
                  _selectedSupplier &&
                  _selectedSupplier?.purchaseInvoiceIds?.length === supplierInvoices?.PurchaseInvoices?.length
                ) {
                  _selectedSupplier.allSelected = true;
                }
              } else {
                if (
                  _selectedSupplier &&
                  (_selectedSupplier?.purchaseInvoiceIds?.length || 0) ===
                    (supplierInvoices?.PurchaseInvoices?.length || 0) &&
                  (_selectedSupplier?.debitNotesIds?.length || 0) === (supplierInvoices?.DebitNotes?.length || 0)
                ) {
                  _selectedSupplier.allSelected = true;
                } else {
                  _selectedSupplier.allSelected = false;
                }
              }
            });
          _selectedInvoices[pi.supplierID] = _selectedSupplier;
        });

      batchDetails.debitNotes &&
        batchDetails.debitNotes.forEach((debitNote: IDebitNote) => {
          if (!isLinkedSupplier) {
            isLinkedSupplier = suppliersList.find(supp => supp.SupplierID === debitNote.supplierID)?.IsLinked || false;
          }
          let _selectedSupplier = _selectedInvoices[debitNote?.supplierID];
          if (_selectedSupplier) {
            _selectedSupplier.debitNotesIds = [...(_selectedSupplier.debitNotesIds || []), debitNote.debitNoteID];
          } else {
            _selectedSupplier = {};
            _selectedSupplier.debitNotesIds = [debitNote.debitNoteID];
          }
          if (debitNote.purchaseInvoices.length) {
            debitNote.purchaseInvoices.forEach((pi: IBatchReviewPurchaseInvoice) => {
              // check if pi is not in selectedInvoices, if not add in selectedInvoices
              if (!_selectedSupplier?.purchaseInvoiceIds?.includes(pi.purchaseInvoiceID)) {
                _selectedSupplier.purchaseInvoiceIds = [
                  ...(_selectedSupplier.purchaseInvoiceIds || []),
                  pi.purchaseInvoiceID,
                ];
                const inv = batchDetails?.purchaseInvoices?.find(i => i.purchaseInvoiceID === pi.purchaseInvoiceID);
                const purchaseInvoice: Partial<IPurchaseInvoice> = {
                  SupplierName: inv?.supplierName,
                  SourceSupplierName: inv?.sourceSupplierName,
                  RefNumber: inv?.refNumber,
                  remnantBalance: pi?.remnantBalance,
                  appliedAmount: pi?.appliedAmount,
                  Balance: inv?.balance,
                  TransID: inv?.purchaseInvoiceID,
                };
                purchaseInvoices.push(purchaseInvoice as IPurchaseInvoice);
              } else {
                const inv = purchaseInvoices?.findIndex(i => i.TransID === pi.purchaseInvoiceID);
                if (inv !== -1) {
                  purchaseInvoices[inv].appliedAmount = (purchaseInvoices[inv]?.appliedAmount || 0) + pi?.appliedAmount;
                }
              }
            });
          }
          const supplierInvoicesDB = suppliersList.find(supp => supp.SupplierID === debitNote.supplierID);
          if (
            _selectedSupplier &&
            (_selectedSupplier?.purchaseInvoiceIds?.length || 0) ===
              (supplierInvoicesDB?.PurchaseInvoices?.length || 0) &&
            (_selectedSupplier?.debitNotesIds?.length || 0) === +(supplierInvoicesDB?.DebitNotes?.length || 0)
          ) {
            _selectedSupplier.allSelected = true;
          } else {
            _selectedSupplier.allSelected = false;
          }
          _selectedInvoices[debitNote.supplierID] = _selectedSupplier;
        });
      setBatchDetails({
        batchName: batchDetails.name,
        paymentDate: moment(batchDetails.dueDate).format('YYYY-MM-DDTHH:mm:ss'),
        invoices: purchaseInvoices,
        debitNotes: batchDetails.debitNotes,
      });
      setApprovedInvoices(approvedInvoices);
      setSelectedInvoices(_selectedInvoices);
      setTotalPayable(totalPayable);
      setIsSavingBatch(false);
      if (showDraftList) {
        setShowDraftList(false);
      }
      if (t2TV3 && !isConnectedSupplierSelected && !isUnlinkedSuppliersSelected && !_.isEmpty(_selectedInvoices)) {
        Toast.warning(
          isLinkedSupplier
            ? 'Connected supplier selected : You can only include invoices from selected supplier in a batch. Other connected and unlinked suppliers will remain blocked until you deselect this option.'
            : 'Unlinked supplier selected : You can only include invoices from same or other unlinked suppliers in a batch. Other connected suppliers will remain blocked until you deselect these.',
        );
      }
    } else {
      resetSelectedInvoices();
    }
  };

  const fetchBatchDetails = async () => {
    const batchDetails = await getPaymentBatch(batchID);
    updateBatchDetails(batchDetails);
  };

  const utcOffset = useMemo(
    () => tenant?.Locations.find(loc => loc.IsDefaultLocation === true)?.UtcOffset || 0,
    [tenant],
  );

  const onSearchString = (searchString: string) => {
    setSelectedBucket(searchString ? PurchaseInvoiceRequestedList.Search : PurchaseInvoiceRequestedList.Overdue);
    setSelectedInvoices({});
    setTotalPayable(0);
    setPagedResultStatements({});
    setpurchaseInvoicesList([]);
    setSuppliersList([]);
    setFilter({
      ...filter,
      SearchString: searchString || undefined,
      StartRow: 1,
      RequestedList: searchString ? PurchaseInvoiceRequestedList.Search : PurchaseInvoiceRequestedList.Overdue,
    });
  };

  const togglePurchaseInvoiceViewMode = (type: PurchaseInvoiceViewTypes) => {
    setPurchaseInvoiceViewMode(type);
    setPagedResultStatements({});
    setSupplierSortingViewMode(SupplierViewSortingTypes.OverduePercentage);
    setTimeLineCustomRangeMode(defaultTimelineGroupsFilter || TimelineCustomRange.SevenToTwentyEightDays);
    setFilter({
      ...omit(filter, ['SortField', 'SortAsc', 'PersistRquestedList', 'TimelineGroup']),
      ...(type === PurchaseInvoiceViewTypes.SupplierView
        ? {SortField: SupplierViewSortingTypes.OverduePercentage, SortAsc: false, PersistRquestedList: true}
        : {}),
      ...(type === PurchaseInvoiceViewTypes.TimelineView
        ? {RequestedList: PurchaseInvoiceRequestedList.Payable, PersistRquestedList: true, TimelineGroup: 1}
        : {}),
      RequestedList: filter.RequestedList,
      StartRow: 1,
    });
  };

  const toggleSupplierViewMode = (type: PurchaseInvoiceViewTypes) => {
    setSupplierViewMode(type);
    setPagedResultStatements({});
    setFilter({
      ...filter,
      StartRow: 1,
    });
  };

  const toggleTimelineViewMode = (type: PurchaseInvoiceViewTypes) => {
    setTimelineViewMode(type);
    setPagedResultStatements({});
    setFilter({
      ...filter,
      StartRow: 1,
    });
  };
  const toggleSupplierViewSortingMode = (type: SupplierViewSortingTypes) => {
    setSupplierSortingViewMode(type);
    setPagedResultStatements({});
    setFilter({
      ...filter,
      SortField: type,
      StartRow: 1,
    });
  };

  const updateUserSettings = async (type: TimelineCustomRange) => {
    const response = await updateUserRoleDefaultViewSettings({
      defaultSearchStatusFilters: defaultSearchStatusFilters,
      defaultTimelineGroupsFilter: type,
    });
    setUserDefaultViewSettings(response);
  };

  const toggletimeLineCustomRangeMode = (type: TimelineCustomRange) => {
    updateUserSettings(type);
    setTimeLineCustomRangeMode(type);
    setPagedResultStatements({});
    setFilter({
      ...filter,
      TimelineGroup: type,
      StartRow: 1,
    });
  };

  const getTotalPayable = async (_selectedInvoices: any) => {
    const data = await purchaseInvoiceAPI.getPaymentSummary(_selectedInvoices, selectedBucket);
    setTotalPayable(data.TotalAmount);
  };

  const handleBulkInvoices = async (sId: number) => {
    const supplier = suppliersList.find(su => su.SupplierID === sId);
    const _selectedInvoices = _.cloneDeep(selectedInvoices);
    if (!supplier) {
      _selectedInvoices[sId.toString()] = {
        purchaseInvoiceIds: [],
        debitNotesIds: [],
        allSelected: false,
      };
      getTotalPayable(_selectedInvoices);
      setSelectedInvoices(_selectedInvoices);
      return;
    }
    const supplierId = supplier.SupplierID.toString();
    const _selectedSupplier = _selectedInvoices[supplierId];
    if (_selectedSupplier && _selectedSupplier.allSelected) {
      if (batchID) {
        // remove selected invoices
        let purchaseInvoices = batchDetails.invoices.map(inv => {
          if (_selectedSupplier.purchaseInvoiceIds.includes(inv.TransID)) {
            return {
              PurchaseInvoiceID: inv.TransID,
              AppliedAmount: inv.Balance,
              Action: IPurchaseInvoiceBatchActions.Remove,
            };
          } else return null;
        });
        purchaseInvoices = purchaseInvoices.filter(function (e) {
          return e;
        });
        // remove selected debit notes
        let debitNotes = batchDetails?.debitNotes?.map(db => {
          if (db.purchaseInvoices.length) {
          }
          if (_selectedSupplier.debitNotesIds.includes(db.debitNoteID)) {
            return {
              DebitNoteID: db.debitNoteID,
              Action: IPurchaseInvoiceBatchActions.Remove,
            };
          } else return null;
        });
        debitNotes = debitNotes.filter(function (e) {
          return e;
        });
        const response = await updatePaymentBatch(batchID, {
          id: batchID,
          name: batchDetails.batchName,
          dueDate: moment(batchDetails.paymentDate).format('YYYY-MM-DDTHH:mm:ss'),
          purchaseInvoices: purchaseInvoices as IPurchaseInvoiceRequest[],
          debitNoteAllocations: debitNotes ? (debitNotes as IDebitNoteRequest[]) : undefined,
        } as IBatchPaymentRequest);
        if (response?.id) {
          updateBatchDetails(response);
        }
      } else {
        _selectedInvoices[supplierId] = {
          purchaseInvoiceIds: [],
          debitNotesIds: [],
          allSelected: false,
        };
        getTotalPayable(_selectedInvoices);
        setSelectedInvoices(_selectedInvoices);
      }
    } else {
      const filteredPurchaseInvoices =
        T2TPhase280729 && !t2TV3
          ? supplier.PurchaseInvoices?.filter(inv => !inv.LinkedDocumentGUID || inv.LinkedDocumentGUID === '')
          : supplier.PurchaseInvoices;
      const filteredDebitNotes = t2TV3 && supplier.DebitNotes;
      let purchaseInvoices = filteredPurchaseInvoices?.map(inv => {
        if (!_selectedSupplier || !_selectedSupplier.purchaseInvoiceIds.includes(inv.TransID)) {
          return {
            PurchaseInvoiceID: inv.TransID,
            AppliedAmount: inv.Balance,
            Action: IPurchaseInvoiceBatchActions.Add,
          };
        } else return null;
      });
      purchaseInvoices = purchaseInvoices?.filter(function (e) {
        return e;
      });
      let debitNotes =
        filteredDebitNotes &&
        filteredDebitNotes?.map((db: {TransID: any}) => {
          if (!_selectedSupplier || !_selectedSupplier.debitNotesIds.includes(db.TransID)) {
            return {
              DebitNoteID: db.TransID,
              Action: IPurchaseInvoiceBatchActions.Add,
            };
          } else return null;
        });
      debitNotes =
        debitNotes &&
        debitNotes?.filter(function (e: any) {
          return e;
        });
      handleBatchInvoicesUpdate(
        purchaseInvoices as IPurchaseInvoiceRequest[],
        debitNotes as IDebitNoteRequest[],
        supplier?.IsLinked,
      );
    }
  };

  const handleCancelBatchCreation = () => {
    setInitialBatchLoad(true);
    setSelectedInvoices([]);
    setTotalPayable(0);
    if (batchID) {
      history.replace(AUTH_PURCHASING_PAY_BILL_ROUTE);
    }
  };

  const handleBatchInvoicesUpdate = async (
    purchaseInvoices: IPurchaseInvoiceRequest[],
    debitNotes?: IDebitNoteRequest[],
    _isConnectedSupplierSelected: boolean = false,
  ) => {
    let response;
    if (purchaseInvoices.length || (debitNotes && debitNotes.length)) {
      if (batchID) {
        response = await updatePaymentBatch(batchID, {
          id: batchID,
          name: batchDetails.batchName,
          dueDate: moment(batchDetails.paymentDate).format('YYYY-MM-DDTHH:mm:ss'),
          purchaseInvoices: purchaseInvoices as IPurchaseInvoiceRequest[],
          debitNoteAllocations: debitNotes ? (debitNotes as IDebitNoteRequest[]) : undefined,
        } as IBatchPaymentRequest);
        if (response?.id) {
          updateBatchDetails(response);
        }
      } else {
        response = await createPurchaseInvoicesBatch({
          Name: batchDetails.batchName,
          DueDate: moment(batchDetails.paymentDate).format('YYYY-MM-DDTHH:mm:ss'),
          PurchaseInvoices: purchaseInvoices as IPurchaseInvoiceRequest[],
          DebitNoteAllocations: debitNotes ? (debitNotes as IDebitNoteRequest[]) : undefined,
        });
        if (response?.id) {
          history.push(AUTH_PURCHASING_PAY_BILL_EDIT_ROUTE.replace(/:batchID/g, response?.id));
          setIsSavingBatch(false);
        }
      }
    } else {
      Toast.info('No selectable invoices available.');
      setIsSavingBatch(false);
    }
  };

  const removeAlreadyApprovedInvoice = (invoice: IBatchReviewPurchaseInvoice) => {
    const purchaseInvoices: IPurchaseInvoiceRequest[] = [
      {
        PurchaseInvoiceID: invoice.purchaseInvoiceID!,
        AppliedAmount: invoice.balance,
        Action: IPurchaseInvoiceBatchActions.Remove,
      },
    ];
    handleBatchInvoicesUpdate(purchaseInvoices);
  };

  const handleInvoices = async (invoiceId: number, supplierId: number) => {
    let supplier;
    let purchaseInvoice;
    if (selectedBucket !== PurchaseInvoiceRequestedList.Search) {
      supplier = suppliersList.find(su => su.SupplierID === supplierId);
      purchaseInvoice = supplier?.PurchaseInvoices?.find(pi => pi.TransID === invoiceId);
    }

    if (!purchaseInvoice) {
      purchaseInvoice = purchaseInvoicesList?.find(pi => pi.TransID === invoiceId);
    }

    if (!purchaseInvoice) {
      Toast.error('Some Error Occured. Please Refresh the Page and try again!');
      return;
    }

    if (purchaseInvoice.Status === PaymentStatusType.Paid) {
      return;
    }

    const isInvoiceSelected = batchDetails?.invoices?.findIndex(i => invoiceId === i.TransID) || 0;
    const purchaseInvoices: IPurchaseInvoiceRequest[] = [
      {
        PurchaseInvoiceID: invoiceId,
        AppliedAmount: purchaseInvoice.Balance,
        Action: isInvoiceSelected > -1 ? IPurchaseInvoiceBatchActions.Remove : IPurchaseInvoiceBatchActions.Add,
      },
    ];
    handleBatchInvoicesUpdate(purchaseInvoices, [], supplier?.IsLinked);
  };

  const handleDebitNotes = async (debitNoteId: number, supplierId: number) => {
    let supplier;
    let debitNote;
    if (selectedBucket !== PurchaseInvoiceRequestedList.Search) {
      supplier = suppliersList.find(su => su.SupplierID === supplierId);
      debitNote = supplier?.DebitNotes?.find(pi => pi.TransID === debitNoteId);
    }

    if (!debitNote) {
      Toast.error('Some Error Occured. Please Refresh the Page and try again!');
      return;
    }

    const isDebitNoteSelected = batchDetails?.debitNotes?.findIndex(i => debitNoteId === i.debitNoteID) || 0;
    const debitNotes: IDebitNoteRequest[] = [
      {
        DebitNoteID: debitNoteId,
        Action: isDebitNoteSelected > -1 ? IPurchaseInvoiceBatchActions.Remove : IPurchaseInvoiceBatchActions.Add,
      },
    ];
    handleBatchInvoicesUpdate([], debitNotes, supplier?.IsLinked);
  };

  const getPurchaseInvoicesList = (SupplierID: number) => {
    const _suppliersList = _.cloneDeep(suppliersList);
    const index = _suppliersList.findIndex(supplier => supplier.SupplierID === SupplierID);

    if (Array.isArray(_suppliersList[index].PurchaseInvoices)) {
      return;
    }

    purchaseInvoiceAPI
      .getPurchaseInvoicesListBySupplier(SupplierID, {
        RequestedList: selectedBucket,
      })
      .then((data: IPurchaseInvoiceSummaries) => {
        _suppliersList[index].PurchaseInvoices = data.Results;
        const supplierId = SupplierID.toString();
        if (selectedInvoices[supplierId] && selectedInvoices[supplierId].allSelected) {
          const _selectedInvoices = _.cloneDeep(selectedInvoices);
          _selectedInvoices[supplierId] = {
            allSelected: true,
            purchaseInvoiceIds: data.Results.map(pi => pi.TransID),
          };
          setSelectedInvoices(_selectedInvoices);
        }
        setSuppliersList(_suppliersList);
      });
  };

  const onSelectAll = async (isAllSuppliersSelected: boolean) => {
    if (isAllSuppliersSelected) {
      let purchaseInvoicesRequest = batchDetails?.invoices?.map(inv => {
        return {
          PurchaseInvoiceID: inv.TransID,
          AppliedAmount: inv.Balance,
          Action: IPurchaseInvoiceBatchActions.Remove,
        };
      });
      handleBatchInvoicesUpdate(purchaseInvoicesRequest);
      return;
    }

    let suppliersData = suppliersList;

    if (pagedResultStatements && pagedResultStatements?.MoreToGet && pagedResultStatements?.TotalRecordCount) {
      const _filter = {
        ...filter,
        StartRow: (filter.StartRow || 1) + (filter.MaxResults || 0),
        MaxResults: pagedResultStatements?.TotalRecordCount - (filter.StartRow || 1) + (filter.MaxResults || 0),
        IsFreeTextSearch: false,
      };
      const res: IPagedActionResults<ISupplierSummaries> = await purchaseInvoiceAPI.search(_filter);
      if (!res || !res.Value) {
        return;
      }

      suppliersData = _filter.StartRow === 1 ? res.Value.Results : [...suppliersList, ...res.Value.Results];
      setSuppliersList(suppliersData);
      setTotals(res.Value);
      setPagedResultStatements(res);
      setFilter(_filter);
    }

    const _selectedInvoices: any = {};

    const purchaseInvoiceList: Promise<IPurchaseInvoiceSummaries>[] = [];
    suppliersData.forEach(supplier => {
      if (!supplier.PurchaseInvoices) {
        purchaseInvoiceList.push(
          purchaseInvoiceAPI.getPurchaseInvoicesListBySupplier(supplier.SupplierID, {
            RequestedList: selectedBucket,
          }),
        );
      }
    });
    const purchaseInvoiceResponses = await Promise.all(purchaseInvoiceList);

    const isOverDueList = selectedBucket === PurchaseInvoiceRequestedList.Overdue;
    const isPayableList = selectedBucket === PurchaseInvoiceRequestedList.Payable;
    const purchaseInvoicesRequest: IPurchaseInvoiceRequest[] = [];
    let idx = 0;

    suppliersData.forEach((supplier: ISupplier) => {
      isOverDueList
        ? supplier.ValueOverdueInvoices
        : isPayableList
          ? supplier.ValueOverdueInvoices + supplier.ValueDueSoonInvoices
          : supplier.ValueDueSoonInvoices;
      if (!supplier.PurchaseInvoices) {
        supplier.PurchaseInvoices = purchaseInvoiceResponses[idx].Results;
        idx += 1;
      }
      _selectedInvoices[supplier.SupplierID] = {
        allSelected: true,
        purchaseInvoiceIds: [...supplier.PurchaseInvoicesID],
      };
      supplier.PurchaseInvoices.forEach((pi: IPurchaseInvoice) => {
        const isInvoiceSelected = batchDetails?.invoices?.findIndex(i => pi.TransID === i.TransID) || 0;
        if (isInvoiceSelected < 0) {
          purchaseInvoicesRequest.push({
            PurchaseInvoiceID: pi.TransID,
            AppliedAmount: pi.Balance,
            Action: IPurchaseInvoiceBatchActions.Add,
          });
        }
      });
    });
    setSuppliersList(suppliersData);
    handleBatchInvoicesUpdate(purchaseInvoicesRequest);
  };

  const checkColumnSelected = (column: number, supplierDetails: ISupplier | null = null) => {
    if (!suppliersList.length || _.isEmpty(selectedInvoices)) return false;
    let suppliersData = suppliersList;
    if (supplierDetails) {
      suppliersData = [supplierDetails];
    }
    const _selectedInvoices: any = cloneDeep(selectedInvoices);
    let allSelected = true;
    suppliersData.forEach((supplier: ISupplier) => {
      if (!allSelected) return;
      const purchaseInvoicesIds =
        supplier.PurchaseInvoices?.filter(inv => checkDueDate(utcOffset, timeLineCustomRangeMode, column, inv)).map(
          inv => {
            return inv.TransID;
          },
        ) || [];
      const suppSelectedInv = _selectedInvoices?.[supplier.SupplierID.toString()];
      if (!purchaseInvoicesIds.length && !suppSelectedInv?.purchaseInvoiceIds?.length) {
        allSelected = true;
      } else if (
        purchaseInvoicesIds &&
        suppSelectedInv?.purchaseInvoiceIds &&
        purchaseInvoicesIds.every(v => suppSelectedInv?.purchaseInvoiceIds?.includes(v))
      ) {
        allSelected = true;
      } else {
        allSelected = false;
      }
    });
    return allSelected;
  };

  const handleTimelineSelect = async (column: number, supplierId?: number) => {
    let suppliersData = suppliersList;
    // check if column already selected
    const isAlreadySelected = checkColumnSelected(column);
    const _selectedInvoices: any = cloneDeep(selectedInvoices);
    const purchaseInvoicesRequest: IPurchaseInvoiceRequest[] = [];
    suppliersData.forEach((supplier: ISupplier) => {
      if (supplierId && supplier.SupplierID !== supplierId) {
        return;
      }
      const filteredPurchaseInvoices =
        T2TPhase280729 && !t2TV3
          ? supplier.PurchaseInvoices?.filter(inv => !inv.LinkedDocumentGUID || inv.LinkedDocumentGUID === '')
          : supplier.PurchaseInvoices;
      const purchaseInvoicesIds =
        filteredPurchaseInvoices
          ?.filter(inv => checkDueDate(utcOffset, timeLineCustomRangeMode, column, inv))
          .map(inv => {
            return inv.TransID;
          }) || [];
      const _purchaseInvoiceIds = new Set<number>(_selectedInvoices[supplier.SupplierID]?.purchaseInvoiceIds || []);
      const isArraySubset = purchaseInvoicesIds.every(val => Array.from(_purchaseInvoiceIds).includes(val));
      if (isArraySubset && (isAlreadySelected || supplierId)) {
        purchaseInvoicesIds.forEach(item => {
          _purchaseInvoiceIds.delete(item);
          purchaseInvoicesRequest.push({
            PurchaseInvoiceID: item,
            Action: IPurchaseInvoiceBatchActions.Remove,
          });
        });
        _selectedInvoices[supplier.SupplierID] = {
          allSelected: false,
          purchaseInvoiceIds: [...Array.from(_purchaseInvoiceIds)],
        };
      } else {
        purchaseInvoicesIds.forEach(item => {
          _purchaseInvoiceIds.add(item);
          const isInvoiceSelected = batchDetails?.invoices?.findIndex(i => item === i.TransID) || 0;
          if (isInvoiceSelected < 0) {
            purchaseInvoicesRequest.push({
              PurchaseInvoiceID: item,
              Action: IPurchaseInvoiceBatchActions.Add,
              AppliedAmount: supplier?.PurchaseInvoices?.find(pi => pi.TransID === item)?.Balance,
            });
          }
        });
        _selectedInvoices[supplier.SupplierID] = {
          allSelected: supplier.PurchaseInvoices?.length === _purchaseInvoiceIds.size ? true : false,
          purchaseInvoiceIds: [...Array.from(_purchaseInvoiceIds)],
        };
      }
    });

    setSuppliersList(suppliersData);
    handleBatchInvoicesUpdate(purchaseInvoicesRequest);
  };

  const onSelectAllSearch = async (isAllSuppliersSelected: boolean) => {
    if (isAllSuppliersSelected) {
      let purchaseInvoicesRequest = batchDetails?.invoices?.map(inv => {
        return {
          PurchaseInvoiceID: inv.TransID,
          AppliedAmount: inv.Balance,
          Action: IPurchaseInvoiceBatchActions.Remove,
        };
      });
      handleBatchInvoicesUpdate(purchaseInvoicesRequest);
      return;
    }
    let purchaseInvoicesData = purchaseInvoicesList;
    if (pagedResultStatements && pagedResultStatements?.MoreToGet && pagedResultStatements?.TotalRecordCount) {
      const _filter = {
        ...filter,
        StartRow: (filter.StartRow || 1) + (filter.MaxResults || 0),
        MaxResults: pagedResultStatements?.TotalRecordCount - (filter.StartRow || 1) + (filter.MaxResults || 0),
        IsFreeTextSearch: false,
      };
      const res: any = await purchaseInvoiceAPI.getPurchaseInvoicesList(_filter);
      if (!res || !res.Value) {
        return;
      }

      purchaseInvoicesData =
        _filter.StartRow === 1 ? res.Value.Results : [...purchaseInvoicesList, ...res.Value.Results];
      setpurchaseInvoicesList(purchaseInvoicesData);
      setTotals(res.Value);
      setPagedResultStatements(res);
      setFilter(_filter);
    }

    const _selectedInvoices: any = {};
    const purchaseInvoicesRequest: IPurchaseInvoiceRequest[] = [];
    purchaseInvoicesData.forEach((pi: IPurchaseInvoice) => {
      if (pi.Status === PaymentStatusType.Paid) return;
      if (_selectedInvoices[pi.SupplierID]) {
        _selectedInvoices[pi.SupplierID].purchaseInvoiceIds.push(pi.TransID);
      } else {
        _selectedInvoices[pi.SupplierID] = {
          purchaseInvoiceIds: [pi.TransID],
          allSelected: filter?.SearchString ? false : true,
        };
      }
      const isInvoiceSelected = batchDetails?.invoices?.findIndex(i => pi.TransID === i.TransID) || 0;
      if (isInvoiceSelected < 0) {
        purchaseInvoicesRequest.push({
          PurchaseInvoiceID: pi.TransID,
          AppliedAmount: pi.Balance,
          Action: IPurchaseInvoiceBatchActions.Add,
        });
      }
    });

    handleBatchInvoicesUpdate(purchaseInvoicesRequest);
  };

  const resetSelectedInvoices = () => {
    setApprovedInvoices([]);
    setInitialBatchLoad(true);
    setSelectedInvoices({});
    setTotalPayable(0);
    setBatchDetails(newBatch);
    setIsSavingBatch(false);
    if (batchID) {
      history.replace(AUTH_PURCHASING_PAY_BILL_ROUTE.replace(/:batchID/g, batchID));
    }
  };

  const handleDeselectClick = () => {
    let purchaseInvoicesRequest = batchDetails?.invoices?.map(inv => {
      return {
        PurchaseInvoiceID: inv.TransID,
        AppliedAmount: inv.Balance,
        Action: IPurchaseInvoiceBatchActions.Remove,
      };
    });
    let debitNotesRequest = batchDetails?.debitNotes?.map(db => {
      return {
        DebitNoteID: db.debitNoteID,
        Action: IPurchaseInvoiceBatchActions.Remove,
      };
    });
    handleBatchInvoicesUpdate(purchaseInvoicesRequest, debitNotesRequest);
    return;
  };

  const renderListView = () => {
    if (
      selectedBucket === PurchaseInvoiceRequestedList.Search ||
      purchaseInvoiceViewMode === PurchaseInvoiceViewTypes.SingleInvoiceView
    ) {
      return (
        <APInvoiceSingleViewList
          isSearching={isSearchingInvoices}
          selectedInvoices={selectedInvoices}
          handleInvoices={handleInvoices}
          handleDebitNotes={handleDebitNotes}
          suppliersList={suppliersList}
          handleBulkInvoices={handleBulkInvoices}
          getPurchaseInvoicesList={getPurchaseInvoicesList}
          onSelectAll={onSelectAllSearch}
          selectedBucket={selectedBucket}
          purchaseInvoicesList={purchaseInvoicesList}
          purchaseInvoiceViewMode={purchaseInvoiceViewMode}
          togglePurchaseInvoiceViewMode={togglePurchaseInvoiceViewMode}
          SupplierViewMode={supplierViewMode}
          toggleSupplierViewMode={toggleSupplierViewMode}
          totalRecords={pagedResultStatements?.TotalRecordCount || 0}
          totalPayable={totalPayable}
          toggleTimelineViewMode={toggleTimelineViewMode}
          toggleSupplierViewSortingMode={toggleSupplierViewSortingMode}
          SupplierSortingViewMode={SupplierSortingViewMode}
          TimelineViewMode={timelineViewMode}
          timeLineCustomRangeMode={timeLineCustomRangeMode}
          toggletimeLineCustomRangeMode={toggletimeLineCustomRangeMode}
          isSavingBatch={isSavingBatch}
          setIsSavingBatch={setIsSavingBatch}
          isT2TPhaseTwo={T2TPhase280729}
          isScheduledPaymentsV2={scheduledPaymentsV2}
          t2TV3={t2TV3}
        />
      );
    } else if (purchaseInvoiceViewMode === PurchaseInvoiceViewTypes.SupplierView) {
      return (
        <APInvoiceSupplierViewList
          selectedInvoices={selectedInvoices}
          handleBulkInvoices={handleBulkInvoices}
          suppliersList={suppliersList}
          purchaseInvoiceViewMode={purchaseInvoiceViewMode}
          togglePurchaseInvoiceViewMode={togglePurchaseInvoiceViewMode}
          supplierViewMode={supplierViewMode}
          toggleSupplierViewMode={toggleSupplierViewMode}
          onSelectAll={onSelectAll}
          selectedBucket={selectedBucket}
          totalPayable={totalPayable}
          handleInvoices={handleInvoices}
          handleDebitNotes={handleDebitNotes}
          getPurchaseInvoicesList={getPurchaseInvoicesList}
          toggleTimelineViewMode={toggleTimelineViewMode}
          timelineViewMode={timelineViewMode}
          toggleSupplierViewSortingMode={toggleSupplierViewSortingMode}
          SupplierSortingViewMode={SupplierSortingViewMode}
          timeLineCustomRangeMode={timeLineCustomRangeMode}
          toggletimeLineCustomRangeMode={toggletimeLineCustomRangeMode}
          isSavingBatch={isSavingBatch}
          setIsSavingBatch={setIsSavingBatch}
          isT2TPhaseTwo={T2TPhase280729}
          isScheduledPaymentsV2={scheduledPaymentsV2}
          t2TV3={t2TV3}
        />
      );
    } else if (purchaseInvoiceViewMode === PurchaseInvoiceViewTypes.TimelineView) {
      return (
        <APInvoiceTimelineViewList
          selectedInvoices={selectedInvoices}
          handleBulkInvoices={handleBulkInvoices}
          suppliersList={suppliersList}
          totalPayable={totalPayable}
          selectedBucket={selectedBucket}
          onSelectAll={onSelectAll}
          handleInvoices={handleInvoices}
          handleDebitNotes={handleDebitNotes}
          purchaseInvoiceViewMode={purchaseInvoiceViewMode}
          togglePurchaseInvoiceViewMode={togglePurchaseInvoiceViewMode}
          isT2TPhaseTwo={T2TPhase280729}
          getPurchaseInvoicesList={getPurchaseInvoicesList}
          supplierViewMode={supplierViewMode}
          toggleSupplierViewMode={toggleSupplierViewMode}
          toggleTimelineViewMode={toggleTimelineViewMode}
          timelineViewMode={timelineViewMode}
          toggleSupplierViewSortingMode={toggleSupplierViewSortingMode}
          SupplierSortingViewMode={SupplierSortingViewMode}
          timeLineCustomRangeMode={timeLineCustomRangeMode}
          toggletimeLineCustomRangeMode={toggletimeLineCustomRangeMode}
          handleTimelineSelect={handleTimelineSelect}
          isSavingBatch={isSavingBatch}
          checkColumnSelected={checkColumnSelected}
          setIsSavingBatch={setIsSavingBatch}
          isScheduledPaymentsV2={scheduledPaymentsV2}
          t2TV3={t2TV3}
          isConnectedSupplierSelected={isConnectedSupplierSelected}
          selectedConnectedSupplierId={selectedConnectedSupplierId}
          isUnlinkedSuppliersSelected={isUnlinkedSuppliersSelected}
          showDeselectOption={showDeselectOption}
          handleDeselectClick={handleDeselectClick}
        />
      );
    }
  };

  const customerActivityPanel = (
    <div className={`flex h-full flex-col overflow-x-auto ${leftGrid}`}>
      <LoadingIndicator isLoading={isLoading} size="md" color="hsl(var(--primary))" />
      <div className={`spendaPayActivityHeader w-full rounded bg-white p-1 ${apActivityHead}`}>
        <AccountsPayableActivityHeader
          purchaseInvoiceViewMode={purchaseInvoiceViewMode}
          searchFilter={filter}
          isSearching={isSearchingInvoices}
          updateFilter={onSearchString}
          onClickBucket={onClickBucket}
          selected={selectedBucket}
          OverdueAmount={totals?.TotalOverdueAmount}
          OverdueRecords={totals?.TotalOverdueRecords}
          DebitNotesAmount={totals?.TotalDebitNotesAmount || totals?.TotalDebitNotes}
          isT2TPhaseTwo={T2TPhase280729}
          DueAmount={totals?.TotalDueAmount}
          DueRecords={totals?.TotalDueRecords}
          CreditAmount={totals?.TotalCreditAmount}
          CreditRecords={totals?.TotalCreditCustomers}
          apRefreshData={fetchSuppliers}
          isAccountsPayable={true}
          financialAdaptorName={financialAdaptor?.Name}
        />
      </div>
      <div className={`${height} invoicesList mt-2 rounded bg-white`}>{renderListView()}</div>
    </div>
  );

  const apBatchPanel = (
    <BatchCreate
      totalPayable={totalPayable}
      handleInvoices={handleInvoices}
      handleDebitNote={handleDebitNotes}
      handleCancelBatchCreation={handleCancelBatchCreation}
      batchDetails={batchDetails}
      batchID={batchID}
      resetSelectedInvoices={resetSelectedInvoices}
      setBatchDetails={setBatchDetails}
      isSavingBatch={isSavingBatch}
      setIsSavingBatch={setIsSavingBatch}
      approvedInvoices={approvedInvoices}
      removeAlreadyApprovedInvoice={removeAlreadyApprovedInvoice}
      showDraftList={showDraftList}
      setShowDraftList={setShowDraftList}
      t2TV3={t2TV3}
    />
  );

  return (
    <div className="relative h-full overflow-hidden bg-spenda-newbg">
      {userRoleBatchPermissions && userRoleBatchPermissions.includes(PaymentBatchPermissionType.CreateBatch) ? (
        isAPFinancialAdaptorSetup() ? (
          <Layout leftPanel={customerActivityPanel} mainPanel={apBatchPanel} splitWidthType={'AR'} />
        ) : (
          <Layout
            splitWidthType={4}
            leftPanel={
              <div className={`relative mt-3 flex h-full items-center justify-center rounded bg-white`}>
                <span className="text-lg font-medium text-spenda-primarytext">
                  Contact support to upgrade and integrate financial adaptor
                </span>
              </div>
            }
          />
        )
      ) : (
        <Layout
          splitWidthType={4}
          leftPanel={
            <div className={`relative mt-3 flex h-full items-center justify-center rounded bg-white`}>
              <span className="font-poppins text-lg font-medium text-spenda-primarytext">
                No permission to create batch
              </span>
            </div>
          }
        />
      )}
    </div>
  );
};
