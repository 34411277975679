import React, {useEffect, useRef, useState} from 'react';
import {
  Button,
  Card,
  CardBody,
  Carousel,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  SearchInput,
  Typography,
} from 'spenda-ui-react';
import ArrowLeftRoundedIcon from '@material-ui/icons/ArrowLeftRounded';
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded';
import {ICustomerAsset} from '../../model/customer/Customer';
import {useLoadAsset} from '../../services/useCustomersAssetAPI';
import LoadingIndicator from '../ui/LoadingIndicator';
import EditCustomerIcon from '../../assets/svg/EditCustomerIcon';

interface ISelectAssetDialog {
  open: boolean;
  handleCancel: () => void;
  handleNewAsset: () => void;
  handleOnSave: (selectedAssetCustomer?: ICustomerAsset) => void;
  selectedAssetCustomer?: ICustomerAsset;
  customerID: number;
  handleEdit: (asset?: ICustomerAsset) => void;
}

export const SelectAssetDialog: React.FunctionComponent<ISelectAssetDialog> = ({
  open,
  handleCancel,
  handleNewAsset,
  handleOnSave,
  selectedAssetCustomer,
  customerID,
  handleEdit,
}: ISelectAssetDialog) => {
  const {items: customerAsset, loading: isLoading, setSearchFilter, searchFilter} = useLoadAsset(customerID);
  const [searchString, setSearchString] = useState<string>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [selAsset, setSelAsset] = useState<ICustomerAsset | undefined>(selectedAssetCustomer);

  useEffect(() => {
    if (typeof searchString != 'undefined') {
      setSearchFilter({
        ...searchFilter,
        SearchString: searchString,
      });
    }
  }, [setSearchString, searchString]);

  useEffect(() => {
    if (!selAsset || selAsset?.assetID != selectedAssetCustomer?.assetID) {
      setSelAsset(selectedAssetCustomer);
    }
  }, [selectedAssetCustomer]);

  const prevArrow = ({loop, handlePrev, firstIndex}: {loop: boolean; handlePrev: () => void; firstIndex: boolean}) => {
    return (
      <button
        data-autoid="btnPrev"
        onClick={handlePrev}
        disabled={!loop && firstIndex ? true : false}
        className="!absolute left-[-45px] top-[50%] z-10 grid h-12 max-h-[55px] w-12 max-w-[55px] -translate-y-2/4 select-none place-items-center transition-all disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
      >
        <ArrowLeftRoundedIcon style={{color: 'inherit', fontSize: '55px'}} />
      </button>
    );
  };

  const nextArrow = ({loop, handleNext, lastIndex}: {loop: boolean; handleNext: () => void; lastIndex: boolean}) => {
    return (
      <button
        data-autoid="btnNext"
        onClick={handleNext}
        disabled={!loop && lastIndex ? true : false}
        className="!absolute right-[-35px] top-[50%] z-10 grid h-12 max-h-[55px] w-12 max-w-[55px] -translate-y-2/4 select-none place-items-center transition-all disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
      >
        <ArrowRightRoundedIcon style={{color: 'inherit', fontSize: '55px'}} />
      </button>
    );
  };

  const onSearchChange: ChangeEventHandler = event => {
    event.preventDefault();
    setSearchString(event.target.value);
  };

  return (
    <>
      <Dialog open={open} handler={() => {}} className="!w-full !max-w-[835px]" data-autoid={'dlgSelectExistingAsset'}>
        <DialogHeader className="justify-center border-b border-[#ccc] text-center font-light">
          Select existing asset
        </DialogHeader>
        <DialogBody>
          <div className="mx-8 pt-16">
            <div className="mb-5 flex flex-col items-center justify-center gap-6 pb-10">
              <div className="flex w-[349px] items-center justify-center text-black-800">
                <SearchInput
                  ref={inputRef}
                  value={searchString}
                  onChange={onSearchChange}
                  clearIcon
                  autoFocus
                  expanded
                  clearIconProps={{
                    onClick: () => {
                      setSearchString('');
                    },
                  }}
                  placeholder="Search asset"
                  reverse={true}
                  color="primary"
                />
              </div>
            </div>
            <div className="relative mb-5">
              {isLoading ? (
                <div className={'relative mb-5 h-32 px-8 py-2'}>
                  <LoadingIndicator isLoading={isLoading} size="md" color="hsl(var(--primary))" />
                </div>
              ) : customerAsset.length === 0 ? (
                <div className={'relative mb-5 h-32 px-8 py-2'}></div>
              ) : (
                <Carousel
                  itemsToShow={4}
                  className={'static max-w-[771px]'}
                  prevArrow={prevArrow}
                  nextArrow={nextArrow}
                >
                  {customerAsset?.map((asset: any, index: number) => (
                    <div key={index} className="!h-full !w-full px-1 py-2">
                      <Card
                        className={`flex h-32 w-44 cursor-pointer items-center justify-center shadow-[0_0px_6px_0px_rgba(144,142,142,0.5)] ${
                          asset.assetID === selAsset?.assetID ? 'border border-primary bg-primary/10' : null
                        }`}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          setSelAsset?.(asset);
                        }}
                        data-autoid={`btnCustomerAssets-${asset.assetID}`}
                      >
                        <CardBody className="flex w-full flex-col items-center justify-center px-2 py-2">
                          <EditCustomerIcon
                            onClick={() => {
                              handleEdit(asset);
                            }}
                            className="absolute right-2 top-2"
                            data-autoid={`btnEditAsset-${asset.assetID}`}
                          />
                          <Typography className="line-clamp-2 w-full cursor-pointer text-center text-base font-semibold text-primary">
                            {asset?.businessLocationAddress?.streetAddress} {asset.businessLocationAddress?.city}{' '}
                            {asset?.businessLocationAddress?.country} {asset?.businessLocationAddress?.state}{' '}
                            {asset?.businessLocationAddress?.postCode}
                          </Typography>
                        </CardBody>
                      </Card>
                    </div>
                  ))}
                </Carousel>
              )}
            </div>
          </div>
        </DialogBody>
        <DialogFooter className="p-2.5">
          <div className="flex w-full items-center justify-between rounded-lg bg-[#f1f1f1] p-2.5">
            <div>
              <Button variant="outlined" onClick={handleCancel} className="bg-white">
                Cancel
              </Button>
            </div>
            <div className="flex gap-3">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleNewAsset()}
                className="bg-white"
                data-autoid="btnCreateNewAsset"
              >
                Create New Asset
              </Button>
              <Button variant="filled" color="primary" onClick={() => handleOnSave(selAsset)}>
                Save Asset
              </Button>
            </div>
          </div>
        </DialogFooter>
      </Dialog>
    </>
  );
};
