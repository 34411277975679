import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Formik, Form, FormikProps} from 'formik';
import * as Yup from 'yup';
import {pickBy, identity} from 'lodash';

import {IActionResults} from '../../model/ActionResults';
import {InventoryClass} from '../../model/inventory/InventoryClass';
import {AUTH_INVENTORY_PRODUCT_CLASSES_LIST} from '../../routes/InventoryRoutes';
import {AddInventoryClasses, AddInventoryClassValues} from '../../components/form/InventoryClassForm';
import {Layout} from '../../components/layout/Layout';
import {SButton} from '../../components/buttons/Button';
import {AlertDialogSlide} from '../../components/dialog/AlertDialogSlide';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {useInventoryClassAPI} from '../../services/useInventoryClassesAPI';
import {Toast} from '../../utils/Toast';

import {makeStyles} from '@material-ui/core/styles';
import {Typography} from 'spenda-ui-react';

const useAddProductClassStyles = makeStyles(() => ({
  root: {
    maxHeight: 'calc( 100vh - 20.5em )',
    height: '100%',
  },
  buttonContainer: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0 -2px 4px 0 #00000010',
    zIndex: 2,
    width: 'calc(100% + 2.5rem) !important',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.5em',
    color: '#4D4D4D',
  },
}));

const validationSchema = Yup.object({
  Name: Yup.string()
    .required('Inventory Class Name is required.')
    .max(50, 'Inventory Class Name cannot be longer than 50 characters.'),
  Description: Yup.string().max(255, 'Inventory Class Description cannot be longer than 255 characters.'),
  SalesPostingInventoryID: Yup.string()
    .nullable(true)
    .test(
      'is-generic',
      'Departmental class for sales/service is required',
      (value: string | null | undefined, ctx: Yup.TestContext<{}>): boolean => {
        return ctx.parent?.PostingBehaviour === 'Generic'
          ? Boolean(value || ctx.parent?.ServicePostingInventoryID)
          : true;
      },
    ),
  ServicePostingInventoryID: Yup.string()
    .nullable(true)
    .test(
      'is-generic',
      'Departmental class for sales/service is required',
      (value: string | null | undefined, ctx: Yup.TestContext<{}>): boolean => {
        return ctx.parent?.PostingBehaviour === 'Generic'
          ? Boolean(value || ctx.parent?.SalesPostingInventoryID)
          : true;
      },
    ),
});

export const AddProductClass = () => {
  const history = useHistory();
  const {productClassID} = useParams<{productClassID?: string | undefined}>();
  const classes = useAddProductClassStyles();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const {isLoading, getInventoryClassById, createInventoryClass, updateInventoryClass, deleteInventoryClass} =
    useInventoryClassAPI();

  const newInventoryClass: AddInventoryClassValues = {
    Name: '',
    Description: '',
    IsApprovedForPOS: false,
    IsApprovedForService: false,
    IsApprovedForWeb: false,
    PostingBehaviour: '',
    SalesPostingInventoryCode: null,
    SalesPostingInventoryExpenseAccountCode: null,
    SalesPostingInventoryID: null,
    SalesPostingInventoryName: null,
    SalesPostingInventoryRevenueAccountCode: null,
    ServicePostingInventoryCode: null,
    ServicePostingInventoryExpenseAccountCode: null,
    ServicePostingInventoryID: null,
    ServicePostingInventoryName: null,
    ServicePostingInventoryRevenueAccountCode: null,
    IsActive: true,
  } as AddInventoryClassValues;

  const [inventoryClassToEdit, setInventoryClassToEdit] = React.useState<AddInventoryClassValues>(newInventoryClass);

  useEffect(() => {
    if (productClassID) {
      getInventoryClassById(productClassID).then((res: InventoryClass) => {
        if (res) {
          const _inventoryClass = {
            ID: res.ID,
            Name: res.Name,
            Description: res.Description,
            IsApprovedForPOS: res.IsApprovedForPOS,
            IsApprovedForService: res.IsApprovedForService,
            IsApprovedForWeb: res.IsApprovedForWeb,
            PostingBehaviour: res.PostingBehaviour || '',
            SalesPostingInventoryCode: res.SalesPostingInventoryCode,
            SalesPostingInventoryExpenseAccountCode: res.SalesPostingInventoryExpenseAccountCode,
            SalesPostingInventoryID: res.SalesPostingInventoryID,
            SalesPostingInventoryName: res.SalesPostingInventoryName,
            SalesPostingInventoryRevenueAccountCode: res.SalesPostingInventoryRevenueAccountCode,
            ServicePostingInventoryCode: res.ServicePostingInventoryCode,
            ServicePostingInventoryExpenseAccountCode: res.ServicePostingInventoryExpenseAccountCode,
            ServicePostingInventoryID: res.ServicePostingInventoryID,
            ServicePostingInventoryName: res.ServicePostingInventoryName,
            ServicePostingInventoryRevenueAccountCode: res.ServicePostingInventoryRevenueAccountCode,
            IsActive: res.IsActive,
          } as AddInventoryClassValues;
          setInventoryClassToEdit(_inventoryClass);
        } else {
          history.push(AUTH_INVENTORY_PRODUCT_CLASSES_LIST);
        }
      });
    }
  }, []);

  const onSubmit = async (values: AddInventoryClassValues): Promise<void> => {
    if (productClassID) {
      if (values.PostingBehaviour !== 'Generic') {
        delete values.SalesPostingInventoryCode;
        delete values.SalesPostingInventoryExpenseAccountCode;
        delete values.SalesPostingInventoryID;
        delete values.SalesPostingInventoryName;
        delete values.SalesPostingInventoryRevenueAccountCode;
        delete values.ServicePostingInventoryCode;
        delete values.ServicePostingInventoryExpenseAccountCode;
        delete values.ServicePostingInventoryID;
        delete values.ServicePostingInventoryName;
        delete values.ServicePostingInventoryRevenueAccountCode;
      }
      const payload = pickBy(values, identity);
      return updateInventoryClass(payload).then((res: IActionResults<InventoryClass>) => {
        if (res.IsSuccess) {
          history.push(AUTH_INVENTORY_PRODUCT_CLASSES_LIST);
          Toast.info(`Product Class '${values.Name}' updated`);
        }
      });
    } else {
      // if (classList?.TotalRecordCount > 0) {
      //   return Promise.reject();
      // }
      if (values.PostingBehaviour !== 'Generic') {
        delete values.SalesPostingInventoryCode;
        delete values.SalesPostingInventoryExpenseAccountCode;
        delete values.SalesPostingInventoryID;
        delete values.SalesPostingInventoryName;
        delete values.SalesPostingInventoryRevenueAccountCode;
        delete values.ServicePostingInventoryCode;
        delete values.ServicePostingInventoryExpenseAccountCode;
        delete values.ServicePostingInventoryID;
        delete values.ServicePostingInventoryName;
        delete values.ServicePostingInventoryRevenueAccountCode;
      }
      const payload = pickBy(values, identity);
      return createInventoryClass(payload).then((res: IActionResults<InventoryClass>) => {
        if (res.IsSuccess) {
          history.push(AUTH_INVENTORY_PRODUCT_CLASSES_LIST);
        }
      });
    }
  };

  const onDelete = (): Promise<void> | void => {
    if (productClassID) {
      return deleteInventoryClass(productClassID).then((res: IActionResults<InventoryClass>) => {
        if (res.IsSuccess) {
          Toast.info(`Product Class deleted`);
          history.push(AUTH_INVENTORY_PRODUCT_CLASSES_LIST);
        }
      });
    }
  };

  const handleCloseClick = (props: FormikProps<AddInventoryClassValues>) => {
    if (props.dirty) {
      setOpenConfirmationDialog(true);
    } else {
      history.push(AUTH_INVENTORY_PRODUCT_CLASSES_LIST);
    }
  };

  const _addProductClass = (
    <div
      className={`${classes.root} relative flex min-h-full w-full flex-col overflow-y-auto rounded-lg bg-white`}
      id="add-product-class"
    >
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={inventoryClassToEdit}
        onSubmit={onSubmit}
      >
        {props => (
          <Form onSubmit={props.handleSubmit} className="flex h-full flex-col px-5 py-4">
            <div className={`flex w-full justify-between p-4`}>
              {productClassID ? (
                <Typography className="text-xl font-medium text-gray-800">Edit Product Class</Typography>
              ) : (
                <Typography className="text-xl font-medium text-gray-800">New Product Class</Typography>
              )}
            </div>
            <AddInventoryClasses {...props} initialValues={inventoryClassToEdit} />
            <div className={`${classes.buttonContainer} sticky bottom-0 -mx-5 -mb-5 flex justify-end p-3`}>
              {productClassID && (
                <SButton
                  margin="0 0.25rem"
                  color="red"
                  border="1px solid #B9624B"
                  textColor="white"
                  lineHeight="m"
                  width="m"
                  type="button"
                  boxShadow="none"
                  onClick={() => setOpenDeleteDialog(true)}
                >
                  Delete
                </SButton>
              )}
              <SButton
                margin="0 0.25rem"
                color="transparent"
                border="1px solid #1C78AD"
                textColor="blueShade"
                lineHeight="m"
                width="m"
                type="button"
                boxShadow="none"
                onClick={() => handleCloseClick(props)}
              >
                Cancel
              </SButton>
              <SButton
                margin="0 0.25rem"
                color="blueShade"
                border="1px solid #1C78AD"
                textColor="white"
                lineHeight="m"
                width="m"
                type="submit"
                boxShadow="none"
                isSubmitting={props.isSubmitting}
                disabled={props.isSubmitting || !props.dirty}
              >
                Save
              </SButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );

  return (
    <>
      {openConfirmationDialog && (
        <AlertDialogSlide
          maxWidth="xs"
          title="Discard Changes"
          footer={
            <div className="flex w-full justify-between px-4 py-2">
              <SButton
                margin="0 0.25rem"
                color="transparent"
                border="1px solid #1C78AD"
                textColor="blueShade"
                lineHeight="m"
                width="m"
                type="button"
                onClick={() => setOpenConfirmationDialog(false)}
              >
                Cancel
              </SButton>
              <SButton
                margin="0 0.25rem"
                color="blueShade"
                textColor="white"
                lineHeight="m"
                width="m"
                type="button"
                onClick={() => history.push(AUTH_INVENTORY_PRODUCT_CLASSES_LIST)}
              >
                Discard
              </SButton>
            </div>
          }
        >
          <p className="pb-3 text-center font-medium">
            You have unsaved changes, are you sure you want to discard changes?
          </p>
        </AlertDialogSlide>
      )}
      {openDeleteDialog && (
        <AlertDialogSlide
          title="Delete Product Class"
          footer={
            <div className="flex w-full justify-between px-4 py-2">
              <SButton
                margin="0 0.25rem"
                color="transparent"
                border="1px solid #1C78AD"
                textColor="blueShade"
                lineHeight="m"
                width="m"
                type="button"
                onClick={() => setOpenDeleteDialog(false)}
              >
                Cancel
              </SButton>
              <SButton
                margin="0 0.25rem"
                color="red"
                textColor="white"
                lineHeight="m"
                width="m"
                type="button"
                onClick={() => {
                  setOpenConfirmationDialog(false);
                  onDelete();
                }}
              >
                Delete
              </SButton>
            </div>
          }
        >
          <p className="pb-3">Are you sure you want to delete this Product Class? </p>
        </AlertDialogSlide>
      )}
      <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
        <Layout leftPanel={_addProductClass} splitWidthType={4} />
        <LoadingIndicator isLoading={isLoading} size="md" color="hsl(var(--primary))" />
      </div>
    </>
  );
};
