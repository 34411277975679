import React, {useState, useEffect} from 'react';
import {css} from 'glamor';
import {makeStyles} from '@material-ui/core';
import {PaymentBatchStatusTypes} from '../../model/constants/Constants';
import {usePaymentBatchAPI} from '../../services/usePaymentBatchAPI';
import LoadingIndicator from '../ui/LoadingIndicator';
import {IPaymentBatchForApproval} from '../../model/payment-batch/PaymentBatch';
import {PriceFormat} from '../../utils/formatter';
import moment from 'moment';
import {useHistory} from 'react-router-dom';
import {AUTH_BUYING_AP_BATCH_BATCH_VIEW, AUTH_PURCHASING_PAY_BILL_EDIT_ROUTE} from '../../routes/AccountsPayableRoutes';

const useDraftBatchStyle = makeStyles({
  panelRightBody: {
    borderRadius: '6px',
    '& .heading': {
      height: '60px',
    },
  },
  batchTableHeight: {
    height: 'calc(100vh - 200px)',
    marginTop: '18px',
    padding: '0px 10px',
    '&.editBatchTableHeight': {
      padding: '0px',
      marginTop: '0px',
    },
  },
  draftBatchBox: {
    border: '1px solid #F1F1F1',
    background: '#FAFAFA',
    borderRadius: '6px',
    padding: '12px 10px',
    marginBottom: '10px',
    '& .supplierName': {
      color: '#999',
    },
  },
  overDueDateStyle: {
    color: '#C55D44',
    borderRadius: '4px',
    backgroundColor: '#F8E1DC',
    fontSize: '10px',
    padding: '4px 12px',
    fontWeight: 500,
    marginTop: '3px',
  },
  dueDateStyle: {
    color: '#1C78AD',
    borderRadius: '4px',
    backgroundColor: '#D3E5EF50',
    fontSize: '10px',
    padding: '4px 12px',
    fontWeight: 500,
    marginTop: '3px',
  },
  batchType: {
    color: '#8178CF',
    fontSize: '10px',
    fontWeight: 500,
    background: '#8178CF20',
    padding: '4px 11px',
    borderRadius: '4px',
    marginLeft: '6px',
  },
  backBtn: {
    maxWidth: '40px',
    height: '40px',
    marginRight: '10px',
  },
  payInvoiceText: {
    width: '214px',
    height: '40px',
    '& .MuiFormControl-root': {
      background: '#fff',
      paddingBottom: '0px',
      height: '100%',
      '& .MuiOutlinedInput-root': {
        height: '100%',
        backgroundColor: 'transparent',
        borderRadius: '6px',
      },
      '& .MuiFormLabel-root': {
        color: '#333',
        fontFamily: 'Poppins, sans-serif !important',
        width: 'auto',
        fontWeight: 500,
        fontSize: '13px',
        top: '-7px',
        left: '-5px',
      },
      '& .MuiInputLabel-shrink': {
        top: '0px',
        left: 0,
        paddingRight: '2px',
        marginLeft: '-4px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#333',
        '& legend': {
          height: '5px',
        },
      },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#333!important',
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: '#333',
      },
      '& .MuiInputBase-input': {
        fontSize: '14px',
        fontWeight: 600,
        padding: '0px 8px',
        fontFamily: 'Poppins !important',
        color: '#333',
      },
      '& .MuiIconButton-root': {
        color: '#1C78AD',
      },
      '& .MuiInputBase-adornedEnd': {
        paddingRight: '0px',
      },
    },
  },
  pickerDialog: {
    '& .MuiPickersToolbar-toolbar': {
      backgroundColor: '#1C78AD',
    },
    '& .MuiPickersCalendarHeader-dayLabel.MuiPickersCalendarHeader-dayLabel': {
      color: '#333',
    },
    '& .MuiPickersCalendar-week': {
      '& .MuiPickersDay-day': {
        color: '#333',
      },
      '& .MuiPickersDay-dayDisabled': {
        color: '#33333350',
      },
      '& .MuiPickersDay-daySelected': {
        backgroundColor: '#1C78AD',
        color: '#fff',
      },
    },
  },
  actionBtns: {
    fontWeight: 600,
  },
});

export const DraftBatch = (props: {
  setShowDraftList: React.Dispatch<React.SetStateAction<boolean>>;
  t2TV3: boolean;
}) => {
  const history = useHistory();
  const classes = useDraftBatchStyle();
  const {getPaymentBatches, isLoading: batchLoader} = usePaymentBatchAPI();
  const [drafts, setDrafts] = useState<IPaymentBatchForApproval[]>();

  useEffect(() => {
    getPaymentBatches({
      StartRow: 1,
      MaxResults: 100,
      Status: [PaymentBatchStatusTypes.Open],
    }).then(response => {
      setDrafts(response.value);
    });
  }, []);

  const handleOnBatchClick = (draftBatch: IPaymentBatchForApproval) => {
    props.setShowDraftList(false);
    if (draftBatch.abaFileID) {
      history.replace(AUTH_BUYING_AP_BATCH_BATCH_VIEW.replace(/:batchID/g, draftBatch.accountsPayableBatchID));
    } else {
      history.replace(AUTH_PURCHASING_PAY_BILL_EDIT_ROUTE.replace(/:batchID/g, draftBatch.accountsPayableBatchID));
    }
  };

  return (
    <div
      className={`${classes.panelRightBody} DraftBatch relative flex h-full w-full items-center justify-center bg-white`}
    >
      <div className="h-full w-full font-poppins">
        <div
          className={`heading flex items-center justify-between px-3 pb-3 pt-4 ${css({
            borderBottom: '1px solid #D8D8D8',
          })}`}
        >
          <div className="text-xl text-spenda-primarytext">{`Drafts(${drafts ? drafts.length : 0})`}</div>
        </div>
        {batchLoader ? (
          <LoadingIndicator isLoading={true} size="md" color="hsl(var(--primary))" />
        ) : (
          <div className={`flex flex-col overflow-y-auto overflow-x-hidden ${classes.batchTableHeight}`}>
            {drafts && drafts.length
              ? drafts.map((draft, index) => {
                  return (
                    <div className={`flex ${classes.draftBatchBox}`} key={index}>
                      <div
                        className="flex w-7/12 flex-col"
                        style={{cursor: 'pointer'}}
                        onClick={() => handleOnBatchClick(draft)}
                        data-autoid={`lnkDraftedBatch-${draft.refNumber}`}
                      >
                        {draft?.abaFileID ? (
                          <div className="text-md mb-1 flex items-center justify-start font-medium text-spenda-primarytext">
                            {draft?.name}
                            <span className={classes.batchType}>ABA</span>
                          </div>
                        ) : (
                          <span className="text-md mb-1 font-medium text-spenda-primarytext">{draft?.name}</span>
                        )}
                        <span className="mb-1 text-xs text-spenda-primarytext">{`Batch ID: ${draft?.accountsPayableBatchID}`}</span>
                        {!props.t2TV3 && (
                          <span className="mb-1 text-xs text-spenda-primarytext">{`No. of records: ${draft?.countCredits}`}</span>
                        )}
                      </div>
                      <div className="flex w-5/12 flex-col items-center justify-center">
                        <span className="text-md font-semibold text-spenda-primarytext">
                          {PriceFormat(draft?.paymentAmount)}
                        </span>
                        <span className={`text-xs ${classes.overDueDateStyle}`}>{`Payment- ${moment(
                          draft.dueDate,
                        ).format('Do MMM YYYY')}`}</span>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        )}
      </div>
    </div>
  );
};
