import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import clsx from 'clsx';
import {pick} from 'lodash';
import * as Yup from 'yup';

import AppContext from '../../context/app/appContext';
import {AUTH_PURCHASING_REQUISITION_DETAIL} from '../../routes/PurchasingRoutes';
import {DatTypes, DeliveryMethods, RequisitionStatusType, RequisitionType} from '../../model/constants/Constants';
import {IRequisition} from '../../model/requisition/Requisition';
import {IWarehouse} from '../../model/Warehouse';
import {ISupplier} from '../../model/supplier/Supplier';
import {SDialog} from '../modals/modalSpendaMeterialUI';
import SDatePicker from '../pickers/DatePicker';
import {STextField} from '../inputs/STextField';
import LoadingIndicator from '../ui/LoadingIndicator';
import {SwitchSlider} from '../form/SwitchSlider';
import {PrimaryButton} from '../buttons/DefaultButtons';
import useRequisitionAPI from '../../services/useRequisitionAPI';
import {useSupplierAPI} from '../../services/useSupplierAPI';
import useWarehouseAPI from '../../services/useWarehouseAPI';
import {Toast} from '../../utils/Toast';

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

interface ICreatePurchaseInvoice {
  open: boolean;
  onClose: (refetchList?: boolean) => void;
  requisitionID?: any;
  isReadOnly?: boolean;
}

const useCreateRequisitionDialog = makeStyles(() => ({
  dialogPaper: {
    minHeight: '60vh',
    fontFamily: 'Poppins, sans-serif',
  },
  dialogTitle: {
    borderBottom: '1px solid #EAEAEA',
    '& h2': {
      fontSize: '22px',
      fontFamily: 'Poppins, sans-serif',
    },
  },
  dialogContent: {},
  dialogActions: {
    margin: '8px',
    justifyContent: 'center',
  },
  detailContainer: {
    maxWidth: 450,
  },
  iconButton: {
    color: '#1C78AD',
    border: '1px solid #1C78AD',
    borderRadius: '6px',
    padding: '5px',
    margin: '0 0.25rem',
    width: 40,
    height: 40,
    position: 'absolute',
  },
}));

const StyledMenuItem = withStyles({
  root: {
    fontFamily: 'Poppins !important',
    '&:hover': {
      backgroundColor: '#1C78AD20 !important',
    },
  },
  selected: {
    backgroundColor: '#1C78AD50 !important',
    '&:hover': {
      backgroundColor: '#1C78AD50 !important',
    },
  },
})(MenuItem);

const validationSchema = Yup.object({
  Name: Yup.string().required('Reason is required.').max(50, 'Reason cannot be longer than 50 characters.'),
  DueDate: Yup.date().required('Due date is required.'),
  OrderDate: Yup.date().required('Order date is required.'),
  ToWarehouseID: Yup.string().required('Warehouse is required.'),
  DeliveryMethod: Yup.string().required('Delivery method is required.'),
});

export interface ICreateRequisitionValues extends Partial<IRequisition> {}

const newRequisitionValues: ICreateRequisitionValues = {
  Status: RequisitionStatusType.New,
  RequisitionType: RequisitionType.Purchase,
  LinkedTrans: [],
  OrderDate: new Date(),
  DueDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
  IsActive: true,
  Lines: [],
  DeliveryMethod: DeliveryMethods.Deliver,
  Name: '',
  ToWarehouseID: 0,
  IsReplenishMinToMax: false,
};

export const CreateRequisitionDialog = (props: ICreatePurchaseInvoice) => {
  const history = useHistory();
  const {tenantInfo} = useContext(AppContext);
  const classes = useCreateRequisitionDialog();
  const {open, onClose, requisitionID, isReadOnly} = props;

  const defaultWarehouseID = tenantInfo?.TenantUserDetails?.DefaultWarehouseID;

  const [requisitionToEdit, setRequisitionToEdit] = React.useState<ICreateRequisitionValues>(newRequisitionValues);
  const [warehouses, setWarehouses] = React.useState<IWarehouse[]>([]);

  const {get: getRequisition, save: saveRequisition, isLoading: requisitionLoading} = useRequisitionAPI();

  const {search: searchWarehouse, isLoading: warehouseLoading} = useWarehouseAPI();

  const isLoading = requisitionLoading || warehouseLoading;

  useEffect(() => {
    if (open && requisitionID) {
      getRequisition(Number(requisitionID)).then(res => {
        setRequisitionToEdit(res);
        searchWarehouse().then(res => {
          setWarehouses(res);
        });
      });
    } else if (open && !requisitionID) {
      searchWarehouse().then(res => {
        setRequisitionToEdit({...requisitionToEdit, ToWarehouseID: defaultWarehouseID});
        setWarehouses(res);
      });
    }
  }, [open, requisitionID]);

  const handleCancelClick = (refetch: boolean = false) => {
    onClose(refetch);
  };

  const onSubmit = async (
    values: ICreateRequisitionValues,
    helpers: FormikHelpers<ICreateRequisitionValues>,
  ): Promise<void> => {
    if (isReadOnly) {
      handleCancelClick();
      return;
    }
    let payload: ICreateRequisitionValues = pick(values, [
      'Status',
      'RequisitionType',
      'LinkedTrans',
      'DueDate',
      'OrderDate',
      'IsActive',
      'Lines',
      'DeliveryMethod',
      'Name',
      'ToWarehouseID',
      'IsReplenishMinToMax',
      'SupplierID',
    ]);
    if (!payload.IsReplenishMinToMax || !payload.SupplierID) {
      delete payload.SupplierID;
    }

    if (requisitionID) {
      return saveRequisition({...payload, ID: requisitionID}).then(res => {
        if (res && res.ID) {
          helpers.resetForm();
          handleCancelClick(true);
        }
      });
    } else {
      return saveRequisition(payload).then(res => {
        if (res && res.ID) {
          helpers.resetForm();
          Toast.info('Requisition created');
          history.push(AUTH_PURCHASING_REQUISITION_DETAIL.replace(/:requisitionID/g, String(res.ID)));
        }
      });
    }
  };

  return (
    <SDialog classes={{paper: classes.dialogPaper}} fullWidth maxWidth="md" open={open}>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={requisitionToEdit}
        onSubmit={onSubmit}
      >
        {props => (
          <>
            <DialogTitle
              className={clsx(classes.dialogTitle, 'font-lg text-center font-light text-spenda-primarytext')}
            >
              Purchase details
              <IconButton
                disableRipple
                className={clsx(classes.iconButton, 'right-4 top-4')}
                data-autoid="btnClose"
                onClick={() => onClose()}
              >
                <CloseRoundedIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent className="flex flex-col items-center justify-center">
              <LoadingIndicator
                isLoading={isLoading}
                position={{
                  height: '100% !important',
                  display: 'flex',
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                size="md"
                color="hsl(var(--primary))"
              />
              <p className="pb-4 text-center text-base text-spenda-primarytext  ">
                Enter a reason for this purchase and the default values for your purchase orders.
              </p>
              <Grid container spacing={2} className={clsx(classes.detailContainer)}>
                <Grid item sm={12}></Grid>
                <Grid item sm={12}>
                  <STextField
                    v2
                    fullWidth
                    id="Name"
                    name="Name"
                    label="What's the reason for this purchase? (required)"
                    placeholder="What's the reason for this purchase? (required)"
                    value={props.values.Name}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    error={props.touched?.Name && Boolean(props.errors?.Name)}
                    helperText={props.touched?.Name && props.errors?.Name}
                    onClearClick={() => props.setFieldValue('Name', '')}
                    disabled={isReadOnly}
                  />
                </Grid>
                <Grid item sm={6}>
                  <SDatePicker
                    v2
                    autoOk
                    fullWidth
                    variant="inline"
                    id="OrderDate"
                    name="OrderDate"
                    inputVariant="outlined"
                    label="Order date"
                    format="dd/MM/yyyy"
                    value={props.values.OrderDate || null}
                    onChange={date => props.setFieldValue('OrderDate', date)}
                    helperText={props.touched?.OrderDate && props.errors?.OrderDate}
                    error={props.touched?.OrderDate && Boolean(props.errors?.OrderDate)}
                    disabled={isReadOnly}
                  />
                </Grid>
                <Grid item sm={6}>
                  <SDatePicker
                    v2
                    autoOk
                    fullWidth
                    variant="inline"
                    id="DueDate"
                    name="DueDate"
                    inputVariant="outlined"
                    label="Required date"
                    format="dd/MM/yyyy"
                    value={props.values.DueDate || null}
                    onChange={date => props.setFieldValue('DueDate', date)}
                    helperText={props.touched?.DueDate && props.errors?.DueDate}
                    error={props.touched?.DueDate && Boolean(props.errors?.DueDate)}
                    disabled={isReadOnly}
                  />
                </Grid>
                <Grid item sm={6}>
                  <STextField
                    v2
                    fullWidth
                    select
                    id="ToWarehouseID"
                    name="ToWarehouseID"
                    variant="outlined"
                    label="Warehouse"
                    placeholder="Warehouse"
                    value={props.values.ToWarehouseID || ''}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    helperText={props.touched?.ToWarehouseID && props.errors?.ToWarehouseID}
                    error={props.touched?.ToWarehouseID && Boolean(props.errors?.ToWarehouseID)}
                    disabled={isReadOnly}
                  >
                    {warehouses.map(warehouse => (
                      <StyledMenuItem key={warehouse.ID} value={warehouse.ID}>
                        {warehouse.Name}
                      </StyledMenuItem>
                    ))}
                  </STextField>
                </Grid>
                <Grid item sm={6}>
                  <STextField
                    v2
                    select
                    fullWidth
                    id="DeliveryMethod"
                    name="DeliveryMethod"
                    variant="outlined"
                    label="Delivery method"
                    placeholder="Delivery method"
                    value={props.values.DeliveryMethod}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    helperText={props.touched?.DeliveryMethod && props.errors?.DeliveryMethod}
                    error={props.touched?.DeliveryMethod && Boolean(props.errors?.DeliveryMethod)}
                    disabled={isReadOnly}
                  >
                    <StyledMenuItem key={DeliveryMethods.Deliver} value={DeliveryMethods.Deliver}>
                      {DeliveryMethods.Deliver}
                    </StyledMenuItem>
                    <StyledMenuItem key={DeliveryMethods.Warehouse_Pickup} value={DeliveryMethods.Warehouse_Pickup}>
                      {DeliveryMethods.Warehouse_Pickup}
                    </StyledMenuItem>
                    <StyledMenuItem key={DeliveryMethods.Dropship} value={DeliveryMethods.Dropship}>
                      {DeliveryMethods.Dropship}
                    </StyledMenuItem>
                  </STextField>
                </Grid>
                {!props.values.ID ? (
                  <>
                    <Grid item sm={12} className="flex items-center justify-start font-poppins">
                      <SwitchSlider
                        disabled={isReadOnly}
                        handleChange={props.handleChange}
                        checked={Boolean(props.values.IsReplenishMinToMax)}
                        name="IsReplenishMinToMax"
                        label={
                          <span className="font-poppins text-xs text-spenda-primarytext">
                            Suggest what I need to purchase based on my min/max levels.
                          </span>
                        }
                      ></SwitchSlider>
                    </Grid>
                    {props.values.IsReplenishMinToMax ? <SelectSupplier {...props} /> : null}
                  </>
                ) : null}
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <PrimaryButton
                margin="0 0 0 8px"
                label={isReadOnly ? 'Close' : 'Save & Close'}
                disabled={!props.isValid}
                onClick={props.handleSubmit}
              />
            </DialogActions>
          </>
        )}
      </Formik>
    </SDialog>
  );
};

interface ISelectSupplier extends FormikProps<ICreateRequisitionValues> {}

const initialFilter = {
  IsShowActiveOnly: true,
  DatTypeID: DatTypes.Suppliers,
  IsApprovedForWeb: true,
  IsShowAccountCustomersOnly: false,
  MaxResults: 1000,
  IsExactMatch: false,
  SortAsc: true,
  SortField: 'Name',
};

const SelectSupplier = (props: ISelectSupplier) => {
  const {values, setFieldValue} = props;

  const [supplierSearchRes, setSupplierSearchRes] = useState<ISupplier[]>([]);

  const {dashboard, getSupplierById, isLoading} = useSupplierAPI();

  useEffect(() => {
    if (values.SupplierID) {
      getSupplierById(values.SupplierID).then(res => {
        setSupplierSearchRes([res]);
      });
    } else {
      getSupplierList();
    }
  }, [values.SupplierID, values.ID]);

  const getSupplierList = (newValue: string = '') => {
    dashboard({
      ...initialFilter,
      ...(newValue ? {SearchString: newValue} : {}),
    }).then((res: ISupplier[]) => {
      setSupplierSearchRes(res || []);
    });
  };

  const handleSupplierClick = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue('SupplierID', e.target.value);
  };

  return (
    <Grid item sm={12} className="relative flex items-center justify-start font-poppins">
      <STextField
        v2
        select
        fullWidth
        name="SelectSupplier"
        placeholder="Select Supplier"
        value={values.SupplierID || ''}
        onChange={handleSupplierClick}
        variant="outlined"
        disabled={isLoading}
        SelectProps={{
          displayEmpty: true,
        }}
      >
        {!values.SupplierID ? <StyledMenuItem value="">All Supplier</StyledMenuItem> : null}
        {supplierSearchRes.map(supplier => (
          <StyledMenuItem key={supplier.ID} value={supplier.ID}>
            {supplier.Name}
          </StyledMenuItem>
        ))}
      </STextField>
    </Grid>
  );
};
