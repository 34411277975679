import * as React from 'react';

export const ImageIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      {...props}
    >
      <g fill="#333" fillRule="evenodd" clipRule="evenodd" opacity={0.2}>
        <path d="M15.833 0H4.167A4.172 4.172 0 000 4.167v11.666A4.172 4.172 0 004.167 20h11.666A4.172 4.172 0 0020 15.833V4.167A4.172 4.172 0 0015.833 0zM4.167 1.667h11.666a2.5 2.5 0 012.5 2.5v11.666a2.459 2.459 0 01-.25 1.071l-7.635-7.636a4.167 4.167 0 00-5.894 0l-2.887 2.887V4.167a2.5 2.5 0 012.5-2.5zm0 16.666a2.5 2.5 0 01-2.5-2.5v-1.321l4.065-4.065a2.5 2.5 0 013.536 0l7.636 7.636a2.459 2.459 0 01-1.07.25H4.166z" />
        <path d="M13.334 8.75a2.917 2.917 0 100-5.833 2.917 2.917 0 000 5.833zm0-4.167a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5z" />
      </g>
    </svg>
  );
};
