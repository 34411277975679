import React, {useEffect, useState} from 'react';
import {WidgetPanel} from '../../components/AccountsReceivable/WidgetPanel';
import {MarketplaceLayout} from '../purchasing/MarketplaceLayout';
import {PSBLFooter} from '../../components/AccountsReceivable/PSBLFooter';
import {PsblLendingView} from './psbl/PsblLendingView';
import {LendingWidgetPanel} from '../../components/AccountsReceivable/LendingWidgetPanel';
import {useSupplierTransactionsAPI} from '../../services/useSupplierTransactionsAPI';
import {IFacilityTransaction, IRepaymentSchedule} from '../../model/payment/Lending';
import LoadingIndicator from '../../components/ui/LoadingIndicator';

export const SpendaFinance = () => {
  //API
  const {getFacilities, isLoading} = useSupplierTransactionsAPI();

  //States
  const [facilitesData, setFacilitiesData] = useState<IFacilityTransaction>();
  const [selectedRepayments, setSelectedRepayments] = useState<IRepaymentSchedule[]>([]);

  const handleGetLendingBorrowerTransactions = async () => {
    const transactions = await getFacilities();
    setFacilitiesData(transactions?.facilities?.[0]);
  };

  useEffect(() => {
    handleGetLendingBorrowerTransactions();
  }, []);

  return (
    <>
      <MarketplaceLayout
        leftPanel={
          <PsblLendingView
            facilitytansaction={facilitesData as IFacilityTransaction}
            isFacilityDataLoading={isLoading}
            refreshBorrowerTransactions={handleGetLendingBorrowerTransactions}
          />
        }
        mainPanel={
          <WidgetPanel showChatBox={false}>
            {isLoading ? (
              <LoadingIndicator isLoading={isLoading} size="md" color="hsl(var(--primary))" />
            ) : (
              <LendingWidgetPanel
                facilityGuid={facilitesData?.facilityGUID as string}
                repayments={facilitesData?.repaymentSchedule as IRepaymentSchedule[]}
                selectedRepayments={selectedRepayments}
                setSelectedRepayments={setSelectedRepayments}
                refreshBorrowerTransactions={handleGetLendingBorrowerTransactions}
              />
            )}
          </WidgetPanel>
        }
        splitWidthType={'AR'}
      />
      <PSBLFooter />
    </>
  );
};
