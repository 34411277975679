import React from 'react';
import {css} from 'glamor';
import {Spinner} from 'spenda-ui-react';

export interface ILoadingIndicatorProps {
  isLoading?: boolean;
  color?: string;
  size?: 'sm' | 'md' | 'lg' | number;
  position?: {};
  text?: string;
}

const LoadingIndicator: React.FC<ILoadingIndicatorProps> = (props: ILoadingIndicatorProps) => {
  const {isLoading, color, size, position, text} = props;

  let loaderSize = 'h-7 w-7';

  switch (size) {
    case 'sm':
      loaderSize = 'h-5 w-5';
      break;
    case 'md':
      loaderSize = 'h-7 w-7';
      break;
    case 'lg':
      loaderSize = 'h-9 w-9';
      break;
    default:
      loaderSize = typeof size === 'number' ? `h-[${size}] w-[${size}]` : 'h-7 w-7';
      break;
  }

  const loaderPosition = css(position || {top: '50%', left: '50%', transform: 'translate(-50%, -50%)'});

  const spinner = isLoading ? (
    text ? (
      <div className="flex items-center gap-2">
        <Spinner className={`h-7 w-7 ${color ? `!text-[${color}]/40` : '!text-primary/40'}`} />
        <p className="font-poppins text-xl font-light text-primary">{text}</p>
      </div>
    ) : (
      <Spinner
        className={`absolute z-10 ${loaderSize} ${loaderPosition} ${color ? `!text-[${color}]/20` : '!text-primary/20'}`}
        data-autoid="loading-indicator"
      />
    )
  ) : (
    <></>
  );

  return spinner;
};

export default LoadingIndicator;
