import React, {useEffect, useState} from 'react';
import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  makeStyles,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import backButton from '../../assets/png/back-button.png';
import {AUTH_BUYING_AP_USER_SETTINGS} from '../../routes/AccountsPayableRoutes';
import {useHistory, useParams} from 'react-router-dom';
import {useAdaptorAPI} from '../../services/useAdaptorAPI';
import {IAdaptorSummary} from '../../model/synkd/AdaptorSummary';
import moment from 'moment';
import {IXeroPurchaseInvoice} from '../../model/purchase-invoice/purchaseInvoice';
import usePurchaseInvoiceAPI from '../../services/usePurchaseInvoiceAPI';
import {ISearchFilter} from '../../model/search-filters/SearchFilter';
import {InvoiceStatusTypes} from '../../model/constants/Constants';
import LoadingIndicator from '../ui/LoadingIndicator';
import {SyncedDetailsView} from '../../screens/AccountsReceivable/ARsettingsView/ARAdaptorView';

const useXeroInvoicesDetailsStyles = makeStyles({
  invoicesTable: {
    maxHeight: 'calc(100vh - 20em)',
    borderRadius: '2px 2px 2px 2px',
    '& table': {
      borderBottom: '2px solid #F8F8F8',
      borderRadius: '2px',
      '& th': {
        color: '#999999',
        fontWeight: 600,
        fontSize: '14px',
        fontFamily: 'Poppins',
        padding: '10px!important',
        lineHeight: '14px',
        height: '40px',
        '& .MuiTableSortLabel-root': {
          paddingRight: '15px',
          '& .MuiTableSortLabel-icon': {
            margin: '0px -7px 0px 0px',
            position: 'absolute',
            right: '0',
            transform: 'rotate(0deg)',
            opacity: '1',
            fontSize: '20px',
            '&.MuiTableSortLabel-iconDirectionAsc': {
              color: '#999',
            },
            '&.MuiTableSortLabel-iconDirectionDesc': {
              color: '#999',
            },
          },
          '&.MuiTableSortLabel-active': {
            color: '#999999',
            '& .MuiTableSortLabel-iconDirectionDesc': {
              color: '#9e9e9e',
            },
            '& .MuiTableSortLabel-iconDirectionAsc': {
              color: '#1c78ad!important',
            },
          },
        },
      },
      '& .MuiTableHead-root': {
        background: '#F1F1F1',
        top: '0',
        zIndex: '9',
        position: 'sticky',
      },
      '& .MuiTableCell-root': {
        borderBottom: '1px solid #F8F8F8',
        borderLeft: '0px',
        padding: '8px',
        position: 'relative',
      },
      '& .MuiTableCell-body': {
        padding: '7px 10px',
        height: '40px',
        '&:nth-child(even)': {
          background: '#FAFAFA',
        },
        '& .tooltip-wrap:hover .tooltip': {
          display: 'block',
        },
        '& .tooltip': {
          position: 'absolute',
          padding: '7px 10px 0px',
          boxSizing: 'border-box',
          background: '#fff',
          border: '1px solid #fafafa',
          left: '-32px',
          zIndex: '999999',
          width: '170px',
          height: '89px',
          top: '43px',
          boxShadow: '0px 0px 4px 0 rgb(0 0 0 / 20%)',
          '&::after': {
            zIndex: '1',
            content: "''",
            position: 'absolute',
            width: '0',
            height: '0',
            marginLeft: '0',
            bottom: '0',
            top: '-8px',
            left: '49%',
            boxSizing: 'border-box',
            border: '7px solid #fff',
            borderColor: 'transparent transparent #fff #fff',
            transformOrigin: '0 0',
            transform: 'rotate(45deg)',
            boxShadow: '-4px -3px 4px 0px rgb(0 0 0 / 12%)',
          },
          '&::before': {
            zIndex: '10',
            content: "''",
            position: 'absolute',
            width: 0,
            height: 0,
            marginLeft: 0,
            bottom: 0,
            top: '0',
            left: '53%',
            boxSizing: 'border-box',
            border: '7px solid black',
            borderColor: 'transparent transparent #fff #fff',
            transformOrigin: '0 0',
            transform: 'rotate(136deg)',
          },
          '& li': {
            '& p': {
              fontSize: '10px',
              '&:first-child': {
                color: '#BFBFBF',
              },
            },
          },
        },
      },
    },
  },
  overDueCellColor: {
    color: '#c65d42',
  },
  allBatchRow: {
    '& .selectAllText': {
      marginLeft: '0px',
    },
  },
  allBatchHead: {
    fontSize: '16px',
    fontWeight: 400,
    margin: '0px',
  },
  allBatchHeader: {
    maxWidth: '406px',
    width: '100%',
    height: '78px',
    backgroundColor: '#EFF3F6',
    padding: '0px 62px',
    borderRadius: '6px',
    '& .batchHeaderWrap': {
      width: '33%',
    },
  },
  invoicesToggler: {
    '& img': {
      minWidth: '25px !important',
      width: '25px',
      height: '25px !important',
      marginTop: '0px',
    },
  },
  syncHeading: {
    fontSize: '10px',
    color: '#707070',
  },
  syncDetailHeading: {
    fontSize: '14px',
    color: '#4D4D4D',
  },
  syncDetailsText: {
    fontSize: '22px',
    color: '#333333',
  },
});

const dateFormater = (date: string) => moment(date).format('DD/MMM/yyyy');

export interface IXeroInvoicesDetailsProps {
  scope?: 'AP' | 'PSBL' | 'AR';
  arAdaptorLogId?: string;
}

export const XeroInvoicesDetails = (props: IXeroInvoicesDetailsProps) => {
  const {scope, arAdaptorLogId} = props;
  const classes = useXeroInvoicesDetailsStyles();
  const history = useHistory();
  const {adaptorLogId: apAdaptorLogId} = useParams<{adaptorLogId: string}>();
  const {getAdaptorLogs} = useAdaptorAPI();
  const {listPurchaseInvoice} = usePurchaseInvoiceAPI();
  const [purchaseInvoiceList, setPurchaseInvoiceList] = useState<IXeroPurchaseInvoice[]>([]);
  const [syncDetail, setSyncDetail] = useState<IAdaptorSummary>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isPsblScope = scope === 'PSBL';
  const isARScope = scope === 'AR';
  const adaptorLogId = isPsblScope || isARScope ? arAdaptorLogId : apAdaptorLogId;

  const getInvoiceDetails = async (adaptorLogId: string) => {
    if (adaptorLogId) {
      const syncDetail = await getAdaptorLogs(adaptorLogId);
      const ids: string[] = [];
      syncDetail.Records.forEach(item => ids.push(item.ID));
      const status = Object.keys(InvoiceStatusTypes);
      const filter: ISearchFilter = {
        StartRow: 1,
        MaxResults: 500,
        SortAsc: true,
        SortField: 'CreatedDateTime',
        IDs: ids,
        Status: status,
      };
      const list = await listPurchaseInvoice(filter);
      setPurchaseInvoiceList(list);
      setSyncDetail(syncDetail);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (adaptorLogId) {
      setIsLoading(true);
      getInvoiceDetails(adaptorLogId);
    }
  }, [adaptorLogId]);

  if (isPsblScope) {
    return (
      <SyncedDetailsView
        isLoading={isLoading}
        syncDetail={syncDetail}
        purchaseInvoiceList={purchaseInvoiceList}
        dateFormater={dateFormater}
      />
    );
  }

  return (
    <div>
      <div className="flex items-center justify-start">
        <span
          className="cursor-pointer"
          data-autoid="btnBack"
          onClick={() => {
            history.push(AUTH_BUYING_AP_USER_SETTINGS);
          }}
        >
          <img src={backButton} alt="Back Button" style={{width: '40px', height: '40px'}} />
        </span>
        <h2 className={`${classes.syncDetailsText}font-light ml-3 font-poppins `}>Synced details</h2>
      </div>
      {isLoading ? (
        <LoadingIndicator
          isLoading={isLoading}
          size="md"
          color="hsl(var(--primary))"
          position={{right: '46%', bottom: '50%'}}
        />
      ) : (
        <>
          <Box className={`${classes.allBatchHeader} mt-5 flex items-center justify-between p-5`}>
            <div className="batchHeaderWrap">
              <p className={`${classes.syncHeading} font-poppins font-medium`}>Last update</p>
              <p className={`${classes.syncDetailHeading} font-poppins font-semibold`}>
                {syncDetail && dateFormater(syncDetail?.ActionDateTimeUTC)}
              </p>
            </div>
            <div className="batchHeaderWrap">
              <p className={`${classes.syncHeading} font-poppins font-medium`}>Invoices synced</p>
              <p className={`${classes.syncDetailHeading} font-poppins font-semibold`}>
                {syncDetail && syncDetail?.count} invoices
              </p>
            </div>
          </Box>
          <div className={`${classes.allBatchRow} my-5 flex items-center justify-between`}>
            <TableContainer className={`${classes.invoicesTable} overflow-y-auto`}>
              <Table className="" aria-label="simple table" data-autoid="tblXeroInvoicesDetails">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" style={{width: '16%'}}>
                      <TableSortLabel IconComponent={ArrowDropDownIcon}>{'Purchase invoice'}</TableSortLabel>
                    </TableCell>
                    <TableCell align="left" style={{width: '16%'}}>
                      Supplier
                    </TableCell>
                    <TableCell align="left" style={{width: '16%'}}>
                      <TableSortLabel IconComponent={ArrowDropDownIcon}>{'Due date'}</TableSortLabel>
                    </TableCell>
                    <TableCell align="right" style={{width: '16%'}}>
                      <TableSortLabel IconComponent={ArrowDropDownIcon}>{'Invoice total'}</TableSortLabel>
                    </TableCell>
                    <TableCell align="right" style={{width: '16%'}}>
                      <TableSortLabel IconComponent={ArrowDropDownIcon}>{'Outstanding'}</TableSortLabel>
                    </TableCell>
                    <TableCell align="right" style={{width: '16%'}}>
                      <TableSortLabel IconComponent={ArrowDropDownIcon}>{'Pay amount'}</TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {purchaseInvoiceList &&
                    purchaseInvoiceList.map(item => {
                      return (
                        <TableRow key={item.RefNumber}>
                          <TableCell className="text-black-800">
                            <span className="supplierName font-poppins text-base font-medium text-black-800">
                              {item.RefNumber}
                            </span>
                          </TableCell>
                          <TableCell align="left">
                            <span className="cursor-pointer font-poppins text-base font-medium text-black-800 underline">
                              {item.OtherPartyName}
                            </span>
                          </TableCell>
                          <TableCell align="left">
                            <span className={`${classes.overDueCellColor} font-poppins text-base font-semibold`}>
                              {item?.DueDate && dateFormater(item.DueDate)}
                            </span>
                          </TableCell>
                          <TableCell align="right">
                            <span className="font-poppins text-base font-medium text-black-800">${item.TotalInc}</span>
                          </TableCell>
                          <TableCell align="right">
                            <span className="font-poppins text-base font-medium text-black-800">${item.TotalInc}</span>
                          </TableCell>
                          <TableCell align="right">
                            <span className="font-poppins text-base font-medium text-black-800">${item.TotalInc}</span>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}
    </div>
  );
};
