import React, {FC, useEffect, useState} from 'react';
import {AlertDialogSlideV2, IDialogActionV2} from './AlertDialogSlideV2';
import QuoteAttachment from '../../screens/quote-management/QuoteAttachment';
import {useAttachmentsAPI} from '../../services/useAttachmentsAPI';
import {AttachmentTypeEnum} from '../../model/constants/Constants';
import {IQuoteAttachment} from '../../model/quotes/quotes';
import {Toast} from '../../utils/Toast';
import {LoadingOverlayV1} from '../ui/LoadingOverlayV1';

type QuoteAttachmentDialogProps = {
  customerView?: boolean;
  loading?: boolean;
  open: boolean;
  quoteID?: number;
  deleteQuoteAttachment?: (guid: string) => void;
  fetchAttachments?: () => void;
  handleOpenAndClose: React.Dispatch<React.SetStateAction<boolean>>;
  uploadedAttachments?: IQuoteAttachment[];
};

export type FileType = {
  file: File;
  uri: string;
};

export const QuoteAttachmentDialog: FC<QuoteAttachmentDialogProps> = ({
  handleOpenAndClose,
  uploadedAttachments,
  deleteQuoteAttachment,
  fetchAttachments,
  customerView,
  loading,
  ...props
}) => {
  const {uploadAttachments, isLoading} = useAttachmentsAPI();
  const [addedFilesToUpload, setAddedFilesToUpload] = useState<FileType[]>([]);

  loading = loading || isLoading;

  const deleteFile = (index: number) => {
    let data = [...addedFilesToUpload];
    if (index >= 0) {
      data.splice(index, 1);
      setAddedFilesToUpload(data);
    }
  };

  const onSubmit = async () => {
    try {
      if (!props.quoteID) return;

      if (addedFilesToUpload?.length) {
        const formData = new FormData();

        addedFilesToUpload?.forEach(attachment => {
          formData.append('Files', attachment.file, attachment.file.name);
        });

        const attachments = addedFilesToUpload?.map((n, i) => {
          return {
            Caption: addedFilesToUpload?.[i]?.file.name,
            Note: '',
            Action: 0,
          };
        });

        const jsonRequest = {
          QuoteID: props.quoteID,
          Attachments: attachments || [],
        };

        formData.append('AttachmentType', JSON.stringify(AttachmentTypeEnum.Quotes));
        formData.append('JsonRequest', JSON.stringify(jsonRequest));

        await uploadAttachments(formData, {'content-type': 'multipart/form-data'});
      }
    } catch {
      Toast.error('Failed to upload attachments');
    }
  };

  useEffect(() => {}, [customerView]);

  const actions: IDialogActionV2[] = [
    {
      label: customerView ? 'Close' : 'Save & Close',
      loading: loading,
      disabled: loading,
      action: async () => {
        if (customerView) {
          handleOpenAndClose(false);
        } else {
          await onSubmit();
          fetchAttachments?.();
          handleOpenAndClose(false);
        }
      },
    },
  ];

  if (!customerView) {
    actions.unshift({
      label: 'Cancel',
      variant: 'outlined',
      action: () => {
        handleOpenAndClose(false);
      },
    });
  }

  return (
    <AlertDialogSlideV2
      title={customerView ? 'View attachment' : 'Add attachment'}
      headingTextSize="h2"
      data-autoid={customerView ? 'dlgViewAttachment' : 'dlgAddAttachment'}
      dialogActionsAlignment={customerView ? 'justify-center' : 'justify-between'}
      dialogBodyClassess="flex flex-col first:gap-y-5  gap-y-2 items-center h-[330px] pt-7 overflow-y-auto max-h-[18rem]"
      actions={actions}
    >
      <LoadingOverlayV1 isLoading={loading}>
        <QuoteAttachment
          addedFilesToUpload={addedFilesToUpload}
          setAddedFilesToUpload={setAddedFilesToUpload}
          deleteFile={deleteFile}
          isUploading={loading}
          disabled={loading}
          uploadedAttachments={uploadedAttachments}
          deleteQuoteAttachment={deleteQuoteAttachment}
          isEditAble={!customerView}
        />
      </LoadingOverlayV1>
    </AlertDialogSlideV2>
  );
};
