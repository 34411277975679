import React from 'react';
import {SModal} from '../modals/modalSpendaMeterialUI';
import {Box} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {AddProduct} from '../../screens/product-mangement/AddProduct';
import {Actions} from '../../model/constants/Constants';
import {IPostingInventoryRequest} from '../../model/requires-attention/StagedTransactions';
import {IStagingInventory} from '../../model/inventory/Inventory';

export interface ICreateNewProductItemModalProps {
  handleClose: () => void;
  action?: Actions;
  supplierID?: number;
  inventoryCode?: string;
  handleCreateAction?: () => void;
  requestItems?: IPostingInventoryRequest[];
  isMultiplePostingSelected?: boolean;
  selectedRuleID?: number;
  hideActionButtons?: boolean;
  stagingInventoryData?: IStagingInventory;
  childVariantID?: number;
  isInternalTab?: boolean;
  setOpenChildItemEdit?: (value: boolean) => void;
  handleEditMasterPrice?: () => void;
}

export const CreateNewProductItemModal = ({handleClose, ...rest}: ICreateNewProductItemModalProps) => {
  return (
    <SModal open>
      <div className={`spenda-color flex h-full max-w-full items-center justify-center font-poppins text-xl`}>
        <div className={`modal-box !min-h-[700px] !max-w-[1020px] pb-5 pt-3 text-spenda-primarytext`}>
          <Box className={`flex justify-between border-b border-[#CCCCCC] px-2.5 pb-3`}>
            <h2 className={`w-full self-center text-center text-xl font-light`}>Product details</h2>
            <Box className="flex justify-end text-right">
              <span className="ap-onboarding-close-icon">
                <CloseIcon data-autoid="btnClose" onClick={handleClose} className="text-primary" />
              </span>
            </Box>
          </Box>
          <Box className={`h-[93%] pt-2 text-base font-medium`}>
            <AddProduct key={'create-product'} context="AP" isInDialog={true} handleClose={handleClose} {...rest} />
          </Box>
        </div>
      </div>
    </SModal>
  );
};
