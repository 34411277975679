import {Form, Formik, getIn} from 'formik';
import React, {useContext} from 'react';
import {Button, Dialog, DialogBody, DialogFooter, DialogHeader, Input, Typography} from 'spenda-ui-react';
import {ILocation} from '../../model/address/Location';
import * as Yup from 'yup';
import QuoteContext from '../../context/quote-context/QuoteContext';
import {useCustomersAPI} from '../../services/useCustomersAPI';
import {FormikHelpers} from 'formik';

interface QuoteUpdateContactDialogPropTypes {
  contacts?: ILocation[];
  handler: (value: boolean) => void;
  sendingType: 'email' | 'phone' | 'both';
  isPrimaryMissing?: boolean;
}

const validationSchemaForMissingMail = Yup.object().shape({
  contacts: Yup.array().of(
    Yup.object().shape({
      EmailAddress: Yup.string().email('Invalid email').required('Email is required'),
    }),
  ),
});

const validationSchemaForMissingPhone = Yup.object().shape({
  contacts: Yup.array().of(
    Yup.object().shape({
      PhoneMobile: Yup.string().required('Mobile No is required'),
    }),
  ),
});

const validationSchemaForBoth = Yup.object().shape({
  contacts: Yup.array().of(
    Yup.object().shape({
      EmailAddress: Yup.string().email('Invalid email').required('Email is required'),
      PhoneMobile: Yup.string().min(9, 'Invalid number').max(11, 'Invalid number').required('Mobile No is required'),
    }),
  ),
});

const QuoteUpdateContactDialog = (props: QuoteUpdateContactDialogPropTypes) => {
  const {handler, contacts, sendingType} = props;

  const {customerDetails, setCustomerDetails} = useContext(QuoteContext);
  const {saveContacts, getCustomer} = useCustomersAPI();

  const onSubmit = async (values: {contacts: ILocation[]}, helper: FormikHelpers<{contacts: ILocation[]}>) => {
    const payload = values.contacts.map(contact => {
      return {
        ...contact,
        PhoneMobile: contact.PhoneMobile,
        ID: contact.BusinessContactID,
      };
    });
    const response = await saveContacts(customerDetails?.ID!, payload);
    const latestCustomer = await getCustomer(customerDetails?.ID!);

    if (response) {
      handler(true);
      setCustomerDetails(latestCustomer);
    }
    helper.setSubmitting(false);
  };

  return (
    <Dialog open handler={() => {}}>
      <Formik
        initialValues={{
          contacts: contacts ? [...contacts] : [],
        }}
        validationSchema={
          sendingType === 'email'
            ? validationSchemaForMissingMail
            : sendingType === 'phone'
              ? validationSchemaForMissingPhone
              : validationSchemaForBoth
        }
        onSubmit={(values, helper) => {
          onSubmit(values, helper);
        }}
      >
        {({values, handleSubmit, handleBlur, handleChange, touched, errors, isSubmitting}) => {
          return (
            <Form
              onSubmit={e => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <DialogHeader data-autoid="dlgMissingInformation" className="justify-center border-b border-b-[#EAEAEA]">
                <Typography variant="h2">Missing important information</Typography>
              </DialogHeader>
              <DialogBody className="py-7">
                <div className="flex flex-col items-center justify-center gap-y-9">
                  <Typography className="w-[300px] text-center text-black-900" variant="small">
                    we are missing some vital customer details please provide the following:
                  </Typography>
                  <div className="flex flex-col gap-y-2.5">
                    {contacts?.map((contact, index) => (
                      <React.Fragment key={index}>
                        {sendingType === 'both' ? (
                          <div className="flex items-center gap-x-2.5">
                            <Typography
                              variant="small"
                              className="w-full min-w-[135px] max-w-[135px] truncate text-black-900"
                            >
                              {contact.FirstName} {contact.LastName}
                            </Typography>
                            {!contact.EmailAddress && (
                              <Input
                                name={`contacts.${index}.EmailAddress`}
                                label="Email"
                                className="w-full"
                                value={values.contacts[index].EmailAddress ?? ''}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                helperText={
                                  getIn(touched, `contacts.[${index}].EmailAddress`) &&
                                  getIn(errors, `contacts.[${index}].EmailAddress`)
                                }
                                error={
                                  getIn(touched, `contacts.[${index}].EmailAddress`) &&
                                  Boolean(getIn(errors, `contacts.[${index}].EmailAddress`))
                                }
                              />
                            )}
                            {!contact.Phone1 && (
                              <Input
                                containerProps={{
                                  className: '!min-w-[135px] !max-w-[135px] w-full',
                                }}
                                name={`contacts.${index}.PhoneMobile`}
                                label="Mobile"
                                type="number"
                                className="w-full"
                                value={values.contacts[index].PhoneMobile}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                helperText={
                                  getIn(touched, `contacts.[${index}].PhoneMobile`) &&
                                  getIn(errors, `contacts.[${index}].PhoneMobile`)
                                }
                                error={
                                  getIn(touched, `contacts.[${index}].PhoneMobile`) &&
                                  Boolean(getIn(errors, `contacts.[${index}].PhoneMobile`))
                                }
                              />
                            )}
                          </div>
                        ) : sendingType === 'email' ? (
                          <div className="flex items-center gap-x-2.5">
                            <Typography
                              variant="small"
                              className="w-full min-w-[135px] max-w-[135px] truncate text-black-900"
                            >
                              {contact.FirstName} {contact.LastName}
                            </Typography>
                            <Input
                              name={`contacts.${index}.EmailAddress`}
                              label="Email"
                              className="w-full"
                              value={values.contacts[index].EmailAddress ?? ''}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              helperText={
                                getIn(touched, `contacts.[${index}].EmailAddress`) &&
                                getIn(errors, `contacts.[${index}].EmailAddress`)
                              }
                              error={
                                getIn(touched, `contacts.[${index}].EmailAddress`) &&
                                Boolean(getIn(errors, `contacts.[${index}].EmailAddress`))
                              }
                            />
                          </div>
                        ) : (
                          <div className="flex items-center gap-x-2.5">
                            <Typography
                              variant="small"
                              className="w-full min-w-[135px] max-w-[135px] truncate text-black-900"
                            >
                              {contact.FirstName} {contact.LastName}
                            </Typography>
                            <Input
                              containerProps={{
                                className: '!min-w-[135px] !max-w-[135px] w-full',
                              }}
                              name={`contacts.${index}.PhoneMobile`}
                              label="Mobile"
                              type="number"
                              className="w-full"
                              value={values.contacts[index].PhoneMobile}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              helperText={
                                getIn(touched, `contacts.[${index}].PhoneMobile`) &&
                                getIn(errors, `contacts.[${index}].PhoneMobile`)
                              }
                              error={
                                getIn(touched, `contacts.[${index}].PhoneMobile`) &&
                                Boolean(getIn(errors, `contacts.[${index}].PhoneMobile`))
                              }
                            />
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </DialogBody>
              <DialogFooter className="mx-2.5 mb-2.5 justify-between rounded-lg bg-spenda-footerBg p-2.5">
                <Button variant="outlined" onClick={() => handler(false)}>
                  Cancel
                </Button>
                <Button type="submit" disabled={isSubmitting} loading={isSubmitting}>
                  Send & Save
                </Button>
              </DialogFooter>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};
export default QuoteUpdateContactDialog;
