import useHttp from '../hooks/useHttp';
import {IActionResults} from '../model/ActionResults';
import {IAddProductVariation, IAddProductVariantsPayload} from '../model/variants';

const useVariantAPI = () => {
  const {POST, isLoading} = useHttp();

  const addVariation = async (
    masterInventoryID: string,
    data: IAddProductVariantsPayload,
  ): Promise<IActionResults<IAddProductVariation[]>> => {
    return POST(`inventory/${masterInventoryID}/variations`, data).then(
      (response: IActionResults<IAddProductVariation[]>) => response,
    );
  };

  const createSingleVariant = (
    masterInventoryID: string,
    variationOptionIDs: number[],
  ): Promise<IAddProductVariation> => {
    const queryParams = variationOptionIDs.map(id => `VariationOptionIDs=${id}`).join('&');
    const url = `inventory/${masterInventoryID}/generatevariant?${queryParams}`;
    return POST(url, {}).then((response: IAddProductVariation) => response);
  };

  const createAllChildVariants = (masterInventoryID: string): Promise<IAddProductVariation[]> => {
    return POST(`inventory/${masterInventoryID}/generateVariants`, {}).then(
      (response: {Value: IAddProductVariation[]}) => response.Value,
    );
  };

  return {
    addVariation,
    isLoading,
    createSingleVariant,
    createAllChildVariants,
  };
};

export default useVariantAPI;
