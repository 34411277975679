import React, {SetStateAction, useState} from 'react';
import {Button, Typography} from 'spenda-ui-react';
import moment from 'moment';

import {PriceFormat} from '../../utils/formatter';
import {QuoteAcceptDialog} from '../dialog/QuoteAcceptDialog';
import {QuoteRejectDialog} from '../dialog/QuoteRejectDialog';
import useQuotesAPI from '../../services/useQuotesAPI';
import {
  IActiveSession,
  ICustomerQuoteDetails,
  IQuoteAttachment,
  IQuoteSupplier,
  IQuotes,
  IQuotesPackage,
} from '../../model/quotes/quotes';
import {QuoteStatus} from '../../model/constants/Constants';
import {QuoteAcceptedRejectedDialog} from '../dialog/QuoteAcceptRejectDialog';
import {QuoteSessionTimeoutDialog} from '../dialog/QuoteSessionTimeoutDialog';
import {SpendaNoTagLogo} from '../../assets/svg/SpendaNoTagLogo';
import {QuoteAttachmentDialog} from '../dialog';
import PreviewAttachment from '../../screens/quote-management/PreviewAttachment';

interface ICustomerQuotePreviewMainPanel {
  activeSession?: IActiveSession;
  quoteVariantsCount?: number;
  inviteCode: string;
  quoteVariant: IQuotes;
  getQuoteDetails: (inviteCode: string, showExistingStatusDialog: boolean) => void;
  supplier?: IQuoteSupplier;
  quotePackage?: IQuotesPackage;
  isQuoteExpired?: boolean;
  handleRequestOTP: (acceptAuthorise: boolean, isSessionTimeout: boolean) => void;
  showSessionTimeoutDialog: boolean;
  setShowSessionTimeoutDialog: React.Dispatch<SetStateAction<boolean>>;
  setActiveSession: React.Dispatch<SetStateAction<IActiveSession>>;
  handleAcceptRejectClose: () => void;
  globalLimepay?: boolean;
  quoteTermsMediaFileURI?: string;
}

export const CustomerQuotePreviewMainPanel = (props: ICustomerQuotePreviewMainPanel) => {
  const {
    activeSession,
    quoteVariantsCount,
    inviteCode,
    quoteVariant,
    getQuoteDetails,
    supplier,
    quotePackage,
    isQuoteExpired,
    handleRequestOTP,
    showSessionTimeoutDialog,
    setShowSessionTimeoutDialog,
    setActiveSession,
    handleAcceptRejectClose,
    globalLimepay,
    quoteTermsMediaFileURI,
  } = props;

  // state
  const [showQuoteAcceptModal, setShowQuoteAcceptModal] = useState<boolean>(false);
  const [showQuoteAcceptRejectDialog, setShowQuoteAcceptRejectDialog] = useState<boolean>(false);
  const [showQuoteRejectModal, setShowQuoteRejectModal] = useState<boolean>(false);
  const [isAccepting, setIsAccepting] = useState<boolean>(false);
  const [isRejecting, setIsRejecting] = useState<boolean>(false);
  const {acceptQuoteByInviteCode, rejectQuoteByInviteCode, getQuoteDetailByInviteCode} = useQuotesAPI();
  const [showAttachmentDialog, setShowAttachmentDialog] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = React.useState({} as {uri: string; show: boolean; type: string});
  const [attachments, setAttachments] = useState<IQuoteAttachment[]>([]);

  const masking = (str: string, n: number) => {
    const normalValue = str.substring(0, n);
    const maskedValue = str.substring(n);
    return `${normalValue}${maskedValue.replace(/[^ ]/g, '*')}`;
  };

  const handleAcceptQuote = async (sessionGUID: string) => {
    await acceptQuoteByInviteCode(quoteVariant?.quoteGUID!, inviteCode, sessionGUID!);
    await getQuoteDetails(inviteCode, true);
    setIsAccepting(false);
    setShowQuoteAcceptModal(false);
  };

  const handleRejectQuote = async (rejectReason?: string) => {
    await rejectQuoteByInviteCode(quoteVariant?.quoteGUID!, inviteCode, rejectReason);
    await getQuoteDetails(inviteCode, false);
    setIsRejecting(false);
    setShowQuoteRejectModal(false);
    setShowQuoteAcceptRejectDialog(true);
  };

  const handleClose = () => {
    setShowQuoteAcceptRejectDialog(false);
  };

  const handleOpenAmountPayDialog = () => {
    setShowQuoteAcceptRejectDialog(false);
    handleAcceptRejectClose();
  };

  const CustomTableData = ({
    tableHead,
    tableCell,
    className,
  }: {
    tableHead: string;
    tableCell: string | React.ReactNode;
    className?: string;
  }) => {
    return (
      <>
        <td
          align="right"
          className={`w-[15%] border-b-2 border-[#E1E1E1] px-2 py-1 text-[6px] font-medium sm:w-[12%] sm:text-xs ${className}`}
        >
          {tableHead}
        </td>
        <td
          align="right"
          className={`w-[15%] border-b-2 border-[#E1E1E1] px-2 py-1 text-[6px] font-medium sm:w-[15%] sm:text-xs ${className}`}
        >
          {tableCell}
        </td>
      </>
    );
  };
  const address =
    quotePackage?.billStreetAddress +
    ' ' +
    quotePackage?.billCity +
    ' ' +
    quotePackage?.billState +
    ' ' +
    quotePackage?.billCountry +
    ' ' +
    quotePackage?.billPostCode;

  return (
    <div className={`h-[520px] w-full max-w-[750px] bg-white !text-[#333333] sm:h-[730px] 2xl:mr-5`}>
      <header
        className={`after:content-[' '] after:block; after:rounded-tl-0 after:rounded-tr-0 after:rounded-br-0 relative flex h-full flex-col
        rounded-[4px] rounded-tr-[40px] bg-white shadow-[0_3px_10px_rgb(227,238,245,1)] after:absolute after:right-0 after:top-0 
       after:z-0 after:w-[0px] after:overflow-y-auto after:rounded-bl-xl after:border-b-[0px] after:border-l-[30px] after:border-r-[0px] after:border-t-[30px]
       after:border-solid after:border-b-[#e1e1e1] after:border-l-[#e1e1e1] after:border-r-[#fff] after:border-t-[#fff] after:bg-[#E1E1E1]`}
      >
        <div className={`mb-5 px-5 sm:mx-2`}>
          <div className={`mt-5 flex justify-between`}>
            <div className="w-full max-w-[160px] pt-2 sm:max-w-max">
              {supplier?.logoUrl ? (
                <img src={supplier?.logoUrl} alt="Preview Logo" className={`h-[100px] w-[100px]`} />
              ) : (
                <SpendaNoTagLogo height="85px" className="sm:w-[200px]" />
              )}
            </div>
            <div className="pt-3">
              <Typography className="text-sm font-semibold sm:text-xl">Sales Quotation</Typography>
              <div className="flex justify-between">
                <div
                  className={`mt-2 flex w-full max-w-max flex-col items-center rounded-[10px] border-[3px] border-[#E1E1E1] px-5 py-1 sm:h-[61px] sm:max-w-[177px] sm:px-0 sm:pb-0 sm:pt-2`}
                >
                  <Typography className={`text-[6px] font-medium sm:text-xs`}>Amount Quoted:</Typography>
                  <Typography className={`text-xs font-semibold sm:text-base`}>
                    {PriceFormat(quoteVariant?.totalInc, '$', 2)}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between">
            <div data-autoid="txtSupplierName" className={`mb-1 flex flex-col text-xl`}>
              <Typography className="text-sm sm:text-xl">
                {activeSession?.isAuthorised
                  ? quotePackage?.contactFirstName + ' ' + quotePackage?.contactLastName
                  : masking(quotePackage?.contactFirstName + ' ' + quotePackage?.contactLastName, 3)}
              </Typography>
              <Typography className="max-w-[170px] text-[6px] sm:text-sm">
                {activeSession?.isAuthorised ? address : masking(address, 7)}
              </Typography>
            </div>
            <div>
              <div className="mb-1 flex flex-col items-end justify-end pt-5">
                <Typography className="text-[6px] sm:text-sm">{quoteVariant?.name}</Typography>
                <Typography className="pt-1 text-[6px] sm:text-sm">
                  Date: {moment(quotePackage?.sentDateTime_utc || new Date()).format('DD MMM YYYY')}
                </Typography>
                <Typography className="text-[6px] sm:text-sm">Ref #: {quotePackage?.refNumber}</Typography>
              </div>
            </div>
          </div>
          <hr />
          <div className="flex flex-row justify-between pb-1">
            <div className="my-1 flex flex-col">
              <Typography variant={'h3'}>Quote from:</Typography>
              <div>
                <Typography className="text-[6px] sm:text-sm">{supplier?.name}</Typography>
                <Typography className="text-[6px] sm:text-sm">ABN: {supplier?.abn}</Typography>
                <Typography className="text-[6px] sm:text-sm">Email: {supplier?.emailAddress}</Typography>
                <Typography className="text-[6px] sm:text-sm">
                  {supplier?.streetAddress} {supplier?.city}
                </Typography>
                <Typography className="text-[6px] sm:text-sm">
                  {supplier?.state} {supplier?.country} {supplier?.postCode}
                </Typography>
              </div>
            </div>
            {!activeSession?.isAuthorised && (
              <Typography className="w-2/3 pt-1 text-[6px] sm:text-sm">{quoteVariant?.description}</Typography>
            )}
          </div>
          <div className="max-h-[122px] overflow-y-auto">
            <table className="w-full text-left">
              <thead className="bg-[#E1E1E1]">
                <tr>
                  <th className="py-1.5 pl-[22px] pr-2 text-[6px] font-medium text-black-800 sm:text-xs md:pl-[38px]">
                    Description
                  </th>
                  {quotePackage?.quotes && quotePackage?.quotes[0].lines[0].quantity !== null && (
                    <>
                      <th align="center" className="px-2 py-1.5 text-[6px] font-medium text-black-800 sm:text-xs">
                        Qty
                      </th>
                      <th align="right" className="px-2 py-1.5 text-[6px] font-medium text-black-800 sm:text-xs">
                        Unit Price
                      </th>
                    </>
                  )}
                  <th align="right" className="px-2 py-1.5 text-[6px] font-medium text-black-800 sm:text-xs">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {quoteVariant?.lines?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td
                        className={`border-b-2 border-[#E1E1E1] py-1 pl-2 pr-2 sm:pl-4 ${item.quantity !== null ? 'w-3/6 sm:w-3/5' : 'w-[85%]'}`}
                      >
                        <div className="flex">
                          <Typography className="min-w-[10px] text-[6px] font-medium sm:text-xs">
                            {index + 1}.
                          </Typography>
                          <Typography className="!line-clamp-1 pl-1 text-[6px] font-medium sm:pl-3 sm:text-xs">
                            {item?.description}
                          </Typography>
                        </div>
                      </td>
                      {item.quantity !== null && (
                        <>
                          <td
                            width={'10%'}
                            align="center"
                            className="border-b-2 border-[#E1E1E1] px-2 py-1 text-[6px] font-medium sm:text-xs"
                          >
                            {item?.quantity} {item?.uoM}
                          </td>
                          <td
                            width={'15%'}
                            align="right"
                            className="border-b-2 border-[#E1E1E1] px-2 py-1 text-[6px] font-medium sm:text-xs"
                          >
                            {PriceFormat(item?.sellPriceEx, '$', 2)}
                          </td>
                        </>
                      )}
                      <td
                        width={'15%'}
                        align="right"
                        className="border-b-2 border-[#E1E1E1] px-2 py-1 text-[6px] font-medium sm:text-xs"
                      >
                        {PriceFormat(item?.lineTotalEx, '$', 2)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <table className="mb-8 w-full">
            <tbody>
              <tr>
                <td rowSpan={5} className={'w-[70%] sm:w-[75%]'}></td>
                <CustomTableData tableHead="Sub Total" tableCell={PriceFormat(quoteVariant?.totalEx, '$', 2)} />
              </tr>
              <tr>
                <CustomTableData tableHead="Tax" tableCell={PriceFormat(quoteVariant?.totalTax, '$', 2)} />
              </tr>
              <tr>
                <CustomTableData tableHead="Total" tableCell={PriceFormat(quoteVariant?.totalInc, '$', 2)} />
              </tr>
              <tr>
                <CustomTableData
                  tableHead="Amount paid"
                  tableCell={PriceFormat((quoteVariant?.totalInc || 0) - (quoteVariant?.balance || 0), '$', 2)}
                />
              </tr>
              <tr>
                <CustomTableData
                  tableHead="Balance"
                  tableCell={PriceFormat(quoteVariant?.balance, '$', 2)}
                  className="bg-[#E0E0E0] !font-bold"
                />
              </tr>
            </tbody>
          </table>
          {quoteTermsMediaFileURI ? (
            <span
              className="mb-5 cursor-pointer text-sm font-normal text-primary underline"
              onClick={() =>
                setPreviewImage({
                  uri: quoteTermsMediaFileURI,
                  show: true,
                  type: 'Document',
                })
              }
            >
              Read full terms and conditions here
            </span>
          ) : (
            <a
              href={'https://spenda.co/terms-conditions/'}
              target="_blank"
              className="mb-5 cursor-pointer text-sm font-normal text-primary underline"
            >
              Read full terms and conditions here
            </a>
          )}
          <Typography variant="small" className="mb-5">
            {quotePackage?.externalNote}
          </Typography>
        </div>
        <div className={`absolute bottom-2.5 left-2.5 z-10 w-[calc(100%-20px)] rounded-md bg-[#ECECEC] p-2.5`}>
          <div className={`flex items-center justify-between`}>
            <Button
              className="h-9 text-sm md:h-10 md:text-base"
              variant="filled"
              disabled={quoteVariant?.status !== QuoteStatus.Draft || isQuoteExpired}
              color="error"
              data-autoid={`btnReject-${quoteVariant.quoteID}`}
              onClick={() => setShowQuoteRejectModal(true)}
            >
              Reject
            </Button>
            <div className="flex flex-row items-center justify-center gap-2">
              {quoteVariant?.attachments && quoteVariant?.attachments?.length > 0 && (
                <Button
                  variant="outlined"
                  className="h-9 bg-white px-2 text-sm sm:px-5 md:h-10 md:text-base"
                  data-autoid={`btnAttachments-${quoteVariant.quoteID}`}
                  onClick={async () => {
                    const quoteDetails: ICustomerQuoteDetails = await getQuoteDetailByInviteCode(inviteCode);
                    setAttachments(
                      quoteDetails?.quotePackage?.quotes?.find(quote => quote.quoteID === quoteVariant.quoteID)
                        ?.attachments || [],
                    );
                    setShowAttachmentDialog(true);
                  }}
                >
                  View Attachments
                </Button>
              )}
              <Button
                className="h-9 text-sm md:h-10 md:text-base"
                variant="filled"
                disabled={quoteVariant?.status !== QuoteStatus.Draft || isQuoteExpired}
                color="primary"
                data-autoid={`btnAccept-${quoteVariant.quoteID}`}
                onClick={() => {
                  setShowQuoteAcceptModal(true);
                }}
              >
                Accept
              </Button>
              {globalLimepay &&
                quoteVariant?.status === QuoteStatus.Approved &&
                (quoteVariant.requiredDepositAmount || 0) > 0 &&
                (quoteVariant?.totalInc || 0) - (quoteVariant?.balance || 0) === 0 && (
                  <Button
                    className="h-9 text-sm md:h-10 md:text-base"
                    variant="filled"
                    color="primary"
                    data-autoid={`btnAccept-${quoteVariant.quoteID}`}
                    onClick={() => {
                      handleAcceptRejectClose();
                    }}
                  >
                    Pay
                  </Button>
                )}
            </div>
          </div>
        </div>
      </header>
      {showQuoteAcceptModal && (
        <QuoteAcceptDialog
          handleCancel={() => setShowQuoteAcceptModal(false)}
          handleAccept={() => {
            // check if is Authorised and authentication is still valid
            let isAuthenticationValid = false;
            if (
              activeSession?.expiryDateTime_utc &&
              moment.utc(activeSession?.expiryDateTime_utc).local().isAfter(new Date())
            ) {
              isAuthenticationValid = true;
            }
            if (isAuthenticationValid) {
              setIsAccepting(true);
              handleAcceptQuote(activeSession?.approvalSessionGUID!);
            } else {
              setShowQuoteAcceptModal(false);
              if (!activeSession) {
                // show Authorise screen
                handleRequestOTP(true, false);
              } else if (activeSession && !isAuthenticationValid) {
                // show session timeout dialog
                handleRequestOTP(false, true);
              }
            }
          }}
          quoteVariantsCount={quoteVariantsCount}
          isLoading={isAccepting}
        />
      )}
      {showQuoteRejectModal && (
        <QuoteRejectDialog
          handleCancel={() => setShowQuoteRejectModal(false)}
          handleRejectQuote={handleRejectQuote}
          quoteName={quoteVariant?.name}
          isLoading={isRejecting}
        />
      )}
      {showAttachmentDialog && quoteVariant?.attachments && quoteVariant?.attachments?.length > 0 && (
        <QuoteAttachmentDialog
          open={showAttachmentDialog}
          handleOpenAndClose={setShowAttachmentDialog}
          customerView
          uploadedAttachments={attachments}
        />
      )}
      {showQuoteAcceptRejectDialog && (
        <QuoteAcceptedRejectedDialog
          open={showQuoteAcceptRejectDialog}
          isAccepted={quoteVariant.status === QuoteStatus.Approved}
          handleClose={handleClose}
          handleOpen={handleOpenAmountPayDialog}
          quoteName={quotePackage?.quotes?.find(quote => quote.status === QuoteStatus.Approved)?.name}
        />
      )}

      {showSessionTimeoutDialog && (
        <QuoteSessionTimeoutDialog
          inviteCode={inviteCode}
          handleClose={setShowSessionTimeoutDialog}
          handleAcceptQuote={handleAcceptQuote}
          handleResendCode={handleRequestOTP}
          setActiveSession={setActiveSession}
          inviteSource={quotePackage?.invite?.toPhone || quotePackage?.invite?.toEmailAddress || ''}
        />
      )}

      {previewImage.show && (
        <PreviewAttachment
          type={previewImage.type}
          imageUrl={previewImage.uri}
          setShow={value =>
            setPreviewImage({
              uri: '',
              show: value,
              type: '',
            })
          }
        />
      )}
    </div>
  );
};
