import React from 'react';
import {FormikProps, getIn} from 'formik';
import {Grid, Divider, makeStyles} from '@material-ui/core';
import {ICustomer} from '../../model/customer/Customer';
import {ILocation} from '../../model/address/Location';
import {IconBin} from '../../assets/svg/IconBin';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import {clone} from 'lodash';
import {Input, IconButton, Select, Option} from 'spenda-ui-react';
import {ICreateCustomerClassValues} from '../../model/customer/CustomerClasses';

const useContactDetailsFormStyle = makeStyles(() => ({
  heading: {
    fontWeight: 500,
    fontSize: '1em',
    color: '#333',
    fontFamily: 'poppins',
  },
  collapseTitle: {
    fontSize: '1.25em',
    color: '#1c78ad',
    fontWeight: 500,
  },
  buttonContainer: {
    padding: '20px 10px 10px 0px',
  },
  subTitle: {
    fontWeight: 400,
    fontSize: '1.25em',
    marginTop: '20px',
  },
  imageContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(2, 1fr)',
    gridGap: '15px',
    padding: '5px',
    backgroundColor: '#FAFAFA50',
    borderRadius: '5px',
    border: '1px solid #EAEAEA',
  },
  addImage: {
    color: '#1C78AD',
    padding: '20px 15px',
    borderRadius: '5px',
    backgroundColor: '#EAEFF2',
    cursor: 'pointer',
  },
  image: {
    borderRadius: '5px',
  },
  image1: {
    borderRadius: '5px',
  },
  addIcon: {
    border: ' 1px solid hsl(var(--primary))',
    width: '40px',
    height: ' 40px',
    borderRadius: '6px',
    fill: 'hsl(var(--primary))',
    marginLeft: '20px',
    cursor: 'pointer',
  },
}));

const tempLocationObj: ILocation = {
  IsPrimaryContact: false,
  IsActive: true,
  FirstName: '',
  LastName: '',
  EmailAddress: '',
  Phone1: '',
};

export const ContactDetailsForm = (
  props: FormikProps<ICustomer> & {
    customerToEdit?: ICustomer;
    isQuoteCustomer?: boolean;
    customerClasses?: ICreateCustomerClassValues[];
    hasPreSelectedClass?: number;
  },
) => {
  const classes = useContactDetailsFormStyle();
  const {
    values,
    touched,
    errors,
    setValues,
    handleChange,
    setFieldValue,
    handleBlur,
    isQuoteCustomer,
    customerClasses,
    hasPreSelectedClass,
  } = props;
  const addContactFunc = () => {
    const newContacts = clone(values.Contacts) || [];
    newContacts.push(tempLocationObj);
    setValues({
      ...values,
      Contacts: newContacts,
    });
  };

  const removeContact = (index: number) => {
    const newContacts = clone(values.Contacts) || [];
    if (newContacts?.[index]?.BusinessContactID) {
      newContacts[index] = {...newContacts[index], IsActive: false};
    } else {
      newContacts.splice(index, 1);
    }
    setValues({
      ...values,
      Contacts: newContacts,
    });
  };

  return (
    <>
      <Divider
        className="w-full"
        style={{
          height: '2px !important',
          margin: values.IsIndividual === 'true' ? '15px 0px' : '',
        }}
      />
      {values.IsIndividual === 'false' && (
        <p className={`text-2xl font-semibold text-[#4D4D4D] ${values.IsIndividual === 'false' && classes.heading}`}>
          Primary Contact{' '}
        </p>
      )}
      {values.IsIndividual === 'false' && (
        <Divider className="w-full" style={{height: '2px !important', margin: '11px 0px'}} />
      )}
      <Grid item container className={`flex gap-y-2.5`} sm={12}>
        <Grid container className="flex" sm={6} spacing={0} item={true}>
          <Grid item sm={6} style={{padding: '0px 12px 0px 0px'}}>
            <Input
              id={`Contacts.[${0}].FirstName`}
              label={'First Name'}
              name={`Contacts.[${0}].FirstName`}
              value={values?.Contacts?.[0]?.FirstName}
              onBlur={handleBlur}
              onChange={e => {
                handleChange(e);
                setFieldValue(`Contacts.[${0}].IsPrimaryContact`, true);
              }}
              helperText={getIn(touched, `Contacts.[${0}].FirstName`) && getIn(errors, `Contacts.[${0}].FirstName`)}
              error={getIn(touched, `Contacts.[${0}].FirstName`) && Boolean(getIn(errors, `Contacts.[${0}].FirstName`))}
              variant={'outlined'}
            />
          </Grid>
          <Grid item sm={6} style={{padding: '0px 12px 0px 0px'}}>
            <Input
              id={`Contacts.[${0}].LastName`}
              label={'Last Name'}
              name={`Contacts.[${0}].LastName`}
              value={values?.Contacts?.[0]?.LastName}
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={getIn(touched, `Contacts.[${0}].LastName`) && getIn(errors, `Contacts.[${0}].LastName`)}
              error={getIn(touched, `Contacts.[${0}].LastName`) && Boolean(getIn(errors, `Contacts.[${0}].LastName`))}
              variant={'outlined'}
            />
          </Grid>
        </Grid>
        <Grid container className="flex" sm={6} spacing={0} item={true}>
          <Grid item sm={7} style={{padding: '0px 12px 0px 0px'}}>
            <Input
              id={`Contacts.[${0}].EmailAddress`}
              label={'Email Address'}
              name={`Contacts.[${0}].EmailAddress`}
              value={values?.Contacts?.[0]?.EmailAddress || ''}
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={
                getIn(touched, `Contacts.[${0}].EmailAddress`) && getIn(errors, `Contacts.[${0}].EmailAddress`)
              }
              error={
                getIn(touched, `Contacts.[${0}].EmailAddress`) && Boolean(getIn(errors, `Contacts.[${0}].EmailAddress`))
              }
              variant={'outlined'}
            />
          </Grid>
          <Grid item sm={5} style={{padding: '0px 12px 0px 0px'}}>
            <Input
              id={`Contacts.[${0}].Phone1`}
              label={'Phone'}
              name={`Contacts.[${0}].Phone1`}
              value={values?.Contacts?.[0]?.Phone1}
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={getIn(touched, `Contacts.[${0}].Phone1`) && getIn(errors, `Contacts.[${0}].Phone1`)}
              error={getIn(touched, `Contacts.[${0}].Phone1`) && Boolean(getIn(errors, `Contacts.[${0}].Phone1`))}
              variant={'outlined'}
            />
          </Grid>
        </Grid>

        <Grid item sm={3} style={{padding: '0px 12px 0px 0px'}}>
          <Input
            id={`Contacts.[${0}].PhoneMobile`}
            label={'Mobile'}
            name={`Contacts.[${0}].PhoneMobile`}
            value={values?.Contacts?.[0]?.PhoneMobile}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={getIn(touched, `Contacts.[${0}].PhoneMobile`) && getIn(errors, `Contacts.[${0}].PhoneMobile`)}
            error={
              getIn(touched, `Contacts.[${0}].PhoneMobile`) && Boolean(getIn(errors, `Contacts.[${0}].PhoneMobile`))
            }
            variant={'outlined'}
          />
        </Grid>
      </Grid>
      {values.IsIndividual === 'true' && isQuoteCustomer && customerClasses?.length && (
        <Grid container item className={`!mt-8 flex`} sm={12}>
          <Select
            name="customerClass"
            size="lg"
            variant="outlined"
            error={touched?.ClassID && Boolean(errors.ClassID)}
            label={
              touched?.ClassID && Boolean(errors.ClassID)
                ? 'Please select a customer class (required)'
                : 'Please select a customer class'
            }
            value={hasPreSelectedClass ? hasPreSelectedClass.toString() : values?.ClassID?.toString()}
            disabled={hasPreSelectedClass ? true : false}
            onChange={(value: string) => {
              setFieldValue('ClassID', value);
            }}
            arrow={hasPreSelectedClass ? false : undefined}
          >
            {customerClasses?.map((options, index) => {
              return (
                <Option value={options.ID?.toString()} key={index}>
                  {options.Name}
                </Option>
              );
            })}
          </Select>
        </Grid>
      )}
      <Divider
        className="w-full"
        style={{
          height: '2px !important',
          backgroundColor: '#F1F1F1',
          margin: '26px 0px',
        }}
      />
      <p className={`text-2xl font-semibold text-[#4D4D4D]  ${classes.heading}`}>
        Contact Details{' '}
        {
          <AddRoundedIcon
            className={classes.addIcon}
            onClick={() => {
              addContactFunc();
            }}
            data-autoid="imgContactDetails"
          />
        }
      </p>
      <Divider className="w-full" style={{height: '2px !important', margin: '0px 0'}} />

      <div>
        {values?.Contacts?.map(
          (data: ILocation, index: number) =>
            index > 0 &&
            data.IsActive && (
              <div key={data.ID} className={`mt-5 flex flex-row`}>
                <Grid container className="gap-y-2.5" sm={12} item={true}>
                  <Grid container sm={6} spacing={0} item={true}>
                    <Grid item sm={6} style={{padding: '0px 12px 0px 0px'}}>
                      <Input
                        id={`Contacts.${index}.FirstName`}
                        label="First Name"
                        name={`Contacts.${index}.FirstName`}
                        value={data?.FirstName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={
                          getIn(touched, `Contacts.[${index}].FirstName`) &&
                          getIn(errors, `Contacts.[${index}].FirstName`)
                        }
                        error={
                          getIn(touched, `Contacts.[${index}].FirstName`) &&
                          Boolean(getIn(errors, `Contacts.[${index}].FirstName`))
                        }
                        variant={'outlined'}
                      />
                    </Grid>
                    <Grid item sm={6} style={{padding: '0px 12px 0px 0px'}}>
                      <Input
                        id={`Contacts.${index}.LastName`}
                        label="Last Name"
                        name={`Contacts.${index}.LastName`}
                        value={data?.LastName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={
                          getIn(touched, `Contacts.[${index}].LastName`) &&
                          getIn(errors, `Contacts.[${index}].LastName`)
                        }
                        error={
                          getIn(touched, `Contacts.[${index}].LastName`) &&
                          Boolean(getIn(errors, `Contacts.[${index}].LastName`))
                        }
                        variant={'outlined'}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item={true} sm={6} spacing={0}>
                    <Grid item sm={6} style={{padding: '0px 12px 0px 0px'}}>
                      <Input
                        id={`Contacts.${index}.EmailAddress`}
                        label="Email Address"
                        name={`Contacts.${index}.EmailAddress`}
                        value={data?.EmailAddress || ''}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={
                          getIn(touched, `Contacts.[${index}].EmailAddress`) &&
                          getIn(errors, `Contacts.[${index}].EmailAddress`)
                        }
                        error={
                          getIn(touched, `Contacts.[${index}].EmailAddress`) &&
                          Boolean(getIn(errors, `Contacts.[${index}].EmailAddress`))
                        }
                        variant={'outlined'}
                      />
                    </Grid>
                    <Grid item sm={6} style={{padding: '0px 12px 0px 0px'}}>
                      <Input
                        id={`Contacts.${index}.Phone1`}
                        label="Phone"
                        name={`Contacts.${index}.Phone1`}
                        value={data?.Phone1}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={
                          getIn(touched, `Contacts.[${index}].Phone1`) && getIn(errors, `Contacts.[${index}].Phone1`)
                        }
                        error={
                          getIn(touched, `Contacts.[${index}].Phone1`) &&
                          Boolean(getIn(errors, `Contacts.[${index}].Phone1`))
                        }
                        variant={'outlined'}
                      />
                    </Grid>
                  </Grid>
                  <Grid item sm={3} style={{padding: '0px 12px 0px 0px'}}>
                    <Input
                      id={`Contacts.[${index}].PhoneMobile`}
                      label={'Mobile No'}
                      name={`Contacts.[${index}].PhoneMobile`}
                      value={values?.Contacts?.[index]?.PhoneMobile}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={
                        getIn(touched, `Contacts.[${index}].PhoneMobile`) &&
                        getIn(errors, `Contacts.[${index}].PhoneMobile`)
                      }
                      error={
                        getIn(touched, `Contacts.[${index}].PhoneMobile`) &&
                        Boolean(getIn(errors, `Contacts.[${index}].PhoneMobile`))
                      }
                      variant={'outlined'}
                    />
                  </Grid>
                </Grid>
                <Grid sm={1} container item={true}>
                  <IconButton
                    variant="text"
                    className="left-3 p-0 text-[#333333]"
                    onClick={() => removeContact(index)}
                    data-autoid={`btnRemoveContact`}
                  >
                    <IconBin height="24px" width="19.73px" />
                  </IconButton>
                </Grid>
              </div>
            ),
        )}
      </div>
    </>
  );
};
