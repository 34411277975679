import React, {PropsWithChildren, useState} from 'react';
import {ITxDeduction} from '../../model/payment/PaymentT';
import {PriceFormat} from '../../utils/formatter';
import {Box} from '@material-ui/core';
import Moment from 'react-moment';
import {css} from 'glamor';
import LoadingIndicator from '../ui/LoadingIndicator';
import {SPopover} from '../modals/modalSpendaMeterialUI';

interface IPaymentSummary {
  payments?: ITxDeduction[];
  isFailed?: boolean;
  onClickPayment?: (paymentOrCredit: ITxDeduction) => void;
}

export const PaymentSummary = (props: PropsWithChildren<IPaymentSummary>) => {
  const payments = props.payments || [];

  const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLSpanElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const paymentLine = css({
    color: props.isFailed ? '#D2A656' : '#4ea7c6',
    textDecoration: 'underline',
    cursor: 'pointer',
    textAlign: 'right',
  });

  const reducer = (prev: number, curr: ITxDeduction) => {
    let sum: number;
    sum = prev + curr.Amount!;
    return sum;
  };

  return (
    <>
      {payments.length > 0 ? (
        <div className="flex" onClick={handleClick}>
          <div className={`flex-1 ${paymentLine}`}>
            <span>{`${payments.length} ${payments.length > 1 ? 'Payments' : 'Payment'} ${
              props.isFailed ? 'Failed' : 'Applied'
            }`}</span>
          </div>
          <div className={`${paymentLine}`} style={{flex: '0 1 25%'}}>
            <span>{props.isFailed ? '' : `(${payments.reduce(reducer, 0).toFixed(2)})`}</span>
          </div>
        </div>
      ) : (
        ''
      )}

      <SPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <PaymentListItems isFailed={props.isFailed} payments={payments} onClickItem={props.onClickPayment} />
      </SPopover>
    </>
  );
};

const PaymentListItems = (props: {
  payments: ITxDeduction[];
  isFailed?: boolean;
  onClickItem?: (paymentOrCredit: ITxDeduction) => void;
}) => {
  const paymentLine = css({
    color: props.isFailed ? '#D2A656' : '#4ea7c6',
    cursor: props.isFailed ? '' : 'pointer',
    textDecoration: props.isFailed ? '' : 'underline',
    '&:hover': {
      color: props.isFailed ? '' : '#66bbd8',
    },
  });
  const width = css({
    minWidth: '30rem',
    pointerEvents: props.isFailed ? 'none' : 'auto',
  });

  const [paymentIdClicked, setPaymentIdClicked] = useState<number | undefined>();

  const onClick = (payment: ITxDeduction) => {
    setPaymentIdClicked(payment.ID);
    if (props.onClickItem) {
      props.onClickItem(payment);
      setTimeout(() => {
        setPaymentIdClicked(undefined);
      }, 2800);
    }
  };

  return (
    <>
      <hr />
      <div className="p-2">
        {props.payments.map((payment: ITxDeduction, id: number) => (
          <div key={id} className={`mt-1 flex ${width}`} onClick={() => onClick(payment)}>
            <div className={`w-10/12 ${paymentLine}`}>
              <p className="mb-1 pl-4 lg:text-xs xl:text-base">
                {payment.DatType} <span>{payment.RefNumber}</span> - {payment.Status}{' '}
                {<Moment date={payment.AppliedDate} format="Do MMM YYYY" local></Moment>}
              </p>
            </div>
            <div className="w-2/12">
              <Box className={`${paymentLine} text-right lg:text-xs xl:text-base`}>
                <Box className="mb-1">
                  <Box>{PriceFormat(payment.Amount || 0)}</Box>
                </Box>
              </Box>
            </div>
            <div className="px-1 pt-1">
              <LoadingIndicator
                key={id}
                size="sm"
                position={{right: '3px'}}
                color="hsl(var(--primary))"
                isLoading={paymentIdClicked === payment.ID}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
