import qs from 'query-string';
import {useContext, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {ITxData} from '../components/AccountsReceivable/TransactionView';
import AppContext from '../context/app/appContext';
import ARContext from '../context/ar-context/ARContext';
import {ISelectedTransactionsList} from '../model/accounts-receivable/AccountsReceivable';
import {IARBatchResponse, IBatchOperation, ISetPaymentAccountGUIDReq} from '../model/accounts-receivable/batch';
import {IClaimsReasonResponse} from '../model/claims/ClaimsAndReturns';
import {
  ARCustomerStatementResponseStatus,
  ARModals,
  ClaimsAndReturnsStatus,
  DatTypes,
  PSBLBatchAction,
} from '../model/constants/Constants';
import {IInvoice} from '../model/invoice/Invoice';
import {ISupplierWhoRaisedInvoice} from '../model/supplier/SupplierWhoRaisedInvoice';
import {claimStatusFilter} from '../screens/AccountsReceivable/claims-and-returns/ClaimsHighLevel';
import {useAccountReceivableBatchAPI} from '../services/useAccountReceivableBatchAPI';
import useAlertAPI from '../services/useAlertAPI';
import useClaimsAndReturnsAPI from '../services/useClaimsAndReturnsAPI';
import {ICreateStatementPayload, useCustomerStatementsAPI} from '../services/useCustomerStatementsAPI';
import {Toast} from '../utils/Toast';
import {netPayableAmount} from '../utils/formatter';
import {useFeatureFlags} from './useFeatureFlags';
import {usePaymentUtilities} from './usePaymentUtilities';
import {useTenantInfo} from './useTenantInfo';
import {defaultMarketPlacePath} from '../config';

interface IGetAvailableQtyProps {
  invoiceLineId: number | null;
  status: ClaimsAndReturnsStatus;
  qty: number;
  invoiceDetails: IInvoice | undefined;
}

export const usePsblStatementHook = () => {
  const {setPsblStatement, setArModalToShow, psblStatement} = useContext(ARContext);
  const {createStatement} = useCustomerStatementsAPI();

  const updateStatement = async (payload: ICreateStatementPayload) => {
    try {
      if (psblStatement?.otherUserEditingStatementError) {
        setArModalToShow({modal: ARModals.IsOtherUserEditingStatement});
        return;
      }
      const createStatementResponse = await createStatement(payload);
      const {errorMessage, statementID, transactions, accountCustomerID} = createStatementResponse;
      if (!createStatementResponse || errorMessage) {
        Toast.error(errorMessage || 'Error');
        return;
      }
      const selectedTransList: ISelectedTransactionsList[] = transactions?.map(t => {
        return {
          id: t.id,
          refNumber: t.refNumber,
          transactionType: t.datTypeName,
          dueDate: t.dueDate,
          transDate: t.transactionDate,
          balance: t.balance,
          totalInc: t.totalInc,
          accountCustomerName: t.accountCustomerName,
          transactionTypeID: t.datTypeInteger,
        };
      });
      setPsblStatement({
        otherUserEditingStatementError: createStatementResponse?.errorMessage,
        accountCustomerId: accountCustomerID,
        selectedTxList:
          createStatementResponse?.status === ARCustomerStatementResponseStatus.CANCEL ? [] : selectedTransList,
        statementId: statementID,
      });
    } catch (err) {
      const {data} = err as any;
      const isOtherUserEditing = data?.Errors?.find((e: any) => e?.Code === 'SMT005');
      if (isOtherUserEditing?.Code) setArModalToShow({modal: ARModals.IsOtherUserEditingStatement});
    }
  };

  return {
    updateStatement,
  };
};

export const usePsblBatchHook = (supplierId?: number) => {
  const {setPsblBatch, psblBatch} = useContext(ARContext);

  const {saveBatchAPI, setPaymentAccountGUIDAPI, getBatchDetailAPI, cancelBatchAPI, retryBatchPaymentAPI} =
    useAccountReceivableBatchAPI({linkedSupplierID: supplierId || 0});
  const _pu = usePaymentUtilities();

  const updatePSBLBatch = (saveBatchResponse: IARBatchResponse) => {
    const {
      claims,
      creditNotes,
      invoices,
      payments,
      merchantSurcharge,
      invoicePaymentAllocationID,
      versionDateTimeUtc,
      paymentAccountGUID,
      invoicePaymentAllocationRefNumber,
    } = saveBatchResponse;

    const isBatchCpp = _pu.arBatchPartialPaymentInProgress(psblBatch?.selectedTxList ?? []);

    if (isBatchCpp && invoices?.length === 1 && ![...claims, ...creditNotes, ...payments].length) {
      invoices[0].amountToPay = isBatchCpp.amountToPay;
    }

    const totalInvoiceAmount = invoices.reduce((sum, i) => sum + i.balance, 0);
    const totalCreditClaimsPrepaymentsSelectedAmount = [...creditNotes, ...claims, ...payments].reduce(
      (sum, i) => sum + i.balance,
      0,
    );

    const selectedTxList = [...claims, ...creditNotes, ...invoices, ...payments]?.map(t => {
      return {
        id: t.id,
        refNumber: t.refNumber,
        transactionType: t.datTypeName,
        dueDate: t.dueDate,
        transDate: t.transactionDate,
        balance: t.balance,
        totalInc: t.totalInc,
        accountCustomerName: t.accountCustomerName,
        transactionTypeID: t.datTypeInteger,
        modifiedByUserID: t.modifiedByUserID,
        modifiedDateTimeUtc: t.modifiedDateTimeUtc,
        datTypeID: t.datTypeID,
        dueDate_String: t.dueDate_String,
        transactionDate_String: t.transactionDate_String,
        amountToPay: t.amountToPay,
      };
    });

    selectedTxList.sort((a, b) => {
      return (
        psblBatch?.selectedTxList?.findIndex(l => l?.id === a?.id) -
        psblBatch?.selectedTxList?.findIndex(l => l?.id === b?.id)
      );
    });

    const transactionsObj = {
      selectedTxList,
      merchantSurcharge,
      invoicePaymentAllocationID,
      invoicePaymentAllocationRefNumber,
      totalInvoiceAmount,
      totalCreditClaimsPrepaymentsSelectedAmount,
      versionDateTimeUtc,
      paymentAccountGUID,
      psblBatchAPIResponse: saveBatchResponse,
      loading: false,
    };

    setPsblBatch(transactionsObj);
    return transactionsObj;
  };

  const getBatchDetail = async (paymentStatus?: string) => {
    const result = await getBatchDetailAPI();
    const isBatchCpp = _pu.arBatchPartialPaymentInProgress(psblBatch?.selectedTxList ?? []);
    if (paymentStatus === 'SUCCESS' && isBatchCpp) {
      const payloadBatch = [
        {
          datTypeID: DatTypes.Invoice,
          id: result?.invoices?.[0].id,
          action: PSBLBatchAction.REMOVE,
        },
      ];
      updateBatchTransactions(payloadBatch);
    } else {
      updatePSBLBatch(result);
    }
  };

  const updateBatchTransactions = async (payload: IBatchOperation[]) => {
    try {
      const saveBatchResponse = await saveBatchAPI(payload);
      updatePSBLBatch(saveBatchResponse);
    } catch {
      console.warn('Error updating batch transactions');
    }
  };

  const setBatchPaymentGuid = async (payload: ISetPaymentAccountGUIDReq, isUpdateResponse = true) => {
    const res = await setPaymentAccountGUIDAPI(payload);
    isUpdateResponse && updatePSBLBatch(res);
    return res;
  };

  const getSelectedInvoiceTotal = (selectedTransactionsList: ISelectedTransactionsList[]) => {
    const totals = selectedTransactionsList?.reduce(
      (acc, st) => {
        if (st?.transactionTypeID === DatTypes.Invoice) {
          acc.totalInvoiceAmount = acc.totalInvoiceAmount + st?.balance;
        } else if ([DatTypes.CreditNote, DatTypes.Claim, DatTypes.Payment].includes(st?.transactionTypeID)) {
          acc.totalCreditAndClaimsSelected = acc.totalCreditAndClaimsSelected + st?.balance;
        }
        return acc;
      },
      {totalInvoiceAmount: 0, totalCreditAndClaimsSelected: 0},
    );
    const previewTotal = netPayableAmount(totals.totalInvoiceAmount, totals.totalCreditAndClaimsSelected);
    return previewTotal?.toFixed(2);
  };

  const cancelPSBLBatch = async (invoicePaymentAllocationID: number) => {
    await cancelBatchAPI(invoicePaymentAllocationID);
    const result = await getBatchDetailAPI();
    updatePSBLBatch(result);
  };

  const retryPSBLBatch = async (invoicePaymentAllocationID: number) => {
    await retryBatchPaymentAPI(invoicePaymentAllocationID);
    const result = await getBatchDetailAPI();
    updatePSBLBatch(result);
  };

  return {
    getSelectedInvoiceTotal,
    updateBatchTransactions,
    updatePSBLBatch,
    setBatchPaymentGuid,
    cancelPSBLBatch,
    getBatchDetail,
    retryPSBLBatch,
  };
};

export const useTransactionViewHook = () => {
  const {search, pathname, state} = useLocation<{isGoBack: boolean}>();

  // Hooks
  const {isInSupplierContext, isInBuyerContext} = useTenantInfo();
  const history = useHistory();
  const {tenant, marketplaceSupplier} = useContext(AppContext);

  const query = new URLSearchParams(search);
  const txGuid = query.get('txGuid');
  const txId = parseInt(query.get('txId') || '');
  const txDatType = parseInt(query.get('txDatType') || '');
  const merchantCustomerID = parseInt(query.get('mcId') || '');

  const {viewingTx, setViewingTx} = useContext(ARContext);

  useEffect(() => {
    if (txDatType) {
      setViewingTx({txGuid: txGuid!, txId, txDatType, mcId: merchantCustomerID});
    } else {
      setViewingTx(undefined);
    }
  }, [search, txDatType, txGuid, txId, merchantCustomerID]);

  const handleViewTx = (txData: ITxData, isGoBack?: boolean) => {
    const parsed = qs.parse(search);

    parsed.txDatType = txData.txDatType.toString();

    if (txData.txGuid) {
      parsed.txGuid = txData.txGuid;
    } else {
      delete parsed.txGuid;
    }

    if (txData.txId) {
      parsed.txId = txData.txId?.toString();
    } else {
      delete parsed.txId;
    }

    history.push({state: {isGoBack}, pathname: pathname, search: qs.stringify(parsed)});
  };

  const handleCloseTx = (goBack: boolean = false) => {
    if (state?.isGoBack || goBack) {
      history.goBack();
      return;
    }
    const parsed = qs.parse(search);

    delete parsed.txId;
    delete parsed.txDatType;
    delete parsed.txGuid;
    delete parsed.mcId;

    history.push({pathname: pathname, search: qs.stringify(parsed)});
  };

  const getSupplierWhoRaisedInvoice = () => {
    let supplierTenant: ISupplierWhoRaisedInvoice | undefined = undefined;

    if (isInSupplierContext) {
      const address = tenant?.Locations?.find(l => l.IsActive)?.Addresses?.find(a => a.IsActive && a.IsDefaultBilling);
      supplierTenant = {
        ID: 0,
        Name: tenant?.Name || '',
        ABN: tenant?.ABN,
        PrimaryContactEmail: tenant?.Users?.find((u, index) => u.IsPrimaryContact || index === 0)?.EmailAddress,
        ARContactEmailAddress: tenant?.ARContactEmailAddress || '',
        Website: tenant?.WebSiteURL,
        BusinessPhone: tenant?.Phone,
        Logos: tenant?.Logos || [],
        Address: {
          City: address?.City,
          Country: address?.Country,
          FullAddress: address?.FullAddress,
          PostCode: address?.PostCode,
          State: address?.State,
          StreetAddress: address?.StreetAddress,
        },
      };
    } else if (isInBuyerContext) {
      supplierTenant = {
        ID: marketplaceSupplier?.SupplierID || 0,
        Name: marketplaceSupplier?.TenantName || '',
        ABN: marketplaceSupplier?.ABN,
        PrimaryContactEmail: marketplaceSupplier?.PrimaryContact?.EmailAddress,
        Website: marketplaceSupplier?.SupplierWebsite,
        BusinessPhone: marketplaceSupplier?.PhoneNumber,
        Logos: marketplaceSupplier?.Logos || [],
        Address: marketplaceSupplier?.BillingAddress,
      };
    }

    return supplierTenant;
  };

  return {
    viewingTx,
    handleViewTx,
    handleCloseTx,
    getSupplierWhoRaisedInvoice,
  };
};

export const usePSBLHook = () => {
  // Feature Flags
  const {payStatementByLinkV289938} = useFeatureFlags().supplierDriven();

  const {suppliers, marketplaceSupplier} = useContext(AppContext);

  const getSelectedSupplier = (supplierId?: number) => suppliers?.find(ss => ss.SupplierID === supplierId);

  const getSupplierByMarketplacePath = (path?: string) => {
    if (!path) return;
    return suppliers?.find(ss => ss.MarketplacePath === path);
  };

  const getSupplierByDefaultMarketplacePath = () => {
    return getSupplierByMarketplacePath(defaultMarketPlacePath);
  };

  const {IsPaymentProvider, IsPaymentServicesCreditCardMerchant, IsPaymentServicesMerchant, IsLendingProvider} =
    marketplaceSupplier || {};
  const isPaymentAbility =
    IsPaymentProvider || IsPaymentServicesCreditCardMerchant || IsPaymentServicesMerchant || IsLendingProvider;
  const isPaymentOptionAvailable = payStatementByLinkV289938 ? isPaymentAbility : true;

  return {
    getSelectedSupplier,
    getSupplierByMarketplacePath,
    getSupplierByDefaultMarketplacePath,
    isPaymentOptionAvailable,
  };
};

export const useClaimsAndReturnHook = () => {
  const {downloadDocument, isLoading: isDownloadClaimLoading} = useAlertAPI();

  const getAvailableQty = ({invoiceLineId, status, qty, invoiceDetails}: IGetAvailableQtyProps) => {
    if (!invoiceLineId) return;
    const line = invoiceDetails?.Lines?.find(l => l.LineID === invoiceLineId);

    if (!line) return;

    const filteredLines = line?.ClaimRequestLines?.filter(l =>
      [ClaimsAndReturnsStatus.Sent, ClaimsAndReturnsStatus.Approved]?.includes(
        l?.ClaimRequestStatus as ClaimsAndReturnsStatus,
      ),
    );
    if (!filteredLines?.length) return line?.Quantity;

    const usedQty = filteredLines?.reduce(
      (a, c) => parseFloat(c?.Quantity?.toString()) + parseFloat(a?.toString()),
      0.0,
    );

    if (status === ClaimsAndReturnsStatus.Sent) return line?.Quantity - usedQty + qty;
    else return line?.Quantity - usedQty;
  };

  const downloadClaimDocument = async (params: {
    claimRequestId: number;
    accountCustomerId?: number;
    linkedSupplierId?: number;
  }) => {
    try {
      const {claimRequestId, accountCustomerId, linkedSupplierId} = params;

      if (!accountCustomerId && !linkedSupplierId) return;

      const payload = {
        docIDs: [claimRequestId],
        accountCustomerId: accountCustomerId,
        linkedSupplierId: linkedSupplierId,
        datTypeID: DatTypes.ClaimDocument,
        alertType: 'Claim Request Status Notification PDF',
        websiteID: 13,
      };
      const response = await downloadDocument(payload);
      if (response?.value?.length) {
        window.location.href = response?.value[0];
      }
    } catch {
      console.warn(`Error downloading claim request: ${params.claimRequestId}`);
    }
  };

  const getFilterNameByStatus = (claimStatus: string) => {
    const entries = Object.entries(claimStatusFilter);
    const finterObject = entries?.find(([_k, v]) => v?.includes(claimStatus));
    return finterObject?.[0];
  };

  return {getAvailableQty, isDownloadClaimLoading, downloadClaimDocument, getFilterNameByStatus};
};

export const useClaimsAndReturnInputHook = ({linkedSupplierID}: {linkedSupplierID?: number}) => {
  // APIs
  const {getClaimReasonBuyer, getClaimReasonSupplier} = useClaimsAndReturnsAPI();

  // Hook
  const {isInSupplierContext, isInBuyerContext} = useTenantInfo();

  // State
  const [reasonDropdownOptions, setReasonDropdownOptions] = useState<IClaimsReasonResponse[]>();
  const [isClaimReasonDropdowLoading, setIsClaimReasonDropdowLoading] = useState(false);

  // Feature Flags
  const {claimAndReturnsV272602: claimAndReturnsV2Supplier} = useFeatureFlags().tenantOwned();
  const {claimAndReturnsV272602: claimAndReturnsV2Buyer} = useFeatureFlags().supplierDriven();
  const claimAndReturnsV272602 = isInBuyerContext ? claimAndReturnsV2Buyer : claimAndReturnsV2Supplier;

  // useEffect
  useEffect(() => {
    claimAndReturnsV272602 && fetchClaimReasons();
  }, [linkedSupplierID]);

  const fetchClaimReasons = async () => {
    try {
      if (reasonDropdownOptions) return reasonDropdownOptions;
      setIsClaimReasonDropdowLoading(true);
      let claimReasonResponse: IClaimsReasonResponse[] | undefined = undefined;
      if (isInBuyerContext && linkedSupplierID) {
        claimReasonResponse = await getClaimReasonBuyer(linkedSupplierID);
        setReasonDropdownOptions(claimReasonResponse);
      } else if (isInSupplierContext) {
        claimReasonResponse = await getClaimReasonSupplier();
        setReasonDropdownOptions(claimReasonResponse);
      }
      setIsClaimReasonDropdowLoading(false);
      return claimReasonResponse;
    } catch {
      setIsClaimReasonDropdowLoading(false);
    }
  };

  const claimDefaultReason = reasonDropdownOptions?.[0]?.parentReason?.reason;

  const claimLineItemReason = reasonDropdownOptions?.[0]?.subReasons?.length
    ? reasonDropdownOptions?.[0]?.subReasons?.[0]?.reason
    : reasonDropdownOptions?.[0]?.parentReason?.reason;

  return {
    reasonDropdownOptions,
    fetchClaimReasons,
    claimDefaultReason,
    claimLineItemReason,
    isClaimReasonDropdowLoading,
  };
};
