import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {FormikProps} from 'formik';
import clsx from 'clsx';
import {debounce, isNumber} from 'lodash';

import {ICreateDeliveryReceiptValues} from './CreateDeliveryReceiptDialog';
import {Inventory} from '../../model/inventory/Inventory';
import {IDeliveryReceiptLine} from '../../model/delivery-receipt/DeliveryReceipt';
import useDeliveryReceiptAPI from '../../services/useDeliveryReceiptAPI';
import {InventoryLineAutocomplete} from '../inventory/InventoryAutocomplete';
import LoadingIndicator from '../ui/LoadingIndicator';
import PriceCalculator from '../../utils/priceCalculator';

import {
  ClickAwayListener,
  Grow,
  IconButton,
  InputBase,
  Popper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import IconSearch from '../../assets/svg/IconSearch';
import {usePopupState, bindToggle, bindPopper} from 'material-ui-popup-state/hooks';

export const UNASSIGNED_SUPPLIER = 'UNASSIGNED';
export const SUPPLIER_ID_ATTRIBUTE = 'data-supplier-id';

const useDeliveryReceiptLinesStyles = makeStyles(() => ({
  table: {
    fontFamily: 'Poppins, sans-serif',
    borderCollapse: 'separate',
    borderSpacing: 0,
    '& .MuiTableRow-root': {
      height: 40,
      '& .MuiTableCell-head': {
        fontFamily: 'Poppins, sans-serif',
        color: '#999999',
        fontWeight: '600',
        fontSize: '14px',
        padding: '0px 8px',
        backgroundColor: '#F1F1F1',
        borderBottom: 'none',
        '&:first-child': {
          borderTopLeftRadius: '2px',
        },
        '&:last-child': {
          borderTopRightRadius: '2px',
        },
      },
      '& .MuiTableCell-body': {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '12px',
        color: '#333333',
        padding: '0px 8px',
        borderBottom: '1px solid #EAEAEA50',
        border: '1px solid transparent',
        '&.active': {
          border: '1px solid #1C78AD',
        },
        '&:nth-of-type(even)': {
          backgroundColor: '#FAFAFA',
        },
        '&.cell-input': {
          cursor: 'text',
        },
        '& .input-bordered.Mui-focused': {
          border: '1px solid #1C78AD !important',
        },
      },
    },
  },
  iconButton: {
    color: '#1C78AD',
    border: '1px solid #1C78AD',
    borderRadius: '6px',
    padding: '8px',
    marginRight: '6px',
  },
  iconButtonActive: {
    borderRadius: '0 6px 6px 0',
    backgroundColor: '#1C78AD',
    '& path': {
      fill: '#FFFFFF',
    },
    '&:hover': {
      borderRadius: '0 6px 6px 0',
      backgroundColor: '#1C78AD',
    },
  },
}));

const StyledInputBase = withStyles({
  root: {
    fontFamily: 'Poppins, sans-serif',
    '& .MuiInputAdornment-root': {
      '& p': {
        color: '#333333',
        fontSize: '12px',
        fontFamily: 'Poppins, sans-serif',
      },
    },
    '& input': {
      fontSize: '12px',
      color: '#333333',
      textAlign: 'right',
      backgroundColor: 'transparent !important',
    },
  },
})(InputBase);

const StyledTextField = withStyles({
  root: {
    border: '1px solid #1C78AD',
    fontFamily: 'Poppins',
    fontWeight: 600,
    borderRadius: '6px 0 0 6px',
    '&:has([aria-expanded="true"])': {
      borderRadius: '6px 0 0 0',
    },
    '& .MuiInputBase-root': {
      paddingLeft: '6px',
      margin: 'auto',
      '&::before, &::after': {
        display: 'none',
      },
      '& input::placeholder': {
        color: '#BBBBBB',
      },
      '& input': {
        marginBottom: 0,
        color: '#333333',
      },
    },
  },
})(TextField);

export interface IDeliveryReceiptAddLine {
  InventoryList: {
    InventoryID: number;
    Quantity: string;
    LineID?: number;
    isActive?: boolean;
  }[];
}

interface IDeliveryReceiptLines extends FormikProps<ICreateDeliveryReceiptValues> {
  editMode: boolean;
  searchText: string;
  setSearchText: (x: string) => void;
}

export const DeliveryReceiptLines = (props: IDeliveryReceiptLines) => {
  const classes = useDeliveryReceiptLinesStyles();
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [selectedInventory, setSelectedInventory] = useState<IDeliveryReceiptLine | null>(null);
  const [inputValue, setInputValue] = useState<string>('');

  const {values, setFieldValue, editMode, searchText, setSearchText} = props;

  const {addInventoryDeliveryReceipt, isLoading} = useDeliveryReceiptAPI();

  const debounceSaveLines = useCallback(
    debounce((lines: IDeliveryReceiptAddLine) => {
      addInventoryDeliveryReceipt(values.ID, lines).then(requisition => {
        setFieldValue('Lines', requisition.Lines || []);
      });
    }, 500),
    [values.ID],
  );

  const handleGlobalClickAway = () => {
    setActiveIndex(-1);
  };

  const saveLineInventory = async (selected: Partial<Inventory> | null, _selection: any) => {
    if (selectedInventory) {
      return;
    }
    const lines: IDeliveryReceiptAddLine = {
      InventoryList: [
        {
          InventoryID: selected?.ID,
          Quantity: '1',
          isActive: selected?.IsActive,
        },
      ],
    };
    const deliveryReceipt = await addInventoryDeliveryReceipt(values.ID, lines);
    const _selectedInventory =
      deliveryReceipt.Lines.find(line => String(line.InventoryID) === String(selected?.ID)) || null;
    setSelectedInventory(_selectedInventory);
    setSearchText('');
    setFieldValue('Lines', deliveryReceipt.Lines);
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    inventory: IDeliveryReceiptLine,
  ) => {
    if (event.key === 'Enter') {
      handleQuantityChange(inventory);
      (event.target as HTMLInputElement).blur();
    }
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    inventory: IDeliveryReceiptLine,
  ) => {
    handleQuantityChange(inventory);
  };

  const handleQuantityChange = (inventory: IDeliveryReceiptLine) => {
    const lines: IDeliveryReceiptAddLine = {
      InventoryList: [
        {
          InventoryID: inventory.InventoryID,
          Quantity: inputValue,
          isActive: inventory.IsActive,
          LineID: inventory.ID,
        },
      ],
    };
    setSearchText('');
    setInputValue('');
    setActiveIndex(-1);
    setSelectedInventory(null);
    debounceSaveLines(lines);
  };

  const filteredLines = useMemo(
    () =>
      (values.Lines || []).filter(
        line =>
          !isNumber(line.QuantityReceived) &&
          (line?.InventoryCode?.toLocaleLowerCase()?.includes(searchText.toLocaleLowerCase()) ||
            line?.ShortDescription?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
            line?.Barcode?.toLocaleLowerCase()?.includes(searchText.toLocaleLowerCase())),
      ),
    [searchText, values.Lines],
  );

  useEffect(() => {
    if (filteredLines.length === 1) {
      setTimeout(() => {
        setSelectedInventory(filteredLines[0]);
      }, 500);
    } else {
      setSelectedInventory(null);
    }
  }, [filteredLines]);

  useEffect(() => {
    if (filteredLines.length !== 1 && selectedInventory) {
      setSearchText('');
      setSelectedInventory(null);
    }
  }, [selectedInventory, searchText, filteredLines, setSearchText]);

  return (
    <ClickAwayListener onClickAway={handleGlobalClickAway}>
      <div className="relative mb-14 flex flex-col">
        <LoadingIndicator isLoading={isLoading} size="md" color="hsl(var(--primary))" />
        {editMode ? (
          <div className="flex">
            <div
              className={clsx('w-[150px] border-collapse border border-solid border-gray-200 px-2 py-1', {
                '!border-spenda-sBlue': activeIndex === 0,
              })}
            >
              <InventoryLineAutocomplete
                placeholder="Enter code"
                searchCodeOnly={true}
                clearOnSelect={true}
                index={0}
                inputRefs={inputRefs}
                id="Inventory-Code"
                onFocus={() => setActiveIndex(0)}
                alreadyFilled={(values.Lines || []).map(a => a.InventoryID)}
                selected={selectedInventory}
                onSuggestionSelected={(e, selected) => saveLineInventory(selected.suggestion, selected)}
                handleSuggestionInputChange={x => props.setSearchText(x)}
                isLocalSearch={Boolean(filteredLines.length)}
              />
            </div>
            <div
              className={clsx('flex-1 border border-solid border-gray-200 px-2 py-1', {
                '!border-spenda-sBlue': activeIndex === 1,
              })}
            >
              <InventoryLineAutocomplete
                placeholder="Enter product"
                clearOnSelect={true}
                index={1}
                inputRefs={inputRefs}
                id="Inventory-ShortDescription"
                onFocus={() => setActiveIndex(1)}
                alreadyFilled={(values.Lines || []).map(a => a.InventoryID)}
                selected={selectedInventory}
                onSuggestionSelected={(e, selected) => saveLineInventory(selected.suggestion, selected)}
                handleSuggestionInputChange={x => props.setSearchText(x)}
                isLocalSearch={Boolean(filteredLines.length)}
              />
            </div>
            <div
              className={clsx('w-[150px] border-collapse border border-solid border-gray-200 px-2 py-1', {
                '!border-spenda-sBlue': activeIndex === 2,
              })}
            >
              <StyledInputBase
                fullWidth
                id="Quantity"
                name="Quantity"
                type="number"
                placeholder="Qty"
                inputRef={el => (inputRefs.current[2] = el)}
                onFocus={() => {
                  setInputValue(String(selectedInventory?.QuantityReceived || 0));
                  setActiveIndex(2);
                }}
                value={
                  activeIndex === 2
                    ? inputValue
                    : isNumber(selectedInventory?.QuantityReceived)
                      ? PriceCalculator.roundNumber(selectedInventory?.QuantityReceived || 0)
                      : ''
                }
                onBlur={e => selectedInventory && handleBlur(e, selectedInventory)}
                onKeyDown={e => selectedInventory && handleKeyDown(e, selectedInventory)}
                onChange={e => {
                  if (Number(e.target.value) >= 0) setInputValue(e.target.value);
                }}
                inputProps={{style: {textAlign: 'center'}, 'data-autoid': 'txtQuantity'}}
                disabled={!selectedInventory}
              />
            </div>
          </div>
        ) : null}
        <Table className={clsx(classes.table, 'mt-4')}>
          <TableHead>
            <TableRow>
              <TableCell width="150px">Code</TableCell>
              <TableCell width="auto">Products</TableCell>
              <TableCell width="150px" align="center">
                UoM
              </TableCell>
              <TableCell width="150px" align="center">
                Qty
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="relative">
            {filteredLines.map((inventory: IDeliveryReceiptLine, index) => (
              <TableRow key={inventory.ID}>
                <TableCell>{inventory.InventoryCode}</TableCell>
                <TableCell>{inventory.ShortDescription}</TableCell>
                <TableCell align="center">{inventory.UoM}</TableCell>
                <TableCell
                  key={`${inventory.ID}_${index + 3}`}
                  data-index={index + 3}
                  data-id={inventory.ID}
                  className={clsx({'!border-spenda-sBlue': activeIndex === index + 3})}
                  align="center"
                  style={{
                    minWidth: '100px',
                    color: !inventory.QuantityReceived && !editMode ? '#999999' : '#333333',
                  }}
                >
                  {!editMode ? inventory.QuantityReceived || '-' : null}
                  {editMode ? (
                    <StyledInputBase
                      fullWidth
                      id={`Quantity-${inventory.ID}`}
                      name="Quantity"
                      type="number"
                      placeholder="-"
                      inputRef={el => (inputRefs.current[index + 3] = el)}
                      onFocus={() => {
                        setInputValue('');
                        setActiveIndex(index + 3);
                      }}
                      value={index + 3 === activeIndex ? inputValue : ''}
                      onChange={e => {
                        if (Number(e.target.value) >= 0) setInputValue(e.target.value);
                      }}
                      onBlur={e => handleBlur(e, inventory)}
                      onKeyDown={e => handleKeyDown(e, inventory)}
                      inputProps={{
                        'data-autoid': `txtQuantity${inventory.ID}`,
                        'data-id': inventory.ID,
                        style: {textAlign: 'center'},
                      }}
                    />
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
            {!(values.Lines || []).length && editMode ? (
              <TableRow key="no-inventory">
                <TableCell colSpan={4} align="center">
                  There are no more products that require counting, if you would like to add to this order please enter
                  the code above.
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </div>
    </ClickAwayListener>
  );
};

export const DeliveryReceiptCounted = (props: IDeliveryReceiptLines) => {
  const classes = useDeliveryReceiptLinesStyles();

  const [searchInput, setSearchInput] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const {addInventoryDeliveryReceipt, isLoading} = useDeliveryReceiptAPI();

  const {values, setFieldValue, searchText, editMode} = props;

  const searchInputPopup = usePopupState({variant: 'popover', popupId: 'searchInputPopup'});

  const debounceSaveLines = useCallback(
    debounce((lines: IDeliveryReceiptAddLine) => {
      addInventoryDeliveryReceipt(values.ID, lines).then(requisition => {
        setFieldValue('Lines', requisition.Lines || []);
      });
    }, 500),
    [values.ID],
  );

  const handleClickAway = () => {
    searchInputPopup.close();
    setSearchInput('');
  };

  const handleGlobalClickAway = () => {
    setActiveIndex(-1);
  };

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    inventory: IDeliveryReceiptLine,
  ) => {
    if (event.key === 'Enter' && String(inventory.QuantityReceived) !== inputValue) {
      handleQuantityChange(inventory);
      (event.target as HTMLInputElement).blur();
    }
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    inventory: IDeliveryReceiptLine,
  ) => {
    if (String(inventory.QuantityReceived) !== inputValue) {
      handleQuantityChange(inventory);
    }
  };

  const handleQuantityChange = (inventory: IDeliveryReceiptLine) => {
    const lines: IDeliveryReceiptAddLine = {
      InventoryList: [
        {
          InventoryID: inventory.InventoryID,
          Quantity: inputValue,
          LineID: inventory.ID,
          isActive: inventory.IsActive,
        },
      ],
    };
    setInputValue('');
    setActiveIndex(-1);
    debounceSaveLines(lines);
  };

  const filteredLines = useMemo(
    () =>
      (values.Lines || []).filter(
        line =>
          isNumber(line.QuantityReceived) &&
          (line?.InventoryCode?.toLocaleLowerCase()?.includes(searchText.toLocaleLowerCase()) ||
            line?.ShortDescription?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
            line?.Barcode?.toLocaleLowerCase()?.includes(searchText.toLocaleLowerCase())) &&
          (line?.InventoryCode?.toLocaleLowerCase()?.includes(searchInput.toLocaleLowerCase()) ||
            line?.ShortDescription?.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase()) ||
            line?.Barcode?.toLocaleLowerCase()?.includes(searchInput.toLocaleLowerCase())),
      ),
    [searchText, searchInput, values.Lines],
  );

  return (
    <ClickAwayListener onClickAway={handleGlobalClickAway}>
      <div className="relative">
        <LoadingIndicator isLoading={isLoading} size="md" color="hsl(var(--primary))" />
        <div className="flex flex-row items-center justify-between border-b-[1px] border-solid border-[#D8D8D8] pb-2">
          <span className={clsx('text-xl font-light text-spenda-primarytext')}>Counted products</span>
          <div className="flex-1" />
          <ClickAwayListener onClickAway={handleClickAway}>
            <div>
              <IconButton
                disableRipple
                className={clsx(classes.iconButton, {[classes.iconButtonActive]: searchInputPopup.isOpen})}
                data-autoid="btnSearchSupplier"
                {...bindToggle(searchInputPopup)}
              >
                <IconSearch />
              </IconButton>
              <Popper {...bindPopper(searchInputPopup)} disablePortal={false} placement="left" transition>
                {({TransitionProps}) => (
                  <Grow {...TransitionProps} timeout={250}>
                    <div className="relative">
                      <StyledTextField
                        fullWidth
                        autoFocus
                        placeholder="Search products"
                        value={searchInput}
                        onChange={onChangeSearch}
                        inputProps={{
                          'data-autoid': 'txtSearchProducts',
                        }}
                      />
                    </div>
                  </Grow>
                )}
              </Popper>
            </div>
          </ClickAwayListener>
        </div>
        <Table className={clsx(classes.table, 'mt-4')}>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell width="auto">Products</TableCell>
              <TableCell align="center">Qty</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLines.map((inventory: IDeliveryReceiptLine, index: number) => (
              <TableRow key={inventory.ID}>
                <TableCell>{inventory.InventoryCode}</TableCell>
                <TableCell>{inventory.ShortDescription}</TableCell>
                <TableCell
                  align="center"
                  width="60px"
                  key={`${inventory.ID}_${index}`}
                  data-index={index}
                  data-id={inventory.ID}
                  className={clsx({'!border-spenda-sBlue': activeIndex === index})}
                  style={{
                    color: !inventory.QuantityReceived && !editMode ? '#999999' : '#333333',
                  }}
                >
                  {!editMode ? inventory.QuantityReceived || '-' : null}
                  {editMode ? (
                    <StyledInputBase
                      fullWidth
                      id={`Quantity-${inventory.ID}`}
                      name="Quantity"
                      type="number"
                      placeholder="-"
                      onFocus={() => {
                        setInputValue(String(inventory.QuantityReceived));
                        setActiveIndex(index);
                      }}
                      value={index === activeIndex ? inputValue : inventory.QuantityReceived}
                      onChange={e => {
                        if (Number(e.target.value) >= 0) setInputValue(e.target.value);
                      }}
                      onBlur={e => handleBlur(e, inventory)}
                      onKeyDown={e => handleKeyDown(e, inventory)}
                      inputProps={{
                        'data-autoid': `txtQuantity${inventory.ID}`,
                        'data-id': inventory.ID,
                        style: {textAlign: 'center'},
                      }}
                    />
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </ClickAwayListener>
  );
};
