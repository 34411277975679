import React, {useContext, useEffect, useState} from 'react';
import {RouteChildrenProps, useHistory} from 'react-router-dom';
import {RedirectLoginOptions, useAuth0} from '@auth0/auth0-react';
import {Box} from '@material-ui/core';
import PublicShell from './PublicShell';
import useMarketPlaceAPI from '../../services/useMarketPlaceAPI';
import AppContext from '../../context/app/appContext';
import {useAuth0Callback} from '../../hooks/useAuth0Callback';
import {Toast} from '../../utils/Toast';
import {useParams} from 'react-router-dom';
import {AUTH_REFERRAL_INVITE} from '../../routes/AuthenticatedRoutes';
import {useQuery} from '../pay-by-link/useQuery';
import {Button} from 'spenda-ui-react';
import LoadingIndicator from '../../components/ui/LoadingIndicator';

export type Auth0LoginProps = {
  marketPlacePath?: string;
};

export interface IAuth0LoginMatchParams {
  username?: string;
  statementInvitationId?: string;
  displayExpiredInviteDialog?: boolean;
  supplierName?: string;
}

export const Auth0Login = (props: RouteChildrenProps<any> & Auth0LoginProps) => {
  const {loginWithAuth0Redirect} = useAuth0Callback();
  const {user, isAuthenticated} = useAuth0();
  const [isAccessDenied, setIsAccesseDenied] = useState<boolean>();

  const {setMarketplaceSupplier, setStatementInvitation} = useContext(AppContext);
  const {validatePath, isRouteWhiteListed} = useMarketPlaceAPI();
  const history = useHistory();

  const statementInvitationId = (props.location.state as IAuth0LoginMatchParams)?.statementInvitationId;
  const {marketPlacePath} = useParams<{marketPlacePath: string}>() || '';

  const query = useQuery();
  const overwriteSsoConnection = query.get('ssoConnection') || '';
  const errorDescription = query.get('error_description') || '';
  const appContext = useContext(AppContext);

  const logOut = async () => {
    await appContext.logout();
  };

  useEffect(() => {
    if (errorDescription) {
      // Auth0 error

      if (errorDescription == 'user_type_not_allowed') {
        setIsAccesseDenied(true);
        return;
      }

      Toast.info(errorDescription as string);
    }

    if (!isAuthenticated && !user) {
      const redirectUri = isRouteWhiteListed(marketPlacePath)
        ? history.location.search
          ? history.location.pathname + history.location.search
          : history.location.pathname
        : marketPlacePath;

      const redirectLoginOptions: RedirectLoginOptions = {
        redirectUri,
        screen_hint: redirectUri?.includes(AUTH_REFERRAL_INVITE) ? 'signup' : 'login',
      };

      if (overwriteSsoConnection) {
        redirectLoginOptions.connection = overwriteSsoConnection;
      }

      loginWithAuth0Redirect(redirectLoginOptions);
    }

    if (marketPlacePath) {
      validatePath(marketPlacePath).then(supplier => {
        if (supplier) {
          setMarketplaceSupplier(supplier);
        } else {
          const isSwitchTenant = sessionStorage.getItem('isSwitchTenant');
          if (isSwitchTenant) {
            sessionStorage.removeItem('isSwitchTenant');
            history.push('/menu');
          }
        }
      });
    }

    if (statementInvitationId) {
      setStatementInvitation(statementInvitationId);
      history.replace(props.location.pathname || '', null); // Clears location.state
    }
  }, []);

  const accessDenied = (
    <div>
      <p className="ml-2 basis-full text-center font-poppins text-xl font-light text-primary">
        Access Denied <br />
      </p>
      <Button variant="outlined" className="mx-auto mt-3 block bg-white" onClick={() => logOut()}>
        Try Again
      </Button>
    </div>
  );

  return (
    <PublicShell>
      <Box display="flex">{isAccessDenied ? accessDenied : <LoadingIndicator isLoading text="Loading..." />}</Box>
    </PublicShell>
  );
};
