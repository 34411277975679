import useHttp from '../hooks/useHttp';
import {IActionResultsPayments, IActionResultsServiceJob} from '../model/ActionResults';
import {IQuoteAttachment} from '../model/quotes/quotes';
import {ISearchFilter} from '../model/search-filters/SearchFilter';
import {
  ICustomerListResponse,
  IDailySummaryJob,
  IRequiresAttentionServiceJobRes,
  IServiceJob,
  IServiceTenantConfig,
  ITechnician,
  ITechnicianViewJobs,
} from '../model/service-management/serviceJob';
import {IPagedResults} from '../model/supplier/Supplier';
import {IActionRes} from './useServiceJobStatusAPI';

const useServiceJobAPI = () => {
  const {POST, GET, isLoading} = useHttp();

  const createJobFromQuote = (quoteID: number): Promise<IServiceJob> => {
    return POST(`Spenda/ServiceJobs/ServiceJobs/CreateFromQuote/${quoteID}`, {}).then(
      (data: IActionResultsPayments<IServiceJob>) => data.value,
    );
  };

  const updateServiceJob = (data: Partial<IServiceJob>): Promise<IServiceJob> => {
    return POST(`Spenda/ServiceJobs/ServiceJobs/${data.serviceJobID}`, data).then(
      (data: IActionResultsPayments<IServiceJob>) => data.value,
    );
  };

  const getServiceJob = (serviceJobID: number): Promise<IServiceJob> => {
    return GET(`Spenda/ServiceJobs/ServiceJobs/${serviceJobID}`).then(
      (data: IActionResultsPayments<IServiceJob>) => data.value,
    );
  };

  const getJobsList = (searchFilter: ISearchFilter): Promise<{items: IServiceJob[]} & IPagedResults> => {
    return GET(`Spenda/ServiceJobs/ServiceJobs`, searchFilter).then(data => data?.value);
  };

  const getDailySummaryJobs = (searchFilter: ISearchFilter): Promise<IDailySummaryJob[]> => {
    return GET(`Spenda/ServiceJobs/ServiceJobs/DailySummary`, searchFilter).then(data => data?.value?.items);
  };

  const getRequiresAttentionList = (searchFilter: ISearchFilter): Promise<IRequiresAttentionServiceJobRes> => {
    return GET(`Spenda/ServiceJobs/ServiceJobs/RequiresAttention`, searchFilter).then(data => ({
      items: data?.value?.items,
      totalRecordCount: data?.value?.totalRecordCount,
    }));
  };

  const fetchServiceAttachments = (serviceJobID: number): Promise<IQuoteAttachment> => {
    return GET(`Spenda/ServiceJobs/ServiceJobs/${serviceJobID}/Attachments`).then(data => data?.value?.attachments);
  };

  const createAdhocJob = (data: Partial<IServiceJob>): Promise<IServiceJob> => {
    return POST(`Spenda/ServiceJobs/ServiceJobs`, data).then((data: IActionResultsPayments<IServiceJob>) => data.value);
  };

  const cancelServiceJob = (serviceJobID: number, cancelReason: string): Promise<IServiceJob> => {
    return POST(`Spenda/ServiceJobs/ServiceJobs/${serviceJobID}/Cancel`, {cancelReason}).then(
      (data: IActionResultsPayments<IServiceJob>) => data.value,
    );
  };

  const setJobStatusAsScheduled = (serviceJobID: number): Promise<IServiceJob> => {
    return POST(`Spenda/ServiceJobs/ServiceJobs/${serviceJobID}/SetAsScheduled`, {}).then(
      (data: IActionResultsPayments<IServiceJob>) => data.value,
    );
  };

  const sendJobToCustomer = (serviceJobID: number, isSendAlert: boolean): Promise<IServiceJob> => {
    return POST(`Spenda/ServiceJobs/ServiceJobs/${serviceJobID}/Sent`, {isSendAlert}).then(
      (data: IActionResultsPayments<IServiceJob>) => data.value,
    );
  };

  const getServiceTechnicians = (): Promise<ITechnician[]> => {
    return GET(`Spenda/ServiceJobs/ServiceJobs/ServiceTechnicians`).then(data => data.value.technicians);
  };

  const getSchedulerViewJobs = (searchFilter: ISearchFilter): Promise<ITechnicianViewJobs[]> => {
    return GET(`Spenda/ServiceJobs/ServiceJobs/Calendar`, searchFilter).then(data => data.value.serviceJobs);
  };

  const setLineEditable = (serviceJobID: number, serviceJobLineID: number): Promise<IServiceJob> => {
    return POST(`Spenda/ServiceJobs/ServiceJobs/${serviceJobID}/Line/${serviceJobLineID}/SetAsEditable`, {}).then(
      data => data.value,
    );
  };

  const getCustomers = (searchFilter: ISearchFilter): Promise<IActionResultsServiceJob<ICustomerListResponse>> => {
    return GET(`Spenda/ServiceJobs/ServiceJobs/ServiceCustomers`, searchFilter);
  };

  const generateServiceJobInvoice = async (
    serviceJobIDs: number[],
  ): Promise<IActionRes<{serviceJobs: Partial<IServiceJob>[]}>> => {
    return POST(`Spenda/ServiceJobs/ServiceJobs/Invoices`, {ServiceJobIDs: serviceJobIDs}).then(data => data);
  };

  const getServiceTenantConfigs = async (): Promise<IServiceTenantConfig> => {
    return GET(`Spenda/Tenant/Configs/Service`).then(data => data.value);
  };

  const setServiceTenantConfigs = async (data: IServiceTenantConfig): Promise<IActionRes> => {
    return POST(`Spenda/Tenant/Configs/Service`, data).then(data => data);
  };

  return {
    cancelServiceJob,
    createAdhocJob,
    createJobFromQuote,
    fetchServiceAttachments,
    generateServiceJobInvoice,
    getCustomers,
    getDailySummaryJobs,
    getJobsList,
    getRequiresAttentionList,
    getSchedulerViewJobs,
    getServiceJob,
    getServiceTechnicians,
    getServiceTenantConfigs,
    isLoading,
    sendJobToCustomer,
    setJobStatusAsScheduled,
    setLineEditable,
    setServiceTenantConfigs,
    updateServiceJob,
  };
};

export default useServiceJobAPI;
