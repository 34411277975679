import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {Button, Switch, Typography} from 'spenda-ui-react';
import {setActive, useTabs} from 'spenda-ui-react/components/Tabs/TabsContext';
import {useFormikContext} from 'formik';

import VariantInventory from './VariantInventory';
import {ProductVariantIcon} from '../../assets/svg';
import {ARTable} from '../AccountsReceivable/ARTable';
import {CloseCross} from '../../assets/svg/CloseCross';
import {AlertDialog} from '../dialog/AlertDialogSlideV2';
import {ChildVariants, Inventory} from '../../model/inventory/Inventory';
import {AddVariantDialog} from '../dialog/AddVariantDialog';
import {InventoryItemsDialog} from './InventoryItemsDialog';
import {useInventoryAPI} from '../../services/useInventoryAPI';
import {AddAttributeDialog} from '../dialog/AddAttributeDialog';
import CreateMasterVariantDialog from '../dialog/CreateMasterVariantDialog';
import {IAddProductVariation, IAddProductVariationOptions} from '../../model/variants';
import EditCustomerIcon from '../../assets/svg/EditCustomerIcon';
import useVariantAPI from '../../services/useVariantAPI';
import {CreateNewProductItemModal} from '../accountsPayableOnboarding/CreateNewProductItemModal';

interface VariantAttributeProps {
  label: string;
  shortLabel: string;
  onClick: (label: string, shortLabel: string) => void;
  color?: string;
  imageUrl?: string;
  sellPrice?: string;
}

interface AttributeButtonProps {
  label: string;
  shortLabel: string;
  onClick: (label: string, shortLabel: string) => void;
}

interface VariantProps {
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}

interface ChildPropertySwitchProps {
  id: string;
  name: string;
  label: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  noMarginTop?: boolean;
}

const AttributeButton: React.FC<AttributeButtonProps> = ({label, shortLabel, onClick}) => (
  <div
    className="flex cursor-pointer items-center rounded-bl-md rounded-tl-md bg-gray-200 py-1 text-sm"
    onClick={() => onClick(label, shortLabel)}
    role="button"
    tabIndex={0}
    data-autoid="btnAttribute"
  >
    <Typography
      variant="small"
      className="flex !h-2 items-center justify-between text-ellipsis whitespace-nowrap bg-gray-200 !px-2 text-sm text-black hover:no-underline"
      data-autoid="txtAttributeName"
    >
      {label}
    </Typography>
    <Typography variant="small" className="ml-4 mr-4 text-gray-500" data-autoid="txtAttributeAbbr">
      {shortLabel}
    </Typography>
  </div>
);

const VariantAttribute: React.FC<VariantAttributeProps> = ({
  label,
  shortLabel,
  onClick,
  color,
  imageUrl,
  sellPrice,
}) => (
  <div className="flex flex-col justify-center">
    <div className="flex items-center gap-1">
      <AttributeButton onClick={onClick} label={label} shortLabel={shortLabel} />
      {color && (
        <div
          className="h-10 w-10 rounded-xl  border border-[#ECECEC] shadow-sm"
          style={{backgroundColor: `#${color}`}}
        ></div>
      )}
      {imageUrl && <img src={imageUrl} alt={label} className="mr-4 h-10 w-10 rounded-xl" />}
    </div>
    {sellPrice && (
      <Typography variant="xsmall" className="font-medium text-spenda-sGreen">
        {sellPrice}
      </Typography>
    )}
  </div>
);

const ChildPropertySwitch: React.FC<ChildPropertySwitchProps> = ({id, name, label, checked, onChange, noMarginTop}) => (
  <div className={`flex !w-[500px] max-w-full items-start justify-between gap-7 ${noMarginTop ? '' : 'mt-10'}`}>
    <Typography variant="paragraph" className="font-poppins font-medium text-black-800">
      {label}
    </Typography>
    <div className="flex items-center justify-center">
      <Switch
        id={id}
        name={name}
        ripple={false}
        className="h-full w-full checked:bg-success"
        checked={checked}
        onChange={onChange}
        containerProps={{
          className: 'w-11 h-6',
        }}
        circleProps={{
          className: 'before:hidden left-0.5 border-none',
        }}
      />
    </div>
  </div>
);

export const Variant: React.FC<VariantProps> = ({setActiveTab}) => {
  const {productID} = useParams<{productID?: string | undefined}>();

  const {createInventory, getInventoryById, isLoading} = useInventoryAPI();
  const {createSingleVariant, createAllChildVariants} = useVariantAPI();
  const {values, setFieldValue, setValues} = useFormikContext<Inventory>();
  const {dispatch} = useTabs();

  const [isLinkedDialog, setIsLinkedDialog] = useState<boolean>(false);
  const [isNewItemDialog, setIsNewItemDialog] = useState<boolean>(false);
  const [isVariantInventoryDialog, setIsVariantInventoryDialog] = useState<boolean>(false);
  const [inventoryItemsDialog, setInventoryItemsDialog] = useState({
    isOpen: false,
    childVariantIndex: -1,
  });

  const [openMasterVariantDialog, setOpenMasterVariantDialog] = useState<boolean>(false);
  const [showStockAlert, setShowStockAlert] = useState<boolean>(false);
  const [openVariantDialog, setOpenVariantDialog] = useState<boolean>(false);
  const [openAttributeDialog, setOpenAttributeDialog] = useState<boolean>(false);
  const [selectedVariationDetails, setSelectedVariationDetails] = useState<IAddProductVariation | null>(null);
  const [selectedVariationOptionsDetails, setSelectedVariationOptionsDetails] =
    useState<IAddProductVariationOptions | null>(null);
  const [loadingNewItemCreation, setLoadingNewItemCreation] = useState<{[key: string]: boolean}>({});
  const [viaCreateAll, setViaCreateAll] = useState<boolean>(false);
  const [openChildItemEdit, setOpenChildItemEdit] = useState<boolean>(false);
  const [isCreatingAllChildren, setIsCreatingAllChildren] = useState<boolean>(false);
  const [showPostingBehaviour, setShowPostingBehaviour] = useState<boolean>(false);
  const [selectedChildVariantID, setSelectedChildVariantID] = useState<number>();
  const [isInternalTab, setIsInternalTab] = useState<boolean>(false);

  const handleCreateNewItem = async (variationOptionIDs: number[]) => {
    if (productID) {
      try {
        setLoadingNewItemCreation(prevState => ({...prevState, [variationOptionIDs.join('-')]: true}));
        await createSingleVariant(productID, variationOptionIDs);
        const updatedInventoryData = await getInventoryById(productID);
        setValues(updatedInventoryData);
      } catch (error) {
        console.error('Failed to create new item:', error);
      } finally {
        setLoadingNewItemCreation(prevState => ({...prevState, [variationOptionIDs.join('-')]: false}));
      }
    }
  };

  const allVariantsCreated = values?.ChildVariants?.every(variant => variant.ID);

  const handleCreateAllChildVariants = async () => {
    if (productID) {
      const hasUnmappedItems = values.ChildVariants?.some(variant => !variant.ID);
      if (hasUnmappedItems) {
        try {
          setIsCreatingAllChildren(true);
          // If there is already an inventoryclass associated with the master product, user is not asked to select the posting behaviour. So, creating all children on single click
          if (values?.InventoryClassID) {
            await createAllChildVariants(productID);
            const updatedInventoryData = await getInventoryById(productID);
            setValues(updatedInventoryData);
          } else {
            setIsVariantInventoryDialog(true);
            setViaCreateAll(true);
            setShowPostingBehaviour(true);
          }
        } catch (error) {
          console.error('Failed to create all child variants');
        } finally {
          setIsCreatingAllChildren(false);
        }
      }
    }
  };

  const handleUpdateChildVariants = async () => {
    if (productID) {
      const updatedInventoryData = await getInventoryById(productID);
      setValues(updatedInventoryData);
    }
  };

  const handleCloseVariantInventory = async () => {
    setIsVariantInventoryDialog(false);
    if (!showPostingBehaviour) {
      setFieldValue('', false);
    }
    await handleUpdateChildVariants();
  };

  const handleCloseVariationBuilder = async () => {
    setOpenMasterVariantDialog(false);
    if (productID) {
      const updatedInventoryData = await getInventoryById(productID);
      setValues(updatedInventoryData);
      setFieldValue('IsVariantMaster', updatedInventoryData.IsVariantMaster || false);
    }
  };

  const handlePromoteToMaster = async () => {
    if (productID) {
      try {
        const payload = {
          ...values,
          ID: productID,
          IsVariantMaster: true,
          IsSOHTracked: false,
          IsSold: false,
          IsPurchased: false,
          IsPostingInventoryItem: false,
        };
        const res = await createInventory(payload);
        setOpenMasterVariantDialog(true);
        setIsLinkedDialog(false);
        setIsNewItemDialog(false);
        setFieldValue('IsVariantMaster', true);
        if (res.IsSuccess) {
          setFieldValue('IsSold', false);
          setFieldValue('IsPurchased', false);
        }
      } catch (error) {
        console.error('Failed to promote to master:', error);
      }
    }
  };

  const handleToggleChange = async () => {
    if (values.IsVariantMaster) {
      return;
    }
    if (values?.IsSOHTracked) {
      setShowStockAlert(true);
      return;
    }
    if (productID) {
      try {
        if (!values?.IsIntegrated) {
          setIsNewItemDialog(true);
        } else {
          setIsLinkedDialog(true);
        }
      } catch (error) {
        console.error('Failed to fetch inventory:', error);
      }
    }
    // setFieldValue('IsVariantMaster', !values.IsVariantMaster);
  };

  const handleDialogCancel = () => {
    setIsLinkedDialog(false);
    setIsNewItemDialog(false);
    if (!showPostingBehaviour) {
      setFieldValue('IsVariantMaster', false);
    }
  };

  const handleAddVariant = () => {
    setOpenVariantDialog(true);
    setOpenMasterVariantDialog(false);
    setSelectedVariationDetails(null);
  };

  const handleEditVariation = (variation: IAddProductVariation) => {
    setOpenVariantDialog(true);
    setOpenMasterVariantDialog(false);
    setSelectedVariationDetails(variation);
  };

  const handleAddAttribute = (variation: IAddProductVariation) => {
    const clearedVariation: IAddProductVariation = {
      ...variation,
      Options: [
        {
          Name: '',
          AbbreviatedName: '',
          StandardSellPriceExDelta: 0,
          StandardSellPriceIncDelta: 0,
          Colour: '',
          MediaFileID: null,
          MediaFileUrl: '',
          MediaFileThumbnailUrl: '',
          IsActive: true,
          VariationOptionID: null,
        },
      ],
    };
    setSelectedVariationDetails(clearedVariation);
    setSelectedVariationOptionsDetails(null);
    setOpenAttributeDialog(true);
    setOpenMasterVariantDialog(false);
  };

  const handleEditAttribute = (variation: IAddProductVariation, option: IAddProductVariationOptions) => {
    setSelectedVariationDetails(variation);
    setSelectedVariationOptionsDetails(option);
    setOpenAttributeDialog(true);
    setOpenMasterVariantDialog(false);
  };

  const handleEditMasterPrice = async () => {
    setOpenChildItemEdit(false);
    setActive(dispatch, 1);
    setActiveTab(1);
  };

  const columns = [
    {
      title: 'Attributes',
      key: 'attributes',
      width: '60%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5 ',
      rowRenderer: (childVariants: ChildVariants, index: number) => (
        <div className="scrollbar-hide flex max-w-[550px] items-center gap-3 overflow-x-auto">
          <Typography variant="small">{index + 1}.</Typography>
          {childVariants?.VariationOptions?.map(option => (
            <VariantAttribute
              key={option.Name}
              label={option.Name || ''}
              onClick={() => {}}
              shortLabel={option.AbbreviatedName || ''}
              color={option.Colour}
              imageUrl={option.MediaFileUrl}
              sellPrice={option.StandardSellPriceIncDelta?.toString()}
            />
          ))}
        </div>
      ),
    },
    {
      title: 'Inventory items',
      key: 'inventoryItems',
      width: '40%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5 bg-spenda-scream/30',
      rowRenderer: (childVariants: ChildVariants, index: number) => {
        const variationOptionIDs = childVariants?.VariationOptions?.map(option => option.VariationOptionID) || [];
        return (
          <div className="flex gap-2">
            {childVariants.ID ? (
              <>
                <Typography
                  key={childVariants.ID}
                  variant="small"
                  className="max-w-[300px] text-wrap font-semibold"
                  data-autoid={`txtUseExisting`}
                >
                  {childVariants.ShortDescription}
                </Typography>

                <EditCustomerIcon
                  className="ml-auto mr-3 cursor-pointer"
                  onClick={() => {
                    setSelectedChildVariantID(childVariants?.ID);
                    setOpenChildItemEdit(true);
                    setIsInternalTab(true);
                  }}
                  data-autoid={`btnEditItem`}
                />
              </>
            ) : (
              <>
                <Typography
                  variant="small"
                  className="cursor-pointer font-semibold text-spenda-labeltext"
                  onClick={() => setInventoryItemsDialog({childVariantIndex: index, isOpen: true})}
                >
                  Use existing
                </Typography>
                <Typography variant="small">or</Typography>
                <Typography
                  variant="small"
                  className="cursor-pointer font-semibold text-spenda-labeltext"
                  data-autoid={`txtCreateNewItem`}
                  onClick={() =>
                    loadingNewItemCreation[variationOptionIDs.join('-')]
                      ? undefined
                      : handleCreateNewItem(variationOptionIDs)
                  }
                >
                  {loadingNewItemCreation[variationOptionIDs.join('-')] ? 'Creating...' : 'Create new item'}
                </Typography>
              </>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="mt-5 flex">
        <div className="w-full lg:w-4/5">
          {!values?.IsVariantMaster && (
            <div className="flex w-full max-w-full items-start gap-7 lg:w-[916px]">
              <div className="mt-[22px] flex items-center justify-center">
                <Switch
                  id="IsLinked"
                  name="IsLinked"
                  ripple={false}
                  className={`h-full w-full checked:bg-success`}
                  onChange={handleToggleChange}
                  checked={isNewItemDialog}
                  containerProps={{
                    className: 'w-11 h-6',
                  }}
                  circleProps={{
                    className: 'before:hidden left-0.5 border-none',
                  }}
                />
              </div>
              <div className="flex flex-col gap-2">
                <Typography className="font-poppins text-lg font-medium text-black-800">
                  Do you want to enable variations for this product?
                </Typography>
                <Typography
                  variant="small"
                  className="max-w-full text-start font-poppins font-normal text-gray-800 lg:w-[554px]"
                >
                  If you enable variations, this item will no longer be sold and you will be prompted to select the
                  specific variation during the sales process.
                </Typography>
              </div>
            </div>
          )}

          {values?.IsVariantMaster && (
            <>
              <ChildPropertySwitch
                id="IsTrackStockEnabled"
                name="IsTrackStockEnabled"
                label="Do you Track Stock On Hand For All Child Variants?"
                checked={values.ChildIsSOHTracked ?? false}
                onChange={e => setFieldValue('ChildIsSOHTracked', e.target.checked)}
                noMarginTop
              />
              <ChildPropertySwitch
                id="IsChildSold"
                name="IsChildSold"
                label="Do You Sell Child Variants?"
                checked={values.ChildIsSold ?? false}
                onChange={e => setFieldValue('ChildIsSold', e.target.checked)}
              />
              <ChildPropertySwitch
                id="IsChildPurchased"
                name="IsChildPurchased"
                label="Do You Buy Child Variants?"
                checked={values.ChildIsPurchased ?? false}
                onChange={e => setFieldValue('ChildIsPurchased', e.target.checked)}
              />
              <ChildPropertySwitch
                id="IsPhysicalItem"
                name="IsPhysicalItem"
                label="Are your Child Variants Physical Items?"
                checked={values.ChildIsPhysical ?? false}
                onChange={e => setFieldValue('ChildIsPhysical', e.target.checked)}
              />

              <div className="mt-10 flex w-full flex-col rounded-md bg-spenda-cream px-2.5 lg:w-[996px]">
                <div className="flex items-center justify-between p-3 ">
                  <Typography className="text-lg font-medium text-black-800">Product variants</Typography>
                  <Button variant="outlined" onClick={() => setOpenMasterVariantDialog(true)}>
                    Variation Builder
                  </Button>
                </div>
                <div className="mb-4 h-1 w-full border-b border-b-[#ECECEC]" />
                <Typography
                  className={`pl-2.5 text-base font-semibold ${
                    !isCreatingAllChildren && !allVariantsCreated ? 'cursor-pointer text-primary' : 'text-primary/50'
                  }`}
                  onClick={!isCreatingAllChildren && !allVariantsCreated ? handleCreateAllChildVariants : undefined}
                >
                  {isCreatingAllChildren ? 'Creating...' : 'Create All'}
                </Typography>
                <ARTable
                  isLoading={false}
                  columns={columns}
                  scope="AR"
                  rows={values?.ChildVariants || []}
                  tableClass="mt-4"
                />
              </div>
            </>
          )}
        </div>

        {!values.IsVariantMaster && (
          <div className="flex h-[350px] w-full max-w-full flex-col items-start rounded-md bg-[#E3EEF5] p-4 lg:w-[320px]">
            <div className="self-end">
              <CloseCross data-autoid={'btnCross'} className="cursor-pointer text-[#cccccc]" />
            </div>
            <div className="flex flex-col items-center gap-y-2 space-x-4 pt-3">
              <ProductVariantIcon />
              <div className="mt-5 flex flex-col items-center justify-center text-center">
                <Typography variant="paragraph" className="font-poppins text-lg font-medium text-black-800">
                  Benefits of adding variants
                </Typography>
                <Typography variant="small" className="pt-3 font-poppins font-normal text-gray-800">
                  Product variants are a great way to manage the same product that differs in style, colour size, etc.
                  Each combination of value will result in a unique product selectable during the sales process.
                </Typography>
              </div>
            </div>
          </div>
        )}
      </div>

      {isLinkedDialog && (
        <AlertDialog
          title="Linked to External System"
          headingTextSize="h2"
          headingClassess="text-black justify-center"
          dialogClassess="!max-w-[497px] !min-w-[20%]"
          data-autoid={'dlgLinkedToExternalSystem'}
          actions={[
            {
              label: 'Cancel',
              variant: 'outlined',
              action: handleDialogCancel,
            },
            {
              label: 'Continue',
              loading: isLoading,
              action: () => {
                setIsVariantInventoryDialog(true);
                setShowPostingBehaviour(false);
                setIsLinkedDialog(false);
              },
            },
          ]}
          dialogBodyClassess="flex justify-center"
          contentClass="max-w-[374px] text-sm"
          body={
            <div className="flex flex-col gap-3 px-[2.5rem] py-3 text-center">
              <Typography variant="small" className="font-normal text-black-800">
                This product is integrated with an external system, In order to create a Master Inventory Item we will
                need to create a copy. This may result in a duplicate product being displayed when in Sales mode. If you
                don't want the original product to appear in your product management, you can manually archive it.
              </Typography>
              <Typography variant="small" className="font-normal text-black-800">
                Would you like to continue?
              </Typography>
            </div>
          }
        ></AlertDialog>
      )}

      {isNewItemDialog && (
        <AlertDialog
          title="Make Master"
          headingTextSize="h2"
          headingClassess="text-black justify-center"
          dialogClassess="!max-w-[497px] !min-w-[20%]"
          data-autoid={'dlgMakeMasterVariant'}
          actions={[
            {
              label: 'Cancel',
              variant: 'outlined',
              action: handleDialogCancel,
            },
            {
              label: 'Continue',
              loading: isLoading,
              action: handlePromoteToMaster,
            },
          ]}
          dialogBodyClassess="flex justify-center"
          contentClass="max-w-[374px] text-sm"
          content={`Are you sure you want to make into a master variant? You cannot undo this action.`}
        />
      )}

      {showStockAlert && (
        <AlertDialog
          title="Track Stock Enabled"
          headingTextSize="h2"
          headingClassess="text-black justify-center"
          dialogClassess="!max-w-[497px] !min-w-[20%]"
          data-autoid="dlgStockAlert"
          actions={[
            {
              label: 'Cancel',
              variant: 'outlined',
              action: () => setShowStockAlert(false),
            },
          ]}
          dialogBodyClassess="flex justify-center"
          dialogActionsAlignment="justify-end"
          contentClass="max-w-[374px] text-sm"
          content={` This item has Track Stock enabled. You can either create a new item to become the master inventory item or
            disable stock tracking.`}
        />
      )}

      {isVariantInventoryDialog && (
        <VariantInventory
          handleClose={handleCloseVariantInventory}
          showPostingBehaviour={showPostingBehaviour}
          viaCreateAll={viaCreateAll}
        />
      )}

      {inventoryItemsDialog.isOpen && (
        <InventoryItemsDialog
          handleCancel={() =>
            setInventoryItemsDialog({
              childVariantIndex: -1,
              isOpen: false,
            })
          }
          variationOptions={values?.ChildVariants?.[inventoryItemsDialog.childVariantIndex]?.VariationOptions!}
          inventoryID={values?.ID}
        />
      )}

      {openMasterVariantDialog && (
        <CreateMasterVariantDialog
          open={openMasterVariantDialog}
          onClose={handleCloseVariationBuilder}
          productID={productID}
          handleAddVariant={handleAddVariant}
          handleEditVariation={handleEditVariation}
          handleAddAttribute={handleAddAttribute}
          handleEditAttribute={handleEditAttribute}
        />
      )}

      {openVariantDialog && (
        <AddVariantDialog
          open={openVariantDialog}
          onClose={() => {
            setOpenVariantDialog(false);
          }}
          productID={productID}
          variationDetails={selectedVariationDetails}
          setOpenMasterVariantDialog={setOpenMasterVariantDialog}
        />
      )}

      <AddAttributeDialog
        open={openAttributeDialog}
        handleClose={() => {
          setOpenAttributeDialog(false);
          setOpenMasterVariantDialog(true);
          setSelectedVariationDetails(null);
          setSelectedVariationOptionsDetails(null);
        }}
        setOpenMasterVariantDialog={setOpenMasterVariantDialog}
        variationDetails={selectedVariationDetails}
        variationOptionsDetails={selectedVariationOptionsDetails}
        productID={productID}
      />

      {openChildItemEdit && (
        <CreateNewProductItemModal
          handleClose={async () => {
            setOpenChildItemEdit(false);
            await handleUpdateChildVariants();
          }}
          childVariantID={selectedChildVariantID}
          setOpenChildItemEdit={setOpenChildItemEdit}
          isInternalTab={isInternalTab}
          handleEditMasterPrice={handleEditMasterPrice}
        />
      )}
    </>
  );
};
