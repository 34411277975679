import {useCallback} from 'react';
import useHttp from '../hooks/useHttp';
import {APOnboardingSteps, LowTouchPsblOnboardingSteps, ModuleTypes} from '../model/constants/Constants';
import {
  ICreateAccountCodeResponse,
  IGetInventoryTemplates,
  IOnboardingV1Step,
  IOnboardingV1StepResponse,
  IPsblOnboardingSteps,
} from '../model/OnboardingV1Step';
import {IBaseResponseV3} from '../model/accounts-receivable/AccountsReceivable';

type NextStepType = {
  action: 'next';
  completeSteps: APOnboardingSteps[] | LowTouchPsblOnboardingSteps[];
};
type SkipStepType = {
  action: 'skip';
  skipSteps: APOnboardingSteps[] | LowTouchPsblOnboardingSteps[];
};

type StartStepType = {
  action: 'start';
};

type TabChangeType = {
  nextStep?: APOnboardingSteps | LowTouchPsblOnboardingSteps;
  isPsblOnboarding?: boolean;
} & (SkipStepType | NextStepType | StartStepType);

type InventoryPayload = {
  inventoryCode: string;
  shortDescription: string;
  expenseAccount: string;
};

export interface ICreateClearingAccountPayload {
  Object: {
    IsActive: boolean;
    Code: string;
    Name: string;
    Description: string;
    Type: string;
    TaxType: string;
    Class: string;
    IsEnablePayments: boolean;
    ParentAccountID: number | undefined;
  };
}

export const useOnboarding = () => {
  const {GET, POST, isLoading} = useHttp();

  const getOnboardingStep = async (): Promise<IOnboardingV1Step[]> => {
    return await GET('/Spenda/Payments/AccountsPayable/OnboardingStepProgress').then(
      (data: IOnboardingV1StepResponse) => data.value,
    );
  };

  const updateOnboardingStep = async (payload: IOnboardingV1Step[]) => {
    return await POST('/Spenda/Payments/AccountsPayable/OnboardingStepProgress', payload);
  };

  // PSBL Onboarding APIs
  const getPsblOnboardingSteps = async (query: {ModuleID: number}): Promise<IPsblOnboardingSteps[]> => {
    return await GET(`Spenda/Tenant/Modules/Onboarding/Steps`, query).then(data => data?.value?.steps);
  };

  const updatePsblOnboardingStep = async (payload: {moduleID: number; steps: IPsblOnboardingSteps[]}) => {
    return await POST(`/Spenda/Tenant/Modules/Onboarding/Steps`, payload);
  };

  const saveInventories = async (payload: {inventories: InventoryPayload[]}) => {
    return await POST(`/Spenda/Inventory/Marketplaces/Inventories`, payload);
  };

  const requestMeeting = async (payload?: {requestedMeetingFor?: string}) => {
    return await POST(`/Spenda/Tenant/Modules/Onboarding/MeetingRequest`, payload);
  };

  const getInventoryTemplate = async (marketplaceID: number): Promise<IBaseResponseV3<IGetInventoryTemplates>> => {
    return await GET(`Spenda/Inventory/Marketplaces/${marketplaceID}/InventoryTemplate`).then(data => data);
  };

  const createAccount = async (payload: ICreateClearingAccountPayload): Promise<ICreateAccountCodeResponse> => {
    return POST(`/accounts`, payload);
  };

  const handleTabChange = useCallback(async (param: TabChangeType) => {
    const {action, nextStep, isPsblOnboarding} = param;

    let payload: (IOnboardingV1Step | IPsblOnboardingSteps)[] = [];
    switch (action) {
      case 'next':
        const {completeSteps} = param;
        for (let step of completeSteps) {
          payload.push({
            stepID: step,
            isCompleted: true,
          });
        }
        if (nextStep)
          payload.push({
            stepID: nextStep,
            isCompleted: false,
          });
        break;
      case 'skip':
        const {skipSteps} = param;
        if (skipSteps) {
          for (let step of skipSteps) {
            payload.push({
              stepID: step,
              isSkipped: true,
            });
          }
        }
        if (nextStep)
          payload.push({
            stepID: nextStep,
            isCompleted: false,
          });
        break;
      case 'start':
        if (nextStep) {
          payload.push({
            stepID: nextStep,
            isCompleted: false,
          });
        }
        break;
    }
    if (isPsblOnboarding) {
      await updatePsblOnboardingStep({moduleID: ModuleTypes.SpendaPay, steps: payload as IPsblOnboardingSteps[]});
    } else {
      await updateOnboardingStep(payload as IOnboardingV1Step[]);
    }
  }, []);

  return {
    getOnboardingStep,
    getPsblOnboardingSteps,
    updateOnboardingStep,
    handleTabChange,
    createAccount,
    getInventoryTemplate,
    saveInventories,
    requestMeeting,
    isLoading,
  };
};
