import React, {useState, useContext, useEffect} from 'react';
import {Box, Divider, InputAdornment, Tooltip} from '@material-ui/core';
import {SButton} from '../buttons/Button';
import NextArrowIcon from '../../assets/svg/next-arrow.svg';
import {STextField} from '../inputs/STextField';
import SPhoneInput from '../inputs/SPhoneInput';
import {AlertDialogSlide} from '../dialog/AlertDialogSlide';
import {DropZoneComponent} from '../inputs/DropZone';
import useTenantAPI from '../../services/useTenantAPI';
import AppContext from '../../context/app/appContext';
import BackIcon from '../../assets/svg/back.svg';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {validateABN} from '../../utils/validators';
import {LogoTypeEnum} from '../../model/Tenant';
import {Formik, Form, FormikHelpers} from 'formik';
import * as Yup from 'yup';
import LoadingIndicator from '../ui/LoadingIndicator';
import {SModal} from '../modals/modalSpendaMeterialUI';
import {ChatWidget} from './ChatWidget';
import CloseIcon from '@material-ui/icons/Close';
import {GetBackgroundCSS} from './Onboarding';
import {makeStyles} from '@material-ui/core/styles';
import arrowIcon from '../../assets/svg/arrowIcon.svg';
import {APFooter} from '../accountsPayableOnboarding/APFooter';
import backButton from '../../assets/png/back-button.png';
import useMarketPlaceAPI from '../../services/useMarketPlaceAPI';
import HelpIcon from '@material-ui/icons/Help';
import useGetTenantDetails from '../../services/useGetTenantDetails';
interface BusinessDetailsProps {
  onNextClick: () => void;
  onBackPress: () => void;
  organisationName?: string;
  handleClose: () => void;
  isBuyer: boolean;
  isAPOnboarding?: boolean;
  selectedAdaptorName?: string;
}
export interface BusinessDetailsFormValues {
  abn: string;
  arContactEmailAddress: string | undefined;
  companyBanner: string | undefined;
  companyBannerThumb: string | undefined;
  companyIcon: string | undefined;
  companyIconThumb: string | undefined;
  companyLogo: string | undefined;
  companyLogoThumb: string | undefined;
  companyName: string;
  companyPhone: string;
  companyEmailAddress: string;
  companyTagLine: string;
  mySpendaUrl: string;
  mySpendaWithMarketplacePath: string;
  website: string;
  uploadedFiles: {
    companyLogo: File | undefined;
    companyIcon: File | undefined;
    companyBanner: File | undefined;
  };
}

const newTenant: BusinessDetailsFormValues = {
  companyName: '',
  abn: '',
  companyPhone: '',
  website: '',
  mySpendaUrl: '',
  companyLogo: '',
  companyIcon: '',
  companyBanner: '',
} as BusinessDetailsFormValues;

const useBusinessDetailsStyles = makeStyles({
  apOnboardingModal: {
    maxWidth: '800px',
  },
  arOnboardingModal: {
    '& .MuiFormHelperText-root.Mui-error': {
      top: '30px !important',
    },
  },
  apBusinessHeader: {
    borderBottom: '1px solid #CCCCCC',
  },
  boxHeight: {
    maxHeight: '85%',
    height: '100%',
    overflow: 'hidden',
  },
  backBtn: {
    maxWidth: '40px',
    height: '40px',
  },
  formRow: {
    '& .MuiTextField-root': {},
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'transparent!important',

      '& fieldset': {
        borderColor: '#333333',
      },
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px!important',
      borderColor: '#333333',
    },
    '& .MuiInputBase-input': {
      padding: '12px 15px 14px 8px!important',
      height: 'auto',
      backgroundColor: 'white',
      fontWeight: 600,
      fontSize: '14px',
      fontFamily: 'Poppins, sans-serif !important',
      '&::placeholder': {
        fontSize: '12px',
        fontWeight: 400,
      },
    },
    '& .MuiFormLabel-root': {
      color: '#333333!important',
      fontWeight: '500',
      top: '-5px',
      fontSize: '14px',
      fontFamily: 'Poppins, sans-serif !important',
      width: 'auto',
    },
    '& .MuiFilledInput-root': {
      background: '#3333',
    },
    '& .MuiFormLabel-root.Mui-focused, .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: '#333!important',
      top: '0',
      fontSize: '14px',
      fontFamily: 'Poppins, sans-serif !important',
    },
    '& .react-tel-input ': {
      width: '100% !important',
    },
    '& .react-tel-input input': {
      width: '100% !important',
      borderRadius: '4px !important',
      background: 'transparent !important',
      height: '45px !important',
      border: '1px solid #333 !important',
      fontSize: '14px',
      fontWeight: 600,
    },
    '& .react-tel-input .flag-dropdown': {
      borderRadius: '0px',
      backgroundColor: 'transparent !important',
      borderRight: '1px solid #333',
    },
    '& legend': {
      fontSize: '15px',
    },
    '& #companyName-helper-text': {
      fontFamily: 'Poppins, sans-serif !important',
    },
  },
  formLabel: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#333333',
    fontFamily: 'Poppins, sans-serif !important',
    marginBottom: '1px',
  },
  formLabelTitle: {
    fontWeight: 300,
    fontSize: '28px',
    color: '#333333',
    fontFamily: 'Poppins, sans-serif !important',
  },
  businessDetailScroll: {
    height: '70%',
    fontFamily: 'Poppins, sans-serif !important',
    '&::-webkit-scrollbar': {
      width: '12px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#fff',
      borderRadius: '7px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#D8D8D8',
      borderRadius: '7px',
    },
  },
  phoneNumberLabel: {
    fontFamily: 'Poppins, sans-serif !important',
    display: 'inline-block',
    position: 'absolute',
    background: 'white',
    left: '9px',
    top: '-8px',
    padding: '0px 4px',
    fontSize: '11px',
    color: '#333 !important',
  },
  abnTextError: {
    '& .MuiFormHelperText-root.Mui-error': {
      top: '39px !important',
      fontFamily: 'Poppins, sans-serif !important',
    },
    '& .MuiFormHelperText-root.MuiFormHelperText-contained': {
      marginLeft: '1px !important',
    },
  },
  CompanyTextError: {
    fontFamily: 'Poppins, sans-serif !important',

    '& .MuiFormHelperText-root.MuiFormHelperText-contained': {
      top: '40px !important',
      marginTop: '1px !important',
      marginLeft: '1px !important',
      color: 'red !important',
    },
  },
  numberInput: {
    '& .react-tel-input': {
      '& .selected-flag:hover, .selected-flag:focus': {
        background: 'transparent !important',
      },
      '& .flag-dropdown.open .selected-flag': {
        background: 'transparent !important',
      },
      '& .country-list': {
        margin: '0px',
      },
    },
  },
  businesslogo: {
    width: '100%',
  },
  apSuggestionBox: {
    '& p': {
      width: '100%',
      margin: '-22px 0 0 0px',
      textAlign: 'left',
      fontSize: '10px',
      padding: '0px',
      color: '#333333',
      fontWeight: 500,
      '& span': {
        color: '#1C78AD',
        fontWeight: 600,
        cursor: 'pointer',
      },
    },
  },
});

export const BusinessDetails = (props: BusinessDetailsProps) => {
  const marketplaceAPI = useMarketPlaceAPI();

  const domain = window.location.origin + '/';
  const tenantAPI = useTenantAPI();
  const appCtx = useContext(AppContext);
  const tenant = appCtx?.tenant;
  const classes = useBusinessDetailsStyles();
  const {getTenantDetails} = useGetTenantDetails();

  const [isShowDiscardChangesConfirmation, setIsShowDiscardChangesConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState<boolean>(true);
  const [phoneError, setPhoneError] = useState<string>('');
  const [xeroOrganizationName, setXeroOrganizationName] = useState<string>(props.organisationName || '');
  const [abnError, setAbnError] = useState<string>('');
  const [isMarketPlaceURLUnique, setIsMarketPlaceURLUnique] = useState<boolean | undefined>();

  useEffect(() => {
    setXeroOrganizationName(props.organisationName || '');
  }, [props.organisationName]);

  useEffect(() => {
    newTenant.companyName = tenant?.Name || '';
    newTenant.abn = tenant?.ABN || '';
    newTenant.companyPhone = tenant?.Phone || '';
    newTenant.website = tenant?.WebSiteURL || '';
    newTenant.arContactEmailAddress = tenant?.ARContactEmailAddress || '';
    if (tenant?.MarketPlacePath) {
      newTenant.mySpendaUrl = tenant.MarketPlacePath;
      setIsMarketPlaceURLUnique(true);
    }

    if (tenant?.Logos?.length) {
      for (let logo of tenant.Logos) {
        if (logo.LogoTypeID == LogoTypeEnum.Banner) {
          newTenant.companyBanner = logo.URI || '';
        } else if (logo.LogoTypeID == LogoTypeEnum.Logo) {
          newTenant.companyLogo = logo.URI || '';
        } else if (logo.LogoTypeID == LogoTypeEnum.Icon) {
          newTenant.companyIcon = logo.URI || '';
        }
      }
    }
  }, []);

  const validationSchema = Yup.object({
    companyName: Yup.string()
      .required('Company Name is required.')
      .max(100, 'Company Name cannot be longer than 100 characters.'),
    companyPhone: Yup.string()
      .required('Company Phone is required.')
      .min(11, 'Invalid Phone no.')
      .max(12, 'Invalid Phone no.'),
    abn: Yup.string().required('ABN is required'),
    mySpendaUrl: !props.isAPOnboarding
      ? Yup.string()
          .required('Market place url is required')
          .matches(/^[a-z0-9]+(-?[a-z0-9]+)+$/, 'Only lower case letters, number, and hyphen is allowed')
      : Yup.string().optional(),
    website: Yup.string().url('Invalid URL (e.g. http://www.example.com)'),
  });

  const filesChanged = (file: File, fieldName: string, setFieldValue: (field: string, value?: any) => void) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      // 'reader.result' contains the data URL for the file
      const imgUrl = reader.result;
      setFieldValue(fieldName, imgUrl ?? '');
      setFieldValue(fieldName + 'Thumb', imgUrl ?? '');
    };

    reader.readAsDataURL(file);
    setFieldValue(`uploadedFiles.${fieldName}`, file);
  };

  const onUploadChange = (files: File[], fieldName: string, setFieldValue: (field: string, value: any) => void) => {
    if (!files.length) {
      return;
    }
    if (!files[0].name) {
      return;
    }

    filesChanged(files[0], fieldName, setFieldValue);
  };

  const onRemoveUpload = (fieldName: string, setFieldValue: (field: string, value: any) => void) => {
    setFieldValue(fieldName, '');
    setFieldValue(`${fieldName}Thumb`, '');
    setFieldValue(`uploadedFiles.${fieldName}`, undefined);
  };

  const abnValidator = (value: any) => {
    const result = validateABN(value);
    if (!result) {
      return false;
    } else if (!result.valid) {
      return false;
    }
    return true;
  };

  const phoneValidator = (value: any) => {
    if (!isValidPhone || value.length < 12) {
      return false;
    }
    return true;
  };

  const onSubmit = async (
    BusinessDetails: BusinessDetailsFormValues,
    helper: FormikHelpers<BusinessDetailsFormValues>,
    initialBusinessDetails: BusinessDetailsFormValues,
  ) => {
    // submit Company Details
    let error;
    if (!props.isAPOnboarding && !phoneValidator(BusinessDetails.companyPhone)) {
      error = true;
      setPhoneError('Please Enter Valid Phone Number');
    } else {
      setPhoneError('');
    }

    if (!abnValidator(BusinessDetails.abn)) {
      error = true;
      setAbnError('Please Enter Valid ABN');
    } else {
      setAbnError('');
    }
    if (error) {
      return;
    }
    setIsLoading(true);
    if (!!tenant?.MarketPlacePath) {
      BusinessDetails.mySpendaUrl = tenant.MarketPlacePath;
    }
    const tenantDetails = await getTenantDetails(BusinessDetails, initialBusinessDetails);
    if (!tenantDetails) {
      setIsLoading(false);
      return;
    }

    const isSaved = (await tenantAPI.saveTenantDetails(tenantDetails, tenantDetails.ARContactEmailAddress)).IsSuccess;
    setIsLoading(false);
    if (isSaved) {
      appCtx.setTenant(tenantDetails);
      props.onNextClick();
    }
  };
  const onBackPress = (dirty: boolean) => {
    if (dirty) {
      setIsShowDiscardChangesConfirmation(true);
    } else {
      props.onBackPress();
    }
  };
  const handleArrowIconClick = (url: string) => {
    if (url !== '') {
      window.open(url);
    }
  };

  const checkPathUnique = async (url: string) => {
    if (url) {
      marketplaceAPI.marketPlacePathUnique(url).then((isUnique: boolean) => {
        setIsMarketPlaceURLUnique(isUnique);
      });
    }
  };

  return (
    <SModal open>
      <div className={`spenda-color flex h-full max-w-full items-center justify-center text-xl ${GetBackgroundCSS()}`}>
        <Formik
          initialValues={newTenant}
          validationSchema={validationSchema}
          onSubmit={(value, helper) => onSubmit(value, helper, newTenant)}
          enableReinitialize
        >
          {({values, touched, errors, handleSubmit, handleBlur, handleChange, setFieldValue, isValid, dirty}) => (
            <Form onSubmit={handleSubmit} className="add-user-form flex items-center justify-center">
              <div
                className={`modal-box ${
                  props.isAPOnboarding
                    ? `${classes.apOnboardingModal} px-3 py-3`
                    : `${classes.arOnboardingModal} select-account-modal max-w-3xl px-5 py-5`
                }`}
              >
                {props.isAPOnboarding && (
                  <Box className={`${classes.apBusinessHeader} -mx-3 mb-1 flex justify-between px-3 pb-3`}>
                    <span className="cursor-pointer" onClick={() => onBackPress(dirty)} data-autoid="btnBack">
                      <img src={backButton} alt="Back Button" className={classes.backBtn} />
                    </span>
                    <p id="simple-modal-description" className={`${classes.formLabelTitle} w-full text-center`}>
                      Business Details
                    </p>
                    <Box className="flex justify-end text-right">
                      <span className="ap-onboarding-close-icon" onClick={props.handleClose} data-autoid="btnClose">
                        <CloseIcon />
                      </span>
                    </Box>
                  </Box>
                )}
                {props.isBuyer && !props.isAPOnboarding && (
                  <span className="closeIcon onboarding-close-icon" onClick={props.handleClose} data-autoid="btnClose">
                    <CloseIcon />
                  </span>
                )}
                {!props.isAPOnboarding && (
                  <>
                    <span
                      className="cursor-pointer"
                      onClick={() => onBackPress(dirty)}
                      style={{position: 'relative'}}
                      data-autoid="btnBack"
                    >
                      <img src={BackIcon} alt="Next Arrow Icon" className="back-btn-bg-image" />
                      <ChevronLeftIcon className="back-btn-icon" />
                    </span>
                    <p
                      id="simple-modal-description"
                      className={'simple-modal-title mx-12 -mt-5 mb-6 pb-3 text-center'}
                      style={{fontSize: '31px'}}
                    >
                      "Now let's finish entering your business details. You can also upload your logo here and it will
                      be included on invoices and emails sent through Spenda."
                    </p>
                  </>
                )}
                <div
                  className={
                    props.isAPOnboarding
                      ? `${classes.businessDetailScroll} flex w-full justify-between overflow-y-auto overflow-x-hidden px-5 pt-8`
                      : 'row flex h-72 w-full justify-between overflow-y-auto px-10'
                  }
                >
                  <div className={props.isAPOnboarding ? 'w-full' : 'w-full'}>
                    <Box
                      className={props.isAPOnboarding ? `${classes.formRow} mb-3` : 'mb-3'}
                      justifyContent="space-between"
                      display="flex"
                    >
                      <div
                        className={
                          props.isAPOnboarding
                            ? `${classes.CompanyTextError} label mr-2 md:w-1/2`
                            : 'label mr-2 md:w-1/2'
                        }
                      >
                        {!props.isAPOnboarding && (
                          <Box className="label">
                            Business Name<span className="text-red-500">*</span>
                          </Box>
                        )}
                        <STextField
                          id="companyName"
                          name="companyName"
                          placeholder={props.isAPOnboarding ? 'Enter your Business Name' : ''}
                          label={props.isAPOnboarding ? 'Business Name' : null}
                          variant={props.isAPOnboarding ? 'outlined' : undefined}
                          helperText={(touched.companyName && errors.companyName) || ''}
                          error={touched.companyName && Boolean(errors.companyName)}
                          autoComplete="off"
                          value={values.companyName}
                          onChange={e => handleChange(e)}
                          onBlur={handleBlur}
                          fullWidth
                        />
                        {xeroOrganizationName !== null &&
                          xeroOrganizationName !== '' &&
                          values.companyName !== xeroOrganizationName && (
                            <div
                              className={`${
                                props.isAPOnboarding
                                  ? `${classes.apSuggestionBox} relative z-50`
                                  : 'suggestion-box items-center justify-center'
                              }`}
                            >
                              {props.isAPOnboarding ? (
                                <p>
                                  Found '{xeroOrganizationName}' in {props?.selectedAdaptorName || 'Xero'}{' '}
                                  <span onClick={() => setFieldValue('companyName', xeroOrganizationName)}>
                                    Use Instead
                                  </span>
                                </p>
                              ) : (
                                <p>"We've found something different in {props?.selectedAdaptorName || 'xero'} :"</p>
                              )}
                              {!props.isAPOnboarding && (
                                <SButton
                                  fontSize="s"
                                  onClick={() => setFieldValue('companyName', xeroOrganizationName)}
                                >
                                  {xeroOrganizationName}
                                </SButton>
                              )}
                            </div>
                          )}
                      </div>
                      <div
                        className={
                          props.isAPOnboarding
                            ? `${classes.abnTextError} label ml-0 flex justify-end md:w-1/2`
                            : 'label ml-2 md:w-1/2'
                        }
                      >
                        {!props.isAPOnboarding && (
                          <Box className="label">
                            ABN<span className="text-red-500">*</span>
                          </Box>
                        )}
                        <STextField
                          id="abn"
                          name="abn"
                          autoComplete="off"
                          placeholder={props.isAPOnboarding ? 'Enter your ABN' : ''}
                          label={props.isAPOnboarding ? 'ABN' : null}
                          variant={props.isAPOnboarding ? 'outlined' : undefined}
                          helperText={(touched.abn && errors.abn) || abnError}
                          error={(touched.abn && Boolean(errors.abn)) || Boolean(abnError)}
                          value={values.abn}
                          onChange={e => {
                            if (abnValidator(e.target.value)) {
                              setAbnError('');
                            } else if (touched.abn) {
                              setAbnError('Please Enter Valid ABN');
                            }
                            setFieldValue('abn', e.target.value);
                          }}
                          onBlur={handleBlur}
                          fullWidth
                        />
                      </div>
                    </Box>
                    <Box
                      className={props.isAPOnboarding ? `${classes.formRow}` : 'mb-3'}
                      justifyContent="space-between"
                      display="flex"
                    >
                      <div
                        className={`label relative mr-2 md:w-1/2 ${
                          props.isAPOnboarding ? `${classes.numberInput} ${classes.abnTextError}` : ''
                        } `}
                      >
                        {!props.isAPOnboarding && (
                          <Box className="label">
                            Business Phone Number
                            <span className="text-red-500">*</span>
                          </Box>
                        )}
                        {!props.isAPOnboarding ? (
                          <>
                            <SPhoneInput
                              name="companyPhone"
                              theme="secondary"
                              countryCodeEditable={true}
                              onBlur={handleBlur}
                              onChange={value => {
                                if (phoneValidator(value)) {
                                  setPhoneError('');
                                } else if (touched.companyPhone) {
                                  setPhoneError('Please Enter Valid Phone Number');
                                }
                                setFieldValue('companyPhone', value);
                              }}
                              isValidCallback={isValid => {
                                window.setTimeout(() => setIsValidPhone(isValid), 0);
                              }}
                              value={values.companyPhone}
                            />
                            {((errors.companyPhone && touched.companyPhone) || phoneError) && (
                              <span className={'text-sm text-[#ff0000]'}>{errors.companyPhone || phoneError}</span>
                            )}
                          </>
                        ) : (
                          <STextField
                            id="companyPhone"
                            name="companyPhone"
                            autoComplete="off"
                            placeholder={props.isAPOnboarding ? 'Enter your Company Phone no.' : ''}
                            label={props.isAPOnboarding ? 'Company Phone No.' : null}
                            variant={props.isAPOnboarding ? 'outlined' : undefined}
                            helperText={(touched.companyPhone && errors.companyPhone) || phoneError}
                            error={(touched.companyPhone && Boolean(errors.companyPhone)) || Boolean(phoneError)}
                            value={values.companyPhone}
                            type="number"
                            onChange={e => {
                              setFieldValue('companyPhone', e.target.value);
                            }}
                            onBlur={handleBlur}
                            fullWidth
                          />
                        )}
                      </div>
                      <div
                        className={
                          props.isAPOnboarding ? 'label ml-0 flex justify-end md:w-1/2' : 'label ml-2 md:w-1/2'
                        }
                      >
                        {!props.isAPOnboarding && <Box className="label">Website</Box>}
                        <STextField
                          id="website"
                          name="website"
                          placeholder={props.isAPOnboarding ? 'Enter your Company Website' : ''}
                          label={props.isAPOnboarding ? 'Company Website' : null}
                          variant={props.isAPOnboarding ? 'outlined' : undefined}
                          helperText={errors.website || ''}
                          error={touched.website && Boolean(errors.website)}
                          value={values.website}
                          onChange={handleChange}
                          InputProps={{
                            endAdornment: props.isAPOnboarding ? (
                              <InputAdornment position="end">
                                <img
                                  onClick={() => {
                                    handleArrowIconClick(values.website);
                                  }}
                                  style={{color: 'white'}}
                                  className="cursor-pointer"
                                  alt="iconCopy"
                                  src={arrowIcon}
                                />
                              </InputAdornment>
                            ) : null,
                          }}
                          fullWidth
                        />
                      </div>
                    </Box>
                    {!props.isAPOnboarding && (
                      <Box className="mb-3" justifyContent="space-between" display="flex">
                        <div className="label mr-2 md:w-1/2">
                          <Box className="label">Account Receivable Contact</Box>
                          <STextField
                            id="arContactEmailAddress"
                            name="arContactEmailAddress"
                            helperText={'These details will be added to the Accounts Receivable Invoice'}
                            error={touched.arContactEmailAddress && Boolean(errors.arContactEmailAddress)}
                            value={values.arContactEmailAddress}
                            onChange={handleChange}
                            fullWidth
                          />
                        </div>
                      </Box>
                    )}
                    {!props.isAPOnboarding && (
                      <>
                        <Box
                          className={props.isAPOnboarding ? `${classes.formRow} mb-3` : 'mb-3'}
                          justifyContent="space-between"
                          display="flex"
                        >
                          <div className={'label w-full'}>
                            <Box className="label">
                              What would you like your Spenda link to be?<span className="text-red-500">*</span>
                              <Tooltip
                                title={
                                  'This unique name will be used when sending your customer a statement or invoice. This link cannot be changed. A suggestion is your short business name.'
                                }
                              >
                                <HelpIcon
                                  className="cursor-pointer"
                                  style={{height: '1rem', width: '1rem', marginTop: '-2px', color: '#1c78ad'}}
                                />
                              </Tooltip>
                            </Box>
                            {!tenant?.MarketPlacePath ? (
                              <>
                                <STextField
                                  style={{paddingBottom: '0px'}}
                                  className="pb-4"
                                  disabled={!!tenant?.MarketPlacePath}
                                  id="mySpendaUrl"
                                  name="mySpendaUrl"
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">{domain}</InputAdornment>,
                                  }}
                                  placeholder={''}
                                  error={touched.mySpendaUrl && Boolean(errors.mySpendaUrl)}
                                  autoComplete="off"
                                  value={values.mySpendaUrl}
                                  onChange={e => {
                                    setIsMarketPlaceURLUnique(undefined);
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                  inputProps={{prefix: domain}}
                                  fullWidth
                                />
                                <div>
                                  <SButton
                                    padding="0 20px"
                                    disabled={!!errors.mySpendaUrl || !values.mySpendaUrl}
                                    className="cursor-pointer px-2"
                                    onClick={() => {
                                      if (!errors.mySpendaUrl) checkPathUnique(values.mySpendaUrl);
                                    }}
                                  >
                                    CHECK AVAILABILITY
                                  </SButton>
                                  {errors.mySpendaUrl && touched.mySpendaUrl ? (
                                    <span className={'ml-4 text-sm text-[#ff0000]'}>{errors.mySpendaUrl}</span>
                                  ) : isMarketPlaceURLUnique !== undefined ? (
                                    isMarketPlaceURLUnique ? (
                                      <span className="ml-4">My spenda URL is available</span>
                                    ) : (
                                      <span className={'ml-4 text-sm text-[#ff0000]'}>
                                        My spenda URL is not available
                                      </span>
                                    )
                                  ) : null}
                                </div>
                              </>
                            ) : (
                              <div style={{padding: '10px 0 0 7px', background: '#F1F1F1', cursor: 'no-drop'}}>
                                {domain + tenant?.MarketPlacePath}
                              </div>
                            )}
                          </div>
                        </Box>
                      </>
                    )}

                    {props.isAPOnboarding && (
                      <Divider
                        className="w-full"
                        style={{height: '1px !important', backgroundColor: '#CCCCCC', margin: '0px 0px 15px'}}
                      />
                    )}
                    <Box className="mb-3" justifyContent="space-between" display="flex">
                      <div className="label mr-1 md:w-1/2">
                        <Box className={props.isAPOnboarding ? `${classes.businesslogo}` : ''}>
                          <Box className={props.isAPOnboarding ? `${classes.formLabel}` : 'label'}>Business Logo</Box>
                          <DropZoneComponent
                            uploadedFile={values.companyLogo}
                            onChange={files => onUploadChange(files, 'companyLogo', setFieldValue)}
                            placeholder={'This logo is used on your invoices and on Spenda landing page'}
                            onRemove={() => onRemoveUpload('companyLogo', setFieldValue)}
                            dialogTitle={'Delete Business Logo'}
                            dialogDescription={'Would you like to delete Business Logo?'}
                            isAPOnboardingDropZone={props.isAPOnboarding ? true : false}
                          ></DropZoneComponent>
                        </Box>
                      </div>
                      <div className={`label ml-1 md:w-1/2 ${props.isAPOnboarding ? 'flex justify-end' : ''}`}>
                        <Box className={props.isAPOnboarding ? `${classes.businesslogo}` : ''}>
                          <Box className={props.isAPOnboarding ? `${classes.formLabel}` : 'label'}>Business Icon</Box>
                          <DropZoneComponent
                            uploadedFile={values.companyIcon}
                            onChange={files => onUploadChange(files, 'companyIcon', setFieldValue)}
                            placeholder={
                              'This icon must be 100 x 100 px and is used for your customers in the My Spenda menu'
                            }
                            onRemove={() => onRemoveUpload('companyIcon', setFieldValue)}
                            dialogTitle={'Delete Business Icon'}
                            dialogDescription={'Would you like to delete Business Icon?'}
                            isAPOnboardingDropZone={props.isAPOnboarding ? true : false}
                          ></DropZoneComponent>
                        </Box>
                      </div>
                    </Box>
                    {props.isAPOnboarding && (
                      <Divider
                        className="w-full"
                        style={{height: '2px !important', backgroundColor: '#CCCCCC', margin: '20px 0px'}}
                      />
                    )}
                    <Box className="mb-5" justifyContent="space-between" display="flex">
                      <div className="column w-full justify-center">
                        {props.isAPOnboarding ? (
                          <Box className={`${classes.formLabel}`}>Banner Image</Box>
                        ) : (
                          <Box className="label">Business Banner</Box>
                        )}
                        <DropZoneComponent
                          uploadedFile={values.companyBanner}
                          onChange={files => onUploadChange(files, 'companyBanner', setFieldValue)}
                          placeholder={
                            'This banner must be 936 x 120 px and is used in the Spenda header and is viewing by any of your connected customers'
                          }
                          onRemove={() => onRemoveUpload('companyBanner', setFieldValue)}
                          dialogTitle={'Delete Business Banner'}
                          dialogDescription={'Would you like to delete Business Banner?'}
                          isAPOnboardingDropZone={props.isAPOnboarding ? true : false}
                        ></DropZoneComponent>
                      </div>
                    </Box>
                  </div>
                </div>

                {!props.isAPOnboarding && (
                  <>
                    <Box className={`center mt-5 flex justify-center`}>
                      <SButton
                        className="common-next-btn"
                        type="submit"
                        width={'m'}
                        color={'blueShade'}
                        lineHeight={'m'}
                        fontSize={'m'}
                        disabled={
                          isLoading || !isValid || Boolean(abnError) || Boolean(phoneError) || !isMarketPlaceURLUnique
                        }
                        data-autoid="btnNext"
                      >
                        Next
                        {isLoading && !props.isAPOnboarding ? (
                          <LoadingIndicator
                            isLoading={isLoading}
                            size="sm"
                            color="hsl(var(--white))"
                            position={{right: '5px'}}
                          />
                        ) : props.isAPOnboarding ? null : (
                          <img src={NextArrowIcon} alt="Next Arrow Icon" className="" />
                        )}
                      </SButton>
                    </Box>
                    <div className="live-chat-section">
                      <ChatWidget />
                    </div>
                  </>
                )}
                {props.isAPOnboarding && (
                  <APFooter isDisabled={isLoading || !isValid || Boolean(abnError) || Boolean(phoneError)} />
                )}
              </div>
            </Form>
          )}
        </Formik>
        {isShowDiscardChangesConfirmation && (
          <AlertDialogSlide
            title="Discard Changes"
            actions={[
              {label: 'Yes', action: () => props.onBackPress()},
              {
                label: 'No',
                color: 'transparent',
                textColor: 'blue',
                variant: 'outlined',
                border: '1px solid #2F97BC;',
                className: 'delete-image-cancel-btn',
                action: () => setIsShowDiscardChangesConfirmation(false),
              },
            ]}
          >
            <p className="pb-3">Are you sure you want to discard your changes ? </p>
          </AlertDialogSlide>
        )}
      </div>
    </SModal>
  );
};
