import React from 'react';
import {ARDialogTemplate} from '../ARDialogTemplate';
import {Button, Typography} from 'spenda-ui-react';
import {
  IStagingPurchaseInvoiceTaxAdjustment,
  ITaxAdjustmentPayload,
  useStagedTransactionsAPI,
} from '../../../services/useStagedTransactionsAPI';
import {ISelectedRow} from '../../../screens/AccountsReceivable/requires-attention/RequiresAttention';

interface IPostAsNewInvoiceModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleDone: () => void;
  selectedRows: ISelectedRow[];
  taxAdjusments: IStagingPurchaseInvoiceTaxAdjustment[];
}

export const PostAsNewInvoiceModal = (props: IPostAsNewInvoiceModalProps) => {
  // Props
  const {isOpen, onClose, selectedRows, handleDone, taxAdjusments} = props;

  // APIs
  const {approveStagedTransactions, taxAdjustment, isLoading} = useStagedTransactionsAPI();

  const handleTaxAdjustment = async () => {
    try {
      const payload: ITaxAdjustmentPayload = {stagingTransactionAdjustments: taxAdjusments};
      return await taxAdjustment(payload);
    } catch (error) {
      console.error('Tax adjustment failed:', error);
      throw error;
    }
  };

  const handlePostAsNewInvoice = async () => {
    try {
      if (selectedRows?.length) {
        const messageIds = selectedRows?.map(selectedRow => selectedRow.messageID);
        await approveStagedTransactions(messageIds, true);
      }
    } catch (error) {
      console.error('Posting as new invoice failed:', error);
    } finally {
      handleDone?.();
    }
  };

  const handleAdjustmentAndPost = async (): Promise<void> => {
    try {
      if (taxAdjusments?.length) {
        await handleTaxAdjustment();
      }
      await handlePostAsNewInvoice();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handlePost = () => {
    handleAdjustmentAndPost();
  };

  return (
    <ARDialogTemplate
      dialogProps={{size: 'xs', open: isOpen, className: '!min-w-[460px] !max-w-[460px]'}}
      isFullScreen
      header={<p className="font-poppins text-xl font-light text-[#333333]">Post as a new</p>}
      body={
        <div className="my-12">
          <Typography
            variant="paragraph"
            className="text-center font-light text-black-800"
            data-autoid={`lblSelectedInvoices`}
          >
            {` Are you sure you would like to post these ${selectedRows?.length} as new transaction? This action cannot be
            undone. `}
          </Typography>
        </div>
      }
      footer={
        <div className="flex w-full flex-row items-center justify-between">
          <Button
            data-autoid={`btnCancel`}
            variant="outlined"
            className="bg-white"
            color="primary"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button data-autoid={`btnPost`} variant="filled" color="primary" loading={isLoading} onClick={handlePost}>
            Post
          </Button>
        </div>
      }
    />
  );
};
