import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import {DatTypes, IAlertTypes, CONSIGNMENT_NOTE_STATUS} from '../../model/constants/Constants';
import {ApplicationInfo} from '../../model/constants/ApplicationInfo';
import {IConsignmentNote} from '../../model/sales-order/ConsignmentNote';
import {Layout} from '../../components/layout/Layout';
import {SButton} from '../../components/buttons/Button';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {SalesOrderShipItems} from '../../components/sales-orders/SalesOrderShipItems';
import {useConsignmentNoteAPI} from '../../services/useConsignmentNoteAPI';
import useAlertAPI from '../../services/useAlertAPI';
import {Toast} from '../../utils/Toast';

import {Divider, makeStyles} from '@material-ui/core';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';

const useOrderDetailsStyles = makeStyles(() => ({
  root: {
    maxHeight: 'calc( 100vh - 20.5em )',
    height: '100%',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.2em',
    color: '#4D4D4D',
  },
  divider: {
    backgroundColor: '#EAEAEA',
  },
  partialOrder: {
    border: '1px solid #C68A19',
    backgroundColor: '#C68A1910',
    fontSize: '12px',
    fontWeight: 500,
    color: '#C68A19',
    borderRadius: '3px',
    display: 'inline-block',
    lineHeight: '22px',
  },
}));

export const SalesOrderShip = () => {
  const classes = useOrderDetailsStyles();
  let {consignmentNoteID} = useParams<{consignmentNoteID?: string | undefined}>();

  const [consignmentNote, setConsignmentNote] = useState<IConsignmentNote>();
  const [trackingUrl, setTrackingUrl] = useState<string>('');
  const [trackingNumber, setTrackingNumber] = useState<string>('');

  const {
    updateConsignmentNoteStatus,
    getConsignmentNoteById,
    patchConsignmentNote,
    isLoading: consignmentLoading,
  } = useConsignmentNoteAPI();
  const {getPrintOut, isLoading: alertLoading} = useAlertAPI();

  const isLoading = alertLoading || consignmentLoading;

  useEffect(() => {
    if (consignmentNoteID) {
      getConsignmentNote();
    }
  }, []);

  const getConsignmentNote = async () => {
    const conNoteRes = await getConsignmentNoteById(consignmentNoteID);
    if (conNoteRes.IsSuccess) {
      setConsignmentNote(conNoteRes.Value);
    }
  };

  const handleShipConNote = async () => {
    const conNoteStatus = await updateConsignmentNoteStatus(consignmentNoteID, {
      Status: CONSIGNMENT_NOTE_STATUS.AWAITING_PICKUP,
    });
    if (conNoteStatus.IsSuccess) {
      Toast.info('Consignment Note Awaiting Pickup');
      getConsignmentNote();
    }
  };

  const handleDeliverConNote = async () => {
    if (trackingNumber || trackingUrl) {
      await patchConsignmentNote(consignmentNote?.ID, {
        TrackingUrl: trackingUrl || '',
        TrackingNumber: trackingNumber || '',
      });
    }
    const conNoteStatus = await updateConsignmentNoteStatus(consignmentNoteID, {
      Status: CONSIGNMENT_NOTE_STATUS.DELIVERED,
    });
    if (conNoteStatus.IsSuccess) {
      Toast.info('Consignment Note Delivered');
      getConsignmentNote();
    }
  };

  const handlePrintConNoteSlip = async () => {
    if (consignmentNoteID) {
      const data = await getPrintOut({
        DocIDs: [Number(consignmentNoteID)],
        DatTypeID: DatTypes.ConsignmentNote,
        AlertType: IAlertTypes.Pdf,
        WebsiteID: ApplicationInfo.WebsiteId,
      });
      if (data?.AlertMsg) {
        const pdf = data.AlertMsg.AttachmentFileNames[0];
        window.open(pdf, '_blank');
      }
    }
  };

  const _orderDetails = (
    <div
      className={`${classes.root} flex min-h-full w-full flex-col overflow-y-auto rounded-lg bg-white`}
      id="sales-orders-list"
    >
      <div className={`flex w-full justify-between px-5 pb-3 pt-2.5`}>
        <div className="flex">
          <p className={`${classes.title} my-auto`}>
            {consignmentNote?.Status === CONSIGNMENT_NOTE_STATUS.DELIVERED ? 'Consignment Note' : 'Ship Items'}
          </p>
        </div>
        <div className="flex">
          <SButton
            margin="0px  0px 0px 4px"
            color="blueShade"
            border="1px solid #1C78AD"
            textColor="white"
            boxShadow="none"
            lineHeight="m"
            type="button"
            width="lg"
            fontSize="m"
            isSubmitting={alertLoading}
            onClick={handlePrintConNoteSlip}
          >
            <PrintOutlinedIcon fontSize="small" className="pr-1 text-white" />
            Print Consignment Note Slip
          </SButton>
          {consignmentNote?.Status === CONSIGNMENT_NOTE_STATUS.APPROVED ? (
            <SButton
              margin="0 0.25rem"
              color="blueShade"
              textColor="white"
              lineHeight="m"
              width="l"
              boxShadow="none"
              type="button"
              isSubmitting={isLoading}
              disabled={isLoading}
              onClick={handleShipConNote}
            >
              Pickup Booked
            </SButton>
          ) : null}
          {consignmentNote?.Status === CONSIGNMENT_NOTE_STATUS.AWAITING_PICKUP ? (
            <SButton
              margin="0 0.25rem"
              color="blueShade"
              textColor="white"
              lineHeight="m"
              width="l"
              boxShadow="none"
              type="button"
              isSubmitting={isLoading}
              disabled={isLoading}
              onClick={handleDeliverConNote}
            >
              Delivered
            </SButton>
          ) : null}
        </div>
      </div>
      <Divider classes={{root: classes.divider}} />
      <div className="relative flex flex-1 flex-col items-start justify-start px-5 py-5">
        <div className="h-full w-full">
          {consignmentNote ? (
            <SalesOrderShipItems
              consignmentNote={consignmentNote}
              trackingUrl={trackingUrl}
              setTrackingUrl={setTrackingUrl}
              trackingNumber={trackingNumber}
              setTrackingNumber={setTrackingNumber}
            />
          ) : null}
        </div>
      </div>
    </div>
  );

  return (
    <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
      <Layout leftPanel={_orderDetails} splitWidthType={4} />
      <LoadingIndicator isLoading={isLoading} size="md" color="hsl(var(--primary))" />
    </div>
  );
};
