import React from 'react';
import LoadingIndicator from '../ui/LoadingIndicator';
import {SButton, color, lineHeight, width} from '../buttons/Button';

export interface IActionFormButton {
  name: string;
  onClick?: (value?: any) => void;
  variant?: 'outlined' | 'text' | 'contained';
  color?: color;
  label?: color;
  border?: string;
  textColor?: color;
  disabled?: boolean;
  form?: string;
  hidden?: boolean;
  lineHeight?: lineHeight;
  width?: width;
  isSubmitting?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  backgroundColor?: string;
  height?: string;
  fontFamily?: string;
  padding?: string;
}

export const ActionFormButton = (props: IActionFormButton) => {
  const autoId = `btn${props.name.replaceAll(' ', '')}`;

  return (
    <>
      <div className="my-4 flex flex-col items-center">
        <SButton
          data-autoid={autoId}
          label={props.textColor}
          type={props.type}
          className={props.className || ''}
          form={props.form}
          hidden={props.hidden!}
          width={props.width}
          lineHeight={props.lineHeight}
          variant={props.variant || 'contained'}
          textColor={props.textColor}
          onClick={props.onClick}
          color={props.color}
          disabled={props.disabled}
          border={props.border}
          backgroundColor={props.backgroundColor}
          height={props.height}
          fontFamily={props.fontFamily}
          padding={props.padding}
        >
          {props.name}
          <LoadingIndicator
            isLoading={props.isSubmitting}
            size="sm"
            color="hsl(var(--white))"
            position={{right: '5px'}}
          />
        </SButton>
      </div>
    </>
  );
};
