import React, {PropsWithChildren, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {IQuotePackageDetails, IQuotes, IQuotesPackage} from '../../model/quotes/quotes';
import {ICustomer} from '../../model/customer/Customer';
import useQuotesAPI from '../../services/useQuotesAPI';
import {useCustomersAPI} from '../../services/useCustomersAPI';
import QuoteContext from './QuoteContext';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {AUTH_SELLING_QUOTES_LIST} from '../../routes/QuoteManagementRoutes';
import {QuoteStatus} from '../../model/constants/Constants';

const QuoteContextProvider: React.FC<PropsWithChildren> = ({children}) => {
  const history = useHistory();
  const {packageId: quotePackageID} = useParams<{packageId: string}>();
  const {
    getQuotePackage,
    isLoading: isQuoteLoading,
    updateQuotePackage,
    deleteQuote,
    deleteQuotePackage,
  } = useQuotesAPI();
  const {getCustomer, isLoading: isCustomerLoading} = useCustomersAPI();

  const [customerDetails, setCustomerDetails] = useState<ICustomer>();
  const [quotePackage, setQuotePackage] = useState<IQuotesPackage>();
  const [approvedQuote, setApprovedQuote] = useState<IQuotes>();
  const [selectedVariantID, setSelectedVariantID] = useState<number>(-1);
  const [selectedVariantTotalAmount, setSelectedVariantTotalAmount] = useState<number>(0);
  const [quotePackageDetails, setQuotePackageDetails] = useState<IQuotePackageDetails>();
  const [quoteCustomer, setQuoteCustomer] = useState<ICustomer>();
  const [duplicatePackage, setDuplicatePackage] = useState<boolean>(false);
  const [isCollapseRightPanal, setIsCollapseRightPanal] = useState<boolean>(false);
  const [showCustomerDetails, setShowCustomerDetails] = useState<boolean>(false);

  let isLoading = isQuoteLoading || isCustomerLoading;

  useEffect(() => {
    if (quotePackageID === undefined) {
      history.push('/menu');
      return;
    }
    const loadContext = async () => {
      try {
        const resQuotePackages = await getQuotePackage(parseInt(quotePackageID));
        const resSelectedCustomer = await getCustomer(resQuotePackages.customerID!);
        setCustomerDetails(resSelectedCustomer);
        setQuotePackage(resQuotePackages);
        if (resQuotePackages.quotes && resQuotePackages?.quotes?.length > 0) {
          setSelectedVariantID(resQuotePackages?.quotes[0]?.quoteID!);
          setSelectedVariantTotalAmount(resQuotePackages?.quotes[0]?.totalInc!);
        }
        setQuotePackageDetails({
          validFor: resQuotePackages.expiryDate!,
          assignQuoteToUserID: resQuotePackages.assignedToUserID!,
          expireInDays: resQuotePackages.expiresInDays,
          depositAmount: resQuotePackages.depositAmount,
          depositAmountType: resQuotePackages.depositAmountType,
        });
        if (resQuotePackages.status === QuoteStatus.Approved) {
          const quote = resQuotePackages.quotes?.find(quote => quote.status === QuoteStatus.Approved);
          setApprovedQuote(quote);
        }
      } catch (error) {
        history.push('/menu');
      }
    };

    loadContext();
  }, []);

  const refreshQuotePackage = async () => {
    const resQuotePackages = await getQuotePackage(parseInt(quotePackageID));
    setQuotePackage(resQuotePackages);
    if (resQuotePackages.status === QuoteStatus.Approved) {
      const quote = resQuotePackages.quotes?.find(quote => quote.status === QuoteStatus.Approved);
      setApprovedQuote(quote);
    }
  };

  const refreshCustomer = async () => {
    const updatedCustomer = await getCustomer(customerDetails?.ID!);
    setCustomerDetails(updatedCustomer);
  };

  const setCustomer = (customer?: ICustomer) => {
    setCustomerDetails(prevCustomer => {
      return {...customer, ID: prevCustomer?.ID};
    });
  };

  const handleDeleteQuote = async (quoteID?: number, deleteQuoteID?: number) => {
    if (deleteQuoteID || quoteID) {
      const id = deleteQuoteID || quoteID;
      await deleteQuote(id!);
      const filteredQuotes = quotePackage?.quotes?.filter(quote => quote.quoteID !== id);
      const quotePkg = quotePackage && {...quotePackage, quotes: filteredQuotes};
      setQuotePackage(quotePkg);
      setSelectedVariantID(quotePkg?.quotes?.[0]?.quoteID || -1);
      setSelectedVariantTotalAmount(quotePkg?.quotes?.[0]?.totalInc || 0);
      return Promise.resolve(true);
    }
    return Promise.resolve(false);
  };

  const handleDeletePackage = async () => {
    await deleteQuotePackage(quotePackage?.quotePackageID!);
    history.push(AUTH_SELLING_QUOTES_LIST);
  };

  return (
    <QuoteContext.Provider
      value={{
        quotePackage: quotePackage,
        customerDetails: customerDetails,
        setCustomerDetails: setCustomer,
        quoteCustomer,
        setQuoteCustomer,
        duplicatePackage,
        setDuplicatePackage,
        updateQuotePackage,
        refreshQuotePackage,
        setQuotePackage,
        selectedQuoteVariantID: selectedVariantID,
        setSelectedQuoteVariantID: setSelectedVariantID,
        selectedVariantTotalAmount: selectedVariantTotalAmount,
        setSelectedVariantTotalAmount: setSelectedVariantTotalAmount,
        quotePackageDetails: quotePackageDetails,
        setQuotePackageDetails: setQuotePackageDetails,
        refreshCustomer,
        handleDeleteQuote,
        handleDeletePackage,
        isLoading,
        isCollapseRightPanal,
        setIsCollapseRightPanal,
        approvedQuote: approvedQuote,
        setApprovedQuote: setApprovedQuote,
        showCustomerDetails,
        setShowCustomerDetails
      }}
    >
      <LoadingIndicator isLoading={isLoading} size={'md'} /> {children}
    </QuoteContext.Provider>
  );
};
export default QuoteContextProvider;
