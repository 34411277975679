import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {AccordionSummary, makeStyles} from '@material-ui/core';
import {
  IMonthlyTransaction,
  ITransaction,
  IFacilityTransaction,
  ISetFacilityPaymentAccountRef,
} from '../../../model/payment/Lending';
import {IHeadCell, SExpandableTable} from '../../../components/tables/SExpandableTable';
import {PriceFormat, getMonthName} from '../../../utils/formatter';
import {
  AccountUsage,
  PaymentProviderName,
  PaymentServiceName,
  SpendaFinanceFacilityTransactionType,
  UserRoleName,
  VerificationStatus,
} from '../../../model/constants/Constants';
import {CaretUp} from '../../../assets/svg/CaretUp';
import LinkIcon from '@material-ui/icons/Link';
import {ARDialogTemplate} from '../../../components/AccountsReceivable/ARDialogTemplate';
import {PrimaryButton, SecondaryButton} from '../../../components/buttons/DefaultButtons';
import useSpendaPaymentServicesAPI from '../../../services/useSpendaPaymentServicesAPI';
import AppContext from '../../../context/app/appContext';
import LoadingIndicator from '../../../components/ui/LoadingIndicator';
import {PaymentMethodSlide} from '../../pay-by-link/payment-widget/PaymentWidgetStepSelectPaymentMethod';
import {PaymentConverter} from '../../../utils/data-converters/payment-converter';
import {ITenantSupplierPaymentMethod} from '../../../model/payment/PaymentMethod';
import {InfoRound} from '../../../assets/svg/InfoRound';
import {WidgetTooltip} from '../../../components/data-display/WidgetTooltip';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import {useSupplierTransactionsAPI} from '../../../services/useSupplierTransactionsAPI';
import ARTooltip from '../../../components/data-display/ARTootip';

const useAccordianStyles = makeStyles({
  AccordionClass: {
    borderRadius: '4px 6px 1px 0px!important',
    backgroundColor: '#ECECEC !important',
    '&.Mui-expanded': {backgroundColor: '#1C78AD !important', color: '#fff !important'},
    '& .MuiAccordionSummary-root': {
      padding: '0px ',
      borderRadius: '6px 6px 10px 0px!important',
      color: 'red !important',
      backgroundColor: '#ECECEC',
      minHeight: '48px !important',
      '& .MuiAccordionSummary-content': {
        margin: '0!important',
      },
    },
    '& .MuiAccordionActions-root': {
      backgroundColor: '#ECECEC',
      padding: '0px!important',
    },
    '& .MuiAccordionDetails-root': {
      padding: '0px!important',
    },
    '& .caretUp': {backgroundColor: '#E7E7E7'},
    '&.Mui-expanded .caretUp': {backgroundColor: '#1C78AD', transform: 'rotate(180deg)'},
  },
});

interface IPsblLendingView {
  facilitytansaction: IFacilityTransaction;
  isFacilityDataLoading: boolean;
  refreshBorrowerTransactions: () => Promise<void>;
}

export const PsblLendingView = (props: IPsblLendingView) => {
  // props
  const {facilitytansaction, isFacilityDataLoading, refreshBorrowerTransactions} = props;

  // states
  const [showPaymentOptionDialog, setShowPaymentOptionDialog] = useState<boolean>(false);
  const [paymentMethods, setPaymentMethods] = useState<ITenantSupplierPaymentMethod[]>([]);
  const [defaultRepaymentMethod, setDefaultRepaymentMethod] = useState<ISetFacilityPaymentAccountRef[]>([
    {paymentOrder: 1, paymentAccountGUID: facilitytansaction?.defaultPaymentAccountGUID!},
  ]);

  // context
  const {marketplaceSupplier, userRole} = useContext(AppContext);

  // APIs
  const {setFacilityPaymentaccount} = useSupplierTransactionsAPI();
  const {getAccounts, isLoading} = useSpendaPaymentServicesAPI();

  // feature flags
  const {'loan2.184196': loan2_184196} = useFeatureFlags().tenantOwned();

  const handleSetFacilityPaymentAccount = (pm: ITenantSupplierPaymentMethod) => {
    setDefaultRepaymentMethod([{paymentOrder: 1, paymentAccountGUID: pm.PaymentAccountGUID!}]);
  };

  const onSavePaymentAccount = async () => {
    await setFacilityPaymentaccount(facilitytansaction.facilityGUID, {accounts: defaultRepaymentMethod});
    setShowPaymentOptionDialog(false);
    refreshBorrowerTransactions();
  };

  useEffect(() => {
    const defaultPaymentMethod = paymentMethods?.find(
      a => a.PaymentAccountGUID === facilitytansaction?.defaultPaymentAccountGUID,
    );
    defaultPaymentMethod &&
      setDefaultRepaymentMethod([{paymentOrder: 1, paymentAccountGUID: defaultPaymentMethod.PaymentAccountGUID!}]);
  }, [paymentMethods]);

  const classes = useAccordianStyles();
  const handleGetbadge = (status: string) => {
    switch (status) {
      case 'active':
        return (
          <div className="flex h-6 !w-fit items-center justify-center rounded-[6px] bg-[#e9edf3] px-5">
            <p className="font-poppins text-xs text-primary">Active</p>
          </div>
        );
      default:
        break;
    }
  };

  const handleGetVerifiedAccounts = async () => {
    const accounts = await getAccounts(PaymentProviderName.Spenda_Payment_Services, {
      merchantGlobalID: marketplaceSupplier?.GlobalID,
      accountUsage: AccountUsage.BUYER,
      verificationStatus: VerificationStatus.VERIFIED,
      creditPaymentServiceName: PaymentServiceName.MarketplaceProvider,
    });
    const formtedRes = accounts?.map(r => PaymentConverter.SpsToFiserveModel(r));
    setPaymentMethods(formtedRes);
  };

  const handlePaymentOptionsDialog = () => {
    handleGetVerifiedAccounts();
    setShowPaymentOptionDialog(true);
  };

  const drawDownTermsHead: IHeadCell<ITransaction>[] = [
    {
      id: 'paymentClearedDateTimeUtc',
      label: 'Date',
      align: 'left',
      render: (row: ITransaction) => {
        return <>{moment.utc(row?.paymentClearedDateTimeUtc).local().format('DD MMMM YYYY')}</>;
      },
      dataAutoid: 'lblTransactionDate',
    },
    {
      id: 'debit',
      label: 'Debit',
      align: 'left',
      render: (row: ITransaction) => {
        return <>{Math.abs(row?.debit) ? PriceFormat(Math.abs(row?.debit)) : ''}</>;
      },
      dataAutoid: 'lblTransactionDebit',
    },
    {
      id: 'credit',
      label: 'Credit',
      align: 'left',
      render: (row: ITransaction) => {
        return <>{Math.abs(row?.credit) ? PriceFormat(Math.abs(row?.credit)) : null}</>;
      },
      dataAutoid: 'lblTransactionCredit',
    },
    {
      id: 'type',
      label: 'Type',
      align: 'left',
      render: (row: ITransaction) => {
        return (
          <>
            {row.type === SpendaFinanceFacilityTransactionType.Drawdown
              ? 'Charge'
              : row.type === SpendaFinanceFacilityTransactionType.Receipt
                ? 'Repayment'
                : row.type}
          </>
        );
      },
      width: '129px',
      dataAutoid: 'lblTransactionType',
    },
    {
      id: 'description',
      label: 'Description',
      align: 'left',
      width: '270px',
      dataAutoid: 'lblTransactionDescription',
    },
    {
      id: 'balance',
      label: 'Balance',
      align: 'left',
      dataAutoid: 'lblBalance',
      render: (row: ITransaction) => {
        return <>{row?.balance < 0 ? `(${PriceFormat(Math.abs(row?.balance))})` : PriceFormat(row?.balance)}</>;
      },
    },
    {
      id: 'invoicePaymentAllocationID',
      label: 'Allocation report',
      align: 'center',
      render: (row: ITransaction) => {
        return (
          <Link
            data-autoid={`lnk-invoicePaymentAllocationID`}
            to={{
              pathname: `/supplier/${row?.supplierID}/payment-history`,
              search: `txDatType=79&txId=${row?.invoicePaymentAllocationID}`,
            }}
            target="_blank"
          >
            {row.invoicePaymentAllocationID && <LinkIcon className="-rotate-45 text-primary" />}
          </Link>
        );
      },
    },
  ];

  const TableSummary = (props: {monthSummary: IMonthlyTransaction; index: number}) => {
    const {monthSummary, index} = props;
    const color = '#333';
    return (
      <AccordionSummary
        className={`w-full cursor-default ${classes.AccordionClass}`}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className="flex w-full flex-row items-center">
          <span className="flex w-[15%] items-center">
            <div
              className="caretUp rounded-t-default flex h-12 w-9 items-center justify-center"
              data-autoid={`btnTransactions${monthSummary?.mth}${monthSummary?.yr}`}
            >
              <div
                className="border-1-red flex h-5 w-5 items-center justify-center rounded-sm bg-white"
                style={{border: '1px solid #1c78ad', padding: '4px', borderRadius: '3px'}}
              >
                <CaretUp className={`cursor-pointer`} width="16.667px" height="16.667px" />
              </div>
            </div>
            <div className={`px-2.5 font-poppins text-base font-semibold text-${color}`}>
              {getMonthName(monthSummary?.mth)}
              {index === 0 ? '(current)' : null}
            </div>
          </span>
          <span
            className={`flex px-2.5 font-poppins text-base font-semibold text-${color} w-[85%] justify-center`}
            data-autoid={`lblMonthlySummary${monthSummary?.mth}${monthSummary?.yr}`}
          >
            {`Opening Balance: ${PriceFormat(monthSummary?.ledgerOpeningBalance)} + charges: ${PriceFormat(
              monthSummary?.drawdowns,
            )} - Repayments: ${PriceFormat(monthSummary?.repayments)} = ${
              index > 0 ? 'closing' : 'current'
            } Balance: ${PriceFormat(monthSummary?.ledgerBalance)}`}
          </span>
        </div>
      </AccordionSummary>
    );
  };
  return (
    <>
      <ARDialogTemplate
        dialogProps={{size: 'sm', open: showPaymentOptionDialog}}
        isFullScreen
        header={
          <p className=" font-poppins text-xl font-light text-[#333333]">
            Set your {facilitytansaction?.marketplaceName} payment option
          </p>
        }
        body={
          <div className="flex max-h-[450px] w-[638px] justify-center overflow-y-auto pb-4 pt-8">
            <div className="flex h-[inherit] w-[360px] flex-col items-center ">
              <div className="flex flex-row">
                <p className="w-[332px] text-center font-poppins text-xs font-normal text-black-800 ">
                  Please select the payment option you would like to set as your default that will automatically be
                  charged when Spenda Finance repayments become due.
                </p>
                <span className="relative left-10 top-4">
                  <WidgetTooltip
                    title={
                      'To add a new payment option or to edit an existing payment option please navigate to the payment option tab. '
                    }
                    placement="top"
                    arrow
                  >
                    <div>
                      <InfoRound
                        style={{
                          width: '22px',
                          height: '22px',
                          color: '#1C78AD',
                        }}
                      />
                    </div>
                  </WidgetTooltip>
                </span>
              </div>
              <div className="-ml-10 min-h-[100px] w-[287px] pt-6">
                {isLoading ? (
                  <LoadingIndicator isLoading={isLoading} size="sm" color="hsl(var(--dashbg))" />
                ) : (
                  paymentMethods?.map(pm => (
                    <div className="my-2" key={pm.PaymentAccountGUID}>
                      <PaymentMethodSlide
                        pm={pm}
                        isSelected={pm.PaymentAccountGUID === defaultRepaymentMethod?.[0]?.paymentAccountGUID}
                        onChangePaymentMethod={() => handleSetFacilityPaymentAccount(pm)}
                        showFriendlyName={false}
                      />
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        }
        footer={
          <div className="flex w-full flex-row items-center justify-between">
            <SecondaryButton
              data-autoid="btnClearAdvanceFilter"
              label="Cancel"
              onClick={() => {
                setShowPaymentOptionDialog(false);
              }}
            />
            <PrimaryButton
              data-autoid="btnDoneAdvanceFilter"
              label="Save"
              onClick={onSavePaymentAccount}
              isSubmitting={isFacilityDataLoading}
            />
          </div>
        }
      />
      <div className={`mb-5 flex flex-col rounded bg-white p-2.5`} style={{boxShadow: '0px 0px 6px 0px #D3E5EF'}}>
        <p className="text-xxl mb-[14px] mt-2.5 font-poppins font-light text-spenda-primarytext">Marketplace Details</p>
        {isFacilityDataLoading ? (
          <LoadingIndicator isLoading={isFacilityDataLoading} size="md" color="hsl(var(--primary))" />
        ) : (
          <div>
            <table className="w-full" data-autoid="tblMarketplaces">
              <thead>
                <tr className="!h-10 bg-[#F1F1F1]">
                  <th
                    align="left"
                    className="w-[30%] min-w-[140px] px-2.5 font-poppins text-base font-semibold text-[#999999]"
                  >
                    Marketplace
                  </th>
                  <th
                    align="left"
                    className="w-[20%] min-w-[141px] px-2.5 font-poppins text-base font-semibold text-[#999999]"
                  >
                    <ARTooltip
                      arrow
                      placement="top"
                      title={
                        <p className="text-center font-poppins text-[10px] font-medium text-spenda-primarytext">
                          This will be automatically charged on repayment date
                        </p>
                      }
                    >
                      <div className="cursor-pointer">Default repayment account</div>
                    </ARTooltip>
                  </th>
                  <th
                    align="right"
                    className="w-[15%] min-w-[104px] px-2.5 font-poppins text-base font-semibold text-[#999999]"
                  >
                    Credit limit
                  </th>
                  <th
                    align="left"
                    className="w-[14%] min-w-[124px] px-2.5 font-poppins text-base font-semibold text-[#999999]"
                  >
                    Terms
                  </th>
                  <th
                    align="center"
                    className="w-[16%] min-w-[100px] px-2.5 font-poppins text-base  font-semibold text-[#999999]"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr data-autoid={`rowMarketplace${facilitytansaction?.facilityID}`}>
                  <td
                    align="left"
                    className="p-2.5 font-poppins text-base font-medium text-spenda-primarytext"
                    data-autoid="lblMarketplaceName"
                  >
                    {facilitytansaction?.marketplaceName}
                  </td>
                  <td
                    align="left"
                    className={`p-2.5 font-poppins text-base font-medium underline ${
                      loan2_184196 && userRole === UserRoleName.Primary ? 'cursor-pointer' : ''
                    } ${facilitytansaction?.defaultPaymentMethod ? 'text-primary' : ' text-spenda-warning'}`}
                    data-autoid="lblDefaultPaymentMethod"
                    onClick={() => {
                      if (loan2_184196 && userRole === UserRoleName.Primary) handlePaymentOptionsDialog();
                    }}
                  >
                    {facilitytansaction?.defaultPaymentMethod ?? 'Set up default'}
                  </td>
                  <td
                    align="right"
                    className="bg-[#FAFAFA]  p-2.5 font-poppins text-base font-medium text-spenda-primarytext"
                    data-autoid="lblCreditLimit"
                  >
                    {PriceFormat(facilitytansaction?.creditLimit)}
                  </td>
                  <td
                    align="left"
                    className="p-2.5 font-poppins text-base font-medium text-spenda-primarytext"
                    data-autoid="lblDrawdownTerm"
                  >
                    {facilitytansaction?.drawdownTerm}
                  </td>
                  <td
                    align="center"
                    className="bg-[#FAFAFA] px-[45px] py-2.5 font-poppins text-base font-normal text-spenda-primarytext"
                    data-autoid="lblStatus"
                  >
                    {handleGetbadge('active')}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div
        className={`h-ful mb-5 flex flex-col rounded bg-white p-2.5`}
        style={{boxShadow: '0px 0px 6px 0px #D3E5EF', height: '100%'}}
      >
        <p className="text-xxl mb-[14px] mt-2.5 font-poppins font-light text-spenda-primarytext">Transactions</p>
        <div className="h-ful mb-20 overflow-hidden overflow-y-scroll pb-20" style={{height: '100%'}}>
          {facilitytansaction?.monthlyTransactions?.map((monthdata, index) => (
            <React.Fragment key={index}>
              <SExpandableTable<ITransaction>
                data={monthdata?.transactions}
                headCells={drawDownTermsHead}
                disabled={monthdata.transactions.length < 1}
                tableHeadDataAutoId={`tblTransactions${monthdata.mth}${monthdata.yr}`}
                rowDataAutoId="rowTransactions"
              >
                <TableSummary monthSummary={monthdata} index={index} />
              </SExpandableTable>
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};
