import React, {useEffect, useState} from 'react';
import LoadingIndicator from '../../../components/ui/LoadingIndicator';
import {Layout} from '../../../components/layout/Layout';
import ServiceHistoryHeader from './ServiceHistoryHeader';
import ServiceHistoryList from './ServiceHistoryList';
import {useLoadCustomersList} from '../../../services/useLoadCustomerList';

export const ServiceHistory = () => {
  const [searchString, setSearchString] = useState<string>();
  const [columnOptions, setColumnOptions] = useState<string[]>([
    'Customer name',
    'Company name',
    'Service address',
    'Email address',
    'Phone number',
    'Open job count',
    'Last service date',
  ]);

  const {
    totalCount,
    items: customerList,
    loading: isLoading,
    currentPage,
    pageSize,
    searchFilter,
    setSearchFilter,
  } = useLoadCustomersList();

  const handlePageChange = (newPage: number) => {
    setSearchFilter({
      ...searchFilter,
      StartRow: (newPage - 1) * pageSize + 1,
    });
  };

  const handleRowsPerPageChange = (rows: number) => {
    setSearchFilter({
      ...searchFilter,
      MaxResults: rows,
      StartRow: 1,
    });
  };

  useEffect(() => {
    if (typeof searchString != 'undefined') {
      setSearchFilter({
        ...searchFilter,
        SearchString: searchString,
      });
    }
  }, [setSearchString, searchString]);

  const _serviceHistory = (
    <div className="flex h-[calc(100vh-120px)] flex-col items-start justify-between rounded bg-white p-2">
      <ServiceHistoryHeader
        columnOptions={columnOptions}
        setColumnOptions={setColumnOptions}
        setSearchString={setSearchString}
      />
      <ServiceHistoryList
        columnOptions={columnOptions}
        customers={customerList}
        totalCount={totalCount}
        currentPage={currentPage}
        pageSize={pageSize}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
    </div>
  );

  return (
    <div className={`relative h-full overflow-hidden bg-[rgba(211,229,239,0.5)]`}>
      <LoadingIndicator isLoading={isLoading} size="md" color="hsl(var(--primary))" />
      <Layout leftPanel={_serviceHistory} splitWidthType={4} />
    </div>
  );
};
