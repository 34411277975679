import React, {PropsWithChildren} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import AppContextProvider from './context/app/AppContextProvider';
import {BrowserRouter} from 'react-router-dom';
import {Auth0Provider, Auth0ProviderOptions} from '@auth0/auth0-react';
import environment from './utils/environment';
import {useAuth0Callback} from './hooks/useAuth0Callback';
import {NotificationProvider} from './context/NotificationContext';
import {IntegrationProvider} from './context/IntegrationContext';
import {ARContextProvider} from './context/ar-context/ARContextProvider';
import {APContextProvider} from './context/ap-context/APContextProvider';
import {ThemeContextProvider} from './context/theme-context/ThemeContextProvider';
import ZohoContextProvider from './context/zoho-context/ZohoContextProvider';
import {InventoryContextProvider} from './context/inventory-context/InventoryContextProvider';

const RootApp = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <NotificationProvider>
          <AppContextProvider>
            <ThemeContextProvider>
              <IntegrationProvider>
                <ARContextProvider>
                  <APContextProvider>
                    <InventoryContextProvider>
                      <ZohoContextProvider>
                        <App />
                      </ZohoContextProvider>
                    </InventoryContextProvider>
                  </APContextProvider>
                </ARContextProvider>
              </IntegrationProvider>
            </ThemeContextProvider>
          </AppContextProvider>
        </NotificationProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

const AuthProvider = (props: PropsWithChildren<{}>) => {
  const {onRedirectCallback, OAuthCallbackWhitelist} = useAuth0Callback();

  const sessionStorageCache = {
    get: function (key: string) {
      return JSON.parse(sessionStorage.getItem(key) || '');
    },

    set: function (key: string, value: any) {
      sessionStorage.setItem(key, JSON.stringify(value));
    },

    remove: function (key: string) {
      sessionStorage.removeItem(key);
    },

    allKeys: function () {
      return Object.keys(sessionStorage);
    },
  };

  const providerConfig: Auth0ProviderOptions = {
    domain: environment.AUTH_DOMAIN || '',
    clientId: environment.AUTH_CLIENT_ID || '',
    redirectUri: window.location.origin,
    audience: environment.AUTH_AUDIENCE || '',
    scope: 'openid profile offline_access',
    cache: sessionStorageCache,
    useRefreshTokens: true,
    onRedirectCallback,
    skipRedirectCallback: OAuthCallbackWhitelist.indexOf(window.location.pathname) >= 0,
  };

  if (environment.AUTH_CONNECTION) {
    providerConfig.connection = environment.AUTH_CONNECTION;
  }

  return <Auth0Provider {...providerConfig}>{props.children}</Auth0Provider>;
};

const rootElement = document.getElementById('root');
rootElement?.classList?.add('flex', 'flex-col');
const root = ReactDOM.createRoot(rootElement as HTMLElement);

root.render(<RootApp />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
