import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Card, CardBody, CardHeader, Chip, IconButton, Typography} from 'spenda-ui-react';
import {camelCase, upperFirst} from 'lodash';
import moment from 'moment';
import clsx from 'clsx';

import {getQuoteStatus} from '../../screens/quote-management/QuotesList';
import {AUTH_SELLING_QUOTES_LIST} from '../../routes/QuoteManagementRoutes';
import {CollapseUpIcon, EditBlackIcon, EditPrimaryIcon, SwapIcon} from '../../assets/svg';
import backButton from '../../assets/png/back-button.png';
import {getLocation} from '../../utils/customerAddressHelper';
import {IAddress} from '../../model/address/Address';
import {ICustomer} from '../../model/customer/Customer';
import {IQuotes} from '../../model/quotes/quotes';
import {DepositAmountType, QuoteStatus} from '../../model/constants/Constants';
import {CustomerDialog} from '../dialog/CustomerDialog';
import {SelectQuoteCustomerDialog} from '../dialog/SelectQuoteCustomerDialog';
import {QuoteDetailsDialog} from '../dialog';
import {useQuoteContext} from '../../context/quote-context/QuoteContext';
import useQuotesAPI from '../../services/useQuotesAPI';
import Skeleton from '../Skeleton';

export interface ICardContent {
  contentValue: string | number | undefined;
  contentHeading: string;
  contentIcon?: boolean;
  containerClassName?: string;
  headingClassName?: string;
  contentClassName?: string;
}

interface IQuoteDetailsCardV2 {
  isQuoteDetailV2?: boolean;
  selectedQuoteDetails?: IQuotes;
  isShowActionJobs?: boolean;
}

const QuoteDetailsCardV2 = (props: IQuoteDetailsCardV2) => {
  const {isQuoteDetailV2, selectedQuoteDetails, isShowActionJobs} = props;
  const {
    quotePackage,
    customerDetails: customerInfo,
    refreshCustomer,
    setCustomerDetails,
    quotePackageDetails,
    setQuotePackageDetails,
    setQuotePackage,
    isLoading,
    isCollapseRightPanal,
    setIsCollapseRightPanal,
    showCustomerDetails,
    setShowCustomerDetails,
  } = useQuoteContext();

  const history = useHistory();
  const {updateQuotePackage} = useQuotesAPI();
  const {packageId: quotePackageID} = useParams<{packageId: string}>();

  const [shippingAddress, setShippingAddress] = useState<IAddress>();
  const [billingAddress, setBillingAddress] = useState<IAddress>();
  const [editCustomerDialog, setEditCustomerDialog] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer>(customerInfo!);
  const [showSelectCustomerDialog, setShowSelectCustomerDialog] = useState<boolean>(false);
  const [showQuoteDetailsDialog, setShowQuoteDetailsDialog] = useState<boolean>(false);

  const handleContinueClick = async (
    expiryDate: string,
    assignedToUserID: number,
    days: number,
    depositAmountType: DepositAmountType,
    depositAmount: number,
  ) => {
    setQuotePackageDetails &&
      setQuotePackageDetails({
        validFor: expiryDate,
        assignQuoteToUserID: assignedToUserID,
        expireInDays: days,
        depositAmountType: depositAmountType,
        depositAmount: depositAmount,
      });
    const packageID = parseInt(quotePackageID);
    const updatedPackage = await updateQuotePackage({
      expiryDate: days ? undefined : expiryDate,
      expiresInDays: expiryDate ? undefined : days,
      assignedToUserID,
      quotePackageID: packageID,
      customerID: selectedCustomer?.ID!,
      depositAmountType: depositAmountType,
      depositAmount: depositAmount,
    });
    setCustomerDetails(selectedCustomer);
    setQuotePackage && setQuotePackage(updatedPackage);
    setShowQuoteDetailsDialog(false);
  };

  const CardContent = useCallback((props: ICardContent) => {
    const {contentHeading, contentValue, contentIcon, headingClassName, contentClassName, containerClassName} = props;

    return (
      <div className={`flex flex-row`}>
        <div className={`${containerClassName} ${contentIcon ? 'items-center' : null} relative flex gap-x-4 gap-y-1`}>
          <Typography className={`${headingClassName} min-w-[65px] text-sm text-black-800`}>
            {contentHeading} :
          </Typography>
          <Typography
            title={`${contentValue}`}
            data-autoid={`txt${upperFirst(camelCase(contentHeading))}`}
            className={`${contentClassName} flex w-[190px] flex-wrap text-sm capitalize text-black-300`}
          >
            {contentValue ? contentValue : '-'}
            {!isQuoteDetailV2 && contentIcon && (
              <EditBlackIcon
                className="ml-3 cursor-pointer"
                onClick={() => setEditCustomerDialog(true)}
                data-autoid="lnkEditCustomer"
              />
            )}
          </Typography>
        </div>
      </div>
    );
  }, []);

  useEffect(() => {
    async function getAddresses() {
      if (customerInfo?.Locations) {
        const {ShippingAddress, BillingAddress} = await getLocation(customerInfo?.Locations || []);
        setShippingAddress(ShippingAddress);
        setBillingAddress(BillingAddress);
      }
    }
    customerInfo?.Locations?.length && getAddresses();
  }, [customerInfo]);

  const chipPrimaryValue = (
    <Typography
      className={`flex items-center justify-center gap-1.5 capitalize ${isQuoteDetailV2 ? 'text-base' : 'text-xs'} font-medium`}
      data-autoId={`txtCustomerName`}
    >
      Customer name - {customerInfo?.PrimaryContactFullName}
      {!isQuoteDetailV2 && (
        <EditPrimaryIcon
          className="cursor-pointer"
          onClick={() => setEditCustomerDialog(true)}
          data-autoid="lnkEditCustomer"
        />
      )}
    </Typography>
  );

  const getQuoteStatusLabel = useCallback(
    (status: QuoteStatus) => {
      if (!isShowActionJobs && selectedQuoteDetails?.status !== QuoteStatus.Rejected && quotePackage?.isSent)
        return QuoteStatus.ServiceInitiated;
      if (
        selectedQuoteDetails?.status === QuoteStatus.Approved &&
        (selectedQuoteDetails?.balance == 0 ||
          (selectedQuoteDetails?.totalInc || 0) - (selectedQuoteDetails.balance || 0) > 0)
      )
        return QuoteStatus.DepositReceived;
      if (selectedQuoteDetails?.status === QuoteStatus.Draft && quotePackage?.isSent) return QuoteStatus.Sent;
      return status;
    },
    [selectedQuoteDetails, quotePackage, isShowActionJobs],
  );

  return (
    <div className="mb-2.5 w-full">
      <Card
        className={clsx(
          'flex-row gap-4 rounded-[10px] border border-[#F1F1F1] bg-[#FAFAFA] p-1 pr-2.5 shadow-[0_0_6px_0_#D3E5EF]',
          {'min-h-[160px]': showCustomerDetails},
          {'gap-0 bg-white': isQuoteDetailV2},
        )}
      >
        <CardHeader
          className={`m-0 flex cursor-pointer flex-col items-center gap-y-2 rounded-none !rounded-l-[10px]  ${isQuoteDetailV2 ? 'min-w-[62px] bg-white pt-2' : 'min-w-[72px] justify-center bg-primary/5'}`}
          onClick={() => setShowSelectCustomerDialog(true)}
        >
          {isQuoteDetailV2 ? (
            <div className="absolute left-2.5 flex flex-row items-center">
              <span
                className="cursor-pointer"
                data-autoid="btnBack"
                onClick={() => {
                  history.push(AUTH_SELLING_QUOTES_LIST);
                }}
              >
                <img
                  src={backButton}
                  alt="Back Button"
                  style={{width: '40px', height: '40px', margin: '0px 10px 0px 0px'}}
                  data-autoid="imgBack"
                />
              </span>
            </div>
          ) : (
            <>
              <SwapIcon />
              {showCustomerDetails && <Typography className="font-semibold text-primary">SWAP</Typography>}
            </>
          )}
        </CardHeader>
        <CardBody className={`${showCustomerDetails ? 'pb-2' : 'pb-1.5'} w-full px-0 pt-1.5`}>
          <div className={`flex items-center justify-between`}>
            <div className={`flex items-center justify-center gap-2.5`}>
              <Typography
                variant="h2"
                className="mb leading-6 text-black-800"
                data-autoid={`txtQuotePackage-${quotePackage?.refNumber}`}
              >
                Quote Package : <span className="text-black-300">{quotePackage?.refNumber}</span>
              </Typography>
              {isQuoteDetailV2 && (
                <div className="flex flex-row items-center justify-between gap-2.5">
                  {!showCustomerDetails && <Chip value={chipPrimaryValue} color="primary" className="p-1.5" />}
                  {selectedQuoteDetails?.status === QuoteStatus.Draft && (
                    <div>
                      <div className="flex justify-center rounded-md bg-warning/10 p-1.5">
                        <Typography className="text-base text-warning" data-autoid={'txtExpiryDate'}>
                          Expiry Date: {moment(quotePackage?.expiryDate).format('DD MMMM')}
                        </Typography>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="flex items-center justify-end gap-2.5">
              {!isQuoteDetailV2 && !showCustomerDetails && (
                <div className="flex gap-2.5">
                  <Chip value={chipPrimaryValue} color="primary" className="px-1.5 py-1" />
                  <Chip
                    value={`Ref no: ${customerInfo?.RefNumber}`}
                    color="gray"
                    className="px-1.5 py-1 text-black-300"
                  />
                </div>
              )}

              <div>
                {isLoading ? (
                  <Skeleton height={'24px'} className="w-[95px]" />
                ) : quotePackage?.isSent ? (
                  getQuoteStatus(getQuoteStatusLabel(selectedQuoteDetails?.status!))
                ) : null}
              </div>
              <IconButton
                variant="outlined"
                onClick={() => {
                  setShowCustomerDetails(!showCustomerDetails);
                }}
                data-autoid={`btnShowHideCustomer`}
              >
                <CollapseUpIcon
                  data-autoid={`imgCollapse`}
                  className={`${!showCustomerDetails ? 'rotate-180' : 'rotate-0'}`}
                />
              </IconButton>
              <IconButton
                variant="outlined"
                onClick={() => {
                  setIsCollapseRightPanal(!isCollapseRightPanal);
                }}
                data-autoid={`btnShowHideCustomer`}
              >
                <CollapseUpIcon
                  data-autoid={`imgCollapse`}
                  className={`${isCollapseRightPanal ? '-rotate-90' : 'rotate-90'}`}
                />
              </IconButton>
            </div>
          </div>
          {showCustomerDetails && (
            <div className="mt-2.5 flex w-full border-t border-[#ECECEC] pt-2.5 ">
              <div className={`flex basis-1/3 flex-col gap-y-1.5`}>
                <CardContent
                  contentHeading={'Customer'}
                  contentValue={customerInfo?.PrimaryContactFullName}
                  containerClassName="w-full"
                  contentClassName="flex justify-start items-center !w-[calc(100%-85px)]"
                  contentIcon
                />
                <CardContent
                  contentHeading={'Email'}
                  contentValue={customerInfo?.PrimaryContactEmailAddress}
                  contentClassName="truncate"
                />
              </div>
              <div className={`flex basis-1/5 flex-col gap-y-1.5`}>
                <CardContent
                  contentHeading={'Ref no'}
                  contentValue={customerInfo?.RefNumber}
                  headingClassName="!min-w-[45px]"
                />
                <CardContent
                  contentHeading={'Phone'}
                  contentValue={customerInfo?.PrimaryContactPhone}
                  headingClassName="!min-w-[45px]"
                />
              </div>
              <div className={`flex basis-1/4 gap-y-1.5`}>
                <CardContent
                  contentHeading={'Billing Address'}
                  contentValue={billingAddress?.FullAddress}
                  containerClassName="flex-col"
                />
              </div>
              <div className={`flex basis-1/4 gap-y-1.5`}>
                <CardContent
                  contentHeading={'Shipping Address'}
                  contentValue={shippingAddress?.FullAddress}
                  containerClassName="flex-col"
                />
              </div>
            </div>
          )}
        </CardBody>
      </Card>
      {editCustomerDialog && (
        <CustomerDialog
          customerId={customerInfo?.ID}
          open={editCustomerDialog}
          onClose={() => {
            setEditCustomerDialog(false);
          }}
          setOpenAccountCustomer={() => {}}
          setCustomer={() => {}}
          isQuoteCustomer
          onCustomerUpdate={() => {
            setEditCustomerDialog(false);
            refreshCustomer?.();
          }}
        />
      )}
      {showSelectCustomerDialog && (
        <SelectQuoteCustomerDialog
          open={showSelectCustomerDialog}
          handleCancel={() => setShowSelectCustomerDialog(false)}
          handleConfirm={() => {
            setShowSelectCustomerDialog(false);
            setShowQuoteDetailsDialog(true);
          }}
          setSelectedQuoteCustomer={setSelectedCustomer}
          selectedQuoteCustomer={selectedCustomer!}
        />
      )}
      {showQuoteDetailsDialog && (
        <QuoteDetailsDialog
          open={showQuoteDetailsDialog}
          handleGoBack={() => setShowQuoteDetailsDialog(false)}
          handleContinueClick={handleContinueClick}
          quotePackageDetails={quotePackageDetails || {validFor: '', assignQuoteToUserID: 0}}
          isEdit={true}
        />
      )}
    </div>
  );
};

export default QuoteDetailsCardV2;
