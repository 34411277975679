import React, {useEffect, useMemo, useState} from 'react';
import {Option, Select, SelectProps} from 'spenda-ui-react';
import {IAccounts} from '../../../model/accounts/Accounts';
import useAccountAPI from '../../../services/useAccountAPI';

interface ISelectCOADropdownProps {
  value?: string;
  onChange: (value: string) => void;
  refetchcount?: number;
  isUseCodeFieldAsValue?: boolean;
  hasOptions?: boolean;
  dropdownValues?: IAccounts[];
  type?: string;
  defaultPlaceholder?: string;
  prefillValue?: string;
}

export const SelectCOADropdown = (
  props: ISelectCOADropdownProps & Omit<SelectProps, 'value' | 'ref' | 'children' | 'onChange'>,
) => {
  const {
    value,
    isUseCodeFieldAsValue,
    refetchcount,
    defaultPlaceholder,
    type,
    hasOptions,
    dropdownValues,
    prefillValue,
  } = props;

  // State
  const [clearingAccounts, setClearingAccounts] = useState<IAccounts[]>([]);

  // APIs
  const {getCreditlineAccounts} = useAccountAPI();

  useEffect(() => {
    if (!hasOptions) {
      getChartAccounts();
    }
  }, []);

  useEffect(() => {
    if (refetchcount && !hasOptions) {
      getChartAccounts();
    }
  }, [refetchcount, hasOptions]);

  useEffect(() => {
    if (clearingAccounts?.length && prefillValue && !value) {
      const isDefaultValueAvl = clearingAccounts.find(item => item.Code === prefillValue);
      if (isDefaultValueAvl) {
        props.onChange(prefillValue);
      }
    }
  }, [clearingAccounts, prefillValue, value]);

  const getChartAccounts = () => {
    try {
      const query = {Types: type ? type : 'Current_Liability'};
      getCreditlineAccounts(query).then((data: IAccounts[]) => {
        setClearingAccounts(data);
      });
    } catch {}
  };

  const dropdownOptions = hasOptions ? dropdownValues : clearingAccounts;

  const options = useMemo(
    () =>
      dropdownOptions?.map(({Name, ID, Code}) => ({
        optionName: `${Code} - ${Name}`,
        value: isUseCodeFieldAsValue ? `${Code}` : ID.toString(),
      })),
    [dropdownOptions],
  );

  return (
    <Select
      containerProps={{className: 'relative !overflow-visible'}}
      menuProps={{className: '!max-h-[200px]'}}
      selected={() => options?.find(c => c?.value === value)?.optionName || defaultPlaceholder}
      {...props}
    >
      {!options?.length ? (
        <Option disabled key={'noResults'}>
          No results
        </Option>
      ) : (
        options?.map(({optionName, value}) => (
          <Option key={value} value={value}>
            {optionName}
          </Option>
        ))
      )}
    </Select>
  );
};
