import React, {useCallback, useState} from 'react';
import {useFormikContext} from 'formik';
import {Typography} from 'spenda-ui-react';
import {debounce} from 'lodash';

import QuoteSmartSearch from './QuoteSmartSearch';
import {useInventoryAPI} from '../../services/useInventoryAPI';
import {IPagedActionResults} from '../../model/ActionResults';
import {Inventory} from '../../model/inventory/Inventory';
import {IQuoteLines, IQuotes} from '../../model/quotes/quotes';
import {DISCOUNT_MODE} from '../../model/constants/Constants';
import {IServiceJob, IServiceJobLines} from '../../model/service-management/serviceJob';
import GreenCheckIcon from '../../assets/svg/accounts-payable/GreenCheckIcon';
import {AddMissingProductInfo} from '../dialog';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {AlertDialog} from '../dialog/AlertDialogSlideV2';

interface QuoteInventoryAutocompleteProps<T> {
  by: 'product' | 'code';
  index: number;
  alreadyFilled: number[];
  calculateLineTotal: (
    Lines: Partial<IQuoteLines | IServiceJobLines>[],
    _values: T,
    shipping?: number | string,
    rowIndex?: number,
    discount?: string,
    discountMode?: DISCOUNT_MODE,
    isRemoveLine?: boolean,
    isUpdatingMargin?: boolean,
    isUpdatingQuantity?: boolean,
  ) => void;
  disabled?: boolean;
}

const QuoteInventoryAutocomplete = <T extends IQuotes | IServiceJob>(
  props: QuoteInventoryAutocompleteProps<T>,
): JSX.Element => {
  const {QuoteV289367} = useFeatureFlags().tenantOwned();
  const [results, setResults] = useState<Partial<Inventory>[]>();
  const [showAddMissingProductInfo, setShowAddMissingProductInfo] = useState({
    show: false,
    inventory: {} as Inventory,
  });
  const [showQuantityUpdateDialog, setShowQuantityUpdateDialog] = useState({
    show: false,
    inventory: {} as Inventory,
  });
  const [isSelectVariantDialog, setIsSelectVariantDialog] = useState<boolean>(false);
  const {listInventory, listInventoryByCode, isLoading} = useInventoryAPI();

  const {values, setFieldValue} = useFormikContext<T>();

  const debouncedSave = useCallback(
    debounce((newValue: string) => {
      getInventoryList(newValue);
    }, 750),
    [],
  );

  const updateLineQuantity = async (item: Inventory) => {
    let rowIndex;
    const _lines = values.lines.map((_line, _index) => {
      if (_line.inventoryID === item.ID) {
        rowIndex = _index;
        const quantity = _line.quantity + 1;
        const lineTotalEx = quantity * (Number(_line?.sellPriceEx?.toFixed(2)) || 0);
        const data = {
          ..._line,
          quantity,
          lineTotalEx: lineTotalEx,
          lineTotalExString: lineTotalEx?.toFixed(2),
        };
        if (_line.marginMode === DISCOUNT_MODE.DOLLAR) {
          return {
            ...data,
            marginString: `${(Number(_line.margin) * quantity).toFixed(2)}`,
          };
        }
        return data;
      }
      return _line;
    });
    setFieldValue('lines', _lines);
    props.calculateLineTotal(
      _lines,
      values,
      values.shipping,
      rowIndex,
      values.discountString,
      values.discountMode,
      false,
      false,
      true,
    );
    setShowQuantityUpdateDialog({show: false, inventory: {}});
  };

  const saveLineInventory = async (selected: Partial<Inventory> | null, _selection: any) => {
    if (Array.isArray(values.lines)) {
      const _lines = values.lines.map((_line, _index) => {
        if (_index === props.index) {
          const data = {
            ..._line,
            inventoryID: selected?.ID,
            code: selected?.InventoryCode,
            shortDescription: selected?.ShortDescription,
            quantity: 1,
            uoM: selected?.UoM,
            description: selected?.Description,
            sellPriceEx: +selected?.StandardSellPriceEx?.toFixed(2)!,
            lineTotalEx: +selected?.StandardSellPriceEx?.toFixed(2)!,
            lineTotalExString: selected?.StandardSellPriceEx?.toFixed(2),
            costPriceEx: +selected?.CostPriceEx?.toFixed(2)!,
          };

          if (QuoteV289367) {
            return {
              ...data,
              margin: Number(selected?.StandardSellPriceEx) - Number(selected?.CostPriceEx),
              marginString: `${(Number(selected?.StandardSellPriceEx) - Number(selected?.CostPriceEx)).toFixed(2)}`,
              marginMode: DISCOUNT_MODE.DOLLAR,
            };
          } else {
            return data;
          }
        }
        return _line;
      });
      setFieldValue('lines', _lines);
      props.calculateLineTotal(
        _lines,
        values,
        values.shipping,
        props.index,
        values.discountString,
        values.discountMode,
      );
    }
  };

  const handleChange: ChangeEventHandler = e => {
    if (!e.target.value.trim()) return setResults([]);
    if (props.by === 'product') setFieldValue(`lines[${props.index}].product`, e.target.value);
    else setFieldValue(`lines[${props.index}].code`, e.target.value);
    if (e.target.value.length < 3) {
      return;
    }
    debouncedSave(e.target.value);
  };

  const getInventoryList = (query?: string) => {
    if (props.by === 'product') {
      listInventory({
        MaxResults: 100,
        ViewMode: 'Sales',
        IsSystem: false,
        ...(query ? {Search: query} : {}),
      }).then((res: IPagedActionResults<Inventory[]>) => {
        setResults(res?.Value ?? []);
      });
    } else {
      listInventoryByCode({
        MaxResults: 100,
        StartRow: 1,
        IsSystem: false,
        IsShowVariantMaster: true,
        ...(query ? {SearchString: query} : {}),
      }).then((res: IPagedActionResults<Inventory[]>) => {
        setResults(res?.Value ?? []);
      });
    }
  };

  const handleSuggestionSelected = (item: Partial<Inventory>) => {
    if (!item) return true;
    if (
      QuoteV289367 &&
      item.ClassPostingBehaviour !== 'Generic' &&
      (!item?.InventoryClassID || !item?.RevenueAccount || !item?.ExpenseAccount)
    ) {
      setShowAddMissingProductInfo({
        show: true,
        inventory: item,
      });
      return true;
    }

    setResults([item]);
    const selectedSuggestion = {
      suggestion: item,
      suggestionValue: `${item.InventoryCode} ${item.ShortDescription}`,
      suggestionIndex: props.index,
      sectionIndex: 0,
    };
    if (props.alreadyFilled && props.alreadyFilled.length) {
      if (!props.alreadyFilled.some((_m: number) => _m === item.ID)) {
        saveLineInventory(item, selectedSuggestion);
      } else {
        QuoteV289367 &&
          setShowQuantityUpdateDialog({
            show: true,
            inventory: item,
          });
        return false;
      }
    } else {
      saveLineInventory(item, selectedSuggestion);
    }
    return true;
  };

  const resultShow = (item: Partial<Inventory>) => {
    const isSelected = props.alreadyFilled && props.alreadyFilled.some((_m: number) => _m === item.ID);
    const isMissingInfo =
      QuoteV289367 &&
      item.ClassPostingBehaviour !== 'Generic' &&
      (!item?.InventoryClassID || !item?.RevenueAccount || !item?.ExpenseAccount);
    return (
      <Typography variant="xsmall" data-autoid={`ddlItem-${item.InventoryCode}`}>
        <span className="flex flex-row items-center justify-between">
          <span>
            <b>{item?.InventoryCode}</b> {item?.ShortDescription}
          </span>
          {item?.IsVariantMaster && QuoteV289367 && (
            <span className="ml-auto cursor-pointer text-spenda-primarytext">
              <b>master</b>
            </span>
          )}
          {isMissingInfo && <span className="ml-1 px-2.5 text-[18px] font-bold text-error">!</span>}
          {isSelected && <GreenCheckIcon className="w-min-[18px] ml-1" width="18px" height={'18px'} />}
        </span>
      </Typography>
    );
  };

  const handleOnClose = (actionType: string) => {
    if (actionType === 'Save') {
      const selectedSuggestion = {
        suggestion: showAddMissingProductInfo.inventory,
        suggestionValue: `${showAddMissingProductInfo.inventory.InventoryCode} ${showAddMissingProductInfo.inventory.ShortDescription}`,
        suggestionIndex: props.index,
        sectionIndex: 0,
      };
      if (showAddMissingProductInfo.inventory.IsVariantMaster) {
        setIsSelectVariantDialog(true);
      } else {
        saveLineInventory(showAddMissingProductInfo.inventory, selectedSuggestion);
      }
    }
    setShowAddMissingProductInfo({show: false, inventory: {} as Inventory});
  };

  return (
    <>
      <QuoteSmartSearch
        key={props.by + props.index}
        results={results}
        placeholder={props.by === 'product' ? 'Search by name or code' : 'Search by code'}
        value={props.by === 'product' ? values?.lines[props.index]?.shortDescription : values?.lines[props.index]?.code}
        renderItem={resultShow}
        byCode={props.by === 'code'}
        onChange={handleChange}
        isLoading={isLoading}
        onSelect={handleSuggestionSelected}
        getInventoryList={getInventoryList}
        index={props.index}
        disabled={props.disabled}
        calculateLineTotal={props.calculateLineTotal}
        setShowAddMissingProductInfo={setShowAddMissingProductInfo}
        isSelectVariantDialog={isSelectVariantDialog}
        setIsSelectVariantDialog={setIsSelectVariantDialog}
        setShowQuantityUpdateDialog={setShowQuantityUpdateDialog}
      />
      {showAddMissingProductInfo.show && (
        <AddMissingProductInfo inventory={showAddMissingProductInfo.inventory} onClose={handleOnClose} />
      )}
      {showQuantityUpdateDialog.show && (
        <AlertDialog
          id="quantityPopup"
          size="xs"
          headingTextSize="h1"
          title="Update Quantity"
          actions={[
            {
              label: 'No',
              action: () => setShowQuantityUpdateDialog({show: false, inventory: {}}),
              variant: 'outlined',
            },
            {
              label: 'Yes',
              action: () => updateLineQuantity(showQuantityUpdateDialog.inventory),
            },
          ]}
          content={`You have already added same product ${showQuantityUpdateDialog?.inventory?.ShortDescription} in the list, do you want to increase the quantity?`}
        />
      )}
    </>
  );
};
export default QuoteInventoryAutocomplete;
