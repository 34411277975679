import React, {FC, useContext, useEffect, useState} from 'react';
import {AlertDialogSlideV2} from './AlertDialogSlideV2';
import {Button, Checkbox, Typography} from 'spenda-ui-react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {AddAdditionalContactCustomerModal} from './AddAdditionalContactCustomerModal';
import QuoteContext from '../../context/quote-context/QuoteContext';
import QuoteAttachmentDocumentDialog from './QuoteAttachmentDocumentDialog';
import {useAutomatedStatementsAPI} from '../../services/useAutomatedStatementsAPI';
import RichTextEditor from '../AccountsReceivable/automated-statement/RichTextEditor';
import {IAlertContentVariable} from '../../model/statements/Statements';
import LoadingIndicator from '../ui/LoadingIndicator';
import {ILocation} from '../../model/address/Location';
import QuoteUpdateContactDialog from './QuoteUpdateContactDialog';
import useQuotesAPI from '../../services/useQuotesAPI';
import clsx from 'clsx';
import {ApplicationInfo} from '../../model/constants/ApplicationInfo';
import {convertNumberToWords} from '../../utils/formatter';
import {DatTypes} from '../../model/constants/Constants';

interface IQuoteContactCustomerModal {
  handleClose: () => void;
  handleSave: () => void;
}

export type ContactID = number | undefined;

interface ICustomerMailTemplate {
  body?: string;
  sendAsEmail?: boolean;
  sendAsTextMessage?: boolean;
  ToContactIDs?: number[];
}

const formInitialValues: ICustomerMailTemplate = {
  sendAsEmail: false,
  sendAsTextMessage: false,
  body: '',
  ToContactIDs: [],
};

const validationSchema = Yup.object({
  body: Yup.string()
    .required('Template body is required')
    .test('contains-quote-link', 'Template body must contain {Quote Link}', function (value) {
      if (value?.includes('{QuoteLink}')) {
        return true;
      }
      return this.createError({
        path: 'body',
        message: 'Template body must contain {Quote Link}.',
      });
    }),
  sendAsEmail: Yup.boolean(),
  sendAsTextMessage: Yup.boolean(),
}).test('at-least-one-checked', 'At least one checkbox must be selected', function (values) {
  const {sendAsEmail, sendAsTextMessage} = values;
  if (!sendAsEmail && !sendAsTextMessage) {
    return this.createError({
      path: 'sendAsEmail',
      message: 'At least one checkbox must be selected.',
    });
  }
  return true;
});

export const QuoteContactCustomerModal: FC<IQuoteContactCustomerModal> = props => {
  const {handleSave, handleClose} = props;

  const [AddAdditionalContactCustomer, setAddAdditionalContactCustomer] = useState<boolean>(false);
  const [showAttachedDocumentDialog, setShowAttachedDocumentDialog] = useState<boolean>(false);
  const [alertContentVariables, setAlertContentVariables] = useState<IAlertContentVariable[]>([]);
  const [updateCustomerContactInfo, setUpdateCustomerContactInfo] = useState<ILocation[]>();
  const [isShowUpdateContactDialog, setIsShowUpdateContactDialog] = useState<boolean>(false);
  // const
  const [formValue, setFormValues] = useState(formInitialValues);
  const {customerDetails, quotePackage} = useContext(QuoteContext);
  const {getEmailTemplate, isLoading: statementAPILoading} = useAutomatedStatementsAPI();
  const {sendQuotePackage, isLoading: sendQuoteLoading} = useQuotesAPI();

  let isLoading = statementAPILoading;

  useEffect(() => {
    fetchDefaultTemplate();
  }, []);

  const fetchDefaultTemplate = async () => {
    try {
      const payload = {
        websiteID: ApplicationInfo.WebsiteId,
        datTypeID: DatTypes.QuoteMailTemplate,
        isMasterTemplate: true,
      };
      const response = await getEmailTemplate(payload);

      if (response[0]) {
        setAlertContentVariables(response[0].alertContentVariables);
        const template = response[0].body;
        const primaryContactID = customerDetails?.Contacts?.find(
          contact => contact.IsPrimaryContact,
        )?.BusinessContactID;
        setFormValues({
          body: template ?? '<p> Create Mail template </p>',
          sendAsEmail: true,
          sendAsTextMessage: false,
          ToContactIDs: primaryContactID ? [primaryContactID] : [],
        });
      }
    } catch (e) {
      console.error('Error while fetching default template', e);
    }
  };

  // Drag and drop
  const handleDragStart = (event: React.DragEvent<HTMLDivElement>, tag: string) => {
    event.dataTransfer.setData('text/plain', tag);
  };

  // Formik;
  const {values, errors, touched, handleChange, setFieldValue, handleSubmit, setSubmitting, isSubmitting} = useFormik({
    initialValues: formValue,
    validationSchema: validationSchema,
    onSubmit: value => {
      onSubmit(value);
    },
    enableReinitialize: true,
  });

  const handleAddAdditionalContactCustomer = (selectedCustomerIDs: ContactID[]) => {
    setAddAdditionalContactCustomer(false);
    if (selectedCustomerIDs?.length > 0) {
      setFieldValue('ToContactIDs', selectedCustomerIDs);
    }
  };

  const sendQuotePackageForApprove = async () => {
    const payload = {
      ToContactIDs: values.ToContactIDs,
      Body: values.body,
      IsSendEmail: values.sendAsEmail,
      IsSendSMS: values.sendAsTextMessage,
    };

    const response = await sendQuotePackage(quotePackage?.quotePackageID!, payload);
    if (response) {
      Promise.resolve();
    }
  };

  const checkHaveAttachment = () => {
    return quotePackage?.quotes?.some(quote => quote.countAttachments);
  };

  const handleNext = async (selectedCustomerIDs?: ContactID[]) => {
    if (selectedCustomerIDs?.length === 0) return;
    setSubmitting(true);
    const findMissing = customerDetails?.Contacts?.filter(contact => {
      if (!contact?.BusinessContactID) return false;
      const ifInclude = selectedCustomerIDs?.includes(contact.BusinessContactID);
      if (ifInclude) {
        if (values.sendAsEmail && !contact.EmailAddress) {
          return true;
        } else if (values.sendAsTextMessage && !contact.PhoneMobile) {
          return true;
        }
        return false;
      }
    });
    if (findMissing && findMissing.length > 0) {
      setUpdateCustomerContactInfo(findMissing);
      setIsShowUpdateContactDialog(true);
      setSubmitting(false);
      return;
    } else {
      if (values.sendAsTextMessage && checkHaveAttachment()) {
        setShowAttachedDocumentDialog(true);
        setSubmitting(false);
        return;
      }
      try {
        await sendQuotePackageForApprove();
        setSubmitting(false);
        handleSave();
      } catch (e) {
        setSubmitting(false);
      }
    }
  };

  const submitViaUpdateContactDialog = async () => {
    if (values.sendAsTextMessage && checkHaveAttachment()) {
      setShowAttachedDocumentDialog(true);
      setSubmitting(false);
      return;
    } else {
      try {
        await sendQuotePackageForApprove();
        setSubmitting(false);
        handleSave();
      } catch (e) {
        setSubmitting(false);
      }
    }
  };

  const onSubmit = (values: ICustomerMailTemplate) => {
    handleNext(values.ToContactIDs);
  };

  return (
    <>
      <AlertDialogSlideV2
        data-autoid="dlgContactCustomer"
        dialogClassess="!max-w-[752px] !w-full"
        title={`Contact ${customerDetails?.Name}`}
        headingTextSize="h2"
        footer={
          <div className="flex w-full items-center justify-between rounded-[6px] bg-[#f1f1f1]">
            <div className="flex gap-3">
              <Button
                data-autoid="btnCancel"
                variant="outlined"
                className="bg-white focus:shadow-none"
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              <Button
                data-autoid="btnAddContacts"
                variant="outlined"
                className="bg-white focus:shadow-none"
                onClick={() => setAddAdditionalContactCustomer(true)}
              >
                Manage Recipients
              </Button>
            </div>
            <div>
              <Button
                data-autoid="btnSend"
                type="submit"
                disabled={isSubmitting || sendQuoteLoading}
                loading={isSubmitting || sendQuoteLoading}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Send
              </Button>
            </div>
          </div>
        }
      >
        {isLoading ? (
          <div className="h-[481px]">
            <LoadingIndicator isLoading={isLoading} size="md" color="hsl(var(--primary))" />
          </div>
        ) : (
          <div className="text-[#000]">
            <div className="mb-5 text-center">
              <Typography className="mb-1 font-medium">
                {convertNumberToWords(quotePackage?.quotes?.length ?? 0)}{' '}
                {(quotePackage?.quotes?.length ?? 0) <= 1 ? 'Quote' : 'Quotes'}
              </Typography>
              <Typography>You can send up to three quotes for the customer to compare. </Typography>
            </div>
            <div className="mb-5">
              <div className="flex items-center justify-center">
                <Typography className="font-medium">To:</Typography>
                <div className="first:before:contents-[' '] flex">
                  {values.ToContactIDs?.map((contactID, index) => {
                    const contact = customerDetails?.Contacts?.find(c => c.BusinessContactID === contactID);

                    return (
                      <Typography
                        key={index}
                        className={clsx('font-medium', {
                          "flex before:block before:content-[',']": index != 0,
                        })}
                        data-autoid={`txtContact-${contact?.BusinessContactID}`}
                      >
                        &nbsp; {contact?.FirstName} {contact?.LastName}
                      </Typography>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="mb-8">
              <div className="flex items-center justify-center gap-6 ">
                <Checkbox
                  ripple={false}
                  name="sendAsEmail"
                  className="h-4 w-4 rounded-[4px] border-primary bg-white transition-all checked:border-primary checked:bg-primary hover:before:opacity-0"
                  label="Send as an email"
                  checked={values.sendAsEmail}
                  onChange={handleChange}
                  data-autoid="chqSendEmail"
                />
                <Checkbox
                  ripple={false}
                  name="sendAsTextMessage"
                  className="h-4 w-4 rounded-[4px] border-primary bg-white transition-all checked:border-primary checked:bg-primary hover:before:opacity-0"
                  label="Send as a text message"
                  checked={values.sendAsTextMessage}
                  onChange={handleChange}
                  data-autoid="chqSendTextMessage"
                />
              </div>
              <div className="mt-2 text-center">
                {errors.sendAsEmail && touched.sendAsEmail && (
                  <Typography variant="small" className="font-medium text-error">
                    {errors.sendAsEmail}
                  </Typography>
                )}
                {errors.body && touched.body && (
                  <Typography variant="small" className="pt-1.5 font-medium text-error">
                    {errors.body}
                  </Typography>
                )}
              </div>
            </div>
            <div className="mb-6 text-center">
              <Typography>
                Feel free to utilise any of our pre-filled messages below, each can be completely customisable.
              </Typography>
            </div>
            <div className="flex gap-4">
              <div className="flex h-[236px] w-[70%] flex-col items-center justify-center">
                <RichTextEditor
                  dataAutoId="txtTemplateBody"
                  defaultValue={formValue.body}
                  handleChange={v => setFieldValue('body', v === '<p><br></p>' ? '' : v)}
                />
              </div>
              <div className="flex w-[40%] flex-col rounded-[6px] bg-[#E3EEF566] p-3">
                <p className="mb-3 font-poppins text-xs font-medium text-spenda-primarytext">Insert variables</p>
                <div className="flex w-full flex-row flex-wrap items-start justify-start gap-2">
                  {alertContentVariables.map((tag, index) => (
                    <div
                      key={index}
                      draggable
                      onDragStart={e => handleDragStart(e, `{${tag.keyName}}`)}
                      data-autoid={`txtDisplayName-${index}`}
                      className="flex cursor-pointer items-center justify-center rounded-[4px] bg-white px-2.5 py-[4px] font-poppins text-xs font-normal text-spenda-primarytext"
                    >
                      {tag.displayName}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </AlertDialogSlideV2>
      {AddAdditionalContactCustomer && (
        <AddAdditionalContactCustomerModal
          handleContinue={handleAddAdditionalContactCustomer}
          handleBack={() => {
            setAddAdditionalContactCustomer(false);
          }}
          selectedCustomer={values.ToContactIDs}
        />
      )}
      {isShowUpdateContactDialog && (
        <QuoteUpdateContactDialog
          handler={value => {
            if (value) {
              setIsShowUpdateContactDialog(false);
              submitViaUpdateContactDialog();
            } else {
              setIsShowUpdateContactDialog(false);
            }
          }}
          sendingType={values.sendAsEmail && values.sendAsTextMessage ? 'both' : values.sendAsEmail ? 'email' : 'phone'}
          contacts={updateCustomerContactInfo}
        />
      )}
      {showAttachedDocumentDialog && (
        <QuoteAttachmentDocumentDialog
          handleBack={() => setShowAttachedDocumentDialog(false)}
          handleSend={async () => {
            setShowAttachedDocumentDialog(false);
            try {
              await sendQuotePackageForApprove();
              handleSave();
            } catch (e) {
              setSubmitting(false);
            }
          }}
        />
      )}
    </>
  );
};
