import React, {useEffect, useState} from 'react';
import {Formik, FormikProps} from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import {omit} from 'lodash';

import {ManageSchedulerTime, ScheduledTaskRecurrenceType, WorkflowStatus} from '../../model/constants/Constants';
import {IScheduledTask} from '../../model/scheduled-task/ScheduledTask';
import useScheduledTaskAPI from '../../services/useScheduledTaskAPI';
import {AlertDialogSlide} from '../../components/dialog/AlertDialogSlide';
import {SModal} from '../modals/modalSpendaMeterialUI';
import {DestructiveButton, PrimaryButton, SecondaryButton} from '../buttons/DefaultButtons';
import {STextField} from '../inputs/STextField';
import LoadingIndicator from '../ui/LoadingIndicator';
import {Toast} from '../../utils/Toast';

import {Box, makeStyles, Radio, MenuItem, Select} from '@material-ui/core';
import {MuiPickersUtilsProvider, TimePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CloseIcon from '@material-ui/icons/Close';
import ManageScheduleDialog from '../AccountsReceivable/modals/ManageScheduleDialog';

export type ScheduledTaskValues = Pick<
  IScheduledTask,
  | 'ID'
  | 'IsActive'
  | 'IsEnabled'
  | 'Interval'
  | 'IntervalType'
  | 'IsRecurringSchedule'
  | 'TimeOfDay'
  | 'RecurrenceType'
  | 'CompletedDateTime_utc'
>;

interface IManageSchedulerBatchModalProps {
  handleClose: () => void;
  open: boolean;
  scope?: 'AP' | 'PSBL';
}

export const useManageSchedulerBatchModalStyles = makeStyles({
  apOnboardingModal: {
    maxWidth: '726px',
    height: '484px',
  },

  apBusinessHeader: {
    borderBottom: '1px solid #CCCCCC',
    '& h2': {
      fontFamily: 'Poppins, sans-serif !important',
      fontWeight: 300,
      fontSize: '28px',
      color: '#333333',
    },
  },
  boxHeight: {
    '& p': {
      color: '#333',
    },
    '& .MuiRadio-root': {
      padding: '0px',
    },
    '& .MuiSvgIcon-root': {
      width: '18px',
      height: '18px',
      fill: '#1c78ad',
    },
  },
  discardFooter: {
    maxWidth: '660px',
    background: 'rgb(173 173 173 / 20%)',
    borderRadius: '8px',
  },
  timeTextField: {
    width: '114px',
    height: '40px',
    '& .MuiFormControl-root': {
      background: '#fff',
      paddingBottom: '0px',
      height: '100%',
      '& .MuiOutlinedInput-root': {
        height: '100%',
        backgroundColor: 'transparent',
        borderRadius: '6px',
      },
      '& .MuiFormLabel-root': {
        color: '#333',
        fontFamily: 'Poppins, sans-serif !important',
        width: 'auto',
        fontWeight: 500,
        fontSize: '13px',
        top: '-7px',
        left: '-5px',
      },
      '& .MuiInputLabel-shrink': {
        top: '0px',
        left: 0,
        paddingRight: '2px',
        marginLeft: '-4px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#333',
        '& legend': {
          height: '7px',
          fontSize: '9px!important',
        },
      },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#333!important',
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: '#333',
      },
      '& .MuiInputBase-input': {
        fontSize: '14px',
        fontWeight: 600,
        padding: '0px 8px',
        fontFamily: 'Poppins !important',
        color: '#333',
      },
      '& .MuiIconButton-root': {
        color: '#1C78AD',
      },
      '& .MuiInputBase-adornedEnd': {
        paddingRight: '0px',
      },
      '& .MuiOutlinedInput-notchedOutline legend': {},
    },
  },
  runTextField: {
    width: '58px',
    height: '42px',
    marginRight: '10px',
    '& .MuiFormControl-root': {
      background: '#fff',
      paddingBottom: '0px',
      borderRadius: ' 6px',
      border: '1px solid #333',
      height: '45px',
      '& .MuiInputBase-root': {
        height: '45px',
        '&::before, &::after': {
          display: 'none',
        },
      },
      '& .MuiFormLabel-root': {
        color: '#333',
        fontFamily: 'Poppins, sans-serif !important',
        width: 'auto',
        fontWeight: 500,
        fontSize: '13px',
        top: '-7px',
        left: '-5px',
      },
      '& .MuiInputLabel-shrink': {
        top: '0px',
        left: 0,
        paddingRight: '2px',
        marginLeft: '-4px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#333',
        '& legend': {
          height: '5px',
        },
      },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#333!important',
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: '#333',
      },
      '& .MuiInputBase-input': {
        fontSize: '14px',
        lineHeight: '14px',
        fontWeight: 600,
        padding: '0px 8px',
        fontFamily: 'Poppins !important',
        color: '#333',
      },
      '& .MuiIconButton-root': {
        color: '#1C78AD',
      },
      '& .MuiInputBase-adornedEnd': {
        paddingRight: '0px',
      },
      '& .MuiOutlinedInput-notchedOutline legend': {
        fontSize: '9px!important',
      },
    },
  },
  timeSelect: {
    width: '115px',
    height: '45px',
    border: '1px solid #333',
    borderRadius: '6px',
    '& .MuiInputBase-root': {
      width: '100%',
    },
    '& .MuiSelect-select': {
      color: '#333333',
      fontSize: '14px',
      fontWeight: '600',
      fontFamily: 'poppins',
      lineHeight: '16px',
      padding: '0px 40px 0px 10px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '43px',
      '& .MuiRadio-root': {
        display: 'none',
      },
    },
    '& svg': {
      color: '#1c78ad',
      width: '1em!important',
      height: '1em!important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px!important',
    },
  },
  pickerDialog: {
    '& .MuiTypography-root': {
      fontFamily: 'poppins',
    },
    '& .MuiPickersToolbar-toolbar': {
      backgroundColor: '#1C78AD',
    },
    '& .MuiPickersCalendarHeader-dayLabel.MuiPickersCalendarHeader-dayLabel': {
      color: '#333',
    },
    '& .MuiPickersClockPointer-pointer': {
      color: '#333',
    },
    '& .MuiPickersClockNumber-clockNumber': {
      color: '#33333350',
    },
    '& .MuiPickersClockNumber-clockNumberSelected': {
      backgroundColor: '#1C78AD',
      color: '#fff',
    },
  },
});

export const initialFilter = {
  LastRowNumber: 0,
  MaxResults: 50,
  SortAsc: true,
  StatusStrings: [
    WorkflowStatus.Cancelled,
    WorkflowStatus.Complete,
    WorkflowStatus.InProcess,
    WorkflowStatus.New,
    WorkflowStatus.Scheduled,
    WorkflowStatus.Workflow,
  ],
  IsExactMatch: false,
};

export const initialValues: ScheduledTaskValues = {
  ID: undefined,
  IsActive: true,
  IsEnabled: true,
  Interval: 0,
  IntervalType: ManageSchedulerTime.Minutes,
  IsRecurringSchedule: true,
  TimeOfDay: null,
  RecurrenceType: ScheduledTaskRecurrenceType.TimeOfDay,
  CompletedDateTime_utc: null,
};

export const validationSchema = Yup.object({
  Interval: Yup.number()
    .nullable(true)
    .when('RecurrenceType', {
      is: ScheduledTaskRecurrenceType.RunEvery,
      then: Yup.number().required('Interval is required.').min(1, 'Interval must be a equal to or greater than 1.'),
    }),
  IntervalType: Yup.number()
    .nullable(true)
    .when('RecurrenceType', {
      is: ScheduledTaskRecurrenceType.RunEvery,
      then: Yup.number().required('Interval type is required.'),
    }),
  TimeOfDay: Yup.date()
    .nullable(true)
    .when('RecurrenceType', {
      is: ScheduledTaskRecurrenceType.TimeOfDay,
      then: Yup.date().required('Must enter email address'),
    }),
});

export const ManageSchedulerBatchModal = (props: IManageSchedulerBatchModalProps) => {
  // Props
  const {handleClose, open, scope} = props;

  // State
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [scheduledTaskToEdit, setScheduledTaskToEdit] = useState<ScheduledTaskValues>(initialValues);
  const [scheduledTasks, setScheduledTasks] = useState<ScheduledTaskValues[]>([]);

  // APIs
  const {getScheduledTask, updateScheduledTask, isLoading} = useScheduledTaskAPI();

  const isPSBLScope = scope === 'PSBL';

  useEffect(() => {
    if (open) {
      getScheduledTask(initialFilter).then(res => {
        const _scheduledTaskToEdit = res.find(task => task.TaskTypeID === 31);
        if (_scheduledTaskToEdit) {
          setScheduledTaskToEdit({
            ID: _scheduledTaskToEdit.ID,
            IsActive: _scheduledTaskToEdit.IsActive,
            IsEnabled: _scheduledTaskToEdit.IsEnabled,
            Interval: _scheduledTaskToEdit.Interval,
            IntervalType: _scheduledTaskToEdit.IntervalType,
            IsRecurringSchedule: _scheduledTaskToEdit.IsRecurringSchedule,
            TimeOfDay: _scheduledTaskToEdit.TimeOfDay,
            RecurrenceType: _scheduledTaskToEdit.RecurrenceType,
            CompletedDateTime_utc: _scheduledTaskToEdit.CompletedDateTime_utc,
          });
        }
        setScheduledTasks(
          res
            .filter(task => task.TaskTypeID !== 31)
            .map(task => ({
              ID: task.ID,
              IsActive: task.IsActive,
              IsEnabled: task.IsEnabled,
              Interval: task.Interval,
              IntervalType: task.IntervalType,
              IsRecurringSchedule: task.IsRecurringSchedule,
              TimeOfDay: task.TimeOfDay,
              RecurrenceType: task.RecurrenceType,
            })),
        );
      });
    }
  }, [open]);

  const handleCancelClick = (props: FormikProps<ScheduledTaskValues>) => {
    if (props.dirty) {
      setOpenConfirmationDialog(true);
    } else {
      handleClose();
    }
  };

  const onSave = async (values: ScheduledTaskValues): Promise<void> => {
    const payload = {...omit(values, ['CompletedDateTime_utc'])};
    payload.TimeOfDay = moment(payload.TimeOfDay).format('HH:mm');
    payload.IsEnabled = true;
    return updateScheduledTask([...scheduledTasks, payload]).then(() => {
      Toast.info('Scheduled task details have been updated.');
      setScheduledTaskToEdit(initialValues);
      handleClose();
    });
  };

  if (isPSBLScope) {
    return (
      <ManageScheduleDialog
        handleClose={handleClose}
        scheduledTaskToEdit={scheduledTaskToEdit}
        open={open}
        isLoading={isLoading}
        onSave={onSave}
      />
    );
  }

  return (
    <APManageScheduleModal
      openConfirmationDialog={openConfirmationDialog}
      scheduledTaskToEdit={scheduledTaskToEdit}
      open={open}
      isLoading={isLoading}
      onSave={onSave}
      handleClose={handleClose}
      setOpenConfirmationDialog={setOpenConfirmationDialog}
      setScheduledTaskToEdit={setScheduledTaskToEdit}
      handleCancelClick={handleCancelClick}
    />
  );
};

interface IAPManageScheduleModal {
  openConfirmationDialog: boolean;
  scheduledTaskToEdit: ScheduledTaskValues;
  open: boolean;
  isLoading: boolean;
  onSave: (values: ScheduledTaskValues) => Promise<void>;
  handleClose: () => void;
  setOpenConfirmationDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setScheduledTaskToEdit: React.Dispatch<React.SetStateAction<ScheduledTaskValues>>;
  handleCancelClick: (props: FormikProps<ScheduledTaskValues>) => void;
}

const APManageScheduleModal = (props: IAPManageScheduleModal) => {
  // Classes
  const classes = useManageSchedulerBatchModalStyles();

  // Props
  const {
    openConfirmationDialog,
    open,
    scheduledTaskToEdit,
    isLoading,
    setOpenConfirmationDialog,
    onSave,
    handleClose,
    handleCancelClick,
  } = props;

  return (
    <>
      {openConfirmationDialog && (
        <AlertDialogSlide
          maxWidth="xs"
          title="Discard Changes"
          isAccountsPayable={true}
          footer={
            <div className="flex w-full justify-between px-4 py-2">
              <SecondaryButton
                label="Cancel"
                onClick={() => setOpenConfirmationDialog(false)}
                data-autoid="btnCancel"
              />
              <DestructiveButton
                label="Discard"
                onClick={() => {
                  setOpenConfirmationDialog(false);
                  handleClose();
                }}
                data-autoid="btnDiscard"
              />
            </div>
          }
        >
          <p className="pb-5 text-center font-poppins">
            You have unsaved changes. Do you want to discard them, or go back ?{' '}
          </p>
        </AlertDialogSlide>
      )}
      <SModal open={open}>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={scheduledTaskToEdit}
          onSubmit={onSave}
        >
          {(fProps: FormikProps<ScheduledTaskValues>) => (
            <>
              <LoadingIndicator
                isLoading={isLoading}
                position={{
                  height: '100% !important',
                  display: 'flex',
                  position: 'absolute',
                  left: '0',
                  right: 0,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                size="md"
                color="hsl(var(--primary))"
              />
              <div className={`spenda-color flex h-full max-w-full items-center justify-center text-xl`}>
                <div className={`modal-box ${classes.apOnboardingModal} px-5 pb-5 pt-3`}>
                  <Box className={`${classes.apBusinessHeader} -mx-5 flex justify-between px-3 pb-3`}>
                    <h2 className={`w-full text-center`}>Manage Scheduler</h2>
                    <Box className="flex justify-end text-right">
                      <span className="ap-onboarding-close-icon">
                        <CloseIcon onClick={handleClose} data-autoid="lnkClose" />
                      </span>
                    </Box>
                  </Box>
                  <div className={`${classes.boxHeight} relative flex flex-col p-5 py-8 font-poppins`}>
                    <p className="flex items-center justify-start pb-6 text-base font-medium">
                      Sync Data{' '}
                      {fProps.values.CompletedDateTime_utc ? (
                        <p className="text-xs" style={{color: '#BFBFBF'}}>
                          <span className="font-medium">(Last Sync:</span>{' '}
                          {moment.utc(fProps.values.CompletedDateTime_utc).local().format('DD MMM YYYY hh:mm a')})
                        </p>
                      ) : null}
                    </p>
                    <Box className="flex items-start justify-start">
                      <Radio
                        checked={fProps.values.RecurrenceType === ScheduledTaskRecurrenceType.TimeOfDay}
                        name="radio-buttons"
                        onChange={() => fProps.setFieldValue('RecurrenceType', ScheduledTaskRecurrenceType.TimeOfDay)}
                        value="timeOfTheDay"
                        inputProps={{'aria-label': 'B'}}
                      />
                      <div className="ml-4 flex flex-col items-start justify-start ">
                        <p className={`mb-4 text-xs`}>Time of the day</p>
                        <div className={`${classes.timeTextField}`}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                              autoOk
                              variant="inline"
                              id="TimeOfDay"
                              name="TimeOfDay"
                              inputVariant="outlined"
                              label="1st time"
                              format="HH:mm"
                              value={fProps.values.TimeOfDay}
                              onChange={date => {
                                fProps.setFieldValue('TimeOfDay', date);
                              }}
                              helperText={fProps.touched?.TimeOfDay && fProps.errors?.TimeOfDay}
                              error={fProps.touched?.TimeOfDay && Boolean(fProps.errors?.TimeOfDay)}
                              PopoverProps={{className: classes.pickerDialog}}
                              InputProps={{style: {fontFamily: 'Poppins'}}}
                              InputLabelProps={{shrink: Boolean(fProps.values.TimeOfDay)}}
                              disabled={fProps.values.RecurrenceType === ScheduledTaskRecurrenceType.RunEvery}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                    </Box>
                    <Box className="mt-5 flex items-center justify-start">
                      <Radio
                        checked={fProps.values.RecurrenceType === ScheduledTaskRecurrenceType.RunEvery}
                        name="radio-buttons"
                        onChange={() => fProps.setFieldValue('RecurrenceType', ScheduledTaskRecurrenceType.RunEvery)}
                        value="runEvery"
                        inputProps={{'aria-label': 'A'}}
                      />
                      <div className="flex items-center justify-start">
                        <p className={`mx-4 text-xs`}>Run Every</p>
                        <div className={`${classes.runTextField}`}>
                          <STextField
                            fullWidth
                            data-autoid="txtInterval"
                            disabled={fProps.values.RecurrenceType === ScheduledTaskRecurrenceType.TimeOfDay}
                            type="number"
                            id="Interval"
                            name="Interval"
                            placeholder="eg. 1"
                            value={fProps.values.Interval}
                            onChange={fProps.handleChange}
                            helperText={fProps.touched.Interval && fProps.errors.Interval}
                            error={fProps.touched.Interval && Boolean(fProps.errors.Interval)}
                          />
                        </div>
                        <div className={`${classes.timeSelect}  mr-1 border font-poppins text-black-800`}>
                          <Select
                            disabled={fProps.values.RecurrenceType === ScheduledTaskRecurrenceType.TimeOfDay}
                            variant="outlined"
                            value={fProps.values.IntervalType}
                            onChange={e => {
                              fProps.setFieldValue('IntervalType', Number(e.target.value));
                            }}
                            MenuProps={{
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                            }}
                            inputProps={{
                              name: 'selectManageScheduler',
                            }}
                          >
                            <MenuItem key={ManageSchedulerTime.Minutes} value={ManageSchedulerTime.Minutes}>
                              Minutes
                            </MenuItem>
                            <MenuItem key={ManageSchedulerTime.Hours} value={ManageSchedulerTime.Hours}>
                              Hours
                            </MenuItem>
                            <MenuItem key={ManageSchedulerTime.Days} value={ManageSchedulerTime.Days}>
                              Days
                            </MenuItem>
                          </Select>
                        </div>
                      </div>
                    </Box>
                  </div>
                  <Box
                    className={`${classes.discardFooter} absolute bottom-5 left-0 mx-8 flex w-full items-center justify-between p-2 `}
                  >
                    <SecondaryButton label="Cancel" onClick={() => handleCancelClick(fProps)} data-autoid="btnCancel" />
                    <PrimaryButton
                      label={fProps.values.IsEnabled ? 'Done' : 'Enable Settings'}
                      isSubmitting={fProps.isSubmitting}
                      disabled={fProps.isSubmitting || (!fProps.dirty && fProps.values.IsEnabled)}
                      onClick={() => {
                        fProps.handleSubmit();
                      }}
                      data-autoid="btnDone"
                    />
                  </Box>
                </div>
              </div>
            </>
          )}
        </Formik>
      </SModal>
    </>
  );
};
