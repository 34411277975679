import React, {useContext, useEffect, useState} from 'react';
import {
  Alert,
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Input,
  Option,
  Select,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Tooltip,
  Typography,
} from 'spenda-ui-react';
import {DepositAmountType, QuoteDetailsValidDate} from '../../model/constants/Constants';
import {Form, Formik} from 'formik';
import useUsersAPI from '../../services/useUsersAPI';
import {IActionResultsList} from '../../model/ActionResults';
import {IUserAccountInfo, UserRoleEnum} from '../../model/user/UserAccountInfo';
import {IQuotePackageDetails} from '../../model/quotes/quotes';
import moment from 'moment';
import LoadingIndicator from '../ui/LoadingIndicator';
import _, {camelCase, upperFirst} from 'lodash';
import AppContext from '../../context/app/appContext';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {BlueInfoIcon} from '../../assets/svg';

interface IQuoteDetailsDialog {
  open: boolean;
  isEdit?: boolean;
  handleGoBack: () => void;
  onChangeCustomer?: () => void;
  quotePackageDetails: IQuotePackageDetails;
  handleContinueClick: (
    expiryDate: string,
    assignedToUserID: number,
    days?: number,
    depositAmountType?: DepositAmountType,
    depositAmount?: number,
  ) => void;
}

const dateAlertMessage = 'Please select a valid expiry date. Expiry dates cannot be in the past.';
const depositAlertMessage = 'Please enter a valid deposit percentage. Percentage should be between 0 to 100.';

export const QuoteDetailsDialog = (props: IQuoteDetailsDialog) => {
  const {open, handleGoBack, handleContinueClick, quotePackageDetails, isEdit = false, onChangeCustomer} = props;
  const [selectedDays, setSelectedDays] = useState<QuoteDetailsValidDate | string>('');
  const dayKeys = Object.keys(QuoteDetailsValidDate) as (keyof typeof QuoteDetailsValidDate)[];
  const {get} = useUsersAPI();
  const [users, setUsers] = useState<IUserAccountInfo[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<number>();
  const [selectedDate, setSelectedDate] = useState<string>();
  const [packageDetails, setPackageDetails] = useState<IQuotePackageDetails>(quotePackageDetails);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disableContinue, setDisableContinue] = useState<boolean>(false);
  const {user: loggedInUser} = useContext(AppContext);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [depositPercent, setDepositPercent] = useState<string>();
  const [depositFlatFee, setDepositFlatFee] = useState<string>();

  const {QuoteV289367} = useFeatureFlags().tenantOwned();

  useEffect(() => {
    if (!_.isEqual(packageDetails, quotePackageDetails) || !selectedDays) {
      quotePackageDetails.assignQuoteToUserID && setSelectedUserId(quotePackageDetails.assignQuoteToUserID);
      if (quotePackageDetails.expireInDays) {
        let days =
          7 === quotePackageDetails.expireInDays
            ? QuoteDetailsValidDate.DAY_7
            : 14 === quotePackageDetails.expireInDays
              ? QuoteDetailsValidDate.DAY_14
              : 21 === quotePackageDetails.expireInDays
                ? QuoteDetailsValidDate.DAY_21
                : 30 === quotePackageDetails.expireInDays
                  ? QuoteDetailsValidDate.DAY_30
                  : '';
        setSelectedDays(days);
      } else if (quotePackageDetails.validFor) {
        if (moment(quotePackageDetails.validFor).isBefore(moment(new Date()), 'day')) {
          setShowErrorAlert(true);
          setAlertMessage(dateAlertMessage);
        }
        setSelectedDays(QuoteDetailsValidDate.CUSTOMISE_DAYS);
        setSelectedDate(moment(quotePackageDetails.validFor).format('YYYY-MM-DD'));
      }
      if (QuoteV289367 && packageDetails.depositAmountType) {
        const index = Object.values(DepositAmountType).findIndex(val => val === quotePackageDetails.depositAmountType);
        if (index !== -1) setActiveTab(index);
        if (index === 0 && quotePackageDetails?.depositAmount)
          setDepositPercent((quotePackageDetails?.depositAmount * 100).toString());
        else if (index === 1)
          (quotePackageDetails?.depositAmount || 0) > 0
            ? setDepositFlatFee(quotePackageDetails?.depositAmount?.toFixed(2))
            : setDepositFlatFee('');
      }
    }
  }, [packageDetails, quotePackageDetails]);

  useEffect(() => {
    setIsLoading(true);
    fetchUser();
  }, []);

  useEffect(() => {
    if (showErrorAlert) {
      setTimeout(() => {
        setShowErrorAlert(false);
        setAlertMessage('');
      }, 2000);
    }
  }, [showErrorAlert]);

  const fetchUser = () => {
    get({}).then((data: IActionResultsList<IUserAccountInfo>) => {
      if (data.IsSuccess) {
        const filterTech = data?.Items.filter(
          user => !user.UserRoles.some(role => role.UserRoleEnum === UserRoleEnum.Technician),
        );
        setUsers(filterTech);
        setSelectedUserId(packageDetails.assignQuoteToUserID || loggedInUser?.UserID);
        setPackageDetails({
          ...packageDetails,
          assignQuoteToUserID: packageDetails.assignQuoteToUserID || loggedInUser?.UserID!,
        });
      }
      setIsLoading(false);
    });
  };
  const handleSelectChange = (value: string) => {
    setSelectedDays(value);
  };

  const onSubmit = (values: IQuotePackageDetails) => {
    if (!selectedDays.length || (selectedDays === QuoteDetailsValidDate.CUSTOMISE_DAYS && !selectedDate)) {
      setShowErrorAlert(true);
      setAlertMessage(dateAlertMessage);
      setDisableContinue(false);
      return;
    }
    if (
      selectedDays === QuoteDetailsValidDate.CUSTOMISE_DAYS &&
      selectedDate &&
      moment(selectedDate).isBefore(moment(new Date()), 'day')
    ) {
      setShowErrorAlert(true);
      setAlertMessage(dateAlertMessage);
      setDisableContinue(false);
      return;
    }
    // get expiry date from days selected
    let expiryDate;
    let days;
    if (selectedDays === QuoteDetailsValidDate.CUSTOMISE_DAYS) {
      expiryDate = selectedDate!;
    } else {
      days =
        selectedDays === QuoteDetailsValidDate.DAY_7
          ? 7
          : selectedDays === QuoteDetailsValidDate.DAY_14
            ? 14
            : selectedDays === QuoteDetailsValidDate.DAY_21
              ? 21
              : selectedDays === QuoteDetailsValidDate.DAY_30
                ? 30
                : 0;
    }
    if (QuoteV289367) {
      let depositAmountType;
      let depositAmount = 0;

      if (activeTab === 0) {
        depositAmountType = DepositAmountType.Percentage;
        if (parseFloat(depositPercent || '0') > 100 || parseFloat(depositPercent || '0') <= 0) {
          setShowErrorAlert(true);
          setAlertMessage(depositAlertMessage);
          setDisableContinue(false);
          return;
        }
        depositAmount = parseFloat(depositPercent || '0') / 100;
      } else if (activeTab === 1) {
        depositAmountType = DepositAmountType.FlatFee;
        depositAmount = parseFloat(depositFlatFee || '0');
      } else {
        depositAmountType = DepositAmountType.NoDeposit;
      }

      handleContinueClick?.(expiryDate!, values.assignQuoteToUserID, days, depositAmountType, depositAmount);
    } else {
      handleContinueClick?.(expiryDate!, values.assignQuoteToUserID, days);
    }
  };

  const tabs = [
    {
      label: 'Percentage',
      value: 0,
    },
    {
      label: 'Flat fee',
      value: 1,
    },
    {
      label: 'No deposit',
      value: 2,
    },
  ];

  return (
    <>
      <Dialog
        open={open}
        handler={() => {}}
        className={`${QuoteV289367 ? '!w-[600px] !min-w-[638px]' : '!w-[500px] !min-w-[538px]'} `}
        data-autoid={isEdit ? 'dlgChangeQuotePackage' : 'dlgQuotePackageDetails'}
      >
        <DialogHeader className="justify-center border-b border-[#ccc] text-center text-xl font-light text-black-900">
          {isEdit ? 'Change quote package details' : 'Quote package details'}
        </DialogHeader>
        {isLoading || !users.length || !selectedUserId ? (
          <DialogBody>
            <div className="py-12">
              <LoadingIndicator isLoading={true} size="md" color="hsl(var(--primary))" />
            </div>
          </DialogBody>
        ) : (
          <Formik initialValues={packageDetails} onSubmit={onSubmit} enableReinitialize>
            {({setFieldValue, handleSubmit}) => (
              <Form>
                <DialogBody>
                  <div className="mr-2 py-12">
                    <div className={`${QuoteV289367 ? 'mb-5' : 'mb-3'} flex flex-col items-center justify-center`}>
                      <div className="flex justify-between">
                        {selectedDays !== 'Customise days' ? (
                          <Select
                            size="lg"
                            variant="outlined"
                            containerProps={{className: 'min-w-[162px] mr-1'}}
                            label="Valid for"
                            onChange={handleSelectChange}
                            value={selectedDays as string}
                          >
                            {dayKeys.map(day => (
                              <Option
                                key={day}
                                data-autoid={`option${upperFirst(camelCase(day))}`}
                                value={QuoteDetailsValidDate[day]}
                              >
                                {QuoteDetailsValidDate[day]}
                              </Option>
                            ))}
                          </Select>
                        ) : (
                          <Input
                            type="date"
                            label="Valid for"
                            name="ValidFor"
                            onChange={e => setSelectedDate(e.target.value)}
                            containerProps={{className: 'min-w-[162px] mr-1'}}
                            value={selectedDate}
                          />
                        )}
                        <div>
                          <Select
                            size="lg"
                            variant="outlined"
                            label="Assign quote"
                            containerProps={{className: 'ml-2 min-w-[218px]'}}
                            value={selectedUserId?.toString()}
                            onChange={(value: string) => {
                              const user = users.find(user => user.UserID === parseInt(value));
                              setFieldValue('assignQuoteToUserID', user?.UserID!);
                              setSelectedUserId(user?.UserID);
                            }}
                          >
                            {users.map((user, index) => {
                              return (
                                <Option
                                  key={index}
                                  data-autoid={`option-${user.UserID}`}
                                  value={user?.UserID?.toString()}
                                >
                                  {user.DisplayName}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                    </div>
                    {QuoteV289367 && (
                      <div className="ml-28 flex h-full w-[377px] flex-col items-center justify-center rounded-md bg-spenda-cream p-4">
                        <Typography className="py-5 text-center text-base font-normal text-black-800" variant="small">
                          You can add a flat fee or percentage to a quote as a deposit to confirm the order.
                        </Typography>

                        <Tabs className="w-full" value={activeTab}>
                          <TabsHeader
                            className="w-full bg-[#ECECEC] p-1"
                            indicatorProps={{
                              className: 'bg-spenda-sBlue shadow-none text-white rounded-lg',
                            }}
                          >
                            {tabs?.map(({label, value}) => (
                              <Tab
                                key={crypto.randomUUID()}
                                value={value}
                                onClick={() => {
                                  setActiveTab(value);
                                }}
                                className={`flex w-full items-center justify-center rounded-lg py-2 font-medium ${activeTab === value ? 'bg-spenda-sBlue text-white' : 'text-black-800'}`}
                                data-autoid={`tab-${label}`.replace(/ /g, '')}
                                title={label}
                              >
                                {label}
                              </Tab>
                            ))}
                          </TabsHeader>
                          <TabsBody
                            animate={{
                              initial: {y: 100},
                              mount: {y: 0},
                              unmount: {y: 10},
                            }}
                          >
                            <div className="flex items-center justify-center gap-4 ">
                              {tabs.map(({value}) => {
                                return value !== 2 ? (
                                  <TabPanel
                                    key={value}
                                    value={value}
                                    className="flex w-[148px] items-center justify-center p-4"
                                  >
                                    <Input
                                      type="number"
                                      label={value === 0 ? `Percentage` : `Flat fee`}
                                      name={value === 0 ? `Percentage` : `FlatFee`}
                                      containerProps={{className: 'w-[148px] min-w-[148px]'}}
                                      value={value === 0 ? depositPercent : depositFlatFee}
                                      onChange={e =>
                                        value === 0
                                          ? setDepositPercent(e.target.value)
                                          : setDepositFlatFee(e.target.value)
                                      }
                                    />
                                  </TabPanel>
                                ) : (
                                  <></>
                                );
                              })}

                              {activeTab !== 2 && (
                                <Tooltip
                                  className="z-[99999999] bg-white shadow-lg"
                                  content={
                                    <div className="w-[157px]">
                                      <Typography className="text-sm text-black">
                                        {activeTab === 0
                                          ? 'Percentage of the total quote as acceptance fee.'
                                          : 'Flat amount on the total quote value as acceptance fee'}
                                      </Typography>
                                    </div>
                                  }
                                  placement="right"
                                >
                                  <div className="cursor-pointer">
                                    <BlueInfoIcon />
                                  </div>
                                </Tooltip>
                              )}
                            </div>
                          </TabsBody>
                        </Tabs>
                      </div>
                    )}
                  </div>
                </DialogBody>
                <DialogFooter className="p-2.5">
                  <div className="flex w-full items-center justify-between rounded-lg bg-[#f1f1f1] p-2.5">
                    <div>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setSelectedDays('');
                          setSelectedUserId(loggedInUser?.UserID);
                          handleGoBack();
                        }}
                        className="bg-white"
                        data-autoid="btnCancelQuote"
                      >
                        {isEdit ? 'Cancel' : 'Go Back'}
                      </Button>
                    </div>
                    <div className="flex gap-3">
                      {isEdit && !QuoteV289367 && <Button onClick={onChangeCustomer}>Change Customer</Button>}
                      <Button
                        variant="filled"
                        color="primary"
                        type="submit"
                        data-autoid="btnConfirmQuote"
                        disabled={disableContinue}
                        loading={disableContinue}
                        onClick={() => {
                          setDisableContinue(true);
                          handleSubmit();
                        }}
                      >
                        {isEdit ? 'Save & Close' : 'Continue'}
                      </Button>
                    </div>
                  </div>
                </DialogFooter>
              </Form>
            )}
          </Formik>
        )}
      </Dialog>

      {showErrorAlert && alertMessage.length && (
        <Alert
          open={showErrorAlert}
          onClose={() => {
            setShowErrorAlert(false);
            setAlertMessage('');
          }}
          color="error"
          animate={{
            mount: {y: 0},
            unmount: {y: 50},
          }}
          className="absolute left-0 right-0 top-7 z-[99999] mx-auto w-full max-w-[350px] font-poppins"
        >
          {alertMessage}
        </Alert>
      )}
    </>
  );
};
