import React, {useState, useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Toast} from '../../utils/Toast';
import {useSupplierTransactionsAPI} from '../../services/useSupplierTransactionsAPI';
import {LogoName} from '../../components/LogoName';
import LoadingIndicator from '../../components/ui/LoadingIndicator';

export const ClaimByLink = () => {
  // Hooks
  const history = useHistory();
  const {magicLink} = useParams<{magicLink?: string}>();

  // State
  const [loading, setLoading] = useState<boolean>(true);

  // API
  const {getInvoice: getInvoiceAPI} = useSupplierTransactionsAPI();

  useEffect(() => {
    const fetchInvoice = async () => {
      if (!magicLink) {
        Toast.info('Missing magic link');
        // TODO: Define what to do here.
        return;
      }

      const request = {MagicLink: magicLink};
      const invoice = await getInvoiceAPI(request);

      if (!invoice) {
        Toast.info('Invoice not found');
        return;
      }

      const claimUrl = `/supplier/${invoice.Supplier?.ID}/claim-request/create?txId=${invoice?.ID}&txGuid=${invoice?.GUID}`;
      history.push(claimUrl);

      setLoading(false);
    };

    fetchInvoice();
  }, []);

  return (
    <div className="flex h-full w-full flex-col items-center justify-center bg-spenda-newbg">
      <LogoName />
      {loading && (
        <div className="flex items-center justify-center">
          <LoadingIndicator isLoading={true} position={{position: 'relative'}} size="sm" color="hsl(var(--primary))" />
          <p className="ml-2 font-poppins font-light text-primary">Loading claim by link...</p>
        </div>
      )}
    </div>
  );
};
