import React, {useState, useEffect} from 'react';
import {FormikProps, getIn} from 'formik';
import {uniqBy, upperFirst, camelCase} from 'lodash';

import {IActionResults} from '../../model/ActionResults';
import {InventorySOH, IStockOnHand} from '../../model/inventory/InventorySOH';
import {InventoryClass} from '../../model/inventory/InventoryClass';
import {ChildVariants, Inventory, InventoryWarrantyMediaFileDetails} from '../../model/inventory/Inventory';
import {AttachmentType, FileDetails} from '../../model/FileDetails';
import {IWarehouseStockMinMaxLevel} from '../../model/Warehouse';
import {IAccounts} from '../../model/accounts/Accounts';
import {IBrand} from '../../model/inventory/Brand';
import {ICategory} from '../../model/inventory/Category';
import {ISupplier} from '../../model/supplier/Supplier';
import {STextField} from '../inputs/STextField';
import {SRichTextEditor} from '../inputs/SRichTextEditor';
import {MediaUploader} from '../inputs/MediaUploader';
import {
  InventoryClassAutocomplete,
  BrandAutocomplete,
  CategoryAutocomplete,
  InventoryTypeDropDown,
  AccountsAutocomplete,
  SupplierAutocomplete,
  UoMDropDown,
} from '../inventory/InventoryAutocomplete';
import {AccountDialog} from '../dialog/AccountDialog';
import {BrandDialog} from '../dialog/BrandDialog';
import {initialBrandData} from '../../screens/product-mangement/BrandsList';
import {CategoryDialog} from '../dialog/CategoryDialog';
import {initialCategoryData} from '../../screens/product-mangement/CategoriesList';
import {Image} from '../ui/Image';
import PriceCalculator from '../../utils/priceCalculator';

import {useInventoryAPI} from '../../services/useInventoryAPI';
import useAccountAPI from '../../services/useAccountAPI';

import UnitofMeasure from '../../assets/data/UnitofMeasure.json';
import {IconHome} from '../../assets/svg/soh-table/IconHome';
import {IconTruck} from '../../assets/svg/soh-table/IconTruck';
import {IconMinStock} from '../../assets/svg/soh-table/IconMinStock';
import {IconMaxStock} from '../../assets/svg/soh-table/IconMaxStock';
import {IconStockOnOrder} from '../../assets/svg/soh-table/IconStockOnOrder';
import {IconStockTransfer} from '../../assets/svg/soh-table/IconStockTransfer';
import {IconStockCommited} from '../../assets/svg/soh-table/IconStockCommited';
import {IconStockAvailable} from '../../assets/svg/soh-table/IconStockAvailable';

import {
  Box,
  Grid,
  Divider,
  FormGroup,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
} from '@material-ui/core';
import {Variant} from '../inventory/Variant';
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {
  Checkbox,
  Chip,
  Switch,
  Input,
  Typography,
  Tabs,
  TabsHeader,
  Tab,
  TabsBody,
  TabPanel,
  Select,
  Textarea,
  Upload,
  IconButton,
  Progress,
  Option,
  Collapse,
} from 'spenda-ui-react';
import {WidgetDeleteLogo} from '../../assets/svg/WidgetDeleteLogo';
import {AttachmentTypeEnum, ProductWarrantyPeriod} from '../../model/constants/Constants';
import {useAttachmentsAPI} from '../../services/useAttachmentsAPI';
import {useLocation} from 'react-router-dom';
import {fileExtension} from '../../utils/formatter';
import LoadingIndicator from '../ui/LoadingIndicator';
import {Toast} from '../../utils/Toast';
import {AlertDialog} from '../dialog/AlertDialogSlideV2';

const useInventoryStyle = makeStyles(() => ({
  selectBox: {
    '& .MuiSelect-root, .MuiInput-root': {
      paddingBottom: '3px',
    },
  },
  descTextBox: {
    '& .MuiSelect-root, .MuiInput-root': {
      paddingBottom: '0px',
    },
  },
  apTextField: {
    '& .MuiInputBase-input, .ql-editor, .ql-editor.ql-blank::before': {
      fontFamily: 'poppins !important',
      fontSize: '14px',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      top: '23px!important',
      fontFamily: 'poppins',
    },
  },
}));

interface InventoryFormProps extends FormikProps<AddInventoryValues> {
  inventorySOH?: InventorySOH;
  selectedTab?: string;
  isInAPContext?: boolean;
  inventory?: Inventory;
  isInDialog?: boolean;
  childVariantID?: number;
  isInternalTab?: boolean;
  setOpenChildItemEdit?: (value: boolean) => void;
  handleEditMasterPrice?: () => void;
}

export type AddInventoryValues = {
  ID?: any;
  IsActive: boolean;
  ShortDescription: string;
  InventoryCode: string;
  InventoryClassID: string;
  InventoryClassName: string;
  SKU: string;
  UoM: string;
  UoMDescription: string;
  Barcode: string;
  Type: string;
  Description: string;
  Brand: IBrand;
  ExpenseAccount: string;
  RevenueAccount: string;
  HTMLDescription: string;
  IsSold: boolean;
  SellPriceEx: number;
  LastBuyPriceEx: number;
  RRP: number;
  StandardSellPriceEx: number;
  StandardSellPriceInc: number;
  IsOrderTaxExempt: boolean;
  IsPublished: boolean;
  AssetAccount: string;
  IsVariantMaster: boolean;
  Categories: ICategory[];
  IsPurchased: boolean;
  IsApproved: boolean;
  IsSOHTracked: boolean;
  CostPriceEx: number;
  DefaultSupplierID: number;
  DefaultSupplierName: string;
  IsPhysical: boolean;
  Width_m: any;
  Length_m: any;
  Depth_m: any;
  Weight_kg: any;
  Volume: any;
  VariantMasterInventoryID?: number;
  ChildVariants?: ChildVariants[];
  ChildIsSold?: boolean;
  ChildIsPurchased?: boolean;
  ChildIsPhysical?: boolean;
  ChildIsSOHTracked?: boolean;
  MediaFiles?: FileDetails[];
  MinMaxLevels?: IWarehouseStockMinMaxLevel[];
  WarrantyPeriodType?: string;
  WarrantyPeriod?: string;
  WarrantyDetails?: string;
  WarrantyMediaFiles?: (InventoryWarrantyMediaFileDetails & IWarrantyMediaFile)[];
};

interface IWarrantyMediaFile {
  file?: File;
  isDeleted?: boolean;
}

export const initialAccountData = {
  Class: '',
  Type: '',
  Code: '',
  Name: '',
  TaxType: 'BAS_Excluded',
  Description: '',
  IsActive: true,
} as IAccounts;

export const AddInventory = (props: InventoryFormProps) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialTab = props.isInternalTab
    ? 0
    : queryParams.get('tab') === 'variants'
      ? 2
      : props.selectedTab
        ? parseInt(props.selectedTab)
        : 0;
  const [activeTab, setActiveTab] = useState<number>(initialTab);
  const [assetAccount, setAssetAccount] = useState<IAccounts | undefined>();
  const [showCustomPricePopup, setShowCustomPricePopup] = useState<boolean>(false);
  const [showRemoveCustomPrice, setShowRemoveCustomPrice] = useState<boolean>(false);
  const {getAccountByCode} = useAccountAPI();
  const {
    values,
    isInAPContext,
    //,addWarrantyDocuments,
    //setAddWarrantyDocuments
  } = props;
  const {QuoteV289367} = useFeatureFlags().tenantOwned();

  useEffect(() => {
    if (values.AssetAccount) {
      getAccountByCode(values.AssetAccount).then((accountRes: IAccounts) => {
        setAssetAccount(accountRes);
      });
    }
  }, [values.AssetAccount]);

  useEffect(() => {
    if (activeTab === 1 && props.childVariantID && (values.IsSold || values.IsPurchased)) {
      setShowCustomPricePopup(true);
    }
  }, [activeTab, props.childVariantID]);

  const tabs = [
    {
      label: 'Basic details',
      value: 0,
      content: <BasicDetails {...props} isInAPContext={isInAPContext} />,
    },
    {
      label: 'Pricing',
      value: 1,
      content: (
        <PriceDetails
          {...props}
          isInAPContext={isInAPContext}
          removeCustomPrice={showRemoveCustomPrice}
          setShowRemoveCustomPrice={setShowRemoveCustomPrice}
          // addWarrantyDocuments={addWarrantyDocuments}
          // setAddWarrantyDocuments={setAddWarrantyDocuments}
        />
      ),
    },
    ...(QuoteV289367
      ? [
          {
            label: 'Variants',
            value: 2,
            content: <Variant setActiveTab={setActiveTab} />,
            disabled: !values.ID || Boolean(props.childVariantID),
          },
        ]
      : []),
    {
      label: 'Warehouse Details',
      value: QuoteV289367 ? 3 : 2,
      content: <WarehouseDetails {...props} assetAccount={assetAccount} />,
    },
  ];

  return (
    <div
      className={`mb-10 flex w-full items-start justify-start overflow-y-auto rounded-lg bg-[#FFFFFF] p-2.5 font-poppins ${isInAPContext ? 'p-0' : 'px-2.5'}`}
    >
      <div className="w-full">
        <Tabs value={activeTab} id="custom-animation">
          <TabsHeader
            className="max-w-max whitespace-nowrap bg-[#ECECEC]"
            indicatorProps={{
              className: 'bg-spenda-sBlue shadow-none !text-white',
            }}
          >
            {tabs?.map(({label, value, disabled}) => (
              <Tab
                key={value}
                value={value}
                onClick={() => !disabled && setActiveTab(value)}
                className={`h-10 px-8 font-medium leading-4 text-black-800 ${activeTab === value ? 'text-white' : ''}`}
                data-autoid={`tab-${label}`.replace(/ /g, '')}
                title={label}
                disabled={!!disabled}
              >
                {label}
              </Tab>
            ))}
          </TabsHeader>
          <TabsBody
            animate={{
              initial: {y: 100},
              mount: {y: 0},
              unmount: {y: 10},
            }}
            className={`mt-2 w-full ${props.isInDialog ? 'px-4' : 'px-2.5'}`}
          >
            {tabs.map(({value, content}) => (
              <TabPanel key={value} value={value} className="z-0 w-full px-0 py-2.5">
                {content}
              </TabPanel>
            ))}
          </TabsBody>
        </Tabs>
      </div>
      {showCustomPricePopup && (
        <AlertDialog
          title="Custom Price"
          headingTextSize="h2"
          headingClassess="text-black justify-center"
          dialogClassess="!max-w-[497px] !min-w-[20%]"
          data-autoid="dlgCustomPrice"
          actions={[
            {
              label: 'Edit Master Price',
              variant: 'outlined',
              action: props.handleEditMasterPrice,
            },
            {
              label: 'Create Special Price',
              action: () => {
                setShowCustomPricePopup(false);
                setShowRemoveCustomPrice(true);
              },
            },
          ]}
          dialogBodyClassess="flex justify-center"
          dialogActionsAlignment="justify-between"
          contentClass="max-w-[374px] text-sm"
          content={` The price of the item is managed by the master item.
            You can edit the master item price or you can create a custom price for this variant.`}
        />
      )}
    </div>
  );
};

const BasicDetails = (props: FormikProps<AddInventoryValues> & {isInAPContext?: boolean; isInDialog?: boolean}) => {
  const classes = useInventoryStyle();
  const {inventoryCodeUnique} = useInventoryAPI();
  const [openBrand, setOpenBrand] = useState<boolean>(false);
  const [openCategory, setOpenCategory] = useState<boolean>(false);

  const {values, touched, errors, status, handleChange, setFieldValue, setStatus, isInAPContext} = props;

  const handleAddNewBrand = () => {
    setOpenBrand(true);
  };

  const handleAddNewCategory = () => {
    setOpenCategory(true);
  };

  const onMediaUpload = (files: FileDetails[]) => {
    if (Array.isArray(values.MediaFiles)) {
      setFieldValue('MediaFiles', [...values.MediaFiles, ...files]);
    } else {
      setFieldValue('MediaFiles', files);
    }
  };

  const deleteMedia = (id: number | undefined) => {
    if (Array.isArray(values.MediaFiles) && id) {
      setFieldValue(
        'MediaFiles',
        values.MediaFiles.filter((media: FileDetails) => media?.ID !== id),
      );
    }
  };

  const setInventoryClass = (selected: Partial<InventoryClass> | null) => {
    setFieldValue('InventoryClassID', selected?.ID || null);
    setFieldValue('InventoryClassName', selected?.Name || null);
  };

  const setCategory = (selected: Partial<ICategory> | null) => {
    if (Array.isArray(values.Categories)) {
      setFieldValue('Categories', uniqBy([...values.Categories, selected], 'ID'));
    } else {
      setFieldValue('Categories', [selected]);
    }
  };

  const deleteCategory = (id: number) => {
    if (Array.isArray(values.Categories) && id) {
      setFieldValue(
        'Categories',
        values.Categories.filter((category: ICategory) => category.ID !== id),
      );
    }
  };

  const setBrand = (selected: Partial<IBrand> | null) => {
    setFieldValue('Brand', selected || null);
  };

  const setUoM = (selected: string | undefined) => {
    setFieldValue('UoM', selected);
    const uom = UnitofMeasure.find((uom: {name: string; value: string}) => uom.value === selected);
    setFieldValue('UoMDescription', uom?.name.split(' - ')[1]);
  };

  const checkInventoryCode = () => {
    inventoryCodeUnique({InventoryCode: values.InventoryCode, InventoryID: values?.ID || undefined}).then(
      (res: IActionResults<any>) => {
        if (res.Value > 0) {
          setStatus({Name: 'Inventory code already exists'});
        } else {
          setStatus(null);
        }
      },
    );
  };

  return (
    <>
      <BrandDialog
        brand={initialBrandData}
        open={openBrand}
        onClose={(_refetch, newBrand) => {
          if (newBrand) {
            setBrand(newBrand);
          }
          setOpenBrand(false);
        }}
      />
      <CategoryDialog
        category={initialCategoryData}
        open={openCategory}
        onClose={(_refetch, newCategory) => {
          if (newCategory) {
            setCategory(newCategory);
          }
          setOpenCategory(false);
        }}
      />
      <Grid
        container
        className={`${
          isInAPContext ? `${classes.apTextField} flex-col !flex-nowrap` : 'flex-row'
        } h-full items-start justify-start overflow-y-auto py-5 pr-2`}
      >
        <Grid item sm={isInAPContext ? 12 : 9} className="w-full justify-start">
          <Box
            display="flex"
            flexDirection="column"
            marginY="10px"
            className={`${props.isInDialog ? 'flex flex-col gap-2' : ''}`}
          >
            <Grid container>
              <Grid item sm={2} className="flex items-start">
                <Typography className={`pb-6 text-[#333333] ${isInAPContext ? '!text-base' : ''} `}>
                  Product Name:
                </Typography>
              </Grid>
              <Grid item sm={3}>
                <Input
                  id="ShortDescription"
                  name="ShortDescription"
                  placeholder="Product Name"
                  value={values?.ShortDescription?.trimStart()}
                  onChange={handleChange}
                  variant="standard"
                  containerProps={{
                    className: 'h-[27px]',
                  }}
                  className="!pt-0"
                  helperText={touched?.ShortDescription && errors?.ShortDescription ? errors?.ShortDescription : ''}
                  error={touched?.ShortDescription && Boolean(errors?.ShortDescription)}
                />
              </Grid>
              <Grid item sm={1} />
              <Grid item sm={2} className="flex items-start">
                <Typography className={`pb-6 text-[#333333] ${isInAPContext ? '!text-base' : ''}`}>Code:</Typography>
              </Grid>
              <Grid item sm={3}>
                <Input
                  id="InventoryCode"
                  name="InventoryCode"
                  placeholder="Code"
                  value={values?.InventoryCode?.trimStart()}
                  onChange={handleChange}
                  onBlur={checkInventoryCode}
                  variant="standard"
                  containerProps={{
                    className: 'h-[27px]',
                  }}
                  className="!pt-0"
                  helperText={(touched?.InventoryCode && errors?.InventoryCode) || (!!status && status?.Name)}
                  error={(touched?.InventoryCode && Boolean(errors?.InventoryCode)) || !!status}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={2} className="flex items-start">
                <Typography className={`pb-6 text-[#333333] ${isInAPContext ? '!text-base' : ''} `}>
                  Product Class:
                </Typography>
              </Grid>
              <Grid item sm={3}>
                <InventoryClassAutocomplete
                  styles={{marginTop: '-1.5rem'}}
                  selected={
                    values.InventoryClassID && values.InventoryClassName
                      ? {
                          ID: values.InventoryClassID,
                          Name: values.InventoryClassName,
                        }
                      : null
                  }
                  clearClick={() => setInventoryClass(null)}
                  onSuggestionSelected={(e, selected) => setInventoryClass(selected.suggestion)}
                />
              </Grid>
              <Grid item sm={1} />
              <Grid item sm={2} className="flex items-start">
                <Typography className={`pb-6 text-[#333333] ${isInAPContext ? '!text-base' : ''} `}>SKU:</Typography>
              </Grid>
              <Grid item sm={3}>
                <Input
                  id="SKU"
                  name="SKU"
                  placeholder="SKU"
                  value={values?.SKU?.trimStart()}
                  onChange={handleChange}
                  variant="standard"
                  containerProps={{
                    className: 'h-[27px]',
                  }}
                  className="!pt-0"
                  helperText={touched?.SKU && errors?.SKU ? errors?.SKU : ''}
                  error={touched?.SKU && Boolean(errors?.SKU)}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={2} className="flex items-start">
                <Typography className={`pb-6 text-[#333333] ${isInAPContext ? '!text-base' : ''}`}>
                  Unit of Measure:
                </Typography>
              </Grid>
              <Grid item sm={3}>
                <UoMDropDown
                  id="UoM"
                  name="UoM"
                  placeholder="Select UoM"
                  value={values.UoM}
                  onChange={(event: React.ChangeEvent<any>) => setUoM(event?.target?.value)}
                  error={touched?.UoM && Boolean(errors?.UoM)}
                  helperText={touched?.UoM && errors?.UoM}
                  fullWidth
                  displayEmpty
                  inputProps={{
                    id: 'deviceSource-native-select',
                    name: 'paymentMethod',
                  }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                  }}
                  className={classes.selectBox}
                />
              </Grid>
              <Grid item sm={1} />
              <Grid item sm={2} className="flex items-start">
                <Typography className={`pb-6 text-[#333333] ${isInAPContext ? '!text-base' : ''}`}>
                  UoM Description:
                </Typography>
              </Grid>
              <Grid item sm={3}>
                <Input
                  id="UoMDescription"
                  name="UoMDescription"
                  placeholder="UoM Description"
                  value={values?.UoMDescription?.trimStart()}
                  onChange={handleChange}
                  variant="standard"
                  containerProps={{
                    className: 'h-[27px]',
                  }}
                  className="!pt-0"
                  helperText={touched?.UoMDescription && errors?.UoMDescription ? errors?.UoMDescription : ''}
                  error={touched?.UoMDescription && Boolean(errors?.UoMDescription)}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={2} className="flex items-start">
                <Typography className={`pb-6 text-[#333333] ${isInAPContext ? '!text-base' : ''}`}>Barcode:</Typography>
              </Grid>
              <Grid item sm={3}>
                <Input
                  id="Barcode"
                  name="Barcode"
                  placeholder="Barcode"
                  value={values?.Barcode?.trimStart()}
                  onChange={handleChange}
                  variant="standard"
                  containerProps={{
                    className: 'h-[27px]',
                  }}
                  className="!pt-0"
                  helperText={touched?.Barcode && errors?.Barcode ? errors?.Barcode : ''}
                  error={touched?.Barcode && Boolean(errors?.Barcode)}
                />
              </Grid>
              <Grid item sm={1} />
              <Grid item sm={2} className="flex items-start">
                <Typography className={`pb-6 text-[#333333] ${isInAPContext ? '!text-base' : ''}`}>Type:</Typography>
              </Grid>
              <Grid item sm={3}>
                <InventoryTypeDropDown
                  className={classes.selectBox}
                  id="Type"
                  name="Type"
                  placeholder="Select Type"
                  value={values.Type}
                  onChange={handleChange}
                  error={touched?.Type && Boolean(errors?.Type)}
                  helperText={touched?.Type && errors?.Type}
                  fullWidth
                  displayEmpty
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={2} className="flex items-start">
                <Typography className={`pb-6 text-[#333333] ${isInAPContext ? '!text-base' : ''}`}>
                  Description:
                </Typography>
              </Grid>
              <Grid item sm={9}>
                <Input
                  id="Description"
                  name="Description"
                  placeholder="Description"
                  value={values?.Description?.trimStart()}
                  onChange={handleChange}
                  variant="standard"
                  containerProps={{
                    className: 'h-[27px]',
                  }}
                  className="!pt-0"
                  helperText={touched?.Description && errors?.Description ? errors?.Description : ''}
                  error={touched?.Description && Boolean(errors?.Description)}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={2} className="flex items-start">
                <Typography className={`pb-6 text-[#333333] ${isInAPContext ? '!text-base' : ''}`}>Brand:</Typography>
              </Grid>
              <Grid item sm={3}>
                <BrandAutocomplete
                  styles={{marginTop: '-1.5rem'}}
                  selected={values.Brand ? values.Brand : null}
                  addNewClick={handleAddNewBrand}
                  clearClick={() => setBrand(null)}
                  onSuggestionSelected={(e, selected) => setBrand(selected.suggestion)}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={2} className="flex items-start">
                <Typography className={`pb-6 text-[#333333] ${isInAPContext ? '!text-base' : ''}`}>
                  Categories:
                </Typography>
              </Grid>
              <Grid item sm={9}>
                <CategoryAutocomplete
                  styles={{marginTop: '-1.5rem'}}
                  multiple
                  multipleSelected={values.Categories}
                  selected={null}
                  addNewClick={handleAddNewCategory}
                  clearClick={() => setCategory(null)}
                  onSuggestionSelected={(e, selected) => setCategory(selected.suggestion)}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={2}></Grid>
              <Grid item sm={9} className="flex  flex-wrap items-start">
                {Array.isArray(values.Categories)
                  ? values.Categories.map(category => {
                      return (
                        <Chip
                          key={category.ID}
                          value={category.Name}
                          onClose={() => deleteCategory(category.ID)}
                          className="mr-2 mt-2 bg-primary-background text-base font-semibold text-primary"
                        />
                      );
                    })
                  : null}
              </Grid>
            </Grid>
            <Divider
              className="w-full"
              style={{height: '2px !important', backgroundColor: '#F1F1F1', margin: '20px 0px'}}
            />
            <Typography className={` text-xl font-medium text-[#4D4D4D]${isInAPContext ? '!text-lg' : ''} mb-4`}>
              Web Description
            </Typography>
            <Grid container>
              <Grid item sm={11}>
                <SRichTextEditor
                  placeholder="Product Description"
                  value={values.HTMLDescription}
                  handleChange={(value: string) => setFieldValue('HTMLDescription', value)}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {isInAPContext && (
          <Divider
            className="w-full"
            style={{height: '2px !important', backgroundColor: '#F1F1F1', margin: '20px 0px'}}
          />
        )}
        <Grid item sm={3} className="flex flex-col justify-start">
          <Typography className={`text-xl font-medium text-[#4D4D4D] ${isInAPContext ? 'mb-4 !text-lg' : ''}`}>
            Upload Media
          </Typography>
          <div className="custom-grid grid grid-cols-2 gap-2 rounded-[5px] border border-[#EAEAEA] bg-[#FAFAFA50] p-1">
            {Array.isArray(values.MediaFiles) && values.MediaFiles.length
              ? values.MediaFiles.map((image: FileDetails, index: number) => {
                  return (
                    <Image
                      key={`product-image-${index}`}
                      alt={image.Name}
                      src={image?.ThumbnailURL || ''}
                      className={`flex justify-center`}
                      onDelete={() => deleteMedia(image?.ID)}
                    />
                  );
                })
              : null}
            <div className="flex items-center justify-center rounded-[5px] bg-primary-header">
              <MediaUploader
                uploadClassName={`flex border border-none bg-transparent p-5 text-primary`}
                onChange={file => onMediaUpload(file)}
                icon={<AddRoundedIcon className="!text-5xl" />}
              ></MediaUploader>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

interface IMarginMarkup {
  markupPercent: number | string;
  marginPrice: number | string;
  marginPercent: number | string;
}

const PriceDetails = (
  props: FormikProps<AddInventoryValues> & {
    isInAPContext?: boolean;
    removeCustomPrice?: boolean;
    setShowRemoveCustomPrice?: React.Dispatch<React.SetStateAction<boolean>>;
    // addWarrantyDocuments: WarrantyFileType[] | null;
    // setAddWarrantyDocuments: React.Dispatch<React.SetStateAction<WarrantyFileType[] | null>>;
  },
) => {
  const [marginMarkup, setMarginMarkup] = useState<IMarginMarkup>({
    markupPercent: '',
    marginPrice: '',
    marginPercent: '',
  });
  const [openAccount, setOpenAccount] = useState<string>('');

  const {values, errors, handleChange, setFieldValue, isInAPContext, removeCustomPrice, setShowRemoveCustomPrice} =
    props;
  const hasVariantMasterID = values?.VariantMasterInventoryID !== null;
  const {getInventoryById} = useInventoryAPI();
  const dayKeys = Object.keys(ProductWarrantyPeriod) as (keyof typeof ProductWarrantyPeriod)[];
  const {QuoteV289367} = useFeatureFlags().tenantOwned();
  const {getSecureAttachment, isLoading} = useAttachmentsAPI();
  const [isRemoving, setIsRemoving] = useState(false);

  useEffect(() => {
    setNewMarginMarkup(values.CostPriceEx || 0, values.StandardSellPriceEx || 0);
  }, []);

  const handlePriceBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target) {
      switch (event.target.name) {
        case 'StandardSellPriceEx':
          setNewMarginMarkup(parseFloat(values.CostPriceEx + ''), parseFloat(event.target.value));
          setFieldValue(
            'StandardSellPriceInc',
            PriceCalculator.getSellIncFromEx(parseFloat(event.target.value), values.IsOrderTaxExempt ? 0 : 0.1),
          );
          break;
        case 'StandardSellPriceInc':
          const newSellEx = PriceCalculator.getSellExFromInc(
            parseFloat(event.target.value),
            values.IsOrderTaxExempt ? 0 : 0.1,
          );
          setNewMarginMarkup(parseFloat(values.CostPriceEx + ''), newSellEx);
          setFieldValue('StandardSellPriceEx', newSellEx);
          break;
        case 'CostPriceEx':
          setNewMarginMarkup(parseFloat(event.target.value), values.StandardSellPriceEx);
          setFieldValue('CostPriceEx', event.target.value);
          break;
      }
    }
  };

  const handleMarkupBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target) {
      let newSellEx;
      switch (event.target.name) {
        case 'markupPercent':
          newSellEx = PriceCalculator.getSellExByMarkupPercent(
            parseFloat(values.CostPriceEx + ''),
            parseFloat(event.target.value),
          );
          setNewSellPrice(newSellEx, 'markupPercent');
          break;
        case 'marginPrice':
          newSellEx = PriceCalculator.getSellExByMarginAmount(
            parseFloat(values.CostPriceEx + ''),
            parseFloat(event.target.value),
          );
          setNewSellPrice(newSellEx, 'marginPrice');
          break;
        case 'marginPercent':
          newSellEx = PriceCalculator.getSellExByMarginPercent(
            parseFloat(values.CostPriceEx + ''),
            parseFloat(event.target.value),
          );
          setNewSellPrice(newSellEx, 'marginPercent');
          break;
      }
    }
  };

  const handleMarginMarkupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target && /^\d+(\.\d{0,2})?$|^$/.test(event.target.value)) {
      setMarginMarkup({
        ...marginMarkup,
        [event.target.name]: event.target.value,
      });
    }
  };

  const setNewMarginMarkup = (cost: number, sell: number) => {
    setMarginMarkup({
      markupPercent: PriceCalculator.getMarkupPercent(cost, sell),
      marginPrice: PriceCalculator.getMarginAmount(cost, sell),
      marginPercent: PriceCalculator.getMarginPercent(cost, sell),
    });
  };

  const setNewSellPrice = (newSellEx: number, name: 'markupPercent' | 'marginPrice' | 'marginPercent') => {
    const newMarginMarkup = {
      markupPercent: PriceCalculator.getMarkupPercent(parseFloat(values.CostPriceEx + ''), newSellEx),
      marginPrice: PriceCalculator.getMarginAmount(parseFloat(values.CostPriceEx + ''), newSellEx),
      marginPercent: PriceCalculator.getMarginPercent(parseFloat(values.CostPriceEx + ''), newSellEx),
    } as IMarginMarkup;
    delete newMarginMarkup[name];
    setMarginMarkup({
      ...marginMarkup,
      ...newMarginMarkup,
    });
    setFieldValue('StandardSellPriceEx', newSellEx);
    setFieldValue(
      'StandardSellPriceInc',
      PriceCalculator.getSellIncFromEx(newSellEx, values.IsOrderTaxExempt ? 0 : 0.1),
    );
  };

  const handleTaxExempt = (checked: boolean) => {
    if (checked) {
      setFieldValue('StandardSellPriceInc', values.StandardSellPriceEx);
    } else {
      const newSellInc = PriceCalculator.getSellIncFromEx(parseFloat(values.StandardSellPriceEx + ''), 0.1);
      setFieldValue('StandardSellPriceInc', newSellInc);
      setNewMarginMarkup(parseFloat(values.CostPriceEx + ''), values.StandardSellPriceEx);
    }
  };

  const changeCheckbox = (
    name: 'IsSold' | 'IsPurchased' | 'IsOrderTaxExempt' | 'IsPublished' | 'IsApproved',
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.persist();
    props.setFieldValue(name, e.target.checked);
    props.setFieldTouched(name, true, false);
    if (name === 'IsOrderTaxExempt' && values.StandardSellPriceEx) {
      handleTaxExempt(e.target.checked);
    }
  };

  const setSupplier = (selected: Partial<ISupplier> | null) => {
    setFieldValue('DefaultSupplierID', selected?.ID || null);
    setFieldValue('DefaultSupplierName', selected?.Name || null);
  };

  const handleAddNewExpenseAccount = () => {
    setOpenAccount('Expense');
  };

  const handleAddNewRevenueAccount = () => {
    setOpenAccount('Revenue');
  };

  const setExpenseAccount = (selected: Partial<IAccounts> | null) => {
    setFieldValue('ExpenseAccount', selected?.Code || null);
  };

  const setRevenueAccount = (selected: Partial<IAccounts> | null) => {
    setFieldValue('RevenueAccount', selected?.Code || null);
  };

  const handleCloseAccount = (account?: IAccounts) => {
    const accountType = openAccount;
    if (account) {
      setFieldValue(accountType === 'Expense' ? 'ExpenseAccount' : 'RevenueAccount', account?.Code || null);
    }
    setOpenAccount('');
  };

  const handleFileChange = (files: FileList) => {
    let isNotAllowed = false;
    if (files && files.length > 0) {
      const newWarrantyMediaFiles = Array.from(files).map(file => {
        // if file type is not pdf, return
        if (file.type !== AttachmentType.pdf) {
          // stop the loop and return from the function
          isNotAllowed = true;
          return;
        }
        return {
          file,
          isDeleted: false,
          WarrantMediaFileName: file.name,
        };
      });

      if (isNotAllowed) {
        Toast.error('Only PDF files are allowed');
        return;
      }

      setFieldValue(
        'WarrantyMediaFiles',
        values.WarrantyMediaFiles ? [...values.WarrantyMediaFiles, ...newWarrantyMediaFiles] : newWarrantyMediaFiles,
      );
    }
  };

  const UploadedWarrantyShow = ({
    file,
    guid,
    name,
    onDelete,
  }: {
    name: string;
    guid?: string;
    onDelete: () => void;
    file?: File;
  }) => {
    const createDownloadLink = (url: string) => {
      const link = document.createElement('a');
      link.href = url;
      link.download = name;
      link.click();
    };

    const onClickForDownload = async () => {
      if (isLoading) return;

      if (guid) {
        const bufferArray = await getSecureAttachment(AttachmentTypeEnum.InventoryWarranty, guid);
        const blob = new Blob([bufferArray], {type: AttachmentType.pdf});
        const createdFile = new File([blob], name, {
          type: AttachmentType[fileExtension(name) as keyof typeof AttachmentType],
        });
        const url = URL.createObjectURL(createdFile);
        // download file
        createDownloadLink(url);
      } else {
        const url = URL.createObjectURL(file!);
        createDownloadLink(url);
      }
    };

    return (
      <div
        className={`flex w-[439px] flex-col overflow-hidden rounded-2xl border border-[#ECECEC] bg-white px-2.5 pb-2 shadow-[0_0_6px_0_rgba(211,229,239,1)]`}
      >
        <div className="relative flex w-full flex-grow items-center justify-between">
          <p
            title={name}
            className={`w-full cursor-pointer truncate font-normal text-black-800`}
            data-autoid={`txt${name}`}
            onClick={onClickForDownload}
          >
            {name}
          </p>
          {isLoading && <LoadingIndicator isLoading={true} size={'sm'} />}
          <IconButton
            className="cursor-pointer active:bg-transparent"
            ripple={false}
            variant="text"
            onClick={onDelete}
            name={`Delete${name}`}
          >
            <WidgetDeleteLogo />
          </IconButton>
        </div>
        <Progress size="sm" value={100} color={'success'} />
      </div>
    );
  };

  const deleteWarrantyFile = async (index: number, guid?: string) => {
    if (guid) {
      setFieldValue(
        'WarrantyMediaFiles',
        values.WarrantyMediaFiles?.map((file, i) => (i === index ? {...file, isDeleted: true} : file)),
      );
    } else {
      setFieldValue(
        'WarrantyMediaFiles',
        values.WarrantyMediaFiles?.filter((_, i) => i !== index),
      );
    }
  };

  const handleRemoveCustomPrice = async () => {
    setIsRemoving(true);
    if (values.VariantMasterInventoryID) {
      const masterItem = await getInventoryById(values.VariantMasterInventoryID.toString());
      if (masterItem) {
        setFieldValue('StandardSellPriceEx', masterItem.StandardSellPriceEx);
        setFieldValue('StandardSellPriceInc', masterItem.StandardSellPriceInc);
        setFieldValue('CostPriceEx', masterItem.CostPriceEx);
      }
    }
    setIsRemoving(false);
    setShowRemoveCustomPrice?.(false);
  };
  return (
    <>
      <AccountDialog
        account={{...initialAccountData, Class: openAccount}}
        open={Boolean(openAccount)}
        onClose={handleCloseAccount}
      />
      <Grid container className="h-full items-start justify-start overflow-y-auto py-5 pr-2">
        <Collapse open={removeCustomPrice ?? false}>
          <div className="mb-4 flex w-[610px] justify-between rounded-lg bg-spenda-bckgrndBlue px-4 py-6">
            <Typography variant="paragraph">This variant item has its own custom pricing</Typography>
            <Typography
              className="cursor-pointer text-base font-semibold text-primary"
              onClick={handleRemoveCustomPrice}
            >
              {isRemoving ? 'Removing...' : 'Remove Custom Pricing'}
            </Typography>
          </div>
        </Collapse>
        <Grid container alignItems="center">
          <Grid item sm={3} className={`flex ${isInAPContext ? 'items-center' : 'items-start'}`}>
            <Typography className={`text-xl font-medium text-[#4D4D4D] ${isInAPContext ? '!text-lg' : ''}`}>
              I Sell This Product
            </Typography>
          </Grid>
          <Grid item sm={1}>
            <Switch
              id="IsSold"
              name="IsSold"
              disabled={hasVariantMasterID || values.IsVariantMaster}
              ripple={false}
              checked={values.IsVariantMaster ? false : values.IsSold}
              className="h-full w-full checked:bg-success"
              onChange={changeCheckbox.bind(null, 'IsSold')}
              containerProps={{
                className: 'w-11 h-6',
              }}
              circleProps={{
                className: 'before:hidden left-0.5 border-none',
              }}
            />
          </Grid>
        </Grid>
        {values.IsSold || values.IsVariantMaster ? (
          <>
            <div
              className={`${isInAPContext ? 'mr-3' : ''} my-5 flex w-full flex-col border border-[#EAEAEA] bg-[#F6F6F6]`}
            >
              <Grid container className="px-3 py-5">
                <Grid item sm={isInAPContext ? 2 : 1} className="flex items-start">
                  <Typography className={`pb-6 text-[#333333] ${isInAPContext ? '!text-base' : ''}`}>
                    Last Cost Ex:
                  </Typography>
                </Grid>
                <Grid item sm={isInAPContext ? 3 : 2}>
                  <STextField
                    type="number"
                    id="LastBuyPriceEx"
                    name="LastBuyPriceEx"
                    placeholder="0.00"
                    value={values.LastBuyPriceEx}
                    onChange={handleChange}
                    onBlur={handlePriceBlur}
                    fullWidth
                    inputProps={{style: {fontFamily: isInAPContext ? 'poppins' : 'Saira Condensed'}}}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoneyRoundedIcon className="!text-lg !font-semibold text-[#444343]" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item sm={1} />
                <Grid item sm={isInAPContext ? 2 : 1} className="flex items-start">
                  <Typography className={`pb-6 text-[#333333] ${isInAPContext ? '!text-base' : ''}`}>
                    Cost Ex:
                  </Typography>
                </Grid>
                <Grid item sm={isInAPContext ? 3 : 2}>
                  <STextField
                    type="number"
                    id="CostPriceEx"
                    name="CostPriceEx"
                    placeholder="0.00"
                    value={values.CostPriceEx}
                    onChange={e => {
                      if (/^\d+(\.\d{0,2})?$|^$/.test(e.target.value)) handleChange(e);
                    }}
                    onBlur={handlePriceBlur}
                    fullWidth
                    inputProps={{style: {fontFamily: isInAPContext ? 'poppins' : 'Saira Condensed'}}}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoneyRoundedIcon className="!text-lg !font-semibold text-[#444343]" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item sm={1} />
                <Grid item sm={isInAPContext ? 2 : 1} className="flex items-start">
                  <Typography className={`pb-6 text-[#333333] ${isInAPContext ? '!text-base' : ''}`}>
                    Sell Price Ex:
                  </Typography>
                </Grid>
                <Grid item sm={isInAPContext ? 3 : 2}>
                  <STextField
                    type="number"
                    id="StandardSellPriceEx"
                    name="StandardSellPriceEx"
                    placeholder="0.00"
                    value={values.StandardSellPriceEx}
                    onChange={e => {
                      if (/^\d+(\.\d{0,2})?$|^$/.test(e.target.value)) handleChange(e);
                    }}
                    onBlur={handlePriceBlur}
                    fullWidth
                    inputProps={{style: {fontFamily: isInAPContext ? 'poppins' : 'Saira Condensed'}}}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoneyRoundedIcon className="!text-lg !font-semibold text-[#444343]" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container className="py-4">
                <Grid item sm={12} className="flex items-center justify-center pb-2">
                  <p className={`text-xl font-medium text-[#666666] opacity-40 ${isInAPContext ? '!text-lg' : ''}`}>
                    Markup/Margin Calculator
                  </p>
                </Grid>
                <Grid item sm={3} />
                <Grid item sm={2}>
                  <Typography
                    className={`py-2 text-center text-xl font-medium text-primary  ${isInAPContext ? '!text-base' : ''}`}
                  >
                    Markup
                  </Typography>
                  <STextField
                    variant="outlined"
                    type="number"
                    id="markupPercent"
                    name="markupPercent"
                    placeholder="0.00"
                    value={marginMarkup.markupPercent}
                    onChange={handleMarginMarkupChange}
                    onBlur={handleMarkupBlur}
                    fullWidth
                    inputProps={{
                      style: {textAlign: 'center', fontFamily: isInAPContext ? 'poppins' : 'Saira Condensed'},
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span className=" !text-xl !font-semibold text-[#444343]">%</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item sm={1} />
                <Grid item sm={3}>
                  <Typography
                    className={`py-2 text-center text-xl font-medium text-primary ${isInAPContext ? '!text-base' : ''}`}
                  >
                    Margin
                  </Typography>
                  <div className="flex flex-row gap-x-0.5">
                    <STextField
                      variant="outlined"
                      type="number"
                      id="marginPrice"
                      name="marginPrice"
                      placeholder="0.00"
                      value={marginMarkup.marginPrice}
                      onChange={handleMarginMarkupChange}
                      onBlur={handleMarkupBlur}
                      fullWidth
                      inputProps={{
                        style: {textAlign: 'center', fontFamily: isInAPContext ? 'poppins' : 'Saira Condensed'},
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AttachMoneyRoundedIcon className="!text-lg !font-semibold text-[#444343]" />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <STextField
                      variant="outlined"
                      type="number"
                      id="marginPercent"
                      name="marginPercent"
                      placeholder="0.00"
                      value={marginMarkup.marginPercent}
                      onChange={handleMarginMarkupChange}
                      onBlur={handleMarkupBlur}
                      fullWidth
                      inputProps={{
                        style: {textAlign: 'center', fontFamily: isInAPContext ? 'poppins' : 'Saira Condensed'},
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <span className=" !text-xl !font-semibold text-[#444343]">%</span>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </Grid>
                <Grid item sm={3} />
              </Grid>
            </div>
            <Grid container className="py-5">
              <Grid item sm={isInAPContext ? 2 : 1} className="flex items-start">
                <Typography className={`pb-6 text-[#333333] ${isInAPContext ? '!text-base' : ''}`}>RRP Inc:</Typography>
              </Grid>
              <Grid item sm={isInAPContext ? 3 : 2}>
                <STextField
                  type="number"
                  id="RRP"
                  name="RRP"
                  placeholder="0.00"
                  value={values.RRP}
                  onChange={e => {
                    if (/^\d+(\.\d{0,2})?$|^$/.test(e.target.value)) handleChange(e);
                  }}
                  onBlur={handlePriceBlur}
                  fullWidth
                  inputProps={{style: {fontFamily: isInAPContext ? 'poppins' : 'Saira Condensed'}}}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyRoundedIcon className="!text-lg !font-semibold text-[#444343]" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item sm={1} />
              <Grid item sm={isInAPContext ? 2 : 1} className="flex items-start">
                <Typography className={`pb-6 text-[#333333] ${isInAPContext ? '!text-base' : ''}`}>
                  Sell Price Inc:
                </Typography>
              </Grid>
              <Grid item sm={isInAPContext ? 3 : 2}>
                <STextField
                  type="number"
                  id="StandardSellPriceInc"
                  name="StandardSellPriceInc"
                  placeholder="0.00"
                  value={values.StandardSellPriceInc}
                  onChange={e => {
                    if (/^\d+(\.\d{0,2})?$|^$/.test(e.target.value)) handleChange(e);
                  }}
                  onBlur={handlePriceBlur}
                  fullWidth
                  inputProps={{style: {fontFamily: isInAPContext ? 'poppins' : 'Saira Condensed'}}}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyRoundedIcon className="!text-lg !font-semibold text-[#444343]" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <FormGroup className="w-full">
              <Checkbox
                ripple={false}
                disabled={Boolean(!values.ID)}
                name="IsOrderTaxExempt"
                className="h-4 w-4 border-primary bg-white transition-all checked:border-primary checked:bg-primary hover:before:opacity-0"
                label="Is Tax Exempt"
                checked={values.IsOrderTaxExempt}
                onChange={changeCheckbox.bind(null, 'IsOrderTaxExempt')}
              />
              <Checkbox
                ripple={false}
                name="IsPublished"
                className="h-4 w-4 border-primary bg-white transition-all checked:border-primary checked:bg-primary hover:before:opacity-0"
                label="I publish this product on B2B e-commerce"
                checked={values.IsPublished}
                onChange={changeCheckbox.bind(null, 'IsPublished')}
              />
              <Checkbox
                ripple={false}
                name=""
                className="h-4 w-4 border-primary bg-white transition-all checked:border-primary checked:bg-primary hover:before:opacity-0"
                label="I publish this product on third party e-commerce"
                checked={values.IsApproved}
                onChange={changeCheckbox.bind(null, 'IsApproved')}
              />
            </FormGroup>
            {QuoteV289367 && (
              <>
                <div className="mb-2 h-1 w-full border-b border-b-[#ECECEC]" />
                <div className="my-5 flex w-full flex-col">
                  <Typography className="text-lg font-medium text-black-800">Warranty details</Typography>
                  <div className="my-4  flex w-full flex-wrap items-center justify-start gap-5">
                    <div className="w-full md:w-[283px]">
                      <Select
                        id="WarrantyPeriodType"
                        name="WarrantyPeriodType"
                        label="Warranty period"
                        className="h-[45px]"
                        value={values?.WarrantyPeriodType?.toString()}
                        onChange={value => {
                          setFieldValue('WarrantyPeriodType', value);
                          setFieldValue('WarrantyPeriod', '');
                        }}
                      >
                        {dayKeys.map(period => (
                          <Option
                            key={period}
                            data-autoid={`option${upperFirst(camelCase(period))}`}
                            value={ProductWarrantyPeriod[period]}
                          >
                            {period === 'NONE' ? 'Select Warranty Period' : ProductWarrantyPeriod[period]}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    {values.WarrantyPeriodType &&
                      values.WarrantyPeriodType !== ProductWarrantyPeriod.LIFETIME &&
                      values.WarrantyPeriodType !== ProductWarrantyPeriod.NONE && (
                        <div className="w-full md:w-[140px]">
                          <Input
                            name="WarrantyPeriod"
                            id="WarrantyPeriod"
                            type="number"
                            data-autoid="txtNumberOfDays"
                            min={1}
                            disabled={
                              values.WarrantyPeriodType === ProductWarrantyPeriod.LIFETIME ||
                              !values.WarrantyPeriodType ||
                              values.WarrantyPeriodType === ProductWarrantyPeriod.NONE
                            }
                            label={
                              values.WarrantyPeriodType &&
                              values.WarrantyPeriodType !== ProductWarrantyPeriod.NONE &&
                              values.WarrantyPeriodType !== ProductWarrantyPeriod.LIFETIME
                                ? values.WarrantyPeriodType
                                : undefined
                            }
                            error={Boolean(errors.WarrantyPeriod)}
                            containerProps={{className: '!min-w-[140px]'}}
                            onChange={e => {
                              const value = e.target.value;
                              if (value === '') {
                                setFieldValue('WarrantyPeriod', '');
                                return;
                              }
                              const numericValue = parseInt(value, 10);
                              if (numericValue > 0) {
                                setFieldValue('WarrantyPeriod', numericValue);
                              }
                            }}
                            value={values.WarrantyPeriod}
                          />
                        </div>
                      )}
                  </div>
                  <div className="flex flex-col items-start gap-6 pt-5 md:flex-row">
                    <div className="w-[443px]">
                      <Textarea
                        name="warrantyDetails"
                        id="warrantyDetails"
                        label="Warranty details"
                        data-autoid="txtWarrantyDetails"
                        value={values.WarrantyDetails}
                        onChange={e => {
                          setFieldValue('WarrantyDetails', e.target.value);
                        }}
                        displayLength
                        maxLength={200}
                        className="min-h-[122px]"
                      />
                    </div>
                    <Upload
                      className="h-[122px] w-full text-sm font-normal text-black-800 md:max-w-[463px]"
                      label="Please drop your warranty documents here or click to upload"
                      onChange={handleFileChange}
                      data-autoid="txtUploadWarrantyDocument"
                    />
                  </div>
                  <div className="mt-8 flex w-full flex-col gap-y-2.5 md:max-w-[463px]">
                    {values.WarrantyMediaFiles?.map((warrantyMediaFile, index) => {
                      if (warrantyMediaFile.isDeleted) return null;
                      return (
                        <UploadedWarrantyShow
                          key={warrantyMediaFile.WarrantyMediaFileID ?? 'newUpload' + index}
                          file={warrantyMediaFile?.file}
                          guid={warrantyMediaFile?.WarrantyMediaFileGUID}
                          name={warrantyMediaFile.WarrantMediaFileName}
                          onDelete={() => deleteWarrantyFile(index, warrantyMediaFile?.WarrantyMediaFileGUID)}
                        />
                      );
                    })}
                    {/* {addWarrantyDocuments?.map((addWarrantyDocument, index) => (
                      <UploadedWarrantyShow
                        key={index}
                        uri={addWarrantyDocument.uri}
                        name={addWarrantyDocument.file.name || 'warranty.pdf'}
                        onDelete={() =>
                          addWarrantyDocument.guid
                            ? deleteWarrantyFile(addWarrantyDocument.guid)
                            : setAddWarrantyDocuments(prev => prev?.splice(index, 1) ?? [])
                        }
                      />
                    ))} */}
                  </div>
                </div>
              </>
            )}
          </>
        ) : null}
        <Divider
          className="w-full"
          style={{height: '2px !important', backgroundColor: '#F1F1F1', margin: '20px 0px'}}
        />

        <Grid container alignItems="center">
          <Grid item sm={3} className={`flex ${isInAPContext ? 'items-center' : 'items-start'}`}>
            <Typography className={`pb-6 text-[#333333]  ${isInAPContext ? '!text-lg' : ''}`}>
              I Purchase This Product
            </Typography>
          </Grid>
          <Grid item sm={1}>
            <Switch
              id="IsPurchased"
              name="IsPurchased"
              disabled={hasVariantMasterID || values.IsVariantMaster}
              ripple={false}
              checked={values.IsVariantMaster ? false : values.IsPurchased}
              onChange={changeCheckbox.bind(null, 'IsPurchased')}
              className="h-full w-full checked:bg-success"
              circleProps={{
                className: 'before:hidden left-0.5 border-none',
              }}
              containerProps={{
                className: 'w-11 h-6',
              }}
            />
          </Grid>
        </Grid>
        {values.IsPurchased || values.IsVariantMaster ? (
          <>
            <Grid container className="py-5">
              <Grid item sm={isInAPContext ? 2 : 1} className="flex items-start">
                <Typography className={`pb-6 text-[#333333] ${isInAPContext ? '!text-base' : ''}`}>
                  Cost Price Ex:
                </Typography>
              </Grid>
              <Grid item sm={isInAPContext ? 2 : 3}>
                <STextField
                  type="number"
                  id="CostPriceEx"
                  name="CostPriceEx"
                  placeholder="0.00"
                  value={values.CostPriceEx}
                  onChange={handleChange}
                  fullWidth
                  inputProps={{style: {fontFamily: isInAPContext ? 'poppins' : 'Saira Condensed'}}}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyRoundedIcon className="!text-lg !font-semibold text-[#444343]" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item sm={1} />
              <Grid item sm={isInAPContext ? 2 : 1} className="flex items-start">
                <Typography className={`pb-6 text-[#333333] ${isInAPContext ? '!text-base' : ''}`}>
                  Supplier:
                </Typography>
              </Grid>
              <Grid item sm={isInAPContext ? 3 : 2}>
                <SupplierAutocomplete
                  styles={{marginTop: '-1.5rem'}}
                  selected={
                    values.DefaultSupplierID && values.DefaultSupplierName
                      ? {
                          ID: values.DefaultSupplierID,
                          Name: values.DefaultSupplierName,
                        }
                      : null
                  }
                  clearClick={() => setSupplier(null)}
                  onSuggestionSelected={(e, selected) => setSupplier(selected.suggestion)}
                />
              </Grid>
            </Grid>
          </>
        ) : null}
        <Divider
          className="w-full"
          style={{height: '2px !important', backgroundColor: '#F1F1F1', margin: '20px 0px'}}
        />
        <Typography className={`text-xl font-medium text-[#4D4D4D] ${isInAPContext ? 'mb-4 !text-lg' : ''}`}>
          Accounting Details
        </Typography>
        <Grid container>
          <Grid item sm={isInAPContext ? 2 : 1} className="flex items-start">
            <Typography className={`pb-6 text-[#333333] ${isInAPContext ? '!text-base' : ''}`}>
              Expense Account:
            </Typography>
          </Grid>
          <Grid item sm={isInAPContext ? 2 : 3}>
            <AccountsAutocomplete
              styles={{marginTop: '-1.5rem'}}
              additionalFilter={{
                'Types[]': ['Expense', 'Direct_Costs'],
              }}
              selected={
                values.ExpenseAccount
                  ? {
                      Code: values.ExpenseAccount,
                    }
                  : null
              }
              clearClick={() => setExpenseAccount(null)}
              addNewClick={handleAddNewExpenseAccount}
              onSuggestionSelected={(e, selected) => setExpenseAccount(selected.suggestion)}
            />
          </Grid>
          <Grid item sm={1} />
          <Grid item sm={isInAPContext ? 2 : 1} className="flex items-start">
            <Typography className={`pb-6 text-[#333333] ${isInAPContext ? '!text-base' : ''}`}>
              Revenue Account:
            </Typography>
          </Grid>
          <Grid item sm={isInAPContext ? 3 : 2}>
            <AccountsAutocomplete
              styles={{marginTop: '-1.5rem'}}
              additionalFilter={{
                'Types[]': ['Revenue', 'Sales'],
              }}
              selected={
                values.RevenueAccount
                  ? {
                      Code: values.RevenueAccount,
                    }
                  : null
              }
              clearClick={() => setRevenueAccount(null)}
              addNewClick={handleAddNewRevenueAccount}
              onSuggestionSelected={(e, selected) => setRevenueAccount(selected.suggestion)}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const WarehouseDetails = (
  props: FormikProps<AddInventoryValues> & {
    inventorySOH?: InventorySOH;
    assetAccount: IAccounts | undefined;
    isInAPContext?: boolean;
  },
) => {
  const {values, touched, errors, inventorySOH, assetAccount, handleBlur, handleChange, setFieldValue, isInAPContext} =
    props;

  const changeCheckbox = (name: 'IsPhysical' | 'IsSOHTracked', e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    props.setFieldValue(name, e.target.checked);
    props.setFieldTouched(name, true, false);
  };

  const setAssetAccount = (selected: Partial<IAccounts> | null) => {
    setFieldValue('AssetAccount', selected?.Code || null);
  };

  return (
    <Grid container className="h-full items-start justify-start overflow-y-auto py-5 pr-2">
      <Grid container alignItems="center">
        <Grid item sm={3} className={`${isInAPContext ? 'items-center' : 'items-start'} flex `}>
          <Typography className={`text-xl font-medium text-[#4D4D4D] ${isInAPContext ? '!text-lg' : ''}`}>
            Is Physical Item
          </Typography>
        </Grid>
        <Grid item sm={1}>
          <Switch
            id="IsPhysical"
            name="IsPhysical"
            ripple={false}
            checked={values.IsPhysical}
            onChange={changeCheckbox.bind(null, 'IsPhysical')}
            className="h-full w-full checked:bg-success"
            containerProps={{
              className: 'w-11 h-6',
            }}
          />
        </Grid>
      </Grid>
      {values.IsPhysical ? (
        <>
          <Grid container className="py-5">
            <Grid item sm={1} className="flex items-start">
              <Typography className={`pb-6 text-[#333333] ${isInAPContext ? '!text-base' : ''}`}>Width:</Typography>
            </Grid>
            <Grid item sm={2}>
              <STextField
                type="number"
                id="Width_m"
                name="Width_m"
                placeholder="0.000"
                value={values.Width_m}
                onChange={handleChange}
                helperText={touched?.Width_m && (errors?.Width_m as string)}
                error={touched?.Width_m && Boolean(errors?.Width_m)}
                fullWidth
                inputProps={{style: {fontFamily: isInAPContext ? 'poppins' : 'Saira Condensed'}}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <span className="font-sairaCondensed text-[1.25rem] font-semibold text-[#444343]">m</span>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item sm={1} />
            <Grid item sm={1} className="flex items-start">
              <Typography className={`pb-6 text-[#333333] ${isInAPContext ? '!text-base' : ''}`}>Length:</Typography>
            </Grid>
            <Grid item sm={2}>
              <STextField
                type="number"
                id="Length_m"
                name="Length_m"
                placeholder="0.000"
                value={values.Length_m}
                onChange={handleChange}
                helperText={touched?.Length_m && (errors?.Length_m as string)}
                error={touched?.Length_m && Boolean(errors?.Length_m)}
                fullWidth
                inputProps={{style: {fontFamily: isInAPContext ? 'poppins' : 'Saira Condensed'}}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <span className="font-sairaCondensed text-[1.25rem] font-semibold text-[#444343]">m</span>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item sm={1} />
            <Grid item sm={1} className="flex items-start">
              <Typography className={`pb-6 text-[#333333] ${isInAPContext ? '!text-base' : ''}`}>Depth:</Typography>
            </Grid>
            <Grid item sm={2}>
              <STextField
                type="number"
                id="Depth_m"
                name="Depth_m"
                placeholder="0.000"
                value={values.Depth_m}
                onChange={handleChange}
                helperText={touched?.Depth_m && (errors?.Depth_m as string)}
                error={touched?.Depth_m && Boolean(errors?.Depth_m)}
                fullWidth
                inputProps={{style: {fontFamily: isInAPContext ? 'poppins' : 'Saira Condensed'}}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <span className="font-sairaCondensed text-[1.25rem] font-semibold text-[#444343]">m</span>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container className="py-5">
            <Grid item sm={1} className="flex items-start">
              <Typography className={`pb-6 text-[#333333] ${isInAPContext ? '!text-base' : ''}`}>Weight:</Typography>
            </Grid>
            <Grid item sm={2}>
              <STextField
                type="number"
                id="Weight_kg"
                name="Weight_kg"
                placeholder="0.000"
                value={values.Weight_kg}
                onChange={handleChange}
                helperText={touched?.Weight_kg && (errors?.Weight_kg as string)}
                error={touched?.Weight_kg && Boolean(errors?.Weight_kg)}
                fullWidth
                inputProps={{style: {fontFamily: isInAPContext ? 'poppins' : 'Saira Condensed'}}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <span className="font-sairaCondensed text-[1.25rem] font-semibold text-[#444343]">kg</span>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item sm={1} />
            <Grid item sm={1} className="flex items-start">
              <Typography className={`pb-6 text-[#333333] ${isInAPContext ? '!text-base' : ''}`}>Volume:</Typography>
            </Grid>
            <Grid item sm={2}>
              <STextField
                type="number"
                id="Volume"
                name="Volume"
                placeholder="0.000"
                value={values.Volume}
                onChange={handleChange}
                helperText={touched?.Volume && (errors?.Volume as string)}
                error={touched?.Volume && Boolean(errors?.Volume)}
                fullWidth
                inputProps={{style: {fontFamily: isInAPContext ? 'poppins' : 'Saira Condensed'}}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <span className="font-sairaCondensed text-[1.25rem] font-semibold text-[#444343]">
                        m<sup>3</sup>
                      </span>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={6} className="!mb-2 flex items-start">
              <Checkbox
                ripple={false}
                name="IsSOHTracked"
                label="I Track SOH for this product"
                className="h-4 w-4 border-primary bg-white transition-all checked:border-primary checked:bg-primary hover:before:opacity-0"
                checked={values.IsSOHTracked}
                onChange={changeCheckbox.bind(null, 'IsSOHTracked')}
              />
            </Grid>
          </Grid>
          {values.IsSOHTracked ? (
            <Grid container>
              <Grid item sm={isInAPContext ? 2 : 1} className="flex items-start">
                <Typography className={`pb-6 text-[#333333] ${isInAPContext ? '!text-base' : ''}`}>
                  Asset Account:
                </Typography>
              </Grid>
              <Grid item sm={isInAPContext ? 3 : 2}>
                <AccountsAutocomplete
                  additionalFilter={{
                    'Types[]': ['Inventory_Asset'],
                  }}
                  selected={
                    values.AssetAccount
                      ? {
                          Code: values.AssetAccount,
                          Name: values.AssetAccount === assetAccount?.Code ? assetAccount?.Name : '',
                        }
                      : null
                  }
                  clearClick={() => setAssetAccount(null)}
                  onSuggestionSelected={(e, selected) => setAssetAccount(selected.suggestion)}
                />
              </Grid>
            </Grid>
          ) : null}
        </>
      ) : null}
      {values.IsSOHTracked && values.ID && inventorySOH ? (
        <Grid
          container
          className="ml-[-25px] mr-[-25px] mt-[5px] !w-[calc(100%_+_50px)] border border-[#EAEAEA] bg-[#EAEFF2]"
        >
          <Grid item sm={12} className="flex items-start">
            <Typography className={`ml-3 p-5 text-xl font-medium text-[#4D4D4D]`}>
              Detail Inventory SOH Level For {values.ShortDescription}
            </Typography>
          </Grid>
          <Grid item sm={12} className={`flex items-start p-2`}>
            <TableContainer className="rounded-md border border-[#E1E1E1] bg-[#FFFFFF]">
              <Table className="min-w-[650]" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      className="relative after:absolute after:left-0 after:h-[70%] after:border-r after:border-[#97979730] after:content-['']"
                    >
                      <Box className="flex flex-col items-center">
                        <Box className="flex w-full flex-row items-center justify-evenly">
                          <IconHome />
                          <IconTruck />
                        </Box>
                        <Typography className="text-center text-base font-medium text-[#999999]">
                          Total Warehouse
                          <br />
                        </Typography>
                        <Box className="flex w-full flex-row items-center justify-evenly">
                          <Typography className="text-center text-lg font-medium text-[#444343]">
                            {inventorySOH.TotalPhysicalWarehouses}
                          </Typography>
                          <Typography className="text-center text-lg font-medium text-[#444343]">
                            {inventorySOH.TotalMobileWarehouses}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Box className="flex flex-col items-center">
                        <IconMinStock />
                        <Typography className="text-center text-base font-medium text-[#999999]">
                          Min Stock Level
                          <br />
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Box className="flex flex-col items-center">
                        <IconMaxStock />
                        <Typography className="text-center text-base font-medium text-[#999999]">
                          Max Stock Level
                          <br />
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Box className="flex flex-col items-center">
                        <IconStockOnOrder />
                        <Typography className="text-center text-base font-medium text-[#999999]">
                          Total Stock On Order
                          <br />
                          (Purchase Orders)
                        </Typography>
                        <Typography className="text-center text-lg font-medium text-[#444343]">
                          {inventorySOH.TotalStockOnOrder}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Box className="flex flex-col items-center">
                        <IconStockTransfer />
                        <Typography className="text-center text-base font-medium text-[#999999]">
                          Total Stock In Transit
                          <br />
                          (Stock Transfer)
                        </Typography>
                        <Typography className="text-center text-lg font-medium text-[#444343]">
                          {inventorySOH.TotalStockInTransit}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Box className="flex flex-col items-center">
                        <Typography className="text-center text-base font-medium text-[#999999]">
                          Total Stock On Hand
                          <br />
                          (SOH)
                        </Typography>
                        <Typography className="text-center text-lg font-medium text-[#444343]">
                          {inventorySOH.TotalSOH}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <div className="flex flex-col items-center">
                        <IconStockCommited />
                        <Typography className="text-center text-base font-medium text-[#999999]">
                          Total Committed Stock
                          <br />
                          (Sold)
                        </Typography>
                        <Typography className="text-center text-lg font-medium text-[#444343]">
                          {inventorySOH.TotalStockCommitted}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="flex flex-col items-center">
                        <IconStockAvailable />
                        <Typography className="text-center text-base font-medium text-[#999999]">
                          Total Stock Available
                          <br />
                          (SOH - Sold = Available)
                        </Typography>
                        <Typography className="text-center text-lg font-medium text-[#444343]">
                          {inventorySOH.TotalStockAvailable}
                        </Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inventorySOH.StockOnHand.map((sohValue: IStockOnHand, index) => (
                    <TableRow key={`Inventory-Row-${index}`}>
                      <TableCell component="th" scope="row">
                        <Typography className="text-base font-medium text-[#999999]">
                          <b className="text-[#444343]">Name:</b> {sohValue.WarehouseName}
                        </Typography>
                        <Typography className="text-base font-medium text-[#999999]">
                          <b className="text-[#444343]">Loc:</b>{' '}
                          {Array.isArray(sohValue.Locations) ? sohValue.Locations.map(loc => loc.Name).join(', ') : '-'}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" style={{color: '#4D4D4D', fontSize: '16px'}}>
                        <STextField
                          id={`MinMaxLevels[${index}].MinSOHQuantity`}
                          name={`MinMaxLevels[${index}].MinSOHQuantity`}
                          style={{paddingBottom: '0'}}
                          value={getIn(values, `MinMaxLevels[${index}].MinSOHQuantity`) || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="number"
                          placeholder="0.00"
                          helperText={
                            getIn(touched, `MinMaxLevels[${index}].MinSOHQuantity`) &&
                            getIn(errors, `MinMaxLevels[${index}].MinSOHQuantity`)
                          }
                          error={
                            getIn(touched, `MinMaxLevels[${index}].MinSOHQuantity`) &&
                            Boolean(getIn(errors, `MinMaxLevels[${index}].MinSOHQuantity`))
                          }
                        />
                      </TableCell>
                      <TableCell align="center" style={{color: '#4D4D4D', fontSize: '16px'}}>
                        <STextField
                          id={`MinMaxLevels[${index}].MaxSOHQuantity`}
                          name={`MinMaxLevels[${index}].MaxSOHQuantity`}
                          style={{paddingBottom: '0'}}
                          value={getIn(values, `MinMaxLevels[${index}].MaxSOHQuantity`) || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="number"
                          placeholder="0.00"
                          helperText={
                            getIn(touched, `MinMaxLevels[${index}].MaxSOHQuantity`) &&
                            getIn(errors, `MinMaxLevels[${index}].MaxSOHQuantity`)
                          }
                          error={
                            getIn(touched, `MinMaxLevels[${index}].MaxSOHQuantity`) &&
                            Boolean(getIn(errors, `MinMaxLevels[${index}].MaxSOHQuantity`))
                          }
                        />
                      </TableCell>
                      <TableCell className=" text-center text-lg text-[#4D4D4D] ">
                        {sohValue.TotalStockOnOrder || 0.0}
                      </TableCell>
                      <TableCell className=" text-center text-lg text-[#4D4D4D] ">
                        {sohValue.TotalStockInTransit || 0.0}
                      </TableCell>
                      <TableCell className=" text-center text-lg text-[#4D4D4D] ">{sohValue.TotalSOH || 0.0}</TableCell>
                      <TableCell className=" text-center text-lg text-[#4D4D4D] ">
                        {sohValue.TotalStockCommitted || 0.0}
                      </TableCell>
                      <TableCell className=" text-center text-lg text-[#4D4D4D] ">
                        {sohValue.TotalStockAvailable || 0.0}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};
