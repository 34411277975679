import useHttp from '../hooks/useHttp';
import {
  IFeatureFlagsState,
  IFlagList,
  IFlagTypes,
  IGenericResponseAPIM,
  ILDFeature,
  ILDKeyValue,
  ISupplierFeatureFlag,
} from '../model/feature-flags/FeatureFlags';
import {Toast} from '../utils/Toast';

export const useFeatureFlagsAPI = () => {
  const {GET, POST} = useHttp();

  const listFeatureFlags = async (): Promise<IFlagList> => {
    return GET(`FeatureFlags/FeatureFlags`);
  };

  const setFeatureFlag = (flagKey: string, value: boolean | undefined): Promise<IGenericResponseAPIM> => {
    return POST(`FeatureFlags/featureflag/${flagKey}/setting`, {Value: value}).then(data => data.Value);
  };

  const getFeatureFlagsByLinkedSupplier = (linkedSupplierId: number): Promise<ISupplierFeatureFlag> => {
    return GET(`FeatureFlags/FeatureFlags/Supplier/${linkedSupplierId}`);
  };

  const fetchFlags = async (supplierId?: number): Promise<Partial<IFeatureFlagsState>> => {
    let flagsResponse: Partial<IFeatureFlagsState> = {};

    const requests: {(): Promise<IFlagList | ISupplierFeatureFlag>}[] = [listFeatureFlags];

    if (supplierId) {
      requests.push(() => getFeatureFlagsByLinkedSupplier(supplierId));
    }

    const res = await Promise.all(requests.map(r => r()));

    const {error, flags} = res[0]; // IFlagList
    if (error) {
      Toast.info(error);
    }

    flagsResponse.featureFlags = flags as IFlagTypes;

    if (supplierId && res.length > 1) {
      const {error: e, flags: supplierFlags} = res[1];
      if (e) {
        Toast.info(e);
      }

      flagsResponse.linkedSupplierFeatureFlags = (supplierFlags as ILDKeyValue[]) || [];
    }

    return flagsResponse;
  };

  const saveFeatureFlag = async (flag: ILDFeature) => {
    const res = await setFeatureFlag(flag.key, flag.value);
    if (!res) return;
    const {error} = res;
    if (error) {
      Toast.info(error);
      return;
    }
  };

  return {
    fetchFlags,
    saveFeatureFlag,
    getFeatureFlagsByLinkedSupplier,
  };
};
