import React, {useContext, useEffect, useState} from 'react';
import {SModal} from '../modals/modalSpendaMeterialUI';
import {Box, Radio, makeStyles, RadioGroup, FormControlLabel} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {APFooter} from './APFooter';
import {PostingInventorySelection} from './PostingInventorySelection';
import APTooltip from '../data-display/APTootip';
import blueInfoIcon from '../../assets/svg/accounts-payable/blue-info-icon.svg';
import {CreateNewProductItemModal} from './CreateNewProductItemModal';
import {Toast} from '../../utils/Toast';
import {useStagedTransactionsAPI} from '../../services/useStagedTransactionsAPI';
import {Inventory} from '../../model/inventory/Inventory';
import {
  IPostingInventoryRequest,
  ISelectedInvoices,
  IStagedDebitNoteDetail,
  IStagedPurchaseInvoiceDetail,
} from '../../model/requires-attention/StagedTransactions';
import AppContext from '../../context/app/appContext';
import {useInventoryAPI} from '../../services/useInventoryAPI';
import LoadingIndicator from '../ui/LoadingIndicator';
import {Actions} from '../../model/constants/Constants';

export interface IFinancialSystemPostingBehaviourModalProps {
  handleClose?: () => void;
  handleDone?: () => void;
  selectedLineItemID?: number | null;
  inventoryCode: string;
  purchaseInvoiceDetails?: IStagedPurchaseInvoiceDetail;
  debitNoteDetails?: IStagedDebitNoteDetail;
  isMultiplePostingSelected?: boolean;
  selectedInvoices?: ISelectedInvoices[];
}

const useFinancialSystemPostingBehaviourModalStyles = makeStyles({
  inventorySelect: {
    minWidth: '185px',
    height: '40px',
    border: '1px solid #333',
    borderRadius: '6px',
    marginLeft: '10px',
    '& .MuiSelect-select': {
      color: '#333333',
      fontSize: '14px',
      fontWeight: '600',
      fontFamily: 'poppins',
      lineHeight: '16px',
      padding: '0px 40px 0px 10px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '40px',
      '& .MuiRadio-root': {
        display: 'none',
      },
    },
    '& svg': {
      color: '#1c78ad',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px!important',
    },
  },
  radioBtns: {
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: '#1c78ad',
    },
  },
});
export const FinancialSystemPostingBehaviourModal = (props: IFinancialSystemPostingBehaviourModalProps) => {
  const {
    handleClose,
    handleDone,
    inventoryCode,
    purchaseInvoiceDetails,
    debitNoteDetails,
    isMultiplePostingSelected,
    selectedInvoices,
  } = props;
  const classes = useFinancialSystemPostingBehaviourModalStyles();
  const [selectedPostingTypeOption, setSelectedPostingTypeOption] = useState<number>(0);
  const [createNewProductItemModal, setCreateNewProductItemModal] = useState<boolean>(false);
  const [selectedGenericPostingInventory, setSelectedGenericPostingInventory] = useState<Partial<Inventory>>({});
  const [selectedMappedPostingInventory, setSelectedMappedPostingInventory] = useState<Partial<Inventory>>({});
  const [selectedRuleID, setSelectedRuleID] = useState<number | undefined>();
  const supplierID = purchaseInvoiceDetails?.sourceSupplierID! || debitNoteDetails?.sourceSupplierID!;
  const [action, setAction] = useState<Actions>(Actions.add);
  const [requestItems, setRequestItems] = useState<IPostingInventoryRequest[]>([]);
  const {tenantInfo} = useContext(AppContext);

  const {postInventoryPostingRule} = useStagedTransactionsAPI();
  const {isLoading: isInventoryLoading, getInventoryById} = useInventoryAPI();

  const getInventoryDetails = async (postingInventoryID: number) => {
    return await getInventoryById(postingInventoryID.toString());
  };

  const genericPostingInventory = () => {
    const responseDetails = purchaseInvoiceDetails || debitNoteDetails;
    return (
      responseDetails?.postingSupplierPostingInventoryID ||
      responseDetails?.sourceSupplierPostingInventoryID ||
      tenantInfo?.TenantUserDetails?.DefaultGenericLineItemInventoryId
    );
  };

  const getSelectedPostingTypeOption = async () => {
    const responseDetails = purchaseInvoiceDetails || debitNoteDetails;
    const lineDetails = responseDetails?.lines.find((line: {code: string}) => line.code === inventoryCode);
    const genericInventory = genericPostingInventory();
    let postingType = -1;
    let apiAction = Actions.add;
    if (lineDetails?.supplierInventoryPostingRuleID) {
      setSelectedRuleID(lineDetails?.supplierInventoryPostingRuleID);
      apiAction = Actions.update;
    }
    if (lineDetails?.postingInventoryID) {
      if (!lineDetails.isInventoryMapped) {
        if (genericInventory == lineDetails?.postingInventoryID) {
          postingType = 2;
        } else if (responseDetails?.isSourceSupplierInventoryMappingEnabled) {
          postingType = -1;
        }
      } else if (lineDetails.isInventoryMapped) {
        if (genericInventory == lineDetails?.postingInventoryID) {
          postingType = 2;
        } else {
          postingType = 1;
        }
      }
      setAction(apiAction);
      setSelectedPostingTypeOption(postingType);
      const genericInventoryDetail = await getInventoryDetails(genericInventory!);
      setSelectedGenericPostingInventory(genericInventoryDetail);
      if (postingType === 1) {
        const mappedInventory = await getInventoryDetails(lineDetails?.postingInventoryID);
        setSelectedMappedPostingInventory(mappedInventory);
      }
    } else {
      const genericInventoryDetail = await getInventoryDetails(genericInventory!);
      setSelectedGenericPostingInventory(genericInventoryDetail);
      setSelectedPostingTypeOption(-1);
      setAction(Actions.add);
    }
  };

  const getRequestItemDetails = async () => {
    setSelectedPostingTypeOption(-1);
    const requestItemsArr: IPostingInventoryRequest[] = [];
    await selectedInvoices?.forEach(item => {
      let action: Actions;
      let selectedRuleID;
      let lineDetails;
      if (item.isInvoice) {
        lineDetails = purchaseInvoiceDetails?.lines.find(
          (line: {stagingPurchaseInvoiceLineID: number}) => line.stagingPurchaseInvoiceLineID === item.id,
        );
      } else {
        lineDetails = debitNoteDetails?.lines.find(
          (line: {stagingDebitNoteLineID: number}) => line.stagingDebitNoteLineID === item.id,
        );
      }
      if (lineDetails?.supplierInventoryPostingRuleID) {
        selectedRuleID = lineDetails?.supplierInventoryPostingRuleID;
        action = Actions.update;
      } else {
        action = Actions.add;
      }
      requestItemsArr.push({
        SourceSupplierID: supplierID,
        InventoryCode: lineDetails?.code!,
        PostingInventoryID: '',
        Action: action,
        ID: selectedRuleID,
      });
    });
    setRequestItems(requestItemsArr);
  };

  useEffect(() => {
    if (!isMultiplePostingSelected) {
      getSelectedPostingTypeOption();
    } else {
      getRequestItemDetails();
    }
  }, []);

  const onSave = async () => {
    try {
      const selectedInventoryID =
        selectedPostingTypeOption === 1
          ? selectedMappedPostingInventory.ID
          : selectedPostingTypeOption === 2
            ? selectedGenericPostingInventory.ID || tenantInfo?.TenantUserDetails?.DefaultGenericLineItemInventoryId
            : undefined;
      if (!selectedInventoryID || !supplierID) {
        Toast.error('Posting behaviour or supplier id missing');
        return;
      }
      if (!isMultiplePostingSelected) {
        await postInventoryPostingRule(supplierID, [
          {
            SourceSupplierID: supplierID,
            InventoryCode: inventoryCode,
            PostingInventoryID: selectedInventoryID,
            Action: action,
            ID: selectedRuleID,
          },
        ]);
      } else if (isMultiplePostingSelected) {
        const requestArr = requestItems.map(item => {
          item.PostingInventoryID = selectedInventoryID;
          return item;
        });
        await postInventoryPostingRule(supplierID, requestArr);
      }
      handleDone?.();
    } catch (error) {
      console.error('error', error);
    }
  };

  return (
    <SModal open>
      <div className={`spenda-color flex h-full max-w-full items-center justify-center font-poppins text-xl`}>
        <div className={`modal-box h-[540px] max-w-[942px] px-5 pb-5 pt-3 text-spenda-primarytext`}>
          <Box className={`-mx-5 flex justify-between border-[#CCCCCC] border-b-default px-3 pb-3`}>
            <h2 className={`w-full self-center text-center text-xl font-light`}>Financial system posting behaviour</h2>
            <Box className="flex justify-end text-right">
              <span className="ap-onboarding-close-icon">
                <CloseIcon data-autoid="btnClose" onClick={handleClose} className="text-primary" />
              </span>
            </Box>
          </Box>
          {isInventoryLoading ? (
            <LoadingIndicator isLoading={isInventoryLoading} size="md" color="hsl(var(--primary))" />
          ) : (
            <>
              <Box className={`px-1 pt-6 text-base font-medium ${classes.radioBtns}`}>
                <div>
                  <p className="mb-3 text-base text-[#707070]">
                    Choose how you want Products to post to your financial system:
                  </p>
                </div>

                <div className="flex text-left ">
                  <RadioGroup>
                    <FormControlLabel
                      value="0"
                      control={
                        <Radio
                          onChange={() => {
                            setSelectedPostingTypeOption(0);
                            setCreateNewProductItemModal(true);
                          }}
                        />
                      }
                      label={
                        <span className="ml-2 font-poppins text-base font-semibold leading-[42px]">{`Create a new item`}</span>
                      }
                      checked={selectedPostingTypeOption === 0}
                    />
                    <FormControlLabel
                      value="1"
                      control={
                        <Radio
                          onChange={() => {
                            setSelectedPostingTypeOption(1);
                          }}
                        />
                      }
                      label={
                        <span className="ml-2 font-poppins text-base font-semibold leading-[42px]">{`Map to existing item`}</span>
                      }
                      checked={selectedPostingTypeOption === 1}
                    />
                    {selectedPostingTypeOption === 1 && (
                      <div className="ml-[41px] flex items-center justify-start">
                        <div className="mr-8 flex w-[230px] flex-row">
                          <span className="min-w-[60%] text-xs">Select existing posting inventory:</span>
                          <APTooltip
                            arrow
                            placement="bottom"
                            title="By default, the system will allocate a generic inventory. If you wish to make your own selection, please choose from the dropdown menu."
                          >
                            <img className="ml-1 cursor-pointer" src={blueInfoIcon} data-autoid={`imgBlueInfo`} />
                          </APTooltip>
                        </div>
                        <PostingInventorySelection
                          selectedPostingInventory={selectedMappedPostingInventory}
                          setSelectedPostingInventory={setSelectedMappedPostingInventory}
                          isShowRedirectIcon={true}
                        />
                      </div>
                    )}
                    <FormControlLabel
                      value="2"
                      control={
                        <Radio
                          onChange={() => {
                            setSelectedPostingTypeOption(2);
                          }}
                        />
                      }
                      label={
                        <span className="ml-2 font-poppins text-base font-semibold leading-[42px]">{`Post as generic item`}</span>
                      }
                      checked={selectedPostingTypeOption === 2}
                    />
                  </RadioGroup>
                </div>
              </Box>
              {selectedPostingTypeOption === 2 && (
                <div className="ml-[41px] flex items-center justify-start">
                  <div className="mr-8 flex w-[230px] flex-row">
                    <span className="min-w-[60%] text-xs">Supplier generic posting inventory:</span>
                  </div>
                  <div className="w-full max-w-[340px]">
                    <PostingInventorySelection
                      selectedPostingInventory={selectedGenericPostingInventory}
                      setSelectedPostingInventory={setSelectedGenericPostingInventory}
                      disableField={true}
                      isShowRedirectIcon={true}
                    />
                  </div>
                </div>
              )}
            </>
          )}
          <APFooter
            isDisabled={isInventoryLoading}
            isCancelButton={true}
            buttonLabel={'Done'}
            onNextClick={() => onSave()}
            handleCancelClick={handleClose}
            isT2TSupplierModule={true}
            conditionallyClass={true}
            APFooterClass="!bottom-[10px] !left-[10px]"
            style={{width: 'calc(100% - 20px)'}}
          />
        </div>
        {createNewProductItemModal && (
          <CreateNewProductItemModal
            handleClose={() => {
              setCreateNewProductItemModal(false);
              handleClose?.();
            }}
            handleCreateAction={() => {
              setCreateNewProductItemModal(false);
              handleDone?.();
            }}
            supplierID={supplierID}
            inventoryCode={inventoryCode}
            action={action}
            requestItems={requestItems}
            selectedRuleID={selectedRuleID}
            isMultiplePostingSelected={isMultiplePostingSelected}
          />
        )}
      </div>
    </SModal>
  );
};
