import React, {useEffect, useState} from 'react';
import {useSupplierAPI} from '../../services/useSupplierAPI';
import {ISupplier} from '../../model/supplier/Supplier';
import {ISupplierPrimaryContact} from '../../model/requires-attention/StagedTransactions';
import {PostingTypeModal} from '../accountsPayableOnboarding/PostingTypeModal';
import {useTabs} from 'spenda-ui-react';
import {LowTouchPsblOnboardingSteps} from '../../model/constants/Constants';
import {ImportWorkflowsModal} from '../AccountsReceivable/modals/ImportWorkflowsModal';
import {IQueueWorkFlowPayload} from '../../model/workflow/WorkflowResponse';
import {ResponsiveBookOnboardingButton} from '../buttons/PSBLOnboardingFooter';

interface ICapricornSupplierMappingProps {
  supplierId?: number;
  handleNext?: () => void;
  suppliersImportWrokFlowId: number | undefined;
  suppliersQueuePayload: Partial<IQueueWorkFlowPayload> | undefined;
  startApDataV2Workflow: () => Promise<void>;
}

const CapricornSupplierMapping = (props: ICapricornSupplierMappingProps) => {
  // Props
  const {handleNext, supplierId, suppliersImportWrokFlowId, suppliersQueuePayload, startApDataV2Workflow} = props;

  // State
  const [postingSupplierDetails, setPostingSupplierDetails] = useState<ISupplier>();
  const [isShowImportModal, setIsShowImportModal] = useState(false);
  const {state: tabState} = useTabs();

  // APIs
  const {getSupplierById} = useSupplierAPI();

  useEffect(() => {
    fetchPostinConfigurationDetails();
  }, [supplierId]);

  useEffect(() => {
    if (tabState?.active === LowTouchPsblOnboardingSteps.SupplierMapping) {
      setIsShowImportModal(true);
    }
  }, [tabState?.active, suppliersImportWrokFlowId]);

  const fetchPostinConfigurationDetails = async () => {
    try {
      if (!supplierId) return;
      const response = await getSupplierById(supplierId);
      setPostingSupplierDetails(response);
    } catch {}
  };

  const handleSave = async (): Promise<void> => {
    startApDataV2Workflow();
    handleNext?.();
  };

  let supplierDetails: ISupplierPrimaryContact | undefined = undefined;
  if (postingSupplierDetails) {
    supplierDetails = {
      abn: postingSupplierDetails?.ABN,
      emailAddress: postingSupplierDetails?.PrimaryContactEmailAddress,
      firstName: postingSupplierDetails?.PrimaryContactFirstName,
      lastName: postingSupplierDetails?.PrimaryContactLastName,
      name: postingSupplierDetails?.Name,
      phone: postingSupplierDetails?.Phone1,
      phoneMobile: postingSupplierDetails?.PrimaryContactMobile,
      refNumber: postingSupplierDetails?.RefNumber,
      supplierID: postingSupplierDetails?.PostingSupplierID || supplierId || 0,
      countDebitNotes: 0,
      countPurchaseInvoices: 0,
      IsVendorMappingEnabled: postingSupplierDetails?.IsVendorMappingEnabled,
    };
  }

  return (
    <>
      {isShowImportModal ? (
        <ImportWorkflowsModal
          workflowId={suppliersImportWrokFlowId}
          open={isShowImportModal}
          onSuccess={() => setIsShowImportModal(false)}
          message="We're currently retrieving your data. This should only take a moment."
          queuePayload={!suppliersImportWrokFlowId ? suppliersQueuePayload : undefined}
        />
      ) : (
        <div className="wrapper relative h-full w-full overflow-hidden bg-white pb-24 sm:pb-16">
          <div className="mx-2 h-full w-full overflow-y-auto overflow-x-hidden pb-0 sm:mx-10 sm:pb-0">
            <div className={`h-auto pb-3 align-middle sm:pb-0 md:!max-w-[747px]`}>
              <p
                className={`!pt-4 text-left font-poppins text-[20px] font-medium text-[#333] sm:text-2xl sm:font-light`}
              >
                Supplier Mapping
              </p>
              <p className={`pt-6 text-left text-sm font-normal text-[#999999] sm:mt-2`}>
                To start integrating transactions, Spenda needs to know which supplier record to use in your financial
                system. Please select 'Capricorn' from the list below to complete the setup, or create a new supplier
                record for Capricorn.
              </p>
              <PostingTypeModal
                supplierDetails={supplierDetails}
                scope="PSBL"
                isDialog={false}
                handleClose={() => {}}
                handleDone={handleSave}
                isLowTouchOnboarding
              />
            </div>
            <ResponsiveBookOnboardingButton />
          </div>
        </div>
      )}
    </>
  );
};

export default CapricornSupplierMapping;
